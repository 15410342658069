import update from "immutability-helper";

const loggedInObj = JSON.parse(sessionStorage.getItem("accountInfo"));
 //console.log("loggedIn",loggedInObj);

const loggedReducer = (
    state = loggedInObj
        ? loggedInObj
        : {
              employeeId: "",
              accessToken: "",
              employeeName: "",
              employeeIdId: "",
              role: "",
              roleId: "",
              image: null,
              access: {},
              homeRoute: "",
              menuCollapse: false,
              plantOptions:[],
              showHideWeight: false,
              itemIdForBomShortcut: null,
              soDetailId : null,
              editableForApproved :null
          },
    action
) => {
    switch (action.type) {
        case "ADD_TOKEN":
            state.menuCollapse = window.innerWidth < 1201 ;
            var info = { employeeId: action.payload.employeeId, accessToken: action.payload.accessToken };
            sessionStorage.setItem("accountInfo", JSON.stringify(info));

            return update(state, {
                accessToken: { $set: action.payload.accessToken },
                employeeId: { $set: action.payload.employeeId },
            });

        case "REMOVE_TOKEN":
            sessionStorage.setItem("accountInfo", JSON.stringify(null));
            return {};

        case "ADD_EMP_INFO":
            var NewState = update(state, {
                employeeIdId: { $set: action.payload.employeeId },
                employeeName: { $set: action.payload.employeeName },
                role: { $set: action.payload.role },
                roleId: { $set: action.payload.roleId },
                editableForApproved :{$set :action.payload.editableForApproved}
            });
            sessionStorage.setItem("accountInfo", JSON.stringify(NewState));
            return NewState;

        case "ADD_EMP_IMAGE":
            var newState = update(state, { image: { $set: action.payload.empImage } });
            sessionStorage.setItem("accountInfo", JSON.stringify(newState));
            return newState;

        case "ADD_USER_ACCESS":
            var newState = update(state, { access: { $set: action.payload.userAccess } });
            sessionStorage.setItem("accountInfo", JSON.stringify(newState));
            return newState;

        case "ADD_HOME_ROUTE":
            var newState = update(state, { homeRoute: { $set: action.payload.homeRoute } });
            sessionStorage.setItem("accountInfo", JSON.stringify(newState));
            return newState;

        case "TOGGLE_MENU_COLLAPSE":
            var newState = update(state, { menuCollapse: { $set: action.payload.menuCollapse } });
            sessionStorage.setItem("accountInfo", JSON.stringify(newState));
            return newState;

        case "ADD_PLANT_OPTIONS":
            var newState = update(state,{plantOptions: {$set: action.payload.options} });
            sessionStorage.setItem("accountInfo",JSON.stringify(newState));
            return newState;
        
            //======================Show/Hide Weight======================
         case "SHOW_HIDE_WEIGHT":
             var newState = update(state,{showHideWeight: { $set: action.payload.bool} });
             sessionStorage.setItem("accountInfo",JSON.stringify(newState));
            return  newState;

            case "BOM_SHORTCUT":
                var newState = update(state,{itemIdForBomShortcut: {$set: action.payload.itemId} });
                sessionStorage.setItem("accountInfo",JSON.stringify(newState));
                return newState;

            case "SO_DETAIL_ID":
                var newState = update(state,{soDetailId: {$set: action.payload.soId} });
                sessionStorage.setItem("accountInfo",JSON.stringify(newState));
                return newState;
        default:
            return state;
    }
};

export default loggedReducer;
