import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";

import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import salesOrderIcon from "../../assets/salesOrder.svg";
import mailIcon from "../../assets/mail.png";
import CreateSalesOrder from "./CreateSalesOrder";
import SalesOrderList from "./SalesOrderList";
import SalesOrderDetail from "./SalesOrderDetail";
import EditSalesOrder from "./EditSalesOrder";
import CreateSalesReturn from "./CreateSalesReturn";
import SalesReturnDetail from "./SalesReturnDetail";
import EditSalesReturn from "./EditSalesReturn";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";
import EmailPopupModal from "../Dialogs/EmailPopup";
import ProformaInvoice from "./ProformaInvoice";
import {viewSoDetailId} from "../../redux/UserAccount/IsLoggedActions"


const SalesOrder = ({ isLogged, poInfo }) => {
    let {soDetailId}=isLogged
    const [currentSection, setCurrentSection] = useState("salesOrderList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);
    const [tab, setTab] = useState(poInfo.soTab);
    const [downloadReportParams,setDownloadReportParams] = useState({})
    var createSalesOrderButton = 
        { buttonName: "Create Sales Order", className: "employeeButton", setSection: setCurrentSection, sectionName: "createSalesOrder" };

    var createSalesReturnButton = 
        { buttonName: "Create Sales Return", className: "employeeButton", setSection: setCurrentSection, sectionName: "createSalesReturn" };

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "salesOrderList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "soDetails",downloadReportParams) }, sectionName: "salesOrderList" };
    var downloadSRButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "srDetails") }, sectionName: "salesOrderList" };
    var profINVdownloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "proformaInvoice",downloadReportParams) }, sectionName: "salesOrderList" };
    var mailIconButton = {
        buttonName: "Mail", className: "saveButton", iconSrc: mailIcon, setSection: () => {
            setEmailPopupModalIsOpen(true);
        }, sectionName: "salesOrderList"
    };
          
    const generatePdf = async(downloadReportParams)=>{
        await exportPDF(isLogged, "proformaInvoice",downloadReportParams);
        viewSoDetailId(null);
    }
   useEffect(()=>{
      if(soDetailId){
        setCurrentSection('proformaInvoice')
      }else{
        viewSoDetailId(null)
        setCurrentSection('salesOrderList')
      }
   },[])

    const [emailModalIsOpen, setEmailPopupModalIsOpen] = useState(false);

    const renderSection = (section) => {
        switch (section) {
            case "createSalesOrder":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salesOrderIcon} pageTitle="Create Sales Order" buttonList={[goBackButton]} />
                        <CreateSalesOrder setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "salesOrderList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesOrderIcon}
                            pageTitle="Sales Order List"
                            buttonList={tab === "soList" && (isLogged.access["Sales Order"] === "Create" || isLogged.access["Sales Order"] === "Edit" || isLogged.access["Sales Order"] === "App/Dec") ?
                                [createSalesOrderButton] :
                                tab === "salesOrderReturn" && (isLogged.access["Sales Order"] === "Create" || isLogged.access["Sales Order"] === "Edit" || isLogged.access["Sales Order"] === "App/Dec")
                                    ? [createSalesReturnButton] : null} />
                        <SalesOrderList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={(value) => setApprovalPage(value)}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setTab={setTab}
                        />
                    </React.Fragment>
                );

            case "salesOrderDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesOrderIcon}
                            pageTitle={approvalPage ? "Approve Sales Order" : "Sales Order Detail"}
                            buttonList={approvalPage ? [createSalesOrderButton,goBackButton] : [createSalesOrderButton,goBackButton, downloadButton, mailIconButton]}
                        />
                        <SalesOrderDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setDownloadReportParams={(value=>setDownloadReportParams(value))}
                        />
                    </React.Fragment>
                );

            case "editSalesOrder":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesOrderIcon}
                            pageTitle={editAndApprove ? "Edit & Approve SO" : "Edit Sales Order"}
                            buttonList={[goBackButton]}
                        />
                        <EditSalesOrder setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            case "createSalesReturn":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salesOrderIcon} pageTitle="Create Sales Order Return" buttonList={[goBackButton]} />
                        <CreateSalesReturn setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "salesReturnDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesOrderIcon}
                            pageTitle={approvalPage ? "Approve Sales Order Return" : "Sales Order Return Detail"}
                            buttonList={approvalPage ? [createSalesReturnButton,goBackButton] : [createSalesReturnButton,goBackButton, downloadSRButton]}
                        />
                        <SalesReturnDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editSalesReturn":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesOrderIcon}
                            pageTitle={editAndApprove ? "Approve Sales Return" : "Edit Sales Return"}
                            buttonList={[goBackButton]}
                        />
                        <EditSalesReturn setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            case "proformaInvoice":
                return(<React.Fragment>
                        <PageTitle
                            imgSrc={salesOrderIcon}
                            pageTitle={ "Proforma Invoice Detail"}
                            buttonList={approvalPage ? [createSalesOrderButton,goBackButton] : [createSalesOrderButton,goBackButton, 
                                profINVdownloadButton,
                                 mailIconButton]}
                        />
                        <ProformaInvoice
                            setSection = {(value) => setCurrentSection(value)}
                            approval = {approvalPage}
                            setEditAndApprove = {(value) => setEditAndApprove(value)}
                            setDownloadReportParams = {(value)=>setDownloadReportParams(value)}
                            generatePdf ={(downloadReportParams)=> generatePdf(downloadReportParams)}
                        />
                    </React.Fragment>
            )

            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (

        <React.Fragment>
            <EmailPopupModal
                openModal={emailModalIsOpen}
                isLogged={isLogged}
                reportName="soDetails"
                cancelClick={() => setEmailPopupModalIsOpen(false)}
            />
            <div className="completePage">
                <MenuList selectedMenu="Sales" selectedSubMenu="Sales Order" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        viewSoDetailId : (soId)=>dispatch(viewSoDetailId(soId)),   
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrder);
