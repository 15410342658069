import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { editTransporterId, editUomAction } from "../../redux/Item/ItemInfoActions";

const TransporterList = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    editTransporter,
    addTokenToState,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getTransporters();
    }

    const history = useHistory();

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    async function checkToken() {
   
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getTransporters() {
        var data = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/transporter-list",
            headers: { token: isLogged.accessToken,module: "Transporter" },
        });
        if (data.msg === "success") {
            setTransporterList(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            // console.log(data);
        }
        setLoading(false);
    }

    const [transporterList, setTransporterList] = useState([]);

    const renderUomList = (list) => {
        return list.map((row) => {
            return (
                <tr className="uomListData" key={row.transporterId}>
                    <td className="uomSymbolData">{row.transporterName}</td>
                    <td className="uomSymbolData">{row.description}</td>
                    <td className="uomActionData">
                        <img
                            className="uomEditImage"
                            alt="Edit Record"
                            src={isLogged.access["Transporter"] === "Edit" ? editIcon : editIconDisabled}
                            onClick={() => {

                                if (isLogged.access["Transporter"] === "Edit") {
                                    editTransporter(row.transporterId);
                                    setSection("editTransporter");
                                }
                            }}
                            style={isLogged.access["Transporter"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <React.Fragment>
            <div className="uomListArea">
                <table className="uomListTable">
                    <thead>
                        <tr className="uomListHeader">
                            <td className="uomSymbolHeader">Transporter Name</td>
                            <td className="uomSymbolHeader">Description</td>
                            <td className="uomActionHeader">Action</td>
                        </tr>
                    </thead>
                    <tbody>{transporterList.length > 0 ? renderUomList(transporterList) : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editTransporter: (transporterId) => dispatch(editTransporterId(transporterId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransporterList);
