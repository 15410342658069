import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";

import logoNotAvailable from "../../assets/no-stopping.svg";
import { editOpdId } from "../../redux/Store/StoreInfoActions";

const OpdDetail = ({
    // props
    approval,
    setSection,
    setEditAndApprove,

    // state
    isLogged,
    storeInfo,

    // dispatch
    addTokenToState,
    editOpdId,
}) => {

    const [opdDetail, setOpdDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);

    const [errorMessage, setErrorMessage] = useState([]);

    const [customerLogo, setCustomerLogo] = useState([]);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving OPD. Please wait...");

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getOpdDetail();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getOpdDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "store-fetch/opd-detail/" + storeInfo.viewOpdId,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
     
            setOpdDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("opdList");
        }
        setModalText("Approving OPD. Please wait...");
    };

    async function approveOpd() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/opd-approve/${opdDetail.opdId}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved OPD");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    const renderSummaryInfo = () => {
        if (opdDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {opdDetail.opdNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={opdDetail.packingStatus === "Approved" ? { color: "#14c76a" } : { color: "#333333" }}
                        >
                            ( {opdDetail.packingStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Order No</span>
                        <span className="vendorSummaryValue">: {opdDetail.orderNo}</span>

                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {opdDetail.orderDate}</span>

                        <span className="vendorSummaryCategory">Storage Detail:</span>
                        <span className="vendorSummaryValue">
                            : {opdDetail.storageName} ({opdDetail.plantName})
                        </span>

                        <span className="vendorSummaryCategory">Packing Date</span>
                        <span className="vendorSummaryValue">: {opdDetail.packingDate}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    // const renderImageStatus = () => {
    //     return (
    //         <div>
    //             <span style={{ flex: "1" }}>&nbsp;</span>

    //             <img
    //                 className="poVendorImage"
    //                 src={
    //                     customerLogo !== null && customerLogo !== undefined && customerLogo !== ""
    //                         ? `data:image/png;base64,${customerLogo}`
    //                         : logoNotAvailable
    //                 }
    //                 alt="Vendor Logo"
    //             />
    //         </div>
    //     );
    // };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms</td>
                        <td>Gross Weight(gms)</td>
                        <td>UOM</td>
                        <td>Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {


        var lineItemParams = ["itemName", "from", "to", "netWeight", "grossWeight", "uom", "packedQuantity"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                </tr>
            );
        });
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editOpd");
                            editOpdId(storeInfo.viewOpdId);
                        }}
                    >
                        Edit
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            approveOpd();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const RenderNoItems = () => {
        if (lineItemsList.length > 0) {
            var uniqArr = Array.from(new Set(lineItemsList.map((item) => item.itemId)));
            return <span>{uniqArr.length}</span>;
        } else {
            return <span>0</span>;
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {opdDetail.companyName}
                            </span>

                            <span className="poSummaryValue">
                                {`Customer ID: ${opdDetail.companyId}`}
                            </span>
                            <span className="poSummaryValue">
                                {opdDetail.companyPinCode !== null
                                    ? ` ${opdDetail.companyAddress} - ${opdDetail.companyPinCode}`
                                    : opdDetail.companyAddress}
                            </span>
                            <span className="poSummaryValue">
                                {opdDetail.companyMobile !== null || opdDetail.companyPhone !== null ? `\nPhone: ` : null}
                                {opdDetail.companyMobile !== null ? opdDetail.companyMobile : null}
                                {opdDetail.companyPhone !== null ? `, ${opdDetail.companyPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">{opdDetail.companyEmail !== null ? `Email Id: ${opdDetail.companyEmail}` : null}</span>

                            <span className="poSummaryValue">{opdDetail.companyGstNo !== null ? `GST No: ${opdDetail.companyGstNo}` : null}</span>
                        </div>
                        {/* {renderImageStatus()} */}
                    </div>

                    {opdDetail.packingStatus === "Declined" ? (
                        <div style={{ marginTop: "2rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {opdDetail.declineReason}
                        </div>
                    ) : null}

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                Number of unique items:&nbsp;
                                <RenderNoItems />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        editOpdId: (poId) => dispatch(editOpdId(poId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OpdDetail);
