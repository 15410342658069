import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { editSoId } from "../../redux/PurchaseOrder/PoInfoActions";
import DeclineModal from "../SmallComponents/DeclineModal";

const SalesOrderDetail = ({
  approval,
  poInfo,
  isLogged,
  addTokenToState,
  setSection,
  setEditAndApprove,
  editSoId,
  setDownloadReportParams
}) => {
  const [soDetail, setSoDetail] = useState({});
  const [lineItemsList, setLineItemsList] = useState([]);
  const [bankDetailsList, setBankDetailsList] = useState([]);
  const [plantProfile,setPlantProfile]=useState()
  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  async function getInformation() {
    await checkToken();
    await getSoDetail();
   
  }
  const renderImageStatus = () => {
    return (
      <div style={{width:"80px",height: "80px",display:"inline-block"}}>
        <img
          className="vendorHeaderImage"
          src={
            plantProfile !== null && plantProfile !== undefined && plantProfile !== ""
              ? `data:image/png;base64,${plantProfile}`
              : logoNotAvailable
          }
          alt="Vendor Logo"
        />
        <div className="vendorCurrentStatus">
        </div>
      </div>
    );
  };
  const history = useHistory();
  async function getPlantProfile({plantId,plantProfile}) {
    if (plantId && plantProfile) {
        var data = await fetchData({
            requestingPage: "plantImage",
            method: "get",
            url: "image/plant-profile-photo/" + plantId,
            headers: { token: isLogged.accessToken, module: "Plant & Store" },
        });
        if (data.msg !== "failure") setPlantProfile(data);
    }
}
  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getSoDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "so-fetch/so-detail/" + poInfo.viewSoId,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
    
      setSoDetail(data.asset[0]);
      setLineItemsList(data.asset[0].lineItems);
      setBankDetailsList(data.asset[0].bankDetails);
      setDownloadReportParams({dealerName: data.asset[0].customerName, orderNo: data.asset[0].soNo})
      await getPlantProfile({plantId:data.asset[0].plantId,plantProfile: data.asset[0].plantProfile});
    } else {
      console.log(data);
    }
  }


  const renderSummaryInfo = () => {
    if (soDetail !== null) {
      return (
        <React.Fragment>
          <span
            className="vendorSummaryRole"
            style={{ textTransform: "capitalize"}}>
            {soDetail.soNo}{" "}
            <span
              className="vendorSummaryCategory"
              style={
                soDetail.soStatus === "Declined"
                  ? { color: "red" }
                  : soDetail.soStatus === "Approved"
                  ? { color: "#14c76a" }
                  : { color: "#333333" }
              }>
              ( {soDetail.soStatus} )
            </span>
          </span>
          <div className="vendorSummaryGrid" style={{paddingTop:"0px"}}>
            <span className="vendorSummaryCategory">Order Date</span>
            <span className="vendorSummaryValue">: {soDetail.soDate}</span>

            <span className="vendorSummaryCategory">
              Expected Delivery Date
            </span>
            <span className="vendorSummaryValue">
              : {soDetail.expectedDeliveryDate}
            </span>

            <span className="vendorSummaryCategory">Transporter Name</span>
            <span className="vendorSummaryValue">
              :{" "}
              {soDetail.transporterName === null ||
              soDetail.transporterName === ""
                ? "NA"
                : soDetail.transporterName}
            </span>
          </div>         
        </React.Fragment>
      );
    }
  };

  const RenderTable = () => {
    return (
      <table className="vendorDetailContactsTable" style={{backgroundColor:"white",margin: "10px 20px"}}>
        <thead>
          <tr className="vendorDetailContactsTableHeader">
            <td>Item</td>
            <td>Item Code</td>
            <td>Remarks</td>
            {isLogged.showHideWeight ? (
              <>
                <td>Weight From(gms)</td>
                <td>Weight To(gms)</td>
                <td>Net Weight(gms)</td>
                <td>Gross Weight(gms)</td>
              </>
            ) : null}
            <td>UOM</td>
            <td>Quantity</td>
            <td>Unit Price</td>
            {lineItemsList.some((row)=>row["discount"] !== null) && <td>Discount (%)</td>}
            <td>Tax (%)</td>
            <td>Net Price</td>
          </tr>
        </thead>
        <tbody>
          <RenderTableRows rows={lineItemsList} tab="contact" />
        </tbody>
      </table>
    );
  };

  const RenderBankTable = () => {
    return (
      <table className="vendorDetailContactsTable" style={{backgroundColor:"white",margin:"10px 20px"}}>
        <thead>
          <tr className="vendorDetailContactsTableHeader">
            <td>Account Holder Name</td>
            <td>Account Number</td>
            <td>Bank Name</td>
            <td>IFSC Code</td>
            <td>Branch</td>
          </tr>
        </thead>
        <tbody>
          <RenderBankTableRows rows={bankDetailsList} tab="contact" />
        </tbody>
      </table>
    );
  };

  const RenderTableRows = ({ rows, tab }) => {
    var lineItemParams = isLogged.showHideWeight
      ? [
          "itemName",
          "itemCode",
          "remarks",
          "from",
          "to",
          "netWeight",
          "grossWeight",
          "uom",
          "quantity",
          "unitPrice",
          "discount",
          "tax",
          "netPrice",
        ]
      : [
          "itemName",
          "itemCode",
          "reamarks",
          "uom",
          "quantity",
          "unitPrice",
          "discount",
          "tax",
          "netPrice",
        ];
    let discountCheck = lineItemsList.some((row)=>row["discount"]!==null)
      if(!discountCheck)
      lineItemParams = lineItemParams.filter((string)=>string!=="discount");
    return rows.map((row, j) => {
      return (
        <tr className="vendorDetailContactsTableRows" key={j}>
          {lineItemParams.map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
        </tr>
      );
    });
  };

  const RenderBankTableRows = ({ rows, tab }) => {
    var bankDetailsParams = [
      "accountHolderName",
      "accountNumber",
      "bankName",
      "ifscCode",
      "branch",
    ];

    return rows.map((row, j) => {
      return (
        <tr className="vendorDetailContactsTableRows" key={j}>
          {bankDetailsParams.map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
        </tr>
      );
    });
  };
  async function approveSo() {
    setIsOpen(true);
    setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "approvePo",
      method: "put",
      url: `so-edit/so-approve/${poInfo.viewSoId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Approved Sales Order");
    } else {
      setModalText(data.desc);
      setErrorMessage(data.desc);
    }
  }

  const [errorMessage, setErrorMessage] = useState([]);

  const renderErrorMessage = () => {
    if (errorMessage.length > 0) {
      return errorMessage[0];
    }
  };
  const ApproveButtons = () => {
    return (
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button
            className="declineButton"
            onClick={() => {
              setDeclineModalIsOpen(true);
            }}>
            Decline
          </button>
          <button
            className="saveButton"
            onClick={() => {
              setEditAndApprove(true);
              setSection("editSalesOrder");
              editSoId(poInfo.viewSoId);
            }}>
            Edit
          </button>
          <button
            className="submitButton"
            onClick={() => {
              approveSo();
            }}>
            Approve
          </button>
        </div>
      </div>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (errorMessage.length === 0) {
      setSection("salesOrderList");
    }
    setModalText("Approving SO. Please wait...");
  };

  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [declineModalText, setDeclineModalText] = useState(
    "Provide a reason for SO Decline"
  );
  const [declineReason, setDeclineReason] = useState("");
  const [declineReasonError, setDeclineReasonError] = useState(false);

  const declineOkClick = () => {
    if (declineReason.trim().length > 0) {
      setDeclineReasonError(false);
      setDeclineModalIsOpen(false);

      setIsOpen(true);
      setModalText("Declining SO. Please Wait...");
      declineSo();
    } else {
      setDeclineReasonError(true);
    }
  };

  async function declineSo() {
    setIsOpen(true);
    setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "declinePo",
      method: "put",
      url: `so-edit/so-decline/${poInfo.viewSoId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: { reason: declineReason },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Declined Sales Order");
    } else {
      setModalText(data.desc);
      setErrorMessage(data.desc);
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Approving PO. Please wait...");
  const footerPlants=useMemo(()=>{

    if(isLogged.plantOptions && soDetail.plantName){
    let filtered = isLogged.plantOptions.filter((obj)=>obj.optionName!==soDetail.plantName);
    return filtered.map((obj)=>obj.optionName).join(',');
    }else{
      return " "
    }
  },[isLogged.plantOptions,soDetail.plantName]);

  const renderPlantDetails=({key,className})=>{
    return(
      <div className={ className  }>
     { className==='saleOrderHeader' ?  <div>{soDetail[`${key}Name`]}</div>: null }
    {className ==='saleOrderHeader' ?  <div style={{fontSize: "12px"}}>{soDetail[`${key}Address`]}</div> : null}
            <div style={{fontSize: "12px"}}>{className==='saleOrderFooter' ? <><span>Head Office:</span> {soDetail[`${key}Address`]}</> : null }{soDetail[`${key}City`]},{soDetail[`${key}State`]},{soDetail[`${key}Country`]} - {soDetail[`${key}ZipCode`]}</div>
            <div style={{fontSize:"12px"}}>GST: {soDetail[`${key}GstNo`]}</div>
            <div style={{fontSize: "12px"}}>{soDetail[`${key}Email`]},{soDetail[`${key}ContactNo`]}</div>
      </div>
    )
  }
  return (
    <React.Fragment>
      <DeclineModal
        openDeclineModal={declineModalIsOpen}
        declineOkClick={declineOkClick}
        declineModalText={declineModalText}
        declineReason={declineReason}
        error={declineReasonError}
        setDeclineReason={setDeclineReason}
        cancelDecline={() => setDeclineModalIsOpen(false)}
      />
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="detailsPageWrapper">
        <div className="detailsPageContainer">
          <div style={{display:"flex"}}>
          {renderImageStatus()}
            {renderPlantDetails({key:'plant',className: 'saleOrderHeader' })}
            </div>
          <hr style={{border: "1px solid #dfd9d9",margin: "10px 0px"}}/>
          <div className="vendorSummary">
            <div className="poSummaryText">{renderSummaryInfo()}</div>
            <div className="poSummaryText2">
              <span
                className="vendorSummaryRole"
                style={{ textTransform: "uppercase" }}>
                {soDetail.customerName}
              </span>

              <span className="poSummaryValue">
                {`Customer ID: ${soDetail.customerId}`}
              </span>
              <span className="poSummaryValue">
                {soDetail.customerPinCode !== null
                  ? ` ${soDetail.customerAddress} - ${soDetail.customerPinCode}`
                  : soDetail.customerAddress}
              </span>
              <span className="poSummaryValue">
                {soDetail.customerMobile !== null ||
                soDetail.customerPhone !== null
                  ? `\nPhone: `
                  : null}
                {soDetail.customerMobile !== null
                  ? soDetail.customerMobile
                  : null}
                {soDetail.customerPhone !== null
                  ? `, ${soDetail.customerPhone} `
                  : null}
              </span>
              <span className="poSummaryValue">
                {soDetail.customerEmail !== null
                  ? `Email Id: ${soDetail.customerEmail}`
                  : null}
              </span>

              <span className="poSummaryValue">
                {soDetail.customerGstNo !== null
                  ? `GST No: ${soDetail.customerGstNo}`
                  : null}
              </span>
            </div>
          </div>

          {soDetail.soStatus === "Declined" ? (
            <div style={{ marginTop: "2rem" }}>
              <span style={{ color: "red", fontWeight: "bold" }}>
                Reason :{" "}
              </span>{" "}
              {soDetail.declineReason}
            </div>
          ) : null}
          <div className="vendorDetailTabArea">
            <div className="vendorSummary">
              <div className="poSummaryText">
                <span
                  className="vendorSummaryRole"
                  style={{
                    textTransform: "capitalize",
                    padding: "5px",
                    color: "#1f43e5",
                  }}>                  
                  Item Details
                </span>
              </div>
            </div>
            <RenderTable />
          </div>
      

          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items: <span>{lineItemsList.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>
                Sales Instruction:{" "}
                {soDetail.salesInstruction !== null
                  ? soDetail.salesInstruction
                  : "-"}
              </div>
            </div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Net Total</b>
                </span>
                <span className="poNetTotalAmount">{soDetail.netTotal}</span>
              </div>
              <div className="SoDetailChargesDiv">
                <div className="poText">
                  <span>Other Charges</span>
                  <span>{soDetail.otherCharges}</span>
                </div>

                <div className="poText">
                  <span>Transport Charges </span>
                  <span>{soDetail.transportCharges}</span>
                </div>

                  {soDetail.finalDiscount ? 
                    <div className="poText">
                      <span>Discount</span>
                      <span>{soDetail.finalDiscount}</span>
                    </div>: null}

              </div>
              <div className="poTotalTitle">
                  <span>Round off { soDetail.totalAmount?.toString().split('.')[1] ? 
                                    Math.floor(soDetail.totalAmount) < Math.round(soDetail.totalAmount) ? <em>(More)</em> : <em>(Less)</em> 
                                  : ""}</span>
                  <span className="poNetTotalAmount">{'0.'+(soDetail.totalAmount?.toString().split('.')[1] || 0)}</span>
                </div>
              <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                <span>
                  <b>Total Amount<em>(Rounded off)</em></b>
                </span>
                <div className="poNetTotalAmount">{Math.round(soDetail.totalAmount)}</div>
              </div>
            </div>
          </div>
          <div className="vendorDetailTabArea">
            <div className="vendorSummary">
              <div className="poSummaryText">
                <span
                  className="vendorSummaryRole"
                  style={{
                    textTransform: "capitalize",
                    padding: "5px",
                    color: "#1f43e5",
                  }}>
                  {" "}
                  Bank Details{" "}
                </span>
              </div>
            </div>
            <RenderBankTable />
          </div>
          <hr style={{margin: "10px 0px",border: "1px solid #dfd9d9"}}/>
          <div style={{display:"flex",flexDirection:"column"}}>
                
               {soDetail.parentPlantName ? renderPlantDetails({key:'parentPlant',className: 'saleOrderFooter'}) : renderPlantDetails({key: 'plant',className: 'saleOrderFooter' })}
              <div style={{fontSize: "12px",textAlign:"center",margin:"5px"}}>
              <span>Branches : </span>{footerPlants}
              </div>
            </div>
        </div>
      </div>
      {approval ? <ApproveButtons /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    editSoId: (poId) => dispatch(editSoId(poId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderDetail);
