import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { viewVendorPaymentId,editVendorPaymentId } from "../../redux/PurchaseOrder/PoInfoActions";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import approveIcon from "../../assets/approve2.svg";
import FormElement from "../SmallComponents/FormElement";


const VendorPaymentList=({
    setSection,isLogged,
    viewVenPayId,
    editVenPayId,
    addTokenToState,
    setApproval,
    setEditAndApprove
})=>{
    const history=useHistory();
    const [loading,setLoading] = useState(true);
    const [plantId,setPlantId] = useState([]);
    const [venPaymentList,setVenPaymentList]=useState([]);
    const [venPaymentApprovedList,setVenPaymentApprovedList] = useState([]);
    const [selectedTab,setSelectedTab] = useState("venPaymentUnApprovedList");
    const access=isLogged.access["Vendor Payments"];

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
     }

     async function getVenPaymentList(){
         var receiptList1=await fetchData({
             requestingPage: "venPaymentList",
             method: "post",
             url : "po-fetch/ven-payment-unapproved-list",
             headers: {token:isLogged.accessToken, module:"Vendor Payments"}
         });

         if(receiptList1.msg==="success"){
             receiptList1.asset.forEach(obj => obj.collapse = true);
            setVenPaymentList(receiptList1.asset);
            setLoading(false);
         }else{
             console.log(receiptList1);
         }
     }

     async function getVenPaymentApprovedList(){
        var receiptList2=await fetchData({
            requestingPage: "venPaymentList",
            method: "post",
            url : "po-fetch/ven-payment-approved-list",
            headers: {token:isLogged.accessToken, module:"Vendor Payments"}
        });

        if(receiptList2.msg==="success"){
            receiptList2.asset.forEach(obj => obj.collapse = true);
           setVenPaymentApprovedList(receiptList2.asset);
           setLoading(false);
        }else{
            console.log(receiptList2);
        }
    };

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "venPaymentUnApprovedList":
                listCopy = venPaymentList.slice();
                break;

            case "venPaymentApprovedList":
                listCopy = venPaymentApprovedList.slice();
                break;

        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "venPaymentUnApprovedList":
                setVenPaymentList(listCopy);
                break;

            case "venPaymentApprovedList":
                setVenPaymentApprovedList(listCopy);
                break;
        }
    };

    const tabList=[ {id: "venPaymentUnApprovedList",name:"Vendor Payments"},
                {id: "venPaymentApprovedList",name:"Approved Vendor Payments"}];

    useEffect(()=>{
        checkToken();
        setLoading(true);
        switch(selectedTab){
            case "venPaymentUnApprovedList":
                setVenPaymentList([]);
                    getVenPaymentList();
                break;
            
            case "venPaymentApprovedList":
                setVenPaymentApprovedList([]);
                    getVenPaymentApprovedList();
                break;

            default:
                break;
        }
    },[selectedTab]);

    const setTab=(tabname)=>{
        setSelectedTab(tabname)
    }
    const RenderTabList = ({ tabList }) => {
        return tabList.map((tab) => {
            return (
                <div className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    key={tab.id} onClick={(e) => setTab(tab.id)}>
                    {tab.name}
                </div>
            );
        });
    };
    
    const RenderSubTable=({lineItems,tab})=>{
        return(
            <td colSpan={tab==="venPaymentUnApprovedList" ? "9":"10"} className="vendorListInnerTableWrapper">
                    <table className="vendorListInnerTable">
                        <thead>
                            <tr className="vendorListInnerTableHeader">
                                <td className="vendorListInnerTableHeading">Invoice No</td>
                                <td className="vendorListInnerTableHeading">Purchase Order No</td>
                                <td className="vendorListInnerTableHeading">Invoice Amount</td>
                                <td className="vendorListInnerTableHeading">Invoice Balance</td>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((row,i)=>{
                                return (
                                    <tr className="vendorListInnerTable" key={row.lineId}>
                                        <td className="vendorListInnertableInfo">{row.invoiceNo}</td>
                                        <td className="vendorListInnertableInfo">{row.purchaseOrderNo}</td>
                                        <td className="vendorListInnertableInfo">{row.totalAmount}</td>
                                        <td className="vendorListInnertableInfo">{row.invoiceBalance}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
            </td>
        );
    };

    const renderVenPaymentList=()=>{
        return venPaymentList.map((data,i)=>{
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==data.plantId) ) || plantId.length==0)
            return(
              <React.Fragment>
                <tr className="vendorList" key={data.receiptId}>
                    <td className="vendorListInfo">{data.receiptNo}</td>
                    <td className="vendorListInfo">{data.vendorName}</td>
                    <td className="vendorListInfo">{data.paymentDate}</td>
                    <td className="vendorListInfo">{data.paymentType}</td>
                    <td className="vendorListInfo">{data.netTotal}</td>
                    <td className="vendorListInfo">{data.paymentAmount}</td>
                    <td className="vendorListInfo">{data.createdBy}</td>
                    <td className="vendorListInfoActions">
                        <img  alt="View Record" className="vendorActionImages" src={viewIcon}
                            title="View Payment detail" onClick={(e)=>{
                                e.stopPropagation();
                                viewVenPayId(data.receiptId);
                                setSection("venPaymentDetail");
                                setApproval(false);}}/>
                        <img alt="Edit" className = "vendorActionImages" 
                        title={access==="Edit" || access ==="App/Dec" ? "Edit this Vendor Payment" : "Access Denied"}
                        src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                        style={access==="Edit" || access === "App/Dec" ? {cursor: "pointer"} : {cursor: "not-allowed"}}
                        onClick={(e)=>{
                            e.stopPropagation();
                            if(access==="Edit" || access === "App/Dec"){
                            editVenPayId(data.receiptId);
                            setSection("editVenPayment");
                            setEditAndApprove(false);}}}/>
                        <img alt="Approve/Decline" className="vendorActionImages"
                            title={access==="App/Dec" ? "Approve/Decline this Vendor Payment" : "Access Denied"}
                            src = {approveIcon}
                            style={access==="App/Dec" ? {cursor:"pointer"} : {cursor: "not-allowed"}}
                            onClick={(e)=>{
                                e.stopPropagation();
                                if(access==="App/Dec"){
                                    viewVenPayId(data.receiptId);
                                    setApproval(true);
                                    setSection("venPaymentDetail");}}}/>
                        <img alt="Toggle Expand" className="vendorActionImages"
                                title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                onClick={(e)=>{e.stopPropagation();
                                editItemCollapse("venPaymentUnApprovedList",i);}}/>
                    </td>
                </tr>
                <tr>{!data.collapse ? <RenderSubTable tab="venPaymentUnApprovedList" lineItems={data.lineItems}/>:null}</tr>
              </React.Fragment>
            );
        })
    };

   const renderVenPaymentApprovedList=()=>{
    return venPaymentApprovedList.map((data,i)=>{
     if( (plantId.length> 0 && plantId.some((obj)=>obj.value==data.plantId) ) || plantId.length==0)
     return(
           <React.Fragment>
                 <tr className="vendorList" key={data.receiptId}>
                     <td className="vendorListInfo">{data.receiptNo}</td>
                     <td className="vendorListInfo">{data.vendorName}</td>
                     <td className="vendorListInfo">{data.paymentDate}</td>
                     <td className="vendorListInfo">{data.paymentType}</td>
                     <td className="vendorListInfo">{data.netTotal}</td>
                     <td className="vendorListInfo">{data.paymentAmount}</td>
                     <td className="vendorListInfo">{data.createdBy}</td>
                     <td className="vendorListInfo">{data.approvedBy}</td>
                     <td className="vendorListInfoActions">
                         <img  alt="View Record" className="vendorActionImages" src={viewIcon}
                             title="View Vendor Payment" onClick={(e)=>{
                                 e.stopPropagation();
                                 viewVenPayId(data.receiptId);
                                 setSection("venPaymentDetail");
                                 setApproval(false);}}/>
                         <img alt="Toggle Expand" className="vendorActionImages"
                             title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                             onClick={(e)=>{e.stopPropagation();
                             editItemCollapse("venPaymentApprovedList",i);}}/>
                     </td>
                 </tr>
                 <tr>{!data.collapse ? <RenderSubTable tab="venPaymentApprovedList" lineItems={data.lineItems}/>:null}</tr>
           </React.Fragment>);
        });
    };

    const renderSubSection=()=>{
        
        switch(selectedTab){
            case "venPaymentUnApprovedList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">Vendor Payment Id</td>
                                    <td className="vendorListHeading">Vendor Name</td>
                                    <td className="vendorListHeading">Payment date</td>
                                    <td className="vendorListHeading">Payment Type</td>
                                    <td className="vendorListHeading">Total Invoice Amount</td>
                                    <td className="vendorListHeading">Payment Amount Received</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                        <tbody>{venPaymentList.length>0 ? renderVenPaymentList() :null }</tbody>
                        </table>
                        {venPaymentList.length === 0 && !loading?
                        (<div className="noRecordsYet">No Records available</div>): (loading?<div className="loader" style={{ margin: "1rem auto" }}></div>:null)}
                    </React.Fragment>
                );

            case "venPaymentApprovedList":
                return(
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">Vendor Payment Id</td>
                                    <td className="vendorListHeading">Vendor Name</td>
                                    <td className="vendorListHeading">Payment date</td>
                                    <td className="vendorListHeading">Payment Type</td>
                                    <td className="vendorListHeading">Total Invoice Amount</td>
                                    <td className="vendorListHeading">Payment Amount Received</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeading">Approved By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                        <tbody>{venPaymentApprovedList.length>0 ? renderVenPaymentApprovedList() :null }</tbody>
                        </table>
                        {venPaymentApprovedList.length === 0 && !loading?
                        (<div className="noRecordsYet">No Records available</div>): (loading?<div className="loader" style={{ margin: "1rem auto" }}></div>:null)}
                    </React.Fragment>
                );

            default: 
            return(<div>Page yet to be Built</div>);
        }
    };

    return(
        <React.Fragment>
            <div key={1} className="poListTabs">
                <RenderTabList tabList={tabList}/>
            </div>
            <div  key={2} style={{width:"250px",marginLeft:"25px"}}>
                <FormElement  setInput={(value)=>{setPlantId(value);}}
                                        inputType={"multiSelect"}
                                        value={plantId}
                                        hintText={"Plant Name"}
                                        options={isLogged.plantOptions}
                                        colSpan={1}
                                        rowSpan={1}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}/>
            </div>
            <div key={3} className="vendorListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
 }

 const mapStateToProps=(state)=>{
    return { isLogged: state.isLogged }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        addTokenToState: (accessToken,employeeId)=>dispatch(addToken(accessToken,employeeId)),
        viewVenPayId: (venPayId)=>dispatch(viewVendorPaymentId(venPayId)),
        editVenPayId: (venPayId)=>dispatch(editVendorPaymentId(venPayId)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(VendorPaymentList);
