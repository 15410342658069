import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import { useHistory } from "react-router";
import FormHint from "../SmallComponents/FormHint";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const CreateInward = ({ setSection, isLogged, addTokenToState }) => {
    const inwardParamsList = [
        ["orderType", "orderNo"],
        ["inwardDate", "batchNo"],
    ];

    const [inwardParams, setInwardParams] = useState({
        orderType: {
            inputType: "options",
            value: "",
            hintText: "Order Type",
            mandatory: true,
            colSpan: 9,
            error: false,
            options: [
                { optionId: "Purchase Order", optionName: "Purchase Order" },
                { optionId: "Sales Return", optionName: "Sales Return" },
                { optionId: "Plant Transfer", optionName: "Plant Transfer" },
                { optionId: "Purchase Import",optionName: "Purchase Import"}
            ],
            errorMessage: "Please select order type",
            disabled: false,
        },
        orderNo: {
            inputType: "options",
            value: "",
            hintText: "Order No",
            mandatory: true,
            colSpan: 9,
            error: false,
            options: [],
            errorMessage: "Select Order Number",
            serverCall: true,
        },
        inwardDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Inward Date",
            mandatory: true,
            colSpan: 9,
            error: false,
            maxdate: true,
            errorMessage: "Please Select inward date",
        },

        batchNo: {
            inputType: "text",
            value: "",
            hintText: "Batch No",
            mandatory: false,
            colSpan: 9,
            error: false,
            errorMessage: "",
        },
    });

    useEffect(() => {
        getInformation();
    }, []);
    const [importPoDetail,setImportPoDetail] = useState({});
    const getPurchaseImportDetail=async()=>{
        let importDetail = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/purchase-import-detail3/"+inwardParams.orderNo.value,
            headers: { token: isLogged.accessToken, module: "Inward"}
        });

        if(importDetail.msg === "success"){
            setImportPoDetail(importDetail.asset.importList[0]);
            setLineItems(importDetail.asset.itemLineItems);
        }else{
            console.log(importDetail);
        }
    }

    useEffect(() => {
        if (inwardParams.orderNo.value !== "") {
            switch (inwardParams.orderType.value) {
                case "Sales Return":
                    getSoReturnDetail();
                    break;

                case "Purchase Order":
                    getPoDetail();
                    break;

                case "Plant Transfer":
                    getTransferDetail();
                    break;
                case "Purchase Import":
                    getPurchaseImportDetail();
                    break;
                default:
                    break;
            }
        }
    }, [inwardParams.orderNo.value]);

    const [poDetail, setPoDetail] = useState({});
    const [soReturnDetail, setSoReturnDetail] = useState({});
    const [plantTransferDetail, setPlantTransferDetail] = useState({});
    const [showVendorDetail, setShowVendorDetail] = useState(false);
    const [instruction, setInstruction] = useState("");

    const [lineItems, setLineItems] = useState([]);

    const [inwardDocument, setInwardDocument] = useState("");

    const history = useHistory();
    async function getInformation() {
        await checkToken();
    }

    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    useEffect(() => {
        const getBasedOnOrderType=async()=>{

            var paramsCopy = Object.assign(inwardParams);
          
            if (paramsCopy.orderNo.serverCall) {
                if (inwardParams.orderType.value !== "") {
                    var orderList = await getOrderList(inwardParams.orderType.value);
    
                    paramsCopy = update(paramsCopy, { orderNo: { options: { $set: orderList }, value: { $set: "" } } });
                    setInwardParams(paramsCopy);
                } else {
                    paramsCopy = update(paramsCopy, { orderNo: { options: { $set: [] }, value: { $set: "" } } });
                    setInwardParams(paramsCopy);
                }
                setShowVendorDetail(false);
                setPoDetail({});
                setSoReturnDetail({});
            } else {
                paramsCopy = update(paramsCopy, { orderNo: { serverCall: { $set: true } } });
                setInwardParams(paramsCopy);
            }
        }
        getBasedOnOrderType()
    }, [inwardParams.orderType]);

    async function getOrderList(orderType) {
        var listUrl = "";

        switch (orderType) {
            case "Purchase Order":
                listUrl = "store-fetch/inward-pre-create-list/Purchase Order";
                break;

            case "Sales Return":
                listUrl = "store-fetch/inward-pre-create-list/Sales Return";
                break;

            case "Plant Transfer":
                listUrl = "store-fetch/inward-pre-create-list/Plant Transfer";
                break;
            
            case "Purchase Import":
                listUrl = "store-fetch/inward-pre-create-list/Purchase Import";
                break;
                
            default:
                break;
        }

        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: listUrl,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.orderNo, optionId: dataItem.orderId };
                optionArray.push(a);
            });

            return optionArray;
        } else {
            console.log(data);
        }
    }

    async function getPoDetail() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/purchase-inward-pre-create/poid=" + inwardParams.orderNo.value,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });
        if (data.msg === "success") {
            var poDetailCopy = data.asset[0];

            poDetailCopy.lineItems.forEach((item) => {
                item.toSend = false;
                item.receivedQuantity = "";
                item.quantityError = false;
                item.quantityErrorMessage = "Please enter a valid quantity";
                item.lineItemId = item.poLineItemId;
            });
            setPoDetail(poDetailCopy);
            setLineItems(poDetailCopy.lineItems);
            setShowVendorDetail(true);
        } else {
            console.log(data);
        }
    }

    async function getSoReturnDetail() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "so-fetch/sales-return-detail/" + inwardParams.orderNo.value,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });
        if (data.msg === "success") {
            var soDetailCopy = data.asset[0];

            soDetailCopy.lineItems.forEach((item) => {
                item.toSend = false;
                item.receivedQuantity = "";
                item.quantityError = false;
                item.quantityErrorMessage = "Please enter a valid quantity";
                item.lineItemId = item.returnLineItemId;
            });
            setSoReturnDetail(soDetailCopy);
            setLineItems(soDetailCopy.lineItems);
            setShowVendorDetail(true);
        } else {
            console.log(data);
        }
    }

    async function getTransferDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "store-fetch/stock-transfer-detail/" + inwardParams.orderNo.value,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });
        if (data.msg === "success") {
            var transferDetailCopy = data.asset[0];

            transferDetailCopy.lineItems.forEach((item) => {
                item.toSend = false;
                item.receivedQuantity = "";
                item.quantityError = false;
                item.quantityErrorMessage = "Please enter a valid quantity";
                item.lineItemId = item.transferLineItemId;
            });
            setPlantTransferDetail(transferDetailCopy);
            setLineItems(transferDetailCopy.lineItems);
            setShowVendorDetail(true);
        } else {
            console.log(data);
        }
    }

    const updateInwardParameter = ({ paramName, section, key, value }) => {
        if (section === "general") {
            var paramsCopy = Object.assign(inwardParams);

            paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });

            if (paramName === "orderNo" && value === "") {
                setPoDetail({});
                setSoReturnDetail({});
                setShowVendorDetail(false);
            }

            setInwardParams(paramsCopy);
        }
    };

    // From a nexted Object, convert the line items into a flat array for not losing focus while editing.

    const updateLineItems = (key, index, value) => {
        var lineItemsListCopy = lineItems.slice();

        lineItemsListCopy = update(lineItemsListCopy, { [index]: { [key]: { $set: value } } });

        setLineItems(lineItemsListCopy);
    };

    const [inwardErrors, setInwardErrors] = useState([]);

    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = JSON.parse(JSON.stringify(inwardParams));

        inwardParamsList[0].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        inwardParamsList[1].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        var sendToServer = false;

        // var orderDetailCopy = "";

        // switch (inwardParams.orderType.value) {
        //     case "Purchase Order":
        //         orderDetailCopy = JSON.parse(JSON.stringify(poDetail));
        //         break;

        //     case "Sales Return":
        //         orderDetailCopy = JSON.parse(JSON.stringify(soReturnDetail));
        //         break;
        // }

        lineItems.forEach((line, i) => {
            if (line.receivedQuantity !== "") {
                if (!isNaN(line.receivedQuantity) && parseInt(line.receivedQuantity) > 0) {
                    lineItems[i].toSend = true;
                    lineItems[i].quantityError = false;
                    sendToServer = true;
                } else {
                    lineItems[i].toSend = false;
                    lineItems[i].quantityError = true;
                    errorList.push(lineItems[i].quantityErrorMessage);
                }
            }
        });


        if (errorList.length === 0 && sendToServer) {

            var data2server = new FormData();

            data2server.append("orderType", paramsCopy.orderType.value);
            data2server.append("orderId", paramsCopy.orderNo.value);
            data2server.append("inwardDate", paramsCopy.inwardDate.value);

            if (paramsCopy.batchNo.value !== "") {
                data2server.append("batchNo", paramsCopy.batchNo.value);
            }

            if (instruction !== "") {
                data2server.append("instruction", instruction);
            }

            if (inwardDocument !== "") {
                data2server.append("inwardDocument", inwardDocument);
            }

            var lineItemsToServer = [];
          
            lineItems.forEach((item) => {
                var newItem = {};
                if (item.toSend) {
                    newItem.lineItemId = item.lineItemId;
                    newItem.itemId = item.itemId;
                    newItem.quantity = item.receivedQuantity;
                    newItem.importInvoiceId = item.invoiceId;
                    lineItemsToServer.push(newItem);
                }
            });

            data2server.append("inwardLineItems", JSON.stringify(lineItemsToServer));
           
            submitData(data2server);
          
        } else {
            if (errorList.length === 0) {
                setInwardErrors(["No Data to Submit"]);
                setModalText("No Data to Submit");
                setDataSubmitted(true);
                setIsOpen(true);
            }
        }

        paramsCopy = update(paramsCopy, { orderNo: { serverCall: { $set: false } } });
        setInwardErrors(errorList);
        setInwardParams(paramsCopy);
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "post",
            url: "store-create/inward",
            headers: { token: isLogged.accessToken, module: "Inward" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Inward Created Successfully!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setInwardErrors([result.desc]);
        }
        setDataSubmitted(true);
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (inwardErrors.length === 0) {
            setSection("inwardList");
        }
        setModalText("Creating inward. Please wait...");
    };

    const renderErrorMessage = () => {
        if (inwardErrors.length > 0) {
            return inwardErrors[0];
        } else return null;
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value}
                    setInput={(value) => {
                        updateInwardParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const RenderVendorDetail = () => {
        switch (inwardParams.orderType.value) {
            case "Purchase Order":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{poDetail.vendorName}</div>
                        <div className="detailText">
                            {`Vendor ID: ${poDetail.vendorId}`},
                            <br />{poDetail.vendorAddress},
                            <br /> {poDetail.vendorCity} {poDetail.state} {poDetail.vendorPinCode !== null ? ` - ${poDetail.vendorPinCode}` : null}
                            {poDetail.vendorMobile !== null || poDetail.vendorPhone !== null ? <br /> : null}
                            {poDetail.vendorMobile !== null || poDetail.vendorPhone !== null ? `Phone: ` : null}
                            {poDetail.vendorMobile !== null ? poDetail.vendorMobile : null}
                            {poDetail.vendorPhone !== null ? `, ${poDetail.vendorPhone} ` : null}
                            {poDetail.vendorEmail !== null ? <br /> : null}
                            {poDetail.vendorEmail !== null ? `Email Id: ${poDetail.vendorEmail}` : null}
                            {poDetail.vendorGstNo !== null ? <br /> : null}
                            {poDetail.vendorGstNo !== null ? `GST No:  ${poDetail.vendorGstNo}` : null}
                        </div>
                    </React.Fragment>
                );

            case "Sales Return":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{soReturnDetail.customerName}</div>
                        <div className="detailText">
                            {`ID: ${soReturnDetail.customerId}`},
                            <br /> {soReturnDetail.customerAddress},
                            <br /> {soReturnDetail.customerCity} {soReturnDetail.customerState}{" "}
                            {soReturnDetail.customerPinCode !== null ? ` - ${soReturnDetail.customerPinCode}` : null}
                            {soReturnDetail.customerMobile !== null || soReturnDetail.customerPhone !== null ? <br /> : null}
                            {soReturnDetail.customerMobile !== null || soReturnDetail.customerPhone !== null ? `Phone: ` : null}
                            {soReturnDetail.customerMobile !== null ? soReturnDetail.customerMobile : null}
                            {soReturnDetail.customerPhone !== null ? `, ${soReturnDetail.customerPhone} ` : null}
                            {soReturnDetail.customerEmail !== null ? <br /> : null}
                            {soReturnDetail.customerEmail !== null ? `Email Id: ${soReturnDetail.customerEmail}` : null}
                            {soReturnDetail.customerGstNo !== null ? <br /> : null}
                            {soReturnDetail.customerGstNo !== null ? `GST No:  ${soReturnDetail.customerGstNo}` : null}
                        </div>
                    </React.Fragment>
                );

            default:
                return null;
        }
    };

    const RenderDates = () => {
        switch (inwardParams.orderType.value) {
            case "Purchase Order":
                return poDetail.poDate !== undefined ? (
                    <div className="createSalesInvoiceGrid">
                        <div
                            style={{
                                gridColumn: `auto / span 9`,
                                display: "flex",
                                alignItems: "center",
                                // paddingLeft: "10px",
                                color: "#333333",
                            }}
                        >
                            Order Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>{poDetail.poDate !== undefined ? poDetail.poDate : "-"}</span>
                        </div>
                        <div
                            style={{
                                gridColumn: `auto / span 9`,
                                display: "flex",
                                alignItems: "center",
                                // paddingLeft: "10px",
                                color: "#333333",
                            }}
                        >
                            Expected Delivery Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {poDetail.expectedDeliveryDate !== undefined ? poDetail.expectedDeliveryDate : "-"}
                            </span>
                        </div>
                    </div>
                ) : null;

            case "Sales Return":
                return soReturnDetail.returnDate !== undefined ? (
                    <div className="createSalesInvoiceGrid">
                        <div
                            style={{
                                gridColumn: `auto / span 9`,
                                display: "flex",
                                alignItems: "center",
                                // paddingLeft: "10px",
                                color: "#333333",
                            }}
                        >
                            Order Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {soReturnDetail.returnDate !== undefined ? soReturnDetail.returnDate : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: `auto / span 9`,
                                display: "flex",
                                alignItems: "center",
                                // paddingLeft: "10px",
                                color: "#333333",
                            }}
                        >
                            Expected Delivery Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {soReturnDetail.expectedDeliveryDate !== undefined ? soReturnDetail.expectedDeliveryDate : "-"}
                            </span>
                        </div>
                    </div>
                ) : null;

            default:
                return null;
        }
    };
const lineItemCols = isLogged.showHideWeight ?["itemName", "from", "to", "netWeight", "grossWeight", "hsnCode", "uom", "quantity", "receivedQuantity"]  : ["itemName","hsnCode", "uom", "quantity", "receivedQuantity"];
    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "1" }}>
                            <div className="createSalesInvoiceGrid">
                                {renderFormElements({ elementList: inwardParamsList[0], param: inwardParams, section: "general" })}
                            </div>
                            <RenderDates />
                            <div className="createSalesInvoiceGrid">
                                {renderFormElements({ elementList: inwardParamsList[1], param: inwardParams, section: "general" })}
                            </div>
                        </div>

                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showVendorDetail ? <RenderVendorDetail /> : null}</div>
                            </div>
                        </div>
                    </div>

                    {/* <RenderLineItems /> */}

                    {lineItems?.length > 0 ? (
                        <table className="createItemPurchaseTable">
                            <thead>
                                <tr className="createVendorContactsTableHeader">
                                    <td className="stickyFirstColumn">Item </td>
                                    { isLogged.showHideWeight ?
                                    <><td>Weight From(gms)</td>
                                    <td>Weight To(gms)</td>
                                    <td>Net Weight(gms)</td>
                                    <td>Gross Weight(gms)</td></>:null}
                                    <td>HSN No</td>
                                    <td>UOM</td>
                                    <td>Quantity</td>
                                    <td>Received Quantity</td>
                                </tr>
                            </thead>
                            <tbody>
                                {lineItems.map((row, j) => (
                                    <tr className="createVendorContactsTableRows" key={j}>
                                        {lineItemCols.map((key, i) => {
                                            switch (key) {
                                                case "receivedQuantity":
                                                    return (
                                                        <td key={i} style={{ maxWidth: "15px",height:"15px" }}>
                                                            <input
                                                                className={
                                                                    lineItems[j].quantityError ? "createInwardInputError" : "createInwardInput"
                                                                }
                                                                type="text"
                                                                value={row[key]}
                                                                onChange={(e) => updateLineItems(key, j, e.target.value)}
                                                            />
                                                        </td>
                                                    );
                                                case "itemName":
                                                    return(
                                                        <td key={i} className="stickyFirstColumn">{row[key]}</td>
                                                    )
                                                default:
                                                    return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items:{" "}
                                <span>
                                    {inwardParams.orderType.value === "Purchase Order"
                                        ? poDetail.lineItems?.length
                                        : soReturnDetail.lineItems?.length}
                                </span>
                            </div>

                            <div style={{ marginTop: "10px" }}>Inward Instruction</div>
                            <textarea
                                className="createInwardMultilineInput"
                                value={instruction}
                                rows="3"
                                cols="30"
                                name="text"
                                placeholder="Enter instructions"
                                style={{
                                    display: "flex",
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    width:"400px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setInstruction(e.target.value);
                                }}
                            />

                            <div style={{ display: "flex" }}>
                                <div className="createSalesInvoiceGrid2">
                                    {inwardDocument !== "" ? (
                                        <div className="formElement" style={{ gridColumn: `auto / span ${9}` }}>
                                            <FormHint hintText="Inward Document" mandatory={false} />
                                            <div className="inputDone">
                                                <span
                                                    style={{
                                                        flex: 1,
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        // maxWidth: "168px",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    ✅ {inwardDocument.name}
                                                </span>
                                                <span
                                                    style={{ paddingRight: "10px", cursor: "pointer", "&:hover": { backgroundColor: "gray" } }}
                                                    onClick={() => setInwardDocument("")}
                                                    title="Remove and Re-upload LR Copy"
                                                >
                                                    ❌
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <FormElement
                                            colSpan={9}
                                            inputType="upload"
                                            hintText="Inward Document"
                                            setInput={(file) => {
                                                setInwardDocument(file);
                                            }}
                                        />
                                    )}
                                </div>

                                <div style={{ width: "300px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var paramsCopy = JSON.parse(JSON.stringify(inwardParams));
                                ["orderType", "orderNo", "inwardDate", "batchNo"].forEach((item) => {
                                    paramsCopy[item].value = "";
                                    paramsCopy[item].error = false;
                                });

                                setInwardParams(paramsCopy);
                                setInwardErrors([]);
                                setPoDetail({});
                                setSoReturnDetail({});
                            }
                        }}
                    >
                        Reset Fields
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setInwardErrors([]);
                            checkErrors();
                        }}
                    >
                        Submit for Approval
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInward);
