import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import CreateVendor from "./CreateVendor";
import VendorDetail from "./VendorDetail";
import VendorList from "./VendorList";
import "../styles/Vendor.css";
import EditVendor from "./EditVendor";
import TopBanner from "../SmallComponents/TopBanner";
import vendorIcon from "../../assets/vendor.svg";
import { connect } from "react-redux";
import { exportData } from "../CommonFunctions/ExportExcel";

const Vendor = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("vendorList");

    var createVendorButton = { buttonName: "Create Vendor", className: "employeeButton", setSection: setCurrentSection, sectionName: "createVendor" };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "vendorList" }];

    var IReportButton = { buttonName: "Export", className: "exportButton", setSection: async () => { await exportData(isLogged, "vendorDetails") }, sectionName: "createVendor" };

    const renderSection = (section) => {
        switch (section) {
            case "vendorList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={vendorIcon}
                            pageTitle="Vendor List"
                            buttonList={[isLogged.access.Vendor === "Create" || isLogged.access.Vendor === "Edit" ? createVendorButton : null, IReportButton]}
                        />
                        <VendorList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createVendor":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={vendorIcon} pageTitle="Create Vendor" buttonList={goBackButton} />
                        <CreateVendor setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "vendorDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={vendorIcon} pageTitle="Vendor Detail" buttonList={goBackButton} />
                        <VendorDetail setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editVendor":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={vendorIcon} pageTitle="Edit Vendor" buttonList={goBackButton} />
                        <EditVendor setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page yet to be created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Vendor" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Vendor);
