import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import FormElement from "../SmallComponents/FormElement";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import { editPoId, importPurchaseOrderEditId, importPurchaseOrderViewId, setPoTab, viewPoId } from "../../redux/PurchaseOrder/PoInfoActions";

const ImportPoList = ({
    setSection,
    poInfo,
    // setPurchaseOrderTab,
    storeInfo,
    setApproval,
    isLogged,
    addTokenToState,
    importPOViewId,
    editPOViewId,
    setEditAndApprove,
}) => {
    const [poList, setPoList] = useState([]);
    const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
    const [plantId,setPlantId] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getPoUnapproved();
    }

    const history = useHistory();
    async function checkToken() {
   
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }


    async function getPoUnapproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/import-po-list",
            headers: { token: isLogged.accessToken,module:"Import PO" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoList(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }
const access = isLogged.access["Import PO"];
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={poInfo.poTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    // onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                    {tab.id === "contactDetails" ? poInfo.poTab === "contactDetails" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const renderSubSection = () => {
        return (
            <div className="vendorListArea" style={{ margin: "2rem 0 0 0" }}>
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading">PO No</td>
                            <td className="vendorListHeading">Order Date</td>
                            <td className="vendorListHeading">Company Name</td>
                            <td className="vendorListHeading">Exp Delivery Date</td>
                            <td className="vendorListHeading">Plant</td>
                            <td className="vendorListHeading">Storage</td>
                            {/* <td className="vendorListHeading">Order Status</td> */}
                            <td className="vendorListHeading">Created By</td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{poList.length > 0 ? renderPoUnapprovedList() : null}</tbody>
                </table>
                {noUnapprovedRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        );
    };

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        listCopy = poList.slice();

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        setPoList(listCopy);
    };

    const renderPoUnapprovedList = () => {
        return poList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment key={item.poId}>
                    <tr className={item.collapse ? "vendorList" : "vendorListExpanded"} key={item.poId} onClick={() => editItemCollapse("poList", i)}>
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                        <td className="vendorListInfo">{item.plantName}</td>
                        <td className="vendorListInfo">{item.storageName}</td>
                        {/* <td className="vendorListInfo">{item.poStatus !== null ? item.poStatus : "-"}</td> */}
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            {/* <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={item.poStatus === "Draft" ? approveIconDisabled : approveIcon}
                                title={item.poStatus === "Draft" ? "Can't approve Draft" : "Approve Purchase Order"}
                                style={item.poStatus === "Draft" ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.poStatus !== "Draft") {
                                        console.log("Clicked");
                                        importPOViewId(item.poId);
                                        setSection("purchaseOrderDetail");
                                        setApproval(true);
                                    }
                                }}
                            /> */}
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // viewItem(item.item.itemId);
                                    importPOViewId(item.poId);
                                    setSection("importPoDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" ? editIcon : editIconDisabled}
                                title={access === "Edit" ? "Edit Item" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (access === "Edit") {
                                        editPOViewId(item.poId);
                                        setSection("editPurchaseOrder");
                                        setEditAndApprove(false);
                                    }
                                }}
                                style={access === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="unapprovedPo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
            else return null
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab === "approvedPo" ? "12" : "10"} className="vendorListInnerTableWrapper">
                {tab === "declinedPo" ? (
                    <div style={{ boxSizing: "border-box", width: "100%", padding: "10px", backgroundColor: "#eee" }}>
                        <span style={{ color: "red", fontWeight: "bold" }}>Reason:&nbsp;&nbsp;&nbsp;</span>
                        <b style={{ color: "#333333" }}> {reason}</b>
                    </div>
                ) : null}
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item</td>
                            {isLogged.showHideWeight ? <>                     <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td> </> : null}
                            <td className="vendorListInnerTableHeading">HSN No</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                            <td className="vendorListInnerTableHeading">Unit Price</td>
                            <td className="vendorListInnerTableHeading">Currency</td>
                            <td className="vendorListInnerTableHeading">Tax (%)</td>
                            <td className="vendorListInnerTableHeading">Net Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.poLineItemId}>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    {isLogged.showHideWeight ? <>                               <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </> : null}
                                    <td className="vendorListInnertableInfo">{row.hsnCode ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                    <td className="vendorListInnertableInfo">{row.unitPrice}</td>
                                    <td className="vendorListInnertableInfo">{row.currencyName}</td>
                                    <td className="vendorListInnertableInfo">{row.tax}</td>
                                    <td className="vendorListInnertableInfo">{row.netPrice}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div  style={{width:"250px",marginLeft:"25px"}}>
                <FormElement  setInput={(value)=>{setPlantId(value);}}
                                        inputType={"multiSelect"}
                                        value={plantId}
                                        hintText={"Plant Name"}
                                        options={isLogged.plantOptions}
                                        colSpan={1}
                                        rowSpan={1}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}/>
            </div>
            <div className="poListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
        storeInfo: state.storeInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        importPOViewId: (viewId) => dispatch(importPurchaseOrderViewId(viewId)),
        editPOViewId: (viewId) => dispatch(importPurchaseOrderEditId(viewId)),
        // setPurchaseOrderTab: (tab) => dispatch(setPoTab(tab)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportPoList);
