import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const CreateAgent = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch

    addTokenToState,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getClearingAgents();
    }

    const history = useHistory();

    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getClearingAgents() {
        var data = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/clearing-agent-list",
            headers: { token: isLogged.accessToken,module:"Clearing Agent" },
        });
        if (data.msg === "success") {
            setAgentList(data.asset);
        } else {
            // console.log(data);
        }
    }

    const [agentList, setAgentList] = useState([]);

    const [clearingAgent, setNewTransporter] = useState({
        clearingAgentName: "",
        description: "",
    });

    const renderCreateAgentInput = () => {
        return (
            <tr className="uomListData">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={clearingAgent.clearingAgentName}
                        onChange={(e) => setNewTransporter({ ...clearingAgent, clearingAgentName: e.target.value })}
                        autoFocus
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                </td>
                <td className="uomSymbolData3">
                    <input
                        className="createUom2"
                        value={clearingAgent.description}
                        onChange={(e) => setNewTransporter({ ...clearingAgent, description: e.target.value })}
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                    <button
                        className="cancelRoleButton"
                        onClick={() => {
                            setNewTransporter({ clearingAgentName: "", description: "" });
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if (clearingAgent.clearingAgentName.trim() !== "" && clearingAgent.description.trim() !== "") {
                                // axios call to add new role and refresh display
                                setIsOpen(true);
                                createAgentData();
                            } else {
                                window.alert("Make sure Clearing Agent name and Description is provided");
                            }
                        }}
                    >
                        Add
                    </button>
                </td>
            </tr>
        );
    };

    async function createAgentData() {
        setDataSubmitted(false);
        var dataSent = await fetchData({
            requestingPage: "createUom",
            method: "post",
            url: "create/clearing-agent",
            headers: { token: isLogged.accessToken ,module:"Clearing Agent" },
            data: clearingAgent,
        });

        setDataSubmitted(true);
        if (dataSent.msg === "success") {
            setModalText("Created new clearing agent successfully!");
            setNewTransporter({ clearingAgentName: "", description: "" });
            getInformation();
        } else {
            setModalText(dataSent.desc);
        }
    }

    const renderAgentList = (list) => {
        return list.map((row) => {
            return (
                <tr className="uomListData" key={row.transporterId}>
                    <td className="uomSymbolData">{row.clearingAgentName}</td>
                    <td className="uomSymbolData">{row.description}</td>
                </tr>
            );
        });
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Creating clearingAgent. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Creating clearingAgent. Please wait...");
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="uomListArea">
                <table className="uomListTable">
                    {/* <thead> */}
                    <tr className="uomListHeader">
                        <td className="uomSymbolHeader">Clearing Agent</td>
                        <td className="uomSymbolHeader">Description</td>
                    </tr>

                    {renderCreateAgentInput()}
                    {renderAgentList(agentList)}
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgent);
