import React, { useState } from 'react';
import { Fragment } from 'react';
import MenuList from '../../Menu/MenuList';
import PageTitle from '../../SmallComponents/PageTitle';
import TopBanner from '../../SmallComponents/TopBanner';
import loadingSlipIcon from "assets/loading-slip.svg";
import { connect } from 'react-redux';
import CreatePackingSlip from '../PackingSlip/CreatePackingSlip';
import PackingSlipList from "../PackingSlip/PackingSlipList"
import EditPackingSlip from '../PackingSlip/EditPackingSlip';
import { useHistory } from 'react-router-dom';
import PackingSlipDetail from '../PackingSlip/packingSlipDetail';
import { exportPDF } from 'components/CommonFunctions/ExportPDF';

const LoadingSlip = ({isLogged}) => {
  const history = useHistory();
  const [currentSection, setCurrentSection] = useState("loadingSlipList");
  const [downloadReportParams,setDownloadReportParams]=useState({});

  var goBackButton={className:"goBackButton",buttonName: "Back",setSection:setCurrentSection,sectionName:"loadingSlipList"};

  var createLoadingSlip={className:"employeeButton",buttonName:"Create Loading Slip",setSection:setCurrentSection,sectionName:"createLoadingSlip"};
  const pushBack = (section) => {
    history.push(section);
};
  var goBackToPackagingButton = {
    buttonName: "Back",
    className: "goBackButton",
    setSection: pushBack,
    sectionName: "/packaging",
};
var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "packingSlip",downloadReportParams) }, sectionName: "loadingSlipList" };

  const renderSection = (section) => {
    switch(section){
      case "createLoadingSlip":
        return (
          <React.Fragment>
              <PageTitle imgSrc={loadingSlipIcon} pageTitle="Create Loading Slip" buttonList={[goBackButton]} />
              <CreatePackingSlip setSection={setCurrentSection} screenName="Loading" />
          </React.Fragment>
      );

      case "editLoadingSlip":
        return (
          <React.Fragment>
              <PageTitle imgSrc={loadingSlipIcon} pageTitle="Edit Loading Slip" buttonList={[goBackButton]} />
              <EditPackingSlip setSection={setCurrentSection} screenName="Loading" />
          </React.Fragment>
        );

      case "loadingSlipList":
        return (
          <React.Fragment>
              <PageTitle imgSrc={loadingSlipIcon} pageTitle="Loading Slip"
              
                  buttonList={isLogged.access["Loading Slip"] === "Create" || isLogged.access["Loading Slip"] === "Edit" || isLogged.access["Loading Slip"] === "App/Dec" ? [createLoadingSlip,goBackToPackagingButton] : [goBackToPackagingButton]} />
              <PackingSlipList
               screenName="Loading"
                  setSection={(value) => setCurrentSection(value)}
              />
          </React.Fragment>
      );

      case "loadingSlipDetail":
        return (
          <React.Fragment>
              <PageTitle imgSrc={loadingSlipIcon} pageTitle="Loading Slip Detail" buttonList={[goBackButton,downloadButton]} />
              <PackingSlipDetail setSection={setCurrentSection} setDownloadReportParams={setDownloadReportParams} screenName="Loading"/>
          </React.Fragment>
        )
      default:
        return <></>
    }
  }

  return (
    <Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Store" selectedSubMenu="Packaging" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </Fragment>

  )
};

const mapStateToProps = (state) => ({
  isLogged: state.isLogged
})


export default connect(mapStateToProps)(LoadingSlip);