import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import { viewIdnId,editIdnId } from "../../redux/Store/StoreInfoActions";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import FormElement from "../SmallComponents/FormElement";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";

const IdnList =({ setList,setSection,isLogged,storeInfo,addTokenToState,viewIdnId,editIdnId}) => {

    const [loading, setLoading] = useState(true);
    const [noIdnList, setNoIdnList] = useState(false);
    const [idnList, setIdnList] = useState([]);
    const [plantId,setPlantId] = useState('');
    const [currentPage,setCurrentPage] = useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const idnListCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return idnList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,idnList,PageSize]);
      useEffect(() => {
        getInformation();
     }, [plantId]);

     async function getInformation() {
        await checkToken();
        await geIdnList();
     }

     const history = useHistory();
    
     async function checkToken() {
     
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
     }

     async function geIdnList() {
        var data = await fetchData({
            requestingPage: "MRSList",
            method: "post",
            url: "idn-fetch/request-list",
            headers: { token: isLogged.accessToken,module:"IDN" },
            data:{plantId}
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoIdnList(true);
            }

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            var opList=data2.filter(i=>i.idnStatus!=="Completed").map(item=>item.mrsNo);
            setList(opList);
            setIdnList(data2);
        }
        setLoading(false);
     }
     const access = isLogged.access["IDN"];

     const editItemCollapse = ( index) => {
        var listCopy = idnList.slice();

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });
        setIdnList(listCopy);
     };

     const renderList = ()=>{
         return idnListCurrentPage.map((item,i)=>{
            var idncolor="";
            switch(item.idnStatus){
                case "Pending":
                    idncolor="red";
                    break;
                case "Completed":
                    idncolor= "#14c76a";
                    break;
                default:
                    idncolor="black";
            }
            const isEditable= item.idnStatus !== "Completed"; 
            return (
                <React.Fragment>
                    <tr className="vendorList"
                        key={item.requestNo}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse(i);
                        }}>

                        <td className="vendorListInfo stickyFirstColumn">{item.requestNo}</td>
                        <td className="vendorListInfo">{item.idnDate}</td>
                        <td className="vendorListInfo">{item.editedDate}</td>
                        <td className="vendorListInfo">{item.mrsNo}</td>
                        <td className="vendorListInfo">{item.mrsReqDate}</td>
                        <td className="vendorListInfo">{item.mrsExpectDate}</td>
                        <td className="vendorListInfo" style={{ color:idncolor}}>{item.idnStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.editedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View IDN Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // setApproval(false);
                                    viewIdnId(item.requestId);
                                    setSection("IdnDetail");
                                }}
                            />
                             <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={(isEditable && access === "Edit") || isLogged.employeeId == "admin"? editIcon : editIconDisabled}
                                onClick={(e) => {
                                            e.stopPropagation(); 
                                            if((isEditable && access === "Edit") || isLogged.employeeId == "admin" ) { 
                                            setSection("IdnEdit"); 
                                            editIdnId(item.requestId);}}}
                                style={(isEditable && access  === "Edit" )|| isLogged.employeeId == "admin" ? { cursor: "pointer" }: { cursor: "not-allowed" }}
                                title={access === "Edit" ? (isEditable || isLogged.employeeId == "admin" ? "Edit IDN Qty/Status " : "Already Completed") : "Access Denied" }
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse(i);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>{!item.collapse ? <RenderSubTable  tab="approvedMrs" data={item.lineItems} /> : null} </tr>
                </React.Fragment>
            );
         });
        }
        
        const RenderSubTable = ({ data }) => {
            return (
                <td colSpan={"11"} className="vendorListInnerTableWrapper" >
                    <table className="vendorListInnerTable">
                        <thead >
                            <tr className="vendorListInnerTableHeader" >
                                <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
                                <td className="vendorListInnerTableHeading">Item Code</td>
                            {isLogged.showHideWeight ? <>  <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                                <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                                <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                                <td className="vendorListInnerTableHeading">Gross Weight(gms)</td> </> : null}
                                <td className="vendorListInnerTableHeading">HSN Code</td>
                                <td className="vendorListInnerTableHeading">UOM</td>
                                <td className="vendorListInnerTableHeading">Requested Qty</td>
                                <td className="vendorListInnerTableHeading">IDN Qty</td> 
                            </tr>
                        </thead>
                        <tbody >
                            {data.map((row) => {
                                return (
                                    <tr className="vendorListInnerTable" key={row.requestLineItemId} >
                                        <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                                        <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                        {isLogged.showHideWeight ? <> <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                        <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                        <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                        <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </> : null}
                                        <td className="vendorListInnertableInfo">{row.hsnCode  ? row.hsnCode : "-"}</td>
                                        <td className="vendorListInnertableInfo">{row.uom}</td>
                                        <td className="vendorListInnertableInfo">{row.mrsQuantity}</td>
                                         <td className="vendorListInnertableInfo">{row.idnQuantity}</td> 
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </td>
            );
        };
        const [order, setOrder] = useState({ col: "requestNo", state: true, data: "" });
        return (
            <React.Fragment key={1}>
                    <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                        <span className="listPageParams">
                            <FormElement setInput={(value) => { setPlantId(value); }}
                                inputType={"multiSelect"}
                                value={plantId}
                                hintText={"Plant Name"}
                                options={isLogged.plantOptions}
                                colSpan={1}
                                rowSpan={1}
                                mandatory={false}
                                error={false}
                                errorMessage=""
                                disabled={false} />
                        </span>
                        <span style={{ flexGrow: 0, width: "80px" }}>
                            <FormElement
                                setInput={(value) => {
                                    setRowsPerPage(value);
                                }}
                                inputType={"number"}
                                value={PageSize}
                                hintText={"Rows Per Page"}
                                colSpan={0.5}
                                rowSpan={1}
                                mandatory={false}
                                error={false}
                                errorMessage=""
                                disabled={false}
                            />
                        </span>
                    </div>
                    <div  className="vendorListArea"> 
                    { <React.Fragment>
                            <table className="vendorListTable" >
                                <thead >
                                <tr className="vendorListHeader" >
                                    <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                                        setOrder({
                                            col: "requestNo",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "requestNo",
                                            state: order.state,
                                        });
                                    }}
                                    >Request No<img
                                            className="sortArrow"
                                            src={
                                                order.col === "requestNo"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "idnDate",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "idnDate",
                                            state: order.state,
                                        });
                                    }}
                                    >IDN Date<img
                                            className="sortArrow"
                                            src={
                                                order.col === "idnDate"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "editedDate",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "editedDate",
                                            state: order.state,
                                        });
                                    }}
                                    >Edited Date<img
                                            className="sortArrow"
                                            src={
                                                order.col === "editedDate"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "mrsNo",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "mrsNo",
                                            state: order.state,
                                        });
                                    }}
                                    >MRS No<img
                                            className="sortArrow"
                                            src={
                                                order.col === "mrsNo"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "mrsReqDate",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "mrsReqDate",
                                            state: order.state,
                                        });
                                    }}
                                    >MRS Request Date<img
                                            className="sortArrow"
                                            src={
                                                order.col === "mrsReqDate"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "mrsExpectDate",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "mrsExpectDate",
                                            state: order.state,
                                        });
                                    }}
                                    >Expected Date<img
                                            className="sortArrow"
                                            src={
                                                order.col === "mrsExpectDate"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "idnStatus",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "idnStatus",
                                            state: order.state,
                                        });
                                    }}
                                    >IDN Status<img
                                            className="sortArrow"
                                            src={
                                                order.col === "idnStatus"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "createdBy",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "createdBy",
                                            state: order.state,
                                        });
                                    }}
                                    >Created by<img
                                            className="sortArrow"
                                            src={
                                                order.col === "createdBy"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "editedBy",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: idnListCurrentPage,
                                            col: "editedBy",
                                            state: order.state,
                                        });
                                    }}
                                    >Edited by<img
                                            className="sortArrow"
                                            src={
                                                order.col === "editedBy"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                        <td className="vendorListHeadingActions">Actions</td>
                                    </tr>
                                </thead>
                                <tbody >
                                    {idnList.length > 0 ? renderList() : null}
                                </tbody>
                            </table>
                            {noIdnList ? (
                                <div className="noRecordsYet">No items available</div>
                            ) : loading ? (
                                <div className="loader" style={{ margin: "1rem auto" }}></div>
                            ) : null}
                        </React.Fragment> }
                </div>
                <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={idnList.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />          
            </React.Fragment>
        );
    
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
        storeInfo: state.storeInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewIdnId: (IdnId) => dispatch(viewIdnId(IdnId)),
        editIdnId: (IdnId)=> dispatch(editIdnId(IdnId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdnList);