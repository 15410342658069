import update from "immutability-helper";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../../assets/delete.svg";
import editIcon from "../../../assets/edit.svg";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import FormElement from "../../SmallComponents/FormElement";

const itemCategoryParamList = [
    // general info
    ["categoryName", "description", "status"],

    // sub category details
    ["subCategoryName", "description", "status"],
];

const CreateItemCategory = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
    existingList
}) => {
    const [itemCategoryGeneralInfo, setItemCategoryGeneralInfo] = useState({
        categoryName: {
            inputType: "text",
            value: "",
            hintText: "Item Main Category",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Please enter Item Category Name",
        },
        description: {
            inputType: "text",
            value: "",
            hintText: "Item Description",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Item Description",
        },
        status: {
            inputType: "options",
            value: "Active",
            hintText: "Status",
            mandatory: false,
            colSpan: 5,
            options: [
                { optionId: "Active", optionName: "Active" },
                { optionId: "Inactive", optionName: "Inactive" },
            ],
            error: false,
            errorMessage: "Select status",
        },
    });

    const [itemSubCategory, setItemSubCategory] = useState({
        subCategoryName: {
            inputType: "text",
            value: "",
            hintText: "Item Sub Category",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Please enter Sub Category Name",
        },
        description: {
            inputType: "text",
            value: "",
            hintText: "Item Description",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Item Description",
        },
        status: {
            inputType: "options",
            value: "Active",
            hintText: "Status",
            mandatory: false,
            colSpan: 3,
            options: [
                { optionId: "Active", optionName: "Active" },
                { optionId: "Inactive", optionName: "Inactive" },
            ],
            error: false,
            errorMessage: "Select status",
        },
    });

    const [itemSubCategoryList, setitemSubCategoryList] = useState([]);

    const updateItemCategoryparamater = ({ paramName, section, key, value }) => {
        switch (section) {
            case "generalInfo":
                var itemCategoryInfo = Object.assign(itemCategoryGeneralInfo);
                itemCategoryInfo = update(itemCategoryInfo, { [paramName]: { [key]: { $set: value } } });
                setItemCategoryGeneralInfo(itemCategoryInfo);
                break;

            case "subCategory":
                var itemCategoryInfo2 = Object.assign(itemSubCategory);
                itemCategoryInfo2 = update(itemCategoryInfo2, { [paramName]: { [key]: { $set: value } } });
                setItemSubCategory(itemCategoryInfo2);
                break;

            default:
                break;
        }
    };

    const [subCategoryError, setSubCategoryError] = useState([]);
    const checkSubCategoryError = () => {
        var errorList = [];
        var itemSubCategoryCopy = Object.assign(itemSubCategory);

        var subCategoryNames = [];
        if (itemSubCategoryList.length > 0) {
            itemSubCategoryList.forEach((subCategory) => {
                subCategoryNames.push(subCategory.subCategoryName);
            });
        }

        itemCategoryParamList[1].forEach((item) => {
            if (itemSubCategoryCopy[item].mandatory) {
                itemSubCategoryCopy = update(itemSubCategoryCopy, {
                    [item]: { error: { $set: !validateMandatory(itemSubCategoryCopy[item].value) } },
                });
            }

            if (item === "subCategoryName" && subCategoryNames.includes(itemSubCategoryCopy[item].value.trim())) {
                itemSubCategoryCopy[item].error = true;
                errorList.push("Subcategory Name Already Exists");
            }

            if (itemSubCategoryCopy[item].error) {
                errorList.push(itemSubCategoryCopy[item].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newSubCategory = {};
            itemCategoryParamList[1].forEach((item) => {
                newSubCategory[item] = itemSubCategoryCopy[item].value;

                if (item !== "status") {
                    itemSubCategoryCopy[item].value = "";
                } else {
                    itemSubCategoryCopy[item].value = "Active";
                }
            });

            setitemSubCategoryList([...itemSubCategoryList, newSubCategory]);

            setSubCategoryError(errorList);
            setItemSubCategory(itemSubCategoryCopy);
        } else {
            setSubCategoryError(errorList);
            setItemSubCategory(itemSubCategoryCopy);
        }
    };

    const [categoryErrors, setCategoryErrors] = useState([]);
    const checkErrors = () => {
        var errorList = [];
        var categoryCopy = Object.assign(itemCategoryGeneralInfo);

        itemCategoryParamList[0].forEach((item) => {
            if (categoryCopy[item].mandatory) {
                categoryCopy = update(categoryCopy, {
                    [item]: { error: { $set: !validateMandatory(categoryCopy[item].value) } },
                });
            }

            if (categoryCopy[item].error) {
                errorList.push(categoryCopy[item].errorMessage);
            }

        });
        setItemCategoryGeneralInfo(categoryCopy);
        let isCategoryExists = existingList.findIndex((category)=>{
        return category.category.categoryName.toLowerCase().trim() == 
        itemCategoryGeneralInfo['categoryName'].value.toLowerCase().trim()
    })
        if(isCategoryExists!=-1){
            errorList.push('Item Category Name Already Exists')
        }
        setCategoryErrors(errorList);
        var subCategoryAllClear = true;
        if (errorList.length === 0) {
            if (itemSubCategoryList.length > 0) {
                itemCategoryParamList[1].forEach((item) => {
                    if (item !== "status") {
                        if (itemSubCategory[item].value !== "") {
                            subCategoryAllClear = false;
                            setSubCategoryError(["Unsaved information found"]);
                        }
                    }
                });
            } else {
                subCategoryAllClear = false;
                setSubCategoryError(["Enter atleast one subcategory information"]);
            }
            if (subCategoryAllClear) {
                var data = {};
                itemCategoryParamList[0].forEach((item) => {
                    data[item] = itemCategoryGeneralInfo[item].value;
                });
                data.subCategory = itemSubCategoryList;

                setIsOpen(true);
                submitData(data);
            }
        }
    };

    const [categoryServerError, setCategoryServerError] = useState([]);
    async function submitData(data) {
        setDataSubmitted(false);
        await checkToken();

        var result = await fetchData({
            requestingPage: "createItemCategory",
            method: "post",
            url: "create/category",
            headers: { token: isLogged.accessToken,module:"Item Category" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully created Item Category!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setCategoryServerError([result.desc]);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        if (categoryServerError.length === 0) setSection("itemCategoryList");
        setModalText("Uploading form. Please wait...");
    };

    const history = useHistory();
    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    const editSubSectionItem = (item, action) => {
        switch (action) {
            case "edit":
                var subCategoryAllClear = true;
                itemCategoryParamList[1].forEach((item) => {
                    if (item !== "status") {
                        if (itemSubCategory[item].value !== "") {
                            subCategoryAllClear = false;
                        }
                    }
                });

                if (!subCategoryAllClear) {
                    var yes = window.confirm("Unsaved data found. Do you want to overwrite it?");
                }

                if (yes || subCategoryAllClear) {
                    var subCategory = Object.assign(itemSubCategory);
                    var subCategoryListCopy = itemSubCategoryList.slice();

                    var editObj = subCategoryListCopy.splice(item, 1)[0];
                    Object.keys(editObj).forEach((element) => {
                        subCategory[element].value = editObj[element];
                    });

                    setitemSubCategoryList(subCategoryListCopy);
                    setItemSubCategory(subCategory);
                }

                break;

            case "delete":
                var subCategoryListCopy2 = itemSubCategoryList.slice();
                subCategoryListCopy2.splice(item, 1);
                setitemSubCategoryList(subCategoryListCopy2);
                break;

            default:
                break;
        }
    };

    const renderErrorMessage = () => {
        // if (plantErrors.length > 0) return `Plant Info Section : ${plantErrors[0]}`;
        // else
        if (categoryErrors.length > 0) return `Category : ${categoryErrors[0]}`;
        else if (subCategoryError.length > 0) return `Sub-Category : ${subCategoryError[0]}`;
        else if (categoryServerError.length > 0) return `Error : ${categoryServerError[0]}`;
        else return null;
    };

    const renderFormElements = ({ elementList, section }) => {
        return elementList.map((item) => {
            switch (section) {
                case "generalInfo":
                    return (
                        <FormElement
                            key={item}
                            inputType={itemCategoryGeneralInfo[item].inputType}
                            value={itemCategoryGeneralInfo[item].value}
                            setInput={(value) => {
                                updateItemCategoryparamater({ section, paramName: item, key: "value", value: value });
                            }}
                            hintText={itemCategoryGeneralInfo[item].hintText}
                            mandatory={itemCategoryGeneralInfo[item].mandatory}
                            colSpan={itemCategoryGeneralInfo[item].colSpan}
                            options={itemCategoryGeneralInfo[item].inputType === "options" ? itemCategoryGeneralInfo[item].options : null}
                            error={itemCategoryGeneralInfo[item].error}
                        />
                    );

                case "subCategory":
                    return (
                        <FormElement
                            key={item}
                            inputType={itemSubCategory[item].inputType}
                            value={itemSubCategory[item].value}
                            setInput={(value) => {
                                updateItemCategoryparamater({ section, paramName: item, key: "value", value: value });
                            }}
                            hintText={itemSubCategory[item].hintText}
                            mandatory={itemSubCategory[item].mandatory}
                            colSpan={itemSubCategory[item].colSpan}
                            options={itemSubCategory[item].inputType === "options" ? itemSubCategory[item].options : null}
                            error={itemSubCategory[item].error}
                        />
                    );

                default:
                    return null;
            }
        });
    };

    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Item Sub Category</td>
                        <td>Description</td>
                        <td>Status</td>
                        <td className="itemIndiTabActions">Actions</td>
                    </tr>
                </thead>
                {itemSubCategoryList.length > 0 ? (
                    <tbody>
                        <RenderTableRows rows={itemSubCategoryList} />
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows">
                            {itemCategoryParamList[1].map((item) => {
                                return <td key={item}>&nbsp;</td>;
                            })}
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const RenderTableRows = ({ rows }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {Object.keys(row).map((key, i) => {
                        return <td key={i}> {row[key]}</td>;
                    })}
                    {listRecordActions(j)}
                </tr>
            );
        });
    };

    var listRecordActions = (j) => {
        return (
            <td className="itemIndiTabActions">
                <img
                    alt="Edit record"
                    className="createVendorContactsAction"
                    src={editIcon}
                    onClick={() => {
                        editSubSectionItem(j, "edit");
                    }}
                />
                <img
                    alt="Delete record"
                    className="createVendorContactsAction"
                    src={deleteIcon}
                    onClick={() => {
                        var reset = window.confirm("Delete record?");
                        if (reset) editSubSectionItem(j, "delete");
                    }}
                />
            </td>
        );
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        overflow: "hidden",
                    }}
                >
                    <div className="createItemCategoryGrid">
                        {renderFormElements({ elementList: itemCategoryParamList[0], section: "generalInfo" })}
                    </div>
                    <div className="createVendorTabArea">
                        &nbsp;
                        <div className="plantsSubTitle">Sub Category :</div>
                        <div className="createItemIndiTabArea">
                            {renderFormElements({ elementList: itemCategoryParamList[1], section: "subCategory" })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    // checkPlantStorageError();
                                    checkSubCategoryError();
                                }}
                            />
                        </div>
                        <RenderTable />
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var emptyCategoryDetail = Object.assign(itemCategoryGeneralInfo);
                                itemCategoryParamList[0].forEach((item) => {
                                    if (item !== "status") {
                                        emptyCategoryDetail[item].value = "";
                                    } else {
                                        emptyCategoryDetail[item].value = "Active";
                                    }
                                    emptyCategoryDetail[item].error = false;
                                });
                                setItemCategoryGeneralInfo(emptyCategoryDetail);
                                setCategoryErrors([]);

                                var emptySubCategory = Object.assign(itemSubCategory);
                                itemCategoryParamList[1].forEach((item) => {
                                    if (item !== "status") {
                                        emptySubCategory[item].value = "";
                                    } else {
                                        emptySubCategory[item].value = "Active";
                                    }
                                    emptySubCategory[item].error = false;
                                });
                                setItemSubCategory(emptySubCategory);
                                setSubCategoryError([]);

                                setitemSubCategoryList([]);
                            }
                        }}
                    >
                        Reset Fields
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            setCategoryErrors([]);
                            setSubCategoryError([]);
                            setCategoryServerError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateItemCategory);
