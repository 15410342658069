import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import dashboard from "../../assets/dashboard.svg";
import CreateLocation from "./createLocation";
import LocationList from "./locationList";
const Location = () => {
  const [currentSection, setCurrentSection] = useState("locationList");

  var createLocation = {
    buttonName: "Create Location",
    className: "employeeButton",
    setSection: setCurrentSection,
    sectionName: "createLocation",
  };

  var goBackButton = [
    {
      buttonName: "Back",
      className: "goBackButton",
      setSection: setCurrentSection,
      sectionName: "locationList",
    },
  ];

  const renderSection = (section) => {
    switch (section) {
      case "locationList":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={dashboard}
              pageTitle={"Location List"}
              buttonList={[createLocation]}
            />
            <LocationList />
          </React.Fragment>
        );

      case "createLocation":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={dashboard}
              pageTitle={"Create Location"}
              buttonList={goBackButton}
            />
            <CreateLocation setSection={(value) => setCurrentSection(value)} />
          </React.Fragment>
        );
    }
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Master Configure" selectedSubMenu="Location" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Location;
