import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import "../styles/Dashboard.css";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

const StoreDashboard = ({ isLogged, addTokenToState }) => {
  const history = useHistory();
  const [barData, setBarData] = useState([]);
  const [noRecords, setNoRecords] = useState(false);
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState({
    plantId: {
      inputType: "options",
      value: "",
      options: isLogged.plantOptions,
      hintText: "Plant Name",
      mandatory: false,
      error: false,
    },
    storageId: {
      inputType: "options",
      value: "",
      hintText: "Store",
      mandatory: false,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "",
    },
    items:{
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Items",
      mandatory: false,
      error: false,
    },
  });


  useEffect(() => {
    getInformation();
    // getSummaryInventory();
  }, []);

  const [storageLocList, setStorageLocList] = useState({});

  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "itemList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Purchase Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.plantId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

    }
    // setPlantList(plantList);
    return [plantList, storageLocList];
  }

  async function getInformation() {
    await checkToken();
    let [itemList] = await getItems();
    var [plantList,storageLocListFormatted] = await getPlants();
    
    // let countryOptions = await getDim({ field: "country" }, "country");
    let queryParamsCopy = Object.assign(queryParams);
    queryParamsCopy = update(queryParamsCopy, {
      items: { options: { $set: itemList } },
    });
    setQueryParams(queryParamsCopy);
    setStorageLocList(storageLocListFormatted);
  }

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{itemsTab:"Active"},
      headers: { token: isLogged.accessToken, module: "Purchase Order" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemList.push(itemObj);
      });

    }
    return [itemList];
  }

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  const getSummaryInventory = async(dataToServer)=>{
    setLoading(true);
    let data = await fetchData({
        requestingPage: "createPlant",
        method: "post",
        url: "store-fetch/summaryInventoryList2",
        data:dataToServer,
        headers: { token: isLogged.accessToken, module: "Store Dashboard" },
    });
    if (data.msg === "success") {
      if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
            let dataList = data.asset
      setBarData(dataList)
    } else {
        //console.log(data);
    }
    setLoading(false);
}

  useEffect(() => {

    var queryParamsCopy = Object.assign(queryParams);

    if (queryParams.plantId.value !== "") {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: {
          options: { $set: storageLocList[queryParams.plantId.value]||[] },
        },
      });
    } else {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: { options: { $set: [] }, value: { $set: "" } },
      });
    }
    setQueryParams(queryParamsCopy);
  }, [queryParams.plantId.value]);

  useEffect(() => {
    let data = {
      plantId:queryParams.plantId.value,
      storageId:queryParams.storageId.value,
      itemList:queryParams.items.value.map(item=>item.value)
    }
    getSummaryInventory(data);
  }, [queryParams]);



  const updateSaleOrderParameters = ({ param, paramName, key, value }) => {
    let paramsCopy = Object.assign(param);
    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });
    setQueryParams(paramsCopy);
  };

  const renderFormElements = ({ elementList, params,styles={} }) => {
    return (
      <div className="dashboardCard dashboardParams" style={styles}>
        {elementList.map((item,ind) => {
          return (
            <div key={ind}>
              <FormElement
                key={item}
                inputType={params[item].inputType}
                value={params[item].value}
                setInput={(value) => {
                  updateSaleOrderParameters({
                    param: params,
                    paramName: item,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={params[item].hintText}
                mandatory={params[item].mandatory}
                colSpan={params[item].colSpan}
                options={
                  params[item].inputType === "options" ||
                  params[item].inputType === "multiSelect"
                    ? params[item].options
                    : null
                }
                error={params[item].error}
                isClearable={params[item].isClearable}
                maxdate={params[item].maxdate}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const theme = {
    axis: {
    /*  ticks:{
        text:{
          fontSize:"3px"
      },
    },*/
      domain: {
        line: {
          stroke: "rgb(11,87,180)",
          strokeWidth: 2,
        },
      },
    },
  };
   const renderBarGraph = (data) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "500px",
          width: "100vw",
          gridColumn: `1/span 1`,
          alignSelf: 'start',
          gridRow: `auto / span 2`,
          padding: "2rem",
        }}>
          {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
        <div className="dashboardGraphBody">
    <ResponsiveBar
        data={data}
        keys={[
            "Grand Total"
        ]}
        indexBy="Item Code"
        margin={{ top: 50, right: 0, bottom: 50, left: 65 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{
          type: 'symlog'
        }}
        // valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={["rgb(11,87,180)"]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        theme={theme}
        tooltip={({ value, indexValue }) => {
                      return (
                        <div className="dashBoardToolTip">
                          {indexValue}
                          <br />
                          {value}
                          <br />
                        </div>
                      );
                    }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        // axisBottom={{
        //               tickSize: 0.0,
        //               tickPadding: 3,
        //               tickRotation: -90,
        //               legend: "",
        //               legendPosition: "",
        //               legendOffset: 52,
        //               labelSkipWidth:12,
        //               labelSkipHeight:0
        //             }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Quantity',
            legendPosition: 'middle',
            legendOffset: -40,
            format: (value) => `${Number(value)/ 1000} `,
        }}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[]}
        animate={false}
        role="application"
        ariaLabel="Nivo bar chart demo"
        labelSkipWidth={12}
        labelSkipHeight={0}
        label={(d) => null}
        layers={["axes", "bars", "markers", "legends"]}
    />
    </div>
    </div>
)}

  const renderSubSection = () => {
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: ['plantId','storageId'], 
              params: queryParams,
            })}
            {renderFormElements({
              elementList: ['items'], 

              params: queryParams, 
              styles: {gridRow: '2/2'}
            })}
            
              {renderBarGraph(
                barData,
                // "Item Code",
                // ["total"],
                // "Inventory"
              )}

            </div>
          </>
        );
  };
  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Store" selectedSubMenu="Store Dashboard" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Store Dashboard" 
          // buttonList={selectedTab==="Purchase Invoice"?[IReportButton]:null}
          />
          <span
            className="hintText"
            style={{ color: "GrayText", fontSize: "13px",marginLeft:"40px",marginBottom:"0px" }}>
            *Dashboard data is mainly based on Approved Orders
          </span>
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreDashboard);
