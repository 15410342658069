import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import SearchableDropDown from "../SmallComponents/SearchableDropDown";

const CreateRole = ({
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getRoles();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getRoles() {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/role", headers: { token: isLogged.accessToken,module: "Role" } });
        // // console.log(data);
        if (data.msg === "success") {
            setRoleList(data.asset);
        } else {
            // console.log(data);
        }
    }

    async function addRole() {
        var dataSent = {
            requestingPage: "createRole",
            method: "post",
            url: "create/role",
            headers: { token: isLogged.accessToken,module: "Role" },
            data: {
                role: newRole,
            },
        };

        var data = await fetchData(dataSent);
        if (data.msg === "success") {
            setDataSubmitted(true);
            setModalText("Created New Role Successfully");
            setNewRole("");
            getInformation();
        } else {
            setDataSubmitted(true);
            setModalText(data.desc);
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Creating Role. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Creating Role. Please wait...");
        setDataSubmitted(false);
    };

    const [roleList, setRoleList] = useState([]);

    const renderRoleList = () => {
        return roleList.map((role) => {
            return (
                <tr className="roleList" key={role.role_id}>
                    <td className="roleName" style={{columnSpan:2}}>
                        <span>{role.role}</span>
                    </td>
                </tr>
            );
        });
    };

    const [newRole, setNewRole] = useState("");

    return (
        <div className="rolePagesContainer">
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="roleListArea">
                <table className="roleListTable">
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Role Name</span>
                        </td>
                    </tr>
                    <tr className="createRole" style={{lineHeight:"20px"}}>
                        <td className="roleName">
                        <input 
                        style={{ width: "140px !important" }}
                             className="createRoleInput"
                            value={newRole}
                            onChange={(e) => {setNewRole(e.target.value)}}
                            autoFocus
                            onKeyUp={(e) => {
                                e.preventDefault();
                                if (e.key === "Enter" || e.keyCode === 13) {
                                    var submit = document.querySelector("#SubmitCreate");
                                    submit.click();
                                }
                            }}
                        />
                        <button
                            className="cancelRoleButton"
                            onClick={() => {
                                setNewRole("");
                            }}
                        >
                            Cancel
                        </button>
                        
                        <button
                            id="SubmitCreate"
                            className="createRoleButton"
                            onClick={() => {
                                if (newRole.trim() !== "") {
                                    // axios call to add new role and refresh dispaly
                                    setIsOpen(true);
                                    addRole();
                                }
                            }}
                        >
                            Add
                        </button>
                        </td>
                    </tr>

                    {renderRoleList()}
                </table>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);
