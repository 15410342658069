import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { editPlantAction } from "../../redux/Item/ItemInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import viewIcon from  '../../assets/view.svg';

const PlantList = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    editPlant,
    addTokenToState,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getPlants();
    }

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [plantList, setPlantList] = useState([]);
    const history = useHistory();

    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPlants() {
        var data = await fetchData({
            requestingPage: "plantList",
            method: "get",
            url: "fetch/plants",
            headers: { token: isLogged.accessToken,module:"Plant & Store" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPlantList(data2);

            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }

        setLoading(false);
    }

    const editPlantCollapse = (index) => {
        var plantListCopy = plantList.slice();
        plantListCopy.forEach((plant, j) => {
            if (j === index) {
                plantListCopy[j].collapse = !plant.collapse;
            } else {
                plantListCopy[j].collapse = true;
            }
        });
        setPlantList(plantListCopy);
    };

    const renderplantList = () => {
        return plantList.map((plant, i) => {
            return (
                <React.Fragment key={i}>
                    <tr
                        className={plant.collapse ? "vendorList" : "vendorListExpanded"}
                        key={plant.plant.customerId}
                        onClick={() => editPlantCollapse(i)}
                    >
                        <td className="vendorListInfo">{plant.plant.plantCode}</td>
                        <td className="vendorListInfo">{plant.plant.plantName}</td>
                        <td className="vendorListAddress">
                            {plant.plant.city}
                            {plant.plant.zipCode !== null && plant.plant.zipCode.length > 0 ? ` - ${plant.plant.zipCode},` : ","}
                            <br /> {plant.plant.state}, {plant.plant.country}.
                        </td>
                        <td className="vendorListInfo">
                            {plant.plant.gstNo}
                            {plant.plant.gstRegisterType !== null && plant.plant.gstRegisterType.length > 0
                                ? ` (${plant.plant.gstRegisterType})`
                                : null}
                        </td>
                        <td className="vendorListInfo">{plant.storage.length}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={isLogged.access["Plant & Store"] === "Edit" ? editIcon : editIconDisabled}
                                title={isLogged.access["Plant & Store"] === "Edit" ? "Edit Plant & Store" : "No Edit Access"}
                                onClick={(e) => {
                                    if (isLogged.access["Plant & Store"] === "Edit") {
                                        e.stopPropagation();
                                        editPlant(plant.plant.plantId);
                                        // editCustomerId(plant.plant.customerId);
                                        setSection("editPlant");
                                    }
                                }}
                                style={isLogged.access["Plant & Store"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Plant & Store"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editPlant(plant.plant.plantId);
                                    setSection("plantDetail");
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={plant.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editPlantCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!plant.collapse ? <RenderSubTable data={plant.storage} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="8" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Store Code</td>
                            <td className="vendorListInnerTableHeading">Store Name</td>
                            <td className="vendorListInnerTableHeading">Address</td>
                            <td className="vendorListInnerTableHeading">Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.storageCode}>
                                    <td className="vendorListInnertableInfo">{row.storageCode}</td>
                                    <td className="vendorListInnertableInfo">{row.storageName}</td>
                                    <td className="vendorListInnertableInfo">{row.address}</td>
                                    <td className="vendorListInnertableInfo">{row.status}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading">Plant Code</td>
                            <td className="vendorListHeading">Plant Name</td>
                            <td className="vendorListHeading">Address</td>
                            <td className="vendorListHeading">GST No</td>
                            <td className="vendorListHeading">Store Count</td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{plantList.length > 0 ? renderplantList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editPlant: (plantId) => dispatch(editPlantAction(plantId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantList);
