import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import clearing from "../../assets/clearing.svg";
import PageTitle from "../SmallComponents/PageTitle";
import "../styles/uom.css";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import AgentList from "./AgentList";
import CreateAgent from "./CreateAgent";
import EditAgent from "./EditAgent";

const ClearingAgent = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("clearingAgentList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "clearingAgentList" }];
    var createAgentButton = {
        buttonName: "Create Clearing Agent",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createAgent",
    };

    const renderSection = (section) => {
        switch (section) {
            case "clearingAgentList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={clearing}
                            pageTitle="Clearing Agent List"
                             buttonList={isLogged.access["Clearing Agent"] === "Create" || isLogged.access["Clearing Agent"] === "Edit" ? [createAgentButton] : null}
                            //buttonList={[createAgentButton]}
                        />

                        <AgentList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createAgent":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={clearing} pageTitle="Create Agent" buttonList={goBackButton} />
                        <CreateAgent setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editAgent":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={clearing} pageTitle="Edit Agent" buttonList={goBackButton} />
                        <EditAgent setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page yet to be created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Clearing Agent" />

                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ClearingAgent);
