import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import FormElement from "../SmallComponents/FormElement";

const ViewScreenControl = ({ isLogged, roleInfo, addTokenToState, setSection }) => {
     
    const [masterList,setMasterList]=useState([]);
    const [masterObj, setMasterObj] = useState({
        "Sales Dashboard":{checked: false,access: null},
        "Quote Dashboard":{checked: false,access: null},
        "Stock Transfer Dashboard":{checked : false, access : null},
        "Purchase Dashboard":{checked: false,access: null},
        "Import Dashboard":{checked : false, access :null},
        "Store Dashboard" :{checked : false, access :null},
        "Production Dashboard" :{checked : false, access :null},

        "Stock Report":{checked : false, access :null},
        "Item Report":{checked:false,access:null},
        "Movement Analysis":{checked : false, access :null},
        "Import Report":{checked:false,access:null},
        "Production Report":{checked:false,access:null},
        "Production Log Report":{checked :false,access: null},
        "Employee Efficiency Report":{checked :false,access:null},
        "Sales Report":{checked:false, access: null},
        "Customer Ledger":{checked:false, access: null},
        "Outstanding statement":{checked:false, access: null},
        "Purchase Report" :{checked : false, access :null},
       "Vendor Ledger" :{checked : false, access :null},
       "Vendor Invoice" :{checked : false, access :null}, 
        
        "Role": { checked: false, access: null },
        "Role Control":{checked: false, access: null},
        "Screen Control":{checked :false,access: null},
        "UOM": { checked: false, access: null },
        "Plant & Store": { checked: false, access: null },
        "Item Menu":{checked: false, access: null},
        "Item Category": { checked: false, access: null },
        "Item Brand": { checked: false, access: null },
        "Item Type": { checked: false, access: null },
        "Item Process": { checked: false, access: null },
        "Item Purchase Cost": { checked: false, access: null },
        "Item Price Type": { checked: false, access: null },
        "Item Tax": { checked: false, access: null },
        "Items": { checked: false, access: null },
        "Item Sales Price": { checked: false, access: null },
        "Item Currency": {checked: false, access: null},
        "Cutting Length": {checked :false,access: null},
        "Section Master":{checked:false,access:null},
        "Premium Charges":{checked:false,access:null},
        "Location":{checked:false,access: null},
        "Employee": { checked: false, access: null },
        "Vendor": { checked: false, access: null },
        "Customer": { checked: false, access: null },
        "Transporter":{checked: false, access: null},
        "Clearing Agent": {checked: false, access: null},
        "Purchase Order": {checked: false, access: null},
        "Purchase Invoice": {checked : false, access :null},
        "Vendor Payments": {checked: false,access: null},
        "Inventory": {checked: false, access: null},
        "OPD": {checked : false, access :null},
        "Inward": {checked : false, access :null},
        "Outward": {checked : false, access :null},
        "Quality Checking": {checked : false, access :null},
        "Stock Transfer":{checked : false, access :null},
        "Pending SO":{checked : false, access :null},
        "Pending MRS":{checked : false, access :null},
        "IDN":{checked : false, access :null},
        "Packaging":{checked:false,access:null},
        "Bundle Creation":{checked:false,access:null},
        "Packing Slip":{checked:false,access:null},
        "Import PO":{checked : false, access :null},
        "Purchase Import":{checked : false, access :null},
        "Sales Order":{checked : false, access :null},
        "Sales Invoice":{checked : false, access :null},
        "Other Charges":{checked : false, access :null},
        "Customer Receipt":{checked:false,access:null},
        "Production Order":{checked : false, access :null},
        "Work Order":{checked: false,access: null},
        "Process Log": {checked: false,access: null},
        "Material Requisition":{checked : false, access :null},
        "DC List": {checked: false, access: null},
        "Bill Of Material":{checked: false,access: null},
        "Sub Contracting":{checked : false, access :null},
        "Loading Slip" :{checked : false, access : null},
        "Sales Opportunities" :{checked : false, access : null},
        "Sales Quote" :{checked : false, access : null},
        "Sales Lead":{checked : false, access : null},
         });
    const [plantOptions,setPlantOptions]=useState([]);
    const [plantAccess,setPlantAccess]=useState([]);
    const [plantAccessValues,setPlantAccessValues]=useState([]);
    const [screenControlParams,setScreenControlParams]=useState({
        plantId:{
            inputType: "options",
            value:"",
            hintText:"Plant Name",
            options:[],
            mandatory: true,
            colSpan: 1,
            placeholder: "Select Plant",
            error: false,
            errorMessage: "Please Select Plant Name",
            disabled: true,
        },
        roleId:{
            inputType:"options",
            value:"",
            hintText:"Role Name",
            options:[],
            mandatory:true,
            colSpan:1,
            placeholder: "Select Role",
            error: false,
            errorMessage:"Please Select Role Name",
            disabled: true,
        },
        empNo:{
            inputType:"options",
            value:"",
            hintText:"Employee No",
            options:[],
            mandatory: true,
            colSpan:1,
            placeholder: "Select Employee",
            error: false,
            errorMessage:"Please Select Employee No",
            disabled: true,
        }
    });
    const [editable,setEditable] = useState(false)
   
    const updateMasterObj = (value, obj) => {
        var newObj = Object.assign(masterObj);
        var updatedObj = update(newObj, { [obj]: { access: { $set: value }, checked: { $set: true } } });
        setMasterObj(updatedObj);
    };
    
    useEffect(() => {
        let listArray =[];
        Object.keys(isLogged.access).map((key)=>{
            if(isLogged.access[key]!=null && key!="plantAccess")
            listArray.push(key);})
        setMasterList(listArray);
        // get roles that don't have any screen controls for creating new
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getScreenControlRolesPreEdit();
       // await getScreenControlRolesEditable();
       await getPlants();
    }

    const history = useHistory();
    async function checkToken() {
      
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
        return true;
    }

    async function getPlants(){
        var plant=await fetchData({
        requestingPage: "screenControl",
        method: "get",
        url: "fetch/plant",
        headers:{token: isLogged.accessToken,module: "Screen Control"}
        });
        if(plant.msg==="success"){
            var optionArray=[];
            plant.asset.forEach((dataItem)=>{
                var a = { optionName: dataItem.plant_name, optionId: dataItem.plant_id };
                optionArray.push(a);
            });
            setPlantOptions(optionArray)
        } else{
            console.log(plant);
        }
    }
    async function getScreenControlRolesPreEdit() {
        let empDetail =roleInfo.viewScreenControlId;
        var data = await fetchData({
            requestingPage: "screenControl",
            method: "get",
            url: `fetch/screen-control-role-pre-edit/${empDetail.plantId}/${empDetail.roleId}/${empDetail.empNo}`,
            headers: { token: isLogged.accessToken,module: "Screen Control" },
        });
        // console.log(data);
        if (data.msg === "success") {
            var masterObjCopy = Object.assign(masterObj);
             var accessArray = data.asset[0]["access"];
             var subMenuArray = data.asset[0]["subMenus"];
             setEditable(data.asset[0].editableForApprove)
            subMenuArray.forEach((subMenuName,index)=>{
                if(accessArray[index]!=="null")
                masterObjCopy = update(masterObjCopy,{[subMenuName]: { checked:{ $set: true},access: {$set: accessArray[index]} } });
            });
            var generalParams=Object.assign(screenControlParams);
            generalParams=update(generalParams,{empNo:{value: {$set: data.asset[0].empNo},options:{$set: [{optionId: data.asset[0].empNo,optionName: data.asset[0].empNo}]} } ,
                                                plantId:{value: {$set: data.asset[0].plantId},options:{$set: [{optionId:data.asset[0].plantId,optionName:data.asset[0].plantName}]} },
                                                roleId:{value: {$set: data.asset[0].roleId},options:{$set: [{optionId:data.asset[0].roleId,optionName:data.asset[0].roleName}]} }});
            setScreenControlParams(generalParams);
            setMasterObj(masterObjCopy);
            setPlantAccessValues(data.asset[0].plantAccess);
        }
    }

    useEffect(()=>{
        if(plantOptions.length>0 && plantAccessValues.length>0){
           var tempPlant=[];
            plantAccessValues.map((value)=>{
               var tempObj=plantOptions.filter((obj)=>obj.optionId==value)[0]
           tempPlant.push({label:tempObj.optionName,value:tempObj.optionId})
           });
           setPlantAccess(tempPlant);
        }
       },[plantOptions,plantAccessValues])

    const appDecModules= ["Items","Purchase Order","Purchase Import","Purchase Invoice","Vendor Payments","OPD","Inward","Outward","Quality Checking","Stock Transfer","Sales Order","Sales Invoice","Other Charges","Customer Receipt","Production Order","Material Requisition","DC List","Sub Contracting"];
    
    const renderFormElements = ({ elementList, params, section }) =>{
        return  elementList.map((element)=>{ 
             return( 
                     <FormElement style={{width:"60px !important"}}
                         key={element}
                         inputType={params[element].inputType}
                         value={params[element].value !== null ? params[element].value : ""}
                         setInput={(value)=>{ return value; }}
                         hintText={params[element].hintText}
                         mandatory={params[element].mandatory}
                         colSpan={params[element].colSpan}
                         options={
                                 params[element].inputType === "options"
                                 ? params[element].options
                                 : null
                                 }
                         error={params[element].error}
                         rowSpan={
                                 element === "vendorLogo" || element === "otherBusinessDetails"
                                 ? params[element].rowSpan
                                 : 0
                                 }
                         placeholder={params[element].placeholder}
                         title={params[element].title}
                         disabled={params[element].disabled}
                         mindate={params[element].mindate} /> 
                 );
         });
        
     };         

    return (
        <React.Fragment>
            <div className="formArea">
                <div className="screenControl">
                    <div className="screenControlOptionsWrapper">
                                {renderFormElements({elementList:Object.keys(screenControlParams),params:screenControlParams,section:"general"})}   
                    
                    <div style={{width:"250px"}}>
                    <FormElement 
                                        setInput={(value)=>{setPlantAccess(value);}}
                                        inputType={"multiSelect"}
                                        value={plantAccess}
                                        hintText={"Plant Names"}
                                        options={plantOptions}
                                        colSpan={3}
                                        rowSpan={2}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={true}
                                    />
                                    </div></div>
                                    <label key="editableForApprove" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox" checked={editable} />
                                    <span id="editableForApprove">Editable For Approved Items </span>
                                </label> 
                    <table className="screenControlTable">
                        <thead>
                            <tr>
                                <th className="screenControlTableHead">Module</th>
                                <th className="screenControlTableHead">View</th>
                                <th className="screenControlTableHead">+Create</th>
                                <th className="screenControlTableHead">+Edit</th>
                                <th className="screenControlTableHead">Approve/Decline</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masterList.length >0 ?masterList.map((obj) => {
                                return (
                                    <tr>
                                        <td className="screenControlTableText">{obj}</td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="View"
                                                checked={masterObj[obj].access === "View" ? true : false}
                                                onChange={(e) => updateMasterObj(e, obj)}
                                                disabled={masterObj[obj].access === "View" ? false : true}
                                            />
                                        </td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="Create"
                                                checked={masterObj[obj].access === "Create" ? true : false}
                                                onChange={(e) => updateMasterObj(e, obj)}
                                                disabled={masterObj[obj].access === "Create" ? false : true}
                                            />
                                        </td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="Edit"
                                                checked={masterObj[obj].access === "Edit" ? true : false}
                                                onChange={(e) => updateMasterObj(e, obj)}
                                                disabled={masterObj[obj].access === "Edit" ? false : true}
                                            />
                                        </td>
                                        {appDecModules.includes(obj) ?
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="App/Dec"
                                                checked={masterObj[obj].access === "App/Dec" ? true : false}
                                                onChange={(e) => updateMasterObj(e, obj)}
                                                disabled={masterObj[obj].access === "App/Dec" ? false : true}
                                            />
                                        </td> : <td>&nbsp;</td>}
                                    </tr>
                                );
                            }) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewScreenControl);
