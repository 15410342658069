import React,{useMemo, useState } from "react"
import classnames from 'classnames';
import "../styles/pagination.css";

const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array For Page Numbers
    */
    return Array.from({ length }, (_, idx) => idx + start);
  };
const DOTS=`...`;
  const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage
  }) => {
    const paginationRange = useMemo(() => {
       const totalPageCount = Math.ceil(totalCount / pageSize);

       // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
       const totalPageNumbers = siblingCount + 5;
   
       /*
         Case 1:
         If the number of pages is less than the page numbers we want to show in our
         paginationComponent, we return the range [1..totalPageCount]
       */
       if (totalPageNumbers >= totalPageCount) {
         return range(1, totalPageCount);
       }
       
       /*
           Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
       */
       const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
       const rightSiblingIndex = Math.min(currentPage + siblingCount,totalPageCount);
   
       /*
         we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
       */
       const shouldShowLeftDots = leftSiblingIndex > 2;
       const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
   
       const firstPageIndex = 1;
       const lastPageIndex = totalPageCount;
   
       /*
           Case 2: No left dots to show, but rights dots to be shown
       */
       if (!shouldShowLeftDots && shouldShowRightDots) {
         let leftItemCount = 3 + 2 * siblingCount;
         let leftRange = range(1, leftItemCount);
   
         return [...leftRange, DOTS, totalPageCount];
       }
   
       /*
           Case 3: No right dots to show, but left dots to be shown
       */
       if (shouldShowLeftDots && !shouldShowRightDots) {
         
         let rightItemCount = 3 + 2 * siblingCount;
         let rightRange = range(
           totalPageCount - rightItemCount + 1,
           totalPageCount
         );
         return [firstPageIndex, DOTS, ...rightRange];
       }
        
       /*
           Case 4: Both left and right dots to be shown
       */
       if (shouldShowLeftDots && shouldShowRightDots) {
         let middleRange = range(leftSiblingIndex, rightSiblingIndex);
         return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
       }
    }, [totalCount, pageSize, siblingCount, currentPage]);
  
    return paginationRange;
  };

  const Pagination = props => {
    const {
      onPageChange,
      totalCount,
      siblingCount = 1,
      currentPage,
      pageSize,
      className
    } = props;
  const [goto,setGoto]=useState(1);
    const paginationRange = usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize
    });
  
    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }
  
    const onNext = () => {
      onPageChange(currentPage + 1);
    };
  
    const onPrevious = () => {
      onPageChange(currentPage - 1);
    };
  
    let lastPage = paginationRange[paginationRange.length - 1];
    return (
      <ul
        className={classnames('pagination-container', { [className]: className })}
      >
         {/* Left navigation arrow */}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {paginationRange.map((pageNumber,ind) => {
           
          // Render Dots
          if (pageNumber === DOTS) {
            return <li key={ind} className="pagination-item dots">&#46;&nbsp;&#46;&nbsp;&#46;&nbsp;</li>;
          }
          
          // Render our Page Numbers
          return (
            <li key={ind}
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {/*  Right Navigation arrow */}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
        {/* Go To Page Input Box */}
        <li className="gotoPage">
            <span className="label">Go to Page</span>
            <input type="text" value={goto} onChange={(e)=>setGoto(parseInt(e.target.value) || "")}
                    onKeyDown ={(e)=>{
                    if(e.key == "Enter")
                    if(goto > 0 && goto <=Math.ceil(totalCount/pageSize))
                    onPageChange(goto) 
                  }}/>
        </li>
      </ul>
    );
  };
  
  export default Pagination;