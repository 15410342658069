import React,{Fragment,useState} from "react";
import { useHistory } from "react-router-dom";
import MenuList from "../../Menu/MenuList"
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner"
import VenderLedgerParams from "./vendorLedgerParams";
import purchaseOrderIcon from "../../../assets/purchaseOrder.svg"
import VendorLedgerList from "./vendorLedgerList";
const VenderLedgerReport = () => {
    const [currentSection, setCurrentSection] = useState("venderLedgerParams");
    const history = useHistory();

    var goBackButton = (props) =>
    ({
        buttonName: "Back",
        className: "goBackButton",
        ...props
    });

    const renderSection=(sectionName)=>{
      switch(sectionName){
          case "venderLedgerParams": return(
              <Fragment>
             <PageTitle imgSrc={purchaseOrderIcon} pageTitle={"Vendor Ledger"}  
                buttonList={[goBackButton({setSection:(value)=>history.push(value),sectionName:"purchasereport"})]}/>
             <VenderLedgerParams setCurrentSection={setCurrentSection}/>
              </Fragment>
          )
          case "vendorLedgerList" :return (
              <Fragment>
                 <PageTitle imgSrc={purchaseOrderIcon} pageTitle={"Vendor Ledger"}  
                   buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"venderLedgerParams"})]}/>
                   <VendorLedgerList/>
              </Fragment>
          )
      }
    }

    return (
        <Fragment>
            <div className="completePage">
                <MenuList selectedMenu={"Reports"} selectedSubMenu={"Purchase Report"} />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </Fragment>
    )
}

export default VenderLedgerReport;