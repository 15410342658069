import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import purchaseInvoiceIcon from "../../assets/purchaseInvoice.svg";
import CreatePurchaseInvoice from "./CreatePurchaseInvoice";
import PurchaseInvoiceList from "./PurchaseInvoiceList";
import PurchaseInvoiceDetail from "./PurchaseInvoiceDetail";
import EditPurchaseInvoice from "./EditPurchaseInvoice";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";


const PurchaseInvoice = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("purchaseInvoiceList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);
    const [loadPo, setLoadPo] = useState(false);

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "purchaseInvoiceList" };
    var purchaseInvoice = { buttonName: "Create Invoice", className: "employeeButton", setSection: setCurrentSection, sectionName: "createPurchaseInvoice", callback: () => setLoadPo(false) }
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "purchaseInvoice") }, sectionName: "purchaseInvoiceList" };

    const renderSection = (section) => {
        switch (section) {
            case "createPurchaseInvoice":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseInvoiceIcon} pageTitle="Create Purchase Invoice" buttonList={[goBackButton]} />
                        <CreatePurchaseInvoice setSection={(value) => setCurrentSection(value)} loadPo={loadPo} />
                    </React.Fragment>
                );

            case "purchaseInvoiceList":
                return (
                    <React.Fragment>
                        {/* <div className="pageTitle">
                            <img className="pageTitleImage" alt="Page Title" src={purchaseInvoiceIcon}></img>
                            <span className="pageTitleText">Purchase Invoice List</span>
                            <div className="buttonList">
                                <button
                                    className="employeeButton"
                                    onClick={() => {
                                        setLoadPo(false);
                                        setCurrentSection("createPurchaseInvoice");
                                    }}
                                >
                                    Create Invoice
                                </button>
                            </div>
                        </div> */}
                        <PageTitle imgSrc={purchaseInvoiceIcon} pageTitle={"Purchase Invoice List"}
                            buttonList={isLogged.access["Purchase Invoice"] === "Create" || isLogged.access["Purchase Invoice"] === "Edit" || isLogged.access["Purchase Invoice"] === "App/Dec" ?
                                [purchaseInvoice] : null} />
                        <PurchaseInvoiceList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={setApprovalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setLoadPo={setLoadPo}
                        />
                    </React.Fragment>
                );

            case "poInvoiceDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseInvoiceIcon}
                            pageTitle={approvalPage ? "Approve Invoice" : "Invoice Detail"}
                            buttonList={approvalPage ? [goBackButton] : [goBackButton, downloadButton]}
                        />
                        <PurchaseInvoiceDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editPurchaseInvoice":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseInvoiceIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Invoice" : "Edit Invoice"}
                            buttonList={[goBackButton]}
                        />
                        <EditPurchaseInvoice setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );

                default :return 0;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Purchase" selectedSubMenu="Purchase Invoice" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(PurchaseInvoice);
