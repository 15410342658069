import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import SalesOpportunityList from "./SalesOpportunityList";
import SalesOpportunityDetail from "./salesOpportunityDetail";
import EditSalesOpportunity from "./EditSalesOpportunity";
import SalesOpportunityParams from "./CreateSalesOpportunities"
import salesOpportunityIcon from "../../assets/sales-opportunity.svg";

const SalesDeal = () => {
  const [currentSection, setCurrentSection] = useState("salesDealList");

  var goBackButton = (props) =>
  ({
    buttonName: "Back",
    className: "goBackButton",
    ...props
  });

  var CreateButton = { buttonName: "Create Opportunity", className: "employeeButton", setSection: setCurrentSection, sectionName: "createSalesDeal" }

  const renderSection = (section) => {
    switch (section) {
      case "salesDealList":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesOpportunityIcon}
              pageTitle={"Sales Opportunity"}
              buttonList={[CreateButton]}
            />
            <SalesOpportunityList setCurrentSection={setCurrentSection} />
          </React.Fragment>
        );
      case "createSalesDeal":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesOpportunityIcon}
              pageTitle={"Create Sales Opportunity"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesDealList" })]}
            />
            <SalesOpportunityParams setCurrentSection={setCurrentSection} />
            {/* <SalesDealParams setCurrentSection={setCurrentSection} /> */}
          </React.Fragment>
        );
      case "salesDealDetail":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesOpportunityIcon}
              pageTitle={"Sales Opportunity"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesDealList" })]}
            />
            <SalesOpportunityDetail setCurrentSection={setCurrentSection} />
          </React.Fragment>
        );
      case "editSalesOpportunity":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesOpportunityIcon}
              pageTitle={"Edit Sales Opportunity"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesDealList" })]}
            />
            <EditSalesOpportunity setCurrentSection={setCurrentSection}/>
          </React.Fragment>
        );

        default:return null
    }
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Sales Quotes(CRM)" selectedSubMenu="Sales Opportunities" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
  )
}


export default SalesDeal;