import React from "react";
import Modal from "react-modal";

const ErrorModal = ({ errorModal, okButtonPressed, errorList }) => {
    Modal.setAppElement("body");
    return (
        <Modal
            isOpen={errorModal}
            contentLabel="Example Modal"
            style={{
                overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)",zIndex:"11" },
                content: {
                    textAlign: "center",
                    width: "100%",
                    height: "30%",
                    position: "absolute",
                    top: "35%",
                    left: "0%",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px gray",
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            {/* <div className="errorHeading">Please fix the following errors</div> */}
            <div style={{ flex: "1", overflow: "auto" }}>
                {errorList.map((error,ind) => {
                    return (
                        <React.Fragment key={ind}>
                            <div className="errorSection">{error.title}</div>
                            {error.messages.map((msg,i) => {
                                return <div className="errorDetail" key={i+'msg'}>{msg}</div>;
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
            {okButtonPressed !== null ?<button className="employeeButton" style={{ margin: "1rem auto 0" }} onClick={okButtonPressed}>
                Ok
            </button> : ''}
        </Modal>
    );
};

export default ErrorModal;
