import {Fragment,useState} from 'react'
import FormElement from '../SmallComponents/FormElement'
import { connect } from 'react-redux';
import { addToken } from '../../redux/UserAccount/IsLoggedActions';
import { validateMandatory } from '../CommonFunctions/ValidateFields';
import { addToImportReportParams } from '../../redux/PurchaseOrder/PoInfoActions';
import update from "immutability-helper";

const ImportReportParams = ({addToImportReportParams,setSection}) => {

  const [importReportParams,setImportReportParams] = useState({
    portArrivalDate:{
      inputType: "dateFromEditPage",
      value: "",
      hintText:"Port Arrival Date",
      colSpan: 4,
      error: false,
      errorMessage: "",
      mandatory: false,
    },
    clearingDate:{
      inputType:"dateFromEditPage",
      value: "",
      hintText:"Clearing Date",
      colSpan: 4,
      error:false,
      errorMessage: "",
      mandatory: false
    },
    pendingImport:{
      inputType: "options",
      value: "",
      hintText:"Import status",
      colSpan: 4,
      options: [{label:"Import Arrived",value:"importArrived"},
                {label:"Import Pending",value: "importPending"}],
      error: false,
      errorMessage: "",
      mandatory: false,
    },
    advancePending:{
      inputType: "float",
      value:"",
      hintText:"Advance Pending ₹",
      colSpan: 4,
      error: false,
      errorMessage: "",
      mandatory: false,
    },
    arrivingIn:{
      inputType:"options",
      hintText:"Arriving In",
      value: 15,
      options:[{label:"Next 15 days",value: 15},
               {label:"Next 30 days",value: 30},
               {label:"Next 6 Months",value: 180}],
      error: false,
      errorMessage:"",
      mandatory: true,
      isClearable:false
    }
  })
  const [importReportError,setImportReportError] =useState([]);
  const updateParameter=(element,key,value)=>{
    let paramsCopy  =  Object.assign(importReportParams);
    paramsCopy = update(paramsCopy,{[element]: {[key]:{$set: value} } });
    setImportReportParams(paramsCopy);
  }

  const checkImportReportError=()=>{
    var errorList = [];
      var paramsCopy = Object.assign(importReportParams);
      let queryParams = {}
      Object.keys(paramsCopy).forEach((item)=>{
        if(paramsCopy[item].mandatory){
          paramsCopy = update(paramsCopy,{ [item]: {error: {$set:  !validateMandatory(paramsCopy[item].value.toString()) } } })
        queryParams[item] = paramsCopy[item].value
        }
        if(paramsCopy[item].error)
          errorList.push(paramsCopy[item].errorMessage);
      });

      let emptyCheck = Object.entries(queryParams).every(([key,value])=>value==="");
      if(emptyCheck){
        errorList.push("Enter atleast One Parameter to fetch list")
      }
      if(errorList.length===0){
        addToImportReportParams(queryParams);
        setSection("importReportList")
      }else{
        setImportReportError(errorList)
      }
  }

  const renderFormElements = ({ elementList, param }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0? "500px" : "250px" }} key={element}>
            <FormElement
              inputType={param[element].inputType}
              value={param[element].value}
              setInput={(value) => updateParameter(element, "value", value)}
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={
                param[element].inputType === "options"
                  ? param[element].options
                  : null
              }
              error={param[element].error}
              key={ind}
              isClearable={param[element].isClearable}
            />
            </span>
          );
        })}
        <FormElement
            inputType="addButton"
            value="Submit"
            colSpan={5}
            boxWidth="80px"
            setInput={() => {
                setImportReportError([]);
                checkImportReportError();
            }}
        />
        </>
    );
  };

  return (
    <Fragment>
      <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
            <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            {renderFormElements({
              elementList: ["portArrivalDate","clearingDate","pendingImport","advancePending",'arrivingIn'],
              param: importReportParams,
            })}
          </div>
          <p className="formSubmitErrorTextArea" style={{textAlign:"center",fontSize:"16px"}}>
            {importReportError[0]}
          </p>
          </div>
        </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
      addToImportReportParams: (value)=>dispatch(addToImportReportParams(value))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ImportReportParams);