import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { editProductionOrderId } from "../../redux/PurchaseOrder/PoInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const ProductionDetail = ({ approval, setSection, setEditAndApprove, isLogged, poInfo, addTokenToState, editProductionOrderId }) => {
    const [productionOrderDetail, setProductionOrderDetail] = useState({});
    const [lineItems, setLineItems] = useState([]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getProductionDetail();
    }

    const history = useHistory();

    async function checkToken() {
     
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getProductionDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "production-fetch/request-detail/" + poInfo.viewProductionOrderId,
            headers: { token: isLogged.accessToken ,module:"Production Order"},
        });
        if (data.msg === "success") {
            setProductionOrderDetail(data.asset[0]);
            setLineItems(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                       { isLogged.showHideWeight ?
                       <>
                       <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td> 
                        </>
                        : '' }
                        <td>UOM</td>
                        <td>So Quantity</td>
                        <td>Request Quantity</td>
                        <td>DC Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItems} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = isLogged.showHideWeight ? ["itemName", "itemCode","from","to","netWeight","grossWeight", "uom","soQuantity", "quantity","dcQuantity"] :
        ["itemName","itemCode","uom","soQuantity","quantity","dcQuantity"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const renderSummaryInfo = () => {
        if (productionOrderDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {productionOrderDetail.requestNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                productionOrderDetail.returnStatus === "Declined"
                                    ? { color: "red" }
                                    : productionOrderDetail.returnStatus === "Approved"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }
                        >
                            ( {productionOrderDetail.returnStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Request Type</span>
                        <span className="vendorSummaryValue">: {productionOrderDetail.requestType}</span>

                        {productionOrderDetail.requestType === "Make To Order" ? (
                            <React.Fragment>
                                <span className="vendorSummaryCategory">Order No</span>
                                <span className="vendorSummaryValue">: {productionOrderDetail.soNo}</span>

                                <span className="vendorSummaryCategory">Order Date</span>
                                <span className="vendorSummaryValue">: {productionOrderDetail.soDate}</span>
                            </React.Fragment>
                        ) : (<React.Fragment>
                             <span className="vendorSummaryCategory">Plant Name</span>
                                <span className="vendorSummaryValue">: {productionOrderDetail.plantName}</span>

                                <span className="vendorSummaryCategory">Storage Name</span>
                                <span className="vendorSummaryValue">: {productionOrderDetail.storageName}</span>
                            </React.Fragment>)}

                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {productionOrderDetail.expectDate}</span>
                    </div>

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>
                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <b> Production Instruction</b> : {productionOrderDetail.instruction !== "" ? productionOrderDetail.instruction : "NA"}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    async function approveProductionOrder() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `production-edit/request-approve/${productionOrderDetail.requestId}`,
            headers: { token: isLogged.accessToken,module:"Production Order" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Production Request");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editProductionOrder");
                            editProductionOrderId(productionOrderDetail.requestId);
                        }}
                    >
                        Edit
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            if(productionOrderDetail.requestType==="Make To Order")
                            {
                                let checkCondition = lineItems.every((row)=>row["soQuantity"] == parseFloat(row["quantity"])+parseFloat(row["dcQuantity"]))
                                if(checkCondition)
                                 {approveProductionOrder();}
                                 else{
                                    setErrorMessage(["Sale Order Quantity is Not Equally Distributed"])
                                }
                                 
                            }else if(productionOrderDetail.requestType==="Make To Stock")
                            {approveProductionOrder();}
                            
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("productionOrderList");
        }
        setModalText("Approving PO. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Production Request. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                    </div>
                </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editProductionOrderId: (productionId) => dispatch(editProductionOrderId(productionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionDetail);
