import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import fetchData from "serverCall/fetchData";
import viewIcon from "assets/view.svg";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import { addToSalesQuoteId } from "../../redux/Store/StoreInfoActions"

const SalesDealsList = ({ isLogged, setCurrentSection, viewSalesQuoteId, editSalesQuoteId }) => {
  const [quoteList, setQuoteList] = useState([])
  const access = isLogged.access["Sales Quote"];


  //function for get sales quote details
  const salesQuoteList = async () => {

    let data = await fetchData({
      requestingPage: "employeeDetail",
      method: "get",
      url: "so-fetch/sales-quote-list",
      headers: { token: isLogged.accessToken, module: "Sales Quote" }
    })

    if (data.msg === "success") {
      setQuoteList(data.asset)
    }
  }

  useEffect(() => {
    salesQuoteList();
  }, [])

  const renderQuoteList = () => {
    return quoteList.map((item, i) => {
      return (
        <Fragment key={i}>
          <tr className="vendorList">
            <td className="vendorListInfo">{item.quoteName}</td>
            <td className="vendorListInfo">{item.opportunityName}</td>
            <td className="vendorListInfo">{new Date(item.expirationDate).toLocaleDateString()}</td>
            <td className="vendorListInfo">{item.dealOwner}</td>
            <td className="vendorListInfoActions">
              <img
                alt="View Record"
                className="vendorActionImages"
                src={viewIcon}
                title="View Invoice Detail"
                onClick={(e) => {
                  e.stopPropagation();
                  viewSalesQuoteId(item.quoteId)
                  setCurrentSection("salesQuoteDetail")
                }}
              />
              <img
                alt="Edit Record"
                className="vendorActionImages"
                src={
                  access === "Edit" || access === "App/Dec"
                    ? editIcon
                    : editIconDisabled
                }
                title={
                  access === "Edit" || access === "App/Dec"
                    ? "Edit Item"
                    : "Access Denied"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (access === "Edit" || access === "App/Dec") {
                    editSalesQuoteId(item.quoteId);
                    setCurrentSection("editSalesQuotes");
                  }
                }}
                style={
                  access === "Edit" || access === "App/Dec"
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed" }
                }
              />
            </td>
          </tr>
        </Fragment>
      )
    })
  }

  const RenderOtherChargesList = () => {
    return (
      <Fragment>
        <table className="vendorListTable">
          <thead>
            <tr className="vendorListHeader" style={{ zIndex: 1 }}>
              <td
                className="vendorListHeading stickyFirstColumn"
              >
                Quote Name
              </td>
              <td
                className="vendorListHeading">
                Opportunity Name
              </td>
              <td className="vendorListHeading">
                Expiration Date
              </td>
              <td className="vendorListHeading">
                Deal Owner
              </td>
              <td className="vendorListHeadingActions">Actions</td>
            </tr>
          </thead>
          <tbody>
            {renderQuoteList()}
            </tbody>
        </table>
      </Fragment>
    )
  }



  return (
    <React.Fragment>
      <div className="vendorListArea" style={{ maxHeight: "450px" }}>
        <React.Fragment>
          {RenderOtherChargesList()}
          {quoteList.length ? null
            : <div className="noRecordsYet">No records found</div>}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewSalesQuoteId: (value) => dispatch(addToSalesQuoteId(value)),
    editSalesQuoteId: (value) => dispatch(addToSalesQuoteId(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesDealsList) 