import { Fragment,useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import ImportReportParams from "./importReportParams";
import ImportReportList from "./importReportList";
import StatementIcon from "../../assets/sales_report.svg"

const ImportReport = () => {
    const [currentSection,setCurrentSection] = useState("importReportParams");

    var goBackButton={buttonName:"Back",className:"goBackButton",setSection:setCurrentSection,sectionName:"importReportParams"}
    const renderSection=(currentSection)=>{
        switch(currentSection){
            case "importReportParams":
                return(
                    <Fragment>
                    <PageTitle imgSrc={StatementIcon} pageTitle={"Import Reports"}
                        buttonList={[]}/>
                     <ImportReportParams setSection={setCurrentSection}/>
              </Fragment>
                );

            case "importReportList":
                return(
                    <Fragment>
                        <PageTitle imgSrc={StatementIcon} pageTitle={"Import Report List"}
                            buttonList={[goBackButton]}
                            />
                            <ImportReportList />
                    </Fragment>
                )

            default:
                return <>Page Not Found</>;
        }
    }

  return (
    <Fragment>
        <div className="completePage">
            <MenuList selectedMenu={"Reports"} selectedSubMenu={"Import Report"}/>
            <div className="detailsContainer">
                <TopBanner />
                {renderSection(currentSection)}
            </div>
        </div>
    </Fragment>
  )
}

export default ImportReport