import React, { useState } from "react";
import PageTitle from "../../SmallComponents/PageTitle";
import processIcon from "../../../assets/process_black.svg";
import MenuList from "../../Menu/MenuList";
import ItemProcessList from "./ItemProcessList";
import CreateProcess from "./CreateProcess";
import EditProcess from "./EditProcess";
import TopBanner from "../../SmallComponents/TopBanner";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const ItemProcess = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("itemProcessList");

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemProcessList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemProcessButton = {
        buttonName: "Create Process",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createItemProcess",
    };

    const renderSection = (section) => {
        switch (section) {
            case "itemProcessList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={processIcon}
                            pageTitle="Item Process"
                            buttonList={
                                isLogged.access["Item Process"] === "Create" || isLogged.access["Item Process"] === "Edit"
                                    ? [createItemProcessButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemProcessList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createItemProcess":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={processIcon} pageTitle="Create Item Process" buttonList={goBackButton} />
                        <CreateProcess setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editItemProcess":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={processIcon} pageTitle="Edit Item Process" buttonList={goBackButton} />
                        <EditProcess setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemProcess);
