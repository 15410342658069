import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import stockTransferIcon from "../../assets/stockTransfer.svg";
import CreateStockTransfer from "./CreateStockTransfer";
import StockTransferList from "./StockTransferList";
import StockTransferDetail from "./StockTransferDetail";
import EditStockTransfer from "./EditStockTransfer";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const StockTransfer = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("transferList");

    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "transferList" };
    var createStockTransfer = [
        { buttonName: "Create Stock Transfer", className: "employeeButton", setSection: setCurrentSection, sectionName: "createTransfer" },
    ];
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "stockTransferDetail") }, sectionName: "transferList" };

    const renderSection = (section) => {
        switch (section) {
            case "createTransfer":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={stockTransferIcon} pageTitle="Create Stock Transfer" buttonList={[goBackButton]} />
                        <CreateStockTransfer setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "transferList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={stockTransferIcon} pageTitle="Stock Transfer List"
                            buttonList={isLogged.access["Stock Transfer"] === "Create" || isLogged.access["Stock Transfer"] === "Edit" || isLogged.access["Stock Transfer"] === "App/Dec" ?
                                createStockTransfer : null} />
                        <StockTransferList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={setApprovalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "transferDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={stockTransferIcon} pageTitle="Stock Transfer Detail" buttonList={[goBackButton, downloadButton]} />
                        <StockTransferDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editTransfer":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={stockTransferIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Stock Transfer" : "Edit Stock Transfer"}
                            buttonList={[goBackButton]}
                        />
                        <EditStockTransfer setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            default:
                return (
                    <div>
                        Page <b>{section}</b> yet to be built
                    </div>
                );
        }
    };
    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Stock Transfer" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
}

export default connect(mapStateToProps, null)(StockTransfer);
