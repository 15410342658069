import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import productionIcon from "../../assets/production.svg";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";
import CreateWorkOrder from "./createWorkOrder";
import WorkOrderList from "./workOrderList";
import EditWorkOrder from "./editWorkOrder";


const WorkOrder = ({ poInfo, isLogged }) => {
    const [currentSection, setCurrentSection] = useState("workOrderList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);


    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "workOrderList" };
    var workOrder = { buttonName: "Create Request", className: "employeeButton", setSection: setCurrentSection, sectionName: "createWorkOrder" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "workOrderDetail") }, sectionName: "workOrderList" };

    const renderSection = (section) => {
        switch (section) {
            case "createWorkOrder":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle="Request Work Order" buttonList={[goBackButton]} />
                        <CreateWorkOrder setSection={(value)=>setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "workOrderList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle={"Work Order List"}
                            buttonList={isLogged.access["Work Order"] === "Create" || isLogged.access["Work Order"] === "Edit" || isLogged.access["Work Order"] === "App/Dec"
                                ? [workOrder] : null} />
                        <WorkOrderList setSection={(value)=>setCurrentSection(value)} setApproval={(val)=>setApprovalPage(val)}/>
                    </React.Fragment>
                );

            case "workOrderDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={approvalPage ? "Approve Production Order" : "Work Order Detail"}
                            buttonList={[goBackButton, downloadButton]}
                        />
                    </React.Fragment>
                );

            case "editWorkOrder":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Production Request" : "Edit Work Order"}
                            buttonList={[goBackButton]}
                        />
                        <EditWorkOrder setSection={(value=>setCurrentSection(value))}/>
                    </React.Fragment>
                );
        default :return null;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Work Order" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { poInfo: state.poInfo, isLogged: state.isLogged };
};


export default connect(mapStateToProps, null)(WorkOrder);
