import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { exportData } from "../CommonFunctions/ExportExcel";
import FormElement from "../SmallComponents/FormElement";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import downloadIcon from "../../assets/download.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import { editPoId, editPoReturnId, setPoTab, viewPoId, viewPoReturnId } from "../../redux/PurchaseOrder/PoInfoActions";

const PurchaseOrderList = ({
    // props
    setSection,
    poInfo,
    setPurchaseOrderTab,
    setApproval,
    setTab,

    // state
    isLogged,

    // dispatch
    addTokenToState,
    viewPurchaseOrderId,
    editPurchaseOrderId,
    viewPurchaseReturnId,
    editPurchaseReturnId,
    setEditAndApprove,
}) => {
    const [poList, setPoList] = useState([]);
    const [poApprovedList, setPoApprovedList] = useState([]);
    const [poClosedList, setPoClosedList] = useState([]);
    const [poDeclinedList, setPoDeclinedList] = useState([]);
    const [poReturnUnapprovedList, setPoReturnUnapprovedList] = useState([]);
    const [poReturnApprovedList, setPoReturnApprovedList] = useState([]);
    const [plantId,setPlantId] = useState([]);
    const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
    const [noApprovedRecords, setNoApprovedRecords] = useState(false);
    const [noClosedRecords, setNoClosedRecords] = useState(false);
    const [noDeclinedRecords, setNoDeclinedRecords] = useState(false);
    const [noReturnUnapprovedRecords, setNoReturnUnapprovedRecords] = useState(false);
    const [noReturnApprovedRecords, setNoReturnApprovedRecords] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        // await getPoUnapproved();
    }

    const history = useHistory();
    async function checkToken() {
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPoUnapproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/po-unapproved-list",
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoList(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getPoApproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/po-approved-list",
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoApprovedList(data2);
            if (data.asset.length > 0) setNoApprovedRecords(false);
            else setNoApprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getPoDeclined() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/po-declined-list",
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoDeclinedList(data2);
            if (data.asset.length > 0) setNoDeclinedRecords(false);
            else setNoDeclinedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getPoReturnUnapproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/pr-unapproved-list",
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoReturnUnapprovedList(data2);
            if (data.asset.length > 0) setNoReturnUnapprovedRecords(false);
            else setNoReturnUnapprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getPoReturnApproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/pr-approved-list",
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoReturnApprovedList(data2);
            if (data.asset.length > 0) setNoReturnApprovedRecords(false);
            else setNoReturnApprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }
    
    async function getPoClosed() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/po-closed-list",
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPoClosedList(data2);
            if (data.asset.length > 0) setNoClosedRecords(false);
            else setNoClosedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const setSelectedTab = (tab) => {
        setPurchaseOrderTab(tab);
    };

    const tablist = [
        { id: "poList", name: "Purchase Orders" },
        { id: "approvedPoList", name: "Approved Purchase Orders" },
        { id: "declinedPoList", name: "Declined Purchase Orders" },
        { id: "purchaseReturn", name: "Purchase Return" },
        { id: "approvedPurchaseReturn", name: "Approved Purchase Return" },
        { id: "closedPoList", name: "Closed Purchase Orders" },
    ];

    useEffect(async () => {
        setLoading(true);
        await checkToken();

        switch (poInfo.poTab) {
            case "poList":
                setPoList([]);
                getPoUnapproved();
                break;

            case "approvedPoList":
                setPoApprovedList([]);
                getPoApproved();
                break;

            case "declinedPoList":
                setPoDeclinedList([]);
                getPoDeclined();
                break;

            case "purchaseReturn":
                setPoReturnUnapprovedList([]);
                getPoReturnUnapproved();
                break;

            case "approvedPurchaseReturn":
                setPoReturnApprovedList([]);
                getPoReturnApproved();
                break;

            case "closedPoList":
                setPoClosedList([]);
                getPoClosed();
                break;

            default:
                break;
        }
    }, [poInfo.poTab]);

    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={poInfo.poTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => {
                        setSelectedTab(tab.id);

                        switch (tab.id) {
                            case "poList":
                                setTab("createPurchaseOrder");
                                return;

                            case "purchaseReturn":
                                setTab("createPurchaseReturn");
                                return;

                            default:
                                setTab("");
                        }
                    }}
                >
                    {tab.name}
                    {tab.id === "contactDetails" ? poInfo.poTab === "contactDetails" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const renderSubSection = () => {
        switch (poInfo.poTab) {
            case "poList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">PO No</td>
                                    <td className="vendorListHeading">Order Date</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeading">Exp Delivery Date</td>
                                    <td className="vendorListHeading">Plant</td>
                                    <td className="vendorListHeading">Storage</td>
                                    <td className="vendorListHeading">Order Status</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{poList.length > 0 ? renderPoUnapprovedList() : null}</tbody>
                        </table>
                        {noUnapprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );

            case "approvedPoList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">PO No</td>
                                    <td className="vendorListHeading">Order Date</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeading">Exp Delivery Date</td>
                                    <td className="vendorListHeading">Order Status</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeading">Modified By</td>
                                    <td className="vendorListHeading">Approved By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{poApprovedList.length > 0 ? renderPoApprovedList() : null}</tbody>
                        </table>
                        {noApprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );

            case "declinedPoList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">PO No</td>
                                    <td className="vendorListHeading">Order Date</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeading">Exp Delivery Date</td>
                                    <td className="vendorListHeading">Plant</td>
                                    <td className="vendorListHeading">Storage</td>
                                    <td className="vendorListHeading">Order Status</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{poDeclinedList.length > 0 ? renderPoDeclinedList() : null}</tbody>
                        </table>
                        {noDeclinedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );

            case "purchaseReturn":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">PO Return No</td>
                                    <td className="vendorListHeading">PO Return Date</td>
                                    <td className="vendorListHeading">PO No</td>
                                    <td className="vendorListHeading">Order Date</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeading">Order Status</td>
                                    <td className="vendorListHeading">Inward Status</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{poReturnUnapprovedList.length > 0 ? renderPoReturnUnapprovedList() : null}</tbody>
                        </table>
                        {noReturnUnapprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );

            case "approvedPurchaseReturn":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">PO Return No</td>
                                    <td className="vendorListHeading">PO Return Date</td>
                                    <td className="vendorListHeading">PO No</td>
                                    <td className="vendorListHeading">Order Date</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeading">Order Status</td>
                                    <td className="vendorListHeading">Inward Status</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{poReturnApprovedList.length > 0 ? renderPoReturnApprovedList() : null}</tbody>
                        </table>
                        {noReturnApprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );
           
            case "closedPoList":
                    return (
                        <React.Fragment>
                            <table className="vendorListTable">
                                <thead>
                                    <tr className="vendorListHeader">
                                        <td className="vendorListHeading">PO No</td>
                                        <td className="vendorListHeading">Order Date</td>
                                        <td className="vendorListHeading">Company Name</td>
                                        <td className="vendorListHeading">Exp Delivery Date</td>
                                        <td className="vendorListHeading">Order Status</td>
                                        <td className="vendorListHeading">Created By</td>
                                        <td className="vendorListHeading">Modified By</td>
                                        <td className="vendorListHeading">Closed By</td>
                                        <td className="vendorListHeadingActions">Actions</td>
                                    </tr>
                                </thead>
                                <tbody>{poClosedList.length > 0 ? renderPoClosedList() : null}</tbody>
                            </table>
                            {noClosedRecords ? (
                                <div className="noRecordsYet">No records found</div>
                            ) : loading ? (
                                <div className="loader" style={{ margin: "1rem auto" }}></div>
                            ) : null}
                        </React.Fragment>
                    );
    
            default:
                return <div style={{ padding: "2rem", textAlign: "center" }}>Section Under Construction</div>;
        }
    };
    const access = isLogged.access["Purchase Order"];
    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "poList":
                listCopy = poList.slice();
                break;

            case "poApprovedList":
                listCopy = poApprovedList.slice();
                break;

            case "poDeclinedList":
                listCopy = poDeclinedList.slice();
                break;

            case "poReturnUnapprovedList":
                listCopy = poReturnUnapprovedList.slice();
                break;

            case "poReturnApprovedList":
                listCopy = poReturnApprovedList.slice();
                break;

            case "poClosedList":
                listCopy = poClosedList.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "poList":
                setPoList(listCopy);
                break;

            case "poApprovedList":
                setPoApprovedList(listCopy);
                break;

            case "poDeclinedList":
                setPoDeclinedList(listCopy);
                break;

            case "poReturnUnapprovedList":
                setPoReturnUnapprovedList(listCopy);
                break;

            case "poReturnApprovedList":
                setPoReturnApprovedList(listCopy);
                break;

            case "poClosedList":
                setPoClosedList(listCopy);
                break;
        }
    };

    const renderPoUnapprovedList = () => {
        return poList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr className={item.collapse ? "vendorList" : "vendorListExpanded"} key={item.poId} onClick={() => editItemCollapse("poList", i)}>
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                        <td className="vendorListInfo">{item.plantName}</td>
                        <td className="vendorListInfo">{item.storageName}</td>
                        <td className="vendorListInfo">{item.poStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={item.poStatus === "Draft" ? approveIconDisabled : approveIcon}
                                title={item.poStatus === "Draft" ? "Can't approve Draft" : (access === "App/Dec"? "Approve Purchase Order" : "Access Denied")}
                                style={item.poStatus !== "Draft" && access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.poStatus !== "Draft" && access === "App/Dec") {
                                        viewPurchaseOrderId(item.poId);
                                        setSection("purchaseOrderDetail");
                                        setApproval(true);
                                    }
                                }}
                            />
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // viewItem(item.item.itemId);
                                    viewPurchaseOrderId(item.poId);
                                    setSection("purchaseOrderDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" || access=== "App/Dec" ? "Edit Item" : "Access Denied"}
                                onClick={(e) => {e.stopPropagation();
                                    if (access === "Edit" || access === "App/Dec") {
                                        editPurchaseOrderId(item.poId);
                                        setSection("editPurchaseOrder");
                                        setEditAndApprove(false);
                                    }
                                }}
                                style={access === "Edit" || access=== "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="unapprovedPo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderPoApprovedList = () => {
        return poApprovedList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.poId}
                        onClick={() => editItemCollapse("poApprovedList", i)}
                    >
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                        <td className="vendorListInfo">{item.poStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.editedBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // viewItem(item.item.itemId);
                                    viewPurchaseOrderId(item.poId);
                                    setSection("purchaseOrderDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Export Record"
                                className="vendorActionImages"
                                src={downloadIcon}
                                title="Export PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    exportData(isLogged, "poApprovedSingleItem", { url: String(item.poId) });
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poApprovedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approvedPo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderPoDeclinedList = () => {
        return poDeclinedList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.poId}
                        onClick={() => editItemCollapse("poDeclinedList", i)}
                    >
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                        <td className="vendorListInfo">{item.plantName}</td>
                        <td className="vendorListInfo">{item.storageName}</td>
                        <td className="vendorListInfo">{item.poStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        {/* <td className="vendorListInfo">{item.storageName}</td> */}

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // viewItem(item.item.itemId);
                                    viewPurchaseOrderId(item.poId);
                                    setSection("purchaseOrderDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poDeclinedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="declinedPo" reason={item.declineReason} data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderPoReturnUnapprovedList = () => {
        return poReturnUnapprovedList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.poId}
                        onClick={() => editItemCollapse("poReturnUnapprovedList", i)}
                    >
                        <td className="vendorListInfo">{item.prNo}</td>
                        <td className="vendorListInfo">{item.prDate}</td>
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.prStatus}</td>
                        <td className="vendorListInfo">{item.inwardStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={item.poStatus === "Draft" ? approveIconDisabled : approveIcon}
                                title={item.poStatus === "Draft" ? "Can't approve Draft" : (access === "App/Dec" ? "Approve Purchase Return" : "Access Denied")}
                                style={item.poStatus !== "Draft" && access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.poStatus !== "Draft" && access=== "App/Dec") {
                                        viewPurchaseReturnId(item.prId);
                                        setSection("purchaseOrderReturnDetail");
                                        setApproval(true);
                                    }
                                }}
                            />
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewPurchaseReturnId(item.prId);
                                    setSection("purchaseOrderReturnDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" || access=== "App/Dec" ? "Edit Item" : "Access Denied"}
                                onClick={(e) => {e.stopPropagation();
                                    if (access === "Edit" || access === "App/Dec") {
                                        editPurchaseReturnId(item.prId);
                                        setSection("editPurchaseReturn");
                                        setEditAndApprove(false);
                                    }
                                }}
                                style={access === "Edit" || access === "App/Dec"? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poReturnUnapprovedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderReturnSubTable tab="unapprovedPo" data={item.prLineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderPoReturnApprovedList = () => {
        return poReturnApprovedList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.poId}
                        onClick={() => editItemCollapse("poReturnApprovedList", i)}
                    >
                        <td className="vendorListInfo">{item.prNo}</td>
                        <td className="vendorListInfo">{item.prDate}</td>
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.prStatus}</td>
                        <td className="vendorListInfo">{item.inwardStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewPurchaseReturnId(item.prId);
                                    setSection("purchaseOrderReturnDetail");
                                    setApproval(false);
                                }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poReturnApprovedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderReturnSubTable tab="unapprovedPo" data={item.prLineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    
    const renderPoClosedList = () => {
        return poClosedList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.poId}
                        onClick={() => editItemCollapse("poClosedList", i)}
                    >
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                        <td className="vendorListInfo">{item.poStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.editedBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // viewItem(item.item.itemId);
                                    viewPurchaseOrderId(item.poId);
                                    setSection("purchaseOrderDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Export Record"
                                className="vendorActionImages"
                                src={downloadIcon}
                                title="Export PO Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    exportData(isLogged, "poApprovedSingleItem", { url: String(item.poId) });
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("poClosedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="closedPo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab === "approvedPo" || tab === "closedPo" ? "11" : "9"} className="vendorListInnerTableWrapper">
                {tab === "declinedPo" ? (
                    <div style={{ boxSizing: "border-box", width: "100%", padding: "10px", backgroundColor: "#eee" }}>
                        <span style={{ color: "red", fontWeight: "bold" }}>Reason:&nbsp;&nbsp;&nbsp;</span>
                        <b style={{ color: "#333333" }}> {reason}</b>
                    </div>
                ) : null}
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item</td>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">HSN No</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                            <td className="vendorListInnerTableHeading">Unit Price</td>
                            <td className="vendorListInnerTableHeading">Tax (%)</td>
                            <td className="vendorListInnerTableHeading">Net Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.poLineItemId}>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.hsnCode !== null ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                    <td className="vendorListInnertableInfo">{row.unitPrice}</td>
                                    <td className="vendorListInnertableInfo">{row.tax}</td>
                                    <td className="vendorListInnertableInfo">{row.netPrice}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const RenderReturnSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab === "approvedPo" || tab === "closedPo" ? "11" : "9"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">HSN No</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Rejected Quantity</td>
                            <td className="vendorListInnerTableHeading">Return Quantity</td>
                            <td className="vendorListInnerTableHeading">Return Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.poLineItemId}>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.hsnCode !== null ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.rejectedQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.returnQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.returnPrice}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div  style={{width:"250px",marginLeft:"25px"}}>
                <FormElement  setInput={(value)=>{setPlantId(value);}}
                                        inputType={"multiSelect"}
                                        value={plantId}
                                        hintText={"Plant Name"}
                                        options={isLogged.plantOptions}
                                        colSpan={1}
                                        rowSpan={1}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}/>
            </div>
            <div className="poListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewPurchaseOrderId: (viewId) => dispatch(viewPoId(viewId)),
        editPurchaseOrderId: (viewId) => dispatch(editPoId(viewId)),
        viewPurchaseReturnId: (returnId) => dispatch(viewPoReturnId(returnId)),
        editPurchaseReturnId: (returnId) => dispatch(editPoReturnId(returnId)),
        setPurchaseOrderTab: (tab) => dispatch(setPoTab(tab)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderList);
