import React, { useState } from 'react';
import { Fragment } from 'react';
import MenuList from '../../Menu/MenuList';
import PageTitle from '../../SmallComponents/PageTitle';
import TopBanner from '../../SmallComponents/TopBanner';
import packingSlipIcon from "assets/packing-slip.svg";
import { connect } from 'react-redux';
import CreatePackingSlip from './CreatePackingSlip';
import PackingSlipList from "./PackingSlipList";
import EditPackingSlip from './EditPackingSlip';
import { useHistory } from 'react-router-dom';
import PackingSlipDetail from './packingSlipDetail';
import { exportPDF } from 'components/CommonFunctions/ExportPDF';

const PackingSlip = ({isLogged}) => {
  const history = useHistory();
  const [currentSection, setCurrentSection] = useState("packingSlipList");
  const [downloadReportParams,setDownloadReportParams]=useState({});

  var goBackButton={className:"goBackButton",buttonName: "Back",setSection:setCurrentSection,sectionName:"packingSlipList"};
  var createPackingSlip={className:"employeeButton",buttonName:"Create Packing Slip",setSection:setCurrentSection,sectionName:"createPackingSlip"};
  const pushBack = (section) => {
    history.push(section);
};
  var goBackToPackagingButton = {
    buttonName: "Back",
    className: "goBackButton",
    setSection: pushBack,
    sectionName: "/packaging",
};
var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "packingSlip",downloadReportParams) }, sectionName: "packingSlipList" };

  const renderSection = (section) => {
    switch(section){
      case "createPackingSlip":
        return (
          <React.Fragment>
              <PageTitle imgSrc={packingSlipIcon} pageTitle="Create Packing Slip" buttonList={[goBackButton]} />
              <CreatePackingSlip setSection={setCurrentSection}  screenName="Packing" />
          </React.Fragment>
      );

      case "editPackingSlip":
        return (
          <React.Fragment>
              <PageTitle imgSrc={packingSlipIcon} pageTitle="Edit Packing Slip" buttonList={[goBackButton]} />
              <EditPackingSlip setSection={setCurrentSection} screenName="Packing" />
          </React.Fragment>
        );

      case "packingSlipList":
        return (
          <React.Fragment>
              <PageTitle imgSrc={packingSlipIcon} pageTitle="Packing Slip"
                  buttonList={isLogged.access["Packing Slip"] === "Create" || isLogged.access["Packing Slip"] === "Edit" || isLogged.access["Packing Slip"] === "App/Dec" ? [createPackingSlip,goBackToPackagingButton] : [goBackToPackagingButton]} />
              <PackingSlipList
                  setSection={(value) => setCurrentSection(value)}
                  screenName="Packing"
                  // setApproval={(value) => setApprovalPage(value)}
                  // setEditAndApprove={(value) => setEditAndApprove(value)}
              />
          </React.Fragment>
      );

      case "packingSlipDetail":
        return (
          <React.Fragment>
              <PageTitle imgSrc={packingSlipIcon} pageTitle="Packing Slip Detail" buttonList={[goBackButton,downloadButton]} />
              <PackingSlipDetail setSection={setCurrentSection} setDownloadReportParams={setDownloadReportParams} screenName="Packing"/>
          </React.Fragment>
        )
      default:
        return <></>
    }
  }

  return (
    <Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Store" selectedSubMenu="Packaging" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </Fragment>

  )
};

const mapStateToProps = (state) => ({
  isLogged: state.isLogged
})


export default connect(mapStateToProps)(PackingSlip);