import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { editOpdId, setOPDTab, viewOpdId } from "../../redux/Store/StoreInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { exportData } from "../CommonFunctions/ExportExcel";
import FormElement from "../SmallComponents/FormElement";
import Pagination from "../CommonFunctions/pagination";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import downloadIcon from "../../assets/download.svg";
import approveIcon from "../../assets/approve2.svg";
import { handleSort } from "../CommonFunctions/utils";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import approveIconDisabled from "../../assets/approve.svg";

const OpdList = ({
    // state
    isLogged,
    storeInfo,
    setEditAndApprove,

    // dispatch
    addTokenToState,
    setOpdTab,
    viewOpdId,
    editOpdId,

    // props
    setTab,
    setSection,
    setApproval,
}) => {
    const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
    const [noApprovedRecords, setNoApprovedRecords] = useState(false);

    const [opdList, setOpdList] = useState([]);
    const [opdApprovedList, setOpdApprovedList] = useState([]);

    const [loading, setLoading] = useState(true);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const opdCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return opdList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,opdList,PageSize]);
      const opdApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return opdApprovedList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,opdApprovedList,PageSize]);
    const setSelectedTab = (tab) => {
        setOpdTab(tab);
    };
    
    const [plantId,setPlantId] = useState('');
    const tablist = [
        { id: "opdList", name: "OPD List" },
        { id: "approvedOpdList", name: "Approved OPD List" },
    ];

    useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        await checkToken();
        await getOpdUnapproved();
    };

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getOpdUnapproved() {
        var data = await fetchData({
            requestingPage: "opdList",
            method: "post",
            url: "store-fetch/opd-unapproved-list",
            headers: { token: isLogged.accessToken, module: "OPD" },
            data:{plantId}
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            data2.sort((a, b) => a.opdId - b.opdId);

            setOpdList(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getOpdApproved() {
        var data = await fetchData({
            requestingPage: "opdList",
            method: "post",
            url: "store-fetch/opd-approved-list",
            headers: { token: isLogged.accessToken, module: "OPD" },
            data:{plantId}
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            data2.sort((a, b) => a.opdId - b.opdId);
            setOpdApprovedList(data2);
            if (data.asset.length > 0) setNoApprovedRecords(false);
            else setNoApprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    useEffect(async () => {
        setLoading(true);
        await checkToken();

        switch (storeInfo.storeTab) {
            case "opdList":
                setOpdList([]);
                getOpdUnapproved();
                break;

            case "approvedOpdList":
                setOpdApprovedList([]);
                getOpdApproved();
                break;

            default:
                break;
        }
    }, [storeInfo.storeTab,plantId]);

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "opdList":
                listCopy = opdList.slice();
                break;

            case "opdApprovedList":
                listCopy = opdApprovedList.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });


        switch (list) {
            case "opdList":
                setOpdList(listCopy);
                break;

            case "opdApprovedList":
                setOpdApprovedList(listCopy);
                break;
        }
    };
    const access = isLogged.access["OPD"];
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={storeInfo.storeTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => {
                        setSelectedTab(tab.id);
                        switch (tab.id) {
                            case "opdList":
                                setTab("opdList");
                                return;

                            case "approvedOpdList":
                                setTab("approvedOpdList");
                                return;

                            default:
                                setTab("");
                        }
                    }}
                >
                    {tab.name}
                    {tab.id === "contactDetails" ? storeInfo.soTab === "contactDetails" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const [order, setOrder] = useState({ col: "opdNo", state: true, data: "" });

    const CommonColumns = ({ data, tab }) => {

        return (
            <tr className="vendorListHeader" style={{ zIndex: "1" }}>
                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                    setOrder({
                        col: "opdNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "opdNo",
                        state: order.state,
                    });
                }}
                >OPD No<img
                        className="sortArrow"
                        src={
                            order.col === "opdNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "packingDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "packingDate",
                        state: order.state,
                    });
                }}
                >Order Date<img
                        className="sortArrow"
                        src={
                            order.col === "packingDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "companyName",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "companyName",
                        state: order.state,
                    });
                }}
                >Company Name<img
                        className="sortArrow"
                        src={
                            order.col === "companyName"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "orderNo",
                        state: order.state,
                    });
                }}
                >Order No<img
                        className="sortArrow"
                        src={
                            order.col === "orderNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderType",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "orderType",
                        state: order.state,
                    });
                }}
                >Order Type<img
                        className="sortArrow"
                        src={
                            order.col === "orderType"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "storageName",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "storageName",
                        state: order.state,
                    });
                }}
                >Storage Name<img
                        className="sortArrow"
                        src={
                            order.col === "storageName"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                    });
                }}
                >Created By<img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                {tab === "approved" ? <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "approvedBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "approvedBy",
                        state: order.state,
                    });
                }}
                >Approved By <img
                        className="sortArrow"
                        src={
                            order.col === "approvedBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td> : ''}
                <td className="vendorListHeadingActions">Actions</td>
            </tr>
        )
    };

    const renderSubSection = (tab) => {
        switch (tab) {
            case "opdList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                               <CommonColumns data={opdCurrentPage} tab={"unapproved"}/>
                            </thead>
                            <tbody>{opdList.length > 0 ? renderOpdUnapprovedList() : null}</tbody>
                        </table>
                        {noUnapprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}

                    </React.Fragment>
                );

            case "approvedOpdList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                               <CommonColumns data={opdApprovedCurrentPage} tab={"approved"}/>
                            </thead>
                            <tbody>{opdApprovedList.length > 0 ? renderOpdApprovedList() : null}</tbody>
                        </table>
                        {noApprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        
                    </React.Fragment>
                );
        }
    };

    const renderOpdUnapprovedList = () => {
        return opdCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.opdId}
                        onClick={() => editItemCollapse("opdList", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.opdNo}</td>
                        <td className="vendorListInfo">{item.packingDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>

                        <td className="vendorListInfo">{item.orderNo}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        <td className="vendorListInfo">
                            {item.storageName} ({item.plantName})
                        </td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={approveIcon}
                                title={access === "App/Dec"?"Approve OPD" : "Access Denied"}
                                style={access === "App/Dec"? { cursor: "pointer" } : {cursor: "not-allowed"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.soStatus !== "Draft" && access === "App/Dec") {
                                        viewOpdId(`${item.orderType}/${item.opdId}`);
                                        setSection("opdDetail");
                                        setApproval(true);
                                    }
                                }}
                            />

                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View OPD Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewOpdId(`${item.orderType}/${item.opdId}`);
                                    setSection("opdDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access==="App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" || access==="App/Dec" ? "Edit Item" : "No Edit Access"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (access === "Edit" || access==="App/Dec") {
                                        editOpdId(`${item.orderType}/${item.opdId}`);
                                        setSection("editOpd");
                                        setEditAndApprove(false);
                                    }
                                }}
                                style={access === "Edit" ||access==="App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("opdList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="unapproved" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderOpdApprovedList = () => {
        return opdApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.opdId}
                        onClick={() => editItemCollapse("opdApprovedList", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.opdNo}</td>
                        <td className="vendorListInfo">{item.packingDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.orderNo}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        {/* <td className="vendorListInfo">{item.packingStatus}</td> */}
                        <td className="vendorListInfo">
                            {item.storageName} ({item.plantName})
                        </td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View OPD Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewOpdId(`${item.orderType}/${item.opdId}`);
                                    setSection("opdDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Export Record"
                                className="vendorActionImages"
                                src={downloadIcon}
                                title="Export OPD Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    exportData(isLogged, "opdApprovedSingleItem", { url: String(item.opdId), method: "POST" });
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("opdApprovedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approved" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={ tab==='approved' ?"9" : '8'} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            { isLogged.showHideWeight ? 
                            <>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            </> : ' '}
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.itemId}>
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    { isLogged.showHideWeight ? 
                                    <>
                                    <td className="vendorListInnertableInfo">{row.from}</td>
                                    <td className="vendorListInnertableInfo">{row.to}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight}</td>
                                    </> : ''
                                    }
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };
    const renderPagination=()=>{
        switch(storeInfo.storeTab){
            case "opdList":
                return(
                    <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={opdList.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                )
            case "approvedOpdList":
                return(
                    <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={opdApprovedList.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                )
        }
    }
    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                <span className="listPageParams">
                    <FormElement setInput={(value) => { setPlantId(value); }}
                        inputType={"multiSelect"}
                        value={plantId}
                        hintText={"Plant Name"}
                        options={isLogged.plantOptions}
                        colSpan={1}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false} />
                </span>
                <span style={{ flexGrow: 0, width: "80px" }}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>

            </div>
            <div className="vendorListArea">{renderSubSection(storeInfo.storeTab)}</div>
            {renderPagination()}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        setOpdTab: (tab) => dispatch(setOPDTab(tab)),
        viewOpdId: (opdId) => dispatch(viewOpdId(opdId)),
        editOpdId: (opdId) => dispatch(editOpdId(opdId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpdList);
