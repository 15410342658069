import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import Modal from "react-modal";
import CreateEditModal from "components/SmallComponents/CreateEditModal";

const PurchaseImportDetail = ({ isLogged, poInfo, addTokenToState, setSection ,approvalPage}) => {
    const [purchaseImportDetail, setPurchaseImportDetail] = useState({});
    const [invoiceList, setInvoiceList] = useState([]);
    const [vendorLogo, setVendorLogo] = useState([]);
    const [totalInvoicePageAmount,setTotalInvoicePageAmount] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [selectedTab, setSelectedTab] = useState("");
    const [errorMessage,setErrorMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getPoDetail();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    const findInvoiceCurrentPageTotal=({lineItems,...rest})=>{
        let lineItemsSum= lineItems?.reduce((prev,row)=>prev+parseFloat(row.netAmountAfterTax),0);
        let invoicePageSum =  Object.values(rest).reduce((acc,val)=>acc+(parseFloat(val) || 0),0)
        return [lineItemsSum,lineItemsSum+invoicePageSum];
    }

    async function getPoDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "po-fetch/purchase-import-detail3/" + poInfo.viewPoImportId,
            headers: { token: isLogged.accessToken, module: "Purchase Import" },
        });
        if (data.msg === "success") {
            let importDetail=data.asset.importList[0];
            let invoiceLineItemsTemp = importDetail.invoiceLineItems.length > 0 ?  importDetail.invoiceLineItems : [];

            invoiceLineItemsTemp.forEach((invPage)=>{
                let [lineItemsSum,invoicePageAmount] = findInvoiceCurrentPageTotal({lineItems: invPage.itemList,
                    clearingAgent: invPage.clearingAgentTotalAmount,
                    insurance: invPage.insuranceAmount,
                    transporter: invPage.transporterBillTotalAmount,
                    linerBill: invPage.linerBillItems.reduce((acc,row)=>row.linerBillTotalAmount+acc,0),
                    exciseTax: invPage.exciseTaxAmount,
                    splExciseTax: invPage.splExciseTaxAmount,
                    interest: invPage.interestAmount,
                    penalty: invPage.penaltyAmount,
                });
                invPage["invoicePageAmount"] = invoicePageAmount;
                // console.log(invoicePageAmount,'invPage',invPage.transporterBillAmount,invPage.insuranceAmount,invPage.clearingAgentBillAmount,lineItemsSum)
                invPage["lineItemsSum"] =  lineItemsSum;
            });

            let allInvoicePageAmount = invoiceLineItemsTemp.reduce((prev,page)=>prev+page["invoicePageAmount"],0);

            setInvoiceList(invoiceLineItemsTemp);

            setTotalInvoicePageAmount(allInvoicePageAmount);
            setPurchaseImportDetail(importDetail);

            var tabListParsed = [];
            if (importDetail.invoiceLineItems.length > 0) {
                importDetail.invoiceLineItems.forEach((invoice) => {
                    tabListParsed.push({ id: invoice.invoiceNo, name: invoice.invoiceNo });
                });
                setTabList(tabListParsed);
                setSelectedTab(tabListParsed[0].id);
            } else {
                console.log("==== No Invoices");
            }
        } else {
            console.log(data);
        }
    }

    async function getDiagramImage(section, imageName) {
        setDiagramImage();
        setIsOpen(true);
        setIsLoading(true);

        var url = "";
        switch (section) {
            case "packing":
                url = `image/purchase-import-document-packing/${imageName}`;
                break;

            case "billOfLading":
                url = `image/purchase-import-document-billoflading/${imageName}`;
                break;

            case "bankDocument":
                url= `image/purchase-import-document-bankdocument/${imageName}`;
                break;

            case "clearingAgent":
                url = `image/purchase-import-document-clearing/${imageName}`;
                break;

            case "insuranceDocument":
                url = `image/purchase-import-document-insurance/${imageName}`;
                break;

            case "transporterDocument":
                url = `image/purchase-import-document-transporter/${imageName}`;
                break;

            case "linerBillDocument":
                url = `image/purchase-import-document-linerbilldocument/${imageName}`;
                break;
            default :
                url="";
        }
        // var url2 = "image/purchase-import-document-billoflading/";

        var data = await fetchData({
            requestingPage: "diagramImage",
            headers: { token: isLogged.accessToken, module: "Purchase Import" },
            url: url,
        });
        if (data === "") {
            setIsOpen(false);
            setIsLoading(false);
        } else if(url.includes('.pdf')){
            setIsLoading(false);
         let pdfFile= <object data={`data:application/pdf;base64,${data}`} type="application/pdf" style={{width: "-webkit-fill-available",height: "-webkit-fill-available"}}>
      <p>Alternative text</p>
     </object>
     setDiagramImage(pdfFile)
        }else if(url.includes('.doc')){
            setIsLoading(false);
            setIsOpen(false);
            var link = document.createElement('a');
            link.href = new Blob([data]).arrayBuffer();
            link.download= 'import-bank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        }else {
            let image = <img className="modalImage" src={`data:image/jpeg;base64,${data}`} alt="Document" />
            setDiagramImage(image);
            setIsLoading(false);
        } 
    }

    const RenderTabList = () => {
        return tabList.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };

    const reduceLinerBill=(lineItems,key)=>lineItems?.reduce((acc,row)=>acc+row[key],0);

    const approvePurchaseImport=async()=>{
        let response = await fetchData({
            method: 'put',
            requestingPage: 'createItem',
            data: {page:"Approve"},
            url: 'po-edit/purchase-import3/'+poInfo.viewPoImportId,
            headers: {token: isLogged.accessToken,module:"Purchase Import"}
          })
          if(response.msg==='success'){
            setApprovalModalText('SuccessFully Approved Purchase Import')
          }else{
            setApprovalModalText(`Approval Failed:${response.desc}`);
            setErrorMessage([response.desc]);
          }
          setApprovalSubmitted(true);    
    }
    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
          return errorMessage[0];
        }
      };
    const ApproveButtons = () => {
        return (
          <div className="formSubmitArea">
            <div className="formSubmitInnerArea">
              <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
              {/* 
              <button
                className="saveButton"
                onClick={() => {
                  setEditAndApprove(true);
                  setSection("editSalesOrder");
                  editSoId(poInfo.viewSoId);
                }}>
                Edit
              </button> */}
              <button
                className="submitButton"
                onClick={() => {
                    setApprovalModalOpen(true);
                  approvePurchaseImport();
                }}>
                Approve
              </button>
            </div>
          </div>
        );
      };

    const RenderInfo = () => {
        var invoice = invoiceList.filter((item) => item.invoiceNo === selectedTab)[0];
        var containerTotalWeight = (invoice?.containerLineItems.reduce((acc,el)=>acc+parseFloat(el["containerWeightMt"]),0))

        if (invoice) {  
            var lineItemsSum = invoice?.itemList?.reduce((prev,row)=>prev+parseFloat(row.netAmountINR),0)
            var lineItemsTotalSum = invoice?.itemList?.reduce((prev,row)=>prev+parseFloat(row.netAmountAfterTax),0);
            var lineItemsTaxSum = lineItemsTotalSum - lineItemsSum;
            
            invoice.linerBillAmount = reduceLinerBill(invoice.linerBillItems,"linerBillAmount");
            invoice.linerBillTaxAmount = reduceLinerBill(invoice.linerBillItems,"linerBillTaxAmount");
            invoice.linerBillTotalAmount = reduceLinerBill(invoice.linerBillItems,"linerBillTotalAmount");
            
            var invoicePageSum = (parseFloat(invoice?.['clearingAgentBillAmount']) || 0)+
                            (parseFloat(invoice?.['transporterBillAmount']) || 0)+
                            (parseFloat(invoice?.['linerBillAmount'] || 0)) +
                            (parseFloat(invoice?.['insuranceAmount'] || 0))+
                            (parseFloat(invoice?.['exciseTaxAmount'] || 0)) +
                            (parseFloat(invoice?.['splExciseTaxAmount'] || 0)) +
                            (parseFloat(invoice?.['interestAmount'] || 0)) +
                            (parseFloat(invoice?.['penaltyAmount'] || 0)) +
                            lineItemsSum;

            var invoicePageTaxTotal = (parseFloat(invoice?.["clearingAgentTaxAmount"]) || 0) +
            (parseFloat(invoice?.["transporterBillTaxAmount"]) || 0) +
            (parseFloat(invoice?.["linerBillTaxAmount"]) || 0) + lineItemsTaxSum;
            
            var invoicePageTotal = invoicePageSum + invoicePageTaxTotal;
            return (
                <React.Fragment>
                    {/* ======================================================== */}
                    {/* Invoice */}
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Invoice</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            <span className="purchaseSummaryCategory">Invoice No</span>
                            <span className="purchaseSummaryValue">: {invoice.invoiceNo}</span>
                        </div>
                        <div className="purchaseSummaryGrid">
                            <span className="purchaseSummaryCategory">Invoice Date</span>
                            <span className="purchaseSummaryValue">: {invoice.invoiceDate}</span>
                        </div>
                    </div>

                    {/* ======================================================== */}
                    {/* Balance Payment Detail */}
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole"> Balance Payment Detail</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            {invoice.balancePaymentDollar1  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment ($)</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentDollar1}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.balancePaymentRupee1  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment (₹)</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentRupee1}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.balancePaymentDate1  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentDate1}</span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid">
                            {invoice.balancePaymentDollar2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment ($)</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentDollar2}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.balancePaymentRupee2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment (₹)</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentRupee2}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.balancePaymentDate2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentDate2}</span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid">
                            {invoice.balancePaymentDollar3  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment ($)</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentDollar3}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.balancePaymentRupee3  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment (₹)</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentRupee3}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.balancePaymentDate3  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Balance Payment Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.balancePaymentDate3}</span>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>

                    {/* ======================================================== */}
                    {/* Bill Of Lading & Bank Detail */}
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Bill Of Lading</span>
                            <span className="purchaseSummaryRole">Bank Detail</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            {invoice.billOfLadingNo  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bill Of Lading No</span>
                                    <span className="purchaseSummaryValue">: {invoice.billOfLadingNo}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.vesselName  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Vessel Name</span>
                                    <span className="purchaseSummaryValue">: {invoice.vesselName}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.billOfLadingDocument  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bill Of Lading Document</span>
                                    {/* <span className="purchaseSummaryValue">: {invoice.billOfLadingDocument}</span> */}

                                    <span>
                                        {": "}
                                        <span
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                            onClick={() => {
                                                if (invoice.billOfLadingDocument ) {
                                                    getDiagramImage("billOfLading", invoice.billOfLadingDocument);
                                                }
                                            }}
                                        >
                                            Open link
                                        </span>
                                    </span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid">
                            {invoice.bankName  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Name</span>
                                    <span className="purchaseSummaryValue">: {invoice.bankName}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.bankRefNo  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Ref No</span>
                                    <span className="purchaseSummaryValue">: {invoice.bankRefNo}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.bankDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.bankDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.bankDocument ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Document</span>
                                    <span>
                                        {": "}
                                        <span
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                            onClick={() => {
                                                if (invoice.bankDocument) {
                                                    getDiagramImage("bankDocument", invoice.bankDocument);
                                                }
                                            }}
                                        >
                                            Open link
                                        </span>
                                    </span>
                                </React.Fragment>
                            ) : null}
                             {invoice.bankName2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Name 2</span>
                                    <span className="purchaseSummaryValue">: {invoice.bankName2}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.bankRefNo2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Ref No 2</span>
                                    <span className="purchaseSummaryValue">: {invoice.bankRefNo2}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.bankDate2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Date 2</span>
                                    <span className="purchaseSummaryValue">: {invoice.bankDate2}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.bankDocument2 ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Bank Document 2</span>
                                    <span>
                                        {": "}
                                        <span
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                            onClick={() => {
                                                if (invoice.bankDocument2) {
                                                    getDiagramImage("bankDocument", invoice.bankDocument2);
                                                }
                                            }}
                                        >
                                        Open Link
                                        </span>
                                    </span>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>

                    {/* ======================================================== */}
                    {/* Port Detail & Clearing Agent DDetail */}
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Port Detail</span>
                            <span className="purchaseSummaryRole">Clearing Agent Detail</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            {invoice.portArrivalDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Port Arrival Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.portArrivalDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.portDetail  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Port Detail</span>
                                    <span className="purchaseSummaryValue">: {invoice.portDetail}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.freeTimeExpiryDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Free Time Expiry Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.freeTimeExpiryDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.freeTimeDurationDays  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Free Time Duration Days</span>
                                    <span className="purchaseSummaryValue">: {invoice.freeTimeDurationDays}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.arrivalSeplDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Arrival SEPL Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.arrivalSeplDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.arrivalSeplDays  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Arrival SEPL Days</span>
                                    <span className="purchaseSummaryValue">: {invoice.arrivalSeplDays}</span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid">
                            {invoice.clearingAgentName  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Agent Name</span>
                                    <span className="purchaseSummaryValue">: {invoice.clearingAgentName}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.clearingAgentBillNo  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Agent Bill No</span>
                                    <span className="purchaseSummaryValue">: {invoice.clearingAgentBillNo}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.clearingAgentBillDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Agent Bill Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.clearingAgentBillDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.clearingAgentBillAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Agent Bill Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.clearingAgentBillAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.clearingAgentTaxAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Agent Tax Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.clearingAgentTaxAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.clearingAgentTotalAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Agent Total Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.clearingAgentTotalAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.clearingDocument  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Clearing Document</span>
                                    <span>
                                        {": "}
                                        <span
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                            onClick={() => {
                                                if (invoice.clearingDocument ) {
                                                    getDiagramImage("clearingAgent", invoice.clearingDocument);
                                                }
                                            }}
                                        >
                                            Open link
                                        </span>
                                    </span>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>

                    {/* ======================================================== */}
                    {/* Insurance Detail & Transporter Detail */}
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Insurance Detail</span>
                            <span className="purchaseSummaryRole">Transporter Detail</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            {invoice.insuranceCompanyName  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Company Name</span>
                                    <span className="purchaseSummaryValue">: {invoice.insuranceCompanyName}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.insuranceNo  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Insurance No</span>
                                    <span className="purchaseSummaryValue">: {invoice.insuranceNo}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.insuranceDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Insurance Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.insuranceDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.insuranceAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Insurance Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.insuranceAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.insuranceDocument  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Insurance Document</span>
                                    {/* <span className="purchaseSummaryValue">: {invoice.insuranceDocument}</span> */}
                                    <span>
                                        {": "}
                                        <span
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                            onClick={() => {
                                                if (invoice.insuranceDocument ) {
                                                    getDiagramImage("insuranceDocument", invoice.insuranceDocument);
                                                }
                                            }}
                                        >
                                            Open link
                                        </span>
                                    </span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid">
                            {invoice.transporterName  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Name</span>
                                    <span className="purchaseSummaryValue">: {invoice.transporterName}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.transporterBillNo  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Bill No</span>
                                    <span className="purchaseSummaryValue">: {invoice.transporterBillNo}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.transporterBillDate  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Bill Date</span>
                                    <span className="purchaseSummaryValue">: {invoice.transporterBillDate}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.transporterBillAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Bill Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.transporterBillAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.transporterBillTaxAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Bill Tax Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.transporterBillTaxAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.transporterBillTotalAmount  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Bill Total Amount</span>
                                    <span className="purchaseSummaryValue">: {invoice.transporterBillTotalAmount}</span>
                                </React.Fragment>
                            ) : null}
                            {invoice.transporterDocument  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Transporter Document</span>
                                    {/* <span className="purchaseSummaryValue">: {invoice.transporterDocument}</span> */}

                                    <span>
                                        {": "}
                                        <span
                                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                            onClick={() => {
                                                if (invoice.transporterDocument ) {
                                                    getDiagramImage("transporterDocument", invoice.transporterDocument);
                                                }
                                            }}
                                        >
                                            Open link
                                        </span>
                                    </span>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>

                    {/* ======================================================== */}
                    {/* Remarks */}

                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Remarks</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            <span className="purchaseSummaryCategory">Remarks</span>
                            <span className="purchaseSummaryValue">: {invoice.remarks}</span>
                        {/* </div>
                        <div className="purchaseSummaryGrid"> */}
                            <span className="purchaseSummaryCategory">Remarks Party</span>
                            <span className="purchaseSummaryValue">: {invoice.remarksParty}</span>
                        </div>
                    </div>

                    {/* Liner Bill Detail */}
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Liner Bill</span>
                        </div>
                    </div>
                    <table className="createVendorContactsTable" style={{ padding: "0 1rem 1rem" }}>
                        <thead>
                        <tr className="createVendorContactsTableHeader" style={{ textAlign: "left", fontSize: "12px" }}>
                            <td style={{ padding: "5px 10px" }}>Liner Bill Name</td>
                            <td style={{ padding: "5px 10px" }}>Liner Bill No</td>
                            <td style={{ padding: "5px 10px" }}>Date</td>
                            <td style={{ padding: "5px 10px" }}>Amount</td>
                            <td style={{ padding: "5px 10px" }}>Tax Amount</td>
                            <td style={{ padding: "5px 10px" }}>Total Amount</td>
                            <td style={{ padding: "5px 10px" }}>Document</td>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.linerBillItems?.length > 0
                            ? <>{ 
                                invoice.linerBillItems.map((line, index) => {
                                return (
                                    <tr className="createVendorContactsTableRows" style={{ fontSize: "12px" }} key={index}>
                                        <td>{line.linerBillName}</td>
                                        <td>{line.linerBillNo}</td>
                                        <td>{line.linerBillDate}</td>
                                        <td>{line.linerBillAmount}</td>
                                        <td>{line.linerBillTaxAmount}</td>
                                        <td>{line.linerBillTotalAmount}</td>
                                        <td>
                                            {line.linerBillDocument  ? (
                                                <React.Fragment>
                                                    <span
                                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                                        onClick={() => {
                                                            if (line.linerBillDocument ) {
                                                                getDiagramImage("linerBillDocument", line.linerBillDocument);
                                                            }
                                                        }}
                                                    >
                                                        Open link
                                                    </span>
                                                </React.Fragment>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                            </>
                            : null}
                        </tbody>
                    </table>
                    <div style={{ borderBottom: "2px solid #e4e4e4" }}></div>
                    {/* ======================================================== */}

                    {/* Container Detail */}
                    
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Container Detail</span>
                        </div>
                    </div>
                    <table className="createVendorContactsTable" style={{ padding: "0 1rem 1rem" }}>
                        <thead>
                        <tr className="createVendorContactsTableHeader" style={{ textAlign: "left", fontSize: "12px" }}>
                            <td style={{ padding: "5px 10px" }}>Container No</td>
                            <td style={{ padding: "5px 10px" }}>Container Weight(MT)</td>
                            <td style={{ padding: "5px 10px" }}>Container Size</td>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.containerLineItems?.length > 0
                            ? <>{ 
                                invoice.containerLineItems.map((line, index) => {
                                return (
                                    <tr className="createVendorContactsTableRows" style={{ fontSize: "12px" }} key={index}>
                                        <td>{line.containerNo}</td>
                                        <td>{Number(line.containerWeightMt)}</td>
                                        <td>{line.containerSize}</td>
                                    </tr>
                                );
                            })}
                            <tr className="createVendorContactsTableRows"><td style={{fontWeight:"600"}}>Total Weight</td><td>{Number(containerTotalWeight) || '\u00A0'}</td><td>&nbsp;</td></tr>
                            </>
                            : null}
                        </tbody>
                    </table>
                    <div style={{ borderBottom: "2px solid #e4e4e4" }}></div>
  {/* ======================================================== */}


  <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole">Other Charges</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem 1rem", borderBottom: "2px solid #e4e4e4" }}>
                        <div className="purchaseSummaryGrid">
                            <span className="purchaseSummaryCategory">Excise Tax Amount</span>
                            <span className="purchaseSummaryValue">: {invoice.exciseTaxAmount}</span>
                            <span className="purchaseSummaryCategory">Spl. Excise Tax Amount</span>
                            <span className="purchaseSummaryValue">: {invoice.splExciseTaxAmount}</span>
                        </div>
                        <div className="purchaseSummaryGrid">
                        <span className="purchaseSummaryCategory">Interest Amount</span>
                            <span className="purchaseSummaryValue">: {invoice.interestAmount}</span>
                            <span className="purchaseSummaryCategory">Penalty Amount</span>
                            <span className="purchaseSummaryValue">: {invoice.penaltyAmount}</span>
                        </div>
                    </div>
                    {/* ======================================================== */}
                    {/* Item Detail */}

                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole"> Item Detail</span>
                        </div>
                    </div>
                    <table className="createVendorContactsTable" style={{ padding: "0 1rem 1rem" }}>
                        <thead>
                        <tr className="createVendorContactsTableHeader" style={{ textAlign: "left", fontSize: "12px" }}>
                            <td style={{ padding: "5px 10px" }}>Container No</td>
                            <td style={{ padding: "5px 10px" }}>Item Name</td>
                            <td style={{ padding: "5px 10px" }}>Weight From</td>
                            <td style={{ padding: "5px 10px" }}>Weight To</td>
                            <td style={{ padding: "5px 10px" }}>Net Weight</td>
                            <td style={{ padding: "5px 10px" }}>Gross Weight</td>
                            <td style={{ padding: "5px 10px" }}>Rate / MT</td>
                            <td style={{ padding: "5px 10px" }}>Quantity (MT)</td>
                            <td style={{ padding: "5px 10px" }}>Net Amount(USD)</td>
                            <td style={{padding:'5px 10px'}}>Currency Conv. Rate</td>
                            <td style={{padding: '5px 10px'}}>Net Amount (INR)</td>
                            <td style={{padding: '5px 10px'}}>Tax Amount</td>
                            <td style={{ padding: "5px 10px" }}>Net Amount After Tax(INR)</td>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.itemList?.length > 0
                            ? invoice.itemList.map((line, index) => {
                                return (
                                    <tr className="createVendorContactsTableRows" style={{ fontSize: "12px" }} key={index}>
                                        <td>{line.containerNo}</td>
                                        <td>{line.itemName}</td>
                                        <td>{line.from ? line.from : " - "}</td>
                                        <td>{line.to ? line.to : " - "}</td>
                                        <td>{line.netWeight ? line.netWeight : " - "}</td>
                                        <td>{line.grossWeight ? line.grossWeight : " - "}</td>
                                        <td>{line.ratePerMt ? line.ratePerMt : " - "}</td>
                                        <td>{line.quantity ? line.quantity : " - "}</td>
                                        <td>{line.netAmount ? line.netAmount : " - "}</td>
                                        <td>{line.currencyConversionRate ? line.currencyConversionRate : '-'}</td>
                                        <td>{line.netAmountINR ? Number(line.netAmountINR).toFixed(2) : '-'}</td>
                                        <td>{Number(line.netAmountAfterTax).toFixed(2) - Number(line.netAmountINR).toFixed(2) || 0}</td>
                                        <td>{line.netAmountAfterTax ? Number(line.netAmountAfterTax).toFixed(2) : " - "}</td>
                                    </tr>
                                );
                            })
                            : null}
                            </tbody>
                    </table>

                    <div style={{ borderBottom: "2px solid #e4e4e4" }}></div>

                    {/* Item Summary */}

                    <div style={{ display: "flex", padding: "0 1rem" }}>
                        <div className="purchaseSummaryGridTitle">
                            <span className="purchaseSummaryRole"> Summary</span>
                        </div>
                    </div>
                    <table className="createVendorContactsTable" style={{ padding: "0 1rem 1rem" }}>
                        <thead>
                        <tr className="createVendorContactsTableHeader" style={{ textAlign: "left", fontSize: "12px" }}>
                            <td style={{ padding: "5px 10px" }}>Item Name</td>
                            <td style={{ padding: "5px 10px" }}>Advance Payment (₹)</td>
                            <td style={{ padding: "5px 10px" }}>Balance Payment (₹)</td>
                            <td style={{ padding: "5px 10px" }}>Net Amount (INR)</td>
                            <td style={{ padding: "5px 10px" }}>Net Amount (USD)</td>
                            <td style={{ padding: "5px 10px" }}>Currency Conv. Rate</td>
                            <td style={{ padding: "5px 10px" }}>Rate / KG</td>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.itemList?.length > 0
                            ? invoice.itemList.map((line, index) => {
                                return (
                                    <tr className="createVendorContactsTableRows" style={{ fontSize: "12px" }} key={index}>
                                        <td>{line.itemName}</td>                                        
                                        <td>{(Number(purchaseImportDetail?.advancePaymentRupee1) + Number(purchaseImportDetail?.advancePaymentRupee2) + Number(purchaseImportDetail?.advancePaymentRupee3))?.toFixed(2)  || 0}</td>
                                        <td>{(Number(invoice.balancePaymentRupee1) + Number(invoice.balancePaymentRupee2) + Number(invoice.balancePaymentRupee3))?.toFixed(2)  || 0}</td>
                                        <td>{line.netAmountINR ? Number(line.netAmountINR).toFixed(2) : '-'}</td>
                                        <td>{line.netAmount ? Number(line.netAmount).toFixed(2) : '-'}</td>
                                        <td>{Number(line.currencyConversionRate).toFixed(2) || 0}</td>
                                        <td>{Number(invoicePageSum / (line.quantity * 1000)).toFixed(2) || 0}</td>
                                    </tr>
                                );
                            })
                            : null}
                            </tbody>
                    </table>

                    <div style={{ borderBottom: "2px solid #e4e4e4" }}></div>
                    
                        <div className="vendorSummaryGrid purchaseImportSummary">
                        <span className="vendorSummaryHeader">Title</span><span className="vendorSummaryHeader">Amount</span><span className="vendorSummaryHeader"> Tax Amount</span><span className="vendorSummaryHeader">Total Amount</span>
                            <span className="vendorSummaryCategory">Clearing Agent Bill Amount </span>
                                <span className="vendorSummaryValue"> {invoice?.clearingAgentBillAmount || 0}</span><span  className="vendorSummaryValue">{invoice?.clearingAgentTaxAmount || 0}</span><span  className="vendorSummaryValue">{invoice?.clearingAgentTotalAmount || 0}</span>
                            <span className="vendorSummaryCategory">Insurance Bill Amount</span>
                                <span className="vendorSummaryValue"> {invoice?.insuranceAmount || 0}</span><span  className="vendorSummaryValue">0</span><span  className="vendorSummaryValue">{invoice?.insuranceAmount || 0}</span>
                            <span className="vendorSummaryCategory">Transporter Bill Amount</span>
                                <span className="vendorSummaryValue"> {invoice?.transporterBillAmount || 0}</span><span  className="vendorSummaryValue"> {invoice?.transporterBillTaxAmount || 0}</span><span  className="vendorSummaryValue"> {invoice?.transporterBillTotalAmount || 0}</span>
                            <span className="vendorSummaryCategory">Liner Bill Amount</span>
                                <span className="vendorSummaryValue"> {invoice?.linerBillAmount || 0}</span><span  className="vendorSummaryValue"> {invoice?.linerBillTaxAmount || 0}</span><span  className="vendorSummaryValue"> {invoice?.linerBillTotalAmount || 0}</span>
                            <span className="vendorSummaryCategory">Excise Tax Amount</span>
                            <span className="vendorSummaryValue">{invoice?.exciseTaxAmount || 0 }</span><span  className="vendorSummaryValue">0</span><span  className="vendorSummaryValue">{invoice?.exciseTaxAmount || 0 }</span>
                            <span className="vendorSummaryCategory">Spl. Excise Tax Amount</span>
                            <span className="vendorSummaryValue">{invoice?.splExciseTaxAmount || 0 }</span ><span  className="vendorSummaryValue">0</span><span  className="vendorSummaryValue">{invoice?.splExciseTaxAmount || 0 }</span>
                            <span className="vendorSummaryCategory">Interest Amount</span>
                            <span className="vendorSummaryValue">{invoice?.interestAmount || 0 }</span ><span  className="vendorSummaryValue">0</span><span  className="vendorSummaryValue">{invoice?.interestAmount || 0 }</span>
                            <span className="vendorSummaryCategory">Penalty Amount</span>
                            <span className="vendorSummaryValue">{invoice?.penaltyAmount || 0 }</span ><span  className="vendorSummaryValue">0</span><span  className="vendorSummaryValue">{invoice?.penaltyAmount || 0 }</span>
                            <span className="vendorSummaryCategory">Total Items</span>
                                <span className="vendorSummaryValue"> {Number(lineItemsSum).toFixed(2) || 0}</span><span  className="vendorSummaryValue">{Number(lineItemsTaxSum).toFixed(2)}</span><span  className="vendorSummaryValue">{Number(lineItemsTotalSum).toFixed(2)}</span>
                            <span className="vendorSummaryCategory">Total Amount of {selectedTab}</span>
                                <span className="vendorSummaryValue"> {Number(invoicePageSum).toFixed(2) || 0}</span><span  className="vendorSummaryValue">{Number(invoicePageTaxTotal).toFixed(2) || 0}</span><span  className="vendorSummaryValue">{Number(invoicePageTotal).toFixed(2) || 0}</span>
                    </div>
                </React.Fragment>
            );
        } else return null;
    };

    const renderSummaryInfo = () => {
        if (purchaseImportDetail ) {
            return (
                <React.Fragment>
                    <span className="purchaseSummaryRole" style={{ textTransform: "capitalize" }}>
                        {purchaseImportDetail.importNo}{" "}
                        <span
              className="vendorSummaryCategory"
              style={
                purchaseImportDetail.importStatus === "Declined"
                  ? { color: "red" }
                  : purchaseImportDetail.importStatus === "Approved"
                  ? { color: "#14c76a" }
                  : { color: "#333333" }
              }>
              ( {purchaseImportDetail.importStatus} )
            </span>
                    </span>
                    <div className="purchaseSummaryGrid" style={{ margin: 0, padding: "20px 0" }}>
                        <span className="purchaseSummaryCategory">Contract Date</span>
                        <span className="purchaseSummaryValue">: {purchaseImportDetail.poDate}</span>

                        <span className="purchaseSummaryCategory">Contract No</span>
                        <span className="purchaseSummaryValue">: {purchaseImportDetail.poNo}</span>

                        <span className="purchaseSummaryCategory">Seller Name</span>
                        <span className="purchaseSummaryValue">: {purchaseImportDetail.sellerName}</span>

                        <span className="purchaseSummaryCategory">Shipment Date</span>
                        <span className="purchaseSummaryValue">: {purchaseImportDetail.shipmentDate}</span>

                        {purchaseImportDetail.packingDocument  ? (
                            <React.Fragment>
                                <span className="purchaseSummaryCategory">Packing Document</span>

                                <span>
                                    {": "}
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "12px" }}
                                        onClick={() => {
                                            if (purchaseImportDetail.packingDocument ) {
                                                getDiagramImage("packing", purchaseImportDetail.packingDocument);
                                            }
                                        }}
                                    >
                                        Open link
                                    </span>
                                </span>
                            </React.Fragment>
                            ) : null}
                    </div>
                </React.Fragment>
            );
        }
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving PO. Please wait...");

    const [diagramImage, setDiagramImage] = useState("");
    const [approvalModalOpen,setApprovalModalOpen] = useState(false);
    const [approvalModalText,setApprovalModalText] = useState("Approving Purchase Import. Please wait...");
    const [approvalSubmitted,setApprovalSubmitted] = useState(false);
    const submitOkClick = () => {
        setApprovalModalOpen(false);
        if (errorMessage.length === 0) {
          setSection("purchaseImportList");
        }
        setApprovalModalText("Approving Purchase Import. Please wait...");
      };
    return (
        <React.Fragment>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                    content: {
                        textAlign: "center",
                        width: "70%",
                        height: "70%",
                        position: "fixed",
                        top: "15%",
                        left: "15%",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px gray",
                    },
                }}
            >
                {isLoading ? (
                    <React.Fragment>
                        <div className="loader" />
                        <span>Loading </span>
                    </React.Fragment>
                ) : null}
               {diagramImage ? diagramImage : ""}
            </Modal>
            <CreateEditModal
                modalIsOpen={approvalModalOpen}
                modalText={approvalModalText}
                dataSubmitted={approvalSubmitted}
                submitOkClick={submitOkClick}
            />
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    {/* <div className="vendorSummary"> */}
                    <div className="poSummaryText">{renderSummaryInfo()}</div>
                    {/* </div> */}

                    <div style={{ display: "flex" }}>
                        {/* <div style={{ width: "100%", backgroundColor: "lightblue", flex: "1" }}> */}

                        <div className="purchaseSummaryGrid" style={{ gridTemplateColumns: "1fr 1fr", margin: "0", padding: "0 20px" }}>
                            {purchaseImportDetail.advancePaymentDollar1  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment ($)</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentDollar1}</span>
                                </React.Fragment>
                            ) : null}
                            {purchaseImportDetail.advancePaymentRupee1  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment (₹)</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentRupee1}</span>
                                </React.Fragment>
                            ) : null}
                            {purchaseImportDetail.advancePaymentDate1  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment Date</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentDate1}</span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid" style={{ gridTemplateColumns: "1fr 1fr", margin: "0", padding: "0 20px" }}>
                            {purchaseImportDetail.advancePaymentDollar2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment ($)</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentDollar2}</span>
                                </React.Fragment>
                            ) : null}{" "}
                            {purchaseImportDetail.advancePaymentRupee2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment (₹)</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentRupee2}</span>
                                </React.Fragment>
                            ) : null}
                            {purchaseImportDetail.advancePaymentDate2  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment Date</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentDate2}</span>
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="purchaseSummaryGrid" style={{ gridTemplateColumns: "1fr 1fr", margin: "0", padding: "0 20px" }}>
                            {purchaseImportDetail.advancePaymentDollar3  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment ($)</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentDollar3}</span>
                                </React.Fragment>
                            ) : null}{" "}
                            {purchaseImportDetail.advancePaymentRupee3  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment (₹)</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentRupee3}</span>
                                </React.Fragment>
                            ) : null}
                            {purchaseImportDetail.advancePaymentDate3  ? (
                                <React.Fragment>
                                    <span className="purchaseSummaryCategory">Advance Payment Date</span>
                                    <span className="purchaseSummaryValue">: {purchaseImportDetail.advancePaymentDate3}</span>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>
                    <div className="vendorDetailTabArea">
                        <div className="vendorDetailTabs">
                            <RenderTabList />
                        </div>
                        {purchaseImportDetail?.invoiceLineItems?.length > 0 ? <RenderInfo /> : null}
                    </div>
                    <div className="poSummaryText" style={{flexDirection:"row"}}> 
                        <span className="purchaseSummaryRole" 
                        style={{ textTransform: "capitalize" }}>
                        Total Purchase Import Amount {" "}:{" "} </span><span>{" "}</span>
                     <span>{" "}{Number(totalInvoicePageAmount).toFixed(2)}</span></div>
                </div>
            </div>
            {approvalPage ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        // editPoId: (poId) => dispatch(editPoId(poId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseImportDetail);
