import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { RenderCustomer, RenderPlantDetails } from "../CustomerLedger/CustomerLedgerList";


const OutStandingStatementList=({isLogged,storeInfo,addTokenToState,setCurrentSection})=>{

    const [outStandingStatementData,setOutStandingStatementData] = useState({});
    const [noRecords,setNoRecords] = useState(false);
    const [isLoading,setLoading] = useState(true);
    const history = useHistory();
    
    useEffect(()=>{
         getInformation();
    },[])

    async function getInformation() {
    await checkToken();
    await getOutStandingDetails(storeInfo.outStandingParams)
    //setOutStandingStatementData(invoiceDetail);
  }
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          addTokenToState(isLogged.employeeId, token2);
        } else {
          console.log("Token not changed");
        }
      }

      async function getOutStandingDetails(data){
        var response = await fetchData({
          requestingPage: "cusReceiptList",
          method: "post",
          url: "fetch/outstanding-statement",
          data,
          headers: {token: isLogged.accessToken , module: "Sales Order"}
        });
    
        if (response.msg === "success") {
          if (response.asset?.voucherLineItems?.length) {

            // Overdue days calculation
            const zeroInvoiceList = response.asset.voucherLineItems.filter((invoice) => invoice.paymentBalance === 0);
            response.asset.voucherLineItems.map((invoice, index) => {
              zeroInvoiceList?.map((item, idx) => {
                if ((item.voucher_no === invoice.voucher_no) && invoice.paymentBalance) {
                  const date1 = new Date(invoice.voucher_date);
                  const date2 = new Date(item.voucher_date);
                  const diffTime = Math.abs(date2 - date1);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  response.asset.voucherLineItems[index]['overdueDays'] = diffDays;
                }
              });
            });
            // Set result data
            setOutStandingStatementData(response.asset);
          }
          else {
            setNoRecords(true);
          }
          setLoading(false);
        } else {
          setCurrentSection("outStandingStatement")
        }
      }

    return(
        <React.Fragment>
        <div className="formArea" style={{marginTop:"0",padding:"0"}}>
            <div style={{
                width: "1000px",height: "500px",
                margin: "0 2rem 4rem",padding: "1rem",
                border: "1px solid inherit",borderRadius: "5px",
                backgroundColor: "inherit",}}>
                   {isLoading ?  <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div> : null}
               {noRecords ?  <div className="noRecordsYet">No items available</div> :
                <div className="detailsPageWrapper"  style={{overflowX:"unset",flexDirection:"row",width:"110%"}}>
                    <div className="detailsPageContainer" style={{minHeight: "1400px"}}>
                        {/*customer & seller details*/}
                    <table className="invoiceBill" style={{width:"100%",border:"none"}}>
                        <tbody>
                            {/* <tr key="customerDetails">
                                <td style={{width:"300px",border:"none"}}>&nbsp;</td>
                                <td  style={{textAlign:"center",border:"none",borderBottom:"0.25px solid black"}}>
                                    <RenderCustomer customer={outStandingStatementData.customer}/>
                                </td>
                                <td style={{width:"300px",border:"none"}}>&nbsp;</td>
                            </tr> */}
                            <tr key="plantDetails">
                                <td colSpan={3} style={{textAlign:"center",border:"none"}}>
                                    <RenderPlantDetails plant={outStandingStatementData.plant}/>
                                </td>
                            </tr>
                            <tr key="dateRange">
                              <td colSpan={3} style={{textAlign:"center",fontSize:"11px",border:"none"}}>{outStandingStatementData?.fromDate?.split('T')[0] + ' to ' + outStandingStatementData?.toDate?.split('T')[0]}</td>
                            </tr>
                        </tbody>
                     </table>
                     {//voucher line items
                     }
                    <table className="innerTable" style={{width:"100%",border:"none"}}>
                  <thead>
                    <tr className="invoiceDetailContactsTableHeader" style={{color:"#000"}} key="headers">
                      {["Customer Name","Date","Invoice No","Created By","Opening Amount","Due on","Overdue by days","Final Amount"].map((item)=>
                      <td key={Math.random()} style={{borderLeft:"none",borderRight:"none",textAlign:"center"}}>
                        {item}
                        </td>)
                      }
                      </tr>
                  </thead>
                  <tbody>
                   {outStandingStatementData.voucherLineItems?.map((row, i)=>(
                    <tr className="invoiceDetailContactsTableRows" key={i}>
                      <td style={{border:"none",textAlign:"center"}}>{row.customer_name}</td>
                      <td style={{border:"none",textAlign:"center"}}>{row.voucher_date}</td>
                      <td style={{border:"none",textAlign:"center"}}>{row.voucher_no}</td>
                      <td style={{border:"none",textAlign:"center"}}>{row.createdBy}</td>
                      <td style={{border:"none",textAlign:"center"}}>{row.voucher_amount+' Dr'}</td>
                      <td style={{border:"none",textAlign:"center"}}>{row.due_date}</td>
                      <td style={{ border: "none", textAlign: "center" }}>{row.overdueDays || <>&nbsp;</>}</td>
                      <td style={{border:"none",textAlign:"center"}}>{row.final_amount || 0+' Dr'}</td>
                    </tr>
                   ))}
                   <tr className="invoiceDetailContactsTableRows" key="total">
                      <td colSpan={4} style={{border:"none"}}></td>
                      <td style={{borderLeft:"none",borderRight:"none",textAlign:"center"}}>
                        {outStandingStatementData.voucherLineItems?.reduce((acc,row)=>acc+row.voucher_amount,0) + ' Dr'}
                      </td>
                      <td colSpan={2} style={{border:"none"}}></td>
                      <td style={{borderLeft:"none",borderRight:"none",textAlign:"center"}}>
                        {outStandingStatementData.voucherLineItems?.reduce((acc,row)=>acc+row.final_amount,0)+' Dr'}
                      </td>
                   </tr>
                  </tbody>
              </table>
                    </div>
                </div>  }
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps=(state)=>{
    return{
        isLogged: state.isLogged,
        storeInfo: state.storeInfo
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        addTokenToState: (accessToken,employeeId)=> dispatch(addToken(accessToken,employeeId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(OutStandingStatementList)