import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";

import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";
import { validateEmail, validateEqualValues, validateMandatory, validateMobileNumber, validatePassword } from "../CommonFunctions/ValidateFields";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const EditEmployee = ({
    // props
    setSection,

    // state
    isLogged,
    employeeInfo,

    // dispatch
    addTokenToState,
}) => {
    const employeeParametersList = [
        // Primary Details
        ["photo", "employeeName", "employeeId", "roleId", "plantId", "dateOfJoining", "wages", "leaveEligible", "email", "department", "status","orgName"],

        // Personal details
        [
            "mobileNumber",
            "alternateMobileNumber",
            "personalEmail",
            "dateOfBirth",
            "idProofType",
            "idProofNo",
            "idProof",
            "address",
            "country",
            "state",
            "cityId",
            "zipCode",
            "bloodGroup",
        ],

        // Emergency Details
        ["emergencyContactName", "emergencyContactNumber", "emergencyContactRelationship"],

        // Bank Details
        ["accountHolderName", "accountNumber", "confirmAccountNumber", "bankName", "bankBranch", "ifscCode"],

        //Account Password
        ["accountPassword", "confirmAccountPassword"],

        ["additionalNotes"],
    ];

    const [employeeParameters, setemployeeParameters] = useState({
        // Primary Details
        photo: {
            inputType: "text",
            value: "",
            hintText: "Profile Photo",
            mandatory: false,
            colSpan: 5,
            url: "",
            error: false,
            errorMessage: "Upload a Profile photo",
        },
        employeeName: {
            inputType: "text",
            value: "",
            hintText: "Employee Name",
            mandatory: true,
            colSpan: 9,
            error: false,
            errorMessage: "Enter a valid name",
        },
        employeeId: {
            inputType: "text",
            value: "",
            hintText: "Employee Id",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Employee Id missing",
            disabled: true,
        },
        dateOfBirth: {
            inputType: "dateFromEditPage",
            value: null,
            hintText: "Date of Birth",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        roleId: {
            setServerSelection: true,
            inputType: "options",
            value: "",
            hintText: "Role",
            mandatory: true,
            colSpan: 5,
            options: [],
            error: false,
            errorMessage: "Please choose a role",
        },
        plantId: {
            setServerSelection: true,
            inputType: "options",
            value: "",
            hintText: "Plant name",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Plant Id",
        },
        dateOfJoining: {
            inputType: "dateFromEditPage",
            value: null,
            hintText: "Date of Joining",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        wages: {
            inputType: "text",
            value: "",
            hintText: "Wages",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Wages Column found empty",
        },
        leaveEligible: {
            inputType: "text",
            value: "",
            hintText: "No of leave eligible / year",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Enter eligible leave information",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Company Email ID",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Enter a valid email address",
        },

        department: {
            inputType: "options",
            value: "",
            hintText: "Department",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Management", optionName: "Management" },
                { optionId: "Marketing", optionName: "Marketing" },
                { optionId: "Sales", optionName: "Sales" },
                { optionId: "Service", optionName: "Service" },
                { optionId: "Operations", optionName: "Operations" },
                { optionId: "Projects", optionName: "Projects" },
                { optionId: "Production", optionName: "Production" },
                { optionId: "Maintenance", optionName: "Maintenance" },
                { optionId: "Stores / Warehouse", optionName: "Stores / Warehouse" },
                { optionId: "Purchase", optionName: "Purchase" },
                { optionId: "Quality", optionName: "Quality" },
                { optionId: "IT", optionName: "IT" },
                { optionId: "HR", optionName: "HR" },
                { optionId: "Office Admin", optionName: "Office Admin" },
                { optionId: "Branch Office", optionName: "Branch Office" },
            ],
            error: false,
            errorMessage: "Pick a department for Employee",
        },
        status: {
            inputType: "options",
            value: "Active",
            hintText: "Status",
            mandatory: true,
            colSpan: 4,
            options: [
                { optionId: "Active", optionName: "Active" },
                { optionId: "Inactive", optionName: "Inactive" }
            ],
            error: false,
            errorMessage: "Set active status for Employee",
        },
        orgName:{
            inputType:"text",
            value: "",
            hintText: "Organization Name",
            mandatory: true,
            colSpan: 4,
            error:false,
            errorMessage: "Organization Name Required"
        },
        idProofType: {
            inputType: "options",
            value: "",
            hintText: "ID Proof Type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Aadhaar", optionName: "Aadhaar" },
                { optionId: "Driving License", optionName: "Driving License" },
                { optionId: "Voter Id", optionName: "Voter Id" },
                { optionId: "Passport", optionName: "Passport" },
                { optionId: "PAN Card", optionName: "PAN Card" },
            ],
            error: false,
            errorMessage: "",
        },
        idProof: {
            inputType: "upload",
            value: "",
            hintText: "Id Proof",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Upload Id Proof",
        },
        idProofNo: {
            inputType: "text",
            value: "",
            hintText: "ID Proof Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Enter ID number",
        },

        // Personal Details
        mobileNumber: {
            inputType: "text",
            value: "",
            hintText: "Mobile Number",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        alternateMobileNumber: {
            inputType: "text",
            value: "",
            hintText: "Alternate Mobile Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        personalEmail: {
            inputType: "text",
            value: "",
            hintText: "Personal Email ID",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Enter a valid email address",
        },
        address: {
            inputType: "text",
            value: "",
            hintText: "Address",
            mandatory: true,
            colSpan: 16,
            error: false,
            errorMessage: "Please enter address",
        },
        country: {
            setServerSelection: true,
            inputType: "options",
            value: "",
            hintText: "Country",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Country",
        },
        state: {
            setServerSelection: true,
            inputType: "options",
            value: "",
            hintText: "State",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select State",
        },
        cityId: {
            setServerSelection: true,
            inputType: "options",
            value: "",
            hintText: "City",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select City",
        },
        zipCode: { inputType: "text", value: "", hintText: "Zip Code", mandatory: false, colSpan: 4, error: false, errorMessage: "Enter Zip code" },

        // Emergency Details
        bloodGroup: {
            inputType: "text",
            value: "",
            hintText: "Blood Group",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Provide employee blood group",
        },
        emergencyContactNumber: {
            inputType: "text",
            value: "",
            hintText: "Emergency Contact Number",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        emergencyContactName: {
            inputType: "text",
            value: "",
            hintText: "Emergency Contact Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter emergency contact's name",
        },
        emergencyContactRelationship: {
            inputType: "text",
            value: "",
            hintText: "Relationship",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide emergency contact's relation with employee",
        },

        // Banking Details
        accountHolderName: {
            inputType: "text",
            value: "",
            hintText: "Account Holder Name",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Account holder name not provided",
        },
        accountNumber: {
            inputType: "text",
            value: "",
            hintText: "Account Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Enter bank account number",
        },
        confirmAccountNumber: {
            inputType: "text",
            value: "",
            hintText: "Confirm Account Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Account numbers do not match",
        },
        bankName: {
            inputType: "text",
            value: "",
            hintText: "Bank Name",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "No bank name provided",
        },
        bankBranch: {
            inputType: "text",
            value: "",
            hintText: "Branch",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Specify Bank branch",
        },
        ifscCode: { inputType: "text", value: "", hintText: "IFSC Code", mandatory: false, colSpan: 4, error: false, errorMessage: "Input ISFC Code" },

        // Account Password
        accountPassword: {
            inputType: "password",
            value: "",
            hintText: "New Password for Employee (Min 6 characters)",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Minimum 6 characters for login password",
        },
        confirmAccountPassword: {
            inputType: "text",
            value: "",
            hintText: "Retype Password",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Login passwords don't match",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Company Email ID",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Enter a valid email address",
        },

        additionalNotes: {
            inputType: "text",
            value: "",
            hintText: "Additional Notes about Employee",
            mandatory: false,
            colSpan: 16,
            error: false,
            errorMessage: "",
        },
    });

    const employeeServerKeys = [
        { localKey: "employeeName", serverKey: "name" },
        { localKey: "employeeId", serverKey: "employee_no" },
        { localKey: "roleId", serverKey: "role_id" },
        { localKey: "plantId", serverKey: "plant_id" },
        { localKey: "dateOfJoining", serverKey: "join_date" },
        { localKey: "wages", serverKey: "wages" },
        { localKey: "leaveEligible", serverKey: "leave_eligibility" },
        { localKey: "department", serverKey: "department" },
        { localKey: "status", serverKey: "emp_status" },
        {localKey: "orgName",serverKey: "orgName"},
        { localKey: "mobileNumber", serverKey: "mobile" },
        { localKey: "alternateMobileNumber", serverKey: "alternate_mobile" },
        { localKey: "email", serverKey: "email" },
        { localKey: "personalEmail", serverKey: "personalEmail" },
        { localKey: "dateOfBirth", serverKey: "birth_date" },
        { localKey: "idProofType", serverKey: "id_proof_type" },
        { localKey: "idProofNo", serverKey: "id_proof_no" },
        { localKey: "idProof", serverKey: "id_proof_img" },
        { localKey: "address", serverKey: "address" },
        { localKey: "country", serverKey: "country_id" },
        { localKey: "zipCode", serverKey: "pin_code" },
        { localKey: "bloodGroup", serverKey: "blood_group" },

        { localKey: "emergencyContactName", serverKey: "emergency_contact_name" },
        { localKey: "emergencyContactNumber", serverKey: "emergency_contact_number" },
        { localKey: "emergencyContactRelationship", serverKey: "relationship_type" },

        { localKey: "accountHolderName", serverKey: "account_holder_name" },
        { localKey: "accountNumber", serverKey: "account_no" },
        { localKey: "confirmAccountNumber", serverKey: "account_no" },
        { localKey: "bankName", serverKey: "bank_name" },
        { localKey: "bankBranch", serverKey: "branch_name" },
        { localKey: "ifscCode", serverKey: "ifsc_code" },

        { localKey: "additionalNotes", serverKey: "additional_notes" },
    ];

    const [varList, setVarList] = useState([]);
    const [profileImage, setProfileImage] = useState();
    // const [idProofImage, setIdProofImage] = useState();
    const [showVarListIdProof, setShowVarListIdProof] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    useEffect(() => {
        getInformation();
        setIsOpen(true);
        setModalText("Getting Employee Information");
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getState();

        // eslint-disable-next-line
    }, [employeeParameters.country.value]);

    useEffect(() => {
              getCity();

        // eslint-disable-next-line
    }, [employeeParameters.state.value]);


    async function getInformation() {
        await checkToken();
        var { empParamCopy0, varListData } = await getEmployeeDetail();
        // eslint-disable-next-line
        var { empParamCopy, varListData } = await getRoles({ empParamCopy0, varListData });
        // eslint-disable-next-line
        var { empParamCopy2, varListData } = await getPlants({ empParamCopy, varListData });
        // eslint-disable-next-line
        var { empParamCopy3, varListData } = await getDim({ empParamCopy2, dataSent: { field: "country" }, dimension: "country", varListData });
        // eslint-disable-next-line
        var { empParamCopy3, varListData } = await getDim({
            empParamCopy2: empParamCopy3,
            dataSent: { field: "state", filterBy: "country_id", filterValue: empParamCopy3.country.value },
            dimension: "state",
            varListData,
        });
        // eslint-disable-next-line
        var { empParamCopy3, varListData } = await getDim({
            empParamCopy2: empParamCopy3,
            dataSent: { field: "city", filterBy: "state_id", filterValue: empParamCopy3.state.value },
            dimension: "cityId",
            varListData,
        });
        setemployeeParameters(empParamCopy3);
        setIsOpen(false);
    }

    const history = useHistory();
    async function checkToken() {
       

        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            //console.log"Token not changed");
        }
    }

    async function getEmployeeDetail() {
        var data = await fetchData({
            requestingPage: "employeeDetail",
            method: "get",
            url: "fetch/employee/" + employeeInfo.editEmployeeId,
            headers: { token: isLogged.accessToken, module: "Employee" },
        });
        if (data.msg === "success") {
            setVarList(data.asset[0]);
            if (data.asset[0].id_proof_img !== null) {
                setShowVarListIdProof(true);
            }
            var dataFromServerEdited = await setDataFromServer(data);
            return { empParamCopy0: dataFromServerEdited, varListData: data.asset[0] };
        } else {
            return { empParamCopy0: await setDataFromServer(data), varListData: [] };
        }
    }

    function setDataFromServer(data) {
        var employeeParametersCopy = JSON.parse(JSON.stringify(employeeParameters));

        if (data.msg === "success") {
            employeeServerKeys.forEach((element) => {
                if (data.asset[0][element.serverKey] !== null) {
                    if (["dateOfBirth", "dateOfJoining"].includes(element.localKey)) {
                        var date = new Date(data.asset[0][element.serverKey]);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dateValue = date.getDate();

                        if (month.toString().length === 1) month = `0${month}`;
                        if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

                        var formattedDate = `${year}-${month}-${dateValue}`;
                        employeeParametersCopy[element.localKey].value = formattedDate;
                    } else {
                        employeeParametersCopy[element.localKey].value = data.asset[0][element.serverKey];
                    }
                } else {
                    if (["dateOfBirth", "dateOfJoining"].includes(element.localKey)) {
                        employeeParametersCopy[element.localKey].value = null;
                    } else {
                        employeeParametersCopy[element.localKey].value = "";
                    }
                }
            });

            return employeeParametersCopy;
        } else {
            return employeeParametersCopy;
        }
    }

    async function getRoles({ empParamCopy0, varListData }) {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/role", headers: { token: isLogged.accessToken, module: "Employee" } });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.role, optionId: dataItem.role_id };
                optionArray.push(a);
            });
            

            if (employeeParameters.roleId.setServerSelection) {
                empParamCopy0 = update(empParamCopy0, {
                    roleId: {
                        options: { $set: optionArray },
                        value: { $set: varListData.role_id },
                    },
                });
            } else {
                empParamCopy0 = update(empParamCopy0, {
                    roleId: {
                        options: { $set: optionArray },
                    },
                });
            }

            return { empParamCopy: empParamCopy0, varListData };
        } else {
                return {}
        }
    }

    async function getPlants({ empParamCopy, varListData }) {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/plant", headers: { token: isLogged.accessToken, module: "Employee" } });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.plant_name, optionId: dataItem.plant_id };
                optionArray.push(a);
            });

            if (employeeParameters.roleId.setServerSelection) {
                empParamCopy = update(empParamCopy, {
                    plantId: {
                        options: { $set: optionArray },
                        value: { $set: varListData.plant_id },
                    },
                });
            } else {
                empParamCopy = update(empParamCopy, {
                    plantId: {
                        options: { $set: optionArray },
                    },
                });
            }
            return { empParamCopy2: empParamCopy, varListData };
        } else {
            //console.logdata);
            return {}
        }
    }

    async function getState() {
       

        if (!employeeParameters.state.setServerSelection) {
            var stateData = await fetchData({
                requestingPage: "getDim",
                method: "post",
                url: "dim",
                headers: { token: isLogged.accessToken, module: "Employee" },
                data: { field: "state", filterBy: "country_id", filterValue: employeeParameters.country.value },
            });

            if (stateData.msg !== "failure") {
                var optionArray = [];
                stateData.forEach((dataItem) => {
                    var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                    optionArray.push(a);
                });

                var empParamCopy = JSON.parse(JSON.stringify(employeeParameters));
                empParamCopy = update(empParamCopy, {
                    state: {
                        options: { $set: optionArray },
                        value: { $set: "" },
                    },
                });
                setemployeeParameters(empParamCopy);
            }
        } else {
            var empParamCopy2 = JSON.parse(JSON.stringify(employeeParameters));
            empParamCopy2 = update(empParamCopy2, {
                state: {
                    setServerSelection: { $set: false },
                },
            });
            setemployeeParameters(empParamCopy2);
        }
    }

    async function getCity() {
      
        if (!employeeParameters.cityId.setServerSelection) {
          
            var cityData = await fetchData({
                requestingPage: "getDim",
                method: "post",
                url: "dim",
                headers: { token: isLogged.accessToken, module: "Employee" },
                data: { field: "city", filterBy: "state_id", filterValue: employeeParameters.state.value },
            });

            if (cityData.msg !== "failure") {
                var optionArray = [];
                cityData.forEach((dataItem) => {
                    var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                    optionArray.push(a);
                });

                var empParamCopy = JSON.parse(JSON.stringify(employeeParameters));
                empParamCopy = update(empParamCopy, {
                    cityId: {
                        options: { $set: optionArray },
                        value: { $set: "" },
                    },
                });
                setemployeeParameters(empParamCopy);
            }
        } else {
            var empParamCopy2 = JSON.parse(JSON.stringify(employeeParameters));
            empParamCopy2 = update(empParamCopy2, {
                cityId: {
                    setServerSelection: { $set: false },
                },
            });
            setemployeeParameters(empParamCopy2);
        }
    }

    async function getDim({ empParamCopy2, dataSent, dimension, varListData }) {

        var data = await fetchData({
            requestingPage: "getDim",
            method: "post",
            url: "dim",
            headers: { token: isLogged.accessToken, module: "Employee" },
            data: dataSent,
        });

        if (data.msg !== "failure") {
            var optionArray = [];

            switch (dimension) {
                case "country":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                        optionArray.push(a);
                    });

                    break;

                case "state":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                        optionArray.push(a);
                    });
                    break;

                case "cityId":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                        optionArray.push(a);
                    });
                    break;

                default:
                    break;
            }

            var dim2 = "city_id";
            if (dimension === "country") dim2 = "country_id";
            if (dimension === "state") dim2 = "state_id";

            empParamCopy2[dimension].setServerSelection
                ? (empParamCopy2 = update(empParamCopy2, {
                    [dimension]: {
                        options: { $set: optionArray },
                        value: { $set: varListData[dim2] },
                    },
                }))
                : (empParamCopy2 = update(empParamCopy2, {
                    [dimension]: {
                        options: { $set: optionArray },
                        value: { $set: "" },
                    },
                }));
            return { empParamCopy3: empParamCopy2, varListData };
        } else {
            return { empParamCopy3: empParamCopy2, varListData };
        }
    }


    useEffect(() => {
        getProfileImage();
        // eslint-disable-next-line
    }, [varList]);

    async function getProfileImage() {
        if (varList !== null && varList.employee_id !== undefined) {
            var data = await fetchData({
                requestingPage: "employeeImage",
                method: "get",
                url: "image/emp-photo/" + varList.employee_id,
                headers: { token: isLogged.accessToken, module: "Employee" },
            });
            if (data.msg !== "failure") setProfileImage(data);
        }
    }

    const updateEmployeeParameter = (paramName, key, value) => {
        const employeeparams = update(employeeParameters, { [paramName]: { [key]: { $set: value } } });
        setemployeeParameters(employeeparams);
    };

    const [errorList, setErrorList] = useState([]);
    const checkErrors = () => {
        var errorListArray = [];
        var updatedParams = Object.assign(employeeParameters);

      
        employeeParametersList.forEach((list, j) => {
            if ((j == 4 && editPassword) || [0, 1, 2, 3, 5].includes(j)) {
                list.forEach((item) => {
                    if (employeeParameters[item].mandatory && item!=="orgName") {
                        updatedParams = update(updatedParams, {
                            [item]: { error: { $set: !validateMandatory(((employeeParameters[item].value) || "").toString()) } },
                        });
                    }
                    if(item==="orgName" && isLogged.roleId==1){
                        updatedParams = update(updatedParams, {
                            [item]: { error: { $set: !validateMandatory(((employeeParameters[item].value) || "").toString()) } },
                        });
                    }
                    if (["mobileNumber", "alternateMobileNumber"].includes(item)) {
                        if (employeeParameters[item].value !== null && employeeParameters[item].value.length > 0) {
                            updatedParams = update(updatedParams, {
                                [item]: { error: { $set: !validateMobileNumber(employeeParameters[item].value) } },
                            });
                        } else updatedParams = update(updatedParams, { [item]: { error: { $set: false } } });
                    }

                    if (item === "emergencyContactNumber") {
                        updatedParams = update(updatedParams, { [item]: { error: { $set: !validateMobileNumber(employeeParameters[item].value) } } });
                    }

                    if ((item === "email") | (item === "personalEmail")) {
                        if (employeeParameters[item].value !== null && employeeParameters[item].value.length > 0) {
                            updatedParams = update(updatedParams, { [item]: { error: { $set: !validateEmail(employeeParameters[item].value) } } });
                        } else updatedParams = update(updatedParams, { [item]: { error: { $set: false } } });
                    }

                    if (item === "confirmAccountNumber") {
                        updatedParams = update(updatedParams, {
                            [item]: { error: { $set: !validateEqualValues(employeeParameters[item].value, employeeParameters.accountNumber.value) } },
                        });
                    }
                    if (item === "accountPassword") {
                        if (employeeParameters[item].value !== null && employeeParameters[item].value.length > 0) {
                            updatedParams = update(updatedParams, { [item]: { error: { $set: !validatePassword(employeeParameters[item].value) } } });
                        }
                    }

                    if (item === "confirmAccountPassword") {
                        updatedParams = update(updatedParams, {
                            [item]: {
                                error: { $set: !validateEqualValues(employeeParameters[item].value, employeeParameters.accountPassword.value) },
                            },
                        });
                    }

                    if (updatedParams[item].error) {
                       
                        errorListArray.push(updatedParams[item].errorMessage);
                    }
                });
            }

        });
        setemployeeParameters(updatedParams);

        setErrorList(errorListArray);

        if (errorListArray.length === 0) {
            var data2 = new FormData();
            employeeParametersList.forEach((list, i) => {
                if ((i == 4 && editPassword) || [0, 1, 1, 2, 3, 5].includes(i)) {
                    list.forEach((item) => {
                   

                        switch (item) {
                            case "photo":
                            case "idProof":
                                if (updatedParams[item].value !== null && updatedParams[item].value !== "")
                                    data2.append(item, updatedParams[item].value);
                                break;

                            default:
                                if (updatedParams[item].value !== null && updatedParams[item].value.toString().length > 0)
                                    data2.append(item, updatedParams[item].value);
                                break;
                        }
                    });
                } else {
                }
            });
            data2.append("employeeIdDb", employeeInfo.editEmployeeId);


            for (var pair of data2.entries()) {
                if (pair[0] == "employeeId")
                    pair[1] = varList.employee_no;
            }

            submitData(data2);
        }
    };

    async function submitData(data2) {
        setModalText("Uploading form. Please wait...");
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "editEmployee",
            method: "put",
            url: "edit/employee",
            headers: { token: isLogged.accessToken, module: "Employee" },
            data: data2,
        });

        if (result.msg === "success") {
            setModalText("Employee edited successfully!");

        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setErrorList([result.desc]);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorList.length === 0) {
            setSection("employeeList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const renderFormElements = (elementList) => {
        return elementList.map((param) => {
            if((param==="orgName" && isLogged.roleId==1) || param!=="orgName")
            switch (param) {
                case "photo":
                    return (
                        <div className="box profilePhoto" key={"photoElement"}>
                            {/* eslint-disable-next-line */}
                            <img style={{opacity:"1"}}
                                src={employeeParameters[param].url ? employeeParameters[param].url : `data:image/png;base64,${profileImage}`}
                                className="photoArea"
                                alt="Profile Picture"
                            />
                            <label
                                className={employeeParameters[param].error ? "uploadEditProfilePhotoError" : "uploadEditProfilePhoto"}
                                htmlFor="uploadProfilePhoto"
                            >
                                EDIT
                            </label>
                            <input
                                className="uploadEditInput"
                                id="uploadProfilePhoto"
                                type="file"
                                accept="images/*"
                                onChange={(event) => {
                                    var empParamCopy = Object.assign(employeeParameters);
                                    empParamCopy = update(empParamCopy, {
                                        [param]: {
                                            value: { $set: event.target.files[0] },
                                            url: { $set: URL.createObjectURL(event.target.files[0]) },
                                        },
                                    });
                                    setemployeeParameters(empParamCopy);
                                }}
                            />
                        </div>
                    );

                case "idProof":
                    if (showVarListIdProof) {
                        return (
                            <FormElement
                                inputType={"uploadReady"}
                                value={varList.id_proof_img}
                                setInput={(value) => {
                                    if (value === "deleteId") {
                                        setShowVarListIdProof(false);
                                        updateEmployeeParameter(param, "value", "");
                                    }
                                }}
                                hintText="ID Proof"
                                mandatory={employeeParameters[param].mandatory}
                                colSpan={employeeParameters[param].colSpan}
                                error={employeeParameters[param].error}
                                key={param}
                            />
                        );
                    } else {
                        return (
                            <FormElement
                                inputType={
                                    employeeParameters[param].value !== null &&
                                        employeeParameters[param].value.name !== null &&
                                        employeeParameters[param].value.name !== undefined
                                        ? "uploadReady"
                                        : "upload"
                                }
                                value={
                                    employeeParameters[param].value !== null && employeeParameters[param].value !== ""
                                        ? employeeParameters[param].value.name
                                        : ""
                                }
                                setInput={(value) => {
                                    if (value !== "deleteId") {
                                        updateEmployeeParameter(param, "value", value);
                                    } else {
                                        updateEmployeeParameter(param, "value", "");
                                        setShowVarListIdProof(true);
                                    }
                                }}
                                hintText="ID Proof"
                                mandatory={employeeParameters[param].mandatory}
                                colSpan={employeeParameters[param].colSpan}
                                error={employeeParameters[param].error}
                                key={param}
                            />
                        );
                    }

                default:
                    return (
                        <FormElement
                            inputType={employeeParameters[param].inputType}
                            value={employeeParameters[param].value}
                            setInput={(value) => updateEmployeeParameter(param, "value", value)}
                            hintText={employeeParameters[param].hintText}
                            mandatory={employeeParameters[param].mandatory}
                            colSpan={employeeParameters[param].colSpan}
                            options={employeeParameters[param].inputType === "options" ? employeeParameters[param].options : null}
                            error={employeeParameters[param].error}
                            disabled={employeeParameters[param].disabled}
                            key={param}
                        />
                    );
            }
        });
    };

    const [editPassword, setEditPassword] = useState(false);

    if (varList !== null && varList !== undefined) {
        return (
            <React.Fragment>
                <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

                <div className="formArea">
                    <div
                        style={{
                            width: "900px",
                            margin: "0 auto 4rem",
                            padding: "3rem",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="formGrid">{renderFormElements(employeeParametersList[0])}</div>

                        <div className="subTitle">Personal Details</div>
                        <div className="personalDetails">{renderFormElements(employeeParametersList[1])}</div>

                        <div className="subTitle">Emergency Details</div>
                        <div className="emergencyDetails">{renderFormElements(employeeParametersList[2])}</div>

                        <div className="subTitle">Bank Details</div>
                        <div className="bankDetails">{renderFormElements(employeeParametersList[3])}</div>

                        <div className="subTitle">
                            <input
                                type="checkbox"
                                checked={editPassword}
                                onChange={(e) => {
                                    setEditPassword(e.target.checked);
                                }}
                            />
                            <span> CREATE / CHANGE PASSWORD</span>
                        </div>
                        {editPassword ? <div className="accountPassword">{renderFormElements(employeeParametersList[4])}</div> : null}

                        <div className="subTitle">Notes</div>
                        <div className="additionalNotes">{renderFormElements(employeeParametersList[5])}</div>
                    </div>
                </div>
                <div className="formSubmitArea">
                    <div className="formSubmitInnerArea">
                        <p className="formSubmitErrorTextArea">{errorList[0]}</p>

                        <button
                            className="submitButton"
                            onClick={() => {
                                checkErrors();
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    } else return null;
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        employeeInfo: state.employeeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployee);
