import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const EditOpd = ({ isLogged, storeInfo, addTokenToState, setSection, editApprove }) => {
    const lineItemParams = isLogged.showHideWeight ? ["itemId", "from", "to", "netWeight", "grossWeight", "uom", "orderQuantity", "quantity"] : ["itemId","uom", "orderQuantity", "quantity"];
    var opdParamList = [
        ["orderType", "orderId", "packingDate", "plantId", "storageId"],
        ["itemId", "from", "to", "netWeight", "grossWeight", "uom", "orderQuantity", "quantity"],
    ];

    const [opdParams, setOpdParams] = useState({
        orderType: {
            inputType: "text",
            value: "",
            hintText: "Order Type",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please select order type",
            disabled: true,
        },
        orderId: {
            inputType: "text",
            value: "",
            hintText: "Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select Sales order",
            disabled: true,
        },
        itemId: {
            inputType: "options",
            value: "",
            hintText: "Item",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select an Item",
        }, from: {
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        to: {
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        uom: {
            inputType: "text",
            value: "",
            hintText: "UOM",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        orderQuantity: {
            inputType: "text",
            value: "",
            hintText: "Order Quantity",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        plantId: {
            inputType: "options",
            value: "",
            hintText: "Plant Name",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Plant Name",
            serverCall: true,
        },
        storageId: {
            inputType: "options",
            value: "",
            hintText: "Storage",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Storage Location",
            serverCall: true,
        },
        packingDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Packing Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter packing date",
            maxdate: true,
        },
        quantity: {
            inputType: "text",
            value: "",
            hintText: "Picking Qty",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please enter a valid picking quantity",
        },
        opdLineItemId: { value: null },
    });

    const [opdDetail, setOpdDetail] = useState({});
    const [showCustomerDetail, setShowCustomerDetail] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});

    const [storageList, setStorageList] = useState({});
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    useEffect(async () => {
        var paramsCopy = Object.assign(opdParams);

        if (opdParams.itemId.value !== "") {
            var currentItem = orderDetail.lineItems.filter((item) => item.itemId === opdParams.itemId.value)[0];

            paramsCopy = update(paramsCopy, {
                uom: { value: { $set: currentItem.uom } },
                from: { value: { $set: currentItem.from } },
                to: { value: { $set: currentItem.to } },
                netWeight: { value: { $set: currentItem.netWeight } },
                grossWeight: { value: { $set: currentItem.grossWeight } },
                orderQuantity: {
                    value: { $set: opdParams.orderType.value === "Purchase Return" ? currentItem.returnQuantity : currentItem.quantity },
                },
            });
        } else {
            paramsCopy = update(paramsCopy, {
                uom: { value: { $set: "" } },
                orderQuantity: { value: { $set: "" } },
            });
        }
        setOpdParams(paramsCopy);
    }, [opdParams.itemId]);

    useEffect(() => {
        var paramsCopy = Object.assign(opdParams);

        if (opdParams.storageId.serverCall) {
            if (opdParams.plantId.value !== "") {
             
                paramsCopy = update(paramsCopy, { storageId: { options: { $set: storageList[opdParams.plantId.value] }, value: { $set: "" } } });

                setOpdParams(paramsCopy);
            } else {
                paramsCopy = update(paramsCopy, { storageId: { options: { $set: [] }, value: { $set: "" } } });
                setOpdParams(paramsCopy);
            }
        } else {
            paramsCopy = update(paramsCopy, { storageId: { serverCall: { $set: true } } });
            setOpdParams(paramsCopy);
        }
    }, [opdParams.plantId]);

    const getInformation = async () => {
        setIsOpen(true);
        setModalText("Getting OPD information. Please wait...");
        setDataSubmitted(false);
        await checkToken();

        var paramsCopy = Object.assign(opdParams);

        // =========================================================================
        // Get plants & Storage list, OPD Detail and Order Detail
        // =========================================================================
        var [plantList, storageLocListFormatted] = await getPlants();
        setStorageList(storageLocListFormatted);

        var opdDetailCopy = await getOpdDetail();
        setOpdDetail(opdDetailCopy);

        var orderDetailCopy = {};
        if (opdDetailCopy.orderType === "Sales Order") {
            orderDetailCopy = await getSoDetail(opdDetailCopy.orderId);
        }
        if (opdDetailCopy.orderType === "Plant Transfer") {
            orderDetailCopy = await getPlantTransferDetail(opdDetailCopy.orderId);
        }

        if (opdDetailCopy.orderType === "Purchase Return") {
            orderDetailCopy = await getPrDetail(opdDetailCopy.orderId);
            orderDetailCopy.lineItems = orderDetailCopy.prLineItems;
        }

        // =========================================================================
        // Setting Item Options (with required parameters)
        // =========================================================================
        var itemOptions = [];
        if (orderDetailCopy?.lineItems.length > 0) {
            var itemOptions = [];
            orderDetailCopy.lineItems.forEach((item) => {
                var lineItemId = "";

                switch (opdDetailCopy.orderType) {
                    case "Sales Order":
                        lineItemId = item.soLineItemId;
                        break;

                    case "Purchase Return":
                        lineItemId = item.prLineItemId;
                        break;

                    case "Plant Transfer":
                        lineItemId = item.transferLineItemid;
                        break;
                }

                var itemOption = {
                    optionId: item.itemId,
                    optionName: item.itemName,
                    lineItemId: lineItemId,
                    uom: item.uom,
                    from: item.from,
                    to: item.to,
                    netWeight: item.netWeight,
                    grossWeight: item.grossWeight,
                    orderQuantity: opdDetailCopy.orderType === "Purchase Return" ? item.returnQuantity : item.quantity,
                };
                itemOptions.push(itemOption);
            });
            paramsCopy = update(paramsCopy, { itemId: { options: { $set: itemOptions }, value: { $set: "" } } });
        }

        setOrderDetail(orderDetailCopy);
        setShowCustomerDetail(true);

        // =========================================================================
        // Setting OPD Parameters
        // =========================================================================

        paramsCopy = update(paramsCopy, {
            orderType: { value: { $set: opdDetailCopy.orderType } },
            orderId: { value: { $set: opdDetailCopy.orderNo } },
            packingDate: { value: { $set: opdDetailCopy.packingDate } },
            plantId: { options: { $set: plantList }, value: { $set: opdDetailCopy.plantId } },
            storageId: {
                options: { $set: storageLocListFormatted[opdDetailCopy.plantId] },
                value: { $set: opdDetailCopy.storageId },
                serverCall: { $set: false },
            },
        });

        if (opdDetailCopy.orderType === "Plant Transfer") {
            paramsCopy = update(paramsCopy, { plantId: { disabled: { $set: true } }, storageId: { disabled: { $set: true } } });
        } else {
            paramsCopy = update(paramsCopy, { plantId: { disabled: { $set: false } }, storageId: { disabled: { $set: false } } });
        }


        // =========================================================================
        // Parsing OPD lineItems and merging info with Order detail
        // =========================================================================

        var lineItemsCopy = [];
        for (let i = 0; i < opdDetailCopy.lineItems.length; i++) {
            var itemFromOpd = opdDetailCopy.lineItems[i];
            var itemFromOpdCopy = Object.assign(itemFromOpd);

            var itemOptionsItem = paramsCopy.itemId.options.filter((rec) => rec.optionId === itemFromOpdCopy.itemId)[0];
            itemFromOpdCopy.quantity = itemOptionsItem.orderQuantity;
            lineItemsCopy.push(itemFromOpdCopy);
        }

        setItemList(lineItemsCopy);
        setOpdParams(paramsCopy);
        setIsOpen(false);
    };

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getOpdDetail() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "post",
            url: `store-fetch/opd-detail/${storeInfo.editOpdId}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }
    }

    async function getSoDetail(orderId) {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: `so-fetch/so-detail/${orderId}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }else{
            return [];
        }
    }

    async function getPlantTransferDetail(orderId) {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: `store-fetch/stock-transfer-detail/${orderId}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }else{
            return [];
        }
    }

    async function getPrDetail(orderId) {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "po-fetch/pr-detail/" + orderId,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }else{
            return [];
        }
    }

    async function getPlants(plantId) {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: `store-fetch/item-in-plant-storage-list/${plantId}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });

        if (data.msg === "success") {
            var plantList = [];
            var storageLocList = {};

            data.asset.forEach((plant) => {
                var plantObj = {};
                plantObj.optionId = plant.plantId;
                plantObj.optionName = plant.plantName;

                plantList.push(plantObj);

                storageLocList[plant.plantId] = [];
                plant.storages.forEach((store) => {
                    var storeObj = {};
                    storeObj.optionId = store.storageId;
                    storeObj.optionName = store.storageName;
                    storeObj.quantity = store.quantity;
                    storageLocList[plant.plantId].push(storeObj);
                });
            });
            return [plantList, storageLocList];
        }
    }

    const [opdErrors, setOpdErrors] = useState([]);
    const [itemErrors, setItemErrors] = useState([]);

    const submitOkClick = () => {
        setIsOpen(false);
        if (opdErrors.length === 0) {
            setSection("opdList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Submitting OPD. Please wait..");

    const RenderCustomerDetail = () => {
        switch (opdParams.orderType.value) {
            case "Sales Order":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{orderDetail.customerName}</div>
                        <div className="detailText">
                            {`Customer ID: ${orderDetail.customerId}`},
                            <br /> {orderDetail.customerAddress},
                            <br /> {orderDetail.customerCity}
                            {orderDetail.customerPinCode !== null ? `, - ${orderDetail.customerPinCode}` : null}
                            {orderDetail.customerMobile !== null || orderDetail.customerPhone !== null ? <br /> : null}
                            {orderDetail.customerMobile !== null || orderDetail.customerPhone !== null ? `Phone: ` : null}
                            {orderDetail.customerMobile !== null ? orderDetail.customerMobile : null}
                            {orderDetail.customerPhone !== null ? `, ${orderDetail.customerPhone} ` : null}
                            {orderDetail.customerEmail !== null ? <br /> : null}
                            {orderDetail.customerEmail !== null ? `Email Id: ${orderDetail.customerEmail}` : null}
                            {orderDetail.customerGstNo !== null ? <br /> : null}
                            {orderDetail.customerGstNo !== null ? `GST No:  ${orderDetail.customerGstNo}` : null}
                        </div>
                    </React.Fragment>
                );

            case "Plant Transfer":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{orderDetail.toStorageName}</div>
                        <div className="detailText">
                            {orderDetail.toPlantName},
                            <br /> {orderDetail.toStorageAddress}
                            {orderDetail.toPlantCity !== null ? <br /> : null}
                            {orderDetail.toPlantCity !== null ? orderDetail.toPlantCity : null}
                            {orderDetail.toPlantState !== null ? <br /> : null}
                            {orderDetail.toPlantState !== null ? orderDetail.toPlantState : null}
                            {orderDetail.toPlantPinCode !== null ? ` - ${orderDetail.toPlantPinCode}` : null}
                        </div>
                    </React.Fragment>
                );

            case "Purchase Return":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{orderDetail.companyName}</div>
                        <div className="detailText">
                            {`Customer ID: ${orderDetail.companyId}`},
                            <br /> {orderDetail.vendorAddress},
                            <br /> {orderDetail.vendorCity}
                            {orderDetail.vendorPinCode !== null ? `, - ${orderDetail.vendorPinCode}` : null}
                            {orderDetail.vendorMobile !== null || orderDetail.vendorPhone !== null ? <br /> : null}
                            {orderDetail.vendorMobile !== null || orderDetail.vendorPhone !== null ? `Phone: ` : null}
                            {orderDetail.vendorMobile !== null ? orderDetail.vendorMobile : null}
                            {orderDetail.vendorPhone !== null ? `, ${orderDetail.vendorPhone} ` : null}
                            {orderDetail.vendorEmail !== null ? <br /> : null}
                            {orderDetail.vendorEmail !== null ? `Email Id: ${orderDetail.vendorEmail}` : null}
                            {orderDetail.vendorGstNo !== null ? <br /> : null}
                            {orderDetail.vendorGstNo !== null ? `GST No:  ${orderDetail.vendorGstNo}` : null}
                        </div>
                    </React.Fragment>
                );

            default:
                return null;
        }
    };

    const updateOpdParameter = ({ paramName, key, value }) => {

        var paramsCopy = Object.assign(opdParams);

        if (paramName === "orderId" && paramsCopy.orderId.value !== "") {
            var yes = window.confirm("Changing Sales Order will reset all item information below. Proceed?");

            if (yes) {
                paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
                paramsCopy = update(paramsCopy, {
                    itemId: { value: { $set: "" } },
                    plantId: { value: { $set: "" } },
                    storageId: { value: { $set: "" } },
                    quantity: { value: { $set: "" } },
                });
                setItemList([]);

                if (value === "") {
                    setShowCustomerDetail(false);
                    setOrderDetail({});
                }
            }
        } else {
            paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        }

        setOpdParams(paramsCopy);
    };

    const editSubSectionItem = (i, action) => {
        var itemListCopy = itemList.slice();
        switch (action) {
            case "edit":
                var proceed = true;
                opdParamList[1].forEach((item) => {
                    if (opdParams[item].value !== "") {
                        proceed = false;
                    }
                });

                if (!proceed) {
                    var yes = window.confirm("Unsaved item information found. Overwrite?");
                }

                if (proceed || yes) {
                    var editItem = itemListCopy.splice(i, 1)[0];
                    var paramsCopy = Object.assign(opdParams);

                    paramsCopy = update(paramsCopy, {
                        opdLineItemId: { value: { $set: editItem.opdLineItemId } },
                        itemId: { value: { $set: editItem.itemId } },
                        from: { value: { $set: editItem.from } },
                        to: { value: { $set: editItem.to } },
                        netWeight: { value: { $set: editItem.netWeight } },
                        grossWeight: { value: { $set: editItem.grossWeight } },
                        quantity: { value: { $set: editItem.packedQuantity } },
                        uom: { value: { $set: editItem.uom } },
                        orderQuantity: { value: { $set: editItem.quantity } },
                    });

                    setOpdParams(paramsCopy);
                    setItemList(itemListCopy);
                }

                break;

            case "delete":
                itemListCopy.splice(i, 1);
                setItemList(itemListCopy);

                break;
        }
    };

    const checkItemError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(opdParams);

        opdParamList[1].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (item === "quantity") {
                if (paramsCopy.quantity.value === "" || isNaN(paramsCopy.quantity.value)) {
                    paramsCopy = update(paramsCopy, { quantity: { error: { $set: true } } });
                } else {
                    if (parseFloat(paramsCopy.quantity.value < 0)) {
                        paramsCopy = update(paramsCopy, { quantity: { error: { $set: true } } });
                    } else {
                        paramsCopy = update(paramsCopy, { quantity: { error: { $set: false } } });
                    }
                }
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
            if(paramsCopy["orderQuantity"].value < paramsCopy["quantity"].value){
                errorList.push("Picking Quantity Cannot be greater than Order Quantity");
            }
            if (errorList.length === 0) {
                var itemListCopy = JSON.parse(JSON.stringify(itemList));

                var opdItem = paramsCopy.itemId.options.filter((option) => option.optionId === paramsCopy.itemId.value)[0];

                itemListCopy.push({
                    itemId: paramsCopy.itemId.value,
                    itemName: opdItem.optionName,
                    from: opdItem.from,
                    to: opdItem.to,
                    netWeight: opdItem.netWeight,
                    grossWeight: opdItem.grossWeight,
                    uom: opdItem.uom,
                    quantity: opdItem.orderQuantity,
                    lineItemId: opdItem.lineItemId,
                    itemOptions: paramsCopy.itemId.options,
                    packedQuantity: paramsCopy.quantity.value,
                    opdLineItemId: paramsCopy.opdLineItemId.value,
                });

                setItemList(itemListCopy);
            }
        });

        paramsCopy = update(paramsCopy, {
            itemId: { value: { $set: "" } },
            from: { value: { $set: "" } },
            to: { value: { $set: "" } },
            netWeight: { value: { $set: "" } },
            grossWeight: { value: { $set: "" } },
            quantity: { value: { $set: "" } },
            opdLineItemId: { value: { $set: null } },
            orderQuantity: { value: { $set: "" } },
            uom: { value: { $set: "" } },
        });

        setOpdParams(paramsCopy);
        setItemErrors(errorList);
    };

    const checkOpdError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(opdParams);

        opdParamList[0].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        if (itemList.length <= 0) {
            errorList.push("Enter atleast one item information");
        }

        if (errorList.length === 0) {

            var data2server = {
                // orderId: paramsCopy.orderId.value,
                opdId: opdDetail.opdId,
                orderType: paramsCopy.orderType.value,
                packingDate: paramsCopy.packingDate.value,
                storageId: paramsCopy.storageId.value,
            };

            var lineItems = [];
            itemList.map((item) => {
                var lineObj = {
                    itemId: item.itemId,
                    // storageId: item.storageId,
                    quantity: parseInt(item.packedQuantity),
                    lineItemId: item.lineItemId,
                    opdLineItemId: item.opdLineItemId,
                };
                lineItems.push(lineObj);
            });

            data2server.lineItems = lineItems;
            submitData(data2server);
        } else {
            console.log("Errors found");
        }
        setOpdErrors(errorList);
        setOpdParams(paramsCopy);
    };

    async function approveOpd() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/opd-approve/${opdDetail.opdId}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("OPD Approved");
        } else {
            setModalText(data.desc);
            setOpdErrors(data.desc);
        }
    }

    const submitData = async (data2server) => {
        setIsOpen(true);
        setDataSubmitted(false);
        setModalText("Updating OPD info. Please wait...");
        var result = await fetchData({
            requestingPage: "createPoItem",
            method: "put",
            url: "store-edit/opd",
            headers: { token: isLogged.accessToken, module: "OPD" },
            data: data2server,
        });

        if (result.msg === "success") {
            if (editApprove) {
                approveOpd();
            } else {
                setDataSubmitted(true);
                setModalText("OPD Submitted for Approval");
            }
        } else {
            setOpdErrors([result.desc]);
            setDataSubmitted(true);
            setModalText(result.desc);
        }
    };

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value) => {
                        updateOpdParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const renderSubSection = () => {
        return (
            <React.Fragment>
                <div className="purchaseOrderIndividualItemsArea">
                    {renderFormElements({ elementList: opdParamList[1], param: opdParams, section: "lineItem" })}
                    <FormElement
                        inputType="addButton"
                        value="+ Add"
                        colSpan={3}
                        setInput={() => {
                            checkItemError();
                        }}
                    />
                    <div
                        style={{
                            marginTop: "26px",
                            gridColumn: "span 1",
                            color: "#666",
                            background: "rgb(230,230,230)",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            style={{ padding: "10px", width: "1rem", height: "1rem" }}
                            src={resetIcon}
                            alt="Reset"
                            onClick={() => {
                                var yes = window.confirm("Clear Input data?");

                                if (yes) {
                                    var opdParamsEmpty = Object.assign(opdParams);
                                    opdParamList[1].forEach((item) => {
                                        opdParamsEmpty[item].value = "";
                                        opdParamsEmpty[item].error = false;
                                    });

                                    setOpdParams(opdParamsEmpty);
                                    setItemErrors([]);
                                }
                            }}
                        />
                    </div>
                    {/* {opdParams.plantId.value !== "" ? <RenderAvailableQuantities /> : null} */}
                </div>
            </React.Fragment>
        );
    };
 const lineItemCols = isLogged.showHideWeight ? ["itemName", "from", "to", "netWeight", "grossWeight", "uom", "quantity", "packedQuantity"] : ["itemName", "uom", "quantity", "packedQuantity"];
    const RenderTable = () => {
        return (
            <table
                className="createVendorContactsTable"
            // style={{ margin: "2rem auto" }}
            >
                <thead>
                    <tr className="createVendorContactsTableHeader" key="tableHeader">
                        <td>Item</td>
                        { isLogged.showHideWeight ?
                        <>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td> 
                        </>
                        : '' }
                        <td>UOM</td>
                        <td>Order Quantity</td>
                        <td>Picking Quantity</td>

                        <td className="vendorListHeadingActions">Actions</td>
                    </tr>
                </thead>
                {itemList.length > 0 ? (
                    <tbody>
                        <RenderTableRows rows={itemList} tab="contactActions" />
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows" key="emptyRow">
                            {lineItemCols.map((item, i) => {
                                return <td key={i}>&nbsp;</td>;
                            })}
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemCols.map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                    <td className="vendorListInfoActions">
                        <img
                            alt="Edit Record"
                            className="createVendorContactsAction"
                            src={editIcon}
                            onClick={() => {
                                editSubSectionItem(j, "edit");
                            }}
                        />
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    editSubSectionItem(j, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderErrorMessage = () => {
        if (opdErrors.length > 0) return opdErrors[0];
        else if (itemErrors.length > 0) return itemErrors[0];
    };

    const RenderNoItems = () => {
        if (itemList.length > 0) {
            var uniqArr = Array.from(new Set(itemList.map((item) => item.itemId)));
            return <span>{uniqArr.length}</span>;
        } else {
            return <span>0</span>;
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div className="createSalesOrderGrid" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                            {renderFormElements({ elementList: opdParamList[0], param: opdParams })}
                        </div>
                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCustomerDetail ? <RenderCustomerDetail /> : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="purchaseOrderSubSectionArea" style={{width:"90%"}}>
                        {renderSubSection()}
                        <RenderTable />
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                Number of unique items:&nbsp;
                                <RenderNoItems />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setOpdErrors([]);
                            checkOpdError();
                        }}
                    >
                        {editApprove ? "Edit & Approve" : "Submit for Approval"}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOpd);
