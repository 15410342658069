import React,{useState,useCallback,useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import FetchNewToken from "serverCall/FetchNewToken";
import fetchData from "serverCall/fetchData";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import {editBundleCreation} from "redux/Item/ItemInfoActions";
import downIcon from "assets/down.svg";
import upIcon from "assets/up.svg";
const BundleList = ({isLogged,
  setSection,
  // state

  // dispatch
  editBundleCreation,
  addTokenToState, }) => {
    const [loading, setLoading] = useState(true);
    const [bundleCreationList, setBundleCreationList] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const history = useHistory();
    const checkToken = useCallback(async () => {
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
        history.push("/");
      } else if (token2 !== isLogged.accessToken) {
        addTokenToState(isLogged.employeeId, token2);
      } else {
       // console.log("Token not changed");
      }
    }, [isLogged, history, addTokenToState]);
  
    const getBundleList = useCallback(async () => {
      setLoading(false);
      var data = await fetchData({
        requestingPage: "sectionMaster",
        method: "post",
        url: "fetch/bundle-creation",
        headers: { token: isLogged.accessToken, module: "Bundle Creation" },
      });
      if (data.msg === "success") {
        if (data.asset.length === 0) {
          setNoRecords(true);
        } else {
          setBundleCreationList(data.asset);
        }
      } else {
        console.log(data);
      }
    }, [isLogged]);

    const getInformation = useCallback(
      async () => {
        await checkToken();
        await getBundleList();
      },
      [checkToken, getBundleList]);
  
    useEffect(() => {
      getInformation();
    }, [getInformation]);

    const toggleCollapse=(index)=>{
      let lineCopy = bundleCreationList.slice();
      lineCopy = lineCopy.map((row,lineIndex)=>{
        if(index===lineIndex){
          row.collapse = !row.collapse
        }else{
          row.collapse = true;
        }
        return row;
      });
      setBundleCreationList(lineCopy);
    }
    const RenderSubTable=({data})=>{

      return(
          <td colSpan={6} className="vendorListInnerTableWrapper">
        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading stickyFirstColumn">Section No</td>
              <td className="vendorListInnerTableHeading">Section Name</td>
              <td className="vendorListInnerTableHeading">Cutting Length</td>
              <td className="vendorListInnerTableHeading">Quantity</td>
              <td className="vendorListInnerTableHeading">Weight</td>
              <td className="vendorListInnerTableHeading">Average Weight</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row,index) => {
              return (
                <tr
                  className="vendorListInnerTable"
                  key={index}>
                  <td className="vendorListInnertableInfo stickyFirstColumn">{row.sectionNo}</td>
                  <td className="vendorListInnertableInfo">{row.sectionName}</td>
                  <td className="vendorListInnertableInfo">{row.cuttingLength}</td>
                  <td className="vendorListInnertableInfo">{row.quantity}</td>
                  <td className="vendorListInnertableInfo">{row.weight}</td>
                  <td className="vendorListInnertableInfo">{row.averageWeight}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
          </td>
      );
    }
    const renderBundleCreationList=()=>{
      return( 
        bundleCreationList.map((row, index) => {
        return (
          <Fragment key={index}>
          <tr className='vendorList' key={index}>
            <td className='vendorListInfo'>{row.bundleNo}</td>
            <td className="vendorListInfo">{row.soNo}</td>
            <td className="vendorListInfo">{row.batchNo}</td>
            <td className='vendorListInfo'>{row.totalQuantity}</td>
            <td className="vendorListInfo">{row.totalWeight}</td>
            <td className="vendorListInfoActions">
            <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={row.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCollapse(index)
                  }}
                />
              <img
                className="uomEditImage"
                alt="Edit Record"
                src={isLogged.access["Bundle Creation"] === "Edit" ? editIcon : editIconDisabled}
                onClick={() => {
                  if (isLogged.access["Bundle Creation"] === "Edit") {
                    editBundleCreation(row.bundleId);
                    setSection("editBundle");
                  }
                }}
                title={isLogged.access["Bundle Creation"] === "Edit" ? "Edit Record" : "No Edit Access"}
                style={isLogged.access["Bundle Creation"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              />
            </td>
          </tr>
          <tr>
          { !row.collapse ? <RenderSubTable data={row.bundleLineItems}/> : null}
          </tr>
          </Fragment>
        )
      })
    )
    }
  return (
    <div className="uomListArea">
    <table className="vendorListTable">
      <thead>
        <tr className="vendorListHeader">
          <td className="vendorListHeading">
            <span>Bundle No</span>
          </td>
          <td className="vendorListHeading"><span>SO No</span></td>
          <td className="vendorListHeading"><span>Batch No</span></td>
          <td className='vendorListHeading'><span>Total Quantity</span></td>
          <td className='vendorListHeading'><span>Total Weight</span></td>
          <td className="vendorListHeadingActions"><span>Action</span></td>
        </tr>
      </thead>
      <tbody>
        {bundleCreationList.length > 0 ? renderBundleCreationList() : null}
      </tbody>
    </table>
    {noRecords ? (
      <div className="noRecordsYet">No records found</div>
    ) : loading ? (
      <div className="loader" style={{ margin: "1rem auto" }}></div>
    ) : null}
  </div>
  )
}

const mapStateToProps = (state) => ({ isLogged: state.isLogged })

const mapDispatchToProps = (dispatch)=>{
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    editBundleCreation: (value)=>dispatch(editBundleCreation(value))
};
}

export default connect(mapStateToProps, mapDispatchToProps)(BundleList);
