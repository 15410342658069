import update from "immutability-helper";

const uomObj = {
    editUomId: null,
    editPlantId: null,

    editItemCategoryId: null,
    editItemBrandId: null,
    editItemTypeId: null,
    editItemProcessId: null,
    editItemId: null,
    viewItemId: null,
    editTransporterId: null,

    editItemPurchaseCostObj: null,
    editItemTaxObj: null,

    editItemPriceTypeId: null,
    editItemSalesPriceObj: null,
    editClearingAgentId: null,
    editItemCurrencyId: null,
    editCuttingLength : null,
    sectionMasterId: null,
    bundleCreationId: null,
};

const ItemInfo = (state = uomObj, action) => {
    switch (action.type) {
        case "EDIT_UOM_ID":
            return update(state, { editUomId: { $set: action.payload.uomId } });

        case "EDIT_PLANT_ID":
            return update(state, { editPlantId: { $set: action.payload.plantId } });

        case "EDIT_ITEM_CATEGORY_ID":
            return update(state, { editItemCategoryId: { $set: action.payload.itemCategoryId } });

        case "EDIT_ITEM_BRAND_ID":
            return update(state, { editItemBrandId: { $set: action.payload.itemBrandId } });

        case "EDIT_ITEM_TYPE_ID":
            return update(state, { editItemTypeId: { $set: action.payload.itemTypeId } });

        case "EDIT_ITEM_PROCESS_ID":
            return update(state, { editItemProcessId: { $set: action.payload.itemProcessId } });

        case "EDIT_ITEM_ID":
            return update(state, { editItemId: { $set: action.payload.itemId } });

        case "VIEW_ITEM_ID":
            return update(state, { viewItemId: { $set: action.payload.itemId } });

        case "EDIT_PURCHASE_COST_OBJ":
            return update(state, { editItemPurchaseCostObj: { $set: action.payload.purchaseObj } });

        case "EDIT_ITEM_TAX_OBJ":
            return update(state, { editItemTaxObj: { $set: action.payload.taxObj } });

        case "EDIT_ITEM_PRICE_TYPE_ID":
            return update(state, { editItemPriceTypeId: { $set: action.payload.itemPriceTypeId } });

        case "EDIT_ITEM_SALES_PRICE_OBJ":
            return update(state, { editItemSalesPriceObj: { $set: action.payload.salePriceObj } });

        case "EDIT_TRANSPORTER_ID":
            return update(state, { editTransporterId: { $set: action.payload.transporterId } });

        case "EDIT_CLEARING_AGENT_ID":
            return update(state, { editClearingAgentId: { $set: action.payload.clearingAgentId } });

        case "EDIT_ITEM_CURRENCY_ID":
            return update(state, {editItemCurrencyId: {$set: action.payload.itemCurrencyId}});
            
        case "EDIT_PREMIUM_CHARGES":
            return update(state,{premiumChargesId: {$set: action.payload.premiumChargesId} });

        case "EDIT_SECTION_MASTER":
            return update(state,{sectionMasterId: {$set: action.payload.sectionMasterId} });

        case "EDIT_CUTTING_LENGTH":
            return update(state,{cuttingLengthId: {$set: action.payload.cuttingLengthId}});
        case "EDIT_BUNDLE_CREATION":
            return update(state,{bundleCreationId:{$set: action.payload.bundleCreationId} });
        default:
            return state;
    }
};

export default ItemInfo;
