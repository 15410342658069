import update from "immutability-helper";

const roleObj = {
    editRole: false,
    editRoleNumber: null,
    editRoleControlId: null,

    editScreenControlId: null,
    viewScreenControlId: null,
};

const RoleInfo = (state = roleObj, action) => {
    switch (action.type) {
        case "EDIT_ROLE_NUMBER":
            return update(state, { editRoleNumber: { $set: action.payload } });

        case "EDIT_ROLE_CONTROL":
            return update(state, { editRoleControlId: { $set: action.payload.roleControlId } });

        case "EDIT_SCREEN_CONTROL":
            return update(state, { editScreenControlId: { $set: action.payload.screenControlId } });

        case "VIEW_SCREEN_CONTROL":
            return update(state, { viewScreenControlId: { $set: action.payload.screenControlId } });

        default:
            return state;
    }
};

export default RoleInfo;
