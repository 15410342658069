const { default: jwtDecode } = require("jwt-decode");

const FetchNewToken = (token) => {
    // // console.log(token);
    if (token.length > 2) {
        return new Promise((resolve) => {
            // console.log("======= DECODING TOKEN =======");
            const decoded = jwtDecode(token);
            let expiry = decoded.exp;

            // get current time
            var d = new Date();
            var currentTime = d.getTime();
            var currentTimeStr = `${currentTime}`;

            // check if expired
            var timeShort = currentTimeStr.substr(0, 10);
            let diff = expiry - timeShort;
            // console.log(`Current time: ${timeShort} \nExpiry time: ${expiry} \nDifference: ${diff}`);

            // if token is expired, get a new token here
            if (diff < 7200) {
                // console.log("GET REFRESH TOKEN");

                // // console.log("FETCH_NEW_TOKEN function called");
                // let payload = { token: token };
                // // console.log(payload);

                // Axios.post(serverEndpoint + "refreshtoken", payload)
                //     .then((res) => {
                //         let receivedToken = res.data.new_token;
                //         // console.log("Axios success", receivedToken);
                //         resolve(receivedToken);
                //     })
                //     .catch((err) => // console.log("Axios error", err));
                resolve("expired");
                // }
                // if (diff <= 300) {
                //     // Log out and redirect to Login page
                //     resolve("expired");
            } else {
                resolve(token);
            }
        });
    } else {
        // console.log("*************\n Token Expired ");
        return "expired";
    }
};

export default FetchNewToken;
