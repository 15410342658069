import React, { useState, useEffect } from "react";
import FormElement from "../SmallComponents/FormElement";
import { useHistory } from "react-router-dom";
import update from "immutability-helper";
import FormHint from "../SmallComponents/FormHint";
import { Country, State, City } from "country-state-city";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import fetchData from "../../serverCall/fetchData";
import Modal from "react-modal/lib/components/Modal";

const CreateLocation = ({ setSection, isLogged, addTokenToState }) => {
  const [locationParams, setLocationParams] = useState({
    country: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Country Name",
      mandatory: true,
      colSpan: 5,
      error: false,
      errorMessage: "Select A country",
    },
    state: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "State/Province/Region Name",
      mandatory: true,
      colSpan: 10,
      error: false,
      isCreatableMulti: true,
      errorMessage: "Select A State/Province/Region",
      disabled: true,
      hasSelectAll: true,
    },
  });

  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cityArray, setCityArray] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Inserting Location. Please wait..."
  );
  const submitOkClick = () => {
    setIsOpen(false);
    if (errors.length === 0 && dataSubmitted) {
      setSection("locationList");
    }
  };

  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);

  const [declineReason, setDeclineReason] = useState("");
  const declineOkClick = () => {
    checkErrors({state:"submit"});
    setDeclineModalIsOpen(false);
  };
  async function checkToken() {
  
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
     
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }
  let CountryList, StateList;

  useEffect(async () => {
    await checkToken();
    CountryList = Country.getAllCountries();
    CountryList = CountryList.map((cou) => {
      cou.optionId = { countryCode: cou.isoCode, countryName: cou.name.toUpperCase() };
      cou.optionName = cou.name.toUpperCase();
      return cou;
    });
    updateParameters({
      section: "general",
      param: "country",
      key: "options",
      value: CountryList,
    });
  }, []);

  const updateParameters = ({ section, param, key, value }) => {
    let locationCopy = Object.assign(locationParams);
    if (param === "state" && key === "value" && cityArray.length > 0) {
      var yes = window.confirm("Selected Cities Will be Cleared!!");
      if (yes) {
        locationCopy = update(locationCopy, {
          [param]: { [key]: { $set: value } },
        });
      } else {
        console.log("");
      }
    } else {
      locationCopy = update(locationCopy, {
        [param]: { [key]: { $set: value } },
      });
    }
    setLocationParams(locationCopy);
  };

  useEffect(() => {
    let locationCopy = Object.assign(locationParams);
    if (locationParams.country.value != "" && locationParams.country.value) {
      setSelectedCountry({
        countryName: locationParams.country.value.countryName,
        countryCode: locationParams.country.value.countryCode,
      });
      StateList = State.getStatesOfCountry(
        locationParams.country.value.countryCode
      );
      StateList = StateList.map((sta) => {
        sta.optionId = { stateCode: sta.isoCode, stateName: sta.name.toUpperCase() };
        sta.optionName = sta.name.toUpperCase();
        return sta;
      });
      locationCopy = update(locationCopy, {
        state: {
          options: { $set: StateList },
          value: { $set: [] },
          disabled: { $set: false },
        },
      });
    } else {
      setSelectedCountry(null);
      locationCopy = update(locationCopy, {
        state: {
          options: { $set: [] },
          value: { $set: [] },
          disabled: { $set: true },
        },
      });
    }
    setLocationParams(locationCopy);
  }, [locationParams.country.value]);

  useEffect(() => {
    // on state selection/deletion
    if (locationParams.state.value.length > 0 && selectedCountry) {
      let stateBoxValue;
      if (locationParams.state.value[0].label === "SELECT ALL") {
        stateBoxValue = locationParams.state.value[0].value.map(
          ({ stateName, stateCode }) => ({ stateName, stateCode })
        );
      } else {
        stateBoxValue = locationParams.state.value.map(({ value }) => ({
          stateName: value.stateName ? value.stateName : value,
          stateCode: value.stateCode ? value.stateCode : value,
        }));
      }

      let lineCopy = stateBoxValue.reverse();
      setTableData(lineCopy);
    } else {
      setTableData([]);
      setCityArray([]);
    }
  }, [locationParams.state.value]);

  useEffect(() => {
    setCityArray([]);
  }, [tableData.length]);

  const checkErrors = ({state}) => {
    var errorList = [];
    let locationCopy = Object.assign(locationParams);
    for (let item in locationCopy) {
      if (locationCopy[item].mandatory) {
        locationCopy[item].error = !validateMandatory(
          locationCopy[item].value.toString()
        );
      }

      if (locationCopy[item].error) {
        errorList.push(locationCopy[item].errorMessage);
      }
    }

    let cityCheckCondition = cityArray.some(
      (row, ind) =>
        !(
          (Array.isArray(row?.cities) ||
            row?.cities?.length > 0 ||
            tableData[ind]["stateName"] != row.state) &&
          row != null
        )
    );
    if (
      cityCheckCondition ||
      cityArray.length < 1 ||
      cityArray.length != tableData.length
    ) {
      errorList.push("City Value Can't be Empty");
    }
    // console.log(errorList, "errorList", cityCheckCondition, cityArray);
    if (errorList.length === 0) {
      setDeclineModalIsOpen(true);
      let locationList = [
        {
          country: locationCopy.country.value.countryName,
          states: Object.entries(cityArray).map(([key, row]) => ({
            state: row.state,
            cities:
              row.cities[0].label === "SELECT ALL"
                ? row.cities[0].value.map((val) => ({ city: val }))
                : row.cities.map((cityBox) => ({ city: cityBox.value })),
          })),
        },
      ];

      //console.log(locationList, "locationList");
      setDeclineReason(<RenderTable disabled={true} />);
      if (state !== "review" && state =="submit") {
        submitData(locationList);
      }
    } else {
      setErrors(errorList);
    }
  };

  async function submitData(dataToServer) {
    setIsOpen(true);
    var result = await fetchData({
      requestingPage: "location",
      method: "post",
      url: "create/location",
      headers: { token: isLogged.accessToken, module: "Location" },
      data: { locationList: dataToServer },
    });

    if (result && result.msg === "success") {
      setModalText("Location Inserted SuccessFully");
    } else {
      setModalText([result.desc]);
      setErrors([result.desc]);
    }
    setDataSubmitted(true);
  }
  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value !== null ? param[element].value : ""}
          setInput={(value) => {
            updateParameters({
              section,
              param: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={param[element].options ? param[element].options : null}
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
          isCreatableMulti={
            param[element].isCreatableMulti
              ? param[element].isCreatableMulti
              : null
          }
          hasSelectAll={
            param[element].hasSelectAll ? param[element].hasSelectAll : null
          }
        />
      );
    });
  };

  const renderErrorMessage = () => {
    if (errors.length > 0) return errors[0];
  };

  const getCitiesOfStateOption = (countryCode, stateCode) => {
    let citiesList = City.getCitiesOfState(countryCode, stateCode);
    //console.log(citiesList,"citiesList",countryCode,stateCode);
    citiesList = citiesList.map((cit) => ({
      optionId: cit.name.toUpperCase(),
      optionName: cit.name.toUpperCase(),
    }));
    return citiesList;
  };

  const mapCitiesToStates = ({ stateName, cityList, stateInd }) => {
    let lineCopy = cityArray.slice();
    lineCopy[stateInd] = {};
    lineCopy[stateInd] = { state: stateName };
    lineCopy[stateInd]["cities"] = null;
    lineCopy[stateInd]["cities"] = cityList.length > 0 ? cityList : null;
    // console.log("In map", lineCopy);
    setCityArray(lineCopy);
  };

  const RenderTable = ({ disabled }) => {
    return tableData.map((row, ind) => {
      return (
        <tr
          className={"createVendorContactsTableRows"}
          // style={{ height: "60px" }}
          style={
            disabled
              ? { pointerEvents: "none", height: "60px" }
              : { height: "60px" }
          }
          key={ind}>
          <td>{selectedCountry?.countryName}</td>
          <td>{row.stateName}</td>
          <td>
            <div style={{ width: "250px", height: "30px" }}>
              <FormElement
                setInput={(value) => {
                  mapCitiesToStates({
                    stateName: row?.stateName,
                    cityList: value,
                    stateInd: ind,
                  });
                }}
                inputType={"multiSelect"}
                value={cityArray[ind]?.cities}
                options={getCitiesOfStateOption(
                  selectedCountry?.countryCode,
                  row.stateCode
                )}
                colSpan={1}
                rowSpan={1}
                error={false}
                errorMessage=""
                hideHint={true}
                isCreatableMulti={true}
                disabled={false}
                hasSelectAll={true}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={declineModalIsOpen}
        contentLabel="Example Modal"
        style={{
          overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
          content: {
            textAlign: "center",
            width: "70%",
            height: "70%",
            position: "fixed",

            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",

            // borderRadius: "5px",
            boxShadow: "0 0 5px gray",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          },
        }}>
        <div
          style={{
            lineHeight: "1rem",
            fontSize: "15px",
          }}>
          {"Review Before Submit"}
        </div>
        <div
          style={{
            margin: "5rem",
            maxHeight: "550px",
            overflow: "scroll",
            disabled: true,
          }}
          onMouseOver={(evt) => {
            if (evt.target == evt.currentTarget) evt.stopPropagation();
          }}>
          {declineReason}
        </div>
        <div>
          <button
            className="cancelButton"
            onClick={() => {
              setDeclineModalIsOpen(false);
            }}>
            Cancel
          </button>
          <span style={{ margin: "0 1rem" }}></span>
          <button className="modalButton" onClick={declineOkClick}>
            Submit
          </button>
        </div>
      </Modal>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "1100px",
            height: "500px",
            margin: "0 auto 4rem",
            padding: "3rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div className="createItemPurchaseGrid">
            {renderFormElements({
              elementList: Object.keys(locationParams),
              param: locationParams,
              section: "general",
            })}
          </div>
          <div>&nbsp;</div>
          <div style={{ height: "480px", overflow: "scroll" }}>
            {tableData.length > 0 ? (
              <table
                className="createItemPurchaseTable"
                style={{
                  margin: "2rem auto",
                }}>
                <thead>
                  <tr className="createVendorContactsTableHeader">
                    <td>Country</td>
                    <td>State/Province/Region</td>
                    <td>
                      City/Area<span className="mandatory">*</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <RenderTable disabled={false} />
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button
            className="submitButton"
            onClick={() => {
              setErrors([]);
              checkErrors({state:"review"});
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocation);
