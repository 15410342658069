import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import receiptRupee from "../../assets/receipt-rupee.svg";
import CreateVendorPayment from "./createVendorPayment";
import VendorPaymentList from "./vendorPaymentsList";
import VendorPaymentDetail from "./vendorPaymentDetail";
import EditVendorPayment from "./editVendorPayment";

const VendorPayments=({isLogged})=>{
    const [currentSection,setCurrentSection]=useState("vendorPaymentsList");
    const [approvalPage,setApprovalPage]=useState(false);
    const [editAndApprove,setEditAndApprove]=useState(false);
    const access = isLogged.access["Vendor Payments"];
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "vendorPaymentsList" };
    var createReceipt = {buttonName:"Create Vendor Payment", className:"employeeButton",setSection: setCurrentSection,sectionName: "createVendorPayment"}


    const renderSection=(section)=>{
        switch(section){
            case "vendorPaymentsList":
                return(
                    <React.Fragment>
                        <PageTitle pageTitle={"Vendor Payments"} imgSrc={receiptRupee} 
                        buttonList={access === "Create"  || access === "Edit" || access==="App/Dec" ? [createReceipt] : null}/>
                    <VendorPaymentList setSection={(value)=>{setCurrentSection(value)}} setApproval={setApprovalPage}  
                                            setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                );
            
            case "createVendorPayment":
                return(
                    <React.Fragment>
                        <PageTitle pageTitle={"Create Vendor Payment"} imgSrc={receiptRupee} buttonList={[goBackButton]}/>
                        <CreateVendorPayment setSection={(value)=>setCurrentSection(value)}/>
                    </React.Fragment>
                );
            
            case "venPaymentDetail":
                return(
                    <React.Fragment>
                        <PageTitle pageTitle={"Vendor Payment Detail"} imgSrc={receiptRupee} buttonList={[goBackButton]}/>
                        <VendorPaymentDetail setSection={(value)=>setCurrentSection(value)} approval={approvalPage} 
                                    setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                );

            case "editVenPayment":
                return(
                    <React.Fragment>
                    <PageTitle imgSrc={receiptRupee} pageTitle="Edit Vendor Payment" buttonList={[goBackButton]}/>
                    <EditVendorPayment setSection={(value)=>setCurrentSection(value)} editApprove={editAndApprove}/>
                </React.Fragment>
                );

            default: 
                return(<div>Page Yet To be Built</div>);
        }
    }

    return(
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Purchase" selectedSubMenu="Vendor Payments" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(VendorPayments);