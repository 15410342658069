import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";

import qualityIcon from "../../assets/quality.svg";
import CreateQc from "./CreateQc";
import QcList from "./QcList";
import QualityDetail from "./QualityDetail";
import EditQc from "./EditQc";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const Quality = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("qualityList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    const [loadInward, setLoadInward] = useState({ load: false });

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "qualityList" };
    var qualityChecking = { buttonName: "Create Qc", className: "employeeButton", setSection: setCurrentSection, sectionName: "createQc", callback: () => setLoadInward({ load: false }) }
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "qcDetail") }, sectionName: "qualityList" };

    const renderSection = (section) => {
        switch (section) {
            case "createQc":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={qualityIcon} pageTitle="Create Quality Checking" buttonList={[goBackButton]} />
                        <CreateQc setSection={(value) => setCurrentSection(value)} loadInward={loadInward} />
                    </React.Fragment>
                );

            case "qualityList":
                return (
                    <React.Fragment>
                        {/* <div className="pageTitle">
                            <img className="pageTitleImage" alt="Page Title" src={qualityIcon}></img>
                            <span className="pageTitleText">Quality Checking</span>
                            <div className="buttonList">
                                <button
                                    className="employeeButton"
                                    onClick={() => {
                                        setLoadInward({ load: false });
                                        setCurrentSection("createQc");
                                    }}
                                >
                                    Create QC
                                </button>
                            </div>
                        </div> */}
                        <PageTitle imgSrc={qualityIcon} pageTitle={"Quality Checking"}
                            buttonList={isLogged.access["Quality Checking"] === "Create" || isLogged.access["Quality Checking"] === "Edit" || isLogged.access["Quality Checking"] === "App/Dec" ?
                                [qualityChecking] : null} />
                        <QcList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={setApprovalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setLoadInward={setLoadInward}
                        />
                    </React.Fragment>
                );

            case "qcDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={qualityIcon} pageTitle="Quality Checking Detail" buttonList={[goBackButton, downloadButton]} />
                        <QualityDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "qcEdit":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={qualityIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Quality Checking" : "Edit Quality Checking"}
                            buttonList={[goBackButton]}
                        />
                        <EditQc setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} loadInward={loadInward} />
                    </React.Fragment>
                );

            default:
                return (
                    <div>
                        Page <b>{section}</b> yet to be built
                    </div>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Quality Checking" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Quality);
