import React from "react";
import Modal from "react-modal";

const LoadingModal = ({ isLoading }) => {
    Modal.setAppElement("body");
    return (
        <Modal
            isOpen={isLoading}
            contentLabel="Loading Modal"
            style={{
                overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                content: {
                    textAlign: "center",
                    width: "10%",
                    height: "10%",
                    position: "fixed",
                    top: "42%",
                    left: "42%",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px gray",

                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <div className="loader" style={{ margin: "1rem auto" }} ></div>
        </Modal>
    );
};

export default LoadingModal;
