import React, { useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import brandIcon from "../../../assets/brand_black.svg";
import ItemBrandList from "./ItemBrandList";
import CreateItemBrand from "./CreateItemBrand";
import EditItemBrand from "./EditItemBrand";
import TopBanner from "../../SmallComponents/TopBanner";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const ItemBrand = ({ isLogged }) => {
    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    const [currentSection, setCurrentSection] = useState("itemBrandList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemBrandList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemBrandButton = {
        buttonName: "Create Brand",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createItemBrand",
    };

    const renderSection = (section) => {
        
        switch (section) {
            case "itemBrandList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={brandIcon}
                            pageTitle="Item Brand"
                            buttonList={
                                isLogged.access["Item Brand"] === "Create" || isLogged.access["Item Brand"] === "Edit"
                                    ? [createItemBrandButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemBrandList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createItemBrand":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={brandIcon} pageTitle="Create Item Brand" buttonList={goBackButton} />
                        <CreateItemBrand setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editItemBrand":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={brandIcon} pageTitle="Edit Item Brand" buttonList={goBackButton} />
                        <EditItemBrand setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemBrand);
