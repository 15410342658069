import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { editTransferId } from "../../redux/Store/StoreInfoActions";

const StockTransferDetail = ({
    // props
    setSection,
    approval,
    setEditAndApprove,

    // state
    isLogged,
    storeInfo,

    // dispatch
    addTokenToState,
    editTransferId,
}) => {
    const [transferDetail, setTransferDetail] = useState({});
    const [transferDocument, setTransferDocument] = useState("");

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getTransferDetail();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getTransferDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "store-fetch/stock-transfer-detail/" + storeInfo.viewTransferId,
            headers: { token: isLogged.accessToken,module:"Stock Transfer" },
        });
        if (data.msg === "success") {

            setTransferDetail(data.asset[0]);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        if (transferDetail.transferDocument !== null) {
            getTransferDocument();
        }
        // eslint-disable-next-line
    }, [transferDetail]);

    async function getTransferDocument() {

        if (transferDetail?.transferDocument !== null && transferDetail.transferDocument !== undefined) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/stock-transfer-document/" + transferDetail.transferDocument,
                headers: { token: isLogged.accessToken,module:"Stock Transfer" },
            });

            if (data !== "") {
                setTransferDocument(data);
            }
        }
    }

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>UOM</td>
                        <td>Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={transferDetail.lineItems} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "itemCode","from","to","netWeight","grossWeight", "uom", "quantity"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key]? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Stok Transfer. Please wait...");

    const [diagramImage, setDiagramImage] = useState("");
    const [diagramModalOpen, setDiagramModalOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState([]);
    const renderErrorMessage = () => {
        if (errorMessage !== "") {
            return errorMessage;
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            editTransferId(storeInfo.viewTransferId);
                            setSection("editTransfer");
                        }}
                    >
                        Edit
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            approveTransfer();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    async function approveTransfer() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/stock-transfer-approve/${storeInfo.viewTransferId}`,
            headers: { token: isLogged.accessToken,module:"Stock Transfer" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Stock Transfer Approved!");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const submitOkClick = () => {
        setIsOpen(false);

        setSection("transferList");

        // setModalText("Approving Stock Transfer. Please wait...");
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <Modal
                isOpen={diagramModalOpen}
                onRequestClose={() => setDiagramModalOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                    content: {
                        textAlign: "center",
                        width: "70%",
                        height: "70%",
                        position: "fixed",
                        top: "15%",
                        left: "15%",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px gray",
                    },
                }}
            >
                <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
            </Modal>

            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div style={{ display: "flex" }}>
                        <span className="vendorSummaryRole" style={{ textTransform: "capitalize", flex: "1" }}>
                            {transferDetail.transferNo}{" "}
                            <span
                                className="vendorSummaryCategory"
                                style={
                                    transferDetail.transferStatus === "Declined"
                                        ? { color: "red" }
                                        : transferDetail.transferStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                                }
                            >
                                ( {transferDetail.transferStatus} )
                            </span>
                        </span>

                        <span
                            className="vendorSummaryRole"
                            style={{
                                textTransform: "capitalize",
                                flex: "1",
                                // textAlign: "end",
                            }}
                        >
                            <span style={{ color: "#333333", margin: "0 3rem" }}>Transfer Date : </span> {transferDetail.transferDate}{" "}
                        </span>
                    </div>
                    <div className="vendorSummary">
                        {/* <div className="poSummaryText">{renderSummaryInfo()}</div> */}

                        <div
                            className="poSummaryText2"
                            style={{
                                textAlign: "left",
                                margin: "0",
                                // backgroundColor: "white",
                                padding: "20px 3rem",
                                margin: "20px 3rem 20px 0",
                                // boxShadow: "0 0 3px 3px rgb(220,220,220)",
                                border: "2px solid rgb(220,220,220)",
                            }}
                        >
                            <span className="vendorSummaryCategory">
                                From Storage <br />
                                <br />
                            </span>
                            <span className="vendorSummaryValue">
                                <span style={{ color: "#ce9547", fontWeight: "bold" }}>
                                    {transferDetail.fromStorageName} <br />
                                </span>
                                {transferDetail.fromStorageAddress}, <br />
                                <br />
                                <span style={{ color: "#ce9547", fontWeight: "bold" }}>
                                    {transferDetail.fromPlantName}
                                    <br />
                                </span>
                                {transferDetail.fromPlantState}, <br />
                                {transferDetail.fromPlantCity}
                                {transferDetail.fromPlantPinCode !== null ? ` - ${transferDetail.fromPlantPinCode}.` : "."}
                            </span>
                        </div>
                        <div
                            className="poSummaryText2"
                            style={{
                                textAlign: "left",
                                margin: "0",
                                // backgroundColor: "white",
                                padding: "20px 3rem",
                                margin: "20px 0 20px 3rem",
                                // boxShadow: "0 0 3px 3px rgb(220,220,220)",
                                border: "2px solid rgb(220,220,220)",
                            }}
                        >
                            <span className="vendorSummaryCategory">
                                To Storage <br />
                                <br />
                            </span>
                            <span className="vendorSummaryValue">
                                <span style={{ color: "#ce9547", fontWeight: "bold" }}>
                                    {transferDetail.toStorageName}
                                    <br />
                                </span>
                                {transferDetail.toStorageAddress}, <br />
                                <br />
                                <span style={{ color: "#ce9547", fontWeight: "bold" }}>
                                    {transferDetail.toPlantName}
                                    <br />
                                </span>
                                {transferDetail.toPlantState}, <br />
                                {transferDetail.toPlantCity}
                                {transferDetail.toPlantPinCode !== null ? ` - ${transferDetail.toPlantPinCode}.` : "."}
                            </span>
                        </div>
                    </div>

                    <div className="vendorDetailTabArea">
                        {transferDetail.lineItems !== undefined && transferDetail.lineItems?.length > 0 ? <RenderTable /> : null}
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items:
                                <span> {transferDetail.lineItems?.length}</span>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                {transferDetail.batchNo !== null ? (
                                    <div>
                                        Batch No:
                                        <span> {transferDetail.batchNo}</span>
                                    </div>
                                ) : null}
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Stock Transfer Instruction: {transferDetail.instruction !== null ? transferDetail.instruction : "NA"}
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Stock Transfer Document:{" "}
                                {transferDetail.transferDocument !== null ? (
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                        onClick={() => {
                                            setDiagramModalOpen(true);
                                            setDiagramImage(transferDocument);
                                        }}
                                    >
                                        Open link
                                    </span>
                                ) : (
                                    "NA"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editTransferId: (transferId) => dispatch(editTransferId(transferId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockTransferDetail);
