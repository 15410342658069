import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import logoNotAvailable from "../../assets/no-stopping.svg";

const CustomerDetail = ({
    // props
    setSection,

    // state
    isLogged,
    customerInfo,

    // dispatch
    addTokenToState,
}) => {

    const [customerDetail, setCustomerDetail] = useState(null);
    const [customerContactDetail, setCustomerContactDetail] = useState([]);
    const [customerDeliverAddress, setCustomerDeliverAddress] = useState([]);
    const [customerBankDetail, setCustomerBankDetail] = useState([]);
    const [customerAddInfo, setCustomerAddInfo] = useState();

    const [customerLogo, setCustomerLogo] = useState();

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getCustomerDetail();
    }

    const history = useHistory();

    async function checkToken() {
        //  console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getCustomerDetail() {
        var data = await fetchData({
            requestingPage: "customerDetail",
            method: "get",
            url: "fetch/customer/" + customerInfo.viewCustomerId,
            headers: { token: isLogged.accessToken,module:"Customer" },
        });
        if (data.msg === "success") {
         
            setCustomerDetail(data.asset.customerDetail[0]);
            setCustomerContactDetail(data.asset.contactDetail);
            setCustomerDeliverAddress(data.asset.deliveryDetail);
            setCustomerBankDetail(data.asset.bankDetail);
            setCustomerAddInfo(data.asset.additionalInfo[0]);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        getCustomerLogo();
        // eslint-disable-next-line
    }, [customerDetail]);

    async function getCustomerLogo() {
        var data = await fetchData({
            requestingPage: "CustomerImage",
            method: "get",
            url: "image/customer-logo/" + customerInfo.viewCustomerId,
            headers: { token: isLogged.accessToken ,module:"Customer"},
        });
        setCustomerLogo(data);
    }

    const renderImageStatus = () => {
        return (
            <div>
                {/* eslint-disable-next-line */}
                <img
                    className="vendorImage"
                    src={
                        customerLogo !== null && customerLogo !== undefined && customerLogo !== ""
                            ? `data:image/png;base64,${customerLogo}`
                            : logoNotAvailable
                    }
                />
                <div className="vendorCurrentStatus">
                    <span className="vendorSummaryCategory">Status :</span>
                    <span className="vendorStatus">Active</span>
                </div>
            </div>
        );
    };

    const renderSummaryInfo = () => {
        if (customerDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole">{customerDetail.companyName}</span>

                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Mailing Name</span>
                        <span className="vendorSummaryValue">: {customerDetail.mailingName}</span>

                        <span className="vendorSummaryCategory">Customer Id</span>
                        <span className="vendorSummaryValue">: {customerDetail.customerId}</span>

                        <span className="vendorSummaryCategory">Mobile Number</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.mobile !== undefined && customerDetail.mobile !== null && customerDetail.mobile.length > 0
                                ? customerDetail.mobile
                                : "NA"}{" "}
                            {customerDetail.phone ? ", " + customerDetail.phone : null}
                        </span>

                        <span className="vendorSummaryCategory">Email Id</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.email !== undefined && customerDetail.email !== null && customerDetail.email.length > 0
                                ? customerDetail.email
                                : "NA"}
                        </span>

                        <span className="vendorSummaryCategory">Website</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.website !== undefined && customerDetail.website !== null && customerDetail.website.length > 0
                                ? customerDetail.website
                                : "NA"}
                        </span>

                        <span className="vendorSummaryCategory">Parent Company</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.parentCompanyName !== undefined &&
                            customerDetail.parentCompanyName !== null &&
                            customerDetail.parentCompanyName.length > 0
                                ? customerDetail.parentCompanyName
                                : "NA"}
                        </span>

                        <span className="vendorSummaryCategory">Customer Type</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.customerType !== undefined &&
                            customerDetail.customerType !== null &&
                            customerDetail.customerType.length > 0
                                ? customerDetail.customerType
                                : "NA"}
                        </span>

                        <span className="vendorSummaryCategory">GST No</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.gstNo !== null && customerDetail.gstNo !== undefined && customerDetail.gstNo.length > 0
                                ? customerDetail.gstNo
                                : "NA"}
                            {customerDetail.gstRegisterType !== null &&
                            customerDetail.gstRegisterType !== undefined &&
                            customerDetail.gstRegisterType.length > 0
                                ? ` - (${customerDetail.gstRegisterType})`
                                : null}
                        </span>

                        <span className="vendorSummaryCategory">Credit Days</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {customerDetail.creditDays !== undefined && customerDetail.creditDays !== null && customerDetail.creditDays.length > 0
                                ? customerDetail.creditDays
                                : "NA"}
                        </span>

                        <span className="vendorSummaryCategory">Price Level</span>
                        <span className="vendorSummaryValue">: {customerDetail.priceLevel}</span>

                        <span className="vendorSummaryCategory">Credit Amount Limit</span>
                        <span className="vendorSummaryValue">: {customerDetail.creditAmountLimit}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderAddress = () => {
        if (customerDetail != null) {
            return (
                <React.Fragment>
                    <div className="vendorSummaryAddress">Address </div>

                    <div className="vendorSummaryAddressValue">{customerDetail.address}</div>
                    <div className="vendorSummaryAddressValue">
                        {customerDetail.city}
                        {customerDetail.zipCode !== null ? ` - ${customerDetail.zipCode}` : null}
                    </div>
                    <div className="vendorSummaryAddressValue">
                        {customerDetail.state}, {customerDetail.country}
                    </div>
                </React.Fragment>
            );
        }
    };

    const tablist = [
        { id: "contactDetails", name: "Contact Details" },
        { id: "deliverAddress", name: "Deliver Address" },
        { id: "bankDetails", name: "Bank Details" },
        { id: "additionalInfo", name: "Additional Information" },
    ];
    const [selectedTab, setSelectedTab] = useState("contactDetails");
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };

    const RenderTable = ({ tab }) => {
        switch (tab) {
            case "contactDetails":
                return (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Contact Name</td>
                                <td>Designation</td>
                                <td>Department</td>
                                <td>Mobile No</td>
                                <td>Email Id</td>
                                <td>Experience</td>
                                <td>Education Details</td>
                            </tr>
                        </thead>
                        <tbody>
                            <RenderTableRows rows={customerContactDetail} tab="contact" />
                        </tbody>
                    </table>
                );

            case "deliverAddress":
                return (
                    <div className="customerDeliverAddress">
                        <RenderDeliverAddressIndiCards rows={customerDeliverAddress} />
                    </div>
                );

            case "bankDetails":
                return (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Account Holder Name</td>
                                <td>Account Number</td>
                                <td>Bank Name</td>
                                <td>IFSC Code</td>
                                <td>Branch</td>
                            </tr>
                        </thead>

                        <tbody>
                            <RenderTableRows rows={customerBankDetail} tab="bank" />
                        </tbody>
                    </table>
                );

            case "additionalInfo":
                return (
                    <div className="vendorAdditionalInfo">
                        <span className="vendorSummaryCategory">Founded Date</span>
                        <span>:</span>
                        <span className="vendorSummaryValue">
                            {customerAddInfo.registerDate !== null ? new Date(customerAddInfo.registerDate).toDateString() : "NA"}
                        </span>
                        <span className="vendorSummaryCategory">Industry Type</span> <span>:</span>
                        <span className="vendorSummaryValue">
                            {customerAddInfo.industryType !== null && customerAddInfo.industryType.length > 0 ? customerAddInfo.industryType : "NA"}
                        </span>
                        <span className="vendorSummaryCategory">Other Business Details</span>
                        <span>:</span>
                        <span className="vendorSummaryValue">
                            {customerAddInfo.otherBusinessDetails !== null && customerAddInfo.otherBusinessDetails.length > 0
                                ? customerAddInfo.otherBusinessDetails
                                : "NA"}
                        </span>
                    </div>
                );

            default:
                return <div>No Table rendered</div>;
        }
    };

    var contactDetailKeys = ["contactName", "designation", "department", "mobile", "email", "experience", "education"];
    var bankDetailKeys = ["accountHolderName", "accountNumber", "bankName", "ifscCode", "branch"];

    const RenderTableRows = ({ rows, tab }) => {
     

        var tableKeys = [];
        if (tab === "contact") tableKeys = contactDetailKeys;
        if (tab === "bank") tableKeys = bankDetailKeys;

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {tableKeys.map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                </tr>
            );
        });
    };

    const RenderDeliverAddressIndiCards = ({ rows }) => {
        return rows.map((row) => {
            return (
                <div className="customerDeliverAddressIndiCard">
                    <div className="customerDeliverCardTitle">{row.consignorCompanyName}</div>

                    <div className="customerDeliverCardGrid">
                        <span className="customerDeliverLeftCard">
                            <span className="vendorSummaryCategory">GST No</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">
                                {row.gstNo} {row.gstRegisterType !== null ? ` (${row.gstRegisterType})` : null}
                            </span>

                            <span className="vendorSummaryCategory">Address</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">
                                {`${row.address}`} <br />
                                {`${row.city}`}
                                {row.pinCode !== null && row.pinCode.length > 0 ? ` - ${row.pinCode},` : ","}
                                <br /> {` ${row.state}, ${row.country}`}
                            </span>
                        </span>
                        <span className="customerDeliverRightCard">
                            <span className="vendorSummaryCategory">Contact name</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">{row.contactName}</span>

                            <span className="vendorSummaryCategory">Mobile</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">{row.mobile}</span>

                            <span className="vendorSummaryCategory">Designation</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">
                                {row.designation !== null && row.designation.length > 0 ? row.designation : "NA"}
                            </span>

                            <span className="vendorSummaryCategory">Department</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">{row.department !== null && row.department.length > 0 ? row.department : "NA"}</span>

                            <span className="vendorSummaryCategory">Email</span>
                            <span>:</span>
                            <span className="vendorSummaryValue">{row.email !== null && row.email.length > 0 ? row.email : "NA"}</span>
                        </span>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="detailsPageWrapper">
            <div className="detailsPageContainer">
                <div className="vendorSummary">
                    {renderImageStatus()}
                    <div className="vendorSummaryText">{renderSummaryInfo()}</div>
                    <div style={{ flex: "1" }} />
                    <div className="vendorSummaryText">{renderAddress()}</div>
                </div>

                <div className="vendorDetailTabArea">
                    <div className="vendorDetailTabs">
                        {/* {renderTabList()} */}
                        <RenderTabList tablist={tablist} />
                    </div>
                    <RenderTable tab={selectedTab} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        customerInfo: state.customerInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
