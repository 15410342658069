import { Fragment,useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import StockReportParams from "./stockReportParams";
import StockReportList from "./stockReportList";
import StatementIcon from "../../../assets/sales_report.svg"
import { exportPDF } from "../../CommonFunctions/ExportPDF";
import {useHistory} from "react-router-dom";

const StockReport = ({ isLogged }) => {
    const [currentSection,setCurrentSection] = useState("stockReportParams");

    const history = useHistory();

    var downloadButton={buttonName:"Download",className:"exportButton",setSection: async () => { await exportPDF(isLogged, "stockReport") },sectionName:"stockReportParams"}
    var goBackButton = (props) =>
    ({
      buttonName: "Back",
      className: "goBackButton",
      ...props
    });
    const renderSection=(currentSection)=>{
        switch(currentSection){
            case "stockReportParams":
                return(
                    <Fragment>
                    <PageTitle imgSrc={StatementIcon} pageTitle={"Item Report"}
                        buttonList={[goBackButton( {setSection: (route)=>history.push(route),sectionName: "/stockreport"})]}/>
                     <StockReportParams setSection={setCurrentSection}/>
              </Fragment>
                );

            case "stockReportList":
                return(
                    <Fragment>
                        <PageTitle imgSrc={StatementIcon} pageTitle={"Item Report List"}
                            buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"stockReportParams"}), downloadButton]}
                            />
                            <StockReportList />
                    </Fragment>
                )

            default:
                <>Page Not Found</>
        }
    }

  return (
    <Fragment>
        <div className="completePage">
            <MenuList selectedMenu={"Reports"} selectedSubMenu={"Stock Report"}/>
            <div className="detailsContainer">
                <TopBanner />
                {renderSection(currentSection)}
            </div>
        </div>
    </Fragment>
  )
}

export default StockReport