import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import { viewSoPendingId } from "../../redux/Store/StoreInfoActions";
import createProdIcon from "../../assets/sheets.svg";
import { pendingSoProduction } from "../../redux/PurchaseOrder/PoInfoActions";
import FormElement from "../SmallComponents/FormElement";
import { handleSort } from "../CommonFunctions/utils";
import Pagination from '../CommonFunctions/pagination';
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";

const SoPendingList = ({
    // props
    setSection,

    // state
    isLogged,
    poInfo,

    // dispatch
    addTokenToState,
    viewSoPendingId,
    pendingSoProduction,
}) => {
    const [loading, setLoading] = useState(true);
    const [soApprovedList, setSoApprovedList] = useState([]);
    const [noApprovedRecords, setNoApprovedRecords] = useState(false);
    const [plantId,setPlantId] = useState('');
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const [customerOptions,setCustomerOptions]=useState([]);
    const [customerId,setCustomerId]=useState('');
    const soApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return soApprovedList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,soApprovedList,PageSize]);
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, [plantId,customerId]);

    async function getInformation() {
        await checkToken();
        await getCustomers();
        await getSoApproved();
    }

    const history = useHistory();
    async function checkToken() {
 
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
    
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
    async function getCustomers() {
        var data = await fetchData({
            requestingPage: "getParents",
            method: "get",
            url: "fetch/customer-list",
            headers: { token: isLogged.accessToken ,module:"Customer"},
        });
        // console.log(data);
        if (data.msg === "success") {
            var options = [];
            data.asset.forEach((parent) => {
                var newParent = { optionId: parent.customerId, optionName: parent.customer };
                options.push(newParent);
            });
            setCustomerOptions(options);
        }
    }
    async function getSoApproved() {
        var data = await fetchData({
            requestingPage: "soList",
            method: "post",
            url: "so-fetch/prod-pre-create-so-approved-list",
            headers: { token: isLogged.accessToken,module:"Pending SO" },
            data:{plantId,customerId}
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;

                if (element.outwardStatus !== "Sent") {
                    if (element.outwardStatus === "Not Sent") {
                        data2.push(newElement);
                    } else {
                        var newLineItems = [];
                        newElement.lineItems.forEach((item) => {
                            if (parseInt(item.quantity) - parseInt(item.outwardQuantity) <= 0) {
                            } else {
                               // console.log("Add line Item");
                                newLineItems.push(item);
                            }
                        });
                        if (newLineItems.length > 0) {
                            element.lineItems = newLineItems;
                            data2.push(element);
                        }
                    }
                }
            });
            setSoApprovedList(data2);
            if (data2.length > 0) setNoApprovedRecords(false);
            else setNoApprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editItemCollapse = (tab, index) => {
        var listCopy = soApprovedList.slice();

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });
        setSoApprovedList(listCopy);
    };
 const access = isLogged.access["Production Order"];
    const renderSoApprovedList = () => {
        return soApprovedCurrentPage.map((item, i) => {
            var outwardColor = "";
            switch (item.outwardStatus) {
                case "Sent":
                    outwardColor = "green";
                    break;

                case "Partially Sent":
                    outwardColor = "orange";
                    break;

                case "Not Sent":
                    outwardColor = "red";
                    break;
            }

            var invoiceColor = "";
            switch (item.invoiceStatus) {
                case "Invoiced":
                    invoiceColor = "green";
                    break;

                case "Not Invoiced":
                    invoiceColor = "red";
                    break;
            }
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.soId}
                        onClick={() => editItemCollapse("soApprovedList", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.soNo}</td>
                        <td className="vendorListInfo">{item.soDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                        <td className="vendorListInfo">{item.soStatus}</td>
                        <td className="vendorListInfo" style={{ color: outwardColor }}>
                            {item.outwardStatus}
                        </td>
                        <td className="vendorListInfo" style={{ color: invoiceColor }}>
                            {item.invoiceStatus}
                        </td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.editedBy !== null ? item.editedBy : "-"}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Create Production Request"
                                className="vendorActionImages"
                                title={access === "Create" || access === "Edit" || access === "App/Dec" ? "Create Production Request" : "No Access For Production"}
                                src={createProdIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access === "Create"|| access==="Edit" || access === "App/Dec")
                                    {pendingSoProduction(item.soId);
                                    history.push("/production");}
                                }}
                                style= {access === "Create" || access === "Edit" || access === "App/Dec" ? {cursor: "pointer"} : {cursor: "not-allowed"}}
                            />

                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Pending SO"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewSoPendingId(item.soId);
                                    setSection("soPendingDetail");
                                }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("soApprovedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approvedSo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={"11"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                           { isLogged.showHideWeight ?
                            <>
                           <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td></> : ''}
                            <td className="vendorListInnerTableHeading">HSN Code</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Order Qty</td>
                            <td className="vendorListInnerTableHeading">Outward Qty</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.soLineItemId}>
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    {isLogged.showHideWeight ?
                                    <>
                                    <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td></>:''}
                                    <td className="vendorListInnertableInfo">{row.hsnCode !== null ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                    <td className="vendorListInnertableInfo">{row.outwardQuantity !== null ? row.outwardQuantity : "-"}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };
    const [order, setOrder] = useState({ col: "soNo", state: true, data: "" });
    const renderPendingSoTable=()=>{
        return(
            <table className="vendorListTable">
                    <thead>
                    <tr className="vendorListHeader" style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                    SO No
                    <img
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                    <img
                      className="sortArrow"
                      src={
                        order.col === "soDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name
                    <img
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "expectedDeliveryDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "expectedDeliveryDate",
                        state: order.state,
                      });
                    }}>
                    Exp Delivery Date
                    <img
                      className="sortArrow"
                      src={
                        order.col === "expectedDeliveryDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status{" "}
                    <img
                      className="sortArrow"
                      src={
                        order.col === "soStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "outwardStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "outwardStatus",
                        state: order.state,
                      });
                    }}>
                    Outward Status{" "}
                    <img
                      className="sortArrow"
                      src={
                        order.col === "outwardStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>
                    Invoice Status{" "}
                    <img
                      className="sortArrow"
                      src={
                        order.col === "invoiceStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                    <img
                      className="sortArrow"
                      src={
                        order.col === "createdBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "editedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "editedBy",
                        state: order.state,
                      });
                    }}>
                    Modified By
                    <img
                      className="sortArrow"
                      src={
                        order.col === "editedBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "approvedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "approvedBy",
                        state: order.state,
                      });
                    }}>
                    Approved By
                    <img
                      className="sortArrow"
                      src={
                        order.col === "approvedBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
                    </thead>
                    <tbody>{soApprovedList.length > 0 ? renderSoApprovedList() : null}</tbody>
                </table>
        )
    }
    return (
        <React.Fragment>
            <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                <span className="listPageParams">
                    <FormElement setInput={(value) => { setPlantId(value); }}
                        inputType={"options"}
                        value={plantId}
                        hintText={"Plant Name"}
                        options={isLogged.plantOptions}
                        colSpan={1}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false} />
                </span>
                <span className="listPageParams">
                    <FormElement
                        setInput={(value) => {
                            setCustomerId(value);
                        }}
                        inputType={"options"}
                        options={customerOptions}
                        value={customerId}
                        hintText={"Customer Name"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
                <span style={{ flexGrow: 0, width: "80px" }}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
            </div>
            <div className="vendorListArea" style={{maxHeight:"450px"}}>
                <React.Fragment>
                {renderPendingSoTable()}
                </React.Fragment>
                {noApprovedRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={soApprovedList.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewSoPendingId: (soId) => dispatch(viewSoPendingId(soId)),
        pendingSoProduction: (soId) => dispatch(pendingSoProduction(soId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoPendingList);
