import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const EditPurchaseInvoice = ({ isLogged, poInfo, setSection, editApprove }) => {
    const [poDetail, setPoDetail] = useState({});
    const [showCustomerDetail, setShowCustomerDetail] = useState(false);
    const [lineItems, setLineItems] = useState([]);

    const [otherCharges, setOtherCharges] = useState("");
    const [transportCharges, settransportCharges] = useState("");
    const [transportChargesError, setTransportChargesError] = useState(false);
    const [otherChargesError, setOtherChargesError] = useState(false);

    const [netTotal, setNetTotal] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    const [instruction, setInstruction] = useState("");

    const [poInvoiceDocument, setInvoiceDocument] = useState("");

    const [invoiceDetail, setInvoiceDetail] = useState({});

    const purchaseOrderParamList = [["orderNo","invoiceDate"], ["eWayBillNo","invoiceNo"]];

    const [purchaseOrderParams, setPurchaseOrderParams] = useState({
        orderNo: {
            inputType: "text",
            value: "",
            hintText: "Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select order no",
        },
        invoiceDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Invoice Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please Select invoice date",
            maxdate: true,
        },

        eWayBillNo: {
            inputType: "text",
            value: "",
            hintText: "E-Way Bill No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Select Office Name",
        },
        invoiceNo :{
            inputType: "text",
            value: "",
            hintText: "Invoice No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please Enter a Invoice No",
       }
    });


    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        setIsOpen(true);
        setModalText("Getting Invoice information....");
        var paramCopy = Object.assign(purchaseOrderParams);

        var detail = await getPoDetail(poInfo.poInvoiceCreateId);
        var invoiceDetailCopy = await getInvoiceDetail();

        var lineItemsCopy = [];
        detail.lineItems.forEach((item) => {

            var invoiceLineItemCreated = invoiceDetailCopy.lineItems.filter((line) => line.poLineItemId === item.poLineItemId)[0];

            var newObj = Object.assign(item);
            newObj.quantityPo = item.orderQuantity;
            newObj.unitPricePo = item.unitPrice;

            newObj.quantity = "";
            newObj.netPrice = 0;
            newObj.invoiceLineItemId = null;

            if ((invoiceLineItemCreated !== null) & (invoiceLineItemCreated !== undefined)) {
                newObj.quantity = parseInt(invoiceLineItemCreated.invoiceQuantity);
                newObj.netPrice = parseFloat(invoiceLineItemCreated.netPrice);
                newObj.invoiceLineItemId = invoiceLineItemCreated.invoiceLineItemId;
            }

            newObj.quantityError = false;
            newObj.quantityErrorMessage = "Please Enter a Valid Quantity";

            newObj.toSend = false;

            lineItemsCopy.push(newObj);
        });

        setPoDetail(detail);
        setShowCustomerDetail(true);
        setNetTotal(invoiceDetailCopy.netTotal);
        setOtherCharges(detail.otherCharges);
        settransportCharges(detail.transportCharges);
        setTotalAmount(invoiceDetailCopy.totalAmount);
        setLineItems(lineItemsCopy);
        setInvoiceDetail(invoiceDetailCopy);

        paramCopy = update(paramCopy, {
            orderNo: { value: { $set: detail.poNo }, disabled: { $set: true } },
            invoiceDate: { value: { $set: invoiceDetailCopy.invoiceDate } },
            eWayBillNo: { value: { $set: invoiceDetailCopy.eWayBillNo !== null ? invoiceDetailCopy.eWayBillNo : "" } },
            invoiceNo: { value: { $set: invoiceDetailCopy.invoiceNo !== null ? invoiceDetailCopy.invoiceNo : "" } },

        });

        setPurchaseOrderParams(paramCopy);

        if (invoiceDetailCopy.invoice_document !== null) {
            setInvoiceDocument({ name: invoiceDetailCopy.invoice_document });
        }

        setIsOpen(false);
        setModalText("Submitting Invoice. Please wait...");
    }

    async function getPoDetail(id) {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: `po-fetch/invoice-pre-create/${id}`,
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }
    }

    async function getInvoiceDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "po-fetch/invoice/" + poInfo.poEditInvoiceId,
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        } else {
            console.log(data);
        }
    }

    const updateInvoiceParameter = ({ paramName, section, key, value }) => {
        if (section === "general") {
            var purchaseParamsCopy = Object.assign(purchaseOrderParams);
            purchaseParamsCopy = update(purchaseParamsCopy, { [paramName]: { [key]: { $set: value } } });

            setPurchaseOrderParams(purchaseParamsCopy);
        }
    };

    const updateLineItems = (param, index, value) => {
        var updatedList = lineItems.slice();

        updatedList = update(updatedList, { [index]: { [param]: { $set: value } } });

        var netRate = 0;
     
        if (!isNaN(updatedList[index].quantity) && !isNaN(updatedList[index].unitPrice)) {

            netRate =
                parseFloat(updatedList[index].quantity) * parseFloat(updatedList[index].unitPrice) +
                (parseFloat(updatedList[index].tax) / 100) * (parseFloat(updatedList[index].quantity) * parseFloat(updatedList[index].unitPrice));

        }

        if (!isNaN(netRate)) {
            updatedList = update(updatedList, { [index]: { netPrice: { $set: netRate } } });
        } else {
            updatedList = update(updatedList, { [index]: { netPrice: { $set: 0 } } });
        }

        var newNetTotal = 0;
        updatedList.forEach((item) => {
            if (!isNaN(item.netPrice)) {
                newNetTotal = parseFloat(newNetTotal) + parseFloat(item.netPrice);
            } else {
                newNetTotal = 0;
            }
        });

        newNetTotal = newNetTotal.toFixed(2);

        var newTotalAmount = parseFloat(newNetTotal) + parseFloat(transportCharges) + parseFloat(otherCharges);

        setLineItems(updatedList);
        setNetTotal(newNetTotal);
        setTotalAmount(newTotalAmount);
    };

    useEffect(() => {
   
        var total = parseFloat(netTotal) + parseFloat(otherCharges) + parseFloat(transportCharges);
        setTotalAmount(total);
    }, [otherCharges, transportCharges]);

    const [errorList, setErrorList] = useState([]);

    const checkErrors = (submitType) => {
        var errList = [];
        var paramsCopy = Object.assign(purchaseOrderParams);

        purchaseOrderParamList[0].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errList.push(paramsCopy[item].errorMessage);
            }
        });

        purchaseOrderParamList[1].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errList.push(paramsCopy[item].errorMessage);
            }
        });

        if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
            errList.push("Enter a valid number for Other Charges");
            setOtherChargesError(true);
        } else {
            setOtherChargesError(false);
        }

        if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
            errList.push("Enter a valid number for Transport Charges");
            setTransportChargesError(true);
        } else {
            setTransportChargesError(false);
        }

        var proceed = false;
        var lineItemsCopy = lineItems.slice();
        lineItemsCopy.forEach((item, i) => {
            if (item.quantity < 0 || item.quantity > item.quantityPo) {
                lineItemsCopy[i].quantityError = true;
                errList.push(lineItemsCopy[i].quantityErrorMessage);
            } else {
                lineItemsCopy[i].quantityError = false;
                item.quantity = parseInt(item.quantity);
            }

            if (item.netPrice > 0) {
                lineItemsCopy[i].toSend = true;
                proceed = true;
            } else {
            }
        });

        setLineItems(lineItemsCopy);
        setPurchaseOrderParams(paramsCopy);
        setErrorList(errList);

        if (errList.length === 0) {
            if (proceed) {
              
                prepareData(lineItemsCopy, paramsCopy, submitType);
            } else {
                setDataSubmitted(true);
                setModalText("Provide quantity information for alteast one item");
                setErrorList(["Provide quantity information for alteast one item"]);
                setIsOpen(true);
            }
        }
    };

    const prepareData = (lineItemsCopy, paramsCopy, submitType) => {
        var data2Server = new FormData();

        data2Server.append("invoiceId", invoiceDetail.invoiceId);
        data2Server.append("invoiceDate", paramsCopy.invoiceDate.value);
        data2Server.append("eWayBillNo", paramsCopy.eWayBillNo.value);
        data2Server.append("invoiceNo", paramsCopy.invoiceNo.value);

        data2Server.append("netTotal", netTotal);
        data2Server.append("transportCharges", transportCharges || 0);
        data2Server.append("otherCharges", otherCharges ||0);
        data2Server.append("totalAmount", totalAmount);
        data2Server.append("instruction", instruction);

        if (poInvoiceDocument !== "" && poInvoiceDocument?.size > 0) {
            data2Server.append("poInvoiceDocument", poInvoiceDocument);
        }

        var lineItemsToServer = [];

        var lineItemsCopy2 = JSON.parse(JSON.stringify(lineItemsCopy));

        lineItemsCopy2.forEach((item) => {
            if (item.toSend) {
                delete item.quantityPo;
                delete item.quantityError;
                delete item.quantityErrorMessage;
                delete item.hsnCode;
                delete item.itemName;
                delete item.soId;
                delete item.toSend;
                delete item.uom;

                lineItemsToServer.push(item);
            }
        });
        data2Server.append("invoiceLineItems", JSON.stringify(lineItemsToServer));
        submitData(data2Server);
    };

    async function submitData(data) {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "put",
            url: "po-edit/invoice",
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
            data: data,
        });

        if (result.msg === "success") {
            if (editApprove) {
                setModalText("Edit Successful. Approving Invoice...");
                approveInvoice();
            } else {
                setModalText("Invoice Edited Successfully!");
                setDataSubmitted(true);
            }
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setErrorList([result.desc]);
            setDataSubmitted(true);
        }
    }

    async function approveInvoice() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `po-edit/invoice-approve/${invoiceDetail.invoiceId}`,
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Invoice");
        } else {
            setModalText(data.desc);
            setErrorList([data.desc]);
        }
    }

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value}
                    setInput={(value) => {
                        updateInvoiceParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const RenderCustomerDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">{poDetail.vendorName}</div>
                <div className="detailText">
                    {`Vendor ID: ${poDetail.vendorId}`},
                    <br />{poDetail.vendorAddress},
                    <br /> {poDetail.vendorCity}
                    {poDetail.vendorPinCode !== null ? ` - ${poDetail.vendorPinCode}` : null}
                    {poDetail.vendorMobile !== null || poDetail.vendorPhone !== null ? <br /> : null}
                    {poDetail.vendorMobile !== null || poDetail.vendorPhone !== null ? `Phone: ` : null}
                    {poDetail.vendorMobile !== null ? poDetail.vendorMobile : null}
                    {poDetail.vendorPhone !== null ? `, ${poDetail.vendorPhone} ` : null}
                    {poDetail.vendorEmail !== null ? <br /> : null}
                    {poDetail.vendorEmail !== null ? `Email Id: ${poDetail.vendorEmail}` : null}
                    {poDetail.vendorGstNo !== null ? <br /> : null}
                    {poDetail.vendorGstNo !== null ? `GST No:  ${poDetail.vendorGstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const renderErrorMessage = () => {
        if (errorList.length > 0) {
            return errorList[0];
        } else return null;
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorList.length === 0) {
            setSection("purchaseInvoiceList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Submitting Invoice. Please wait..");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1200px",
                        margin: "0 auto 4rem",
                        padding: "3rem 3rem 1rem 3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "2" }}>
                            <div className="createSalesInvoiceGrid">
                                {renderFormElements({ elementList: purchaseOrderParamList[0], param: purchaseOrderParams, section: "general" })}
                            </div>

                            <div className="createSalesInvoiceInfo">
                                <div className="salesInvoiceOurAddress">
                                    <div className="vendorSummaryGrid">
                                        {purchaseOrderParams.orderNo.value !== "" ? (
                                            <React.Fragment>
                                                <span className="vendorSummaryCategory">Order Date</span>
                                                <span className="vendorSummaryValue">: {poDetail.poDate}</span>

                                                <span className="vendorSummaryCategory">Store Details</span>
                                                <span className="vendorSummaryValue">
                                                    :{" "}
                                                    {poDetail.storageName +
                                                        ", " +
                                                        poDetail.storageAddress +
                                                        ", " +
                                                        poDetail.plantName +
                                                        ", " +
                                                        poDetail.plantCity +
                                                        ", " +
                                                        (poDetail.plantZipCode !== null ? ` - ${poDetail.plantZipCode}. ` : ".")}
                                                </span>

                                                <span className="vendorSummaryCategory">Transporter Name</span>
                                                <span className="vendorSummaryValue">
                                                    : {poDetail.transporterName !== null ? poDetail.transporterName : "NA"}
                                                </span>
                                            </React.Fragment>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="salesInvoiceCompanyAddress">{showCustomerDetail ? <RenderCustomerDetail /> : null}</div>
                    </div>

                    <div style={{ display: "flex" }}>
                        <div className="createSalesInvoiceGrid">
                            {renderFormElements({ elementList: purchaseOrderParamList[1], param: purchaseOrderParams, section: "general" })}
                        </div>
                    </div>

                    {lineItems.length > 0 ? (
                        <table className="createItemPurchaseTable">
                            <thead>
                                <tr className="createVendorContactsTableHeader">
                                    <td>Item </td>
                                    <td>Weight From(gms)</td>
                                    <td>Weight To(gms)</td>
                                    <td>Net Weight(gms)</td>
                                    <td>Gross Weight(gms)</td>
                                    <td>HSN No</td>
                                    <td>UOM</td>
                                    <td>PO Qty</td>
                                    <td>Accepted Qty</td>
                                    <td>Invoice Qty</td>
                                    <td>Unit Price</td>
                                    <td>Tax (%)</td>
                                    <td>Net Price</td>
                                </tr>
                            </thead>
                            <tbody>
                                {lineItems.map((row, j) => (
                                    <tr className="createVendorContactsTableRows" key={j}>
                                        {[
                                            "itemName",
                                            "from",
                                            "to",
                                            "netWeight",
                                            "grossWeight",
                                            "hsnCode",
                                            "uom",
                                            "orderQuantity",
                                            "acceptedQuantity",
                                            "quantity",
                                            "unitPrice",
                                            "tax",
                                            "netPrice",
                                        ].map((key, i) => {
                                            switch (key) {
                                                case "quantity":
                                                    return (
                                                        <td key={i} style={{ width: "0px" }}>
                                                            <input
                                                                className={
                                                                    lineItems[j].quantityError
                                                                        ? "createPurchaseCostInputError"
                                                                        : "createPurchaseCostInput"
                                                                }
                                                                type="text"
                                                                value={row[key]}
                                                                onChange={(e) => updateLineItems(key, j, e.target.value)}
                                                            />
                                                        </td>
                                                    );

                                                case "netPrice":
                                                    return <td key={i}>{row[key] === 0 ? "-" : row[key]}</td>;

                                                default:
                                                    return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{lineItems.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>Invoice Instruction</div>
                            <textarea
                                className="createInvoiceMultilineInput"
                                value={instruction}
                                rows="3"
                                cols="30"
                                name="text"
                                placeholder="Enter instructions"
                                style={{
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setInstruction(e.target.value);
                                }}
                            />
                            <div className="createSalesInvoiceGrid2">
                                {poInvoiceDocument !== "" ? (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${9}` }}>
                                        <FormHint hintText="Invoice Document" mandatory={false} />
                                        <div className="inputDone">
                                            <span
                                                style={{
                                                    flex: 1,
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    // maxWidth: "168px",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                ✅ {poInvoiceDocument.name}
                                            </span>
                                            <span
                                                style={{ paddingRight: "10px", cursor: "pointer", "&:hover": { backgroundColor: "gray" } }}
                                                onClick={() => setInvoiceDocument("")}
                                                title="Remove and Re-upload Invoice Document"
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <FormElement
                                        colSpan={9}
                                        inputType="upload"
                                        hintText="Invoice Document"
                                        setInput={(file) => {
                                            setInvoiceDocument(file);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="poSpacer">&nbsp;</div>

                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{netTotal}</span>
                            </div>

                            <div className="poFormElement">
                                <div className="formElement">
                                    <FormHint hintText="Other Charges" mandatory={false} />
                                    <InputField
                                        type="float"
                                        value={otherCharges}
                                        setName={(value) => setOtherCharges(value)}
                                        align="end"
                                        error={otherChargesError}
                                    />
                                </div>
                                <div className="formElement">
                                    <FormHint hintText="Transport Charges" mandatory={false} />

                                    <InputField
                                        type="float"
                                        value={transportCharges}
                                        setName={(value) => settransportCharges(value)}
                                        align="end"
                                        error={transportChargesError}
                                    />
                                </div>
                            </div>

                            <div className="poTotalTitle" style={{ margin: "3rem 0 0 0" }}>
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <div className="poNetTotalAmount">{totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setErrorList([]);
                            checkErrors("Approval Pending");
                        }}
                    >
                        {editApprove ? "Edit & Approve" : " Submit for Approval"}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPurchaseInvoice);
