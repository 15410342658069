import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { editMaterialRequisitionId } from "../../redux/PurchaseOrder/PoInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";


const MaterialRequisitionDetail = ({ approval, setSection, setEditAndApprove, isLogged, poInfo, addTokenToState, editMaterialRequisitionId }) => {
    const [materialRequisitionDetail, setMaterialRequisitionDetail] = useState({});
    const [lineItems, setLineItems] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
       await getMaterialRequisitionDetail();
    }

    const history = useHistory();

    async function checkToken() {
      
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getMaterialRequisitionDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "mrs-fetch/request-detail/" + poInfo.viewMaterialRequisitionId,
            headers: { token: isLogged.accessToken,module: "Material Requisition" },
        });
        if (data.msg === "success") {
            setMaterialRequisitionDetail(data.asset[0]);
            setLineItems(data.asset[0].lineItems);
        } else {
            console.log("Error in Fetching data");
        }
    }

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>UOM</td>
                        <td>Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItems} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "itemCode","from","to","netWeight","grossWeight", "uom", "quantity"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const renderSummaryInfo = () => {
        if (materialRequisitionDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {materialRequisitionDetail.requestNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                materialRequisitionDetail.returnStatus === "Declined"
                                    ? { color: "red" }
                                    : materialRequisitionDetail.returnStatus === "Approved"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }
                        >
                            ( {materialRequisitionDetail.returnStatus} )
                        </span>
                        </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">MRS No</span>
                        <span className="vendorSummaryValue">: {materialRequisitionDetail.requestNo}</span>

                        {
                            <React.Fragment>
                                <span className="vendorSummaryCategory">Order Date</span>
                                <span className="vendorSummaryValue">: {materialRequisitionDetail.orderDate}</span>
                            </React.Fragment>
                        }

                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {materialRequisitionDetail.expectDate}</span>
                    </div>

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>
                    <div className="mrsNetTotalSurchargeDiv">
                        <div className="MRSInstructions">
                            <b> MRS Instructions</b> : {materialRequisitionDetail.instruction !== "" ? materialRequisitionDetail.instruction : "NA"}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    async function approveMaterialRequisition() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approveMrs",
            method: "put",
            url: `mrs-edit/request-approve/${materialRequisitionDetail.requestId}`,
            headers: { token: isLogged.accessToken,module: "Material Requisition" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Material Requisition Request");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editMaterialRequisition");
                            editMaterialRequisitionId(materialRequisitionDetail.requestId);
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            approveMaterialRequisition();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("materialRequisitionList");
        }
        setModalText("Approving Mrs, Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Material Requisition Request. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="MrsSummaryText">{renderSummaryInfo()}</div>
                    </div>
                </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );

};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editMaterialRequisitionId: (materialRequisitionId) => dispatch(editMaterialRequisitionId(materialRequisitionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialRequisitionDetail);
