import React,{useState} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import PageTitle from 'components/SmallComponents/PageTitle';
import MenuList from 'components/Menu/MenuList';
import TopBanner from 'components/SmallComponents/TopBanner';
import premiumChargesIcon from "assets/section-master-black.svg";
import CreatePremiumCharges from "./createPremiumCharges";
import EditPremiumCharges from "./editPremiumCharges";
import PremiumChargesList from "./premiumChargesList";


export const PremiumCharges = ({isLogged}) => {
  const [currentSection, setCurrentSection] = useState("premiumChargesList");

  var history = useHistory();

  const pushBack = (section) => {
      history.push(section);
  };

  var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "premiumChargesList" }];

  var goBackToItemButton = {
      buttonName: "Back",
      className: "goBackButton",
      setSection: pushBack,
      sectionName: "/itemMenu",
  };

  var createPremiumChargesButton = {
      buttonName: "Create Premium Charges",
      className: "employeeButton",
      setSection: setCurrentSection,
      sectionName: "createPremiumCharges",
  };

  const renderSection = (section) => {
      switch (section) {
          case "premiumChargesList":
              return (
                  <React.Fragment>
                      <PageTitle
                          imgSrc={premiumChargesIcon}
                          pageTitle="Premium Charges"
                          buttonList={
                              isLogged.access["Premium Charges"] === "Create" || isLogged.access["Premium Charges"] === "Edit"
                                  ? [createPremiumChargesButton, goBackToItemButton]
                                  : [goBackToItemButton]
                          }
                      />
                      <PremiumChargesList setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          case "createPremiumCharges":
              return (
                  <React.Fragment>
                      <PageTitle imgSrc={premiumChargesIcon} pageTitle="Create Premium Charges" buttonList={goBackButton} />
                      <CreatePremiumCharges setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          case "editPremiumCharges":
              return (
                  <React.Fragment>
                      <PageTitle imgSrc={premiumChargesIcon} pageTitle="Edit Premium Charges" buttonList={goBackButton} />
                      <EditPremiumCharges setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          default:
              return <h1>Page not yet created</h1>;
      }
  };

  return (
      <React.Fragment>
          <div className="completePage">
              <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
              <div className="detailsContainer">
                  <TopBanner />
                  {renderSection(currentSection)}
              </div>
          </div>
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLogged : state.isLogged
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumCharges)