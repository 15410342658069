import update from "immutability-helper";

const storeObj = {
    storeTab: "opdList",
    viewOpdId: null,
    editOpdId: null,

    inwardTab: "inwardList",
    viewInwardId: null,
    editInwardId: null,

    outwardTab: "outwardList",
    viewOutwardId: null,
    editOutwardId: null,

    qcTab: "inwardList",
    viewQcId: null,
    editQcId: null,

    transferTab: "transferList",
    viewTransferId: null,
    editTransferId: null,

    viewSoPendingId: null,
    viewIdnPendingId: null,

    viewIdnId : null,
    editIdnId : null,

    mrsForIdn: null,

    customerLedger: null,
    outStandingParams: null,

    packingSlipId: null,
    otherChargesId: null,
    loadingSlipId :null,
    salesOpportunityId :null,
    salesQuoteId :null,
    salesLeadId : null

};

const StoreInfo = (state = storeObj, action) => {
    switch (action.type) {
        // ======================= OPD =======================
        case "SET_OPD_TAB":
            return update(state, { storeTab: { $set: action.payload.opdTab } });

        case "VIEW_OPD_ID":
            return update(state, { viewOpdId: { $set: action.payload.opdId } });

        case "EDIT_OPD_ID":
            return update(state, { editOpdId: { $set: action.payload.opdId } });

        // ======================= Inward =======================
        case "SET_INWARD_TAB":
            return update(state, { inwardTab: { $set: action.payload.inwardTab } });

        case "VIEW_INWARD_ID":
            return update(state, { viewInwardId: { $set: action.payload.viewInwardId } });

        case "EDIT_INWARD_ID":
            return update(state, { editInwardId: { $set: action.payload.editInwardId } });

        // ======================= Outward =======================

        case "SET_OUTWARD_TAB":
            return update(state, { outwardTab: { $set: action.payload.outwardTab } });

        case "VIEW_OUTWARD_ID":
            return update(state, { viewOutwardId: { $set: action.payload.outwardId } });

        case "EDIT_OUTWARD_ID":
            return update(state, { editOutwardId: { $set: action.payload.outwardId } });

        // ======================= QC =======================
        case "SET_QC_TAB":
            return update(state, { qcTab: { $set: action.payload.qcTab } });

        case "VIEW_QC_ID":
            return update(state, { viewQcId: { $set: action.payload.qcId } });

        case "EDIT_QC_ID":
            return update(state, { editQcId: { $set: action.payload.qcId } });

        // ======================= Stock Transfer =======================
        case "SET_TRANSFER_TAB":
            return update(state, { transferTab: { $set: action.payload.transferTab } });

        case "VIEW_TRANSFER_ID":
            return update(state, { viewTransferId: { $set: action.payload.transferId } });

        case "EDIT_TRANSFER_ID":
            return update(state, { editTransferId: { $set: action.payload.transferId } });

        // ======================= SO Pending =======================
        case "VIEW_SO_PENDING_ID":
            return update(state, { viewSoPendingId: { $set: action.payload.viewSoPendingId } });
        
        //======================== IDN Pending =======================
        case "VIEW_IDN_PENDING_ID":
            return update(state,{ viewIdnPendingId: { $set: action.payload.viewIdnPendingId } });
        //======================== Pending MRS============================================
        case "PENDING_IDN":
            return update(state, { mrsForIdn: { $set: action.payload.mrsForIdn } });
        
        //======================== IDN =============================
        case "VIEW_IDN_ID":
            return update(state,{ viewIdnId: { $set: action.payload.viewIdnId } });
            
        case "EDIT_IDN_ID":
            return update(state,{ editIdnId: { $set: action.payload.editIdnId } });

        case "CUSTOMER_LEDGER_PARAMS":
            return update(state,{ customerLedger: {$set: action.payload.customerLedgerParams} });

        case "OUTSTANDING_STATEMENT_PARAMS":
            return update(state,{outStandingParams: {$set: action.payload.outStandingParams} });

        case "PACKING_SLIP_ID":
            return update(state,{packingSlipId: {$set: action.payload.packingSlipId}});

        case "OTHER_CHARGES_ID":
            return update(state,{otherChargesId: {$set: action.payload.otherChargesId}});

        case "LOADING_SLIP_ID":
            return update(state,{loadingSlipId: {$set: action.payload.loadingSlipId}});

        case "SALES_OPPORTUNITY_ID":
            return update(state,{salesOpportunityId: {$set: action.payload.salesOpportunityId}});

        case "SALES_QUOTE_ID":
            return update(state,{salesQuoteId : {$set: action.payload.salesQuoteId}});

        case "SALES_LEAD_ID":
            return update(state,{salesLeadId : {$set: action.payload.salesLeadId}});

        default:
            return state;
    }
};

export default StoreInfo;
