import React,{useEffect, useState} from "react";
import BOMIcon from '../../assets/bill-of-materials.svg';
import PageTitle from "../SmallComponents/PageTitle";
import MenuList from "../Menu/MenuList";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import CreateBOM from "./createBOM";
import BomListPage from "./BOMListPage";
import EditBom from "./editBOM";
import { bomShortCut } from "../../redux/UserAccount/IsLoggedActions";
import BOMDetail from "./BOMDetail";

const BillOfMaterial = ({ isLogged,bomShortCut }) => {
    const [currentSection, setCurrentSection] = useState("billOfMaterialList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);
    const access = isLogged.access["Bill Of Material"];
    var goBackButton = { buttonName: "Back",className: "goBackButton",
                        setSection: setCurrentSection,sectionName: "billOfMaterialList",callback: ()=>{bomShortCut(null)}};
    var createBOM = {buttonName: "Create BOM",className: "employeeButton",
                            setSection: setCurrentSection,sectionName: "createBOM",};
    useEffect(()=>{
        if(isLogged.itemIdForBomShortcut)
        setCurrentSection("createBOM")
        else{
            setCurrentSection("billOfMaterialList")
        }
    },[])
    const renderSection=(section)=>{
        switch(section){
            case "billOfMaterialList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={BOMIcon} pageTitle="Bill Of Material" 
                        buttonList={access==="Create" || access === "Edit" || access === "App/Dec" ? [createBOM] : null} />
                        <BomListPage setSection={(value)=>setCurrentSection(value)}/>
                    </React.Fragment>
                );
            
            case "createBOM":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={BOMIcon} pageTitle="Create BOM" buttonList={[goBackButton]}/>
                        <CreateBOM setSection={(value)=>setCurrentSection(value)}/>
                    </React.Fragment>
                );

            case "editBOM":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={BOMIcon} pageTitle="Edit Bill Of Material" buttonList={[goBackButton]}/>
                        <EditBom setSection={(value)=>setCurrentSection(value)}/>
                    </React.Fragment>
                );
                
            case "bomDetail":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={BOMIcon} pageTitle="Bill of Material Detail" buttonList={[goBackButton]}/>
                        <BOMDetail />
                    </React.Fragment>
                )
            default: 
                    return(<div>Page Yet To be Built</div>);
        }
    }
    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Bill of Material" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps=(dispatch)=>{
    return{
        bomShortCut: (itemId)=>dispatch(bomShortCut(itemId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BillOfMaterial);
