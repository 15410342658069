import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import view from "../../assets/ProfilePlaceholder.svg";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import Modal from "react-modal";
import imageNotAvailable from "../../assets/imageNotAvailable.svg";

const EmployeeDetail = ({
    // props
    setSection,

    // state
    isLogged,
    employeeInfo,

    // dispatch
    addTokenToState,
}) => {
    const [varList, setVarList] = useState([]);
    const [profileImage, setProfileImage] = useState();
    const [idProofImage, setIdProofImage] = useState();

    useEffect(() => {
       
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getEmployeeDetail();
    }

    const history = useHistory();

    async function checkToken() {
        

        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getEmployeeDetail() {
        var data = await fetchData({
            requestingPage: "employeeDetail",
            method: "get",
            url: "fetch/employee/" + employeeInfo.viewEmployeeId,
            headers: { token: isLogged.accessToken,module:"Employee" },
        });
        if (data.msg === "success") {
            setVarList(data.asset[0]);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        getProfileImage();
        // eslint-disable-next-line
    }, [varList]);

    async function getProfileImage() {
        var data = await fetchData({
            requestingPage: "employeeImage",
            method: "get",
            url: "image/emp-photo/" + varList.employee_id,
            headers: { token: isLogged.accessToken,module:"Employee" },
        });
        if (data === "") {
            setProfileImage(view);
        } else setProfileImage(data);
    }

    async function getIdProofImage(employee_no) {
        var data = await fetchData({
            requestingPage: "employeeImage",
            headers: { token: isLogged.accessToken,module:"Employee" },
            url: `image/emp-id-proof/${employee_no}`,
        });
        if (data === "") {
            // setProfileImage(view);
        } else {
            setIdProofImage(data);
            setIsOpen(true);
        }
    }

    const varGrouping = [
        // [{ cardTitle: "Emergency Contact", cardDetails: [{ displayName: "Employee Status", key: "emp_status" }] }],
        // [
        //     {
        //         cardTitle: "Emergency Contact",
        //         cardDetails: [
        //             { displayName: "Employee Name", key: "name" },
        //             { displayName: "Role", key: "role" },
        //             { displayName: "Employee ID", key: "employee_no" },
        //             { displayName: "Mobile Number", key: "mobile" },
        //             { displayName: "Alternate Mobile Number", key: "alternate_mobile" },
        //             { displayName: "Email Id", key: "email" },
        //             { displayName: "Blood Group", key: "blood_group" },
        //         ],
        //     },
        // ],

        {
            cardTitle: "Emergency Contact",
            cardDetails: [
                { displayName: "Mobile No", key: "emergency_contact_number" },
                { displayName: "Name", key: "emergency_contact_name" },
                { displayName: "Relationship Type", key: "relationship_type" },
                { displayName: "Address", key: "address", key2: "city", key3: "state", key4: "country", key5: "pin_code" },
            ],
        },

        {
            cardTitle: "Other Details",
            cardDetails: [
                { displayName: "Wages", key: "wages" },
                { displayName: "Leave", key: "leave_eligibility" },
                { displayName: "Date Of Joining", key: "join_date" },
                { displayName: "Date Of Birth", key: "birth_date" },
                { displayName: "ID Proof No", key: "id_proof_no" },
            ],
        },

        {
            cardTitle: "Bank Details",
            cardDetails: [
                { displayName: "IFSC Code", key: "ifsc_code" },
                { displayName: "Bank Name", key: "bank_name" },
                { displayName: "Branch Name", key: "branch_name" },
                { displayName: "Account Holder Name", key: "account_holder_name" },
                { displayName: "Account Number", key: "account_no" },
            ],
        },

        {
            cardTitle: "Additional Details ",
            cardDetails: [
                { displayName: "Personal Email Id", key: "personalEmail" },
                { displayName: "Id Proof", key: "id_proof_img" },
                { displayName: "Notes", key: "additional_notes" },
            ],
        },

        // {
        //     cardTitle: "Additional Notes ",
        //     cardDetails: [{ displayName: "Notes", key: "additional_notes" }],
        // },
    ];

    const renderDetailCardElement = (detail) => {
        switch (detail.displayName) {
            case "Address":
                return (
                    <span className="employeeSummaryCardValue">
                        {varList[detail.key] + ",\n" + varList[detail.key2]}
                        {varList !== null &&
                        varList !== undefined &&
                        varList[detail.key5] &&
                        varList[detail.key5] !== null &&
                        varList[detail.key5] !== undefined
                            ? " - " + varList[detail.key5]
                            : null}{" "}
                        {"\n" + varList[detail.key3] + ", " + varList[detail.key4]}
                    </span>
                );

            case "Id Proof":
                if (varList[detail.key] !== null) {
                    return (
                        <span
                            className="employeeSummaryCardValue"
                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                            onClick={(e) => {
                                getIdProofImage(varList.employee_id);
                            }}
                        >
                            View
                        </span>
                    );
                } else {
                    return <span className="employeeSummaryCardValue">NA</span>;
                }

            case "Date Of Joining":
            case "Date Of Birth":
                var date = "";

                if (varList[detail.key] !== null) {
                    var date2 = new Date(varList[detail.key]);
                    date = date2.toDateString();
                } else {
                    date = "NA";
                }

                return <span className="employeeSummaryCardValue">{date}</span>;

            default:
                return (
                    <span className="employeeSummaryCardValue">
                        {varList[detail.key] !== undefined && varList[detail.key] !== null && varList[detail.key].length > 0
                            ? varList[detail.key]
                            : "NA"}
                    </span>
                );
        }
    };

    const renderCards = () => {
        return varGrouping.map((card) => {
            return (
                <div className="detailCard">
                    <div className="detailCardTitle">{card.cardTitle}:</div>
                    <div className="employeeSummaryCardGrid">
                        {card.cardDetails.map((detail) => {
                            return (
                                <React.Fragment>
                                    <span className="employeeSummaryCardCategory">{detail.displayName}</span>
                                    <span> : </span>
                                    {renderDetailCardElement(detail)}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        });
    };

    const renderImageStatus = () => {
        return (
            <div>
                <img
                    className="employeeImage"
                    src={profileImage !== null && profileImage !== undefined ? `data:image/png;base64,${profileImage}` : imageNotAvailable}
                    alt="Employee"
                />
                <div className="employeeCurrentStatus">
                    <span className="employeeSummaryCategory">Status :</span>
                    <span className={varList.emp_status === "Active" ? "employeeStatus" : "employeeStatusInactive"}>{varList.emp_status}</span>
                </div>
            </div>
        );
    };

    const renderSummaryInfo = () => {
        return (
            <React.Fragment>
                <span className="employeeSummaryName">
                    {varList.name} <span className="employeeSummaryRole"> - {varList.role}</span>
                </span>
                {isLogged.roleId==1? <div>Org Name : {varList.orgName}</div>:null}
                <div className="employeeSummaryGrid">
                    <span className="employeeSummaryCategory">Employee Id</span>
                    <span className="employeeSummaryValue">: {varList.employee_no}</span>
                    <span className="employeeSummaryCategory">Employee Plant</span>
                    <span className="employeeSummaryValue">: {varList.plant_name}</span>
                    <span className="employeeSummaryCategory">Mobile Number</span>
                    <span className="employeeSummaryValue">
                        : {varList.mobile}
                        {varList.alternate_mobile ? ", " + varList.alternate_mobile : null}
                    </span>

                    <span className="employeeSummaryCategory">Email Id</span>
                    <span className="employeeSummaryValue">
                        : {varList.email !== undefined && varList.email !== null && varList.email.length > 0 ? varList.email : "NA"}
                    </span>

                    <span className="employeeSummaryCategory">Department</span>
                    <span className="employeeSummaryValue">
                        : {varList.department !== undefined && varList.department !== null ? varList.department : "NA"}
                    </span>

                    <span className="employeeSummaryCategory">Blood Group</span>
                    <span className="employeeSummaryValue">: {varList.blood_group}</span>
                </div>
            </React.Fragment>
        );
    };

    Modal.setAppElement("body");
    const [modalIsOpen, setIsOpen] = useState(false);

    if (varList !== null && varList !== undefined) {
        return (
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <Modal
                        isOpen={modalIsOpen}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={() => setIsOpen(false)}
                        // style={customStyles}
                        contentLabel="Example Modal"
                        // className="modalOuter"

                        style={{
                            overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                            content: {
                                textAlign: "center",
                                width: "70%",
                                height: "70%",
                                position: "fixed",
                                top: "15%",
                                left: "15%",
                                padding: "20px",
                                borderRadius: "5px",
                                boxShadow: "0 0 5px gray",
                            },
                        }}
                    >
                        <img className="modalImage" src={`data:image/jpeg;base64,${idProofImage}`} alt="Id Proof" />
                    </Modal>
                    {/* <div style={{ width: "90%", backgroundColor: "white", margin: "10 auto", padding: "20px", boxSizing: "border-box" }}> */}
                    <div className="employeeSummary">
                        {renderImageStatus()}
                        <div className="employeeSummaryText">{renderSummaryInfo()}</div>
                    </div>
                    <div className="employeeDetailCards">{renderCards()}</div> {/* </div> */}
                </div>
            </div>
        );
    } else return null;
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        employeeInfo: state.employeeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetail);
