import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import opdIcon from "../../assets/opd.svg";

import TopBanner from "../SmallComponents/TopBanner";
import CreateOpd from "./CreateOpd";
import OpdList from "./OpdList";
import OpdDetail from "./OpdDetail";
import EditOpd from "./EditOpd";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const StoreOpd = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("opdList");
    const [tab, setTab] = useState("createSalesOrder");

    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    var opd = { buttonName: "Create OPD", className: "employeeButton", setSection: setCurrentSection, sectionName: "createOpd" };
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "opdList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "opdDetail") }, sectionName: "opdList" };

    const renderSection = (section) => {
        switch (section) {
            case "opdList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={opdIcon}
                            pageTitle="OPD List"
                            buttonList={isLogged.access["OPD"] === "Create" || isLogged.access["OPD"] === "Edit" || isLogged.access["OPD"] === "App/Dec" ? [opd] : null} />
                        <OpdList
                            setTab={setTab}
                            setSection={setCurrentSection}
                            setApproval={(value) => setApprovalPage(value)}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "opdDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={opdIcon} pageTitle="OPD Detail" buttonList={[goBackButton, downloadButton]} />
                        <OpdDetail approval={approvalPage} setSection={setCurrentSection} setEditAndApprove={setEditAndApprove} />
                    </React.Fragment>
                );

            case "createOpd":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={opdIcon} pageTitle="Create OPD" buttonList={[goBackButton]} />
                        <CreateOpd setSection={setCurrentSection} />
                    </React.Fragment>
                );

            case "editOpd":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={opdIcon} pageTitle={editAndApprove ? "Edit & Approve OPD" : "Edit OPD"} buttonList={[goBackButton]} />
                        <EditOpd setSection={setCurrentSection} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            default:
                return <span>{`No ${section} page created yet`}</span>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="OPD" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(StoreOpd);

