import React, { useEffect, useState } from "react";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";

import { validateMandatory, validateEqualValues,validateEmail,validateMobileNumber } from "../CommonFunctions/ValidateFields";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { gstValidation,swiftCodeValidation } from "./Plants";
const plantsParameterList = [
    // general info
    ["plantProfile","plantCode", "plantName","plantEmail","contactNo","website","address","country", "state", "cityId", "zipCode", "gstRegisterType", "gstNo","parentPlantId"],

    // storage details
    ["storageCode", "storageName", "address", "status",'storeType'],

    // bank details
    ["accountHolderName", "accountNumber", "confirmAccountNo", "ifscCode",'swiftCode', "bankName", "branch"],
];

const CreatePlant = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
}) => {
    const [plantGeneralInfo, setPlantGeneralInfo] = useState({
        plantProfile: {
            inputType: "text",
            value: "",
            hintText: "Plant Profile",
            mandatory: false,
            colSpan: 5,
            url: "",
            error: false,
            errorMessage: "Upload a Plant profile",
        },
        plantCode: {
            inputType: "text",
            value: "",
            hintText: "Plant Code",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Plant Code",
        },
        plantName: {
            inputType: "text",
            value: "",
            hintText: "Plant Name",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Plant Name",
        },
        country: {
            inputType: "options",
            value: "",
            hintText: "Country",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select country",
        },
        state: {
            inputType: "options",
            value: "",
            hintText: "State",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select state",
        },
        cityId: {
            inputType: "options",
            value: "",
            hintText: "City",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select city",
        },
        zipCode: {
            inputType: "text",
            value: "",
            hintText: "Zip Code",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Enter Zip code",
        },
        gstRegisterType: {
            inputType: "options",
            value: "",
            hintText: "GST Register Type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Regular", optionName: "Regular" },
                { optionId: "Composition", optionName: "Composition" },
                { optionId: "Unregistered", optionName: "Unregistered" },
                { optionId: "Consumer", optionName: "Consumer" },
            ],
            error: false,
            errorMessage: "Select GST register type",
        },
        gstNo: {
            inputType: "text",
            value: "",
            hintText: "GST No",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Provide GST Number",
        },
        website:{
            inputType: "text",
            value: "",
            hintText: "Website",
            colSpan: 4,
            mandatory: false,
        },
        address:{
            inputType: "text",
            value: "",
            hintText: "Address",
            colSpan: 4,
            mandatory: false,
        },
        // swiftCode: {
        //     inputType: "text",
        //     value: "",
        //     hintText: "SWIFT Code",
        //     colSpan: 4,
        //     error: false,
        //     errorMessage: "Invalid Swift Code"
        // },
        parentPlantId: {
            inputType: "options",
            value: "",
            hintText: "Parent Company",
            mandatory: false,
            colSpan: 4,
            options: [],
        },
        plantEmail:{
            inputType: "text",
            value: "",
            hintText: "Email",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Please Enter Valid Email"
        },
        contactNo:{
            inputType: "text",
            value: "",
            hintText: "Contact No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter a Valid phone Number"
        }
    });

    const [plantStorageDetails, setPlantStorageDetails] = useState({
        storageCode: {
            inputType: "text",
            value: "",
            hintText: "Store Code",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please enter Store Code",
        },
        storageName: {
            inputType: "text",
            value: "",
            hintText: "Store Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please provide Store Name",
        },
        address: {
            inputType: "text",
            value: "",
            hintText: "Address",
            mandatory: true,
            colSpan: 16,
            error: false,
            errorMessage: "Please provide company address",
        },
        status: {
            inputType: "options",
            value: "",
            hintText: "Status",
            mandatory: true,
            colSpan: 6,
            options: [
                { optionId: "Active", optionName: "Active" },
                { optionId: "Inactive", optionName: "Inactive" },
            ],
            error: false,
            errorMessage: "Select Store status",
        },
        storeType: {
            inputType: "options",
            value: "",
            hintText: "Store Type",
            mandatory: false,
            colSpan: 5,
            options: [{optionId: "FinishedGoods Store",optionName: "FinishedGoods Store"},
                      {optionId: "RawMaterials Store",optionName:"RawMaterials Store"},
                     {optionId: "Scrap/Reject Store",optionName:"Scrap/Reject Store"}],
            error: false,
            errorMessage: ""
        }
    });
    const [plantBankDetail, setPlantBankDetail] = useState({
        accountHolderName: {
            inputType: "text",
            value: "",
            hintText: "Account Holder Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Provide account holder name",
        },
        accountNumber: {
            inputType: "text",
            value: "",
            hintText: "Account No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter account number",
        },
        confirmAccountNo: {
            inputType: "text",
            value: "",
            hintText: "Confirm Account No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Account numbers don't match",
        },
        ifscCode: {
            inputType: "text",
            value: "",
            hintText: "IFSC Code",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter IFSC Code",
        },
        bankName: {
            inputType: "text",
            value: "",
            hintText: "Bank Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Bank Name",
        },
        swiftCode: {
            inputType: "text",
            value: "",
            hintText: "SWIFT Code",
            colSpan: 4,
            error: false,
            errorMessage: "Invalid Swift Code"
        },
        branch: {
            inputType: "text",
            value: "",
            hintText: "Branch",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Specify Bank Branch",
        },
    });

    const [plantBankDetailsList, setPlantBankDetailsList] = useState([]);
    const [storageDetailList, setStorageDetailList] = useState([
        // { storageCode: "ABC", storageName: "ABC Storage", address: "12, Cross cut road, Gandhipuram", status: "Active" },
    ]);
    useEffect(()=>{
         getInformation();
    },[]);

    useEffect(() => {
        let plantGenInfoCopy=Object.assign(plantGeneralInfo)
        if (plantGenInfoCopy.country.value != "") 
        getState();
        else{
            plantGenInfoCopy=update(plantGenInfoCopy,{
                                                    state: {options: {$set: []},
                                                            value: {$set: ""} 
                                                            },
                                                    cityId: {options: {$set: []},
                                                            value: {$set: ""} 
                                                            }   
                                                });
        setPlantGeneralInfo(plantGenInfoCopy);
        }
    }, [plantGeneralInfo.country]);

    useEffect(() => {
        let plantGenInfoCopy=Object.assign(plantGeneralInfo)
        if (plantGeneralInfo.state.value != "") 
        getCity();
        else{
            plantGenInfoCopy=update(plantGenInfoCopy,{
                cityId: {options: {$set: []},
                        value: {$set: ""} 
                        }   
            });
        setPlantGeneralInfo(plantGenInfoCopy);
        }
    }, [plantGeneralInfo.state]);

    async function getInformation(){
         var Parents= await  getParents();
         var countryOptions = await getDim({ field: "country" }, "country");
       let generalCopy=Object.assign(plantGeneralInfo);
       generalCopy = update( generalCopy , {
                                            parentPlantId : {options :{$set:  Parents} },
                                            country: {options: {$set: countryOptions} } 
                                            });
       setPlantGeneralInfo(generalCopy);
    };

    async function getDim(dataSent, dimension) {
        var data = await fetchData({
            requestingPage: "getDim",
            method: "post",
            url: "dim",
            headers: { token: isLogged.accessToken, module: "Employee" },
            data: dataSent,
        });
        if (data.msg !== "failure") {
            var optionArray = [];

            switch (dimension) {
                case "country":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                        optionArray.push(a);
                    });
                    break;

                case "state":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                        optionArray.push(a);
                    });
                    break;

                case "cityId":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                        optionArray.push(a);
                    });
                    break;

                default:
                    break;
            }

            return optionArray;
        } else {
            return [];
        }
    }

    async function getState() {
        if (plantGeneralInfo.country.value !== "") {
            let stateparam = await getDim({ field: "state", filterBy: "country_id", filterValue: plantGeneralInfo.country.value }, "state");

            let plantGeneralInfoCopy = Object.assign(plantGeneralInfo);
            plantGeneralInfoCopy = update(plantGeneralInfoCopy, { state: { options: { $set: stateparam },value:{$set: ""} },
                                                                    cityId: {options: {$set: []},value: {$set: "" } }  });

            setPlantGeneralInfo(plantGeneralInfoCopy);
        }
    }

    async function getCity() {
        if (plantGeneralInfo.state.value !== "") {
            let cityparam = await getDim({ field: "city", filterBy: "state_id", filterValue: plantGeneralInfo.state.value }, "cityId");

            let plantGeneralInfoCopy = Object.assign(plantGeneralInfo);
            plantGeneralInfoCopy = update(plantGeneralInfoCopy, { cityId: { options: { $set: cityparam } } });

            setPlantGeneralInfo(plantGeneralInfoCopy);
        }
    }

    async function getParents(){
        var data = await fetchData({
            requestingPage: "getParents",
            method: "get",
            url: "fetch/plants",
            headers: { token: isLogged.accessToken ,module:"Plant & Store"},
        });
        var parentOptions = [];
        if (data.msg === "success") {
            data.asset.forEach((parent) => {
                var newParent = { optionId: parent.plant.plantId, optionName: parent.plant.plantName };
                parentOptions.push(newParent);
            });
        }
        return parentOptions;
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");
    const [dataSubmitted, setDataSubmitted] = useState(false);

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading form. Please wait...");
        if (modalText === "Plant & Store info created successfully!") {
            setSection("plantList");
        }
    };

    const updatePlantParameter = ({ paramName, section, key, value }) => {
        switch (section) {
            case "generalInfo":
                var plantParams = Object.assign(plantGeneralInfo);
                plantParams = update(plantParams, { [paramName]: { [key]: { $set: value } } });
                setPlantGeneralInfo(plantParams);
                break;

            case "storageDetails":
                var plantParams2 = Object.assign(plantStorageDetails);
                plantParams2 = update(plantParams2, { [paramName]: { [key]: { $set: value } } });
                setPlantStorageDetails(plantParams2);
                break;

            case "bankDetails":
                var plantParams3 = Object.assign(plantBankDetail);
                plantParams3 = update(plantParams3, { [paramName]: { [key]: { $set: value } } });
                setPlantBankDetail(plantParams3);
                break;
            default:
                break;
        }
    };

    const [plantStorageError, setPlantStorageError] = useState([]);
    const checkPlantStorageError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(plantStorageDetails);

        plantsParameterList[1].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateMandatory(paramsCopy[element].value) } },
                });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newStorageDetail = {};
            plantsParameterList[1].forEach((item) => {
                newStorageDetail[item] = paramsCopy[item].value;
                paramsCopy[item].value = "";
            });

            setStorageDetailList([...storageDetailList, newStorageDetail]);

            setPlantStorageError(errorList);
            setPlantStorageDetails(paramsCopy);
        } else {
            setPlantStorageError(errorList);
            setPlantStorageDetails(paramsCopy);
        }
    };

    const [plantBankError, setPlantBankError] = useState([]);
    const checkPlantBankError = async() => {
        var errorList = [];
        var paramsCopy = Object.assign(plantBankDetail);

        plantsParameterList[2].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value) } } });
            }
            if (element === "confirmAccountNo") {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateEqualValues(paramsCopy[element].value, paramsCopy.accountNumber.value) } },
                });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });
        if(paramsCopy['swiftCode'].value){

            await swiftCodeValidation({swiftCode:paramsCopy['swiftCode'].value,isLogged}).then((res)=>{

                if(res==='Invalid')
                errorList.push('Invalid Swift Code')
            });
        }
        if (errorList.length === 0) {
            var newVendorbank = {};
            plantsParameterList[2].forEach((item) => {
                if (item !== "confirmAccountNo") {
                    newVendorbank[item] = paramsCopy[item].value;
                }
                paramsCopy[item].value = "";
            });
            setPlantBankDetailsList([...plantBankDetailsList, newVendorbank]);
            setPlantBankError(errorList);
            setPlantBankDetail(paramsCopy);
        } else {
            setPlantBankError(errorList);
            setPlantBankDetail(paramsCopy);
        }
    };

    const [plantErrors, setPlantErrors] = useState([]);

    const checkErrors = async() => {
        // check generalInfo error

        var errorList = [];
        var paramsCopy = Object.assign(plantGeneralInfo);

        // check errors in general info area
        plantsParameterList[0].forEach((element) => {
            if (paramsCopy[element].mandatory) {

                switch(element){
                    case "plantEmail":
                        paramsCopy = update(paramsCopy, {
                            [element]: { error: { $set: !validateEmail(paramsCopy[element].value.toString()) } },
                            });
                    break;

                    case "contactNo":
                        paramsCopy = update(paramsCopy, {
                            [element]: { error: { $set: !validateMobileNumber(paramsCopy[element].value.toString()) } },
                            });
                    break;
                    
                    default:
                        paramsCopy = update(paramsCopy, {
                        [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } },
                        });
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        //GSTIN API Validation
        if(paramsCopy['gstNo'].value){

            await gstValidation({gstNo:paramsCopy['gstNo'].value,isLogged}).then((res)=>{

                if(res==='Invalid')
                errorList.push('Invalid GST No')
            });
        }


        // if(paramsCopy['swiftCode'].value){

        //     await swiftCodeValidation({swiftCode:paramsCopy['swiftCode'].value,isLogged}).then((res)=>{

        //         if(res==='Invalid')
        //         errorList.push('Invalid Swift Code')
        //     });
        // }

        setPlantErrors(errorList);
        setPlantGeneralInfo(paramsCopy);

        // Checking customer contact and bank details errors before submitting

        var plantStorageAllClear = true;
        var plantBankAllClear = true;

        if (errorList.length === 0) {

            // storageList area
            // check atleast 1 row present
            // check if input is not saved

            if (storageDetailList.length > 0) {
                plantsParameterList[1].forEach((element) => {
                    if (plantStorageDetails[element].value !== "") {
                        plantStorageAllClear = false;
                        setPlantStorageError(["Unsaved Information found"]);
                    }
                });
            } else {
                plantStorageAllClear = false;
                setPlantStorageError(["Enter atleast one storage detail for Plant"]);
            }

            if(plantBankDetailsList.length>0){
            plantsParameterList[2].forEach((element) => {
                if (plantBankDetail[element].value !== "") {
                    plantBankAllClear = false;
                    setPlantBankError(["Unsaved plant bank information"]);
                }
            });
        }else{
            plantBankAllClear=false;
            setPlantBankError(['Enter atleast one bank detail for Plant']);
        }
            // , customerBankAllClear, customerDeliverAllClear);
            if (plantStorageAllClear && plantBankAllClear) {
                prepareDataToSubmit(paramsCopy);
            }
        }
    };

    const prepareDataToSubmit = (paramsCopy) => {
        var data2 = new FormData();

        // Add General Info
        plantsParameterList[0].forEach((item) => {
            switch(item) {
                case 'plantProfile':
                    if(paramsCopy[item].value!==null)
                    data2.append(item,paramsCopy[item].value)
                    break;
                
                    default:
                        if (paramsCopy[item].value !== null && paramsCopy[item].value.toString().length > 0) {
                            data2.append(item , paramsCopy[item].value);
                        }
            }
        });

        // Add Contact Details
        data2.append('storageDetail',JSON.stringify(storageDetailList)) ;
        data2.append('bankDetail' , JSON.stringify(plantBankDetailsList));

        setIsOpen(true);
        submitData(data2);
    };

    const [plantServerError, setPlantServerError] = useState([]);
    async function submitData(data2) {
        await checkToken();

        var result = await fetchData({
            requestingPage: "createPlant",
            method: "post",
            url: "create/plant",
            headers: { token: isLogged.accessToken, module: "Plant & Store" },
            data: data2,
        });

        if (result.msg === "success") {
            setModalText("Plant & Store info created successfully!");
            setDataSubmitted(true);
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setDataSubmitted(true);
            setPlantServerError([result.desc]);
        }
    }

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    const editSubSectionItem = (tab, item, action) => {
        if (action === "edit") {
            switch (tab) {
                case "storageActions":
                    var storageAllClear = true;
                    plantsParameterList[1].forEach((item) => {
                        if (plantStorageDetails[item].value !== "") {
                            storageAllClear = false;
                        }
                    });

                    if (!storageAllClear) {
                        var yes = window.confirm("Unsaved data found. Do you want to overwrite it?");
                    }

                    if (yes || storageAllClear) {
                        var storageCopy = Object.assign(plantStorageDetails);
                        var storageDetailListCopy = storageDetailList.slice();

                        var editObj = storageDetailListCopy.splice(item, 1)[0];
                        Object.keys(editObj).forEach((element) => {
                            storageCopy[element].value = editObj[element];
                        });

                        setStorageDetailList(storageDetailListCopy);
                        setPlantStorageDetails(storageCopy);
                    }
                    break;

                case "bankActions":
                    var bankAllClear = true;
                    plantsParameterList[2].forEach((item) => {
                        if (plantBankDetail[item].value !== "") {
                            bankAllClear = false;
                        }
                    });

                    if (!bankAllClear) {
                        var yes2 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }

                    if (yes2 || bankAllClear) {
                        var plantparam2 = Object.assign(plantBankDetail);
                        var plantBankListCopy = plantBankDetailsList.slice();

                        var editBankObj = plantBankListCopy.splice(item, 1)[0];

                        Object.keys(editBankObj).forEach((key) => {
                            plantparam2[key].value = editBankObj[key];
                        });
                        plantparam2.confirmAccountNo.value = editBankObj.accountNumber;
                        setPlantBankDetail(plantparam2);
                        setPlantBankDetailsList(plantBankListCopy);
                    }
                    break;

                default:
                    break;
            }
        } else if (action === "delete") {
            switch (tab) {
                case "storageActions":
                    var storageDetailListCopy2 = storageDetailList.slice();
                    storageDetailListCopy2.splice(item, 1);
                    setStorageDetailList(storageDetailListCopy2);
                    break;

                case "bankActions":
                    var plantBankListCopy3 = plantBankDetailsList.slice();
                    plantBankListCopy3.splice(item, 1);
                    setPlantBankDetailsList(plantBankListCopy3);
                    break;

                default:
                    console.log("Not Handled Yet");
                    break;
            }
        }
    };

    const renderErrorMessage = () => {
        if (plantErrors.length > 0) return `Plant Info Section : ${plantErrors[0]}`;
        else if (plantStorageError.length > 0) return `Storage Details : ${plantStorageError[0]}`;
        else if (plantBankError.length > 0) return `Bank Details Tab: ${plantBankError[0]}`;
        else if (plantServerError.length > 0) return `Error: ${plantServerError[0]}`;
        else return null;
    };

    const renderFormElements = ({ elementList, section }) => {
        return elementList.map((item) => {
            if(item==='plantProfile'){
               return( <div className="box profilePhoto" key={"photoElement"}>
                {/* eslint-disable-next-line */}
                <img style={{opacity:"1"}} src={plantGeneralInfo[item].url} className="photoArea" alt="Profile Picture" />
                <label
                    className={plantGeneralInfo[item].error ? "uploadEditProfilePhotoError" : "uploadEditProfilePhoto"}
                    htmlFor="uploadProfilePhoto"
                >
                    {plantGeneralInfo[item].value !== null &&
                        plantGeneralInfo[item].value.name !== null &&
                        plantGeneralInfo[item].value.name !== undefined &&
                        plantGeneralInfo[item].value.name.length > 0
                        ? "EDIT"
                        : "UPLOAD"}
                </label>
                <input
                    className="uploadEditInput"
                    id="uploadProfilePhoto"
                    type="file"
                    accept="images/*"
                    onChange={(event) => {
                        var plantParamCopy = Object.assign(plantGeneralInfo);
                        plantParamCopy = update(plantParamCopy, {
                            [item]: {
                                value: { $set: event.target.files[0] },
                                url: { $set: URL.createObjectURL(event.target.files[0]) },
                            },
                        });
                        setPlantGeneralInfo(plantParamCopy);
                    }}
                />
                        </div>);
            }else{
            switch (section) {
                case "generalInfo":
                    return (
                        <FormElement
                            key={item}
                            inputType={plantGeneralInfo[item].inputType}
                            value={plantGeneralInfo[item].value}
                            setInput={(value) => {
                                updatePlantParameter({ section, paramName: item, key: "value", value: value });
                            }}
                            hintText={plantGeneralInfo[item].hintText}
                            mandatory={plantGeneralInfo[item].mandatory}
                            colSpan={plantGeneralInfo[item].colSpan}
                            options={plantGeneralInfo[item].inputType === "options" ? plantGeneralInfo[item].options : null}
                            error={plantGeneralInfo[item].error}
                        />
                    );

                case "storageDetails":
                    return (
                        <FormElement
                            key={item}
                            inputType={plantStorageDetails[item].inputType}
                            value={plantStorageDetails[item].value}
                            setInput={(value) => {
                                updatePlantParameter({ section, paramName: item, key: "value", value: value });
                            }}
                            hintText={plantStorageDetails[item].hintText}
                            mandatory={plantStorageDetails[item].mandatory}
                            colSpan={plantStorageDetails[item].colSpan}
                            options={plantStorageDetails[item].inputType === "options" ? plantStorageDetails[item].options : null}
                            error={plantStorageDetails[item].error}
                        />
                    );
                case "bankDetails":
                    return (
                        <FormElement
                            key={item}
                            inputType={plantBankDetail[item].inputType}
                            value={plantBankDetail[item].value}
                            setInput={(value) => {
                                updatePlantParameter({ section, paramName: item, key: "value", value: value });
                            }}
                            hintText={plantBankDetail[item].hintText}
                            mandatory={plantBankDetail[item].mandatory}
                            colSpan={plantBankDetail[item].colSpan}
                            options={plantBankDetail[item].inputType === "options" ? plantBankDetail[item].options : null}
                            error={plantBankDetail[item].error}
                            rowSpan={item === "vendorLogo" || item === "otherBusinessDetails" ? plantBankDetail[item].rowSpan : null}
                        />
                    );

                default:
                    return null;
            }
            }
        });
    };

    const tablist = [
        { id: "storageDetails", name: "Storage Details" },
        { id: "bankDetails", name: "Bank Details" }
    ];
    const [selectedTab, setSelectedTab] = useState("storageDetails");
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                    {tab.id === "storageDetails" || tab.id==="bankDetails"? selectedTab === "storageDetails" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const RenderTable = ({ tab }) => {
        switch (tab) {
            case "storageDetails":
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Store Code</td>
                                <td>Store Name</td>
                                <td>Address</td>
                                <td>Status</td>
                                <td>Storage Type</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        {storageDetailList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={storageDetailList}
                                                 tab="storageActions"
                                                 fields={["storageCode","storageName","address","status","storeType"]} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {plantsParameterList[1].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );
            case "bankDetails":
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Account Holder Name</td>
                                <td>Account Number</td>
                                <td>IFSC Code</td>
                                <td>Swift Code</td>
                                <td>Bank Name</td>
                                <td>Branch</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        {plantBankDetailsList.length > 0 ? (
                            <tbody>
                                <RenderTableRows
                                    rows={plantBankDetailsList}
                                    tab="bankActions"
                                    fields={["accountHolderName", "accountNumber", "bankName", "ifscCode",'swiftCode', "branch"]}
                                />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {plantsParameterList[2].map((item) => {
                                        return <td>&nbsp;</td>;
                                    })}
                                </tr>
                            </tbody>
                        )}
                    </table>
                );
            default:
                return <div>No Table rendered</div>;
        }
    };


    const renderSubSection = (selectedTab) => {
        switch (selectedTab) {
            case "storageDetails":
                return (
                    <React.Fragment>
                        {/* <div style={{ fontSize: "1px" }}>&nbsp;</div> */}
                        {/* <div className="plantsSubTitle">Storage Details :</div> */}
                        <div className="createVendorIndividualTabDetails">
                            {renderFormElements({ elementList: plantsParameterList[1], section: "storageDetails" })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkPlantStorageError();
                                }}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "bankDetails":
                return (
                    <React.Fragment>
                        <div className="createVendorIndividualTabDetails">
                            {renderFormElements({ elementList: plantsParameterList[2], section: "bankDetails" })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkPlantBankError();
                                }}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            default:
                return <div>No Specified Subsection</div>;
        }
    };

    const RenderTableRows = ({ rows, tab }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {Object.keys(row).map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                    <td>
                        <img
                            alt="Edit Record"
                            title="Edit Record"
                            className="createVendorContactsAction"
                            src={editIcon}
                            onClick={() => {
                                editSubSectionItem(tab, j, "edit");
                            }}
                        />
                        <img
                            alt="Delete Record"
                            title="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) editSubSectionItem(tab, j, "delete");
                            }}
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        overflow: "hidden",
                    }}
                >
                    <div className="createPlantGeneralGrid">
                        {renderFormElements({ elementList: plantsParameterList[0], section: "generalInfo" })}
                    </div>
                    <div className="createVendorTabArea">
                        <div className="createVendorTabs">
                            <RenderTabList tablist={tablist} />
                        </div>
                        {renderSubSection(selectedTab)}
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var emptyGeneralInfo = Object.assign(plantGeneralInfo);
                                plantsParameterList[0].forEach((item) => {
                                    emptyGeneralInfo[item].value = "";
                                    emptyGeneralInfo[item].error = false;
                                });
                                setPlantGeneralInfo(emptyGeneralInfo);
                                setPlantErrors([]);

                                var emptyStorageInfo = Object.assign(plantStorageDetails);
                                plantsParameterList[1].forEach((item) => {
                                    emptyStorageInfo[item].value = "";
                                    emptyStorageInfo[item].error = false;
                                });
                                setStorageDetailList([]);
                                setPlantStorageDetails(emptyStorageInfo);
                                setPlantStorageError([]);

                                var emptyBankDetail = Object.assign(plantBankDetail);
                                plantsParameterList[2].forEach((item) => {
                                    emptyBankDetail[item].value = "";
                                    emptyBankDetail[item].error = false;
                                });
                                setPlantBankDetail(emptyBankDetail);
                                setPlantBankDetailsList([]);
                                setPlantBankError([]);
                            }
                        }}
                    >
                        Reset Fields
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            setPlantErrors([]);
                            setPlantStorageError([]);
                            setPlantBankError([]);
                            setPlantServerError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlant);
