import FormElement from 'components/SmallComponents/FormElement';
import fetchData from "../../../serverCall/fetchData";
import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { handleSort } from "../../CommonFunctions/utils";
import SortUpGreen from "assets/SortUpGreen.svg";
import SortDownGreen from "assets/SortDownGreen.svg";
import Pagination from 'components/CommonFunctions/pagination';
import { useMemo } from 'react';
import viewIcon from "assets/view.svg";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import { addToLoadingSlipId, addToPackingSlipId } from 'redux/Store/StoreInfoActions';

const PackingSlipList = ({isLogged, pcEdit,loadingSlipEdit,loadingSlipView, pcview, pcCreate, pcView,setEditAndApprove, setSection ,screenName}) => {
  const access = isLogged.access["Packing Slip"];
  const [plantId, setPlantId] = useState("")
  const [customerId, setCustomerId] = useState("");
  const [PageSize,setRowsPerPage]=useState(10);
  const [customerOptions,setCustomerOptions]=useState([]);
  const [psList, setPsList]=useState([]);
  const [loading, setLoading]=useState(true);
  const [currentPage,setCurrentPage]=useState(1);
  const [noPsList, setNoPslist] = useState(false)
  

  const psCurrentPage = useMemo(() => {
    if(isLogged){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return psList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,psList,PageSize]);

  useEffect(async () => {
    await getCustomers();
    await getPackingSlipList();
  }, []);


  async function getCustomers() {
    var data = await fetchData({
        requestingPage: "getParents",
        method: "get",
        url: "fetch/customer-list",
        headers: { token: isLogged.accessToken ,module:"Customer"},
    });
    if (data.msg === "success") {
        var options = [];
        data.asset.forEach((parent) => {
            var newParent = { optionId: parent.customerId, optionName: parent.customer };
            options.push(newParent);
        });
        setCustomerOptions(options);
    }
}

  async function getPackingSlipList(){
    let url = "store-fetch/"
    url = screenName === "Packing" ? url+"packing-slip" :url+"loading-slip"

    var data = await fetchData({
      requestingPage: "PaymentSlipList",
      method: "post",
      url: url,
      headers: { token: isLogged.accessToken, module: screenName === "Packing"?"Packing Slip" :"Loading Slip" },
      data: {plantId,customerId}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoPslist(true);
      }
      setPsList(data.asset);
    }
    setLoading(false);
  }
  
const renderPslist = () => {
  return psList?.map((item,i)=>{
    return (
      <React.Fragment key={i}>
      <tr className="vendorList">
        <td className="vendorListInfo">{item.packingSlipNo || item.loadingSlipNo}</td>
      <td className="vendorListInfo">{item.soNo}</td>
        <td className="vendorListInfo">{item.companyName}</td>
        <td className="vendorListInfo">{item.invoiceNo}</td>
        <td className="vendorListInfo">{item.vehicleNo}</td>
        <td className="vendorListInfo">{item.plantName}</td>
        <td className="vendorListInfoActions">
        <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Invoice Detail"
                  onClick={(e) => {
                    e.stopPropagation();
                   screenName === "Packing" ? pcView(item.packingSlipId):loadingSlipView(item.loadingSlipId)
                    setSection(screenName==="Packing" ?"packingSlipDetail":"loadingSlipDetail");
                  }}
                />
                <img
                  alt="Edit Record"
                  className="vendorActionImages"
                  src={
                    access === "Edit" || access === "App/Dec"
                      ? editIcon
                      : editIconDisabled
                  }
                  title={
                    access === "Edit" || access === "App/Dec"
                      ? "Edit Item"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "Edit" || access === "App/Dec") {
                      screenName==="Packing"? pcEdit(item.packingSlipId):loadingSlipEdit(item.loadingSlipId) 
                      setSection(screenName==="Packing" ?"editPackingSlip":"editLoadingSlip");
                    }
                  }}
                  style={
                    access === "Edit" || access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />
        </td>
      </tr>
    </React.Fragment>
    );
  });
};

const [order, setOrder] = useState({ col: "soNo", state: true, data: "" });

const RenderPackingSlipList=()=>{
  return(
    <React.Fragment>
      <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader" style={{zIndex:1}}>
              <td
              className="vendorListHeading stickyFirstColumn"
              onClick={(e) => {
                setOrder({
                  col: "packingSlipNo",
                  state: !order.state,
                });
                handleSort({
                  data: "soApprovedCurrentPage",
                  col: "packingSlipNo",
                  state: order.state,
                });
              }}>
              Packing Slip No
              <img alt="sort"
                className="sortArrow"
                src={
                  order.col === "packingSlipNo"
                    ? order.state
                      ? SortUpGreen
                      : SortDownGreen
                    : null
                }
              />
            </td>
            <td
              className="vendorListHeading"
              onClick={(e) => {
                setOrder({
                  col: "soNo",
                  state: !order.state,
                });
                handleSort({
                  data: "soApprovedCurrentPage",
                  col: "soNo",
                  state: order.state,
                });
              }}>
              SO No
              <img alt="sort"
                className="sortArrow"
                src={
                  order.col === "soNo"
                    ? order.state
                      ? SortUpGreen
                      : SortDownGreen
                    : null
                }
              />
            </td>
            <td
              className="vendorListHeading"
              onClick={(e) => {
                setOrder({
                  col: "CustomerName",
                  state: !order.state,
                });
                handleSort({
                  data: "soApprovedCurrentPage",
                  col: "CustomerName",
                  state: order.state,
                });
              }}>
              Customer Name
              <img alt="sort"
                className="sortArrow"
                src={
                  order.col === "CustomerName"
                    ? order.state
                      ? SortUpGreen
                      : SortDownGreen
                    : null
                }
              />
            </td>
            <td
              className="vendorListHeading"
              onClick={(e) => {
                setOrder({
                  col: "InvoiceNo",
                  state: !order.state,
                });
                handleSort({
                  data: "soApprovedCurrentPage",
                  col: "InvoiceNo",
                  state: order.state,
                });
              }}>
              Invoice No
              <img alt="sort"
                className="sortArrow"
                src={
                  order.col === "InvoiceNo"
                    ? order.state
                      ? SortUpGreen
                      : SortDownGreen
                    : null
                }
              />
            </td>
            <td
              className="vendorListHeading"
              onClick={(e) => {
                setOrder({
                  col: "vehicleNo",
                  state: !order.state,
                });
                handleSort({
                  data: "soApprovedCurrentPage",
                  col: "vehicleNo",
                  state: order.state,
                });
              }}>
              Vehicle No
              <img alt="sort"
                className="sortArrow"
                src={
                  order.col === "vehicleNo"
                    ? order.state
                      ? SortUpGreen
                      : SortDownGreen
                    : null
                }
              />
            </td>
            <td
              className="vendorListHeading"
              onClick={(e) => {
                setOrder({
                  col: "plantDetails",
                  state: !order.state,
                });
                handleSort({
                  data: "soApprovedCurrentPage",
                  col: "plantDetails",
                  state: order.state,
                });
              }}>
          Plant Details
              <img alt="sort"
                className="sortArrow"
                src={
                  order.col === "plantDetails"
                    ? order.state
                      ? SortUpGreen
                      : SortDownGreen
                    : null
                }
              />
            </td>
            <td className="vendorListHeadingActions">Actions</td>
          </tr>
              </thead>
              <tbody>{psList.length > 0 ?
              renderPslist()
              :null}</tbody>
          </table>
          {noPsList ? (
            <div className="noRecordsYet">No records found</div>
          ) : loading ? (
            <div className="loader" style={{ margin: "1rem auto" }}></div>
          ) : null}
        </React.Fragment>
  )
}

const renderPagination = () => {
  return(
  psList.length > 0 ? <Pagination
    className= "pagination-bar"
    currentPage={currentPage}
    totalCount={psList.length}
    pageSize={PageSize}
    onPageChange={page => setCurrentPage(page)}
/> : null)}

  return (
    <React.Fragment>
       <div style={{ display:"inline-flex",marginLeft:"25px" }} className="listPageParamsGrid">
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setPlantId(value);
          }}
          inputType={"options"}
          value={plantId}
          hintText={"Plant Name"}
          options={isLogged.plantOptions}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setCustomerId(value);
          }}
          inputType={"options"}
          options={customerOptions}
          value={customerId}
          hintText={"Customer Name"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
      </div>
      <div className="vendorListArea" style={{maxHeight:"450px"}}>
    <React.Fragment>
    {RenderPackingSlipList()}
    <div>{renderPagination()}</div>
    </React.Fragment>
    </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isLogged: state.isLogged
})

const mapDispatchToProps=(dispatch)=>{
  return{
    pcEdit: (value)=>dispatch(addToPackingSlipId(value)),
    pcView: (value)=>dispatch(addToPackingSlipId(value)),
    loadingSlipEdit:(value)=>dispatch(addToLoadingSlipId(value)),
    loadingSlipView:(value)=>dispatch(addToLoadingSlipId(value))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PackingSlipList);