import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import { getUom } from "serverCall/fetchData";

const EditMaterialRequisition = ({
  setSection,
  isLogged,
  poInfo,
  addTokenToState,
  editApprove,
  storeInfo,
}) => {
  const materialRequisitionParamsList = ["orderDate", "expectDate"];
  const lineItemsParamsList = isLogged.showHideWeight
    ? [
        "itemName",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "uom",
        "requestQuantity",
      ]
    : ["itemName", "uom", "requestQuantity"];

  const [materialRequisitionParams, setMaterialRequisitionParams] = useState({
    orderDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: " Order date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please select order date for completing request",
      mindate: true,
    },
    expectDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Expected request complete date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please select expected date for completing request",
      mindate: true,
    },
  });

  const [materialRequisitionParamsMTS, setMaterialRequisitionParamsMTS] =
    useState({
      itemCategory: {
        inputType: "options",
        value: "",
        hintText: "Item Category",
        mandatory: false,
        colSpan: 12,
        options: [],
        error: false,
        errorMessage: "Select an Item",
        callServer: true,
    },
      itemId: {
        inputType: "options",
        value: "",
        hintText: "Item ",
        mandatory: true,
        colSpan: 6,
        error: false,
        options: [],
        errorMessage: "Please select Item",
      },

      uomId: {
        inputType: "options",
        value: "",
        hintText: "UOM",
        mandatory: true,
        colSpan: 6,
        error: false,
        options: [],
        errorMessage: "Please select UOM",
        serverCall: true,
      },
      from: {
        inputType: "text",
        value: "",
        hintText: "Weight From(gms)",
        mandatory: false,
        colSpan: 4,
        error: false,
        errorMessage: "Select Order date above to autofill this field",
        disabled: true,
      },
      to: {
        inputType: "text",
        value: "",
        hintText: "Weight To(gms)",
        mandatory: false,
        colSpan: 4,
        error: false,
        errorMessage: "Select Order date above to autofill this field",
        disabled: true,
      },
      netWeight: {
        inputType: "text",
        value: "",
        hintText: "Net Weight(gms)",
        mandatory: false,
        colSpan: 4,
        error: false,
        errorMessage: "Select Order date above to autofill this field",
        disabled: true,
      },
      grossWeight: {
        inputType: "text",
        value: "",
        hintText: "Gross Weight(gms)",
        mandatory: false,
        colSpan: 4,
        error: false,
        errorMessage: "Select Order date above to autofill this field",
        disabled: true,
      },
      requestQuantity: {
        inputType: "text",
        value: "",
        hintText: "Request Quantity",
        mandatory: true,
        colSpan: 6,
        error: false,
        errorMessage: "Please enter a valid Request Quantity",
      },
      requestLineItemId: { value: null },
    });

  const [lineItems, setLineItems] = useState([]);
  const [mtsLineItemsList, setMtsLineItemsList] = useState([]);
  const [instruction, setInstruction] = useState("");
  const [allItemUomList, setAllItemUomList] = useState({});
  const [materialRequisitionDetail, setMaterialRequisitionDetail] = useState(
    {}
  );
  const [itemList,setItemList] = useState()

  const updateMaterialRequisitionParameter = ({
    paramName,
    section,
    key,
    value,
  }) => {
    if (section === "general") {
      var paramsCopy = Object.assign(materialRequisitionParams);
      paramsCopy = update(paramsCopy, {
        [paramName]: { [key]: { $set: value } },
      });
      setMaterialRequisitionParams(paramsCopy);
    }

    if (section === "lineItem") {
      var paramsCopy = Object.assign(materialRequisitionParamsMTS);
      paramsCopy = update(paramsCopy, {
        [paramName]: { [key]: { $set: value } },
      });

      if(paramName === "itemCategory"){
        if(value){
          const filteredItems = itemList.filter(item=>{return item.categoryId === value})
          paramsCopy = update(paramsCopy, {
                itemId: { options: {$set:filteredItems},value:{$set :""}},
            });
        }else{

          paramsCopy = update(paramsCopy, {
                itemId: { options: {$set:itemList},
                         value:{$set:""}},
            });
        }
    }
      setMaterialRequisitionParamsMTS(paramsCopy);
    }
  };
  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  async function getInformation() {
    await checkToken();
    var mrsDetail = await getMaterialRequisitionDetail();

    var paramsCopy = Object.assign(materialRequisitionParams);

    paramsCopy = update(paramsCopy, {
      orderDate: { value: { $set: mrsDetail.orderDate } },
      expectDate: { value: { $set: mrsDetail.expectDate } },
    });

    {
      mrsDetail.lineItems.forEach((item) => {
        item.requestQuantity = item.quantity;
      });
      setMtsLineItemsList(mrsDetail.lineItems);
    }
    setMaterialRequisitionDetail(mrsDetail);
    setMaterialRequisitionParams(paramsCopy);
    setInstruction(mrsDetail.instruction);
  }

  const history = useHistory();

  async function checkToken() {
    
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
     
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }
 const getInitialInfo =async()=>{
  var [itemListCopy, itemUomList] = await getUom(isLogged);
  var [categoryList,subCategoryList] = await getCategory();

  var materialRequisitionDetailCopy = { ...materialRequisitionDetail };
  var removedOptions = [];
  materialRequisitionDetailCopy.lineItems &&
    materialRequisitionDetailCopy.lineItems.length > 0 &&
    materialRequisitionDetailCopy.lineItems.forEach((opt, id) => {
      var itemOptionToRemove = itemListCopy.filter(
        (item) => opt.itemId === item.optionId
      )[0];
      removedOptions.push(itemOptionToRemove);
      itemListCopy = itemListCopy.filter(
        (filOpt) => filOpt !== itemOptionToRemove
      );
      materialRequisitionDetailCopy.lineItems[id].itemOption =
        itemOptionToRemove;
    });

  var paramsCopy = Object.assign(materialRequisitionParamsMTS);
  paramsCopy = update(paramsCopy, {
    itemId: { options: { $set: itemListCopy } },
    itemCategory:{options :{$set : categoryList}},
    uomId: { value: { $set: "" } },
  });

  setLineItems([]);
  setMaterialRequisitionDetail(materialRequisitionDetailCopy);
  setMaterialRequisitionParamsMTS(paramsCopy);
  setAllItemUomList(itemUomList);

setItemList(itemListCopy)
 }

 async function getCategory() {
  var categoryData = await fetchData({
      requestingPage: "getCategory",
      method: "get",
      url: "fetch/categories",
      headers: { token: isLogged.accessToken, module: "Dropdowns" },
  });

  var categoryList = [];
  var subCategoryList = {};

  if (categoryData.msg === "success") {
      categoryData.asset.forEach((categ) => {
          var catObj = {};
          catObj.optionId = categ.category.categoryId;
          catObj.optionName = categ.category.categoryName;
          categoryList.push(catObj);

          subCategoryList[categ.category.categoryId] = [];

          categ.subCategory.forEach((subCateg) => {
              var subCatObj = {};
              subCatObj.optionId = subCateg.subCategoryId;
              subCatObj.optionName = subCateg.subCategoryName;

              subCategoryList[categ.category.categoryId].push(subCatObj);
          });
      });
      return [categoryList, subCategoryList];
  }
}

 useEffect(()=>{
  getInitialInfo()
 },[materialRequisitionParams])
  // useEffect(async () => {
  
  //   var [itemListCopy, itemUomList] = await getUom(isLogged);
  //   var [categoryList,subCategoryList] = await getCategory();

  //   var materialRequisitionDetailCopy = { ...materialRequisitionDetail };
  //   var removedOptions = [];
  //   materialRequisitionDetailCopy.lineItems &&
  //     materialRequisitionDetailCopy.lineItems.length > 0 &&
  //     materialRequisitionDetailCopy.lineItems.forEach((opt, id) => {
  //       var itemOptionToRemove = itemListCopy.filter(
  //         (item) => opt.itemId === item.optionId
  //       )[0];
  //       removedOptions.push(itemOptionToRemove);
  //       itemListCopy = itemListCopy.filter(
  //         (filOpt) => filOpt !== itemOptionToRemove
  //       );
  //       materialRequisitionDetailCopy.lineItems[id].itemOption =
  //         itemOptionToRemove;
  //     });

  //   var paramsCopy = Object.assign(materialRequisitionParamsMTS);
  //   paramsCopy = update(paramsCopy, {
  //     itemId: { options: { $set: itemListCopy } },
  //     itemCategory:{options :{$set : categoryList}},
  //     uomId: { value: { $set: "" } },
  //   });

  //   setLineItems([]);
  //   setMaterialRequisitionDetail(materialRequisitionDetailCopy);
  //   setMaterialRequisitionParamsMTS(paramsCopy);
  //   setAllItemUomList(itemUomList);

  //   var itemsList = {
  //     categoryItemList:subCategoryList,
  //     defaultItemList:itemListCopy
  // }
  // setItemList(itemsList)
  // }, [materialRequisitionParams]);

  useEffect(() => {
    var paramsCopy = Object.assign(materialRequisitionParamsMTS);
    if (materialRequisitionParamsMTS.itemId.value !== "") {
      paramsCopy = update(paramsCopy, {
        uomId: {
          options: {
            $set: allItemUomList[materialRequisitionParamsMTS.itemId.value],
          },
        },
        from: {
          value: { $set: allItemUomList[paramsCopy.itemId.value][0]["from"] },
        },
        to: {
          value: { $set: allItemUomList[paramsCopy.itemId.value][0]["to"] },
        },
        netWeight: {
          value: {
            $set: allItemUomList[paramsCopy.itemId.value][0]["netWeight"],
          },
        },
        grossWeight: {
          value: {
            $set: allItemUomList[paramsCopy.itemId.value][0]["grossWeight"],
          },
        },
      });
    } else {
      paramsCopy = update(paramsCopy, { uomId: { options: { $set: [] } } });
    }
    setMaterialRequisitionParamsMTS(paramsCopy);
  }, [materialRequisitionParamsMTS.itemId.value]);

  const editSubSectionItem = (index, action) => {
    var lineItemsListCopy = mtsLineItemsList.slice();
    var paramsCopy = Object.assign(materialRequisitionParamsMTS);

    if (action === "edit") {
      var lineItemAllClear = true;

      Object.keys(paramsCopy).forEach((item) => {
        if (materialRequisitionParamsMTS[item].value !== "" && item!=="requestLineItemId") {
          lineItemAllClear = false;
        }
      });
      if (!lineItemAllClear) {
        var yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var editObj = lineItemsListCopy.splice(index, 1)[0];

        var allItemListCopy =
          materialRequisitionParamsMTS.itemId.options.slice();
        allItemListCopy.push(editObj.itemOption);

        paramsCopy = update(paramsCopy, {
          itemId: {
            value: { $set: editObj.itemId },
            options: { $set: allItemListCopy },
          },
          from: { value: { $set: editObj.from } },
          to: { value: { $set: editObj.to } },
          netWeight: { value: { $set: editObj.netWeight } },
          grossWeight: { value: { $set: editObj.grossWeight } },
          uomId: {
            value: { $set: editObj.uomId },
            options: { $set: allItemUomList[editObj.itemId] },
            serverCall: { $set: false },
          },
          requestQuantity: { value: { $set: editObj.requestQuantity } },
          requestLineItemId: { value: { $set: editObj.requestLineItemId } },
        });
      }
    }

    if (action === "delete") {
      var editObj = lineItemsListCopy.splice(index, 1)[0];

      var allItemListCopy = materialRequisitionParamsMTS.itemId.options.slice();
      allItemListCopy.push(editObj.itemOption);

      paramsCopy = update(paramsCopy, {
        itemId: { options: { $set: allItemListCopy } },
      });
    }

    setMaterialRequisitionParamsMTS(paramsCopy);
    setMtsLineItemsList(lineItemsListCopy);
  };

  async function getMaterialRequisitionDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "mrs-fetch/request-detail/" + poInfo.editMaterialRequisitionId,
      headers: { token: isLogged.accessToken, module: "Material Requisition" },
    });
    if (data.msg === "success") {
      return data.asset[0];
    } else {
    }
  }

  const checkLineItemError = () => {
    var errorList = [];

    var paramsCopy = Object.assign(materialRequisitionParamsMTS);
    lineItemsParamsList.map((item) => {
      if (paramsCopy[item].mandatory)
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });

      if (item !== "itemId" || item !== "uomId") {
        if (paramsCopy[item].value !== "") {
          if (
            isNaN(paramsCopy[item].value) ||
            parseFloat(paramsCopy[item].value) < 1
          ) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var lineItemsCopy = mtsLineItemsList.slice();

      var newItem = {
        itemId: paramsCopy.itemId.value,
        itemName: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0].optionName,
        itemOption: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0],
        from: paramsCopy.from.value,
        to: paramsCopy.to.value,
        netWeight: paramsCopy.netWeight.value,
        grossWeight: paramsCopy.grossWeight.value,
        uomId: paramsCopy.uomId.value,
        uom: paramsCopy.uomId.options.filter(
          (item) => item.optionId === paramsCopy.uomId.value
        )[0].optionName,
        requestQuantity: paramsCopy.requestQuantity.value,
        requestLineItemId: paramsCopy.requestLineItemId.value,
      };
      lineItemsCopy.push(newItem);

      var trimmedItemList = paramsCopy.itemId.options.filter(
        (item) => item.optionId !== paramsCopy.itemId.value
      );

      paramsCopy = update(paramsCopy, {
        itemId: { value: { $set: "" }, options: { $set: trimmedItemList } },
        from: { value: { $set: "" } },
        to: { value: { $set: "" } },
        netWeight: { value: { $set: "" } },
        grossWeight: { value: { $set: "" } },
        uomId: { value: { $set: "" } },
        requestQuantity: { value: { $set: "" } },
      });

      setMtsLineItemsList(lineItemsCopy);
    }

    setMaterialRequisitionError(errorList);
    setMaterialRequisitionParamsMTS(paramsCopy);
  };
  async function approveMrs() {
    setIsOpen(true);
    setDataSubmitted(false);
    var approveMrs = await fetchData({
      requestingPage: "approveMrs",
      method: "put",
      url: `mrs-edit/request-approve/${poInfo.editMaterialRequisitionId}`,
      headers: { token: isLogged.accessToken, module: "Material Requisition" },
    });
    setDataSubmitted(true);
    if (approveMrs.msg === "success") {
      setModalText("Material Requisition Approved");
      setDataSubmitted(true);
    } else {
      setModalText(approveMrs.desc);
      setMaterialRequisitionError(approveMrs.desc);
    }
  }
  const submitData = async (data) => {
    setIsOpen(true);
    setModalText("Editing Material Requisition request. Please wait...!");
    var result = await fetchData({
      requestingPage: "createPoItem",
      url: "mrs-edit/request",
      method: "put",
      headers: { token: isLogged.accessToken, module: "Material Requisition" },
      data: data,
    });
    if (result.msg === "success") {
      if (editApprove) {
        await approveMrs();
        setModalText("Material Requisition edited Successfully");
      } else {
        setDataSubmitted(true);
        setModalText("Material Requisition Request edited successfully!");
      }
    } else {
    }
  };

  const checkErrorsMTS = () => {
    var errorList = [];
    var paramsCopy = Object.assign(materialRequisitionParams);
    ["orderDate", "expectDate"].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    var lineItemsCopy = mtsLineItemsList.slice();

    if (lineItemsCopy.length > 0) {
      var itemsToServer = [];

      lineItemsCopy.forEach((item) => {
        var newItem = {
          itemId: item.itemId,
          uomId: item.uomId,
          quantity: parseInt(item.requestQuantity),
          requestLineItemId: item.requestLineItemId,
        };

        itemsToServer.push(newItem);
      });
    } else {
      errorList.push("Provide request quantity for atleast one item");
    }

    if (errorList.length === 0) {
      var data2Server = {
        orderDate: materialRequisitionParams.orderDate.value,
        requestId: materialRequisitionDetail.requestId,
        expectDate: materialRequisitionParams.expectDate.value,
        instruction: instruction,
        lineItems: itemsToServer,
      };

      submitData(data2Server);
    }

    setMaterialRequisitionError(errorList);
    setMaterialRequisitionParams(paramsCopy);
  };
  const subSectionParams = isLogged.showHideWeight
    ? [
         "itemCategory",
        "itemId",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "uomId",
        "requestQuantity",
      ]
    : ["itemCategory","itemId", "uomId", "requestQuantity"];
  const renderSubSection = () => {
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea">
          {renderFormElements({
            elementList: subSectionParams,
            param: materialRequisitionParamsMTS,
            section: "lineItem",
          })}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={5}
            setInput={() => {
              checkLineItemError();
            }}
          />
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
            <img
              style={{ padding: "10px", width: "1rem", height: "1rem" }}
              src={resetIcon}
              alt="Reset"
              onClick={() => {
                var yes = window.confirm("Clear Input data?");

                if (yes) {
                  var paramsCopy = Object.assign(materialRequisitionParams);

                  lineItemsParamsList.forEach((item) => {
                    paramsCopy[item].value = "";
                    paramsCopy[item].error = false;
                  });

                  setMaterialRequisitionParams(paramsCopy);
                }
              }}
            />
          </div>
        </div>
        <RenderTable />
      </React.Fragment>
    );
  };

  const RenderTableRows = ({ rows, tab }) => {
    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {lineItemsParamsList.map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const RenderTable = () => {
    var emptyColumns = isLogged.showHideWeight ? ["", "", "", "", "", "", "", ""] : ["", "", "", ""];
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Item</td>
            {isLogged.showHideWeight ? (
              <>
                {" "}
                <td>Weight From(gms)</td>
                <td>Weight To(gms)</td>
                <td>Net Weight(gms)</td>
                <td>Gross Weight(gms)</td>{" "}
              </>
            ) : null}
            <td>UOM</td>
            <td>Request Quantity</td>
            <td>Actions</td>
          </tr>
        </thead>
        {mtsLineItemsList.length > 0 ? (
          <tbody>
            <RenderTableRows rows={mtsLineItemsList} tab="contactActions" />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
              {emptyColumns.map((item, i) => {
                return <td key={i}>&nbsp;</td>;
              })}
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value !== null ? param[element].value : ""}
          setInput={(value) => {
            updateMaterialRequisitionParameter({
              section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };

  const [materialRequisitionError, setMaterialRequisitionError] = useState([]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Editing Material Requisition request. Please wait..."
  );

  const submitOkClick = () => {
    setIsOpen(false);
    if (materialRequisitionError.length === 0) {
      setSection("materialRequisitionList");
    }
  };

  const renderErrorMessage = () => {
    if (materialRequisitionError.length > 0) return materialRequisitionError[0];
  };

  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "1000px",
            margin: "0 auto 2rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className="createPurchaseOrderGrid"
              style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
              {renderFormElements({
                elementList: materialRequisitionParamsList,
                param: materialRequisitionParams,
                section: "general",
              })}
            </div>
          </div>

          <div className="purchaseOrderSubSectionArea" style={{width:"fit-content"}}>
            {renderSubSection()}
          </div>

          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div style={{ marginTop: "10px" }}>
                Material Requisition Instruction
              </div>
              <textarea
                className="multilineInput"
                value={instruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="submitButton"
            onClick={() => {
              checkErrorsMTS();
            }}>
            {editApprove ? "Edit & Approve" : "Submit for Approval"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
    storeInfo: state.storeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMaterialRequisition);
