import React, { useState } from "react";
import PageTitle from "../../SmallComponents/PageTitle";
import itemsIcon from "../../../assets/item_black.svg";
import ItemList from "./ItemList";
import MenuList from "../../Menu/MenuList";
import CreateItem from "./CreateItem";
import ItemDetail from "./ItemDetail";
import EditItem from "./EditItem";
import TopBanner from "../../SmallComponents/TopBanner";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import UploadItems from "./UploadItems";

const Item = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("itemList");
    const [isApproval,setIsApproval] = useState(false);

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemButton = {
        buttonName: "Create Item",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createItem",
    };

    var uploadItemsButton = {
        buttonName: "Upload Items",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "uploadItems",
    };

    const renderSection = (section) => {
        switch (section) {
            case "itemList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={itemsIcon}
                            pageTitle="Item List"
                            buttonList={
                                isLogged.access.Items === "Create" || isLogged.access.Items === "Edit" || isLogged.access.Items === "App/Dec"
                                    ? [uploadItemsButton, createItemButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemList setSection={(value) => setCurrentSection(value)} setIsApproval={setIsApproval} />
                    </React.Fragment>
                );

            case "createItem":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Create Item" buttonList={goBackButton} />
                        <CreateItem setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "itemDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Item Detail" buttonList={goBackButton} />
                        <ItemDetail setSection={(value) => setCurrentSection(value)} isApproval={isApproval}/>
                    </React.Fragment>
                );

            case "editItem":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Edit Item" buttonList={goBackButton} />
                        <EditItem setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "uploadItems":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Upload Items" buttonList={goBackButton} />
                        <UploadItems setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Item);
