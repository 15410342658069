import {Fragment,useEffect,useState} from 'react'
import { addToken } from '../../redux/UserAccount/IsLoggedActions';
import {connect} from 'react-redux';
import fetchData from '../../serverCall/fetchData';


const ImportReportList = ({importReportParams,isLogged,poInfo}) => {

  const [isLoading,setIsLoading] = useState(true);
  const [noRecords,setNoRecords]=useState(false);
  const [importReportList,setImportReportList] = useState([]);

  async function getImportList(){
    var response =await fetchData({
      requestingPage: "importReport",
      method: "post",
      url: "po-fetch/import-reports",
      headers: { token: isLogged.accessToken, module: "Import Report" },
      data: importReportParams 
      })
setIsLoading(false)
      if(response.data.msg=='success'){
        if(response.data.asset.length) 
        {
          setImportReportList(response.data.asset) 
      }else {
        setNoRecords(true);
        }
      }else{
        setNoRecords(true);
      }
  }

  useEffect(()=>{
    getImportList();
  },[])
  const RenderSubTable=({data})=>{

    return(
      <td colSpan={4}>
        <table className='vendorListInnerTable'>
            <thead className='vendorListInnerTableHeader'>
              <td className="vendorListInnerTableHeading">Import No</td>
              <td className="vendorListInnerTableHeading">Invoice No</td>
              <td className="vendorListInnerTableHeading">Port Arrival Date</td>
            </thead>
            <tbody>
              <tr className="vendorListInnerTable">
                <td className="vendorListInnerTableInfo">{data.importNo}</td>
                <td className="vendorListInnerTableInfo">{data.invoiceNo}</td>
                <td className="vendorListInnerTableInfo">{data.portArrivalDate}</td>
              </tr>
            </tbody>
        </table>
      </td>
    )
  }
  const editItemCollapse = (index) => {
    var listCopy = [];
        listCopy = importReportList.slice();
    listCopy.forEach((item, j) => {
      if (j === index) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });
  setImportReportList(listCopy)
  };
  const renderImportReport=()=>{

    return(
      <Fragment>
        {importReportList.map((Imp,index)=>(
          <>
        <tr className='vendorList' key={index} onClick={()=>editItemCollapse(index)}>
          <td className="vendorListInfo">{Imp.poNo}</td>
          <td className="vendorListInfo">{Imp.importNo}</td>
          <td className="vendorListInfo">{Imp.companyName}</td>
          <td className="vendorListInfo">{Imp.expectedDeliveryDate}</td>
        </tr>
          {
            !Imp.collapse ? (
              <RenderSubTable data={Imp.invoices}/>
            ) : null
          }
        </>
        ))

        }
      </Fragment>
    )
  }
  
  return (
    <Fragment>
      <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
             {isLoading ?  <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div> : null}
          {noRecords ?  <div className="noRecordsYet">No items available</div> :
          <div className="detailsPageWrapper"  style={{overflowX:"unset",flexDirection:"row",width:"110%"}}>
            <div className="detailsPageContainer" style={{minHeight: "1400px"}}>
            <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader"  style={{zIndex:1}}>
                <td className='vendorListHeading'>Purchase Order No</td>
                <td className='vendorListHeading'>Import No</td>
                <td className='vendorListHeading'>Company Name</td>
                <td className='vendorListHeading'>Expected Delivery Date</td>
              </tr>
              </thead>
              <tbody>
                {importReportList.length > 0 ? renderImportReport() : null}
              </tbody>
            </table>
        
            </div>
          </div>}
          </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
    importReportParams: state.poInfo.importReportParams
  };
};

const mapDispatchToProps = (dispatch) => {

  return {
    addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ImportReportList);