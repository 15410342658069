import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { exportData } from "../CommonFunctions/ExportExcel";
import FormElement from "../SmallComponents/FormElement";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import downloadIcon from "../../assets/download.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import {
  editSoId,
  editSoReturnId,
  setSoTab,
  viewSoId,
  viewSoReturnId,
} from "../../redux/PurchaseOrder/PoInfoActions";
import { viewSoDetailId } from "../../redux/UserAccount/IsLoggedActions";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";
import pageIcon from "assets/sheets.svg";


const SalesOrderList = ({
  // props
  setSection,
  setApproval,
  setTab,
  setEditAndApprove,

  // state
  isLogged,
  poInfo,

  // dispatch
  addTokenToState,
  viewSalesOrderId,
  editSalesOrderId,
  viewSalesOrderReturnId,
  editSalesOrderReturnId,
  setSalesOrderTab,
  viewSoDetailId
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [soList, setSoList] = useState([]);
  const [soApprovedList, setSoApprovedList] = useState([]);
  const [soDeclinedList, setSoDeclinedList] = useState([]);
  const [soReturnUnapprovedList, setSoReturnUnapprovedList] = useState([]);
  const [soReturnApprovedList, setSoReturnApprovedList] = useState([]);
  const [soClosedList, setSoClosedList] = useState([]);

  const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
  const [noApprovedRecords, setNoApprovedRecords] = useState(false);
  const [noDeclinedRecords, setNoDeclinedRecords] = useState(false);
  const [noSoReturnUnapprovedRecords, setNoSoReturnUnapprovedRecords] =
    useState(false);
  const [noSoReturnApprovedRecords, setNoSoReturnApprovedRecords] =
    useState(false);
  const [noClosedRecords, setNoClosedRecords] = useState(false);

  const [loading, setLoading] = useState(true);
  const [plantId, setPlantId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [fromOrderDate, setFromOrderDate] = useState("");
  const [toOrderDate, setToOrderDate] = useState("");
  const [customerOptions,setCustomerOptions]=useState([]);
  const [PageSize,setRowsPerPage]=useState(10);
  const soApprovedCurrentPage = useMemo(() => {
    if(poInfo.soTab==="approvedSoList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soApprovedList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soApprovedList,PageSize,poInfo.soTab]);

  const soUNApprovedCurrentPage = useMemo(() => {
    if(poInfo.soTab==="soList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soList,PageSize,poInfo.soTab]);

  const soDeclinedCurrentPage = useMemo(() => {
    if(poInfo.soTab==="declinedSoList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soDeclinedList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soDeclinedList,PageSize,poInfo.soTab]);

  const soReturnCurrentPage = useMemo(() => {
    if(poInfo.soTab==="salesOrderReturn"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soReturnUnapprovedList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soReturnUnapprovedList,PageSize,poInfo.soTab]);

  const soReturnApprovedCurrentPage = useMemo(() => {
    if(poInfo.soTab==="salesOrderReturnApproved"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soReturnApprovedList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soReturnApprovedList,PageSize,poInfo.soTab]);
  
  const soClosedCurrentPage = useMemo(() => {
    if(poInfo.soTab==="salesOrderClosed"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soClosedList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soClosedList,PageSize,poInfo.soTab]);

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  async function getInformation() {
    await checkToken();
    await getCustomers();   
    viewSoDetailId(null)
  }

  const history = useHistory();
  async function checkToken() {
    // console.log("Checking token");
    //   console.log(isLogged.accessToken);
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      // console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getSoUnapproved() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "so-fetch/so-unapproved-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: {plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoList(data2);
      if (data.asset.length > 0) setNoUnapprovedRecords(false);
      else setNoUnapprovedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getCustomers() {
    var data = await fetchData({
        requestingPage: "getParents",
        method: "get",
        url: "fetch/customer-list",
        headers: { token: isLogged.accessToken ,module:"Customer"},
    });
    if (data.msg === "success") {
        var options = [];
        data.asset.forEach((parent) => {
            var newParent = { optionId: parent.customerId, optionName: parent.customer };
            options.push(newParent);
        });
        setCustomerOptions(options);
    }
}

  async function getSoApproved() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "so-fetch/so-approved-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: {plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoApprovedList(data2);
      if (data.asset.length > 0) setNoApprovedRecords(false);
      else setNoApprovedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getSoDeclined() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "so-fetch/so-declined-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: {plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoDeclinedList(data2);
      if (data.asset.length > 0) setNoDeclinedRecords(false);
      else setNoDeclinedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getSoReturnUnapproved() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "so-fetch/sales-return-unapproved-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data:{plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoReturnUnapprovedList(data2);
      if (data.asset.length > 0) setNoSoReturnUnapprovedRecords(false);
      else setNoSoReturnUnapprovedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getSoReturnApproved() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "so-fetch/sales-return-approved-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data:{plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoReturnApprovedList(data2);
      if (data.asset.length > 0) setNoSoReturnApprovedRecords(false);
      else setNoSoReturnApprovedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getSoClosedList() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "so-fetch/so-closed-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: {plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoClosedList(data2);
      if (data.asset.length > 0) setNoClosedRecords(false);
      else setNoClosedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  const setSelectedTab = (tab) => {
    setSalesOrderTab(tab);
  };

  const tablist = [
    { id: "soList", name: "Sales Orders" },
    { id: "approvedSoList", name: "Approved Sales Orders" },
    { id: "declinedSoList", name: "Declined Sales Orders" },
    { id: "salesOrderReturn", name: "Sales Order Return" },
    { id: "salesOrderReturnApproved", name: "Approved SO Return" },
    { id: "salesOrderClosed", name: "Closed Sales Orders" },
  ];
  const fetchDataOnTabSwitch =async () => {
    setLoading(true);
    setCurrentPage(1);
    await checkToken();
    switch (poInfo.soTab) {
      case "soList":
        setSoList([]);
        await getSoUnapproved();
        break;

      case "approvedSoList":
        setSoApprovedList([]);
        await getSoApproved();
        break;

      case "declinedSoList":
        setSoDeclinedList([]);
        await getSoDeclined();
        break;

      case "salesOrderReturn":
        setSoReturnUnapprovedList([]);
        await getSoReturnUnapproved();
        break;

      case "salesOrderReturnApproved":
        setSoReturnApprovedList([]);
        getSoReturnApproved();
        break;

      case "salesOrderClosed":
        setSoClosedList([]);
        await getSoClosedList();
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    fetchDataOnTabSwitch({ soTab: poInfo.soTab, plantId, customerId });

  }, [poInfo.soTab, plantId, customerId,fromOrderDate,toOrderDate]);

  const RenderTabList = ({ tablist }) => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            poInfo.soTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={() => {
            setSelectedTab(tab.id);
            switch (tab.id) {
              case "soList":
                setOrder({ col: "soNo", state: true, data: "" });
                setTab("soList");
                return;

              case "salesOrderReturn":
                setOrder({ col: "returnNo", state: true, data: "" });
                setTab("salesOrderReturn");
                return;

              default:
                setTab("");
            }
          }}>
          {tab.name}
          {tab.id === "contactDetails" ? (
            poInfo.soTab === "contactDetails" ? (
              " *"
            ) : (
              <span style={{ color: "red" }}> *</span>
            )
          ) : null}
        </div>
      );
    });
  };

  const [order, setOrder] = useState({ col: "soNo", state: true, data: "" });

  const renderSubSection = () => {
    switch (poInfo.soTab) {
      case "soList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader" style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "soNo",
                        state: !order.state,
                      });
                    }}>
                    SO No
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({ col: "soDate", state: !order.state });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "expectedDeliveryDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "expectedDeliveryDate",
                        state: order.state,
                      });
                    }}>
                    Exp Delivery Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "expectedDeliveryDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "plantName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "plantName",
                        state: order.state,
                      });
                    }}>
                    Plant
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "plantName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "storageName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "storageName",
                        state: order.state,
                      });
                    }}>
                    Storage
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "storageName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "soStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soUNApprovedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "createdBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {soList.length > 0 ? renderSoUnapprovedList() : null}
              </tbody>
            </table>
            {noUnapprovedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "approvedSoList":
        return (
          <React.Fragment>
            <div className="exportBtn">
              <button
                onClick={() => exportData(isLogged, "soApprovedList")}
                className="exportButton">
                Export
              </button>
            </div>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader" style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                    SO No
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "expectedDeliveryDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "expectedDeliveryDate",
                        state: order.state,
                      });
                    }}>
                    Exp Delivery Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "expectedDeliveryDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "outwardStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "outwardStatus",
                        state: order.state,
                      });
                    }}>
                    Outward Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "outwardStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>
                    Invoice Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "invoiceStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "createdBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "editedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "editedBy",
                        state: order.state,
                      });
                    }}>
                    Modified By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "editedBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "approvedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "approvedBy",
                        state: order.state,
                      });
                    }}>
                    Approved By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "approvedBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {soApprovedList.length > 0 ? renderSoApprovedList() : null}
              </tbody>
            </table>
            {noApprovedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "declinedSoList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                    SO No
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "expectedDeliveryDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "expectedDeliveryDate",
                        state: order.state,
                      });
                    }}>
                    Exp Delivery Date{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "expectedDeliveryDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "plantName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "plantName",
                        state: order.state,
                      });
                    }}>
                    Plant{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "plantName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "storageName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "storageName",
                        state: order.state,
                      });
                    }}>
                    Storage
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "storageName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "soStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soDeclinedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "createdBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {soDeclinedList.length > 0 ? renderSoDeclinedList() : null}
              </tbody>
            </table>
            {noDeclinedRecords ? (
              <div className="noRecordsYet">No Records available</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "salesOrderReturn":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "returnNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "returnNo",
                        state: order.state,
                      });
                    }}>
                    SO Return No
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "returnNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "returnDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "returnDate",
                        state: order.state,
                      });
                    }}>
                    SO Return Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "returnDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                    SO No{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "orderStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "orderStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "orderStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "inwardStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "inwardStatus",
                        state: order.state,
                      });
                    }}>
                    Inward Status
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "inwardStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>
                    Invoice Status
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "invoiceStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {soReturnUnapprovedList.length > 0? renderSoReturnList(): null}
              </tbody>
            </table>
            {noSoReturnUnapprovedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "salesOrderReturnApproved":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "returnNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "returnNo",
                        state: order.state,
                      });
                    }}>
                    SO Return No
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "returnNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "returnDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "returnDate",
                        state: order.state,
                      });
                    }}>
                    SO Return Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "returnDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                    SO No
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "orderStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "orderStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "orderStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "inwardStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "inwardStatus",
                        state: order.state,
                      });
                    }}>
                    Inward Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "inwardStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>
                    Invoice Status
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "invoiceStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soReturnApprovedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                    <img alt="sort"
                    className="sortArrow"
                    src={
                        order.col === "createdBy"
                        ? order.state
                        ? SortUpGreen : SortDownGreen
                                  : null}
                    />
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {soReturnApprovedList.length > 0? renderSoReturnApprovedList(): null}
              </tbody>
            </table>
            {noSoReturnApprovedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "salesOrderClosed":
        return (
          <React.Fragment>
            <div className="exportBtn">
              <button
                onClick={() => exportData(isLogged, "soClosedList")}
                className="exportButton">
                Export
              </button>
            </div>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader" style={{zIndex:1}}>
                  <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                    SO No
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>
                    Order Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>
                    Company Name
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "companyName"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "expectedDeliveryDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "expectedDeliveryDate",
                        state: order.state,
                      });
                    }}>
                    Exp Delivery Date
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "expectedDeliveryDate"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "soStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "soStatus",
                        state: order.state,
                      });
                    }}>
                    Order Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "soStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "outwardStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "outwardStatus",
                        state: order.state,
                      });
                    }}>
                    Outward Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "outwardStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>
                    Invoice Status{" "}
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "invoiceStatus"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>
                    Created By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "createdBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "editedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "editedBy",
                        state: order.state,
                      });
                    }}>
                    Modified By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "editedBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td
                    className="vendorListHeading"
                    onClick={(e) => {
                      setOrder({
                        col: "approvedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: soClosedCurrentPage,
                        col: "approvedBy",
                        state: order.state,
                      });
                    }}>
                    Closed By
                    <img alt="sort"
                      className="sortArrow"
                      src={
                        order.col === "approvedBy"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    />
                  </td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {soClosedList.length > 0 ? renderSoClosedList() : null}
              </tbody>
            </table>
            {noClosedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      default:
        return (
          <div style={{ padding: "2rem", textAlign: "center" }}>
            Section Under Construction
          </div>
        );
    }
  };
  const access = isLogged.access["Sales Order"];
  const editItemCollapse = (list, index) => {
    var listCopy = [];
    let clickedIndex = ((currentPage-1) * PageSize) + index;
    switch (list) {
      case "soList":
        listCopy = soList.slice();
        break;

      case "soApprovedList":
        listCopy = soApprovedList.slice();
        break;

      case "soDeclinedList":
        listCopy = soDeclinedList.slice();
        break;

      case "soReturnList":
        listCopy = soReturnUnapprovedList.slice();
        break;

      case "soReturnApprovedList":
        listCopy = soReturnApprovedList.slice();
        break;

      case "salesOrderClosed":
        listCopy = soClosedList.slice();
        break;
        
        default:
          listCopy=[];
    }

    listCopy.forEach((item, j) => {
      if (j === clickedIndex) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });

    switch (list) {
      case "soList":
        setSoList(listCopy);
        break;

      case "soApprovedList":
        setSoApprovedList(listCopy);
        break;

      case "soDeclinedList":
        setSoDeclinedList(listCopy);
        break;

      case "soReturnList":
        setSoReturnUnapprovedList(listCopy);
        break;

      case "soReturnApprovedList":
        setSoReturnApprovedList(listCopy);
        break;

      case "salesOrderClosed":
        setSoClosedList(listCopy);
        break;
      
      default:
        return null;
    }
  };

  const newTabForPdfExport=()=>{
    let toPath = window.location.href.split('#');
    // viewSoDetailId(soId);
    toPath[1] = '/salesorder';
    toPath = toPath.join('#');
    window.open(toPath,'_blank')
    viewSoDetailId(null)
 }

  const renderSoUnapprovedList = () => {
    return soUNApprovedCurrentPage?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <tr
                className={item.collapse ? "vendorList" : "vendorListExpanded"}
                key={item.soId}
                onClick={() => editItemCollapse("soList", i)}>
                <td className="vendorListInfo stickyFirstColumn">{item.soNo}</td>
                <td className="vendorListInfo">{item.soDate}</td>
                <td className="vendorListInfo">{item.companyName}</td>
                <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                <td className="vendorListInfo">{item.plantName}</td>
                <td className="vendorListInfo">{item.storageName}</td>
                <td className="vendorListInfo">{item.soStatus}</td>
                <td className="vendorListInfo">{item.createdBy}</td>

                <td className="vendorListInfoActions">
                  <img
                    alt="Approve Record"
                    className="vendorActionImages"
                    src={
                      item.soStatus === "Draft"
                        ? approveIconDisabled
                        : approveIcon
                    }
                    title={
                      item.soStatus === "Draft"
                        ? "Can't approve Draft"
                        : access === "App/Dec"
                        ? "Approve Sales Order"
                        : "Access Denied"
                    }
                    style={
                      item.soStatus !== "Draft" && access === "App/Dec"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.soStatus !== "Draft" && access === "App/Dec") {
                        viewSalesOrderId(item.soId);
                        setSection("salesOrderDetail");
                        setApproval(true);
                      }
                    }}
                  />
                   <img
                    alt="Export Record"
                    className="vendorActionImages"
                    src={downloadIcon}
                    title="Export SO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSoDetailId(item.soId);
                      newTabForPdfExport();
                      
                    }}
                  />
                  <img
                    alt="View Proforma"
                    className="vendorActionImages"
                    src={pageIcon}
                    title="View Proforma Invoice Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderId(item.soId);
                      setSection("proformaInvoice");
                      setApproval(false);
                    }}
                  />
                  <img
                    alt="Edit Record"
                    className="vendorActionImages"
                    src={
                      access === "Edit" || access === "App/Dec"
                        ? editIcon
                        : editIconDisabled
                    }
                    title={
                      access === "Edit" || access === "App/Dec"
                        ? "Edit Item"
                        : "Access Denied"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (access === "Edit" || access === "App/Dec") {
                        editSalesOrderId(item.soId);
                        setSection("editSalesOrder");
                        setEditAndApprove(false);
                      }
                    }}
                    style={
                      access === "Edit" || access === "App/Dec"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                  />
                  <img
                    alt="Toggle Expand"
                    className="vendorActionImages"
                    title="Toggle Expand"
                    src={item.collapse ? downIcon : upIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      editItemCollapse("soList", i);
                    }}
                  />
                </td>
              </tr>

              <tr>
                {!item.collapse ? (
                  <RenderSubTable tab="unapprovedSo" data={item.lineItems} />
                ) : null}
              </tr>
            </React.Fragment>
          );
    });
  };

  const renderSoApprovedList = () => {
    return soApprovedCurrentPage?.map((item, i) => {
      var outwardColor = "";
      switch (item.outwardStatus) {
        case "Sent":
          outwardColor = "green";
          break;

        case "Partially Sent":
          outwardColor = "orange";
          break;

        case "Not Sent":
          outwardColor = "red";
          break;

          default:
            outwardColor = "black";
      }

      var invoiceColor = "";
      switch (item.invoiceStatus) {
        case "Invoiced":
          invoiceColor = "green";
          break;

        case "Not Invoiced":
          invoiceColor = "red";
          break;

        default:
          invoiceColor = "black";
      }
          return (
            <React.Fragment key={i}>
              <tr
                className={item.collapse ? "vendorList" : "vendorListExpanded"}
                key={item.soId}
                onClick={() => editItemCollapse("soApprovedList", i)}>
                <td className="vendorListInfo stickyFirstColumn">{item.soNo}</td>
                <td className="vendorListInfo">{item.soDate}</td>
                <td className="vendorListInfo">{item.companyName}</td>
                <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                <td className="vendorListInfo">{item.soStatus}</td>
                <td className="vendorListInfo" style={{ color: outwardColor }}>
                  {item.outwardStatus}
                </td>
                <td className="vendorListInfo" style={{ color: invoiceColor }}>
                  {item.invoiceStatus}
                </td>
                <td className="vendorListInfo">{item.createdBy}</td>
                <td className="vendorListInfo">
                  {item.editedBy !== null ? item.editedBy : "-"}
                </td>
                <td className="vendorListInfo">{item.approvedBy}</td>

                <td className="vendorListInfoActions">

                  <img
                    alt="View Proforma"
                    className="vendorActionImages"
                    src={pageIcon}
                    title="View Proforma Invoice Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderId(item.soId);
                      setSection("proformaInvoice");
                      setApproval(false);
                    }}
                  />
                  <img
                    alt="Export Record"
                    className="vendorActionImages"
                    src={downloadIcon}
                    title="Export SO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSoDetailId(item.soId);
                      newTabForPdfExport();
                      // exportData(isLogged, "soApprovedSingleItem", {
                      //   url: String(item.soId),
                      //   dealerName: item.companyName,
                      //   orderNo: item.soNo
                      // });
                    }}
                  />
                  <img
                    alt="Toggle Expand"
                    className="vendorActionImages"
                    title="Toggle Expand"
                    src={item.collapse ? downIcon : upIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      editItemCollapse("soApprovedList", i);
                    }}
                  />
                </td>
              </tr>

              <tr>
                {!item.collapse ? (
                  <RenderSubTable tab="approvedSo" data={item.lineItems} />
                ) : null}
              </tr>
            </React.Fragment>
          );
    });
  };

  const renderSoDeclinedList = () => {
    return soDeclinedCurrentPage?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <tr
                className={item.collapse ? "vendorList" : "vendorListExpanded"}
                key={item.soId}
                onClick={() => editItemCollapse("soDeclinedList", i)}>
                <td className="vendorListInfo stickyFirstColumn">{item.soNo}</td>
                <td className="vendorListInfo">{item.soDate}</td>
                <td className="vendorListInfo">{item.companyName}</td>
                <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                <td className="vendorListInfo">{item.plantName}</td>
                <td className="vendorListInfo">{item.storageName}</td>
                <td className="vendorListInfo">{item.soStatus}</td>
                <td className="vendorListInfo">{item.createdBy}</td>

                <td className="vendorListInfoActions">
                  <img
                    alt="View Record"
                    className="vendorActionImages"
                    src={viewIcon}
                    title="View PO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderId(item.soId);
                      setSection("salesOrderDetail");
                      setApproval(false);
                    }}
                  />
                  <img
                    alt="View Proforma"
                    className="vendorActionImages"
                    src={pageIcon}
                    title="View Proforma Invoice Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderId(item.soId);
                      setSection("proformaInvoice");
                      setApproval(false);
                    }}
                  />
                  <img
                    alt="Toggle Expand"
                    className="vendorActionImages"
                    title="Toggle Expand"
                    src={item.collapse ? downIcon : upIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      editItemCollapse("soDeclinedList", i);
                    }}
                  />
                   { // editable only when user is given permission in screen control 
               isLogged.editableForApproved ?
                 <img
                    alt="Edit Record"
                    className="vendorActionImages"
                    src={
                      access === "Edit" || access === "App/Dec"
                        ? editIcon
                        : editIconDisabled
                    }
                    title={
                      access === "Edit" || access === "App/Dec"
                        ? "Edit Item"
                        : "Access Denied"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (access === "Edit" || access === "App/Dec") {
                        editSalesOrderId(item.soId);
                        setSection("editSalesOrder");
                        setEditAndApprove(false);
                      }
                    }}
                    style={
                      access === "Edit" || access === "App/Dec"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                  /> : null
              }
                </td>
              </tr>

              <tr>
                {!item.collapse ? (
                  <RenderSubTable
                    tab="declinedSo"
                    reason={item.declineReason}
                    data={item.lineItems}
                    reaspn={item.delineReason}
                  />
                ) : null}
              </tr>
            </React.Fragment>
          );
    });
  };

  const renderSoReturnList = () => {
    return soReturnCurrentPage?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <tr
                className={item.collapse ? "vendorList" : "vendorListExpanded"}
                key={item.soId}
                onClick={() => editItemCollapse("soReturnList", i)}>
                <td className="vendorListInfo stickyFirstColumn">{item.returnNo}</td>
                <td className="vendorListInfo">{item.returnDate}</td>
                <td className="vendorListInfo">{item.soNo}</td>
                <td className="vendorListInfo">{item.soDate}</td>
                <td className="vendorListInfo">{item.companyName}</td>
                <td className="vendorListInfo">
                  {item.orderStatus !== null ? item.orderStatus : "-"}
                </td>
                <td className="vendorListInfo">
                  {item.inwardStatus !== null ? item.inwardStatus : "-"}
                </td>
                <td className="vendorListInfo">
                  {item.invoiceStatus !== null ? item.invoiceStatus : "-"}
                </td>
                <td className="vendorListInfo">{item.createdBy}</td>

                <td className="vendorListInfoActions">
                  <img
                    alt="Approve Record"
                    className="vendorActionImages"
                    src={
                      item.returnStatus === "Draft"
                        ? approveIconDisabled
                        : approveIcon
                    }
                    title={
                      item.returnStatus === "Draft"
                        ? "Can't approve Draft"
                        : access === "App/Dec"
                        ? "Approve Sales Order Return"
                        : "Access Denied"
                    }
                    style={
                      item.returnStatus !== "Draft" && access === "App/Dec"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.soStatus !== "Draft" && access === "App/Dec") {
                        viewSalesOrderReturnId(item.returnId);
                        setSection("salesReturnDetail");
                        setApproval(true);
                      }
                    }}
                  />

                  <img
                    alt="View Record"
                    className="vendorActionImages"
                    src={viewIcon}
                    title="View PO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderReturnId(item.returnId);
                      setSection("salesReturnDetail");
                      setApproval(false);
                    }}
                  />
                  <img
                    alt="Edit Record"
                    className="vendorActionImages"
                    src={
                      access === "Edit" || access === "App/Dec"
                        ? editIcon
                        : editIconDisabled
                    }
                    title={
                      access === "Edit" || access === "App/Dec"
                        ? "Edit Item"
                        : "Access Denied"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (access === "Edit" || access === "App/Dec") {
                        editSalesOrderReturnId(item.returnId);
                        setSection("editSalesReturn");
                        setEditAndApprove(false);
                      }
                    }}
                    style={
                      access === "Edit" || access === "App/Dec"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                  />

                  <img
                    alt="Toggle Expand"
                    className="vendorActionImages"
                    title="Toggle Expand"
                    src={item.collapse ? downIcon : upIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      editItemCollapse("soReturnList", i);
                    }}
                  />
                </td>
              </tr>

              <tr>
                {!item.collapse ? (
                  <RenderSubTable
                    tab="soReturnList"
                    reason={item.declineReason}
                    data={item.lineItems}
                    reaspn={item.delineReason}
                  />
                ) : null}
              </tr>
            </React.Fragment>
          );
    });
  };

  const renderSoReturnApprovedList = () => {
    return soReturnApprovedCurrentPage?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <tr
                className={item.collapse ? "vendorList" : "vendorListExpanded"}
                key={item.soId}
                onClick={() => editItemCollapse("soReturnApprovedList", i)}>
                <td className="vendorListInfo stickyFirstColumn">{item.returnNo}</td>
                <td className="vendorListInfo">{item.returnDate}</td>
                <td className="vendorListInfo">{item.soNo}</td>
                <td className="vendorListInfo">{item.soDate}</td>
                <td className="vendorListInfo">{item.companyName}</td>
                <td className="vendorListInfo">
                  {item.orderStatus !== null ? item.orderStatus : "-"}
                </td>
                <td className="vendorListInfo">
                  {item.inwardStatus !== null ? item.inwardStatus : "-"}
                </td>
                <td className="vendorListInfo">
                  {item.invoiceStatus !== null ? item.invoiceStatus : "-"}
                </td>
                <td className="vendorListInfo">{item.createdBy}</td>

                <td className="vendorListInfoActions">
                  <img
                    alt="View Record"
                    className="vendorActionImages"
                    src={viewIcon}
                    title="View PO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderReturnId(item.returnId);
                      setSection("salesReturnDetail");
                      setApproval(false);
                    }}
                  />

                  <img
                    alt="Toggle Expand"
                    className="vendorActionImages"
                    title="Toggle Expand"
                    src={item.collapse ? downIcon : upIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      editItemCollapse("soReturnApprovedList", i);
                    }}
                  />
                </td>
              </tr>

              <tr>
                {!item.collapse ? (
                  <RenderSubTable
                    tab="soReturnApprovedList"
                    reason={item.instruction}
                    data={item.lineItems}
                  />
                ) : null}
              </tr>
            </React.Fragment>
          );
    });
  };

  const renderSoClosedList = () => {
    return soClosedCurrentPage?.map((item, i) => {
      var outwardColor = "";
      switch (item.outwardStatus) {
        case "Sent":
          outwardColor = "green";
          break;

        case "Partially Sent":
          outwardColor = "orange";
          break;

        case "Not Sent":
          outwardColor = "red";
          break;
        
        default:
          break;
      }

      var invoiceColor = "";
      switch (item.invoiceStatus) {
        case "Invoiced":
          invoiceColor = "green";
          break;

        case "Not Invoiced":
          invoiceColor = "red";
          break;

        default:
          break;
      }
          return (
            <React.Fragment key={i}>
              <tr
                className={item.collapse ? "vendorList" : "vendorListExpanded"}
                key={item.soId}
                onClick={() => editItemCollapse("salesOrderClosed", i)}>
                <td className="vendorListInfo stickyFirstColumn">{item.soNo}</td>
                <td className="vendorListInfo">{item.soDate}</td>
                <td className="vendorListInfo">{item.companyName}</td>
                <td className="vendorListInfo">{item.expectedDeliveryDate}</td>
                <td className="vendorListInfo">{item.soStatus}</td>
                <td className="vendorListInfo" style={{ color: outwardColor }}>
                  {item.outwardStatus}
                </td>
                <td className="vendorListInfo" style={{ color: invoiceColor }}>
                  {item.invoiceStatus}
                </td>
                <td className="vendorListInfo">{item.createdBy}</td>
                <td className="vendorListInfo">
                  {item.editedBy !== null ? item.editedBy : "-"}
                </td>
                <td className="vendorListInfo">{item.approvedBy}</td>

                <td className="vendorListInfoActions">
                  <img
                    alt="View Record"
                    className="vendorActionImages"
                    src={viewIcon}
                    title="View SO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSalesOrderId(item.soId);
                      setSection("salesOrderDetail");
                      setApproval(false);
                    }}
                  />
                  <img
                    alt="Export Record"
                    className="vendorActionImages"
                    src={downloadIcon}
                    title="Export SO Detail"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSoDetailId(item.soId);
                      newTabForPdfExport();
                      // exportData(isLogged, "soApprovedSingleItem", {
                      //   url: String(item.soId),
                      //   dealerName: item.companyName,
                      //   orderNo: item.soNo
                      // });
                    }}
                  />
                  <img
                    alt="Toggle Expand"
                    className="vendorActionImages"
                    title="Toggle Expand"
                    src={item.collapse ? downIcon : upIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      editItemCollapse("salesOrderClosed", i);
                    }}
                  />
                </td>
              </tr>

              <tr>
                {!item.collapse ? (
                  <RenderSubTable tab="closedSo" data={item.lineItems} />
                ) : null}
              </tr>
            </React.Fragment>
          );
    });
  };

  const RenderSubTable = ({ tab, data, reason }) => {
    return (
      <td
        colSpan={
          tab === "approvedSo" || tab === "closedSo"
            ? "11"
            : tab === "soReturnList" || tab === "soReturnApprovedList"
            ? "10"
            : "9"
        }
        className="vendorListInnerTableWrapper">
        {tab === "declinedSo" ? (
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              padding: "10px",
              backgroundColor: "#eee",
            }}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Reason:&nbsp;&nbsp;&nbsp;
            </span>
            {reason}
          </div>
        ) : null}

        {tab === "soReturnApprovedList" ? (
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              padding: "10px",
              backgroundColor: "#eee",
            }}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Notes:&nbsp;&nbsp;&nbsp;
            </span>
            {reason !== null ? reason : "-"}
          </div>
        ) : null}

        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
              <td className="vendorListInnerTableHeading">Item Code</td>
              {isLogged.showHideWeight ? (
                <>
                  <td className="vendorListInnerTableHeading">
                    Weight From(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Weight To(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Net Weight(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Gross Weight(gms)
                  </td>
                </>
              ) : null}
              <td className="vendorListInnerTableHeading">HSN Code</td>
              <td className="vendorListInnerTableHeading">UOM</td>
              <td className="vendorListInnerTableHeading">Order Qty</td>
              {tab === "approvedSo" || tab === "closedSo" ? (
                <td className="vendorListInnerTableHeading">Outward Qty</td>
              ) : null}
              <td className="vendorListInnerTableHeading">Unit Price</td>
              <td className="vendorListInnerTableHeading">Discount (%)</td>
              <td className="vendorListInnerTableHeading">Tax (%)</td>
              <td className="vendorListInnerTableHeading">Net Price</td>
              {tab === "soReturnList" ? (
                <React.Fragment>
                  <td className="vendorListInnerTableHeading">
                    Return Quantity
                  </td>
                  <td className="vendorListInnerTableHeading">Paid Amount</td>
                </React.Fragment>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr className="vendorListInnerTable" key={row.soLineItemId}>
                  <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                  <td className="vendorListInnertableInfo">{row.itemCode}</td>
                  {isLogged.showHideWeight ? (
                    <>
                      <td className="vendorListInnertableInfo">
                        {row.from ? row.from : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.to ? row.to : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.netWeight ? row.netWeight : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.grossWeight ? row.grossWeight : " - "}
                      </td>
                    </>
                  ) : null}
                  <td className="vendorListInnertableInfo">
                    {row.hsnCode ? row.hsnCode : "-"}
                  </td>
                  <td className="vendorListInnertableInfo">{row.uom}</td>
                  <td className="vendorListInnertableInfo">{row.quantity}</td>
                  {tab === "approvedSo" || tab === "closedSo" ? (
                    <td className="vendorListInnertableInfo">
                      {row.outwardQuantity !== null ? row.outwardQuantity : "-"}
                    </td>
                  ) : null}
                  <td className="vendorListInnertableInfo">{row.unitPrice}</td>
                  <td className="vendorListInnertableInfo">
                    {row.discount !== null ? row.discount : "-"}
                  </td>
                  <td className="vendorListInnertableInfo">{row.tax}</td>
                  <td className="vendorListInnertableInfo">{row.netPrice}</td>
                  {tab === "soReturnList" ? (
                    <React.Fragment>
                      <td className="vendorListInnertableInfo">
                        {row.returnQuantity}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.payAmount}
                      </td>
                    </React.Fragment>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    );
  };

const renderPagination=()=>{
  switch(poInfo.soTab){
    case "soList":
       return(soList.length >0   ?  <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={soList.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      /> : null);

      case "approvedSoList":
        return(
          soApprovedList.length >0   ?  <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={soApprovedList.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          /> : null
        );

      case "declinedSoList":
       return(soDeclinedList.length >0   ?  <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={soDeclinedList.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      /> : null);

      case "salesOrderReturn":
        return(
          soReturnUnapprovedList.length >0   ?  <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={soReturnUnapprovedList.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        /> : null
        );

      case "salesOrderReturnApproved":
        return(
          soReturnApprovedList.length >0   ?  <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={soReturnApprovedList.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        /> : null);

      case "salesOrderClosed":
        return(
          soClosedList.length >0   ?  <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={soClosedList.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        /> : null);

      default:
        return null;

  }
}

  return (
    <React.Fragment>
      <div className="poListTabs">
        <RenderTabList tablist={tablist} />
      </div>
      <div style={{ display:"inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setPlantId(value);
          }}
          inputType={"options"}
          value={plantId}
          hintText={"Plant Name"}
          options={isLogged.plantOptions}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setCustomerId(value);
          }}
          inputType={"options"}
          options={customerOptions}
          value={customerId}
          hintText={"Customer Name"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setFromOrderDate(value);
          }}
          inputType={"dateFromEditPage"}
          value={fromOrderDate}
          hintText={"From Date"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setToOrderDate(value);
          }}
          inputType={"dateFromEditPage"}
          value={toOrderDate}
          hintText={"To Date"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
      </div>
      <div className="vendorListArea">{renderSubSection(poInfo.soTab)}</div>
      {renderPagination()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
      viewSoDetailId : (soId)=>dispatch(viewSoDetailId(soId)),
    viewSalesOrderId: (viewId) => dispatch(viewSoId(viewId)),
    editSalesOrderId: (viewId) => dispatch(editSoId(viewId)),
    viewSalesOrderReturnId: (viewId) => dispatch(viewSoReturnId(viewId)),
    editSalesOrderReturnId: (viewId) => dispatch(editSoReturnId(viewId)),

    setSalesOrderTab: (tab) => dispatch(setSoTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderList);
