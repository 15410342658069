import React, { useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import salePriceIcon from "../../../assets/saleprice_black.svg";
import { useHistory } from "react-router";
import CreateSalesPrice from "./CreateSalesPrice";
import ItemSalesPriceList from "./ItemSalesPriceList";
import EditSalesPrice from "./EditSalesPrice";
import { connect } from "react-redux";

const ItemSalesPrice = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("itemSalesPriceList");

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemSalesPriceList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemSalesPriceButton = {
        buttonName: "Create Sales Price",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createSalesPrice",
    };

    const renderSection = (section) => {
        switch (section) {
            case "itemSalesPriceList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salePriceIcon}
                            pageTitle="Item Sales Price List"
                            buttonList={
                                isLogged.access["Item Sales Price"] === "Create" || isLogged.access["Item Sales Price"] === "Edit"
                                    ? [createItemSalesPriceButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemSalesPriceList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createSalesPrice":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salePriceIcon} pageTitle="Create Sales Price" buttonList={goBackButton} />
                        <CreateSalesPrice setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editSalesPrice":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salePriceIcon} pageTitle="Edit Sales Price" buttonList={goBackButton} />
                        <EditSalesPrice setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}{" "}
                </div>
            </div>{" "}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemSalesPrice);
