import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";

const EditRoleControl = ({
    setSection,

    // state
    isLogged,
    roleInfo,

    // dispatch
    addTokenToState,
}) => {
    useEffect(() => {

        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getSubordinateRoles(roleInfo.editRoleControlId.role_id);
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
        
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getSubordinateRoles(roleId) {
        var data = await fetchData({
            requestingPage: "roleList",
            method: "get",
            url: `fetch/role-control-pre-create-subordinates/${roleId}`,
            headers: { token: isLogged.accessToken,module : "Role Control" },
        });
        if (data.msg === "success") {
            setSubordinateRoleList(data.asset);

            var tempSub = [];
            roleInfo.editRoleControlId.controls.forEach((control) => {
                tempSub.push(control.reporteeRoleId);
            });
            setselectedSubordinates(tempSub);
        } else {
            console.log(data);
        }
    }

    const [selectedBossRole] = useState(roleInfo.editRoleControlId);

    var [subordinateRoleList, setSubordinateRoleList] = useState([]);
    const [selectedSubordinates, setselectedSubordinates] = useState([]);

    const addSubordinates = (subOrdinate, checked) => {
        if (checked) {
            setselectedSubordinates([...selectedSubordinates, subOrdinate.role_id]);
        } else {
            var tempSelectedSubordinate = selectedSubordinates.filter((id) => id !== subOrdinate.role_id);
            setselectedSubordinates(tempSelectedSubordinate);
        }
    };


    useEffect(() => {
        if(subordinateRoleList.length>0)
        renderSubordinateRoleUnits();
        // eslint-disable-next-line
    }, [subordinateRoleList]);

    const renderSubordinateRoleUnits = () => {
        return subordinateRoleList.map((role) => {
            if (role.role_id !== selectedBossRole.role_id) {
                return (
                    <div className="controlRoleUnit">
                        <input
                            checked={selectedSubordinates.includes(role.role_id) ? true : false}
                            type="checkbox"
                            className="controlRoleCheckBox"
                            onChange={(e) => {
                                addSubordinates(role, e.target.checked);
                            }}
                        />
                        <span className="controlRoleDescription">{role.role}</span>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    async function submitNewRoleControl() {
        var dataToServer = [];
        selectedSubordinates.forEach((item) => {
            var tempObj = { boss_role_id: selectedBossRole.role_id, reportee_role_id: item };
            dataToServer.push(tempObj);
        });

        var dataSentResult = await fetchData({
            requestingPage: "editRole",
            method: "put",
            url: "edit/role-control-role",
            headers: { token: isLogged.accessToken,module : "Role Control" },
            data: dataToServer,
        });
        if (dataSentResult.msg === "success") {
            setSection("roleControl");
        } else {
            window.alert("Please choose atleast one role to submit");
       
        }
    }

    return (
        <React.Fragment>
            <div className="createRoleControlRole">
                <div className="bossRole">
                    <span className="hintText2">Role</span>
                    <label className="bossRoleDropdown">
                        <div className="bossRoleDisplay"> {selectedBossRole.role}</div>
                    </label>
                </div>
                <div className="reporteeRole">
                    <span className="hintText2">Control Roles</span>
                    <div className="controlRolesList">{renderSubordinateRoleUnits()}</div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerAreaRoles">
                    <p className="formSubmitErrorTextArea">&nbsp;</p>
                    <button
                        className="cancelButton"
                        onClick={() => {
                            setselectedSubordinates([]);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            submitNewRoleControl();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleControl);
