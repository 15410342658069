import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import TopBanner from "../SmallComponents/TopBanner";
import MRSIcon from "../../assets/mrs.svg";
import PageTitle from "../SmallComponents/PageTitle";
import IdnPendingList from "./IdnPendingList";
import IdnPendingDetail from "./IdnPendingDetail";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const IdnPending = () => {
    const [currentSection, setCurrentSection] = useState("IdnPendingList");

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "IdnPendingList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF({}, "mrsPendingDetail") }, sectionName: "IdnPendingList" };

    const renderSection = (section) => {
        switch (section) {
            case "IdnPendingList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={MRSIcon} pageTitle="IDN Pending, MRS (Approved)" />
                        <IdnPendingList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "IdnPendingDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={MRSIcon} pageTitle={"Pending MRS Detail"} buttonList={[goBackButton, downloadButton]} />
                        <IdnPendingDetail setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Pending MRS" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

export default IdnPending;
