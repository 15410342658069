import React, { useEffect, useState,useRef } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import deleteIcon from "../../assets/delete.svg";

const EditCustomerReceipt=({isLogged,addTokenToState,setSection,poInfo,editApprove})=>{

    const [customerReceiptParams,setCustomerReceiptParams] = useState({
        orderType:{
            inputType: "text",
            value:"Sales Order",
            hintText:"Order Type",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter Order Type",
            disabled: true
        },
        customerId:{
            inputType:"text",
            value:"",
            hintText:"Customer Name",
            mandatory: true,
            colSpan:5,
            error: false,
            errorMessage : "Please Select a Customer",
            disabled: true
        },
        paymentDate:{
            inputType:"dateFromEditPage",
            value: "",
            hintText: "Payment Date",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter Payment Date",
        },
        paymentType:{
            inputType: "options",
            value:"",
            options:[
                {optionId:"Cash",optionName:"Cash"},
                {optionId:"Online",optionName:"Online"},
                {optionId:"Cheque",optionName:"Cheque"},
                {optionId:"Debit/Credit Card",optionName:"Debit/Credit Card"},
            ],
            hintText:"Payment Type",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Please Select a Payment Type",
        },
        paymentAmount:{
            inputType: "number",
            value:"",
            hintText:"Payment Amount",
            mandatory: true,
            colSpan:  4,
            error: false,
            errorMessage:"Enter Valid Payment Amount",
        },
        paidToPlant: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Paid To Plant",
            colSpan: 4,
            mandatory: false,
        },
        paidToAccount: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Bank Account",
            colSpan: 4,
            mandatory: false
        }
    });
    const [paymentAmount,setPaymentAmount]=useState(0);
    const [invoiceParams,setInvoiceParams] = useState({
        lineId:"",
        receiptId:"",
        invoiceNo:{
            inputType:"options",
            options:[],
            value:"",
            hintText:"Invoice No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Select a Invoice Number",
        },
        salesOrderNo:{
            inputType: "text",
            value:"",
            hintText:"Sales Order No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Sales Order No can't be Empty",
            disabled: true,
        },
        totalAmount:{
            inputType: "text",
            value:"",
            hintText:"Total Amount",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Total Amount can't be Empty",
            disabled: true,
        },
        oldInvoiceBalance:{
            inputType: "text",
            value: "",
            hintText:" Old Invoice Balance",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Balance Amount can't be Empty",
            disabled: true,
        },
        invoiceBalance:{
            inputType: "text",
            value: "",
            hintText:"Invoice Balance",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Balance Amount can't be Empty",
            disabled: true,
        }
    });
    let remainingBalance=0;
    const [lineItems,setLineItems]=useState([]);
    const history=useHistory();
    const [showCustomerDetail,setShowCustomerDetail] = useState(false);
    const [notes,setNotes] = useState("");
    const [errors,setErrors] = useState([]);
   // const [openBalance,setOpenBalance] = useState(0);
    const [netTotal,setNetTotal] = useState(0);
    const [cusRecDetail,setCusRecDetail]=useState({});
    const [totalInvoiceBalance,setTotalInvoiceBalance]=useState(0);

    
    useEffect(()=> {getInformation()},[]);

    async function getInformation(){
        await checkToken();
        var receiptDetail=await getCustomerReceiptDetail();
        var bankList = await getBankListOptions();
        let paramsCopy=Object.assign(customerReceiptParams);

        let initialAccountOption = bankList.filter((row)=>row.plantId==receiptDetail.paidToPlant)[0];
        initialAccountOption = initialAccountOption.bankList;
        paramsCopy=update(paramsCopy,{ customerId:{value: {$set: receiptDetail.customerName} },
                                        paymentDate : {value: {$set: receiptDetail.paymentDate}},
                                        paymentType: {value: {$set: receiptDetail.paymentType}},
                                        paymentAmount: {value: {$set: receiptDetail.paymentAmount}},
                                        paidToPlant: {options: {$set: bankList},value: {$set: receiptDetail.paidToPlant} },
                                        paidToAccount: {options: {$set: initialAccountOption},value: {$set: receiptDetail.paidToAccount} }
                                  });
        setCustomerReceiptParams(paramsCopy);
    }
    async function getBankListOptions(){
        var response = await fetchData({
            requestingPage: "venPaymentList",
            method: "get",
            url: 'po-fetch/bank-options-list',
            headers: {token: isLogged.accessToken, module: "Customer Receipt"}
        });

        if(response.msg='success'){
           return response.asset;
        }else{
            // console.log(response);
            return [];
        }
    }
    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };

    async function getCustomerReceiptDetail(){
        var result= await fetchData({
            requestingPage:"cusReceiptDetail",
            method: "get",
            url: "so-fetch/cus-receipt-detail/" + poInfo.editCusRecId,
            headers: {token: isLogged.accessToken, module : "Customer Receipt"}
        });

        if(result.msg==="success"){
            setCusRecDetail(result.asset[0]);
           
            setShowCustomerDetail(true);
            // setOpenBalance(result.asset[0].openBalance);
            setNotes(result.asset[0].notes);
            setLineItems(result.asset[0].lineItems);
            remainingBalance=result.asset[0]["paymentAmountBalance"];
            setPaymentAmount((result.asset[0]["paymentAmountBalance"] ==0 ? NaN : result.asset[0]["paymentAmountBalance"] ));
            return  result.asset[0];
        }else{
            console.log(result);
        }
    };
    
    const filterByKeyValue = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
           return !arr2.find(element => {
              return element.invoiceNo == el.invoiceNo;
           });
        });
        return res;
     };

    useEffect(async()=>{
        if(customerReceiptParams.customerId.value!="" && lineItems.length>0){
                var invoices=await fetchData({
                    requestingPage: "customerList",
                    method: "post",
                    url: "so-fetch/sales-invoice-approved-list",
                    headers: { token: isLogged.accessToken,module: "Customer Receipt" },
                });
        
                if(invoices.msg==="success"){
                    let invoiceList=[];
                    invoices.asset=invoices.asset.filter((invoice)=>invoice.oldInvoiceBalance!=0);
                    invoices.asset=invoices.asset.filter((invoice)=>invoice.customerId===cusRecDetail.customerId);
                    invoices.asset.forEach(({invoiceNo,soNo,totalAmount,customerId,oldInvoiceBalance},i)=>
                        invoiceList.push({optionId:invoiceNo,optionName:invoiceNo,invoiceNo,salesOrderNo:soNo,totalAmount:parseInt(totalAmount),customerId,
                            lineId:"",receiptId:"",oldInvoiceBalance: oldInvoiceBalance || parseInt(totalAmount)}));
                    invoiceList=filterByKeyValue(invoiceList,lineItems);
                    let invoiceCopy = Object.assign(invoiceParams);

                    invoiceCopy = update(invoiceCopy,{invoiceNo: {options: {$set: invoiceList} } });

                    setInvoiceParams(invoiceCopy);
                }
        }
    },[customerReceiptParams.customerId.value]);

    useEffect(()=>{
        let invoiceParamsCopy = Object.assign(invoiceParams);
        if(invoiceParams.invoiceNo.value!==""){
            let selectedInvoice = invoiceParamsCopy.invoiceNo.options.filter((row)=> row.invoiceNo==invoiceParamsCopy.invoiceNo.value)[0];
            
            remainingBalance=selectedInvoice["oldInvoiceBalance"]-paymentAmount;
            if(remainingBalance < 0){
                selectedInvoice["invoiceBalance"] = 0;
                setPaymentAmount((remainingBalance*-1));
            } else{
                selectedInvoice["invoiceBalance"] = parseInt(remainingBalance);
                setPaymentAmount(NaN)
            }
            // if(remainingBalance < 0){
            //     setPaymentAmount((remainingBalance*-1));
            // } else{
            //     setPaymentAmount(NaN);
            // }

            //console.log("rrr",remainingBalance,paymentAmount);
            invoiceParamsCopy = update(invoiceParamsCopy,{ salesOrderNo: { value: {$set: selectedInvoice.salesOrderNo} },
                                                            totalAmount: {value: {$set: selectedInvoice.totalAmount} },
                                                            oldInvoiceBalance: {value: {$set: selectedInvoice.oldInvoiceBalance}},
                                                            invoiceBalance: {value: {$set: selectedInvoice.invoiceBalance}},
                                                            lineId: {$set: selectedInvoice.lineId},
                                                            receiptId: {$set: selectedInvoice.receiptId}
                                                        });
            setInvoiceParams(invoiceParamsCopy);
            //console.log("rrr",remainingBalance,paymentAmount);
        } else {
            invoiceParamsCopy= update(invoiceParamsCopy,{ salesOrderNo: {value : {$set : "" } },totalAmount: {value: {$set: ""} },
                                                        oldInvoiceBalance: {value: {$set: ""}},invoiceBalance: {value: {$set: ""}} 
                                                    });
            setInvoiceParams(invoiceParamsCopy);
        }
    },[invoiceParams.invoiceNo]);

    const addInvoiceToLine=()=>{
        var lineCopy=lineItems.slice();
        var errorList=[]
        let invoiceCopy = Object.assign(invoiceParams);
        ["invoiceNo","salesOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"].map((item)=>{
            if(invoiceParams[item].value==="" || invoiceParams[item].value === null){
                invoiceParams[item].error=true;
                errorList.push("Provide Valid Invoice Details");
            }
        })
        //console.log("@add",remainingBalance,paymentAmount);

        if(isNaN(invoiceCopy["invoiceBalance"].value)){
            invoiceCopy["invoiceBalance"].error=true;
            errorList.push("Payment Amount Insufficient To add This Invoice");
        };
        
        if(errorList.length===0){
            lineCopy.push({invoiceNo: invoiceCopy.invoiceNo.value,salesOrderNo: invoiceCopy.salesOrderNo.value, totalAmount: invoiceCopy.totalAmount.value,
                lineId: invoiceCopy.lineId, receiptId: invoiceCopy.receiptId,oldInvoiceBalance: invoiceCopy.oldInvoiceBalance.value,invoiceBalance: invoiceCopy.invoiceBalance.value});
            var trimmedList = invoiceCopy.invoiceNo.options.filter((item) => item.optionId !== invoiceCopy.invoiceNo.value);
            invoiceCopy = update(invoiceCopy,{invoiceNo: {value: {$set: ""},options: {$set: trimmedList} },
                                             salesOrderNo : {value: {$set: ""} },
                                             totalAmount: {value: {$set: ""} },
                                             oldInvoiceBalance: {value: {$set: ""}},
                                             invoiceBalance: {value: {$set: ""},error: {$set: false} } 
                                            });
            setInvoiceParams(invoiceCopy);
            setLineItems(lineCopy);
        }else{
            setErrors(errorList);
        }
    };

    const editSubSectionItem=(rowIndex,action)=>{
        let invoiceCopy = Object.assign(invoiceParams);
        let lineCopy = lineItems.slice();
        if(action === "delete"){
            var deletedObj=lineCopy.splice(rowIndex,1)[0];
            deletedObj.optionId = deletedObj.invoiceNo;
            deletedObj.optionName = deletedObj.invoiceNo; 
            
            let deductedAmount=deletedObj["oldInvoiceBalance"]-deletedObj["invoiceBalance"];
            // console.log("deduce",deductedAmount);
            setPaymentAmount(+deductedAmount);
            remainingBalance=(-(remainingBalance)-deletedObj["invoiceBalance"]);
            deletedObj["invoiceBalance"]= 0;

            var invoiceOptions= invoiceParams.invoiceNo.options.slice();
            invoiceOptions.push(deletedObj);
            invoiceCopy = update(invoiceCopy,{invoiceNo: {options: {$set: invoiceOptions} } });
            setInvoiceParams(invoiceCopy);
            setLineItems(lineCopy);
        }
    };

    const RenderTableRows=({data})=>{
        return data.map((row,i)=>{
            return ( 
                <tr className="createVendorContactsTableRows" key={i}>
                    {["invoiceNo","salesOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"].map((col,j)=>{
                        return <td key={j}>{row[col]}</td> 
                    })}
                    <td className="itemIndiTabActions">
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    editSubSectionItem(i, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
            ) 
        });
    };

    const RenderTable=()=>{
        return (
                <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Invoice  No</td>
                        <td>Sales Order No</td>
                        <td>Invoice Amount</td>
                        <td>Old Invoice Balance</td>
                        <td>Invoice Balance</td>
                        <td className="itemIndiTabActions">Actions</td>
                    </tr>
                </thead>
                    {lineItems.length>0 ?
                    (<tbody>
                        <RenderTableRows data={lineItems}/>
                    </tbody> ) :
                    (<tbody>
                        <tr className="createVendorContactsTableRows">
                        {["","","","","",""].map((item,i)=>{
                            return <td key={i}>&nbsp;</td>;
                                })}
                    </tr>
                    </tbody>)}
                </table>
                );
    };

    const renderSubSection=()=>{
        return(
            <React.Fragment>
                 <div className="purchaseOrderIndividualItemsArea">
                    { renderFormElements({ elementList: ["invoiceNo","salesOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"], param: invoiceParams, section: "invoice" })}
                    <FormElement
                        inputType="addButton"
                        value="+ Add"
                        colSpan={4}
                        setInput={() => {
                            setErrors([]);
                            addInvoiceToLine();
                        }}
                    />

                    </div>
                    <RenderTable />
            </React.Fragment>
        )
    };

    const RenderCustomerDetail=()=>{
        return (
            <React.Fragment>
                <div className="detailTitle">{`${cusRecDetail.customerId}-${cusRecDetail.customerName}`}</div>
                <div className="detailText">
                   <b>Address:</b> {cusRecDetail.address},
                    <br /> {cusRecDetail.city}
                    {cusRecDetail.zipCode  ? `, - ${cusRecDetail.zipCode}` : null}
                    {cusRecDetail.mobile   ? <br /> : null}
                    {cusRecDetail.mobile  ? <b>Phone: </b> : null}
                    {cusRecDetail.mobile ? cusRecDetail.mobile : null}
                    {cusRecDetail.email  ? <><br /> <b>Email Id:</b></> : null}
                    {cusRecDetail.email  ?  <>{cusRecDetail.email}</> : null}
                    {cusRecDetail.gstNo  ? <><br /> <b>GST No:</b></> : null}
                    {cusRecDetail.gstNo ? ` ${cusRecDetail.gstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const updateParameters=({section,param,key,value,wholeObject})=>{
        if(section==="general"){
            let paramsCopy=Object.assign(customerReceiptParams);
            if(param==="paymentAmount")
            setPaymentAmount(value);

            paramsCopy=update(paramsCopy,{[param]:{[key]: {$set: value} } });

            if(param==='paidToPlant'){
                if(value)
                paramsCopy = update(paramsCopy,{['paidToAccount']: {['options']: {$set: wholeObject.bankList} } })
                else
                paramsCopy = update(paramsCopy,{['paidToAccount']: {['options']: {$set: [] },['value']:{$set: "" }} })
            }

            setCustomerReceiptParams(paramsCopy);
        }else if(section==="invoice"){
            let invoiceCopy = Object.assign(invoiceParams);
            invoiceCopy=update(invoiceCopy,{[param]:{[key]:{$set: value} } });
            setInvoiceParams(invoiceCopy);
        }
    };
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }
      const pAValue= usePrevious(customerReceiptParams.paymentAmount.value);
    useEffect(()=>{
        let invoiceCopy = Object.assign(invoiceParams);
        
        if(pAValue!==customerReceiptParams.paymentAmount.value && pAValue!==""){
            //setPaymentAmount(customerReceiptParams.paymentAmount.value);
            remainingBalance=paymentAmount;
            //console.log("@paid",remainingBalance,paymentAmount);
            let invoiceOptionsCopy=invoiceParams.invoiceNo.options.slice();
            invoiceOptionsCopy.push(...lineItems);
            setLineItems([]);
            invoiceCopy = update(invoiceCopy, {invoiceNo : {value: {$set: ""},options: {$set: invoiceOptionsCopy} },
                                               salesOrderNo :{value :{$set  :""} },
                                                totalAmount:{value: {$set: ""} },
                                                oldInvoiceBalance: {value: {$set: ""}},
                                                invoiceBalance: {value: {$set: ""} }  });
            setInvoiceParams(invoiceCopy);
        }
         if(customerReceiptParams.paymentAmount.value===""){
             setLineItems([]);
             invoiceCopy = update(invoiceCopy, {invoiceNo : {value: {$set: ""} },
                                               salesOrderNo :{value :{$set  :""} },
                                                totalAmount:{value: {$set: ""} },
                                                oldInvoiceBalance: {value: {$set: ""}},
                                                invoiceBalance: {value: {$set: ""} }  });
            setInvoiceParams(invoiceCopy);
         }
    },[customerReceiptParams.paymentAmount])

    const renderFormElements=({elementList,param,section})=>{
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value,wholeObject) => {
                        updateParameters({ section, param: element, key: "value", value: value ,wholeObject});
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                />
            );
        });
    };

    useEffect(()=>{
        if(lineItems.length>0){
        let totalInvoiceTemp=0;
        lineItems.forEach((row)=>{
            totalInvoiceTemp+=parseInt(row.oldInvoiceBalance);
        });
        setNetTotal(totalInvoiceTemp);
        let totalInvoiceBalanceTemp = lineItems[lineItems.length-1]["newInvoiceBalance"];
        setTotalInvoiceBalance(totalInvoiceBalanceTemp);
    }
    },[lineItems.length]);

    const renderErrorMessage=()=>{
        if(errors.length>0)
        return errors[0]
    };

    const checkErrors=()=>{
        var errorList=[];
        let cusParamCopy=Object.assign(customerReceiptParams);
        Object.keys(cusParamCopy).map((item)=>{
            if(cusParamCopy[item].mandatory)
            cusParamCopy[item].error= !validateMandatory(cusParamCopy[item].value.toString());
            if(cusParamCopy[item].error){
                errorList.push(cusParamCopy[item].errorMessage);
            }
        });

        let invoiceParamsCopy= Object.assign(invoiceParams); 

        ["invoiceNo","salesOrderNo","totalAmount"].map((item,i)=>{
            if(invoiceParamsCopy[item].value!=="")
                errorList.push("Unsaved Invoice Record Found!");
        })

        let lineItemsCopy = lineItems.slice();
        lineItemsCopy.forEach((row)=>{
            for (let item in row){
                if(row[item]==null || row[item]==undefined)
                errorList.push("Invalid Invoice Item Found");}
            });
        // if(!parseInt(openBalance) && openBalance!==0)
        //     errorList.push("Provide Valid Open Balance");

        if(!parseInt(paymentAmount) && paymentAmount!==0 && !isNaN(paymentAmount))
        errorList.push("Provide valid Payment Amount");

        if(lineItems.length<1)
        errorList.push("Select Invoice No");

        if(errorList.length===0 && lineItems.length>0)
        {
            var dataToServer={
                receiptId: cusRecDetail.receiptId,
                customerId: cusRecDetail.customerId,
                paymentDate: cusParamCopy.paymentDate.value,
                paymentType: cusParamCopy.paymentType.value,
                //openBalance: openBalance,
                paymentAmount: cusParamCopy.paymentAmount.value || null,
                paymentAmountBalance: paymentAmount || 0,
                paidToPlant: cusParamCopy.paidToPlant.value || null,
                paidToAccount: cusParamCopy.paidToAccount.value || null,
                notes:notes,
                netTotal:netTotal
            };
            dataToServer.lineItems=lineItemsCopy;
            //console.log("Error Free",dataToServer);
            submitData(dataToServer);
        }else{
            setCustomerReceiptParams(cusParamCopy);
            setLineItems(lineItemsCopy);
            setErrors(errorList);
        }

    };

    async function approveReceipt(){
        setIsOpen(true);
        setDataSubmitted(false);
        setModalText("Approving Customer Receipt. Please Wait...");
        var result = await fetchData({
            requestingPage: "approveCusReceipt",
            method: "put",
            url : "so-edit/approve-customer-receipt/"+poInfo.viewCusRecId,
            headers: {token: isLogged.accessToken,module: "Customer Receipt"}
        });
        setDataSubmitted(true);
        if(result.msg==="success"){
            setModalText("Customer Receipt Approved");
        }else{
            setModalText(result.desc);
            setErrors([result.desc]);
        }
    };


    async function submitData(dataToServer){
        setIsOpen(true);
        setModalText("Editing Customer Receipt... Please Wait...")
         var result = await fetchData({
             requestingPage: "createPoItem",
             url: "so-edit/edit-customer-receipt",
             method: "put",
             headers: {token: isLogged.accessToken, module: "Customer Receipt"},
             data: dataToServer
         });
         if(result.msg==="success"){
             if(editApprove)
              approveReceipt();
                setDataSubmitted(true);
             setModalText("Customer Receipt Edited SuccessFully");
         }else{
             setDataSubmitted(true);
             setModalText(result.desc);
             setErrors([result.desc]);
         }
     };

     const [modalIsOpen, setIsOpen] = useState(false);
     const [dataSubmitted, setDataSubmitted] = useState(false);
     const [modalText, setModalText] = useState("Editing Customer Receipt. Please wait...");

     const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
            setSection("receiptList");
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
            <div
                    style={{
                        width: "fit-content",
                        margin: "0 auto 2rem",
                        // padding: "3rem 3rem 1rem 3rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}>
                    <div style={{ display: "flex" }}>
                        <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                            {renderFormElements({ elementList: Object.keys(customerReceiptParams), param: customerReceiptParams, section: "general" })}
                        </div>
                        <div style={{ width: "260px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCustomerDetail ? <RenderCustomerDetail /> : null}</div>
                            </div>
                        </div>
                    </div>

                    <div className="purchaseOrderSubSectionArea">{renderSubSection()}</div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div style={{ marginTop: "10px" }}>Notes</div>
                            <textarea
                                className="multilineInput"
                                value={notes}
                                rows="6"
                                cols="30"
                                name="text"
                                placeholder="Enter your notes"
                                style={{
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                             <div className="poTotalTitle">
                                <span>
                                    <b>Total Invoice Amount</b>
                                </span>
                                <span className="poNetTotalAmount">{netTotal}</span>
                             </div>
                             <div className="poTotalTitle">
                                <span>
                                    <b>Total Invoice Balance</b>
                                </span>
                                <span className="poNetTotalAmount">{isNaN(totalInvoiceBalance) ? 0 : totalInvoiceBalance}</span>
                             </div>
                             <div className="poTotalTitle">
                                <span>
                                    <b>Payment Amount Balance</b>
                                </span>
                                <span className="poNetTotalAmount">{isNaN(paymentAmount) ? 0 : paymentAmount}</span>
                             </div>
                            {/* <div className="poFormElement">
                                <div className="formElement">
                                    <FormHint hintText="Open Balance" mandatory={true} />
                                    <InputField
                                        value={openBalance}
                                        setName={(value) => setOpenBalance(parseInt(value) || 0)}
                                        align="end"/>
                                </div> 
                            </div> */}
                            </div>
                        </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button className="submitButton" onClick={() => {setErrors([]);checkErrors();}}>
                        {editApprove ? "Edit & Approve" : "Submit to Edit"}
                    </button>
            </div>
            </div>
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      poInfo: state.poInfo};
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerReceipt);