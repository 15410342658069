import React, { Fragment, useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import editIcon from "assets/edit.svg";
import { editSectionMaster } from "redux/Item/ItemInfoActions";
import CreateEditModal from "components/SmallComponents/CreateEditModal";

const EditSectionMaster = ({
  isLogged,
  editSectionMaster,

  itemInfo,
  setSection,
  addTokenToState
}) => {
  const [sectionMasterList, setSectionMasterList] = useState([]);
  const [premiumChargesList, setPremiumChargesList] = useState([]);
  const [updatedSectionMaster, setUpdatedSectionMaster] = useState({});

  const history = useHistory();
  const checkToken = useCallback(async () => {
   
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
     
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }, [isLogged, history, addTokenToState]);

  const getSectionList = useCallback(async () => {
    var data = await fetchData({
      requestingPage: "sectionMaster",
      method: "post",
      url: "fetch/section-master",
      headers: { token: isLogged.accessToken, module: "Section Master" },
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        console.log(data);
      } else {
        setSectionMasterList(data.asset);
        let toEdit = data.asset.filter((row)=>row.sectionId === itemInfo.sectionMasterId)[0];
        setUpdatedSectionMaster(toEdit);
      }
    } else {
      console.log(data);
    }
   
  }, [isLogged,itemInfo.sectionMasterId])

  
  const getPremiumCharges=useCallback(async()=> {
    var data = await fetchData({
        requestingPage: "premiumCharges",
        method: "get",
        url: "fetch/premium-charges",
        headers: { token: isLogged.accessToken,module:"Section Master" },
    });
    if (data.msg === "success") {
        setPremiumChargesList(data.asset);
    } else {
        console.log(data);
    }

},[isLogged]);

  const getInformation = useCallback(
    async () => {
      await checkToken();
      await getSectionList();
      await getPremiumCharges();
    },
    [checkToken, getSectionList, getPremiumCharges]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const submitUpdatedSectionMaster = useCallback(async () => {

    var dataSent = await fetchData({
      requestingPage: "editUom",
      method: "put",
      url: "edit/section-master/"+itemInfo.sectionMasterId,
      headers: { token: isLogged.accessToken, module: "Section Master" },
      data: updatedSectionMaster,
    });

    setDataSubmitted(true);
    if (dataSent.msg === "success") {
      editSectionMaster(null);
      setModalText("Saved UOM change successfully!");
      setUpdatedSectionMaster({});
      getInformation();
    } else {
      setModalText(dataSent.desc);
    }
  }, [isLogged, editSectionMaster, getInformation, updatedSectionMaster,itemInfo.sectionMasterId]);

  const renderOptions = (options) => {
    return (
        <React.Fragment>
            {options.map((option) => {
                return (
                    <option
                        key={option.optionId}
                        // style={{ height: "3rem", margin: "10px", padding: "10px 30px" }}
                        value={option.optionId}
                        selected={option.optionId === updatedSectionMaster.premiumId ? true : false}
                    >
                        {option.optionName}
                    </option>
                );
            })}
        </React.Fragment>
    );
};
  const renderSectionMasterEditTable = () => {
    return (
      sectionMasterList.map((row, index) => {
        if (row.sectionId === itemInfo.sectionMasterId) {
          return (
            <tr className="uomListData" key={index}>
              <td className="uomSymbolData2">
                <input
                  className="createUom"
                  value={updatedSectionMaster.sectionNo || ""}
                  onChange={(e) => setUpdatedSectionMaster({ ...updatedSectionMaster, sectionNo: e.target.value })}
                  autoFocus
                />
              </td>
              <td className="uomSymbolData2">
                <input
                  className="createUom"
                  value={updatedSectionMaster.sectionName || ""}
                  onChange={(e) => setUpdatedSectionMaster({ ...updatedSectionMaster, sectionName: e.target.value })}
                />
              </td>
              <td className="uomSymbolData2">
                <select
                    defaultValue={""}
                        onChange={(e) => {
                          setUpdatedSectionMaster({ ...updatedSectionMaster, premiumId: Number(e.target.value) })
                        }}
                    >
                        <option value="" disabled>Choose a rate..</option>
                        {renderOptions(premiumChargesList)}
                </select>
              </td>
             <td className="uomSymbolData2" >
                <button
                  style={{ width: "7rem" }}
                  className="cancelRoleButton"
                  onClick={() => {
                    editSectionMaster(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ width: "7rem" }}
                  id="SubmitCreate"
                  className="createRoleButton"
                  onClick={() => {
                    if(updatedSectionMaster.sectionId && updatedSectionMaster.sectionName)
                    submitUpdatedSectionMaster();
                  }
                  }
                >
                  Save
                </button>
              </td>
            </tr>
          )
        } else {
          return (
            <tr className='uomListData' key={index}>
              <td className="uomSymbolData">{row.sectionNo}</td>
              <td className='uomSymbolData'>{row.sectionName}</td>
              <td className='uomSymbolData'>{row.premiumId}</td>
              <td className="uomActionData">
                <img
                  className="uomEditImage"
                  alt="Edit Record"
                  src={editIcon}
                  onClick={() => {
                    editSectionMaster(row.sectionId);
                    setUpdatedSectionMaster({...row});
                  }}
                  title={"Edit Record"}
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          )
        }
      })
    )
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Updating Section Master. Please wait...");

  const submitOkClick = () => {
    setIsOpen(false);
    setModalText("Updating section Master. Please wait...");
  };
  return (
    <Fragment>
      <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
      <div className="uomListArea">
        <table className="uomListTable">
          <thead>
          <tr className="uomListHeader">
            <td className="uomSymbolHeader">
              <span>Section No</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Section Name</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Premium Id</span>
            </td>
            <td className="uomActionHeader">
              <span>Action</span>
            </td>
          </tr>
          </thead>
          <tbody>
          {renderSectionMasterEditTable()}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    itemInfo: state.itemInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editSectionMaster: (value) => dispatch(editSectionMaster(value)),
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSectionMaster);