import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const CreateInvoice = ({ isLogged, poInfo, setSection, loadSo }) => {
  const [soDetail, setSoDetail] = useState({});
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);
  const [lineItems, setLineItems] = useState([]);

  const [otherCharges, setOtherCharges] = useState("");
  const [transportCharges, settransportCharges] = useState("");
  const [transportChargesError, setTransportChargesError] = useState(false);
  const [otherChargesError, setOtherChargesError] = useState(false);

  const [netTotal, setNetTotal] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [instruction, setInstruction] = useState("");

  const [lrDocument, setLrDocument] = useState("");
  const [outwardDocument, setOutwardDocument] = useState("");

  const salesOrderParamList = [
    ["orderType", "orderNo","deliveryAddressId","dispatchedThrough","destination"],
    ["invoiceDate", "officeName"],
  ];
  const [salesOrderParams, setSalesOrderParams] = useState({
    orderType: {
      inputType: "options",
      value: "Sales",
      hintText: "Order Type",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [
        { optionId: "Sales", optionName: "Sales" },
        { optionId: "Sales Return", optionName: "Sales Return" },
      ],
      errorMessage: "Please select order type",
      disabled: true,
    },
    orderNo: {
      inputType: "options",
      value: "",
      hintText: "Order No",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select order no",
      disabled: false,
    },
    invoiceDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Invoice Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      // mindate: true,
      errorMessage: "Please Select invoice date",
    },

    officeName: {
      inputType: "options",
      value: "",
      hintText: "Office Name",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Select Office Name",
    },
    dispatchedThrough: {
      inputType: "text",
      value: "",
      hintText: "Dispatched Through",
      mandatory: false,
      error :false,
      colSpan: 6,
      errorMessage: "" 
    },
    destination: {
      inputType: "text",
      value: "",
      hintText: "Destination",
      mandatory: false,
      error :false,
      colSpan: 6,
      errorMessage: "" 
    },
    deliveryAddressId:{
      inputType: "options",
      value: "",
      hintText: "Delivery Address",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      options: []
    }
  });
  const [finalDiscount,setFinalDiscount] = useState(0);
  const [finalDiscountError,setFinalDiscountError] = useState("");
  const [showDiscount,setShowDiscount] = useState(false);
  useEffect(() => {
    getInformation();
  }, []);

  useEffect(async () => {
    let paramCopy = Object.assign(salesOrderParams);
    if (salesOrderParams.orderNo.value !== "") {

      var [detail, lineItemsCopy] = await getCustomerDetail(
        salesOrderParams.orderNo.value
      );

      setSoDetail(detail);
      setShowCustomerDetail(true);
      setNetTotal(0);
      setOtherCharges(detail.otherCharges);
      settransportCharges(detail.transportCharges);
      setTotalAmount(0);
      setLineItems(lineItemsCopy);
      setFinalDiscount(detail.finalDiscount);
        if(detail.finalDiscount)
        setShowDiscount(true);
        
      paramCopy = update(paramCopy, {
        deliveryAddressId: {options: {$set: detail.deliveryAddressOptions},value: {$set: ""} }
      });

    } else {
      // Reset all information
      setSoDetail({});
      setShowCustomerDetail(false);
      setNetTotal(0);
      setOtherCharges(0);
      settransportCharges(0);
      setTotalAmount(0);
      setFinalDiscount(0);
      setLineItems([]);
      paramCopy = update(paramCopy, {
        deliveryAddressId: {options: {$set: []},value: {$set: ""} }
      });
    }
    setSalesOrderParams(paramCopy);
  }, [salesOrderParams.orderNo.value]);

  async function getInformation() {
    var orderList = await getOrderList();
    var plantList = await getPlants();

    var paramCopy = Object.assign(salesOrderParams);

    if (loadSo) {
      var [detail, lineItemsCopy] = await getCustomerDetail(
        poInfo.soInvoiceCreateId
      );

      setSoDetail(detail);
      setShowCustomerDetail(true);
      setNetTotal(0);
      setOtherCharges(detail.otherCharges);
      settransportCharges(detail.transportCharges);
      setFinalDiscount(detail.finalDiscount);
      setTotalAmount(0);
      setLineItems(lineItemsCopy);

      paramCopy = update(paramCopy, {
        orderNo: {
          options: { $set: orderList },
          value: { $set: detail.soId },
          disabled: { $set: true },
        },
        officeName: { options: { $set: plantList } },
        deliveryAddressId: {options: {$set: detail.deliveryAddressOptions},value: {$set: ""} }
      });
    } else {
      paramCopy = update(paramCopy, {
        orderNo: { options: { $set: orderList } },
        officeName: { options: { $set: plantList } },
        deliveryAddressId: {options: {$set: []},value: {$set: ""} }
      });
    }
    setSalesOrderParams(paramCopy);
  }

  async function getOrderList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: `so-fetch/so-approved-list-mini`,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });
    if (data.msg === "success") {
      var orderList = [];
      data.asset.forEach((item) => {
        var orderObj = { optionId: item.soId, optionName: item.soNo };
        orderList.push(orderObj);
      });
      return orderList;
    }
  }

  async function getCustomerDetail(id) {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: `so-fetch/sales-invoice-pre-create/${id}`,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });
    if (data.msg === "success") {
      var lineItemsCopy = [];
      data.asset[0].lineItems.forEach((item) => {
        var newObj = Object.assign(item);
        newObj.quantitySo = item.quantity;
        newObj.unitPriceSo = item.unitPrice;
        if (item.discount === null) {
          newObj.discountSo = 0;
          newObj.discount = 0;
        } else {
          newObj.discountSo = item.discount;
        }

        newObj.quantity = "";
        newObj.netPrice = 0;

        newObj.quantityError = false;
        newObj.quantityErrorMessage = "Please Enter a Valid Quantity";

        newObj.unitPriceError = false;
        newObj.unitPriceErrorMessage = "Please Enter a Valid Unit Price";

        newObj.discountError = false;
        newObj.discountErrorMessage = "Please Enter a Valid Discount value";

        newObj.toSend = false;

        lineItemsCopy.push(newObj);
      });


      return [data.asset[0], lineItemsCopy];
    }
  }

  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });

    var plantList = [];

    if (plantsData.msg === "success") {
      plantsData.asset.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);
      });

      return plantList;
    }
  }

  useEffect(() => {

    var total =
      (parseFloat(netTotal) +
      parseFloat(otherCharges) +
      parseFloat(transportCharges)) - (showDiscount ? parseFloat(finalDiscount) : 0);
    setTotalAmount(total);
  }, [otherCharges, transportCharges,showDiscount,finalDiscount]);

  const RenderCustomerDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">{soDetail.customerName}</div>
        <div className="detailText">
          {`Customer ID: ${soDetail.customerId}`},
          <br /> {soDetail.customerAddress},
          <br /> {soDetail.customerCity}
          {soDetail.customerPinCode !== null
            ? ` - ${soDetail.customerPinCode}`
            : null}
          {soDetail.customerMobile !== null ||
          soDetail.customerPhone !== null ? (
            <br />
          ) : null}
          {soDetail.customerMobile !== null || soDetail.customerPhone !== null
            ? `Phone: `
            : null}
          {soDetail.customerMobile !== null ? soDetail.customerMobile : null}
          {soDetail.customerPhone !== null
            ? `, ${soDetail.customerPhone} `
            : null}
          {soDetail.customerEmail !== null ? <br /> : null}
          {soDetail.customerEmail !== null
            ? `Email Id: ${soDetail.customerEmail}`
            : null}
          {soDetail.customerGstNo !== null ? <br /> : null}
          {soDetail.customerGstNo !== null
            ? `GST No:  ${soDetail.customerGstNo}`
            : null}
        </div>
      </React.Fragment>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (errorList.length === 0) {
      setSection("salesInvoiceList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Submitting Invoice. Please wait.."
  );

  const updateInvoiceParameter = ({ paramName, section, key, value }) => {
    if (section === "general") {
      var salesParamsCopy = Object.assign(salesOrderParams);
      salesParamsCopy = update(salesParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });

      setSalesOrderParams(salesParamsCopy);
    }
  };

  const updateLineItems = (param, index, value) => {
    var updatedList = lineItems.slice();

    updatedList = update(updatedList, {
      [index]: { [param]: { $set: value } },
    });

    var netRate = 0;
    var taxAmount = 0;
    if (
      !isNaN(updatedList[index].quantity) &&
      !isNaN(updatedList[index].unitPrice) &&
      !isNaN(updatedList[index].discount) && 
      !isNaN(updatedList[index].soOtherCharges)
    ) {
    let  netRateWithoutTax=  parseFloat(updatedList[index].quantity) *
                      (parseFloat(updatedList[index].unitPrice + (parseFloat(updatedList[index].soOtherCharges || 0))) -
                        (parseFloat(updatedList[index].discount) / 100) *
                          parseFloat(updatedList[index].unitPrice));
      taxAmount =  (parseFloat(updatedList[index].tax) / 100) * netRateWithoutTax;
      netRate = netRateWithoutTax  + taxAmount;
    }

    if (!isNaN(netRate)) {
      updatedList = update(updatedList, {
        [index]: { netPrice: { $set: netRate },taxAmount: {$set: taxAmount} },
      });
    } else {
      updatedList = update(updatedList, { [index]: { netPrice: { $set: 0 } ,taxAmount: {$set: taxAmount} } });
    }

    var newNetTotal = 0;
    updatedList.forEach((item) => {
      if (!isNaN(item.netPrice)) {
        newNetTotal = parseFloat(newNetTotal) + parseFloat(item.netPrice);
      } else {
        newNetTotal = 0;
      }
    });

    newNetTotal = newNetTotal.toFixed(2);

    var newTotalAmount =
      parseFloat(newNetTotal) +
      parseFloat(transportCharges) +
      parseFloat(otherCharges) - (showDiscount ? parseFloat(finalDiscount) : 0);

    setLineItems(updatedList);
    setNetTotal(newNetTotal);
    setTotalAmount(newTotalAmount);
  };

  const [errorList, setErrorList] = useState([]);
  const checkErrors = (submitType) => {
    var errList = [];
    var paramsCopy = Object.assign(salesOrderParams);

    salesOrderParamList[0].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errList.push(paramsCopy[item].errorMessage);
      }
    });

    salesOrderParamList[1].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errList.push(paramsCopy[item].errorMessage);
      }
    });

    if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
      errList.push("Enter a valid number for Other Charges");
      setOtherChargesError(true);
    } else {
      setOtherChargesError(false);
    }

    if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
      errList.push("Enter a valid number for Transport Charges");
      setTransportChargesError(true);
    } else {
      setTransportChargesError(false);
    }

    if (isNaN(finalDiscount) || parseInt(finalDiscount) < 0) {
      errList.push("Enter a valid number for Discount");
      setFinalDiscountError(true);
    } else {
      setFinalDiscountError(false);
    }

    var proceed = false;
    var lineItemsCopy = lineItems.slice();
    lineItemsCopy.forEach((item, i) => {
      if (item.quantity < 0 || item.quantity > item.quantitySo) {
        lineItemsCopy[i].quantityError = true;
        errList.push(lineItemsCopy[i].quantityErrorMessage);
      } else {
        lineItemsCopy[i].quantityError = false;
        item.quantity = parseFloat(item.quantity);
      }

      if (item.discount < 0 || item.discount > 100) {
        lineItemsCopy[i].discountError = true;
        errList.push(lineItemsCopy[i].discountErrorMessage);
      } else {
        lineItemsCopy[i].discountError = false;
      }

      if (item.unitPrice < 0) {
        lineItemsCopy[i].unitPriceError = true;
        errList.push(lineItemsCopy[i].unitPriceErrorMessage);
      } else {
        lineItemsCopy[i].unitPriceError = false;
      }

      if (item.netPrice > 0) {
        lineItemsCopy[i].toSend = true;
        proceed = true;
      } else {
        console.log(item.itemName, "Don't send to Server");
      }
    });

    setLineItems(lineItemsCopy);
    setSalesOrderParams(paramsCopy);
    setErrorList(errList);

    if (errList.length === 0) {
      if (proceed) {
        prepareData(lineItemsCopy, paramsCopy, submitType);
      } else {
        setDataSubmitted(true);
        setModalText("Provide quantity information for alteast one item");
        setErrorList(["Provide quantity information for alteast one item"]);
        setIsOpen(true);
      }
    }
  };

  const prepareData = (lineItemsCopy, paramsCopy, submitType) => {
    var data2Server = new FormData();

    data2Server.append("soId", soDetail.soId);
    data2Server.append("invoiceDate", paramsCopy.invoiceDate.value);
    data2Server.append("plantId", paramsCopy.officeName.value);
    data2Server.append("deliveryAddressId",paramsCopy.deliveryAddressId.value);
    data2Server.append("dispatchedThrough",paramsCopy.dispatchedThrough.value);
    data2Server.append("destination",paramsCopy.destination.value);
    
    data2Server.append("netTotal", netTotal);
    data2Server.append("transportCharges", transportCharges || 0);
    data2Server.append("otherCharges", otherCharges || 0);
    data2Server.append("totalAmount", totalAmount);
    data2Server.append("instruction", instruction);
    data2Server.append("invoiceStatus", submitType);

    if(showDiscount && parseFloat(finalDiscount) >= 0)
    data2Server.append('finalDiscount',finalDiscount);

    if (lrDocument !== "") {
      data2Server.append("lrDocument", lrDocument);
    }
    if (outwardDocument !== "") {
      data2Server.append("outwardDocument", outwardDocument);
    }

    var lineItemsToServer = [];

    var lineItemsCopy2 = JSON.parse(JSON.stringify(lineItemsCopy));

    lineItemsCopy2.forEach((item) => {
      if (item.toSend) {
        delete item.discountSo;
        delete item.discountError;
        delete item.discountErrorMessage;

        delete item.quantitySo;
        delete item.quantityError;
        delete item.quantityErrorMessage;

        delete item.unitPriceSo;
        delete item.unitPriceError;
        delete item.unitPriceErrorMessage;

        delete item.hsnCode;
        delete item.itemName;
        delete item.soId;
        delete item.toSend;
        delete item.uom;

        lineItemsToServer.push(item);
      }
    });
    data2Server.append("invoiceLineItems", JSON.stringify(lineItemsToServer));

  
    submitData(data2Server);
  };

  async function submitData(data) {
    setIsOpen(true);
    setDataSubmitted(false);
    var result = await fetchData({
      requestingPage: "createCustomer",
      method: "post",
      url: "so-create/sales-invoice",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      data: data,
    });
    // console.log(result);

    if (result.msg === "success") {
      setModalText("Invoice Created Successfully!");
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setErrorList([result.desc]);
    }
    setDataSubmitted(true);
  }

  const renderErrorMessage = () => {
    if (errorList.length > 0) {
      return errorList[0];
    } else return null;
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value}
          setInput={(value) => {
            updateInvoiceParameter({
              section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };
  const lineItemsTableCols = isLogged.showHideWeight
    ? [
        "itemName",
        "itemCode",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "hsnCode",
        "uom",
        "quantitySo",
        "quantity",
        "unitPriceSo",
        "unitPrice",
        "soOtherCharges",
        "tax",
        "taxAmount",
        "discountSo",
        "discount",
        "netPrice",
      ]
    : [
        "itemName",
        "itemCode",
        "hsnCode",
        "uom",
        "quantitySo",
        "quantity",
        "unitPriceSo",
        "unitPrice",
        "soOtherCharges",
        "tax",
        "taxAmount",
        "discountSo",
        "discount",
        "netPrice",
      ];
  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "80%",
            margin: "0 auto 2rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div className="createSalesInvoiceGrid" style={{gridTemplateRows: "repeat(2,4rem"}}>
              {renderFormElements({
                elementList: salesOrderParamList[0],
                param: salesOrderParams,
                section: "general",
              })}
            </div>
          </div>
          <div className="createSalesInvoiceInfo">
            <div className="salesInvoiceOurAddress">
              <div className="vendorSummaryGrid">
                {/* <span className="vendorSummaryCategory">Order No</span>
                                <span className="vendorSummaryValue">: {soDetail.soNo}</span> */}
                {salesOrderParams.orderNo.value !== "" ? (
                  <React.Fragment>
                    <span className="vendorSummaryCategory">Order Date</span>
                    <span className="vendorSummaryValue">
                      : {soDetail.soDate}
                    </span>

                    <span className="vendorSummaryCategory">
                      Transporter Name
                    </span>
                    <span className="vendorSummaryValue">
                      :{" "}
                      {soDetail.transporterName !== null
                        ? soDetail.transporterName
                        : "NA"}
                    </span>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <div className="salesInvoiceCompanyAddress">
              {showCustomerDetail ? <RenderCustomerDetail /> : null}
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="createSalesInvoiceGrid">
              {renderFormElements({
                elementList: salesOrderParamList[1],
                param: salesOrderParams,
                section: "general",
              })}
            </div>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            {salesOrderParams.officeName.value !== "" ? (
              <span style={{ color: "#ce9547", fontWeight: "bold" }}>
                {" "}
                Office Address:{" "}
              </span>
            ) : null}
            {salesOrderParams.officeName.value !== ""
              ? salesOrderParams.officeName.options.filter(
                  (opt) => opt.optionId === salesOrderParams.officeName.value
                )[0].address
              : null}
          </div>

          {lineItems.length > 0 ? (
            <table className="createItemPurchaseTable">
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className="stickyFirstColumn">Item </td>
                  <td>Item Code</td>
                  {isLogged.showHideWeight ? (
                    <>
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>{" "}
                    </>
                  ) : null}
                  <td>HSN No</td>
                  <td>UOM</td>
                  <td>SO Quantity</td>
                  <td>Quantity</td>
                  <td>SO Unit Price</td>
                  <td>Unit Price</td>
                  <td>Premium Charges</td>
                  <td>Tax (%)</td>
                  <td>Tax Amount</td>
                  <td>SO Discount (%)</td>
                  <td>Discount (%)</td>
                  <td>Net Rate</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsTableCols.map((key, i) => {
                      switch (key) {
                        case "quantity":
                          return (
                            <td key={i} style={{ maxWidth: "8px" }}>
                              <input
                                className={
                                  lineItems[j].quantityError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                style={{ minWidth: "3rem" }}
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );

                        case "unitPrice":
                          return (
                            <td key={i} style={{ maxWidth: "80px" }}>
                              <input
                                className={
                                  lineItems[j].unitPriceError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );
                        case "discount":
                          return (
                            <td key={i} style={{ maxWidth: "8px" }}>
                              <input
                                className={
                                  lineItems[j].discountError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                style={{ minWidth: "3rem" }}
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );
                        case "soOtherCharges":
                            return(
                              <td key={i} style={{ maxWidth: "8px" }}>
                              <input
                                className={
                                  lineItems[j].discountError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                style={{ minWidth: "3rem" }}
                                type="number"
                                value={row[key] || ""}
                                min={0}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                            );
                        case "netPrice":
                          return (
                            <td key={i}>{row[key] === 0 ? "-" : row[key]}</td>
                          );
                        case "itemName":
                          return(
                            <td key={i} className="stickyFirstColumn">{row[key]}</td>
                          )
                        case "taxAmount":
                          return(
                            <td key={i}>{(row.unitPrice * (row.tax/100)).toFixed(2)}</td>
                          )
                        default:
                          return (
                            <td key={i}>
                              {row[key] !== null ? row[key] : "-"}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items: <span>{lineItems.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>Invoice Instruction</div>
              <textarea
                className="createInvoiceMultilineInput"
                value={instruction}
                rows="3"
                cols="30"
                name="text"
                placeholder="Enter instructions"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
              <div className="createSalesInvoiceGrid2">
                {lrDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint hintText="LR Document" mandatory={false} />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {lrDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setLrDocument("")}
                        title="Remove and Re-upload LR Copy">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="LR Document"
                    setInput={(file) => {
                      setLrDocument(file);
                    }}
                  />
                )}

                {outwardDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint hintText="Outward Document" mandatory={false} />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {outwardDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setOutwardDocument("")}
                        title="Remove and Re-upload Outward Document">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="Outward Document"
                    setInput={(file) => {
                      setOutwardDocument(file);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="poSpacer">&nbsp;</div>

            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Net Total</b>
                </span>
                <span className="poNetTotalAmount">{netTotal}</span>
              </div>

              <div className="poFormElement" style={{gridTemplateRows: "repeat(4,4rem)"}}>
                <div className="formElement">
                  <FormHint hintText="Other Charges" mandatory={false} />
                  <InputField
                    value={otherCharges}
                    setName={(value) => setOtherCharges(parseFloat(value) >=0 ? parseFloat(value) : 0)}
                    align="end"
                    error={otherChargesError}
                  />
                </div>
                <div className="formElement">
                  <FormHint hintText="Transport Charges" mandatory={false} />

                  <InputField
                    value={transportCharges}
                    setName={(value) => settransportCharges(parseFloat(value) >=0 ? parseFloat(value) : 0)}
                    align="end"
                    error={transportChargesError}
                  />
                </div>
                {<div>
                   <div
                style={{
                  gridColumn: `auto / span 6`,
                  margin: "auto 0px",
                  color: "#666666",
                  cursor: "pointer",
                }}
                onClick={() => setShowDiscount(!showDiscount)}>
                <input
                  type="checkbox"
                  name="discount"
                  checked={showDiscount}
                  readOnly
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor="discount"
                  style={
                    showDiscount
                      ? {
                          fontWeight: "600",
                          color: "#1f43e5",
                          padding: "0 10px",
                          cursor: "pointer",
                          fontSize: "17px",
                        }
                      : {
                          padding: "0 10px",
                          cursor: "pointer",
                          fontSize: "17px",
                        }
                  }>
                  Add Discount
                </label>
              </div>
              {showDiscount ? <div className="formElement">
                <FormHint hintText="Discount" mandatory={false} />

                <InputField
                  type="float"
                  value={finalDiscount}
                  setName={(value) => setFinalDiscount(parseFloat(value) >=0 ? parseFloat(value) : 0)}
                  align="end"
                  error={finalDiscountError}
                />
                </div> : null}
                </div>}
              </div>

              <div className="poTotalTitle">
                  <span>Round off</span>
                <span className="poNetTotalAmount">{'0.'+(totalAmount.toString().split('.')[1] || 0)}</span>
                </div>
              <div className="poTotalTitle" style={{ margin: "1rem 0 0 0",fontSize:"14px" }}>
                <span>
                  <b>Total Amount<em>(Rounded Off)</em></b>
                </span>
                <div className="poNetTotalAmount">{Math.round(totalAmount)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="saveButton"
            onClick={() => {
              setErrorList([]);
              checkErrors("Draft");
            }}>
            Save Draft
          </button>
          <button
            className="submitButton"
            onClick={() => {
              setErrorList([]);
              checkErrors("Approval Pending");
            }}>
            Submit for Approval
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
