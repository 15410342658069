import {Fragment, useEffect, useState} from 'react'
import FormElement from '../../SmallComponents/FormElement'
import { connect } from 'react-redux';
import fetchData from '../../../serverCall/fetchData';
import { validateMandatory } from '../../CommonFunctions/ValidateFields';
import { addToStockReportParams } from '../../../redux/PurchaseOrder/PoInfoActions';
import update from "immutability-helper";
import { addToken } from '../../../redux/UserAccount/IsLoggedActions';

var plantList = [];
var storageList = [];
var axisParams = [ 
  { optionId: "1", optionName: "Item Quantity" },
  { optionId: "2", optionName: "Plant Storage" } ]; 

export const paramsTemplate = (
  {
  itemId: {
    inputType: "options",
    value: "",
    hintText: "Item",
    mandatory: false,
    colSpan: 12,
    options: [],
    error: false,
    errorMessage: "Select an Item",
    callServer: true,
    filterOptions : (optionObject,input)=>
    { 
      if(input){
        return optionObject.label.toLowerCase().includes(input.toLowerCase())
      }
      return true;
    }
  },
  plantName: {
      inputType: "multiSelect",
      value: [],
      hintText: "Plant Name",
      options: plantList,
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "Please select Plant Name",
  },
  storageName: {
      inputType: "multiSelect",
      value: [],
      hintText: "Storage",
      options: storageList,
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "Please enter Plant Name",
  },
  fromDate: {
    inputType: "dateFromEditPage",
    value: new Date(),
    hintText: "From Date",
    mandatory: false,
    colSpan: 5,
    error: false,
    maxdate: true,
  },
  toDate: {
    inputType: "dateFromEditPage",
    value: new Date(),
    hintText: "To Date",
    mandatory: false,
    colSpan: 5,
    error: false,
    maxdate: true,
  },
  chartType: {
    inputType: "options",
    value: '',
    hintText: "Chart",
    options: [
      {label: "Pie Chart", value: "pie"},
      {label: "Bar Chart", value: "bar"},
      {label: "Line Chart", value: "line"},
    ],
    mandatory: false,
    colSpan: 5,
    error: false,
    errorMessage: "Please Enter the Graph Name",
  },
  xAxis: {
    inputType: "options",
    value: '',
    hintText: "X Axis Param",
    options: axisParams,
    mandatory: false,
    colSpan: 5,
    error: false,
    errorMessage: "Please enter X axis",
  },
  yAxis: {
  inputType: "options",
  value: '',
  hintText: "Y Axis Param",
  options: axisParams,
  mandatory: false,
  colSpan: 5,
  error: false,
  errorMessage: "Please enter Y axis",
  }
})


const StockReportParams = ({addToStockReportParams,isLogged, setSection}) => {

  const [stockReportParams,setStockReportParams] = useState({...paramsTemplate})
 
  const [enableChart, setChartEnabled] = useState(false);
  const [storageLocList, setStorageLocList] = useState({});
  const [stockReportError,setStockReportError] =useState([]);
  const updateParameter=(element,key,value, section)=>{
    if(section = "stock"){
    let paramsCopy  =  Object.assign(stockReportParams);
    paramsCopy = update(paramsCopy,{[element]: {[key]:{$set: value} } });
    if(element==="plantName"){
      paramsCopy = update(paramsCopy,{storageName: {value: {$set: ""},options:{$set: []} } })
    }
    setStockReportParams(paramsCopy);
  }
    if (section = "graph"){
      let paramsCopy = Object.assign(stockReportParams);
      paramsCopy = update(paramsCopy,{[element]: {[key]:{$set: value} } });
      setStockReportParams(paramsCopy)
    }
  }
    
  useEffect(()=>{
    getItemList();
  },[]);

  useEffect(()=>{
    if(stockReportParams.plantName.value.length){
      let storageOptions = [];
      let plantsList=[]
       plantsList = stockReportParams.plantName.value.map(plant=>plant.value)
      if(plantsList.includes(-1)){
        plantsList=stockReportParams.plantName.options.map(plant=>plant.optionId)
      }
      
      plantsList.forEach((plantId)=>{
        if(plantId!== -1){
          storageOptions.push(...storageLocList[plantId]);
        }
      })
      let paramsCopy = Object.assign(stockReportParams);
      paramsCopy = update(paramsCopy,{storageName : {options: {$set: storageOptions} }});
      setStockReportParams(paramsCopy);
    }
  },[stockReportParams.plantName.value.length]);

  async function getItemList() {
    var itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{itemsTab:"Active"},
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    var itemList = [];
    if (itemData.msg === "success") {
      itemData.asset.forEach((item) => {
        var itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemList.push(itemObj);
      });

      var plantsData = await fetchData({
        requestingPage: "plantList",
        method: "get",
        url: "fetch/plants",
        headers: { token: isLogged.accessToken, module: "Plant & Store" },
      });

      var plantListItem = [];
      var storageLocList = {};

      if (plantsData.msg === "success") {
        if(plantsData.asset.length){
          plantList.splice(0,0,{optionId:-1,optionName:"All"});
        }
        plantsData.asset.forEach((plant) => {
          var plantObj = {};
          plantObj.optionId = plant.plant.plantId;
          plantObj.optionName = plant.plant.plantName;
          plantObj.address =
            plant.plant.plantName +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          plantListItem.push(plantObj);
         

          
          plant.storage.forEach((store) => {
            var storeObj = {};
            storeObj.optionId = store.storageId;
            storeObj.optionName = store.storageName;
            storeObj.address =
              store.address +
              ", " +
              plant.plant.city +
              ", " +
              plant.plant.state +
              (plant.plant.zipCode !== null
                ? ` - ${plant.plant.zipCode}, `
                : ", ") +
              plant.plant.country;
            storeObj.gstNo = plant.plant.gstNo;
            if(storageLocList[plant.plant.plantId]){
              storageLocList[plant.plant.plantId].push(storeObj);
            }else{
              storageLocList[plant.plant.plantId] = [storeObj]
            }
          });
        });

        plantList = plantListItem;
        // if(plantList.length){

        //   plantList.push({optionId:-1,optionName:"All"});
        // }

      var stockReportParamsCopy = Object.assign(stockReportParams);
      stockReportParamsCopy = update(stockReportParamsCopy, {
        itemId: { options: { $set: itemList } },
        plantName: { options: { $set: plantList } },
        storageName: { options: { $set: [] } },
        fromDate: { value: { $set: new Date() } },
        toDate: { value: { $set: new Date() } },
      });

      setStockReportParams(stockReportParamsCopy);
      setStorageLocList(storageLocList);
      return [itemList,storageLocList];
    }
  }
}

  const checkStockReportError=()=>{
    var errorList = [];
      var paramsCopy = Object.assign(stockReportParams);
      let queryParams = {}
      Object.keys(paramsCopy).forEach((item)=>{
        if(paramsCopy[item].mandatory){
          paramsCopy = update(paramsCopy,{ [item]: {error: {$set:  !validateMandatory(paramsCopy[item].value.toString()) } } })
        queryParams[item] = paramsCopy[item].value
        }
        else if (paramsCopy[item].value) {
          paramsCopy = update(paramsCopy,{ [item]: {value: {$set: paramsCopy[item].value}} });
        queryParams[item] = paramsCopy[item].value;
        }
        if(paramsCopy[item].error)
          errorList.push(paramsCopy[item].errorMessage);
      });

      let emptyCheck = Object.entries(queryParams).every(([key,value])=>value==="");
      if(emptyCheck){
        errorList.push("Enter atleast One Parameter to fetch list")
      }
      if(errorList.length === 0){
        let plantList = queryParams.plantName?.map((plant)=>plant.value)
        queryParams.plantId = plantList.includes(-1)? paramsCopy.plantName?.options.map((plant)=>plant.optionId):plantList;
        queryParams.storageId = queryParams.storageName?.map((stg)=>stg.value);
        queryParams['enableChart'] = enableChart;
        addToStockReportParams(queryParams);
        setSection("stockReportList")
      }else{
        setStockReportError(errorList)
      }
  }

  const renderFormElements = ({ elementList, param, section }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0? "500px" : "250px" }} key={element}>
            <FormElement
              inputType={param[element].inputType}
              value={param[element].value}
              filterOptions={param[element].filterOptions}
              setInput={(value) => updateParameter(element, "value", value, section)}
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={
                param[element].options
                  ? param[element].options
                  : null
              }
              error={param[element].error}
              key={ind}
              isClearable={param[element].isClearable}
            />
            </span>
          );
        })}
        </>
    );
  };

  useEffect(()=>{
    let paramsCopy = Object.assign(stockReportParams)

      paramsCopy = update(paramsCopy,{
        chartType: { mandatory: {$set: enableChart}},
        xAxis:  { mandatory: {$set: enableChart}},
        yAxis: { mandatory: {$set: enableChart}},
      })
      setStockReportParams(paramsCopy)
  },[enableChart]);

  return (
    <Fragment>
      <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
            <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            {renderFormElements({
              elementList: ["itemId", "plantName", "storageName", "fromDate", "toDate"],
              param: stockReportParams,
              section : "stock"
            })}
          </div>
          <div style ={{ margin: "20px 10px"}}>
          <label className="plantsSubTitle" style={{fontSize: "14px"}}>
          <input type="checkbox" onClick={(e)=> {setChartEnabled(!enableChart)}}/>
          <span>Enable chart in reports</span>
        </label>
        </div>
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
          {enableChart ? renderFormElements({
              // elementList: ["chartType", "xAxis", "yAxis"],
              elementList: ["chartType"],
              param: stockReportParams,
              section : "graph"
            }) : null }
            </div>
            <div style= {{ marginBottom: "5px"}}>
            <FormElement
            inputType="addButton"
            value="Submit"
            colSpan={5}
            boxWidth="80px"
            setInput={() => {
                setStockReportError([]);
                checkStockReportError();
            }}
        />
        </div>
          <p className="formSubmitErrorTextArea" style={{textAlign:"center",fontSize:"16px"}}>
            {stockReportError[0]}
          </p>
          </div>
        </div>
    </Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
      addToStockReportParams: (value)=>dispatch(addToStockReportParams(value))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(StockReportParams);