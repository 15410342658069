import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { removeToken } from "../../redux/UserAccount/IsLoggedActions";
import enterot from "../../assets/enterotNewLogoImage.svg";
import  '../styles/SmallComponents.css';

const TopBanner = ({ empInfo, removeAccessToken }) => {
    const [logoutPopover, setLogoutPopover] = useState(false);
    const history = useHistory();

    return (
        <div className="topBannerDiv">
            {empInfo.menuCollapse ? <img alt="enterot"
                className="companyLogo"
                title={"Enterot https://enterot.com/"}
                src={enterot}
                onClick={() => {
                    window.open("https://enterot.com/", "_blank");
                }}
            />: ''}
            {/* <input className="searchBar" type="text" placeholder="Search" /> */}
            <div style={{ flex: 1 }} />
            <Popover
                isOpen={logoutPopover}
                positions={["bottom", "right"]}
                containerStyle={{zIndex:"7"}}
                onClickOutside={(e) => {
                    e.preventDefault();
                    setLogoutPopover(false);
                }}
                content={() => (
                    <div className="logoutPopoverContent">
                        <img className="popoverUserImage" src={empInfo.image} alt="emp"/>
                        <div className="popoverEmployeeName">{empInfo.employeeName}</div>
                        <div className="popoverEmployeeId">{empInfo.role}</div>
                        <button
                            className="logoutButton"
                            onClick={() => {
                                setLogoutPopover(false);
                                removeAccessToken();
                                history.push("/");
                            }}
                        >
                            Log Out
                        </button>
                    </div>
                )}
            >
                <div className="logOutGroup" onClick={() => setLogoutPopover(!logoutPopover)}>
                    <img className="topBannerUserImage" src={empInfo.image} alt="User" />
                </div>
            </Popover>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        empInfo: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { removeAccessToken: () => dispatch(removeToken()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBanner);
