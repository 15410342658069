import {useState,useLayoutEffect} from 'react';
export const dateFormatting=(date)=>{
    date=new Date(date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dateValue = date.getDate();

    if (month.toString().length === 1) month = `0${month}`;
    if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

    var formattedDate = `${year}-${month}-${dateValue}`;
    return formattedDate;
}

export const addDaysToDate=(dateString,daysToAdd)=>{
    var date =new Date(dateString);
    date.setDate(date.getDate()+daysToAdd);
    return date;
}

export const simpleDateTime=(dateTime)=>{
  let date = new Date(dateTime);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  const time = date.toLocaleTimeString().replace(/:\d+ (\w\w)$/,' $1')
  return `${day}-${month}-${year} ${time}`;
}

export const numToWords=(n)=>{
   const num = "zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve Thirteen Fourteen Fifteen Sixteen Seventeen Eighteen Nineteen".split(" ");
   const tens = "Twenty Thirty Forty Fifty Sixty Seventy Eighty Ninety".split(" ");
   
    if(n>=0 && n<=999999999)
       {if (n < 20) 
          return num[n];
       if (n < 100) 
          return tens[Math.floor((n/10))-2] + ((n%10)? " " + num[n%10]: "");
       if (n < 1000) 
          return num[Math.floor(n/100)] +" Hundred" + (n%100 == 0? "": " " + numToWords(n%100));
        if(n<100000)
       return numToWords(Math.floor(n/1000)) + " Thousand" + (n%1000 != 0? " " + numToWords(n%1000): "");
        if(n<10000000)
         return numToWords(Math.floor(n/100000)) + " Lakh" + (n%100000 !=0 ? " " + numToWords(n%100000) : "");
       
            return numToWords(Math.floor(n/10000000)) + " Crore" + (n%10000000 !=0 ? " " + numToWords(n%10000000) : "");
   }
    else 
   {
    console.log("Out of Bound Limits");
    return 0;
   }
   }
   
   const sortBy = (data, col, order) => {
      if (order === false) {
        const sortAlphaNum = (a, b) =>
          a[col]?.localeCompare(b[col]||"", "en", { numeric: true });
      return  data.sort(sortAlphaNum);
      } else {
        const sortAlphaNum = (a, b) =>
          b[col]?.localeCompare(a[col] || "", "en", { numeric: true });
      return  data.sort(sortAlphaNum);
      }
    };
    const sortByDate = (data, col, order) => {
      if (order === true) {
        const sortAlphaNum = (a, b) => new Date(a[col]) - new Date(b[col]);
      return  data.sort(sortAlphaNum);
      } else {
        const sortAlphaNum = (a, b) => new Date(b[col]) - new Date(a[col]);
      return  data.sort(sortAlphaNum);
      }
    };
  export  const handleSort = ({ col, state, data }) => {
      if (col.toLowerCase().includes("date")) {
        return sortByDate(data, col, state);
      } else {
        return sortBy(data, col, state);
      }
    };

    export const getIndianCurrency=(number,needCurrency=true)=>{
      number =Number(number) || 0
      if(needCurrency)
      return number.toLocaleString('en-IN',{maximumFractionDigits:2,currency:"INR",style:"currency"});
     else
     return number.toLocaleString('en-IN');
    }

    
 export const useWindowSize = () => {
      const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
    };

//To Get Array Depth
export const depthOfAnArray=(array)=> array.reduce((count,v) => !Array.isArray(v) ? count : 1 + depthOfAnArray(v),1);

// ONLY SINGLE Dimensional Array
export const isSortedArray = {
  ascending: (array) => {
    let depthOfArray = depthOfAnArray(array);
    if(depthOfArray===1) 
      return array.every(function (num, idx, arr) {
        return (num <= arr[idx + 1]) || (idx === arr.length - 1) ? 1 : 0;
      })
    else document.write('IS SORTED OBJECT ONLY ACCEPTS SINGLE DIMENSIONAL ARRAY')
    },
  descending: (array) => {
    let depthOfArray = depthOfAnArray(array);
    if(depthOfArray===1) 
    return array.every(function (num, idx, arr) {
      return (num >= arr[idx + 1]) || (idx === arr.length - 1) ? 1 : 0;
    })
    else document.write('IS SORTED OBJECT ONLY ACCEPTS SINGLE DIMENSIONAL ARRAY')
  }
}