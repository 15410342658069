import { Fragment,useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import EmployeeReportParams from "./employeeEfficiencyParams";
import productionReportIcon from "../../assets/production-report.svg"
import { useHistory } from "react-router-dom";
import EmployeeEfficiencyReportList from "./employeeEfficiencyReportList";
import EmployeeReportDetail from "./employeeEfficiencyDetail";

const EmployeeEfficiencyReport=()=>{

    const [currentSection,setCurrentSection] = useState("employeeReportParams");
    const history = useHistory();

var goBackButton = (props) =>
  ({
    buttonName: "Back",
    className: "goBackButton",
    ...props
  });
    const renderSection=(sectionName)=>{

        switch (sectionName) {
            case "employeeReportParams":
                return (<Fragment>
                            <PageTitle imgSrc={productionReportIcon} pageTitle={"Employee Report Form"}
                                buttonList={[goBackButton({ setSection: (route) => history.push(route), sectionName: "/production-report" })]} />
                            <EmployeeReportParams setSection={setCurrentSection} />
                        </Fragment>);
                
                case "employeeReportList":
                    return(
                        <Fragment>
                            <PageTitle imgSrc={productionReportIcon} pageTitle={"Employee Report List"}
                            buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "employeeReportParams" })]} />
                            <EmployeeEfficiencyReportList setSection={setCurrentSection}/>
                        </Fragment>
                    );

                case "employeeReportDetail":
                    return(
                        <Fragment>
                            <PageTitle imgSrc={productionReportIcon} pageTitle={"Employee Report Detail"}
                                buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "employeeReportList" })]} />
                           <EmployeeReportDetail />
                        </Fragment>
                    )
            default:
                return("Page Not Built")
        }
    }

    return (
        <Fragment>
            <div className="completePage">
                <MenuList selectedMenu={"Reports"} selectedSubMenu={"Production Report"}/>
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </Fragment>
      )
}

export default EmployeeEfficiencyReport;