export const viewPoId = (viewPoId) => {
    return { type: "VIEW_PO_ID", payload: { viewPoId } };
};

export const editPoId = (editPoId) => {
    return { type: "EDIT_PO_ID", payload: { editPoId } };
};

export const viewPoReturnId = (returnId) => {
    return { type: "VIEW_PO_RETURN_ID", payload: { returnId } };
};

export const editPoReturnId = (returnId) => {
    return { type: "EDIT_PO_RETURN_ID", payload: { returnId } };
};

export const viewSoId = (viewSoId) => {
    return { type: "VIEW_SO_ID", payload: { viewSoId } };
};

export const editSoId = (editSoId) => {
    return { type: "EDIT_SO_ID", payload: { editSoId } };
};

export const poInvoiceCreateId = (poInvoiceCreateId) => {
    return { type: "PO_INVOICE_ID", payload: { poInvoiceCreateId } };
};

export const poViewInvoiceId = (poViewInvoiceId) => {
    return { type: "PO_VIEW_INVOICE_ID", payload: { poViewInvoiceId } };
};

export const poEditInvoiceId = (poEditInvoiceId) => {
    return { type: "PO_EDIT_INVOICE_ID", payload: { poEditInvoiceId } };
};

export const soInvoiceCreateId = (soInvoiceCreateId) => {
    return { type: "SO_INVOICE_ID", payload: { soInvoiceCreateId } };
};

export const soViewInvoiceId = (soViewInvoiceId) => {
    return { type: "SO_VIEW_INVOICE_ID", payload: { soViewInvoiceId } };
};

export const soEditInvoiceId = (soEditInvoiceId) => {
    return { type: "SO_EDIT_INVOICE_ID", payload: { soEditInvoiceId } };
};

export const viewSoReturnId = (viewSoReturnId) => {
    return { type: "VIEW_SO_RETURN_ID", payload: { viewSoReturnId } };
};

export const editSoReturnId = (editSoReturnId) => {
    return { type: "EDIT_SO_RETURN_ID", payload: { editSoReturnId } };
};

// ===============================================================================

export const setPoTab = (poTab) => {
    return { type: "SET_PO_TAB", payload: { poTab } };
};

export const setPoInvoiceTab = (poInvoiceTab) => {
    return { type: "SET_PO_INVOICE_TAB", payload: { poInvoiceTab } };
};

export const setSoTab = (soTab) => {
    return { type: "SET_SO_TAB", payload: { soTab } };
};

export const setSoInvoiceTab = (soInvoiceTab) => {
    return { type: "SET_SO_INVOICE_TAB", payload: { soInvoiceTab } };
};

export const viewPoImportId = (viewPoImportId) => {
    return { type: "VIEW_PO_IMPORT_ID", payload: { viewPoImportId } };
};

export const editPoImportId = (editPoImportId) => {
    return { type: "EDIT_PO_IMPORT_ID", payload: { editPoImportId } };
};

export const importPurchaseOrderViewId = (importPurchaseOrderViewId) => {
    return { type: "IMPORT_PURCHASE_ORDER_VIEW_ID", payload: { importPurchaseOrderViewId } };
};

export const importPurchaseOrderEditId = (importPurchaseOrderEditId) => {
    return { type: "IMPORT_PURCHASE_ORDER_EDIT_ID", payload: { importPurchaseOrderEditId } };
};

export const pendingSoProduction = (pendingSoId) => {
    return { type: "PENDING_SO_PRODUCTION", payload: { pendingSoId } };
};

// export const pendingIdn = (pendingIdnId) => {
//     return { type: "PENDING_IDN", payload: { pendingIdnId } };
// };

export const setProductionOrderTab = (productionOrderTab) => {
    return { type: "PRODUCTION_ORDER_TAB", payload: { productionOrderTab } };
};

export const viewProductionOrderId = (productionId) => {
    return { type: "VIEW_PRODUCTION_ORDER_ID", payload: { productionId } };
};

export const editProductionOrderId = (productionId) => {
    return { type: "EDIT_PRODUCTION_ORDER_ID", payload: { productionId } };
};

export const setMaterialRequisitionTab = (materialRequisitionTab) => {
    return {type: "MATERIAL_REQUISITION_TAB", payload :{materialRequisitionTab} };
}

export const viewMaterialRequisitionId = (materialRequisitionId) => {
    return {type: "VIEW_MATERIAL_REQUISITION_ID", payload: {materialRequisitionId} };
}

export const editMaterialRequisitionId = (materialRequisitionId) => {
    return {type: "EDIT_MATERIAL_REQUISITION_ID", payload : {materialRequisitionId} };
}

export const setDcListTab = (dcTab)=>{
    return {type: "DC_LIST_TAB",payload:{dcTab} };
}

export const viewDcId = (dcId)=>{
    return {type: "VIEW_DC_ID",payload: { dcId } };
}

export const editDcId = (dcId) =>{
    return {type: "EDIT_DC_ID",payload:{ dcId } };
}

export const viewDcReturnId = (dcReturnId)=>{
    return {type: "VIEW_DC_RETURN_ID",payload: { dcReturnId } };
}

export const editDcReturnId = (dcReturnId) =>{
    return {type: "EDIT_DC_RETURN_ID",payload:{ dcReturnId } };
}

export const viewCustomerReceiptId = (cusRecId)=>{
    return {type: "VIEW_CUS_RECEIPT_ID",payload:{cusRecId} };
}

export const editCustomerReceiptId = (cusRecId)=>{
    return {type: "EDIT_CUS_RECEIPT_ID",payload: {cusRecId} };
}

export const viewVendorPaymentId = (venPayId)=>{
    return {type: "VIEW_VEN_PAYMENT_ID",payload:{venPayId} };
}

export const editVendorPaymentId = (venPayId)=>{
    return {type: "EDIT_VEN_PAYMENT_ID",payload: {venPayId} };
}

export const editBOMId = (bomId)=>{
    return {type: "EDIT_BOM_ID",payload:{bomId} }
}

export const viewBOMId = (bomId)=>{
    return {type: "VIEW_BOM_ID",payload:{bomId} }
}

export const editWorkOrderId =(workOrderId) =>{
    return {type: "EDIT_WORK_ORDER_ID",payload: {workOrderId}}
}

export const viewWorkOrderId =(workOrderId) =>{
    return {type: "VIEW_WORK_ORDER_ID",payload: {workOrderId}}
}

export const editOrViewProcessLogId=(processLogId)=>{
    return {type: "PROCESS_LOG_ID",payload: {processLogId}}
}

export const addToImportReportParams=(importReportParams)=>{
    return {type: "IMPORT_REPORT_PARAMS",payload:{importReportParams}}
}

export const addToStockReportParams=(stockReportParams)=>{
    return {type: "STOCK_REPORT_PARAMS",payload:{stockReportParams}}
}

export const addToProductionReportParams=(productionReportParams)=>{
    return {type: "PRODUCTION_REPORT_PARAMS",payload:{productionReportParams}}
}

export const addViewProcessId =(viewProcessLogId)=>{
    return {type:"VIEW_PROCESS_LOG_ID",payload:{viewProcessLogId}}
}

export const addEmployeeId =(employeeId)=>{
    return {type:"EMPLOYEE_ID",payload:{employeeId}}
}
export const addEmployeeEfficiencyItemId =(employeeEfficiencyItemId)=>{
    return {type:"EMPLOYEE_EFFICIENCY_ITEM_ID",payload:{employeeEfficiencyItemId}}
}

export const addVendorLedgerParams =(vendorLedgerParams)=>{
    return {type:"VENDOR_LEDGER_PARAMS",payload:{vendorLedgerParams}}
}

export const addVendorInvoiceParams =(vendorInvoiceParams)=>{
    return {type:"VENDOR_INVOICE_PARAMS",payload:{vendorInvoiceParams}}
}
