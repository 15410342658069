import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import InputField from "../SmallComponents/InputField";
const EditDc=({isLogged,setSection,poInfo,storeInfo,addTokenToState,editApprove})=>{
    const dcParamsList = ["dcNo","orderDate","expectDate","orderNo","process","vendor"];
    const lineItemsParamsList = ["itemName","itemCode","from","to","netWeight","grossWeight","uom","allowedDcQuantity","previousDc","currentDc"];
    const [dcParams,setDcParams]=useState({
        dcId: {value:""},
        dcNo:{
            inputType: "text",
            value:"",
            hintText: "DC NO",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Enter Dc No",
        },
        orderDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Dc Order date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select Order date",
            mindate: true,
        },
        expectDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Dc Expected Delivery date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select expected date for completing request",
            mindate: true,
        },
        orderNo:{
            inputType:"options",
            value:"",
            options:[],
            hintText:"Production Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please Select Order Number",
            disabled: true
        },
        process:{
            inputType: "options",
            value:"",
            options:[],
            hintText:"Process Name",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please Select a Process",
        },
        vendor:{
            inputType:"options",
            value:"",
            options:[],
            hintText:"Vendor Name",
            mandatory:true,
            colSpan:6,
            error: false,
            errorMessage:"Please Select a Vendor"
        }
    });const [lineItems,setLineItems]=useState([]);
    const [instruction,setInstruction]=useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Creating DC. Please wait...");
    const [dcError,setDcError] = useState([]);
    const history=useHistory();
    const [showVendorDetail,setShowVendorDetail] = useState(false);
    const [vendorDetail,setVendorDetail] = useState({});


    useEffect(()=>{getInformation();},[]);

    useEffect(()=>{
        if(dcParams.vendor.value!==""){
            getVendorDetail();
        } else {
            setVendorDetail({});
            setShowVendorDetail(false);
        }
    },[dcParams.vendor.value]);

    async function getInformation(){
        await checkToken();
       var dcDetail = await getDcDetail();
        var processOptions = await getProcesses();
        var vendorOptions = await getVendors();
        var paramsCopy = Object.assign(dcParams);
        paramsCopy = update(paramsCopy,{ 
                                         dcId: {value: {$set: dcDetail.dcId} }, 
                                         dcNo: {value: {$set: dcDetail.userDcNo} },
                                         orderDate: {value: {$set: dcDetail.orderDate} },
                                         expectDate: {value: {$set: dcDetail.expectDate} },
                                         orderNo: {options: {$set: [{optionId: dcDetail.prodId,optionName: dcDetail.prodNo}] },value:{$set: dcDetail.prodId} },
                                         process: {options: {$set: processOptions},value:{$set: dcDetail.processId} },
                                         vendor: {options: {$set: vendorOptions},value:{$set: dcDetail.vendorId} } 
                                        });
        setDcParams(paramsCopy);
        setInstruction(dcDetail.instruction);}
     
     async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
     }
    async function getDcDetail(){
        var dcDetail= await fetchData({
            requestingPage: "dcDetail",
            method: "get",
            url: `dclist-fetch/dc-detail/${poInfo.editDcId}`,
            headers: {token: isLogged.accessToken, module:"DC List"}
        });

        if(dcDetail.msg="success"){
            dcDetail.asset[0].lineItems.forEach((row)=>row.editQty=0);
            setLineItems(dcDetail.asset[0].lineItems);
        }else{
            // console.log(dcDetail);
        }
        return dcDetail.asset[0];
    }

    async function getProcesses(){
        var processes = await fetchData({
            requestingPage:"getProcess",
            method: "get",
            url: "fetch/process-list",
            headers: {token : isLogged.accessToken, module: "DC List"},
        });
        var processOptions=[];
        if(processes.msg==="success"){
            processes.asset.forEach((process)=>{
                processOptions.push({optionId: process.processId,optionName: process.processName});
            });
        }
        return processOptions;
    };

    async function getVendors(){
        var vendors = await fetchData({
            requestingPage: "vendorList",
            method: "get",
            url: "fetch/vendor-names",
            headers: { token: isLogged.accessToken, module: "DC List" },
        });
        var vendorOptions = [];
        if (vendors.msg === "success") {
            vendors.asset.forEach((vendor) => {
                var a = { optionName: vendor.VendorName, optionId: vendor.vendorId };
                vendorOptions.push(a);
            });
        }
            return vendorOptions;
    };

    async function getVendorDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "fetch/vendor-detail/" + dcParams.vendor.value,
            headers: { token: isLogged.accessToken, module: "DC List" },
        });
        if (data.msg === "success") {
            setShowVendorDetail(true);
            setVendorDetail(data.asset[0]);
        }
    }

const updateDcParameter = ({section,param,key,value})=>{
    if(section === "general" && param !=="orderNo"){
        var paramsCopy = Object.assign(dcParams);
        paramsCopy = update(paramsCopy,{ [param]: {[key]: {$set: value} } });
        setDcParams(paramsCopy);
    }
}


const checkErrors=()=>{
    var errorList=[];
    var paramsCopy=Object.assign(dcParams);
    dcParamsList.forEach((item)=>{
        if(paramsCopy[item].mandatory)
        {
            paramsCopy=update(paramsCopy,{[item]: {error: {$set: !validateMandatory(paramsCopy[item].value.toString()) } } });
        }
        if(paramsCopy[item].error){
            errorList.push(paramsCopy[item].errorMessage);
        }
    });
    var LineCheckCondition1 = lineItems.some((row)=>row["currentDc"] < 0);
    if(LineCheckCondition1)
    errorList.push("Provide Valid Current and Previous dc Quantity");
    var LineCheckCondition2=lineItems.some((row)=>row["allowedDcQuantity"] < row["previousDc"]);
    if(LineCheckCondition2)
    errorList.push("The Total Dc Quantity For an Item Cannot be Greater than Allowed DC Quantity");
    if(errorList.length===0 && lineItems.length>0){
        var data2Server={
            dcId: paramsCopy.dcId.value,
            userDcNo: paramsCopy.dcNo.value, 
            orderDate: paramsCopy.orderDate.value,
            expectDate: paramsCopy.expectDate.value,
            productionOrderNo: paramsCopy.orderNo.value,
            processId: paramsCopy.process.value,
            vendorId: paramsCopy.vendor.value,
            instruction: instruction,
        }
        var lineItemsCopy = lineItems.slice();
        lineItemsCopy=lineItemsCopy.map((item)=>{
            return {
                lineId: item.lineId,
                itemId: item.itemId,
                prodLineId: item.prodLineId,
                uomId: item.uomId,
                quantity: item.quantity,
                previousDc: item.previousDc,
                currentDc: item.currentDc
            }
        });
        if(lineItemsCopy.length>0){
            data2Server.lineItems=lineItemsCopy;
            submitData(data2Server);}
    }else{
    errorList.push("Order Number and Items cannot be Empty");
    }
    setDcParams(paramsCopy);
    setDcError(errorList);
}
const approveOrDecline=()=>{
    setSection("dcDetail");
}
const submitData=async(data2Server)=>{
    setIsOpen(true);
    setModalText("Editing DC. Please Wait...!");
    var result = await fetchData({
        requestingPage:"editDc",
        url: "dclist-edit/edit-dc",
        method: "put",
        headers: {token: isLogged.accessToken, module: "DC List"},
        data: data2Server,
    });
        setDataSubmitted(true);
        if(result.msg==="success"){
            if(editApprove)
            approveOrDecline();
        setModalText("DC Edited SuccessFully");
        }else{
        setDataSubmitted(true);
        setDcError([result.desc]);
        setModalText(result.desc);
        // console.log("Error in Submitting data",result);
    }
 }

    const renderFormElements=({elementList,param,section})=>{
     return elementList.map((element) => {
        return (
            <FormElement
                key={element}
                inputType={param[element].inputType}
                value={param[element].value !== null ? param[element].value : ""}
                setInput={(value) => {
                    updateDcParameter({ section, param: element, key: "value", value: value });
                }}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={param[element].inputType === "options" ? param[element].options : null}
                error={param[element].error}
                rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                placeholder={param[element].placeholder}
                title={param[element].title}
                disabled={param[element].disabled}
                mindate={param[element].mindate}
            />
        );
  });
}
const RenderVendorDetail = () => {
    return (
        <React.Fragment>
            <div className="detailTitle">Vendor Detail</div>
            <div className="detailText">
                {<><b>{`Vendor Id: `}</b>{vendorDetail.vendorId}</>}
                <br />
                {vendorDetail.address !== null ? <><b>{`Address: `}</b> {vendorDetail.address}</> : null },
                <br /> {vendorDetail.city}, {vendorDetail.state}, {vendorDetail.zipCode !== null ? ` - ${vendorDetail.zipCode}` : null}
                <br /> <b>{vendorDetail.mobile !== null || vendorDetail.phone !== null ? `\nPhone: ` : null}</b>
                {vendorDetail.mobile !== null ? vendorDetail.mobile : null}
                {vendorDetail.phone !== null ? `, ${vendorDetail.phone} ` : null}
                {vendorDetail.email !== null ? <br /> : null}
                {vendorDetail.email !== null ? <><b>{`Email Id:`}</b>{vendorDetail.email}</> : null}
                {vendorDetail.gstNo !== null ? <br /> : null}
                {vendorDetail.gstNo !== null ? <><b>{`GST No: `}</b>{vendorDetail.gstNo}</> : null}
            </div>
        </React.Fragment>
    );
};

const updateEditQty=(rowIndex,value)=>{
    let eDc=lineItems.slice(); 
    eDc =update(eDc,{[rowIndex]:{["editQty"]:{$set: parseFloat(value) || 0} } })
    setLineItems(eDc);
}
const updateLineItems=(action,rowIndex,value)=>{
    let lineCopy = lineItems.slice();
   if(action === "increment"){
      let incremented = lineCopy[rowIndex]["previousDc"]+value;
           lineCopy=update(lineCopy,{[rowIndex]:{["previousDc"]: {$set: incremented}  , 
                                                ["currentDc"]: {$set: incremented}  }   
                                                })
   } else if(action === "decrement"){
    let decremented = lineCopy[rowIndex]["previousDc"]-value;
    lineCopy=update(lineCopy,{[rowIndex]:{["previousDc"]: {$set: decremented}  , 
                              ["currentDc"]: {$set: decremented}  }   
    })
   }
   setLineItems(lineCopy);
}
    const renderErrorMessage = () => {
        if (dcError.length > 0) return dcError[0];
      };

      const submitOkClick = () => {
        setIsOpen(false);
        if (dcError.length === 0) {
            setSection("dcList");
        }
    };

      return ( 
        <React.Fragment>
          <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
          <div className="formArea">
            <div
              style={{
                width: "1000px",
                margin: "0 auto 2rem",
                padding: "2rem",
                border: "1px solid lightgray",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
                <div style={{ display: "flex" }}>
                    <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                        {renderFormElements({elementList: dcParamsList,param: dcParams,section: "general"})}
                    </div>
                    <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showVendorDetail ? <RenderVendorDetail /> : null}</div>
                            </div>
                    </div>
                </div>
              {lineItems.length > 0 ? (
            <table
              className="createItemPurchaseTable"
              style={{ margin: "2rem auto" }}>
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className="stickyFirstColumn">Item Name</td>
                  <td>Item Code</td>
                  { isLogged.showHideWeight ? <> <td>Weight From(gms)</td>
                  <td>Weight To(gms)</td>
                  <td>Net Weight(gms)</td>
                  <td>Gross Weight(gms)</td> </> : null}
                  <td>UOM</td>
                  <td>Allowed DC Qty</td>
                  <td>Total Dc Qty</td>
                  <td>Current Dc Qty</td>
                  <td>Edit Quantity</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsParamsList.map((col, i) => {
                          if(["from","to","netWeight","grossWeight"].includes(col))
                     return   isLogged.showHideWeight ? 
                                <td key={i}>{row[col] !==null ? row[col] : "-"}</td> : null
                          else
                    return  <td key={i} className={col==="itemName" ? "stickyFirstColumn" : ''}>
                                {row[col] !==null ? row[col] : "-"}
                            </td>} ) }

                          <td  key={j} style={{ display:"flex",maxWidth:"10px",justifyContent:"center" }}>
                              <button onClick={(e)=>{updateLineItems("increment",j,lineItems[j].editQty)}}>+</button>

                                 <InputField type="float" value={lineItems[j].editQty} align="center" boxWidth="90px"
                                        setName={(value) => updateEditQty(j,parseFloat(value) || 0)}/>
                              
                              <button onClick={(e)=>{updateLineItems("decrement",j,lineItems[j].editQty)}}>-</button>
                          </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
          <div className="poNetTotalSurchargeDiv">
            <div className="dcInstruction" style={{width:'400px'}}>
              <div style={{ marginTop: "10px" }}>
                Instruction
              </div>
              <textarea
                className="multilineInput"
                value={instruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button
            className="submitButton"
            onClick={() => {
                setDcError([]);
              checkErrors();
            }}>
            {editApprove ? "Edit & Approve" : "Submit for Approval"}
          </button>
        </div>
      </div>
    </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      poInfo: state.poInfo,
      storeInfo: state.storeInfo
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditDc);
