import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { editMaterialRequisitionId, setMaterialRequisitionTab, viewMaterialRequisitionId } from "../../redux/PurchaseOrder/PoInfoActions";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FormElement from "../SmallComponents/FormElement";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";

const MaterialRequisitionList=(
    {
        setSection,
        setApproval,
        isLogged,
        poInfo,
        storeInfo,
        setMaterialRequisitionTab,
        addTokenToState,
        viewMaterialRequisitionId,
        editMaterialRequisitionId,
        setEditAndApprove
    }
) =>{
    const [materialRequisitionList, setMaterialRequisitionList] = useState([]);
    const [approvedMaterialRequisitionList, setApprovedMaterialRequisitionList] = useState([]);
    const [plantId,setPlantId] = useState("");

    const [loading, setLoading] = useState(true);
    const [noMrRecords, setNoMrRecords] = useState(false);
    const [noMrApprovedRecords, setNoMrApprovedRecords] = useState(false);
    const [PageSize,setRowsPerPage]=useState(10);
    const [currentPage,setCurrentPage]=useState(1);

    const MRSUnApprovedCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return materialRequisitionList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,materialRequisitionList,PageSize]);
      const MRSApprovedCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return approvedMaterialRequisitionList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,approvedMaterialRequisitionList,PageSize]);
    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getMaterialRequisitionList();
    }

    const history = useHistory();

    async function checkToken() {
    
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }


    async function getMaterialRequisitionList() {
        var data = await fetchData({
            requestingPage: "MRSList",
            method: "post",
            url: "mrs-fetch/request-unapproved-list",
            headers: { token: isLogged.accessToken,module: "Material Requisition" },
            data: {plantId}
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoMrRecords(true);
            }

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setMaterialRequisitionList(data2);
        }
        setLoading(false);
    }

    async function getApprovedMaterialRequisitionList() {
        var data = await fetchData({
            requestingPage: "MRSList",
            method: "post",
            url: "mrs-fetch/request-approved-list",
            headers: { token: isLogged.accessToken,module: "Material Requisition" },
            data: {plantId}
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoMrApprovedRecords(true);
            }

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setApprovedMaterialRequisitionList(data2);
        }
        setLoading(false);
    }

    const tablist = [
        { id: "materialRequisitionList", name: "Material Requisition List" },
        { id: "approvedMaterialRequisitionList", name: "Approved Material Requisition List" },
    ];
    const access = isLogged.access["Material Requisition"];
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={poInfo.materialRequisitionTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setMaterialRequisitionTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };

    useEffect(async () => {
        setLoading(true);
        await checkToken();

        switch (poInfo.materialRequisitionTab) {
            case "materialRequisitionList":
                setMaterialRequisitionList([]);
                getMaterialRequisitionList();
                break;

            case "approvedMaterialRequisitionList":
                setApprovedMaterialRequisitionList([]);
                getApprovedMaterialRequisitionList([]);
                break;

            default:
                break;
        }
    }, [poInfo.materialRequisitionTab,plantId]);

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "materialRequisitionList":
                listCopy = materialRequisitionList.slice();
                break;

            case "approvedMaterialRequisitionList":
                listCopy = approvedMaterialRequisitionList.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "materialRequisitionList":
                setMaterialRequisitionList(listCopy);

            case "approvedMaterialRequisitionList":
                setApprovedMaterialRequisitionList(listCopy);
                break;
        }
    };

    const renderMrsList = () => {
        return MRSUnApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr className="vendorList"
                        key={item.requestNo}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse("materialRequisitionList", i);
                        }} >

                        <td className="vendorListInfo">{item.requestNo}</td>
                        <td className="vendorListInfo">{item.orderDate}</td>

                        <td className="vendorListInfo">{item.expectDate}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Material Requisition Request"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSection("materialRequisitionDetail");
                                    setApproval(false);
                                    viewMaterialRequisitionId(item.requestId);
                                }}
                            />

                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={approveIcon}
                                title={access === "App/Dec"? "Approve Material Requisition Request" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access === "App/Dec"){
                                    setSection("materialRequisitionDetail");
                                    setApproval(true);
                                    viewMaterialRequisitionId(item.requestId);}
                                }}
                                style={access === "App/Dec" ? {cursor: "pointer"}: {cursor:"not-allowed"}}
                            />

                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec"? editIcon : editIconDisabled}
                                title={access === "Edit"|| access === "App/Dec" ? "Edit Material Requisition Request" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access === "Edit" || access === "App/Dec"){
                                    setSection("editMaterialRequisition");
                                    editMaterialRequisitionId(item.requestId);
                                    setEditAndApprove(false); }
                                }}
                                style= {access === "Edit" || access === "App/Dec"? {cursor: "pointer"} : {cursor: "not-allowed"}}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("materialRequisitionList", i);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>{!item.collapse ? <RenderSubTable tab="unapprovedMrs" data={item.lineItems} /> : null}</tr>
                    
                </React.Fragment>
            );
        });
    };

    const renderApprovedMrsList = () => {
        return MRSApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr className="vendorList"
                        key={item.requestNo}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse("approvedMaterialRequisitionList", i);
                        }}>

                        <td className="vendorListInfo">{item.requestNo}</td>
                        <td className="vendorListInfo">{item.orderDate}</td>

                        <td className="vendorListInfo">{item.expectDate}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View MRS Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setApproval(false);
                                    setSection("materialRequisitionDetail");
                                    viewMaterialRequisitionId(item.requestId);
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("approvedMaterialRequisitionList", i);
                                }}
                            />
                        </td>
                    </tr>
                    <tr> {!item.collapse ? <RenderSubTable  tab="approvedMrs" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab==="unapprovedSo"? "7" : "8"} className="vendorListInnerTableWrapper" >
                <table  className="vendorListInnerTable" >
                    <thead >
                        <tr className="vendorListInnerTableHeader" >
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            {isLogged.showHideWeight ? <>     <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td> </> : null}
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.invoiceLineItemId} >
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
        {isLogged.showHideWeight ? <>       <td className="vendorListInnerTableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </> : null}
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };
    const [order, setOrder] = useState({ col: "requestNo", state: true, data: "" });

    const CommonColumns = ({ data }) => {
        return (
            <>
                <td
                    className="vendorListHeading stickyFirstColumn"
                    onClick={(e) => {
                        setOrder({
                            col: "requestNo",
                            state: !order.state,
                        });
                        handleSort({
                            data,
                            col: "requestNo",
                            state: order.state,
                        });
                    }}>
                    Request No
                    <img
                        className="sortArrow"
                        src={
                            order.col === "requestNo"
                                ? order.state
                                    ? SortUpGreen
                                    : SortDownGreen
                                : null
                        }
                    />
                </td>
                <td
                    className="vendorListHeading"
                    onClick={(e) => {
                        setOrder({
                            col: "orderDate",
                            state: !order.state,
                        });
                        handleSort({
                            data,
                            col: "orderDate",
                            state: order.state,
                        });
                    }}>
                    Order Date
                    <img
                        className="sortArrow"
                        src={
                            order.col === "orderDate"
                                ? order.state
                                    ? SortUpGreen
                                    : SortDownGreen
                                : null
                        }
                    />
                </td>
                <td
                    className="vendorListHeading"
                    onClick={(e) => {
                        setOrder({
                            col: "expectedDate",
                            state: !order.state,
                        });
                        handleSort({
                            data,
                            col: "expectedDate",
                            state: order.state,
                        });
                    }}>
                    Expected Date
                    <img
                        className="sortArrow"
                        src={
                            order.col === "expectedDate"
                                ? order.state
                                    ? SortUpGreen
                                    : SortDownGreen
                                : null
                        }
                    />
                </td>
                <td
                    className="vendorListHeading"
                    onClick={(e) => {
                        setOrder({
                            col: "createdBy",
                            state: !order.state,
                        });
                        handleSort({
                            data,
                            col: "createdBy",
                            state: order.state,
                        });
                    }}>
                    Created by
                    <img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen
                                    : SortDownGreen
                                : null
                        }
                    />
                </td>
            </>
        );
    };
    const renderSubSection = () => {
        switch (poInfo.materialRequisitionTab) {
            case "materialRequisitionList":
                return (
                  <React.Fragment>
                    <table className="vendorListTable">
                      <thead>
                        <tr className="vendorListHeader" style={{ zIndex: 1 }}>
                          <CommonColumns data={MRSUnApprovedCurrentPage} />
                          <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                      </thead>
                      <tbody>
                        {materialRequisitionList.length > 0
                          ? renderMrsList()
                          : null}
                      </tbody>
                    </table>
                    {noMrRecords ? (
                      <div className="noRecordsYet">No items available</div>
                    ) : loading ? (
                      <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div>
                    ) : null}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={materialRequisitionList.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </React.Fragment>
                );

            case "approvedMaterialRequisitionList":
                return (
                  <React.Fragment>
                    <table className="vendorListTable">
                      <thead>
                        <tr className="vendorListHeader" style={{ zIndex: 1 }}>
                          <CommonColumns data={MRSApprovedCurrentPage} />
                          <td
                            className="vendorListHeading"
                            onClick={(e) => {
                              setOrder({
                                col: "approvedBy",
                                state: !order.state,
                              });
                              handleSort({
                                data: MRSApprovedCurrentPage,
                                col: "approvedBy",
                                state: order.state,
                              });
                            }}>
                            Approved By
                            <img
                              className="sortArrow"
                              src={
                                order.col === "approvedBy"
                                  ? order.state
                                    ? SortUpGreen
                                    : SortDownGreen
                                  : null
                              }
                            />
                          </td>
                          <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                      </thead>
                      <tbody>
                        {approvedMaterialRequisitionList.length > 0
                          ? renderApprovedMrsList()
                          : null}
                      </tbody>
                    </table>
                    {noMrApprovedRecords ? (
                      <div className="noRecordsYet">No items available</div>
                    ) : loading ? (
                      <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div>
                    ) : null}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={approvedMaterialRequisitionList.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </React.Fragment>
                );
        }
    };


    return (
      <React.Fragment>
        <div key={1} className="poListTabs">
          <RenderTabList key={1} tablist={tablist} />
        </div>
        <div
          style={{ display: "inline-flex", marginLeft: "25px" }}
          className="listPageParamsGrid">
          <span className="listPageParams">
            <FormElement
              setInput={(value) => {
                setPlantId(value);
              }}
              inputType={"options"}
              value={plantId}
              hintText={"Plant Name"}
              options={isLogged.plantOptions}
              colSpan={1}
              rowSpan={1}
              mandatory={false}
              error={false}
              errorMessage=""
              disabled={false}
            />
          </span>
          <span style={{ flexGrow: 0, width: "80px" }}>
            <FormElement
              setInput={(value) => {
                setRowsPerPage(value);
              }}
              inputType={"number"}
              value={PageSize}
              hintText={"Rows Per Page"}
              colSpan={0.5}
              rowSpan={1}
              mandatory={false}
              error={false}
              errorMessage=""
              disabled={false}
            />
          </span>
        </div>
        <div key={2} className="vendorListArea">
          {" "}
          {renderSubSection()}
        </div>
      </React.Fragment>
    );

};
const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
        storeInfo: state.storeInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        setMaterialRequisitionTab: (tab) => dispatch(setMaterialRequisitionTab(tab)),
        viewMaterialRequisitionId: (materialRequisitionId) => dispatch(viewMaterialRequisitionId(materialRequisitionId)),
        editMaterialRequisitionId: (materialRequisitionId) => dispatch(editMaterialRequisitionId(materialRequisitionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialRequisitionList);
