import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import {
  editSoId,
  editSoReturnId,
} from "../../redux/PurchaseOrder/PoInfoActions";
import DeclineModal from "../SmallComponents/DeclineModal";
import Modal from "react-modal";

const SalesOrderDetail = ({
  approval,
  poInfo,
  isLogged,
  addTokenToState,
  setSection,
  setEditAndApprove,
  editSalesOrderReturnId,
}) => {
  const [soReturnDetail, setSoReturnDetail] = useState({});
  const [lineItemsList, setLineItemsList] = useState([]);
  const [customerLogo, setCustomerLogo] = useState([]);

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  async function getInformation() {
    await checkToken();
    await getSoReturnDetail();
  }

  const history = useHistory();

  async function checkToken() {
 
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getSoReturnDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "so-fetch/sales-return-detail/" + poInfo.viewSoReturnId,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      setSoReturnDetail(data.asset[0]);
      setLineItemsList(data.asset[0].lineItems);
    } else {
      console.log(data);
    }
  }

  useEffect(() => {
    getCustomerLogo();
    // eslint-disable-next-line
  }, [soReturnDetail]);

  async function getCustomerLogo() {
    var data = await fetchData({
      requestingPage: "vendorImage",
      method: "get",
      url: "image/customer-logo/" + soReturnDetail.customerId,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    setCustomerLogo(data);
  }

  const [returnDocument, setReturnDocument] = useState("");
  useEffect(() => {
    getReturnDocument();
    // eslint-disable-next-line
  }, [soReturnDetail]);

  async function getReturnDocument() {

    if (soReturnDetail?.returnDocument !== null) {
      var data = await fetchData({
        requestingPage: "itemImage",
        method: "get",
        url: "image/sales-return-document/" + soReturnDetail.returnId,
        headers: { token: isLogged.accessToken, module: "Sales Order" },
      });

      if (data !== "") {
        setReturnDocument(data);
      }
    }
  }

  const renderSummaryInfo = () => {
    if (soReturnDetail !== null) {
      return (
        <React.Fragment>
          <span
            className="vendorSummaryRole"
            style={{ textTransform: "capitalize" }}>
            {soReturnDetail.returnNo}{" "}
            <span
              className="vendorSummaryCategory"
              style={
                soReturnDetail.returnStatus === "Declined"
                  ? { color: "red" }
                  : soReturnDetail.returnStatus === "Approved"
                  ? { color: "#14c76a" }
                  : { color: "#333333" }
              }>
              ( {soReturnDetail.returnStatus} )
            </span>
          </span>
          <div className="vendorSummaryGrid">
            {/* <span className="vendorSummaryCategory">PO Number</span> */}

            <span className="vendorSummaryCategory">Sales Order No</span>
            <span className="vendorSummaryValue">: {soReturnDetail.soNo}</span>

            <span className="vendorSummaryCategory">Order Date</span>
            <span className="vendorSummaryValue">
              : {soReturnDetail.soDate}
            </span>

            <span className="vendorSummaryCategory">
              Expected Delivery Date
            </span>
            <span className="vendorSummaryValue">
              : {soReturnDetail.expectedDeliveryDate}
            </span>

            <span className="vendorSummaryCategory">Return Type</span>
            <span className="vendorSummaryValue">
              : {soReturnDetail.returnType}
            </span>

            <span className="vendorSummaryCategory">Return Date</span>
            <span className="vendorSummaryValue">
              : {soReturnDetail.returnDate}
            </span>

            <span className="vendorSummaryCategory">Transporter Name</span>
            <span className="vendorSummaryValue">
              :{" "}
              {soReturnDetail.transporterName === null ||
              soReturnDetail.transporterName === ""
                ? "NA"
                : soReturnDetail.transporterName}
            </span>

            {/* <span className="vendorSummaryCategory">Store Details</span>
                        <span className="vendorSummaryValue">: {soReturnDetail.storageAddress}</span> */}
          </div>
        </React.Fragment>
      );
    }
  };

  const renderImageStatus = () => {
    return (
      <div>
        <span style={{ flex: "1" }}>&nbsp;</span>

        <img
          className="poVendorImage"
          src={
            customerLogo !== null &&
            customerLogo !== undefined &&
            customerLogo !== ""
              ? `data:image/png;base64,${customerLogo}`
              : logoNotAvailable
          }
          alt="Vendor Logo"
        />
      </div>
    );
  };

  const RenderTable = () => {
    return (
      <table className="vendorDetailContactsTable">
        <thead>
          <tr className="vendorDetailContactsTableHeader">
            <td>Item</td>
            {isLogged.showHideWeight ? (
              <>
                <td>Weight From(gms)</td>
                <td>Weight To(gms)</td>
                <td>Net Weight(gms)</td>
                <td>Gross Weight(gms)</td>
              </>
            ) : null}
            <td>UOM</td>
            <td>HSN No</td>
            {/* <td>Quantity</td> */}
            {/* <td>Unit Price</td>
                        <td>Discount (%)</td>
                        <td>Tax (%)</td>
                        <td>Net Price</td> */}
            <td>Return Quantity</td>
            <td>Paid Amount</td>
          </tr>
        </thead>
        <tbody>
          <RenderTableRows rows={lineItemsList} tab="contact" />
        </tbody>
      </table>
    );
  };

  async function approveSoReturn() {
    setIsOpen(true);
    setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "approvePo",
      method: "put",
      url: `so-edit/sales-return-approve/${poInfo.viewSoReturnId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Approved Sales Order Return");
    } else {
      setModalText(data.desc);
      setErrorMessage(data.desc);
    }
  }

  const RenderTableRows = ({ rows, tab }) => {
  

    var lineItemParams = isLogged.showHideWeight
      ? [
          "itemName",
          "from",
          "to",
          "netWeight",
          "grossWeight",
          "uom",
          "hsnCode",
          // "quantity",
          // "unitPrice", "discount", "tax", "netPrice",
          "quantity",
          "payAmount",
        ]
      : [
          "itemName",
          "uom",
          "hsnCode",
          // "quantity",
          // "unitPrice", "discount", "tax", "netPrice",
          "quantity",
          "payAmount",
        ];

    return rows.map((row, j) => {
      return (
        <tr className="vendorDetailContactsTableRows" key={j}>
          {lineItemParams.map((key, i) => {
            return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
          })}
        </tr>
      );
    });
  };

  const [errorMessage, setErrorMessage] = useState([]);

  const renderErrorMessage = () => {
    if (errorMessage.length > 0) {
      return errorMessage[0];
    }
  };
  const ApproveButtons = () => {
    return (
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="saveButton"
            onClick={() => {
              setEditAndApprove(true);
              setSection("editSalesReturn");
              editSalesOrderReturnId(soReturnDetail.returnId);
            }}>
            Edit
          </button>

          <button
            className="submitButton"
            onClick={() => {
              approveSoReturn();
            }}>
            Approve
          </button>
          {/* <button
                        className="declineButton"
                        onClick={() => {
                            setDeclineModalIsOpen(true);
                        }}
                    >
                        Decline
                    </button> */}
        </div>
      </div>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (errorMessage.length === 0) {
      setSection("salesOrderList");
    }
    setModalText("Approving SO Return. Please wait...");
  };

  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [declineModalText, setDeclineModalText] = useState(
    "Provide a reason for SO Decline"
  );
  const [declineReason, setDeclineReason] = useState("");
  const [declineReasonError, setDeclineReasonError] = useState(false);

  const declineOkClick = () => {
    if (declineReason.trim().length > 0) {
      setDeclineReasonError(false);
      setDeclineModalIsOpen(false);

      setIsOpen(true);
      setModalText("Declining SO. Please Wait...");
      declineSo();
    } else {
      setDeclineReasonError(true);
    }
  };

  async function declineSo() {
    setIsOpen(true);
    setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "declinePo",
      method: "put",
      url: `so-edit/so-decline/${poInfo.viewSoId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: { reason: declineReason },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Declined Sales Order Return");
    } else {
      setModalText(data.desc);
      setErrorMessage(data.desc);
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Approving Sales Order Return. Please wait..."
  );

  const [diagramImage, setDiagramImage] = useState("");
  const [diagramModalOpen, setDiagramModalOpen] = useState(false);
  // const [returnDocument, setReturnDocument] = useState("");

  return (
    <React.Fragment>
      <DeclineModal
        openDeclineModal={declineModalIsOpen}
        declineOkClick={declineOkClick}
        declineModalText={declineModalText}
        declineReason={declineReason}
        error={declineReasonError}
        setDeclineReason={setDeclineReason}
        cancelDecline={() => setDeclineModalIsOpen(false)}
      />

      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />

      <Modal
        isOpen={diagramModalOpen}
        onRequestClose={() => setDiagramModalOpen(false)}
        contentLabel="Example Modal"
        style={{
          overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
          content: {
            textAlign: "center",
            width: "70%",
            height: "70%",
            position: "fixed",
            top: "15%",
            left: "15%",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0 0 5px gray",
          },
        }}>
        <img
          className="modalImage"
          src={`data:image/jpeg;base64,${diagramImage}`}
          alt="Item Diagram"
        />
      </Modal>
      <div className="detailsPageWrapper">
        <div className="detailsPageContainer">
          <div className="vendorSummary">
            <div className="poSummaryText">{renderSummaryInfo()}</div>
            <div className="poSummaryText2">
              <span
                className="vendorSummaryRole"
                style={{ textTransform: "uppercase" }}>
                {soReturnDetail.customerName}
              </span>

              <span className="poSummaryValue">
                {`Customer ID: ${soReturnDetail.customerId}`}
              </span>
              <span className="poSummaryValue">
                {soReturnDetail.customerPinCode !== null
                  ? ` ${soReturnDetail.customerAddress} - ${soReturnDetail.customerPinCode}`
                  : soReturnDetail.customerAddress}
              </span>
              <span className="poSummaryValue">
                {soReturnDetail.customerMobile !== null ||
                soReturnDetail.customerPhone !== null
                  ? `\nPhone: `
                  : null}
                {soReturnDetail.customerMobile !== null
                  ? soReturnDetail.customerMobile
                  : null}
                {soReturnDetail.customerPhone !== null
                  ? `, ${soReturnDetail.customerPhone} `
                  : null}
              </span>
              <span className="poSummaryValue">
                {soReturnDetail.customerEmail !== null
                  ? `Email Id: ${soReturnDetail.customerEmail}`
                  : null}
              </span>

              <span className="poSummaryValue">
                {soReturnDetail.customerGstNo !== null
                  ? `GST No: ${soReturnDetail.customerGstNo}`
                  : null}
              </span>

              {/* <span className="poSummaryValue">
                                Quotation No: {soReturnDetail.quotationNo === null || soReturnDetail.quotationNo === "" ? "NA" : soReturnDetail.quotationNo}
                            </span>

                            <span className="poSummaryValue">
                                Quotation Date: {soReturnDetail.quotationDate === null || soReturnDetail.quotationDate === "" ? "NA" : soReturnDetail.quotationDate}
                            </span> */}
            </div>
            {renderImageStatus()}
          </div>

          {soReturnDetail.returnStatus === "Declined" ? (
            <div style={{ marginTop: "2rem" }}>
              <span style={{ color: "red", fontWeight: "bold" }}>
                Reason :{" "}
              </span>{" "}
              {soReturnDetail.declineReason}
            </div>
          ) : null}

          <div className="vendorDetailTabArea">
            <RenderTable />
          </div>

          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items: <span>{lineItemsList.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>
                Sales Instruction:{" "}
                {soReturnDetail.salesInstruction !== null
                  ? soReturnDetail.salesInstruction
                  : "NA"}
              </div>

              <div style={{ marginTop: "10px" }}>
                Sales Return Notes:{" "}
                {soReturnDetail.instruction !== null
                  ? soReturnDetail.instruction
                  : "NA"}
              </div>

              <div style={{ marginTop: "10px" }}>
                Sales Return Document:{" "}
                {soReturnDetail.returnDocument !== null ? (
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                    onClick={() => {
                      setDiagramModalOpen(true);
                      setDiagramImage(returnDocument);
                    }}>
                    Open link
                  </span>
                ) : (
                  "NA"
                )}
              </div>
            </div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poNetTotal">
              {/* <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{soReturnDetail.netTotal}</span>
                            </div>
                            <div className="SoDetailChargesDiv">
                                <div className="poText">
                                    <span>Other Charges</span>
                                    <span>{soReturnDetail.otherCharges}</span>
                                </div>

                                <div className="poText">
                                    <span>Transport Charges </span>
                                    <span>{soReturnDetail.transportCharges}</span>
                                </div>
                            </div> */}

              <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                <span>
                  <b>Total Amount</b>
                </span>
                <div className="poNetTotalAmount">
                  {soReturnDetail.totalAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {approval ? <ApproveButtons /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    editSoId: (poId) => dispatch(editSoId(poId)),
    editSalesOrderReturnId: (viewId) => dispatch(editSoReturnId(viewId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderDetail);
