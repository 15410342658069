import React, { Fragment, useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import resetIcon from "../../assets/return.svg";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import {
  validateEmail,
  validateMandatory,
  validateMobileNumber,
} from "../CommonFunctions/ValidateFields";

const CreateSalesOrder = ({ setSection, isLogged, addTokenToState }) => {
  const [newCustomer, setNewCustomer] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const salesOrderParamList = [
    ["customerId"],
    ["customerName", "mobileNo", "email"],
    [
      "soDate",
      "plantId",
      "storageId",
      "reference",
      "expectedDeliveryDate",
      "transporterName",
      "paymentMode",
      "dispatchedThrough",
      "destination"
    ],
    ["deliveryAddressId"],
    ["address","country","state","cityId"]
  ];

  const [salesOrderParams, setSalesOrderParams] = useState({
    customerId: {
      inputType: "options",
      value: "",
      hintText: "Customer Name",
      mandatory: true,
      colSpan: 12,
      error: false,
      options: [],
      errorMessage: "Please select a Customer",
    },
    customerName: {
      inputType: "text",
      value: "",
      hintText: "Customer Name",
      mandatory: true,
      colSpan: 12,
      error: false,
      errorMessage: "Please provide a customer name",
    },

    mobileNo: {
      inputType: "text",
      value: "",
      hintText: "Mobile No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Provide a valid mobile number",
    },
    email: {
      inputType: "text",
      value: "",
      hintText: "Email",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide a valid email address",
    },

    soDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Order Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a Order date",
      // mindate: true,
    },
    reference: {
      inputType: "text",
      value: "",
      hintText: "Reference",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    plantId: {
      inputType: "options",
      value: "",
      hintText: "Plant",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please pick a Plant",
    },
    storageId: {
      inputType: "options",
      value: "",
      hintText: "Store",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select Store",
    },
    expectedDeliveryDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Expected Delivery Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Select delivery date",
      // mindate: true,
    },
    transporterName: {
      inputType: "text",
      value: "",
      hintText: "Transporter Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    deliveryAddressId: {
      inputType: "options",
      value: "",
      hintText: "Delivery Address",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      options: []
    },
    address: {
      inputType: "text",
      value: "",
      hintText: "Address",
      mandatory: true,
      colSpan: 8,
      error: false,
      errorMessage: "Please provide company address",
    },
    country: {
      inputType: "options",
      value: "",
      hintText: "Country",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select country",
  },
  state: {
      inputType: "options",
      value: "",
      hintText: "State",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select state",
  },
  cityId: {
      inputType: "options",
      value: "",
      hintText: "City",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select city",
  },paymentMode:{
    inputType: "options",
    value: "",
    hintText: "Payment Mode",
    mandatory: false,
    colSpan: 6,
    error: false,
    errorMessage: "",
    options: [{optionId: "Paid",optionName: "Paid"},
              {optionId: "To-Pay",optionName: "To-Pay"}]
  },
  dispatchedThrough: {
    inputType: "text",
    value: "",
    hintText: "Dispatched Through",
    mandatory: false,
    error :false,
    colSpan: 6,
    errorMessage: "" 
  },
  destination: {
    inputType: "text",
    value: "",
    hintText: "Destination",
    mandatory: false,
    error :false,
    colSpan: 6,
    errorMessage: "" 
  }
  });
  const [isTaxable, setIsTaxable] = useState(true)

  let lineItemsParamsList = 
    [
      "itemId",
      "hsnNo",
      "uomId",
      "quantity",
      "unitPrice",
      // "otherCharges",
      "discount",
      "tax",
      "taxAmount",
      "netPrice",
      "lastSaleUnitPrice",
      "remarks"
    ];
  const [lineItemParams, setLineItemParams] = useState({

  
    itemId: {
      inputType: "options",
      value: "",
      hintText: "Item",
      mandatory: true,
      colSpan: 6,
      options: [],
      error: false,
      errorMessage: "Select an Item",
      callServer: true,
      filterOptions: (optionObject, input) => {
        if (input) {
          return optionObject.label.toLowerCase().includes(input.toLowerCase())
        }
        return true;
      }
    },

    hsnNo: {
      inputType: "text",
      value: "",
      hintText: "HSN No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    uomId: {
      inputType: "options",
      value: "",
      hintText: "UOM",
      mandatory: true,
      colSpan: 6,
      options: [],
      error: false,
      errorMessage: "Pick a UOM",
    },
    quantity: {
      inputType: "number",
      value: "",
      hintText: "Quantity",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid quantity",
    },
    // salePrice: {
    //     inputType: "text",
    //     value: "",
    //     hintText: "Sale Price",
    //     mandatory: false,
    //     colSpan: 4,
    //     error: false,
    //     errorMessage: "Select Order date above to autofill this field",
    //     disabled: true,
    // },
    unitPrice: {
      inputType: "float",
      value: "",
      hintText: "Unit Price",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid price",
    },
    tax: {
      inputType: "text",
      value: "",
      hintText: "Tax (%)",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Tax not declared for this Item",
      disabled: true,
    },
    taxAmount: {
      inputType: "text",
      value: "",
      hintText: "Tax Amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    discount: {
      inputType: "float",
      value: "",
      hintText: "Discount (%)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid discount percent",
      disabled: false,
    },
    netPrice: {
      inputType: "text",
      value: "",
      hintText: "Net Price",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    lastSaleUnitPrice: {
      inputType: "text",
      value: "",
      hintText: "Last Invoice Price",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    // otherCharges: {
    //   inputType: 'float',
    //   value: "",
    //   hintText: "Premium Charges",
    //   mandatory: false,
    //   colSpan: 6,
    //   error: false,
    //   errorMessage: "Enter Valid Amount",
    //   disabled: false
    // },
    remarks:{
      inputType: 'text',
      value: "",
      hintText: "Remarks",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: false
    }

  });

  const [lineItemsList, setLineItemsList] = useState([]);

  const [transportChargesError, setTransportChargesError] = useState(false);
  const [otherChargesError, setOtherChargesError] = useState(false);

  const [salePriceTypes, setSalePriceTypes] = useState([]);
  const [cuttingLength, setCuttingLength] = useState([])

  const [customerDetail, setCustomerDetail] = useState({});
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);

  const [storeDetail, setStoreDetail] = useState({});
  const [showStoreDetail, setShowStoreDetail] = useState(false);

  const [storageLocList, setStorageLocList] = useState({});
  const [itemUomList, setItemUomList] = useState({});
  const [itemsTotalCost, setItemsTotalCost] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [taxPer, setTaxPer] = useState(0);
  const [transportCharges, settransportCharges] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [salesInstruction, setSalesInstruction] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [finalDiscount, setFinalDiscount] = useState(0);
  const [finalDiscountError, setFinalDiscountError] = useState("");
  const [showDiscount, setShowDiscount] = useState(false);
  const [itemList, setItemList] = useState()
  useEffect(() => {
    getInformation();
  }, []);

  const getInformation = async () => {
    await checkToken();
    var customerList = await getCustomerList();
    // let cuttingLength = await getCuttingLength();
    var dataDim = await getDim({ field: "country" });
    var parsedDataDim = await parseDim(dataDim, "country");
    var [plantList, storageLocListFormatted] = await getPlants();


    var [itemList, itemUomListFormatted] = await getItemUom();

    var salesParamsCopy = Object.assign(salesOrderParams);
    salesParamsCopy = update(salesParamsCopy, {
      country: { options: { $set: parsedDataDim }, value: { $set: "" } },
      customerId: { options: { $set: customerList } },
      plantId: { options: { $set: plantList } },
    });
    setSalesOrderParams(salesParamsCopy);
    setStorageLocList(storageLocListFormatted);

    var lineItemParamsCopy = Object.assign(lineItemParams);
    lineItemParamsCopy = update(lineItemParamsCopy, {
      itemId: { options: { $set: itemList } },
    });
    setLineItemParams(lineItemParamsCopy);
    setItemUomList(itemUomListFormatted);
    setItemList(itemList)
  };

  async function getState() {
    if (salesOrderParams.country.value !== "") {
        var dataDim = await getDim({ field: "state", filterBy: "country_id", filterValue: salesOrderParams.country.value });
        var parsedDataDim = await parseDim(dataDim, "state");
        var salesOrderParamsCopy = Object.assign(salesOrderParams);
        salesOrderParamsCopy = update(salesOrderParamsCopy, {
            state: {
                options: { $set: parsedDataDim },
                value: { $set: "" },
            },
        });

        setSalesOrderParams(salesOrderParamsCopy);
    }
}

useEffect(()=>{
  let taxPercentage =0;
  if(lineItemsList.length){
    let filter=[];

     lineItemsList.map(item=>{
      if(!filter.includes(item.tax)){
        filter.push(item.tax)
      }
    });
  
     taxPercentage = Math.max(...filter);
  }else{
   taxPercentage =0
  }
  setTaxPer(taxPercentage)
},[lineItemsList.length])

async function getCity() {
  if (salesOrderParams.state.value !== "") {
      var dataDim = await getDim({ field: "city", filterBy: "state_id", filterValue: salesOrderParams.state.value });
      var parsedDataDim = await parseDim(dataDim, "cityId");
      var salesOrderParamsCopy = Object.assign(salesOrderParams);
      salesOrderParamsCopy = update(salesOrderParamsCopy, {
          cityId: {
              options: { $set: parsedDataDim },
              value: { $set: "" },
          },
      });

      setSalesOrderParams(salesOrderParamsCopy);
  }
}

useEffect(() => {
  getState();
  // eslint-disable-next-line
}, [salesOrderParams.country]);

useEffect(() => {
  getCity();
  // eslint-disable-next-line
}, [salesOrderParams.state]);

  async function getCategory() {
    var categoryData = await fetchData({
      requestingPage: "getCategory",
      method: "get",
      url: "fetch/categories",
      headers: { token: isLogged.accessToken, module: "Dropdowns" },
    });

    var categoryList = [];
    var subCategoryList = {};

    if (categoryData.msg === "success") {
      categoryData.asset.forEach((categ) => {
        var catObj = {};
        catObj.optionId = categ.category.categoryId;
        catObj.optionName = categ.category.categoryName;
        categoryList.push(catObj);

        subCategoryList[categ.category.categoryId] = [];

        categ.subCategory.forEach((subCateg) => {
          var subCatObj = {};
          subCatObj.optionId = subCateg.subCategoryId;
          subCatObj.optionName = subCateg.subCategoryName;

          subCategoryList[categ.category.categoryId].push(subCatObj);
        });
      });
      return [categoryList, subCategoryList];
    }
  }

  useEffect(()=>{

    let lineItemParamsCopy = Object.assign(lineItemParams);
    if(lineItemParams.unitPrice.value && lineItemParams.tax.value){
      let taxAmount = 0 ;
      if(lineItemParams.unitPrice.value){
        taxAmount = (lineItemParams.unitPrice.value*(lineItemParams.tax.value/100).toFixed(2)).toFixed(2)
       }
      lineItemParamsCopy = update(lineItemParamsCopy,{taxAmount:{value:{$set:taxAmount}}})
      setLineItemParams(lineItemParamsCopy);

    }
  },[lineItemParams.unitPrice.value,lineItemParams.tax.value])

  async function getLastSaleUnitPrice(data) {
    var result = await fetchData({
      url: 'so-fetch/last-unit-price-of-item',
      method: "post",
      data,
      requestingPage: "createPoItem",
      headers: { token: isLogged.accessToken, module: "Sales Order" }
    });

    if (result.msg === 'success') {
      let { invoiceUnitPrice, createdDate } = result.asset;
      if (invoiceUnitPrice && createdDate) {
        return `${createdDate} : ₹${invoiceUnitPrice}`
      }
      else {
        return " ";
      }
    } else {
      return " ";
    }
  }

  useEffect(() => {
    let lineItemParamsCopy = Object.assign(lineItemParams);
    if ( lineItemParams.itemId.value) {

      getLastSaleUnitPrice({
        customerId: salesOrderParams.customerId.value || 0,
        itemId: lineItemParams.itemId.value
      }).then(async (resp) => {
        lineItemParamsCopy = update(lineItemParamsCopy, {
          ["lastSaleUnitPrice"]: { value: { $set: resp } }, uomId: {
            options: { $set: itemUomList[lineItemParams.itemId.value] },
            // value: { $set: "" } 
          }, ["unitPrice"]: { value: { $set: parseFloat(resp.split(': ₹')[1]) || "" } },
          // taxAmount:{value:{$set:taxAmount}}
        })

        /// if line item changes fetch detail by so date
        if (salesOrderParams.soDate.value && lineItemParamsCopy.itemId.callServer)
          lineItemParamsCopy = await getItemDetail(lineItemParamsCopy);

        setLineItemParams(lineItemParamsCopy)
      });

    } else {
      lineItemParamsCopy = update(lineItemParamsCopy, {
        ["lastSaleUnitPrice"]: { value: { $set: "" } },
        hsnNo: { value: { $set: "" } },
        tax: { value: { $set: "" } },
        taxAmount: { value: { $set: "" } },
      })
      setLineItemParams(lineItemParamsCopy)
    }
  }, [salesOrderParams.customerId.value, lineItemParams.itemId.value]);

  const history = useHistory();

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getDim(dataSent) {
    var data = await fetchData({
        requestingPage: "getDim",
        method: "post",
        url: "dim",
        headers: { token: isLogged.accessToken ,module:"Customers"},
        data: dataSent,
    });
    if (data.msg !== "failure") {
        return data;
    } else {
        return [];
    }
}

  async function parseDim(data, dimension) {
    var optionArray = [];

    switch (dimension) {
        case "country":
            data.forEach((dataItem) => {
                var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                optionArray.push(a);
            });
            break;

        case "state":
            data.forEach((dataItem) => {
                var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                optionArray.push(a);
            });
            break;

        case "cityId":
            data.forEach((dataItem) => {
                var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                optionArray.push(a);
            });
            break;

        default:
            break;
    }

    return optionArray;
}

  async function getCustomerList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: "fetch/customer-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: dataItem.customer,
          optionId: dataItem.customerId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }
  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

    }
    return [plantList, storageLocList];
  }
  const RenderStoreDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">Store Detail</div>
        <div className="detailText">
          Address: {storeDetail.address}
          <br />
          GST No: {storeDetail.gstNo}
        </div>
      </React.Fragment>
    );
  };

  async function CustomerDetailAndOptions() {
    let saleParamsCopy = Object.assign(salesOrderParams);
    if (salesOrderParams.customerId.value !== "") {
      let deliveryAddOpts = await getCustomerDetail();
      saleParamsCopy = update(saleParamsCopy, {
        deliveryAddressId: {
          options: { $set: deliveryAddOpts }
        }
      });
      setShowCustomerDetail(true);
    } else {
      saleParamsCopy = update(saleParamsCopy, {
        deliveryAddressId: {
          options: { $set: [] }, value: { $set: "" }
        }
      });
      setShowCustomerDetail(false);
    }
    setSalesOrderParams(saleParamsCopy);
  }

  useEffect(() => {
    CustomerDetailAndOptions();
  }, [salesOrderParams.customerId.value]);

  async function getCustomerDetail() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: `fetch/customer/${salesOrderParams.customerId.value}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      setCustomerDetail(data.asset.customerDetail[0]);
      return data.asset.deliveryDetail;
    }
  }

  const RenderCustomerDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">{customerDetail.companyName}</div>
        <div className="detailText">
          {`Customer ID: ${customerDetail.customerId}`},
          <br /> {customerDetail.address},
          <br /> {customerDetail.city}, {customerDetail.state}{" "}
          {customerDetail.zipCode !== null
            ? `, - ${customerDetail.zipCode}`
            : null}
          {customerDetail.mobile !== null || customerDetail.phone !== null ? (
            <br />
          ) : null}
          {customerDetail.mobile !== null || customerDetail.phone !== null
            ? `Phone: `
            : null}
          {customerDetail.mobile !== null ? customerDetail.mobile : null}
          {customerDetail.phone !== null ? `, ${customerDetail.phone} ` : null}
          {customerDetail.email !== null ? <br /> : null}
          {customerDetail.email !== null
            ? `Email Id: ${customerDetail.email}`
            : null}
          {customerDetail.gstNo !== null ? <br /> : null}
          {customerDetail.gstNo !== null
            ? `GST No:  ${customerDetail.gstNo}`
            : null}
        </div>
      </React.Fragment>
    );
  };

  async function getItemUom() {
    var itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data: { itemsTab: "Active" },
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var itemList = [];
    var itemUomList = {};
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        var itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemObj.categoryId = item.item.categoryId;
        itemList.push(itemObj);

        itemUomList[item.item.itemId] = [];
        item.uom.forEach((uom) => {
          var uomObj = {};
          uomObj.optionId = uom.uomId;
          uomObj.optionName = uom.uom;

          itemUomList[item.item.itemId].push(uomObj);
        });
      });

      return [itemList, itemUomList];
    }
  }

  const updatePOParameter = ({ paramName, section, key, value }) => {

    if (section === "general") {
      var salesParams = Object.assign(salesOrderParams);
      salesParams = update(salesParams, {
        [paramName]: { [key]: { $set: value } },
      });
      if (paramName === "plantId") {
        salesParams = update(salesParams, {
          storageId: { value: { $set: "" }, options: { $set: [] } },
        });
      }
      if (paramName === "poDate") {
        var lineParamsCopy = Object.assign(lineItemParams);
        lineParamsCopy = update(lineParamsCopy, {
          itemId: { callServer: { $set: true } },
        });
        setLineItemParams(lineParamsCopy);
      }
      setSalesOrderParams(salesParams);
    } else {
      var lineParamsCopy = Object.assign(lineItemParams);
      lineParamsCopy = update(lineParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });
      if (paramName === "itemId") {
        lineParamsCopy = update(lineParamsCopy, {
          uomId: { value: { $set: "" }, options: { $set: [] } },
          itemId: { callServer: { $set: true } },
        });
      }
      setLineItemParams(lineParamsCopy);
    }
  };


  useEffect(() => {
    var salesParamsCopy = Object.assign(salesOrderParams);

    if (salesOrderParams.plantId.value !== "") {
      salesParamsCopy = update(salesParamsCopy, {
        storageId: {
          options: { $set: storageLocList[salesOrderParams.plantId.value] },
        },
      });
      setSalesOrderParams(salesParamsCopy);
    } else {
      salesParamsCopy = update(salesParamsCopy, {
        storageId: { options: { $set: [] }, value: { $set: "" } },
      });
      setSalesOrderParams(salesParamsCopy);
    }
  }, [salesOrderParams.plantId.value]);

  useEffect(() => {
    if (salesOrderParams.storageId.value !== "") {
      setShowStoreDetail(true);
      setStoreDetail(
        storageLocList[salesOrderParams.plantId.value].filter(
          (arr) => arr.optionId === salesOrderParams.storageId.value
        )[0]
      );
    } else {
      setShowStoreDetail(false);
    }
  }, [salesOrderParams.storageId.value]);

  useEffect(() => {

    var _otherCharges = otherCharges;
    var _transportCharges = transportCharges;
    var _finalDiscount = finalDiscount;

    if (otherCharges === "") {
      _otherCharges = 0;
    }

    if (transportCharges === "") {
      _transportCharges = 0;
    }

    if (finalDiscount === "") {
      _finalDiscount = 0;
    }

   
    var returnVal =
      !isNaN(_otherCharges) && parseFloat(_otherCharges) >= 0
        ? !isNaN(_transportCharges) && parseFloat(_transportCharges) >= 0
          ? !isNaN(_finalDiscount) && parseFloat(_finalDiscount) >= 0
            ? (parseFloat(_transportCharges)+(parseFloat(_transportCharges)*(taxPer)/100) +
             parseFloat(_otherCharges)+(parseFloat(_otherCharges*18/100)) +
              itemsTotalCost) - (showDiscount ? parseFloat(_finalDiscount) : 0)
            : "-"
          : "-" : "-";


    setTotalAmount(returnVal);
  }, [otherCharges, transportCharges, itemsTotalCost, finalDiscount, showDiscount]);

  async function getItemDetail(paramCopy) {
    var data = await fetchData({
      requestingPage: "poItemDetail",
      method: "post",
      url: "so-fetch/so-line-item-pre-create",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: {
        itemId: lineItemParams.itemId.value,
        soDate: salesOrderParams.soDate.value,
      },
    });
    if (data.msg === "success") {
      paramCopy = update(paramCopy, {
        hsnNo: { value: { $set: data.asset[0].hsnCode } },
        tax: { value: { $set: data.asset[0].taxPercentage||0 } },
        taxAmount: { value: { $set: 0 } },
      });

      setSalePriceTypes(data.asset[0].salesPrice);

      return paramCopy;
    }
  }

  const [lineItemErrors, setLineItemErrors] = useState([]);
  const checkLineItemError = () => {
    var errorList = [];

    var lineItemParamsCopy = Object.assign(lineItemParams);
    lineItemsParamsList.forEach((element) => {
      if (element === "discount") {
        if (lineItemParamsCopy.discount.value !== "") {
          if (
            parseFloat(lineItemParamsCopy.discount.value) < 0 ||
            parseFloat(lineItemParamsCopy.discount.value) > 100
          ) {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: true } },
            });
          } else {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: false } },
            });
          }
        } else {
          lineItemParamsCopy = update(lineItemParamsCopy, {
            [element]: { error: { $set: false } },
          });
        }
      } else if (lineItemParamsCopy[element]?.value !== null && lineItemParamsCopy[element]?.mandatory === true) {
        lineItemParamsCopy = update(lineItemParamsCopy, {
          [element]: {
            error: {
              $set: !validateMandatory(
                lineItemParamsCopy[element]?.value.toString()
              ),
            },
          },
        });
      } else {
        if (element === "tax") {
          lineItemParamsCopy = update(lineItemParamsCopy, {
            tax: { error: { $set: true } },
          });
        }
      }

      if (
        element === "quantity" ||
        element === "unitPrice" 
      ) {
        if (lineItemParamsCopy[element].value !== "") {
          if (
            isNaN(lineItemParamsCopy[element].value) ||
            parseFloat(lineItemParamsCopy[element].value) < 0
          ) {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: true } },
            });
          } else {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: false } },
            });
          }
        }
      }

      if (lineItemParamsCopy[element].error) {
        errorList.push(lineItemParamsCopy[element].errorMessage);
      }

      setLineItemErrors(errorList);
      setLineItemParams(lineItemParamsCopy);
    });

    if (errorList.length === 0) {

      var newLineItem = {
        itemId: lineItemParamsCopy.itemId.value,
        item: lineItemParamsCopy.itemId.options.filter(
          (item) => item.optionId === lineItemParamsCopy.itemId.value
        )[0].optionName,
        hsnNo: lineItemParamsCopy.hsnNo.value,
        uomId: lineItemParamsCopy.uomId.value,
        uom: itemUomList[lineItemParamsCopy.itemId.value].filter(
          (item) => item.optionId === lineItemParamsCopy.uomId.value
        )[0].optionName,
        quantity: Number(parseFloat(lineItemParamsCopy.quantity.value).toFixed(2)),
        // cost: lineItemParamsCopy.cost.value,
        discount:
          lineItemParamsCopy.discount.value !== ""
            ? parseFloat(lineItemParamsCopy.discount.value)
            : null,
        unitPrice: parseFloat(lineItemParamsCopy.unitPrice.value),
        tax: isTaxable?lineItemParamsCopy.tax.value:0,
        taxAmount: isTaxable?lineItemParamsCopy.taxAmount.value:null,
        netPrice:
          Number(parseFloat(lineItemParams.quantity.value *
            (lineItemParams.unitPrice.value 
              // + lineItemParams.otherCharges.value 
              -
              (lineItemParams.discount.value / 100) *
              lineItemParams.unitPrice.value) +
            (isTaxable? (lineItemParams.tax.value / 100):0) *
            (lineItemParams.quantity.value *
              (lineItemParams.unitPrice.value 
                // + lineItemParams.otherCharges.value 
                -
                (lineItemParams.discount.value / 100) *
                lineItemParams.unitPrice.value))).toFixed(2)),
                
        lastSaleUnitPrice: lineItemParamsCopy.lastSaleUnitPrice.value,
        // otherCharges: lineItemParamsCopy.otherCharges.value !== ""
        //   ? parseFloat(lineItemParamsCopy.otherCharges.value)
        //   : null,
          remarks:lineItemParamsCopy.remarks.value
      };

      var lineItemsListCopy = lineItemsList;
      lineItemsListCopy.push(newLineItem);
      setLineItemsList(lineItemsListCopy);

      var itemsCost = 0;
      lineItemsListCopy.forEach((item) => {

        itemsCost = parseFloat(itemsCost) + parseFloat(item.netPrice);
      });
      setItemsTotalCost(itemsCost);

      lineItemsParamsList.forEach((element) => {
        lineItemParamsCopy[element].value = "";
      });
      setLineItemParams(lineItemParamsCopy);
    }
  };

  const [soErrors, setSoErrors] = useState([]);
  const checkErrors = (submitMode) => {

    var errorList = [];
    var paramsCopy = Object.assign(salesOrderParams);

    if (newCustomer) {
      salesOrderParamList[1].forEach((item) => {
        if (paramsCopy[item].mandatory) {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: {
                $set: !validateMandatory(paramsCopy[item].value.toString()),
              },
            },
          });
        }

        if (item === "mobileNo") {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: { $set: !validateMobileNumber(paramsCopy[item].value) },
            },
          });
        }

        if (item === "email") {
          if (paramsCopy.email.value !== "") {
            paramsCopy = update(paramsCopy, {
              [item]: {
                error: { $set: !validateEmail(paramsCopy[item].value) },
              },
            });
          }
        }

        if (paramsCopy[item].error) {
          errorList.push(paramsCopy[item].errorMessage);
        }
      });
    }else if(newAddress){
      salesOrderParamList[4].forEach((item) => {
        if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
        [item]: {
          error: {
            $set: !validateMandatory(paramsCopy[item].value.toString()),
          },
        },
      });
    }
  
    if (paramsCopy[item].error) {
      errorList.push(paramsCopy[item].errorMessage);
    }
  })
    }
     else {
      salesOrderParamList[0].forEach((item) => {
        if (paramsCopy[item].mandatory) {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: {
                $set: !validateMandatory(paramsCopy[item].value.toString()),
              },
            },
          });
        }

        if (paramsCopy[item].error) {
          errorList.push(paramsCopy[item].errorMessage);
        }
      });
    }

    salesOrderParamList[2].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
      errorList.push("Enter a valid number for Transport Charges");
      setTransportChargesError(true);
    } else {
      setTransportChargesError(false);
    }
    if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
      errorList.push("Enter a valid number for Other Charges");
      setOtherChargesError(true);
    } else {
      setOtherChargesError(false);
    }

    if (isNaN(finalDiscount) || parseInt(finalDiscount) < 0) {
      errorList.push("Enter a valid number for Other Charges");
      setFinalDiscountError(true);
    } else {
      setFinalDiscountError(false);
    }

    if (errorList.length === 0) {
      var lineItemAllClear = true;
      lineItemsParamsList.forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
          setLineItemErrors(["Unsaved Item information found"]);
        }
      });

      if (lineItemAllClear) {
        if (lineItemsList.length > 0) {
          setDataSubmitted(false);
          setIsOpen(true);
          prepareDataToSubmit(submitMode);
        } else {
          setLineItemErrors(["Add atleast one item information"]);
        }
      }
    }

    setSalesOrderParams(paramsCopy);
    setSoErrors(errorList);
  };

  var newCustomerObj = {
    companyName: newCustomer?salesOrderParams.customerName.value:"ABC",
    mailingName: "-----",
    address: newAddress?salesOrderParams.address.value:"-----",
    cityId: newAddress?salesOrderParams.cityId.value:1,
    gstNo: "-----",
    priceTypeId: 1,
    creditAmountLimit: "-----",
    creditDays: "-----",
    contactDetail: [
      { contactName: "-----", 
      mobile: newCustomer?salesOrderParams.mobileNo.value:"0000000000",
       email: "-----" },
    ],
    deliveryAddress: [
      {
        consignorCompanyName: "ABC",
        gstNo: "-----",
        address: "-----",
        cityId: 1,
        contactName: "-----",
        mobile: "0000000000",
      },
    ],
  };

  const prepareDataToSubmit = (submitMode) => {
    var serverObj = {};
    if (newCustomer) {
      salesOrderParamList[1].forEach((item) => {
        if (item === "customerName") {
          serverObj.customerId = null;
          serverObj.customerName = salesOrderParams[item].value;

        } else {
          if (salesOrderParams[item].value !== "") {
            serverObj[item] = salesOrderParams[item].value;
          } else {
            serverObj[item] = null;
          }
        }
      });
    } else {
      salesOrderParamList[0].forEach((item) => {
        if (salesOrderParams[item].value !== "") {
          serverObj[item] = salesOrderParams[item].value;
        } else {
          serverObj[item] = null;
        }
      });
    }
     if(newAddress){
      salesOrderParamList[4].forEach((item) => {
         if(item !== "country" || item !== "state")
         serverObj[item] = salesOrderParams[item].value;
      })
      serverObj["gstNo"] = "";
      serverObj["mobile"] = newCustomer ? salesOrderParams.mobileNo.value : "";


    let customerName = salesOrderParams?.customerId?.options.filter((item)=>{
      return item?.optionId === salesOrderParams?.customerId?.value
    } )
         serverObj["customerName"] = newCustomer? salesOrderParams?.customerName?.value :customerName[0].optionName;
         serverObj["deliveryAddressLineId"] = 1
    }
    else{
      salesOrderParamList[3].forEach((item) => {
        if (salesOrderParams[item].value !== "") {
          serverObj[item] = salesOrderParams[item].value;
        } else {
          serverObj[item] = null;
        }
      });
    }
    salesOrderParamList[2].forEach((item) => {
      if (salesOrderParams[item].value !== "") {
        serverObj[item] = salesOrderParams[item].value;
      } else {
        serverObj[item] = null;
      }
    });
    serverObj.lineItems = lineItemsList;
    serverObj.otherCharges = otherCharges || 0;
    serverObj.transportCharges = transportCharges || 0;
    serverObj.netTotal = itemsTotalCost;
    serverObj.salesInstruction =
      salesInstruction !== "" ? salesInstruction : null;
    serverObj.soStatus = submitMode;
    serverObj.totalAmount = totalAmount;
    serverObj.finalDiscount = showDiscount ? parseFloat(finalDiscount) || 0 : 0;
    serverObj.newCustomer = newCustomer;
    

    if (newCustomer) {
      // Create customer

      var name = salesOrderParams.customerId.options.filter(
        (cusName) =>
          cusName.optionName.toLowerCase() ===
          salesOrderParams.customerName.value.toLowerCase()
      );
      if (name.length > 0) {
        setSoErrors(["Customer Name Already Exists"]);
        setModalText("Customer Name Already Exists");
        var a = "notSubmitted";
        async function closeModal() {
          await setDataSubmitted(true);
          a = "submitted";
          return a;
        }

        closeModal();
      } else {
        var data2 = new FormData();
        Object.keys(newCustomerObj).forEach((key) => {
          if (key === "companyName") {
            data2.append(key, salesOrderParams.customerName.value);
          } else if (key === "contactDetail") {
            var obj = Object.assign(newCustomerObj[key][0]);
            obj.mobile = salesOrderParams.mobileNo.value;

            if (salesOrderParams.email.value !== "") {
              obj.email = salesOrderParams.email.value;
            }
            data2.append(key, JSON.stringify([obj]));
          } else if (key === "deliveryAddress") {
            data2.append(key, JSON.stringify(newCustomerObj[key]));
          } else {
            data2.append(key, newCustomerObj[key]);
          }
        });
        createCustomer(data2, serverObj);
      }
    } else {
      submitData(serverObj);
    }
  };

  async function createCustomer(data2, serverObj) {
    // setIsOpen(true);
    var result = await fetchData({
      requestingPage: "createCustomer",
      method: "post",
      url: "create/customer",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: data2,
    });

    if (result.msg === "success") {
      setModalText("Successfully Created Customer. Uploading Sales Order Data");

      serverObj.customerId = result.asset[0].customer_id;
      submitData(serverObj);
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setSoErrors([result.desc]);
      setDataSubmitted(true);
    }
    //
  }

  const submitData = async (serverObj) => {
    var result = await fetchData({
      requestingPage: "createPoItem",
      url: "so-create/so",
      method: "post",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: serverObj,
    });
    if (result.msg === "success") {
      setRedirect(true);
      setDataSubmitted(true);
      setModalText(
        serverObj.soStatus === "Draft"
          ? "Sales Order Saved"
          : "Sales Order Submitted for Approval"
      );
    } else {
      console.log(result);
    }
  };

  const editSubSectionItem = (index, action) => {
    if (action === "edit") {
      var lineItemAllClear = true;

      lineItemsParamsList.forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
        }
      });
      if (!lineItemAllClear) {
        var yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var lineItemCopy = Object.assign(lineItemParams);
        var lineItemsListCopy = lineItemsList.slice();

        var editObj = lineItemsListCopy.splice(index, 1)[0];

        Object.keys(editObj).forEach((itemKey) => {
          if (itemKey === "item" || itemKey === "uom" || itemKey === "cuttingLengthName") {
          } 
          else {
            lineItemCopy[itemKey].value = editObj[itemKey];
          }
        });
        setIsTaxable(editObj.tax?true :false)
        lineItemCopy.itemId.callServer = false;
        lineItemCopy.uomId.options = itemUomList[lineItemCopy.itemId.value];
        setLineItemParams(lineItemCopy);

        var itemsCost = 0;
        lineItemsListCopy.forEach((item) => {
          itemsCost = itemsCost + item.netPrice;
        });
        setItemsTotalCost(itemsCost);

        setLineItemsList(lineItemsListCopy);
      }
    }
    if (action === "delete") {
      var lineItemsListCopy = lineItemsList.slice();
      lineItemsListCopy.splice(index, 1);

      var itemsCost = 0;
      lineItemsListCopy.forEach((item) => {
        itemsCost = itemsCost + item.netPrice;
      });
      setItemsTotalCost(itemsCost);

      setLineItemsList(lineItemsListCopy);
    }
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element,i) => {
        let netPr = lineItemParams.quantity.value *
          (lineItemParams.unitPrice.value 
            // + lineItemParams.otherCharges.value 
            -
            (lineItemParams.discount.value / 100) *
            lineItemParams.unitPrice.value) +
          (isTaxable?(lineItemParams.tax.value / 100):0) *
          (lineItemParams.quantity.value *
            (lineItemParams.unitPrice.value 
              // + lineItemParams.otherCharges.value 
              -
              (lineItemParams.discount.value / 100) *
              lineItemParams.unitPrice.value));
        netPr = parseFloat(netPr.toFixed(2));
        return (
          <FormElement
            key={i}
            inputType={param[element].inputType}
            value={
              element === "netPrice"
                ? netPr >=
                  0
                  ? netPr
                  : "-"
                : param[element].value !== null
                  ? param[element].value
                  : ""
            }
            filterOptions={param[element].filterOptions}
            setInput={(value) => {
              updatePOParameter({
                section,
                paramName: element,
                key: "value",
                value: value,
              });
            }}
            hintText={param[element].hintText}
            mandatory={param[element].mandatory}
            colSpan={param[element].colSpan}
            options={
              param[element].inputType === "options"
                ? param[element].options
                : null
            }
            error={param[element].error}
            rowSpan={
              element === "vendorLogo" || element === "otherBusinessDetails"
                ? param[element].rowSpan
                : null
            }
            placeholder={param[element].placeholder}
            title={param[element].title}
            disabled={param[element].disabled}
            mindate={param[element].mindate}
          />
        );
    });
  };

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Item</td>
            <td>HSN No</td>
            <td>UOM</td>
            <td>Quantity</td>
            <td>Unit Price</td>
            {/* <td>Other Charges</td> */}
            <td>Discount</td>
            <td>Tax</td>
            <td>Tax Amount</td>
            <td>Net Price</td>
            <td>Last sale Price</td>
            <td>Remarks</td>
            <td>Actions</td>
          </tr>
        </thead>
        {lineItemsList.length > 0 ? (
          <tbody>
            <RenderTableRows rows={lineItemsList} tab="contactActions" />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
              {lineItemsParamsList.map((item,index) => {
                return <td key={index}>&nbsp;</td>;
              })}
              <td key={1}>&nbsp;</td>
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const RenderTableRows = ({ rows, tab }) => {
    let RenderTableCols = isLogged.showHideWeight
      ? [
        "item",
        // "cuttingLengthName",
        // "from",
        // "to",
        // "netWeight",
        // "grossWeight",
        "hsnNo",
        "uom",
        "quantity",
        "unitPrice",
        // "otherCharges",
        "discount",
        "tax",
        "taxAmount",
        "netPrice",
        "lastSaleUnitPrice",
        "remarks"
      ]
      : [
        "item",
        // "cuttingLengthName",
        "hsnNo",
        "uom",
        "quantity",
        "unitPrice",
        // "otherCharges",
        "discount",
        "tax",
        "taxAmount",
        "netPrice",
        "lastSaleUnitPrice",
        "remarks"
      ];
    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {RenderTableCols.map((key, i) => {

            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  let filteredArray = lineItemsParamsList.filter(item=>item !== "tax" && item!=="taxAmount")
  const renderSubSection = () => {
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
          {renderFormElements({
            elementList: isTaxable?lineItemsParamsList:filteredArray,
            param: lineItemParams,
            section: "lineItem",
          })}
          <div
            style={{
              gridColumn: `auto / span 6`,
              margin: "auto 0px",
              color: "#666666",
              cursor: "pointer",
            }}

            onClick={() => setIsTaxable(!isTaxable)}>
            <input
              type="checkbox"
              name="tax"
              checked={isTaxable}
              readOnly
              style={{ cursor: "pointer" }}
            />
            <label
              htmlFor="tax"
              style={
                isTaxable
                  ? {
                    fontWeight: "600",
                    color: "#1f43e5",
                    padding: "0 10px",
                    cursor: "pointer",
                    fontSize: "17px",
                  }
                  : {
                    padding: "0 10px",
                    cursor: "pointer",
                    fontSize: "17px",
                  }
              }>
              Taxable
            </label>
          </div>
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={3}
            setInput={() => {
              checkLineItemError();
            }}
          />
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
            <img
              style={{ padding: "10px", width: "1rem", height: "1rem" }}
              src={resetIcon}
              alt="Reset"
              onClick={() => {
                var yes = window.confirm("Clear Input data?");

                if (yes) {
                  var lineItemParamEmpty = Object.assign(lineItemParams);
                  lineItemsParamsList.forEach((item) => {
                    lineItemParamEmpty[item].value = "";
                    lineItemParamEmpty[item].error = false;
                  });
                  setLineItemParams(lineItemParamEmpty);
                  setLineItemErrors([]);
                }
              }}
            />
          </div>

          {salePriceTypes.length > 0 ? <RenderSalesPriceTypes /> : null}
        </div>

        <RenderTable />
      </React.Fragment>
    );
  };

  const RenderSalesPriceTypes = () => {
    return (
      <div
        style={{
          padding: "10px 0 0 0",
          gridColumn: "span 24",
          fontWeight: "bold",
          color: "#ce9547",
        }}>
        Sales Price:&nbsp;&nbsp;&nbsp;&nbsp;
        {salePriceTypes.map((price) => {
          return (
            <span
              style={{
                paddingRight: "1rem",
                color: "#666666",
                fontWeight: "normal",
              }}>
              <b>{price.priceType}</b>: {price.price}
            </span>
          );
        })}
      </div>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (soErrors.length === 0 && redirect) {
      setSection("salesOrderList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Submitting Sales Order. Please wait.."
  );

  const renderErrorMessage = () => {
    if (soErrors.length > 0) return soErrors[0];
    else if (lineItemErrors.length > 0) return lineItemErrors[0];
  };

  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            // width: "1100px",
            width: "fit-content",
            margin: "0 auto 4rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className={
                newCustomer
                  ? "createSalesOrderGridNewCustomer"
                  : "createSalesOrderGrid"
              }>
              <div
                style={{
                  gridColumn: `auto / span 6`,
                  margin: "auto 0px",
                  color: "#666666",
                  cursor: "pointer",
                }}
                onClick={() => setNewCustomer(!newCustomer)}>
                <input
                  type="checkbox"
                  name="Old / New Customer"
                  checked={newCustomer}
                  readOnly
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor="vehicle1"
                  style={
                    newCustomer
                      ? {
                        fontWeight: "600",
                        color: "#1f43e5",
                        padding: "0 10px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                      : {
                        padding: "0 10px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                  }>
                  New Customer
                </label>
              </div>
              {renderFormElements(
                newCustomer
                  ? {
                    elementList: salesOrderParamList[1],
                    param: salesOrderParams,
                    section: "general",
                  }
                  : {
                    elementList: salesOrderParamList[0],
                    param: salesOrderParams,
                    section: "general",
                  }
              )}
              {newCustomer ? (
              <div
                  style={{
                    gridColumn: "auto / span 18",
                    borderBottom: "2px solid #dddddd",
                    height: "2.5rem",
                  }}></div>
              ) : null}
              {renderFormElements({
                elementList: salesOrderParamList[2],
                param: salesOrderParams,
                section: "general",
              })}

              <div
                style={{
                  gridColumn: `auto / span 6`,
                  margin: "auto 0px",
                  color: "#666666",
                  cursor: "pointer",
                }}
                onClick={() => setNewAddress(!newAddress)}>
                <input
                  type="checkbox"
                  name="Old / New Customer"
                  checked={newAddress}
                  readOnly
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor="vehicle1"
                  style={
                    newAddress
                      ? {
                        fontWeight: "600",
                        color: "#1f43e5",
                        padding: "0 10px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                      : {
                        padding: "0 10px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                  }>
                  Add New Address
                </label>
              </div>
              {
                renderFormElements(
                  newAddress ?
                  {elementList: salesOrderParamList[4],
                    param: salesOrderParams,
                    section: "general",}
                    :
                    {elementList: salesOrderParamList[3],
                    param: salesOrderParams,
                    section: "general",})
              }

            </div>
            <div style={{ width: "300px" }}>
              <div className="vendorStoreDetailArea">
                <div className="poVendorAddressDetail">
                  {showCustomerDetail && !newCustomer ? (
                    <RenderCustomerDetail />
                  ) : null}
                </div>
                <div className="poStoreAddressDetail">
                  {showStoreDetail ? <RenderStoreDetail /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="purchaseOrderSubSectionArea">
            {renderSubSection()}
          </div>
          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items:&nbsp;
                <span>{lineItemsList.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>Sales Instructions</div>
              <textarea
                className="multilineInput"
                value={salesInstruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setSalesInstruction(e.target.value);
                }}
              />
            </div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Net Total</b>
                </span>
                <span className="poNetTotalAmount">{itemsTotalCost}</span>
              </div>

              <div className="poFormElement" style={{ gridTemplateRows: "repeat(4, 4rem)" }}>
              
                <div className="formElement">
                  <FormHint hintText="Other Charges" mandatory={false} />
                  <InputField
                    type="float"
                    value={otherCharges}
                    setName={(value) => setOtherCharges(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                    align="end"
                    error={otherChargesError}
                  />
                </div>
                <div className="formElement">
                  <FormHint hintText="Tax" mandatory={false} />
                  <InputField
                    type="float"
                    value={taxPer}
                    setName={(value) => setTaxPer(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                    align="end"
                    error={""}
                    disabled={true}
                  />
                </div>
                <div className="formElement">
                  <FormHint hintText="Transport Charges" mandatory={false} />

                  <InputField
                    type="float"
                    value={transportCharges}
                    setName={(value) => settransportCharges(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                    align="end"
                    error={transportChargesError}
                  />
                </div>
                {<div>
                  <div
                    style={{
                      gridColumn: `auto / span 6`,
                      margin: "auto 0px",
                      color: "#666666",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowDiscount(!showDiscount)}>
                    <input
                      type="checkbox"
                      name="discount"
                      checked={showDiscount}
                      readOnly
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      htmlFor="discount"
                      style={
                        showDiscount
                          ? {
                            fontWeight: "600",
                            color: "#1f43e5",
                            padding: "0 10px",
                            cursor: "pointer",
                            fontSize: "17px",
                          }
                          : {
                            padding: "0 10px",
                            cursor: "pointer",
                            fontSize: "17px",
                          }
                      }>
                      Add Discount
                    </label>
                  </div>
                  {showDiscount ? <div className="formElement">
                    <FormHint hintText="Discount" mandatory={false} />

                    <InputField
                      type="float"
                      value={finalDiscount}
                      setName={(value) => setFinalDiscount(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                      align="end"
                      error={finalDiscountError}
                    />
                  </div> : null}
                </div>}
              </div>
              <div className="poTotalTitle">
                <span>Round off {totalAmount.toString().split('.')[1] ?
                  Math.floor(totalAmount) < Math.round(totalAmount) ? <em>(More)</em> : <em>(Less)</em>
                  : ""}</span>
                <span className="poNetTotalAmount">{'0.' + (totalAmount.toString().split('.')[1] || 0)}</span>
              </div>
              <div className="poTotalTitle" style={{ margin: "1rem 0 0 0", fontSize: "14px" }}>
                <span>
                  <b>Total Amount</b>
                </span>
                <div className="poNetTotalAmount">{Math.round(totalAmount)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="cancelButton"
            onClick={() => {
              var reset = window.confirm("Do you want to reset all fields?");
              if (reset) {
                var salesOrderParamsCopy = Object.assign(salesOrderParams);
                salesOrderParamList[0].forEach((element) => {
                  salesOrderParamsCopy[element].value = "";
                  salesOrderParamsCopy[element].error = false;
                });
                salesOrderParamList[1].forEach((element) => {
                  salesOrderParamsCopy[element].value = "";
                  salesOrderParamsCopy[element].error = false;
                });
                salesOrderParamList[2].forEach((element) => {
                  salesOrderParamsCopy[element].value = "";
                  salesOrderParamsCopy[element].error = false;
                });
                setSalesOrderParams(salesOrderParamsCopy);

                var lineItemParamsCopy = Object.assign(lineItemParams);
                lineItemsParamsList.forEach((element) => {
                  lineItemParamsCopy[element].value = "";
                  lineItemParamsCopy[element].error = false;
                });
                setLineItemParams(lineItemParamsCopy);

                setLineItemsList([]);
                setSoErrors([]);
                setLineItemErrors([]);
                setOtherCharges(0);
                settransportCharges(0);
                setOtherChargesError(false);
                setTransportChargesError(false);
                setSalesInstruction("");
                setItemsTotalCost(0);
                setFinalDiscount(0);
                setFinalDiscountError("");
              }
            }}>
            Reset Fields
          </button>
          <button
            className="saveButton"
            onClick={() => {
              setSoErrors([]);
              setLineItemErrors([]);
              checkErrors("Draft");
            }}>
            Save draft
          </button>
          <button
            className="submitButton"
            onClick={() => {
              setSoErrors([]);
              setLineItemErrors([]);
              checkErrors("Approval Pending");
            }}>
            Submit for Approval
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSalesOrder);
