import React,{useCallback,useEffect,Fragment,useState} from 'react'
import { connect } from 'react-redux';
import { addToken } from '../../redux/UserAccount/IsLoggedActions';
import { useHistory } from 'react-router-dom';
import FetchNewToken from '../../serverCall/FetchNewToken';
import fetchData from '../../serverCall/fetchData';
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";


const RenderSubTable=({data})=>{
  
  const [itemsList,setItemsList]=useState(data || [])
  const editProcessTableCollapse=(itemIndex)=>{
    let listCopy = itemsList.slice();
    listCopy.forEach((item,j)=>{
      if (j === itemIndex) {
        listCopy[j].showProcess = !item.showProcess;
      } else {
        listCopy[j].showProcess = true;
      }      
    })
  
    setItemsList(listCopy);
  }

  return(
    <td colSpan={6} className='vendorListInnerTableWrapper'>
      <table className='vendorListInnerTable'>
          <thead>
            <tr className='vendorListInnerTableHeader'>
            <td className="vendorListInnerTableHeading">Item Name</td>
            <td className="vendorListInnerTableHeading">Status</td>
            <td className="vendorListInnerTableHeading">Picked Quantity</td>
            <td className="vendorListInnerTableHeading">Produced Quantity</td>
            <td className="vendorListInnerTableHeading">Accepted Quantity</td>
            <td className="vendorListInnerTableHeading">Rejected Quantity</td>
            <td className='vendorListInnerTableHeading'>Show Process</td>
            </tr>
          </thead>
          <tbody>
            {
              itemsList?.map((item,itemIndex)=>{
            let statusColor = item.status === "In Progress"  ? '#ff9800' : '#43a047';
                return(
                  <>
                <tr className="vendorListInnerTable">
                  <td className="vendorListInnertableInfo">{item.itemName}</td>
                  <td className="vendorListInnertableInfo" style={{color:statusColor}}>{item.status?.toUpperCase()}</td>
                  <td className="vendorListInnertableInfo">{item.pick}</td>
                  <td className="vendorListInnertableInfo">{item.pass}</td>
                  <td className="vendorListInnertableInfo">{item.produced}</td>
                  <td className="vendorListInnertableInfo">{item.rejected}</td>
                  <td className='vendorListInfoActions'>
                    <img alt="Toggle Expand" className="vendorActionImages"
                    title="Toggle Expand" src={item.showProcess ? upIcon : downIcon }
                    onClick={(e) => {
                      e.stopPropagation();
                      editProcessTableCollapse(itemIndex);
                    }}
                  /></td>
                </tr>
                {item.showProcess ?
                  <tr>
                     <RenderProcessTable data={item.processStatus}/>
                   </tr> : null
                  } 
                  </>
                )
              })
            }
          </tbody>
      </table>
    </td>
  )
}

const RenderProcessTable=({data})=>{
  return(
    <td colSpan={7} className="vendorListInnerTableWrapper">
      <table className='vendorListInnerTable' style={{margin:"auto",width:"50%"}}>
            <thead>
              <tr className='vendorListInnerTableHeader'>
              <td className="vendorListInnerTableHeading" style={{textAlign:"center"}}>Process Name</td>
              <td className="vendorListInnerTableHeading" style={{textAlign:"center"}}>Accepted Quantity</td>
              </tr>
            </thead>
            <tbody>
              {
                data?.map((proc) => (
                  <tr className="vendorListInnerTable">
                    <td className="vendorListInnertableHeading" style={{ textAlign: "center" }}>{proc.process}</td>
                    <td className="vendorListInnertableInfo" style={{ textAlign: "center" }}>{proc.producedQuantity}</td>
                  </tr>
                )
                )
              }
            </tbody>
        </table>
    </td>
  )
}

const ProductionReportList = ({isLogged,addTokenToState,productionReportParams}) => {

  const [productionReportList,setProductionReportList] = useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [noRecords,setNoRecords] = useState(false);

  const history = useHistory();
  const checkToken = useCallback(async() => {
    const {accessToken,employeeId} = isLogged
    
      const token2 = await FetchNewToken(accessToken);
      if (token2 === "expired") {
        history.push("/");
      } else if (token2 !== accessToken) {
       
        addTokenToState(employeeId, token2);
      } else {
        console.log("Token not changed");
      }
    },
    [isLogged,addTokenToState,history])

    const getProdReportList=useCallback(async()=>{
      var response =await fetchData({
        requestingPage: "productionReport",
        method: "post",
        url: "production-fetch/production-report",
        headers: { token: isLogged.accessToken, module: "Production Log Report" },
        data: productionReportParams 
        })
      setIsLoading(false)
        if(response.msg==='success'){
          if(response.asset.length) 
          {
            setProductionReportList(response.asset) 
        }else {
          setNoRecords(true);
          }
        }else{
          setNoRecords(true);
        }
    },[productionReportParams,isLogged.accessToken]);

  useEffect(()=>{
    checkToken();
    getProdReportList();
  },[checkToken,getProdReportList]);



  const editItemCollapse = (index) => {
    var listCopy = [];
        listCopy = productionReportList.slice();
    listCopy.forEach((item, j) => {
      if (j === index) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });
    setProductionReportList(listCopy)
  };

  const renderProductionReport=()=>{

   return( <Fragment>
        {productionReportList.map((prod,index)=>(
          <Fragment key={index}>
        <tr className='vendorList' key={index} onClick={()=>editItemCollapse(index)}>
          <td className="vendorListInfo">{prod.productionNo}</td>
          <td className="vendorListInfo">{prod.soNo || "Make to Stock"}</td>
          <td className='vendorListInfo'>{prod.soDate || prod.productionDate}</td>
          <td className="vendorListInfo">{prod.companyName}</td>
          <td className="vendorListInfo">{prod.expectDate}</td>
          <td className='vendorListInfoActions'>
          <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={prod.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse(index);
                  }}
                />
          </td>
        </tr>
          {
            !prod.collapse ? (
              <tr>
              <RenderSubTable data={prod.lineItems}/>
              </tr>
            ) : null
          }
        </Fragment>
        ))

        }
      </Fragment>
   );
  }

  return (
    <Fragment>
      <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
             {isLoading ?  <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div> : null}
          {noRecords ?  <div className="noRecordsYet">No items available</div> :
          <div className="detailsPageWrapper"  style={{overflowX:"unset",flexDirection:"row",width:"110%"}}>
            <div className="detailsPageContainer" style={{minHeight: "1400px"}}>
            <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader"  style={{zIndex:1}}>
                <td className='vendorListHeading'>Production Order No</td>
                <td className='vendorListHeading'>Sale Order No</td>
                <td className='vendorListHeading'>Sale Order/Production Date</td>
                <td className='vendorListHeading'>Company Name</td>
                <td className='vendorListHeading'>Prod. Expd. Delivery Date</td>
                <td className='vendorListHeading'>Actions</td>
              </tr>
              </thead>
              <tbody>
                {productionReportList.length > 0 ? renderProductionReport() : null}
              </tbody>
            </table>
        
            </div>
          </div>}
          </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {  
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
    productionReportParams: state.poInfo.productionReportParams
  };
};

const mapDispatchToProps = (dispatch) => {

  return {
    addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
  };
};


export default connect(mapStateToProps,mapDispatchToProps)(ProductionReportList);