import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";

import TopBanner from "../SmallComponents/TopBanner";
import OtherChargesList from "./OtherChargesList";
import salesInvoiceIcon from "../../assets/invoice.svg";
import OtherChargesDetail from "./OtherChargesDetail";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";
import CreateOtherCharges from "./createOtherCharges";
import EditOtherCharges from "./editOtherCharges";

const OtherCharges = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("otherChargesList");

    const [approvalPage, setApprovalPage] = useState(false);

    const [editAndApprove, setEditAndApprove] = useState(false);

    const [loadSo, setLoadSo] = useState(false);
    const [downloadReportParams,setDownloadReportParams]=useState({});
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "otherChargesList" };
    var otherChargesButton = { buttonName: "Create Premium Charge", className: "employeeButton", setSection: setCurrentSection, sectionName: "createPremium", callback: () => setLoadSo(false) }
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "otherCharges",downloadReportParams) }, sectionName: "otherChargesList" };

    const renderSection = (section) => {
        switch (section) {
            case "createPremium":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salesInvoiceIcon} pageTitle="Other Charges" buttonList={[goBackButton]} />
                        <CreateOtherCharges setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "otherChargesList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salesInvoiceIcon} pageTitle={"Premium List"}
                            buttonList={isLogged.access["Other Charges"] === "Create" || isLogged.access["Other Charges"] === "Edit" || isLogged.access["Other Charges"] === "App/Dec" ?
                                [otherChargesButton] : null} />
                        <OtherChargesList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={setApprovalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setLoadSo={setLoadSo}
                        />
                    </React.Fragment>
                );

            case "otherChargesDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesInvoiceIcon}
                            pageTitle={approvalPage ? "Approve Invoice" : "Invoice Detail"}
                            buttonList={[otherChargesButton,goBackButton, downloadButton]}
                        />
                        <OtherChargesDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setDownloadReportParams={(params)=>setDownloadReportParams(params)}
                        />
                    </React.Fragment>
                );

            case "editSalesInvoice":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesInvoiceIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Invoice" : "Edit Invoice"}
                            buttonList={[goBackButton]}
                        />
                    </React.Fragment>
                );

             case "editPackingSlip":
                 return(
                    <React.Fragment>
                    <PageTitle
                    imgSrc={salesInvoiceIcon}
                    pageTitle={"Edit Other Charges"}
                    buttonList={[goBackButton]}
                />
                <EditOtherCharges setSection={(value)=>setCurrentSection(value)}/>
                </React.Fragment>
                 )

            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Sales" selectedSubMenu="Other Charges" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(OtherCharges);
