import React from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import "../styles/item.css";
import { useHistory } from "react-router-dom";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import packingSlipIcon from "assets/packing-slip.svg";
import bundleCreationIcon from "assets/bundle-creation-black.svg";
import loadingSlipIcon from "assets/loading-slip.svg"

const Packaging = ({ isLogged }) => {
  const history = useHistory();

  const packagingParams = [
    { menu: "Bundle Creation", route: "bundle-creation", icon: bundleCreationIcon },
    { menu: "Loading Slip", route: "loading-slip", icon: loadingSlipIcon },
    { menu: "Packing Slip", route: "packing-slip", icon: packingSlipIcon },
  ];

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Store" selectedSubMenu="Packaging" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={packingSlipIcon} pageTitle="Packaging" />
          <span className="itemImagesGrid" style={{display:"flex",flexWrap:"wrap",maxWidth:"80%"}}>
            {packagingParams.map((obj,i) =>
              isLogged.access[obj.menu] ? (
                <div key={i}
                  className={
                    isLogged.access[obj.menu] !== null
                      ? "singleItem"
                      : "singleItemDisabled"
                  }
                  onClick={() => {
                    if (isLogged.access[obj.menu] !== null) {
                      history.push(`/${obj.route}`);
                    }
                  }}
                  alt={obj.menu}
                  title={
                    isLogged.access[obj.menu] !== null ? obj.menu : "No Access"
                  }>
                  <span className="itemText"></span>
                  <img
                    className={
                      isLogged.access[obj.menu] !== null
                        ? "itemImage"
                        : "itemImageDisabled"
                    }
                    src={obj.icon}
                    alt="Category"
                  />
                  <span className="itemText">{obj.menu}</span>
                </div>
              ) : null
            )}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Packaging);
