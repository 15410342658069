import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import FormElement from "../../SmallComponents/FormElement";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import { addVendorLedgerParams } from "redux/PurchaseOrder/PoInfoActions";

const VenderLedgerParams = ({ isLogged, setCurrentSection,poInfo,addToVendorLedgerParams,addTokenToState}) => {

  const [vendorLedgerParams, setVendorLedgerParams] = useState({
    vendorId: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Vendor Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Vendor",
      mandatory: true
    },
    plantId: {
      inputType: "options",
      hintText: "Plant Name",
      value: "",
      options: isLogged.plantOptions,
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Plant Name",
      mandatory: true
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "From Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select a From Date Range",
      mandatory: true
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select a To Date Range",
      mandatory: true
    }
  });

  const history = useHistory();

  //getting all vendors
  async function getVendors() {
    let ledgerCopy = Object.assign(vendorLedgerParams);
    let vendors = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: "fetch/vendor-names",
      headers: { token: isLogged.accessToken, module: "DC List" },
    });
    let vendorOptions = [];
    if (vendors.msg === "success") {
      if(vendors.asset.length){
        vendorOptions.splice(0,0,{optionId:-1,optionName:"All"})
      }
      vendors.asset.forEach((vendor) => {
        let a = { optionName: vendor.VendorName, optionId: vendor.vendorId };
        vendorOptions.push(a);
      });
      // if(vendorOptions.length){
      //   vendorOptions.push({optionId:-1,optionName:"All"})
      // }
    }

    async function checkToken() {
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
        history.push("/");
      } else if (token2 !== isLogged.accessToken) {
        addTokenToState(isLogged.employeeId, token2);
      } else {
        console.log("Token not changed");
      }
    }

    //updating vendor options 
    ledgerCopy = update(ledgerCopy, {
      ["vendorId"]: { ["options"]: { $set: vendorOptions } },
    });
    setVendorLedgerParams(ledgerCopy);
    // return vendorOptions;
  };

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  //handling inputs while onchange
  const updateParameter = (element, key, value) => {
    let ledgerCopy = Object.assign(vendorLedgerParams);
    ledgerCopy = update(ledgerCopy, {
      [element]: { [key]: { $set: value } },
    });
    setVendorLedgerParams(ledgerCopy);
  };

  useEffect(() => {
    checkToken()
    getVendors();
  }, [])

  const [vendorLedgerError, setVendorLedgerError] = useState([]);

  const checkVendorLedgerError = () => {
    var errorList = [];
    var paramsCopy = Object.assign(vendorLedgerParams);
    let queryParams = {}
    Object.keys(paramsCopy).forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } })
        if(item==="vendorId"){
          let vendorList = paramsCopy[item].value.map((val)=>val.value)
          if(vendorList.includes(-1)){
            vendorList = paramsCopy[item].options.map((val)=>val.optionId)
          }
          queryParams[item] = vendorList
        }else{
          queryParams[item] = paramsCopy[item].value
        }
      }
      if (paramsCopy[item].error)
        errorList.push(paramsCopy[item].errorMessage);
    })
    if (errorList.length === 0) {
      addToVendorLedgerParams(queryParams)
      setCurrentSection('vendorLedgerList');
    } else {
      setVendorLedgerError(errorList)
    }
  };

  const renderFormElements = ({ elementList, param }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                setInput={(value) => updateParameter(element, "value", value)}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options" || param[element].inputType === "multiSelect"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                key={ind}
              />
            </span>
          );
        })}
        <FormElement
          inputType="addButton"
          value="Submit"
          colSpan={5}
          boxWidth="80px"
          setInput={() => {
            setVendorLedgerError([]);
            checkVendorLedgerError();
          }}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            {renderFormElements({
              elementList: ["vendorId", "plantId", "fromDate", "toDate"],
              param: vendorLedgerParams,
            })}
          </div>
          <p className="formSubmitErrorTextArea" style={{ textAlign: "center", fontSize: "16px" }}>
            {vendorLedgerError[0]}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo :state.poInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    addToVendorLedgerParams: (value) => dispatch(addVendorLedgerParams(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VenderLedgerParams);

