import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import fetchData from "../../serverCall/fetchData";

export const exportToCSV = async(apiData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(apiData);
  let lineItems = [];
  apiData.forEach(lines => {
    if (lines.lineItems !== undefined && lines.lineItems.length > 0) {
      lineItems = lineItems.concat(lines.lineItems);
    }
  })
  let wb;
  if (lineItems.length > 0) {
    wb = { Sheets: { data: ws, lineItems: XLSX.utils.json_to_sheet(lineItems) }, SheetNames: ["data", "lineItems"] };
  } else {
    wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export async function exportData(isLogged, reportName, params = {dealerName:'',orderNo:'', url: "", method: "get" }) {
  //console.log(reportsMap, reportName);
  const { url, filename, module } = reportsMap[reportName];
  let {dealerName,orderNo,method}=params;
  var data = await fetchData({
    requestingPage: "report",
    method: method,
    url: url + params.url,
    headers: { token: isLogged.accessToken, module },
  });
  if (data.msg === "success") {
    //console.log(data.asset);
    if(filename === 'SaleOrderDetail')
    { orderNo = orderNo.split('-').pop();
      orderNo = Array.from({length: 3-orderNo.length},(v,i)=>'0').concat(orderNo).join('');
      let saleOrderFileName = `${dealerName}-SO-${orderNo}`;
     await  exportToCSV(data.asset,saleOrderFileName);
    }
    else{
     await  exportToCSV(data.asset, filename);
    }
  } else {
     console.log(data);
  }
}

export const reportsMap = {

  "employeeDetails": {
    url: "fetch/employees-report",
    module: "Employee",
    filename: "EmployeeDetails"
  },
  "vendorDetails": {
    url: "fetch/vendors-report",
    module: "Vendor",
    filename: "VendorDetails"
  },
  "customerDetails": {
    url: "fetch/customers-report",
    module: "Customer",
    filename: "CustomerDetails"
  },
  "priceDetails": {
    url: "store-fetch/inventory-price-list-report",
    module: "Inventory",
    filename: "Store Price List"
  },
  "soApprovedList": {
    url: "so-fetch/so-approved-list-report",
    module: "Sales Order",
    filename: "SalesOrderAList"
  },
  "inventoryDetails": {
    url: "store-fetch/inventory-list2-report",
    module: "Inventory",
    filename: "Store Inventory Details"
  },
  "soApprovedSingleItem": {
    url: "so-fetch/so-detail/",
    module: "Sales Order",
    filename: "SaleOrderDetail"
  },
  "soUnApprovedSingleItem": {
    url: "so-fetch/so-detail/",
    module: "Sales Order",
    filename: "SaleOrderDetail"
  },
  "poApprovedSingleItem": {
    url: "po-fetch/po-detail/",
    module: "Purchase Order",
    filename: "PurchaseOrderDetail"
  },
  "opdApprovedSingleItem": {
    url: "store-fetch/opd-detail/Sales Order/",
    module: "OPD",
    filename: "OutProductDeliveryDetail"
  },
  "inwardApprovedSingleItem": {
    url: "store-fetch/inward-detail/Purchase Order/",
    module: "Inward",
    filename: "InwardDetail"
  },
  "outwardApprovedSingleItem": {
    url: "store-fetch/outward-detail/Sales Order/",
    module: "Outward",
    filename: "OutwardDetail"
  },
  "qcApprovedSingleItem": {
    url: "store-fetch/qc-detail/Purchase Order/",
    module: "Quality Checking",
    filename: "QCDetail"
  }
}
