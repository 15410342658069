import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import uomIcon from "../../assets/uom2.svg";
import PageTitle from "../SmallComponents/PageTitle";
import UomList from "./UomList";
import "../styles/uom.css";
import CreateUom from "./CreateUom";
import EditUom from "./EditUom";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";

const UOM = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("uomList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "uomList" }];
    var createUomButton = { buttonName: "Create UOM", className: "employeeButton", setSection: setCurrentSection, sectionName: "createUom" };

    const renderSection = (section) => {
        switch (section) {
            case "uomList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={uomIcon}
                            pageTitle="Unit of Measure (UOM)"
                            buttonList={isLogged.access.UOM === "Create" || isLogged.access.UOM === "Edit" ? [createUomButton] : null}
                        />
                        <UomList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createUom":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={uomIcon} pageTitle="Create - Unit of Measure" buttonList={goBackButton} />
                        <CreateUom setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editUom":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={uomIcon} pageTitle="Edit - Unit of Measure" buttonList={goBackButton} />
                        <EditUom setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page yet to be created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="UOM" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(UOM);
