export const editRoleNumber = (roleId) => {
    return { type: "EDIT_ROLE_NUMBER", payload: roleId };
};

export const editRoleControl = (roleControlId) => {
    return { type: "EDIT_ROLE_CONTROL", payload: { roleControlId } };
};

export const editScreenControl = (screenControlId) => {
    return { type: "EDIT_SCREEN_CONTROL", payload: { screenControlId } };
};

export const viewScreenControl = (screenControlId) => {
    return { type: "VIEW_SCREEN_CONTROL", payload: { screenControlId } };
};
