import React, { useEffect, useState } from "react";
import "../styles/Email.css";
import { validateEmail } from "../CommonFunctions/ValidateFields";
import Modal from "react-modal";
import { sendPDFMail } from "../CommonFunctions/sendMailPDF";
import LoadingModal from "../SmallComponents/LoadingModal";

const EmailPopupModal = ({ openModal, isLogged, reportName, cancelClick }) => {

    const [fromMailAddresses, setFromMailAddresses] = useState("admin@enterot.com");
    const [toMailAddresses, setToMailAddresses] = useState("");
    const [ccMailAddresses, setCcMailAddresses] = useState("");
    const [textSubject, setSubjectText] = useState("");
    const [mailContent, setMailContent] = useState("");
    const [errorMsg, setErrorMessage] = useState("");
    const [loading, setLoader] = useState(false);

    useEffect(() => {
        setErrorMessage("");
        setCcMailAddresses("");
        setToMailAddresses("");
        setSubjectText("");
        setMailContent("");
        setLoader(false);
        // eslint-disable-next-line
    }, [openModal]);

    const sendMailOkClick = async () => {

        if (!openModal) {
            return;
        }
        var isValid = true;
        setErrorMessage("");
        setLoader(true);
        // setErrorMessage([]);
        // To Address
        if (toMailAddresses === "") {
            setErrorMessage("Kindly provide valid email Id(s) in \"To\" section");
            setLoader(false);
            return
        }
        toMailAddresses.split(",").map(toAdd => {
            if (isValid && toAdd.trim() !== "")
                isValid = validateEmail(toAdd.trim());
        });
        if (!isValid) {
            setErrorMessage("Provide valid email Id(s) in \"To\" section");
            setLoader(false);
            return;
        }
        // Cc Address
        ccMailAddresses.split(",").map(ccAdd => {
            if (isValid && ccAdd.trim() !== "")
                isValid = validateEmail(ccAdd.trim());
        });
        if (!isValid) {
            setErrorMessage("Provide valid email Id(s) in \"Cc\" section");
            setLoader(false);
            return;
        }

        const returnMsg = await sendPDFMail(isLogged, reportName, { "from": fromMailAddresses, "to": toMailAddresses, "cc": ccMailAddresses, "sub": textSubject, "content": mailContent });

        setLoader(false);
        if (returnMsg.status === "Success") {
            setErrorMessage("Email sent successfully");
        } else {
            setErrorMessage("Error sending mail:" + returnMsg.msg);
        }

    }

    return (
        <Modal
            isOpen={openModal}
            contentLabel="Email Modal"
            style={{
                overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                content: {
                    textAlign: "center",
                    width: "40%",
                    height: "65%",
                    position: "fixed",

                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",

                    // borderRadius: "5px",
                    boxShadow: "0 0 5px gray",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                },
            }}
        >
            <LoadingModal isLoading={loading}>
            </LoadingModal>

            <div>
                <table>
                    <tbody>
                        <tr key="idFromAddress">
                            <td><label>From: <span style={{ color: "red" }}> *</span></label></td>
                            <td><input className="commonEmailInput" type="text" value={fromMailAddresses} disabled></input></td>
                        </tr>
                        <tr key="idToAddress">
                            <td><label>To: <span style={{ color: "red" }}> *</span></label></td>
                            <td><input className="commonEmailInput" type="text"
                                value={toMailAddresses}
                                placeholder="Use comma (,) separator for multiple mail ids"
                                onChange={(e) => { setToMailAddresses(e.target.value) }}
                                autoFocus></input></td>
                        </tr>
                        <tr key="idCcAddress">
                            <td><label>Cc:</label></td>
                            <td><input className="commonEmailInput" type="text"
                                value={ccMailAddresses}
                                placeholder="Use comma (,) separator for multiple mail ids"
                                onChange={(e) => { setCcMailAddresses(e.target.value) }}
                            ></input></td>
                        </tr>
                        <tr key="idSubject">
                            <td><label>Subject:</label></td>
                            <td><input className="commonEmailInput" type="text"
                                value={textSubject}
                                onChange={(e) => { setSubjectText(e.target.value) }}
                            ></input></td>
                        </tr>
                        <tr key="idMailContent">
                            <td colSpan="2">
                                <textarea
                                    value={mailContent}
                                    className="multilineInput"
                                    rows="50"
                                    cols="1000"
                                    name="text"
                                    placeholder="Enter your mail content here"
                                    style={{ boxSizing: "border-box", fontFamily: "sans-serif", padding: "10px", resize: "none" }}
                                    onChange={(e) => {
                                        setMailContent(e.target.value);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {
                (errorMsg !== "") ? <div className="errorTextArea" style={(errorMsg === "Email sent successfully") ? { color: 'green' } : { color: 'red' }}>{errorMsg}</div> : <div></div>
            }

            <div>
                <button className="cancelButton" onClick={cancelClick}>
                    Close
                </button>
                <span style={{ margin: "0 1rem" }}></span>

                <button className="submitButton" onClick={sendMailOkClick}>
                    Send
                </button>
            </div>

            <div className="infoTextArea">&lt;&lt;- By default, attachment is attached here -&gt;&gt;</div>
        </Modal >
    );
};

export default EmailPopupModal;

