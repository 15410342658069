import { Fragment,useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import StatementIcon from "../../../assets/sales_report.svg"
import { exportPDF } from "../../CommonFunctions/ExportPDF";
import { useHistory } from "react-router-dom";
import MovementAnalysisParams from "./movementAnalysisParams";
import MovementAnalysisList from "./movementAnalysisList";

const MovementAnalysis = ({ isLogged }) => {
    const [currentSection,setCurrentSection] = useState("movementAnalysisParams");

    const history = useHistory();

    var goBackButton = (props) =>
  ({
    buttonName: "Back",
    className: "goBackButton",
    ...props
  });
    var downloadButton={buttonName:"Download",className:"exportButton",setSection: async () => { await exportPDF(isLogged, "stockReport") },sectionName:"movementAnalysisParams"}

    const renderSection=(currentSection)=>{
        switch(currentSection){
            case "movementAnalysisParams":
                return(
                    <Fragment>
                    <PageTitle imgSrc={StatementIcon} pageTitle={"Movement Analysis"}
                        buttonList={[goBackButton( {setSection: (route)=>history.push(route),sectionName: "/stockreport"})]}/>
                   <MovementAnalysisParams setSection={setCurrentSection}/>
              </Fragment>
                );

            case "movementAnalysisList":
                return(
                    <Fragment>
                        <PageTitle imgSrc={StatementIcon} pageTitle={"Movement Analysis List"}
                            buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"movementAnalysisParams"}), downloadButton]}
                            />
                        <MovementAnalysisList setSection={setCurrentSection}/>
                    </Fragment>
                )

            default:
                <>Page Not Found</>
        }
    }

  return (
    <Fragment>
        <div className="completePage">
            <MenuList selectedMenu={"Reports"} selectedSubMenu={"Stock Report"}/>
            <div className="detailsContainer">
                <TopBanner />
                {renderSection(currentSection)}
            </div>
        </div>
    </Fragment>
  )
}

export default MovementAnalysis