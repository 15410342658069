import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import "../styles/Dashboard.css";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import {exportToCSV} from "../CommonFunctions/ExportExcel";
import {
  dateFormatting,
  addDaysToDate,
  getIndianCurrency,
} from "../CommonFunctions/utils";

const SalesDashboard = ({ isLogged, addTokenToState }) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("Sales Invoice");
  const [barData, setBarData] = useState([]);
  const [pieChartData1, setPieChartData1] = useState([]);
  const [pieChartData2, setPieChartData2] = useState([]);
  const [cardData1, setCardData1] = useState([]);
  const [itemTrendList,setItemTrendList]=useState([]);
  const [trendList,setTrendList]= useState({ivTrendList:[],returnTrendList:[]})
  const [queryParams, setQueryParams] = useState({
    showChartBy: {
      inputType: "options",
      value: "MM-YY",
      mandatory: true,
      options: [
        { optionId: "DD-MM-YY", optionName: "Show By Days" },
        { optionId: "MM-YY", optionName: "Show By Months" },
        { optionId: "YYYY", optionName: "Show By Years" },
      ],
      error: false,
      hintText: "Show Chart By",
      isClearable: false,
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: "04-01-" + new Date().getFullYear(),
      hintText: "From Date",
      mandatory: true,
      error: false,
      mindate: true,
      maxdate: true,
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      mandatory: true,
      error: false,
      maxdate: true,
    },
    plantId: {
      inputType: "options",
      value: "",
      options: isLogged.plantOptions,
      hintText: "Plant Name",
      mandatory: false,
      error: false,
    },
    storageId: {
      inputType: "multiSelect",
      value: [],
      hintText: "Store",
      mandatory: false,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "",
    },
    customerId: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Customer Name",
      mandatory: false,
      error: false,
    },
    items:{
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Items",
      mandatory: false,
      error: false,
    },
    countryId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Country",
      mandatory: false,
      error: false
    },
    cityId: {
      inputType: "multiSelect",
      value: [],
      options: [],
      mandatory: false,
      error: false,
      hintText: "City Name"
    },
    stateId:{
      inputType: "options",
      value: "",
      options: [],
      hintText: "State Name",
      mandatory: false,
      error: false
    }
  });

  const tablist = [
    { id: "Sales Invoice", name: "Sales Invoices" },
    { id: "Sales Order", name: "Sales Orders" },
    { id: "Sales Return", name: "Sales Returns" },
  ];

  var IReportButton = {buttonName: "Export" ,className: "exportButton", 
  setSection: async()=>{await exportToCSV(trendList.ivTrendList,`Sales Invoice Items Report-  ${new Date().toDateString()}`)}    
  , sectionName: "SalesInvoiceDashboard"}

  useEffect(() => {
    getInformation();
  }, []);

  const [storageLocList, setStorageLocList] = useState({});

  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

    }
    return [plantList, storageLocList];
  }

  async function getInformation() {
    await checkToken();
    let [itemList] = await getItems();
    var [plantList,storageLocListFormatted] = await getPlants();
    
    let countryOptions = await getDim({ field: "country" }, "country");
    let queryParamsCopy = Object.assign(queryParams);
    queryParamsCopy = update(queryParamsCopy, {
      items: { options: { $set: itemList } },
      countryId: {options: {$set: countryOptions} }
    });
    let [options] = await getCustomers();
    queryParamsCopy = update(queryParamsCopy, {
      customerId: { options: { $set: options } },
    });
    setQueryParams(queryParamsCopy);
    setStorageLocList(storageLocListFormatted);
  }

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{itemsTab:"Active"},
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemList.push(itemObj);
      });

    }
    return [itemList];
  }

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getCustomers() {
    let data = await fetchData({
      requestingPage: "getParents",
      method: "get",
      url: "fetch/customer-list",
      headers: { token: isLogged.accessToken, module: "Customer" },
    });
    if (data.msg === "success") {
      let options = [];
      data.asset.forEach((parent) => {
        let newParent = {
          optionId: parent.customerId,
          optionName: parent.customer,
        };
        options.push(newParent);
      });
      return [options]
    }
  }

  async function getSoData(qryParams) {
    let data1 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-order-dashboard",
      headers: { token: isLogged.accessToken, module: "Sales Dashboard" },
      data: qryParams,
    });
    if (data1.msg === "success") {
      setBarData(data1.asset.barData);
      setCardData1([
        {
          type: "numbers",
          title: "Sale Order Count",
          value: getIndianCurrency(data1.asset.totalApprovedSoCount, false),
        },
        {
          type: "numbers",
          title: "Basic Amount",
          value: getIndianCurrency(Math.round(data1.asset.totalUnitAmount), false),
        },
        {
          type: "numbers",
          title: "Tax Amount",
          value: getIndianCurrency(Math.round(data1.asset.totalTaxAmount), false),
        },
        {
          type: "numbers",
          title: "Total Sale Order Amount",
          value: getIndianCurrency(Math.round(data1.asset.totalSalesAmount)).split('.')[0],
        },
      ]);
      setItemTrendList(data1.asset.itemTrendList);
      data1.asset.pieChartData1.map((obj) => {
        switch (obj["id"]) {
          case "Not Sent":
            obj["color"] = "rgb(178,38,58,0.75)";
            break;
          case "Sent":
            obj["color"] = "rgb(127,201,127)";
            break;
          case "Partially Sent":
            obj["color"] = "rgb(255,255,51)";
            break;
          default:
            obj["color"] = "yellow";
        }
        return obj;
      });
      setPieChartData1(data1.asset.pieChartData1);
      data1.asset.pieChartData2.map((obj) => {
        switch (obj["id"]) {
          case "Invoiced":
            obj["color"] = "rgb(127,201,127)";
            break;
          case "Not Invoiced":
            obj["color"] = "rgb(178,38,58,0.75)";
            break;
          default:
            obj["color"] = "yellow";
        }
        return obj;
      });
      setPieChartData2(data1.asset.pieChartData2);
    } else {
      console.log(data1);
    }
  }

  async function getSoReturnData(qryParams) {
    let data2 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-return-dashboard",
      headers: { token: isLogged.accessToken, module: "Sales Dashboard" },
      data: qryParams,
    });
    if (data2.msg === "success") {
      setBarData(data2.asset.barData);
      setTrendList(prev=>({...prev,returnTrendList:data2.asset.returnTrendList}))
      setCardData1([
        {
          type: "numbers",
          title: "Sales Return Count",
          value: getIndianCurrency(
            data2.asset.totalApprovedSalesReturnCount,
            false
          ),
        },
        {
          type: "numbers",
          title: "Sales Return Total Amount",
          value: getIndianCurrency(Math.round(data2.asset.totalSalesReturnAmount)).split('.')[0],
        },
      ]);
    } else {
      console.log(data2);
    }
  }
  async function getSoInvoiceData(qryParams) {
    let data3 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-invoice-dashboard",
      headers: { token: isLogged.accessToken, module: "Sales Dashboard" },
      data: qryParams,
    });
    if (data3.msg === "success") {
      setBarData(data3.asset.barData);
      setTrendList(prev=>({...prev,ivTrendList:data3.asset.invoiceTrendList}))
      setCardData1([
        {
          type: "numbers",
          title: "Sales Invoice Count",
          value: getIndianCurrency(
            data3.asset.totalApprovedSalesInvoiceCount,
            false
          ),
        },
        {
          type: "numbers",
          title: "Basic Amount",
          value: getIndianCurrency(Math.round(data3.asset.totalUnitAmount), false),
        },
        {
          type: "numbers",
          title: "Tax Amount",
          value: getIndianCurrency(Math.round(data3.asset.totalTaxAmount), false),
        },
        {
          type: "numbers",
          title: "Sales Invoice Total Amount",
          value: getIndianCurrency(Math.round(data3.asset.totalSalesInvoiceAmount)).split('.')[0],
        },
      ]);
    } else {
      console.log(data3);
    }
  }
  
  async function getDim(dataSent, dimension) {
    var data = await fetchData({
        requestingPage: "getDim",
        method: "post",
        url: "dim",
        headers: { token: isLogged.accessToken, module: "Employee" },
        data: dataSent,
    });
    if (data.msg !== "failure") {
        var optionArray = [];

        switch (dimension) {
            case "country":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                    optionArray.push(a);
                });
                break;

            case "state":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                    optionArray.push(a);
                });
                break;

            case "cityId":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                    optionArray.push(a);
                });
                break;

            default:
                break;
        }

        return optionArray;
    } else {
        return [];
    }
}

  async function getState() {
    if (queryParams.countryId.value !== "") {
        let stateparam = await getDim({ field: "state", filterBy: "country_id", filterValue: queryParams.countryId.value }, "state");

        let queryParamsCopy = Object.assign(queryParams);
        queryParamsCopy = update(queryParamsCopy, { stateId: { options: { $set: stateparam },value:{$set: ""} },
                                                                cityId: {options: {$set: []},value: {$set: [] } }  });

        setQueryParams(queryParamsCopy);
    }
}

  async function getCity() {
    if (queryParams.stateId.value !== "") {
        let cityparam = await getDim({ field: "city", filterBy: "state_id", filterValue: queryParams.stateId.value }, "cityId");

        let queryParamsCopy = Object.assign(queryParams);
        queryParamsCopy = update(queryParamsCopy, { cityId: { options: { $set: cityparam } } });

        setQueryParams(queryParamsCopy);
    }
  }

    useEffect(() => {
      let queryParamsCopy=Object.assign(queryParams)
      if (queryParamsCopy.countryId.value != "") 
      getState();
      else{
          queryParamsCopy=update(queryParamsCopy,{
                                                  stateId: {options: {$set: []},
                                                          value: {$set: ""} 
                                                          },
                                                  cityId: {options: {$set: []},
                                                          value: {$set: []} 
                                                          }   
                                              });
      setQueryParams(queryParamsCopy);
      }
  }, [queryParams.countryId]);

  useEffect(() => {

    var queryParamsCopy = Object.assign(queryParams);

    if (queryParams.plantId.value !== "") {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: {
          options: { $set: storageLocList[queryParams.plantId.value]||[] },
        },
      });
      setQueryParams(queryParamsCopy);
    } else {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: { options: { $set: [] }, value: { $set: [] } },
      });
      setQueryParams(queryParamsCopy);
    }
  }, [queryParams.plantId.value]);

  useEffect(() => {
    let plantGenInfoCopy=Object.assign(queryParams)
    if (queryParams.stateId.value !== "") 
    getCity();
    else{
        plantGenInfoCopy=update(plantGenInfoCopy,{
            cityId: {options: {$set: []},
                    value: {$set: []} 
                    }   
        });
    setQueryParams(plantGenInfoCopy);
    }
}, [queryParams.stateId]);

  useEffect(() => {
    let saleCopy = Object.assign(queryParams);
    let fromDate = dateFormatting(saleCopy.fromDate.value),
      // converting date and adding 1 date for between operator in backend
      toDate = dateFormatting(addDaysToDate(saleCopy.toDate.value, 1));

    let qryParams = {
      dates: [fromDate, toDate],
      plantId: saleCopy.plantId.value,
      customerId: saleCopy.customerId.value.map(item=>item.value),
      showChartBy: saleCopy.showChartBy.value,
      itemIds: saleCopy.items.value.map((it)=>it?.value), // since it is multi select extract only values,
      stateId: saleCopy.stateId.value,
      cityId: saleCopy.cityId.value.map(item=>item.value),
      storageId:saleCopy.storageId.value.map(item=>item.value)
    };
    if (fromDate.length === 10 && toDate.length === 10) {
      if (selectedTab === "Sales Order") {
        getSoData(qryParams);
      } else if (selectedTab === "Sales Return") {
        getSoReturnData(qryParams);
      } else if (selectedTab === "Sales Invoice") {
        getSoInvoiceData(qryParams);
      }
    }
  }, [queryParams, selectedTab]);
  const renderItemTrendList = (itemTrendList) => {
    return (
      <div
        className="dashboardCard itemTrendList">
        <div className="dashboardCardHeader">
          <span className="dashboardCardTitle">Items Sale Count</span>
        </div>
        <div className="dashboardAlertBody">
          {itemTrendList.length > 0 ? (
            itemTrendList.map((stock, ind) => {
                return (
                  <div className="alertStockLine" key={ind}>
                    <span style={{ flex: "1 0",paddingRight: "1em" }}>{stock.itemName}</span>
                    <span style={{paddingRight: "1em"}}>{Number(stock.rate).toLocaleString('en-IN',{maximumFractionDigits:2,currency:"INR",style:"currency"})}</span>
                    <span style={{paddingRight: "1em"}}>{stock.saleCount}</span>
                    <span>{Number(stock.value).toLocaleString('en-IN',{maximumFractionDigits:2,currency:"INR",style:"currency"})}</span>
                  </div>
                );
            })
          ) : (
            <div className="noRecordsYet">No Item Has been Sold</div>
          )}
        </div>
      </div>
    );
};

  const renderCards = (data) => {
    return data?.map((item,ind) => {
      return (
        <div
          className="dashboardCard"
          key={item.title}
          style={{ height: "120px", width: "250px" }}>
          <div className="dashboardCardHeader">
            <span className="dashboardCardTitle">{item.title}</span>
          </div>
          <div className="dashboardCardBody">
            <div className="dashSingle">
              <div>{item.value}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  const updateSaleOrderParameters = ({ param, paramName, key, value }) => {
    let paramsCopy = Object.assign(param);
    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });
    setQueryParams(paramsCopy);
  };

  const renderFormElements = ({ elementList, params,styles={} }) => {
    return (
      <div className="dashboardCard dashboardParams" style={styles}>
        {elementList.map((item,ind) => {
          return (
            <div key={ind}>
              <FormElement
                key={item}
                inputType={params[item].inputType}
                value={params[item].value}
                setInput={(value) => {
                  updateSaleOrderParameters({
                    param: params,
                    paramName: item,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={params[item].hintText}
                mandatory={params[item].mandatory}
                colSpan={params[item].colSpan}
                options={
                  params[item].inputType === "options" ||
                  params[item].inputType === "multiSelect"
                    ? params[item].options
                    : null
                }
                error={params[item].error}
                isClearable={params[item].isClearable}
                maxdate={params[item].maxdate}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const RenderTabList = () => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            selectedTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={(e) => setSelectedTab(tab.id)}>
          {tab.name}
        </div>
      );
    });
  };

  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "rgb(11,87,180)", //rgb(87 118 232) //    color:rgb(88, 80, 245)
          strokeWidth: 2,
        },
      },
    },
  };
  const renderBarGraph = (data, indexBy, key, title) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          width: "32.8rem",
          gridColumn: `1/span 1`,
          alignSelf: 'start',
          gridRow: `auto / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
          <ResponsiveBar
            data={data}
            keys={key}
            indexBy={indexBy}
            margin={{ top: 10, right: 0, bottom: 50, left: 65 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            tooltip={({ value, indexValue }) => {
              return (
                <div className="dashBoardToolTip">
                  {indexValue}
                  <br />
                  {getIndianCurrency(value)}
                  <br />
                </div>
              );
            }}
            theme={theme}
            colors={["rgb(11,87,180)"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 1,
              tickPadding: 5,
              tickRotation: -90,
              legend: "",
              legendPosition: "middle",
              legendOffset: 52,
            }}
            axisLeft={{
              tickSize: 1,
              tickPadding: 4,
              tickRotation: -1,
              legend: "Sales Amount",
              legendPosition: "middle",
              legendOffset: -60,
              format: (value) => `   ₹${Number(value) / 100000} L`,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            label={(d) => null}
            layers={["axes", "bars", "markers", "legends"]}
          />
        </div>
      </div>
    );
  };

  const renderPieChart = (pieData, arcColors, title, columnIndex) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          alignSelf: 'self-start',
          gridColumn: ` ${columnIndex} / span 1`,
          gridRow: `auto / span 1`,
        }}>
        <div className="dashboardCardHeader">
          <span className="dashboardCardTitle">{title}</span>
        </div>
        <div className="dashboardGraphBody">
          <ResponsivePie
            data={pieData}
            margin={{ top: 10, right: 80, bottom: 100, left: 80 }}
            innerRadius={0.5}
            padAngle={1}
            cornerRadius={6}
            activeOuterRadiusOffset={15}
            arcLinkLabelsSkipAngle={5}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={5}
            colors={arcColors}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 18,
                itemWidth: 80,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const renderSubSection = () => {
    switch (selectedTab) {
      case "Sales Order":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: ['showChartBy','fromDate','toDate','plantId','storageId'], 
              params: queryParams,
            })}
            {renderFormElements({
              elementList: ['customerId','items','countryId','stateId','cityId'], 
              params: queryParams,
              styles: {gridRow: '2/2'}
            })}
              <div className="cardsContainer"
              style={{gridTemplateRows: "auto auto",
                gridGap: "11px"
            }}
              >{renderCards(cardData1)}</div>
              {renderItemTrendList(itemTrendList)}
              {renderBarGraph(
                barData,
                "soCreateDate",
                ["totalAmount"],
                "Sales Order"
              )}
              {renderPieChart(
                pieChartData1,
                { datum: "data.color" },
                "Outward Status",
                "1"
              )}
              {renderPieChart(
                pieChartData2,
                { datum: "data.color" },
                "Invoice Status",
                "2"
              )}
            </div>
          </>
        );
      case "Sales Return":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: ['showChartBy','fromDate','toDate','plantId','storageId'], 
              params: queryParams,
            })}
            {renderFormElements({
              elementList: ['customerId','items','countryId','stateId','cityId'], 
              params: queryParams,
              styles: {gridRow: '2/2'}
            })}
              <div className="cardsContainer">
                {renderCards(cardData1)}
                
                </div>
                {renderItemTrendList(trendList.returnTrendList)}
              {renderBarGraph(
                barData,
                "soReturnCreateDate",
                ["totalAmount"],
                "Sales Order Return"
              )}
            </div>
          </>
        );
      case "Sales Invoice":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: ['showChartBy','fromDate','toDate','plantId','storageId'], 
              params: queryParams,
            })}
            {renderFormElements({
              elementList: ['customerId','items','countryId','stateId','cityId'], 
              params: queryParams,
              styles: {gridRow: '2/2'}
            })}
              <div className="cardsContainer"
              style={{gridTemplateRows: "auto auto",
              gridGap: "11px"
          }}>
                {renderCards(cardData1)}
                
                </div>
                {renderItemTrendList(trendList.ivTrendList.slice(0,10))}
              {renderBarGraph(
                barData,
                "soInvoiceCreateDate",
                ["totalAmount"],
                "Sales Invoice"
              )}
            </div>
          </>
        );
      default:
        return <span>Page Yet To Be Built</span>;
    }
  };
  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Dashboard" selectedSubMenu="Sales Dashboard" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Sales Dashboard" buttonList={selectedTab==="Sales Invoice"?[IReportButton]:null}/>
          <span
            className="hintText"
            style={{ color: "GrayText", fontSize: "13px",marginLeft:"40px",marginBottom:"0px" }}>
            *Dashboard data is mainly based on Approved Orders
          </span>
          <div className="poListTabs">
            <RenderTabList />
          </div>
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesDashboard);
