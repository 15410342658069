import React, { useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import cusLedgerIcon from "../../../assets/customer_ledger_mono.svg";
import CustomerLedgerParams from "./CustomerLedgerParams";
import { useHistory } from "react-router-dom";
import CustomerLedgerList from "./CustomerLedgerList";
import { exportPDF } from "../../CommonFunctions/ExportPDF";


const CustomerLedger = () => {
  const [currentSection, setCurrentSection] = useState("customerLedger");

  const history = useHistory();

  var goBackButton = (props) =>
  ({
    buttonName: "Back",
    className: "goBackButton",
    ...props
  });

  var downloadButton = (props) =>
  ({
    buttonName: "Download",
    className: "exportButton",
    ...props
  });

  const renderSection = (section) => {
    switch (section) {
      case "customerLedger":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={cusLedgerIcon}
              pageTitle={"Customer Ledger"}
              buttonList={[goBackButton( {setSection: (route)=>history.push(route),sectionName: "/salesreport"})]}
            />
            <CustomerLedgerParams setCurrentSection={setCurrentSection} />
          </React.Fragment>
        );

      case "customerLedgerList":
        return(<React.Fragment>
          <PageTitle 
          imgSrc={cusLedgerIcon}
          pageTitle={"Customer Ledger"}
          buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"customerLedger"}),
            downloadButton( {setSection: async () => { await exportPDF({}, "customerLedger") },sectionName: "customerLedger"})]}
          />
          <CustomerLedgerList setCurrentSection={setCurrentSection}/>
        </React.Fragment>)
    }
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Reports" selectedSubMenu="Sales Report" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerLedger;
