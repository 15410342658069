export const editUomAction = (uomId) => {
    return { type: "EDIT_UOM_ID", payload: { uomId } };
};

export const editPlantAction = (plantId) => {
    return { type: "EDIT_PLANT_ID", payload: { plantId } };
};

export const editItemCategoryId = (itemCategoryId) => {
    return { type: "EDIT_ITEM_CATEGORY_ID", payload: { itemCategoryId } };
};

export const editItemBrandId = (itemBrandId) => {
    return { type: "EDIT_ITEM_BRAND_ID", payload: { itemBrandId } };
};

export const editItemTypeId = (itemTypeId) => {
    return { type: "EDIT_ITEM_TYPE_ID", payload: { itemTypeId } };
};

export const editItemProcessId = (itemProcessId) => {
    return { type: "EDIT_ITEM_PROCESS_ID", payload: { itemProcessId } };
};

export const editItemId = (itemId) => {
    return { type: "EDIT_ITEM_ID", payload: { itemId } };
};

export const viewItemId = (itemId) => {
    return { type: "VIEW_ITEM_ID", payload: { itemId } };
};

export const editItemPurchaseCostObj = (purchaseObj) => {
    return { type: "EDIT_PURCHASE_COST_OBJ", payload: { purchaseObj } };
};

export const editItemTaxObj = (taxObj) => {
    return { type: "EDIT_ITEM_TAX_OBJ", payload: { taxObj } };
};

export const editItemPriceTypeId = (itemPriceTypeId) => {
    return { type: "EDIT_ITEM_PRICE_TYPE_ID", payload: { itemPriceTypeId } };
};

export const editItemSalesPriceObj = (salePriceObj) => {
    return { type: "EDIT_ITEM_SALES_PRICE_OBJ", payload: { salePriceObj } };
};

export const editTransporterId = (transporterId) => {
    return { type: "EDIT_TRANSPORTER_ID", payload: { transporterId } };
};

export const editClearingAgentId = (clearingAgentId) => {
    return { type: "EDIT_CLEARING_AGENT_ID", payload: { clearingAgentId } };
};

export const editItemCurrencyId = (itemCurrencyId)=>{
    return {type: "EDIT_ITEM_CURRENCY_ID", payload: {itemCurrencyId}};
}

export const editPremiumCharges = (premiumChargesId)=>{
    return {type: "EDIT_PREMIUM_CHARGES",payload:{premiumChargesId}};
}

export const editSectionMaster = (sectionMasterId)=>{
    return {type: "EDIT_SECTION_MASTER",payload:{sectionMasterId}};
}

export const editBundleCreation = (bundleCreationId)=>{
    return {type: "EDIT_BUNDLE_CREATION",payload:{bundleCreationId}};
}
export const editCuttingLengthId = (cuttingLengthId)=>{
    return {type: "EDIT_CUTTING_LENGTH",payload:{cuttingLengthId}};
}