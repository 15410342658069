import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import  receiptRupee  from  "../../assets/receipt-rupee.svg";
import {connect} from "react-redux";
import CreateCustomerReceipt from "./createCustomerReceipt";
import CustomerReceiptList from "./customerReceiptList";
import EditCustomerReceipt from "./editCustomerReceipt";
import CustomerReceiptDetail from "./customerReceiptDetail";

const CustomerReceipts =({isLogged})=>{
    const [currentSection,setCurrentSection] = useState("receiptList");
    const [approvalPage,setApprovalPage]=useState(false);
    const [editAndApprove,setEditAndApprove]=useState(false);
    const access = isLogged.access["Customer Receipt"];
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "receiptList" };
    var createReceipt = {buttonName:"Create Receipt", className:"employeeButton",setSection: setCurrentSection,sectionName: "createReceipt"}

    const renderSection=(section)=>{
        switch(section){
            case "receiptList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={receiptRupee} pageTitle="Customer Receipts" 
                        buttonList={access==="Create" || access === "Edit" || access === "App/Dec" ? [createReceipt] : null} />
                        <CustomerReceiptList setSection={(value)=>{setCurrentSection(value)}} setApproval={setApprovalPage}  
                                            setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                );
            
            case "createReceipt":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={receiptRupee} pageTitle="Create Customer Receipt" buttonList={[goBackButton]}/>
                        <CreateCustomerReceipt setSection={(value)=>setCurrentSection(value)}/>
                    </React.Fragment>
                );

            case "editReceipt":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={receiptRupee} pageTitle="Edit Customer Receipt" buttonList={[goBackButton]}/>
                        <EditCustomerReceipt setSection={(value)=>setCurrentSection(value)} editApprove={editAndApprove}/>
                    </React.Fragment>
                );
                
            case "receiptDetail":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={receiptRupee} pageTitle="Customer Receipt Detail" buttonList={[createReceipt,goBackButton]}/>
                        <CustomerReceiptDetail setSection={(value)=>setCurrentSection(value)} approval={approvalPage} 
                                    setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                )
            default: 
                    return(<div>Page Yet To be Built</div>);
        }
    }

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu= "Sales" selectedSubMenu= "Customer Receipt"/>
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};


export default connect(mapStateToProps, null)(CustomerReceipts);