import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import editIcon from "../../assets/edit.svg";
import { editRoleNumber } from "../../redux/Role/RoleInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const EditRole = ({
    setSection,

    // state
    roleInfo,
    isLogged,

    // dispatch
    addTokenToState,
    editRoleId,
}) => {
    const [roleList, setRoleList] = useState([]);
    const [newRole, setNewRole] = useState("");
    const history = useHistory();

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        var oldRoleName = roleList.filter((role) => role.role_id === roleInfo.editRoleNumber)[0];
        if (oldRoleName) {setNewRole(oldRoleName.role);}
        // eslint-disable-next-line
    }, [roleList]);

    async function getInformation() {
        await checkToken();
        await getRoles();
    }

    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getRoles() {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/role", headers: { token: isLogged.accessToken,module: "Role" } });
        if (data.msg === "success") {
            setRoleList(data.asset);
        } else {
            // console.log(data);
        }
    }

    async function editRole(roleId) {
        var dataSent = {
            requestingPage: "editRole",
            method: "put",
            url: "edit/role",
            headers: { token: isLogged.accessToken,module: "Role" },
            data: {
                role: newRole,
                roleId: roleId,
            },
        };

        var data = await fetchData(dataSent);
        if (data.msg === "success") {
            editRoleId(null);
            setDataSubmitted(true);
            setModalText("Updated Role Successfully");
            setNewRole("");
            getInformation();
        } else {
            setDataSubmitted(true);
            setModalText(data.desc);
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Updating Role. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Updating Role. Please wait...");
        setDataSubmitted(false);
    };

    const renderRoleList = () => {
        return roleList.map((role) => {
            return roleInfo.editRoleNumber !== role.role_id ? (
                <tr className="roleList" key={role.role_id}>
                    <td className="roleName">
                        <span>{role.role}</span>
                    </td>
                    <td className="roleAction">
                        <img
                            src={editIcon}
                            className="roleEditImage"
                            alt="Edit Role"
                            onClick={() => {
                                setNewRole(role.role);
                                editRoleId(role.role_id);
                            }}
                        />
                    </td>
                </tr>
            ) : (
                <tr className="roleList" key={role.role_id}>
                    <td className="createRole">
                        <input
                            className="createRoleInput"
                            value={newRole}
                            onChange={(e) => setNewRole(e.target.value)}
                            autoFocus
                            onKeyUp={(e) => {
                                e.preventDefault();
                                if (e.key === "Enter" || e.keyCode === 13) {
                                    var submit = document.querySelector("#SubmitEdit");
                                    submit.click();
                                } else {
                                    // console.log(e.key);
                                }
                            }}/>
                            </td>
                        <td className="createRole">
                        <button
                            className="cancelRoleButton"
                            onClick={() => {
                                editRoleId(null);
                                getInformation();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            id="SubmitEdit"
                            className="createRoleButton"
                            value={newRole}
                            onClick={() => {
                                if (newRole.trim() !== "") {
                                    setIsOpen(true);
                                    editRole(role.role_id);
                                }
                            }}
                        >
                            Save
                        </button>
                        </td>
                </tr>
            );
        });
    };

    return (
        <div className="rolePagesContainer">
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="roleListArea">
                <table className="roleListTable">
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Role Name</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>
                    {renderRoleList()}
                </table>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editRoleId: (roleId) => dispatch(editRoleNumber(roleId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRole);
