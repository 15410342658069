import { combineReducers } from "redux";
import EmployeeInfo from "./Employee/EmployeeInfo";
import RoleInfo from "./Role/RoleInfo";
import VendorInfo from "./Vendor/VendorInfo";
import loggedReducer from "./UserAccount/IsLogged";
import CustomerInfo from "./Customer/CustomerInfo";
import ItemInfo from "./Item/ItemInfo";
import PoInfo from "./PurchaseOrder/PoInfo";
import StoreInfo from "./Store/StoreInfo";

const allReducers = combineReducers({
    isLogged: loggedReducer,
    roleInfo: RoleInfo,
    employeeInfo: EmployeeInfo,
    vendorInfo: VendorInfo,
    customerInfo: CustomerInfo,
    itemInfo: ItemInfo,
    poInfo: PoInfo,
    storeInfo: StoreInfo,
});

export default allReducers;
