import React, { Fragment, useState,useCallback } from 'react'
import FormElement from '../SmallComponents/FormElement';
import update from "immutability-helper";
import { validateMandatory } from '../CommonFunctions/ValidateFields';
import { addToProductionReportParams } from '../../redux/PurchaseOrder/PoInfoActions';
import { connect } from 'react-redux';
import { addToken } from '../../redux/UserAccount/IsLoggedActions';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FetchNewToken from '../../serverCall/FetchNewToken';
import fetchData from '../../serverCall/fetchData';

const ProductionLogReport = ({isLogged,setSection,addTokenToState,addToProdReportParams}) => {
  const [customerOptions,setCustomerOptions] = useState([]);
  const [prodReportParams, setProdReportParams] = useState({
    fromDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "From Date",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "To Date",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
    },
    orderStatus: {
      inputType: "options",
      value: "",
      hintText: "Order Status",
      mandatory: false,
      options: [{ label: "Completed", value: "Completed" },
      { label: "In Progress", value: "In Progress" },
      { label: "Items Rejected", value: "Rejected" },],
      error: false,
      errorMessage: "Order Status is Mandatory",
      isClearable: false
    },
    customerId:{
      inputType:"multiSelect",
      value: [],
      hintText: "Customer Name",
      mandatory: false,
      options: [],
      error: false,
      isClearable: true,
      colSpan: 4
    }
  })
  const [prodReportError,setProdReportError]=  useState([]);
  const history = useHistory();
  const checkToken = useCallback(async() => {
    const {accessToken,employeeId} = isLogged
      const token2 = await FetchNewToken(accessToken);
      if (token2 === "expired") {
        history.push("/");
      } else if (token2 !== accessToken) {
        addTokenToState(employeeId, token2);
      } else {
        console.log("Token not changed");
      }
    },
    [isLogged,addTokenToState,history])

  useEffect(()=>{
    checkToken();
    getCustomers();
  },[]);
  useEffect(()=>{
    updateParameter("customerId","options",customerOptions);
  },[customerOptions.length])

  async function getCustomers() {
    var data = await fetchData({
        requestingPage: "getParents",
        method: "get",
        url: "fetch/customer-list",
        headers: { token: isLogged.accessToken ,module:"Customer"},
    });
    if (data.msg === "success") {
        var options = [];
        if(data.asset.length){
          options.splice(0,0,{optionId:-1,optionName:"All"});
        }
        data.asset.forEach((parent) => {
            var newParent = { optionId: parent.customerId, optionName: parent.customer };
            options.push(newParent);
        });
        // if(options.length){

        //   options.push({optionId:-1,optionName:"All"});
        // }
        setCustomerOptions(options);
    }
}
  const updateParameter = (element, key, value) => {
    let paramsCopy = structuredClone(prodReportParams);
    paramsCopy = update(paramsCopy, { [element]: { [key]: { $set: value } } });
    setProdReportParams(paramsCopy);
  }

  const renderFormElements = ({ elementList, param }) => {
    return (
      <Fragment>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                setInput={(value) => updateParameter(element, "value", value)}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options" || param[element].inputType === "multiSelect"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                key={ind}
                isClearable={param[element].isClearable}
              />
            </span>
          );
        })}
      </Fragment>
    )
  }

  const checkProdReportError=()=>{
    let paramsCopy= structuredClone(prodReportParams);
    let errorList = [];
    Object.entries(prodReportParams).forEach(([key,value])=>{
      if(key.mandatory){
        paramsCopy = update(paramsCopy,{[key]: {error: {$set: !validateMandatory(paramsCopy[key].value.toString()) } } });
      }
      if(paramsCopy["error"]){
        errorList.push(paramsCopy["errorMessage"])
      }
    });

    if(errorList.length === 0){
      let queryParams = {};
      Object.keys(paramsCopy).forEach((key)=>{
        if(key==="customerId")
          {
            let customerList = paramsCopy[key].value.map(item=>item.value)
            if(customerList.includes(-1)){
              queryParams[key]=[]
            }else{
              queryParams[key] = customerList
            }
          } else{

            queryParams[key] = paramsCopy[key].value
          }      
      });
      addToProdReportParams(queryParams);
      setSection("productionReportList");
    }
  }

  return (
    <Fragment>
          <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
            <div
              style={{
                width: "1000px",
                height: "500px",
                margin: "0 2rem 4rem",
                padding: "1rem",
                border: "1px solid inherit",
                borderRadius: "5px",
                backgroundColor: "inherit",
              }}>
              <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>

                {renderFormElements({ elementList: ["fromDate", "toDate", "orderStatus","customerId"], param: prodReportParams })}
                <FormElement
                  inputType="addButton"
                  value="Submit"
                  colSpan={5}
                  boxWidth="80px"
                  setInput={() => {
                      setProdReportError([]);
                      checkProdReportError();
                  }}
              />
              </div>
              <p className="formSubmitErrorTextArea" style={{textAlign:"center",fontSize:"16px"}}>
                {prodReportError[0]}
              </p>
            </div>
          </div> 
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps=(dispatch)=>{

  return{
    addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
    addToProdReportParams: (value)=>dispatch(addToProductionReportParams(value))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductionLogReport);