import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import viewIcon from "../../assets/view.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import { editVendor, viewVendor } from "../../redux/Vendor/VendorInfoActions";
import Pagination from "../CommonFunctions/pagination";
import FormElement from "../SmallComponents/FormElement";
import SortUpGreen from '../../assets/SortUpGreen.svg';
import SortDownGreen from '../../assets/SortDownGreen.svg';

const VendorList = ({
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
    viewVendorId,
    editVendorId,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getVendors();
    }

    const history = useHistory();
    const [vendorList, setVendorList] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [PageSize,setRowsPerPage]=useState(20);
    const [currentPage,setCurrentPage]=useState(1);
    const [vendorFilter,setVendorFilter]=useState("");
    const vendorListCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return vendorList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,vendorList,PageSize]);
      const [order, setOrder] = useState({ col: "vendorId", state: true, data: "" });
      const sortBy = (data, col, order) => {
        if (order === false) {
          const sortAlphaNum = (a, b) =>
            a["vendor"][col]?.toString().localeCompare(b["vendor"][col]||"", "en", { numeric: true });
        return  data.sort(sortAlphaNum);
        } else {
          const sortAlphaNum = (a, b) =>
            b["vendor"][col]?.toString().localeCompare(a["vendor"][col] || "", "en", { numeric: true });
        return  data.sort(sortAlphaNum);
        }
      };
      const sortByDate = (data, col, order) => {
        if (order === true) {
          const sortAlphaNum = (a, b) => new Date(a["vendor"][col]) - new Date(b["vendor"][col]);
        return  data.sort(sortAlphaNum);
        } else {
          const sortAlphaNum = (a, b) => new Date(b["vendor"][col]) - new Date(a["vendor"][col]);
        return  data.sort(sortAlphaNum);
        }
      };
  const handleSort = ({ col, state, data }) => {
        if (col.includes("Date")) {
          return sortByDate(data, col, state);
        } else {
          return sortBy(data, col, state);
        }
      };

    async function checkToken() {
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getVendors() {
        var data = await fetchData({
            requestingPage: "vendorList",
            method: "get",
            url: "fetch/vendors",
            headers: { token: isLogged.accessToken,module: "Vendor" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setVendorList(data2);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editVendorCollapse = (index) => {

        var vendorListCopy = vendorList.slice();
        vendorListCopy.forEach((vendor, j) => {
            if (j === index) {
                vendorListCopy[j].collapse = !vendor.collapse;
            } else {
                vendorListCopy[j].collapse = true;
            }
        });
        setVendorList(vendorListCopy);
    };

        useEffect(()=>{
        if(vendorFilter!=="")
        setVendorList(vendorList.filter((obj)=>obj.vendor.companyName.toLowerCase().includes(vendorFilter.toLowerCase())))
        else
        getVendors();
        },[vendorFilter])
    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="7" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Contact Name</td>
                            <td className="vendorListInnerTableHeading">Mobile No</td>
                            <td className="vendorListInnerTableHeading">Email Id</td>
                            <td className="vendorListInnerTableHeading">Designation</td>
                            <td className="vendorListInnerTableHeading">Department</td>
                            <td className="vendorListInnerTableHeading">Experience</td>
                            <td className="vendorListInnerTableHeading">Education</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.contactDetailId}>
                                    <td className="vendorListInnertableInfo">{row.contactName}</td>
                                    <td className="vendorListInnertableInfo">{row.mobile}</td>
                                    <td className="vendorListInnertableInfo">{row.email}</td>
                                    <td className="vendorListInnertableInfo">{row.designation}</td>
                                    <td className="vendorListInnertableInfo">{row.department}</td>
                                    <td className="vendorListInnertableInfo">{row.experience}</td>
                                    <td className="vendorListInnertableInfo">{row.education}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const renderVendorList = () => {
        return vendorListCurrentPage.map((vendor, i) => {
  //          if(((vendorFilter!=="") && vendor.vendor.companyName.toLowerCase().includes(vendorFilter.toLowerCase())  ) || vendorFilter==="")    
            return (
                <React.Fragment>
                    <tr
                        className={vendor.collapse ? "vendorList" : "vendorListExpanded"}
                        key={vendor.vendor.vendorId}
                        onClick={() => editVendorCollapse(i)}
                    >
                        <td className="vendorListInfo">{vendor.vendor.vendorId}</td>
                        <td className="vendorListInfo">{vendor.vendor.companyName}</td>
                        <td className="vendorListAddress">
                            {vendor.vendor.address}, <br /> {vendor.vendor.city}
                            {vendor.vendor.zipCode !== null && vendor.vendor.zipCode.length > 0 ? ` - ${vendor.vendor.zipCode},` : ","}
                            <br /> {vendor.vendor.state}, {vendor.vendor.country}.
                        </td>
                        <td className="vendorListInfo">{vendor.vendor.panCardNo}</td>
                        <td className="vendorListInfo">{vendor.vendor.vendorJobType}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Vendor Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewVendorId(vendor.vendor.vendorId);
                                    setSection("vendorDetail");
                                }}
                            />

                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={isLogged.access.Vendor === "Edit" ? editIcon : editIconDisabled}
                                title={isLogged.access.Vendor === "Edit" ? "Edit Vendor" : "No Edit Access"}
                                onClick={(e) => {
                                    if (isLogged.access.Vendor === "Edit") {
                                        e.stopPropagation();
                                        editVendorId(vendor.vendor.vendorId);
                                        setSection("editVendor");
                                    }
                                }}
                                style={isLogged.access.Vendor === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={vendor.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editVendorCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!vendor.collapse ? <RenderSubTable data={vendor.contact} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
             <div style={{ display:"inline-flex",width: "500px",  }} className="purchaseOrderGrid">
             <span style={{flexGrow:0, width:"80px",marginLeft:"25px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span>
            <FormElement 
            inputType={"text"}
            setInput={(value)=>setVendorFilter(value)}
            value={vendorFilter}
            hintText={"Vendor Name"}
            colSpan={0.5}
            rowSpan={1}
            mandatory={false}
            error={false}
            errorMessage=""
            disabled={false}
            />
        </span>
        </div>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "vendorId",
                        state: !order.state,
                      });
                      handleSort({
                        data: vendorListCurrentPage,
                        col: "vendorId",
                        state: order.state,
                      });
                    }}>Vendor Id
                    <img
                          className="sortArrow"
                          src={
                               order.col === "vendorId"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: vendorListCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>Company Name
                    <img
                          className="sortArrow"
                          src={
                               order.col === "companyName"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading">Address</td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "panCardNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: vendorListCurrentPage,
                        col: "panCardNo",
                        state: order.state,
                      });
                    }}>PanCard No
                    <img
                          className="sortArrow"
                          src={
                               order.col === "panCardNo"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "vendorJobType",
                        state: !order.state,
                      });
                      handleSort({
                        data: vendorListCurrentPage,
                        col: "vendorJobType",
                        state: order.state,
                      });
                    }}>Job Type
                    <img
                          className="sortArrow"
                          src={
                               order.col === "vendorJobType"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{vendorList.length > 0 ? renderVendorList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
            {vendorList.length > 0 ?<Pagination 
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={vendorList.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewVendorId: (vendorId) => dispatch(viewVendor(vendorId)),
        editVendorId: (vendorId) => dispatch(editVendor(vendorId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
