import React,{useState} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import PageTitle from 'components/SmallComponents/PageTitle';
import MenuList from 'components/Menu/MenuList';
import TopBanner from 'components/SmallComponents/TopBanner';
import cuttingLengthIcon from "assets/laser-cutting-black.svg";
import CreateCuttingLength from "./createCuttingLength";
import EditCuttingLength from "./editCuttingLength";
import CuttingLengthList from "./cuttingLengthList";


 const CuttingLength = ({isLogged}) => {
  const [currentSection, setCurrentSection] = useState("cuttingLengthList");

  var history = useHistory();

  const pushBack = (section) => {
      history.push(section);
  };

  var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "cuttingLengthList" }];

  var goBackToItemButton = {
      buttonName: "Back",
      className: "goBackButton",
      setSection: pushBack,
      sectionName: "/itemMenu",
  };

  var createCuttingLengthButton = {
      buttonName: "Create Cutting Length",
      className: "employeeButton",
      style: {width: "12rem"},
      setSection: setCurrentSection,
      sectionName: "createCuttingLength",
  };

  const renderSection = (section) => {
      switch (section) {
          case "cuttingLengthList":
              return (
                  <React.Fragment>
                      <PageTitle
                          imgSrc={cuttingLengthIcon}
                          pageTitle="Cutting Length"
                          buttonList={
                              isLogged.access["Cutting Length"] === "Create" || isLogged.access["Cutting Length"] === "Edit"
                                  ? [createCuttingLengthButton, goBackToItemButton]
                                  : [goBackToItemButton]
                          }
                      />
                      <CuttingLengthList setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          case "createCuttingLength":
              return (
                  <React.Fragment>
                      <PageTitle imgSrc={cuttingLengthIcon} pageTitle="Create Cutting Length" buttonList={goBackButton} />
                      <CreateCuttingLength setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          case "editCuttingLength":
              return (
                  <React.Fragment>
                      <PageTitle imgSrc={cuttingLengthIcon} pageTitle="Edit Cutting Length" buttonList={goBackButton} />
                      <EditCuttingLength setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          default:
              return <h1>Page not yet created</h1>;
      }
  };

  return (
      <React.Fragment>
          <div className="completePage">
              <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
              <div className="detailsContainer">
                  <TopBanner />
                  {renderSection(currentSection)}
              </div>
          </div>
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLogged : state.isLogged
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CuttingLength)