import React, { Fragment, useState, useEffect } from "react";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import FormHint from "../SmallComponents/FormHint";
import { validateEmail, validateEqualValues, validateMandatory, validateMobileNumber } from "../CommonFunctions/ValidateFields";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const CreateCompany = ({ setSection, addTokenToState, isLogged }) => {
    const [companyGeneralInfo, setCompanyGeneralInfo] = useState({
        companyName: {
            inputType: "text",
            value: "",
            hintText: "Company Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please enter Company Name",
        },
        address: {
            inputType: "text",
            value: "",
            hintText: "Address",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please provide company address",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Email",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Please provide Valid email address",
        },
        country: {
            inputType: "options",
            value: "",
            hintText: "Country",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select country",
        },
        state: {
            inputType: "options",
            value: "",
            hintText: "State",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select state",
        },
        cityId: {
            inputType: "options",
            value: "",
            hintText: "City",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select city",
        },
        website: {
            inputType: "text",
            value: "",
            hintText: "Website",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Provide company website",
        },
        mobile: {
            inputType: "text",
            value: "",
            hintText: "Mobile Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        phone: {
            inputType: "text",
            value: "",
            hintText: "Phone Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
    });

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");
    const [companyServerError, setCompanyServerError] = useState([]);
    const [companyErrors, setCompanyErrors] = useState([]);
    const history = useHistory();
    const submitOkClick = () => {
        setIsOpen(false);
        if (companyServerError.length === 0) {
            setSection("createSalesLead");
        }
        setModalText("Uploading form. Please wait...");
    };

    async function getDim(dataSent) {
        var data = await fetchData({
            requestingPage: "getDim",
            method: "post",
            url: "dim",
            headers: { token: isLogged.accessToken ,module:"Customers"},
            data: dataSent,
        });
        if (data.msg !== "failure") {
            return data;
        } else {
            return [];
        }
    }

    async function parseDim(data, dimension) {
        var optionArray = [];

        switch (dimension) {
            case "country":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                    optionArray.push(a);
                });
                break;

            case "state":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                    optionArray.push(a);
                });
                break;

            case "cityId":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                    optionArray.push(a);
                });
                break;

            default:
                break;
        }

        return optionArray;
    }


    async function getState() {
        if (companyGeneralInfo.country.value !== "") {
            var dataDim = await getDim({ field: "state", filterBy: "country_id", filterValue: companyGeneralInfo.country.value });
            var parsedDataDim = await parseDim(dataDim, "state");
            var companyGenInfoCopy = Object.assign(companyGeneralInfo);
            companyGenInfoCopy = update(companyGenInfoCopy, {
                state: {
                    options: { $set: parsedDataDim },
                    value: { $set: "" },
                },
            });

            setCompanyGeneralInfo(companyGenInfoCopy);
        }
    }

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }


    async function getCity() {
        if (companyGeneralInfo.state.value !== "") {
            var dataDim = await getDim({ field: "city", filterBy: "state_id", filterValue: companyGeneralInfo.state.value });
            var parsedDataDim = await parseDim(dataDim, "cityId");
            var companyGenInfoCopy = Object.assign(companyGeneralInfo);
            companyGenInfoCopy = update(companyGenInfoCopy, {
                cityId: {
                    options: { $set: parsedDataDim },
                    value: { $set: "" },
                },
            });

            setCompanyGeneralInfo(companyGenInfoCopy);
        }
    }

    async function getInformation() {
        await checkToken();

        var dataDim = await getDim({ field: "country" });
        var parsedDataDim = await parseDim(dataDim, "country");
        // var parentsList = await getParents();
        // var priceTypeOptionsList = await getPriceTypeList();
        // let employeeList = await getEmployees();

        var companyGenInfoCopy = Object.assign(companyGeneralInfo);
        companyGenInfoCopy = update(companyGenInfoCopy, {
            country: { options: { $set: parsedDataDim }, value: { $set: "" } },
        });

        // var customerDeliverAddressCopy = Object.assign(customerDeliverAddress);
        // customerDeliverAddressCopy = update(customerDeliverAddressCopy, { country: { options: { $set: parsedDataDim }, value: { $set: "" } } });

        setCompanyGeneralInfo(companyGenInfoCopy);
        // setCustomerDeliverAddress(customerDeliverAddressCopy);
    }

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getState();
        // eslint-disable-next-line
    }, [companyGeneralInfo.country]);

    useEffect(() => {
        getCity();
        // eslint-disable-next-line
    }, [companyGeneralInfo.state]);

    const renderFormElements = ({ elementList }) => {
        return elementList.map((item) => {
            return (
                <FormElement
                    key={item}
                    inputType={companyGeneralInfo[item].inputType}
                    value={companyGeneralInfo[item].value}
                    setInput={(value) => {
                        updateCustomerParameter({ paramName: item, key: "value", value: value });
                    }}
                    hintText={companyGeneralInfo[item].hintText}
                    mandatory={companyGeneralInfo[item].mandatory}
                    colSpan={companyGeneralInfo[item].colSpan}
                    options={companyGeneralInfo[item].options || null}
                    error={companyGeneralInfo[item].error}
                // rowSpan={item === "customerLogo" || item === "otherBusinessDetails" ? companyGeneralInfo[item].rowSpan : null}
                />
            );
        })
    }

    const updateCustomerParameter = ({ paramName, key, value }) => {

        var companyParams = Object.assign(companyGeneralInfo);
        companyParams = update(companyParams, { [paramName]: { [key]: { $set: value } } });
        setCompanyGeneralInfo(companyParams);
    };

    async function submitData(dataToSent) {
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "post",
            url: "so-create/create-company",
            headers: { token: isLogged.accessToken ,module: "Sales Lead" },
            data: dataToSent,
        });
        
        if (result.msg === "success") {
            setIsOpen(true);
            setModalText("Successfully Created Customer!");
        } else {
            setIsOpen(true);
            setModalText(`Upload failed: ${result.desc}`);
            setCompanyServerError([result.desc]);
        }
        setDataSubmitted(true);
    }

    const checkErrors = () => {
        // check error

        var errorList = [];
        var paramsCopy = Object.assign(companyGeneralInfo);

        // check errors in general info area
        Object.keys(companyGeneralInfo).forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } },
                });
            }

            if (["mobile", "phone"].includes(element)) {
                if (paramsCopy[element].value.toString().length > 0) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMobileNumber(paramsCopy[element].value) } } });
                }
            }

            if (element === "email") {
                if (paramsCopy[element].value.length > 0) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateEmail(paramsCopy[element].value) } } });
                } else {
                    paramsCopy[element].error = false;
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });
        setCompanyErrors(errorList);
        setCompanyGeneralInfo(paramsCopy);

        if(errorList.length===0){
            let {companyName,email,website,address,country,state,cityId,mobile,phone}= companyGeneralInfo
            let data = {
                companyName:companyName.value,
                email:email.value,
                website:website.value,
                address:address.value,
                cityId:cityId.value,
                mobile:mobile.value,
                phone:phone.value
            }
            submitData(data)
        }
    };

    return (
        <Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "900px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        overflow: "hidden",
                    }}
                >
                    <div className="createCustomerGeneralGrid">
                        {renderFormElements({ elementList: Object.keys(companyGeneralInfo), section: "generalInfo" })}
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{companyErrors[0]}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setCompanyServerError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
