import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { editPoId } from "../../redux/PurchaseOrder/PoInfoActions";
import DeclineModal from "../SmallComponents/DeclineModal";

const ImportPoDetail = ({ approval, poInfo, isLogged, addTokenToState, setSection, setEditAndApprove, editPoId }) => {
    const [poDetail, setPoDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    const [vendorLogo, setVendorLogo] = useState([]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getPoDetail();
    }

    const history = useHistory();

    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPoDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "po-fetch/import-po-detail/" + poInfo.importPurchaseOrderViewId,
            headers: { token: isLogged.accessToken, module: "Import PO" },
        });
        if (data.msg === "success") {
            setPoDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        // if (poDetail.vendorLogoAvailable) {
        getVendorLogo();
        // }
        // eslint-disable-next-line
    }, [poDetail]);

    async function getVendorLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/vendor-logo/" + poDetail.vendorId,
            headers: { token: isLogged.accessToken, module: "Import PO" },
        });
        setVendorLogo(data);
    }

    const renderSummaryInfo = () => {
        if (poDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {poDetail.poNo}{" "}
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {poDetail.poDate}</span>

                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {poDetail.expectedDeliveryDate}</span>

                        <span className="vendorSummaryCategory">Transporter Name</span>
                        <span className="vendorSummaryValue">
                            : {poDetail.transporterName === null || poDetail.transporterName === "" ? "NA" : poDetail.transporterName}
                        </span>

                        <span className="vendorSummaryCategory">Store Details</span>
                        <span className="vendorSummaryValue">: {poDetail.storageAddress || "NA"}</span>
                        <span className="vendorSummaryCategory">Port Of Origin</span>
                        <span className="vendorSummaryValue">: {poDetail.portOfOrigin || "NA"}</span>
                        <span className="vendorSummaryCategory">Kind Of Packing</span>
                        <span className="vendorSummaryValue">: {poDetail.kindOfPacking || "NA"}</span>
                        <span className="vendorSummaryCategory">Port Of Destination</span>
                        <span className="vendorSummaryCategory">: {poDetail.destinationPort || "NA"}</span>
                        <span></span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
                            ? `data:image/png;base64,${vendorLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>UOM</td>
                        <td>HSN No</td>
                        <td>Quantity</td>
                        <td>Unit Price</td>
                        <td>Currency Name</td>
                        <td>Tax (%)</td>
                        <td>Net Price</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "from", "to", "netWeight", "grossWeight", "uom", "hsnCode", "quantity", "unitPrice", "currencyName","tax", "netPrice"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    return (
        <React.Fragment>
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {poDetail.vendorName}
                            </span>

                            <span className="poSummaryValue">
                                {`Vendor ID: ${poDetail.vendorId}`}
                            </span>
                            <span className="poSummaryValue">
                                {poDetail.vendorPinCode !== null ? ` ${poDetail.vendorAddress} - ${poDetail.vendorPinCode}` : poDetail.vendorAddress}
                            </span>
                            <span className="poSummaryValue">
                                {poDetail.vendorMobile !== null || poDetail.vendorPhone !== null ? `\nPhone: ` : null}
                                {poDetail.vendorMobile !== null ? poDetail.vendorMobile : null}
                                {poDetail.vendorPhone !== null ? `, ${poDetail.vendorPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">{poDetail.vendorEmail !== null ? `Email Id: ${poDetail.vendorEmail}` : null}</span>

                            <span className="poSummaryValue">{poDetail.vendorGstNo !== null ? `GST No: ${poDetail.vendorGstNo}` : null}</span>

                            <span className="poSummaryValue">
                                Quotation No: {poDetail.quotationNo === null || poDetail.quotationNo === "" ? "NA" : poDetail.quotationNo}
                            </span>

                            <span className="poSummaryValue">
                                Quotation Date: {poDetail.quotationDate === null || poDetail.quotationDate === "" ? "NA" : poDetail.quotationDate}
                            </span>
                            <span className="poSummaryValue">
                                Contract No: {poDetail.contractNo || "NA"}
                            </span>
                            <span className="poSummaryValue">
                                Contract Date: {poDetail.contractDate || "NA"}
                            </span>
                            <span className="poSummaryValue">
                                Advance Given: {poDetail.advanceGiven || "NA"}
                            </span>
                        </div>
                        {renderImageStatus()}
                    </div>

                    {poDetail.poStatus === "Declined" ? (
                        <div style={{ marginTop: "2rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {poDetail.declineReason}
                        </div>
                    ) : null}

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{lineItemsList.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Purchase Instruction: {poDetail.purchaseInstruction !== null ? poDetail.purchaseInstruction : "-"}
                            </div>
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{poDetail.netTotal}</span>
                            </div>
                            <div className="poDetailChargesDiv">
                                <div className="poText">
                                    <span>Other Charges</span>
                                    <span>{poDetail.otherCharges}</span>
                                </div>

                                <div className="poText">
                                    <span>Transport Charges </span>
                                    <span>{poDetail.transportCharges}</span>
                                </div>
                            </div>

                            <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <div className="poNetTotalAmount">{poDetail.totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editPoId: (poId) => dispatch(editPoId(poId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportPoDetail);
