import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import update from "immutability-helper";
import FormHint from "../SmallComponents/FormHint";
import FormElement from "../SmallComponents/FormElement";
import {
  validateEmail,
  validateEqualValues,
  validateMandatory,
  validateMobileNumber,
} from "../CommonFunctions/ValidateFields";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const vendorParameterList = [
  // general info
  [
    "companyName",
    "mailingName",
    "address",
    "country",
    "state",
    "cityId",
    "zipCode",
    "website",
    "panCardNo",
    "gstRegisterType",
    "gstNo",
    "email",
    "mobile",
    "phone",
    "creditDays",
    "vendorJobType",
  ],

  // contact details
  [
    "contactName",
    "designation",
    "department",
    "mobile",
    "email",
    "experience",
    "education",
  ],

  // bank details
  [
    "accountHolderName",
    "accountNumber",
    "confirmAccountNo",
    "ifscCode",
    "bankName",
    "branch",
  ],

  // additional information
  ["industryType", "foundedDate", "vendorLogo", "otherBusinessDetails"],
];

const CreateVendor = ({
  // props
  setSection,

  // state
  isLogged,

  // dispatch
  addTokenToState,
}) => {
  const [vendorGeneralInfo, setVendorGeneralInfo] = useState({
    companyName: {
      inputType: "text",
      value: "",
      hintText: "Company Name",
      mandatory: true,
      colSpan: 8,
      error: false,
      errorMessage: "Please enter Company Name",
    },
    mailingName: {
      inputType: "text",
      value: "",
      hintText: "Mailing Name",
      mandatory: true,
      colSpan: 8,
      error: false,
      errorMessage: "Please enter Mailing Name",
    },
    address: {
      inputType: "text",
      value: "",
      hintText: "Address",
      mandatory: true,
      colSpan: 8,
      error: false,
      errorMessage: "Please provide company address",
    },
    country: {
      inputType: "options",
      value: "",
      hintText: "Country",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select country",
    },
    state: {
      inputType: "options",
      value: "",
      hintText: "State",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select state",
    },
    cityId: {
      inputType: "options",
      value: "",
      hintText: "City",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select city",
    },
    zipCode: {
      inputType: "text",
      value: "",
      hintText: "Zip Code",
      mandatory: false,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Enter Zip code",
    },
    website: {
      inputType: "text",
      value: "",
      hintText: "Website",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Provide company website",
    },
    panCardNo: {
      inputType: "text",
      value: "",
      hintText: "PanCard Number",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
    },
    gstRegisterType: {
      inputType: "options",
      value: "",
      hintText: "GST Register Type",
      mandatory: false,
      colSpan: 4,
      options: [
        { optionId: "Regular", optionName: "Regular" },
        { optionId: "Composition", optionName: "Composition" },
        { optionId: "Unregistered", optionName: "Unregistered" },
        { optionId: "Consumer", optionName: "Consumer" },
      ],
      error: false,
      errorMessage: "Select GST register type",
    },
    gstNo: {
      inputType: "text",
      value: "",
      hintText: "GST No",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Provide GST Number",
    },
    email: {
      inputType: "text",
      value: "",
      hintText: "Email",
      mandatory: false,
      colSpan: 8,
      error: false,
      errorMessage: "Provide a valid Email address",
    },
    mobile: {
      inputType: "text",
      value: "",
      hintText: "Mobile Number",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Mobile number must be atleast 10 digits",
    },
    phone: {
      inputType: "text",
      value: "",
      hintText: "Phone Number",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Mobile number must be atleast 10 digits",
    },
    creditDays: {
      inputType: "text",
      value: "",
      hintText: "Credit Days",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
    },
    vendorJobType: {
      inputType: "options",
      value: "",
      hintText: "Vendor Job Type",
      mandatory: false,
      colSpan: 4,
      options: [
        { optionId: "Partial Job", optionName: "Partial Job" },
        { optionId: "Full Job", optionName: "Full Job" },
        { optionId: "Domestic", optionName: "Domestic" },
        { optionId: "Import", optionName: "Import" },
      ],
      error: false,
      errorMessage: "",
    },
  });

  const [vendorContactDetail, setvendorContactDetail] = useState({
    contactName: {
      inputType: "text",
      value: "",
      hintText: "Contact Name",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Provide Contact Name",
    },
    designation: {
      inputType: "text",
      value: "",
      hintText: "Designation",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
    },
    department: {
      inputType: "text",
      value: "",
      hintText: "Department",
      mandatory: false,
      colSpan: 3,
      error: false,
      errorMessage: "testing spaces",
    },
    mobile: {
      inputType: "text",
      value: "",
      hintText: "Mobile No",
      mandatory: true,
      colSpan: 5,
      error: false,
      errorMessage: "Mobile number must be atleast 10 digits",
    },
    email: {
      inputType: "text",
      value: "",
      hintText: "Email",
      mandatory: false,
      colSpan: 8,
      error: false,
      errorMessage: "Please enter a valid email address",
    },
    experience: {
      inputType: "text",
      value: "",
      hintText: "Experience",
      mandatory: false,
      colSpan: 3,
      error: false,
      errorMessage: "",
    },
    education: {
      inputType: "text",
      value: "",
      hintText: "Education Details",
      mandatory: false,
      colSpan: 3,
      error: false,
      errorMessage: "",
    },
  });

  const [vendorBankDetail, setvendorBankDetail] = useState({
    accountHolderName: {
      inputType: "text",
      value: "",
      hintText: "Account Holder Name",
      mandatory: true,
      colSpan: 8,
      error: false,
      errorMessage: "Provide account holder name",
    },
    accountNumber: {
      inputType: "text",
      value: "",
      hintText: "Account No",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Enter account number",
    },
    confirmAccountNo: {
      inputType: "text",
      value: "",
      hintText: "Confirm Account No",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Account numbers don't match",
    },
    ifscCode: {
      inputType: "text",
      value: "",
      hintText: "IFSC/SWIFT Code",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Enter IFSC Code",
    },
    bankName: {
      inputType: "text",
      value: "",
      hintText: "Bank Name",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Provide Bank Name",
    },
    branch: {
      inputType: "text",
      value: "",
      hintText: "Branch",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Specify Bank Branch",
    },
  });

  const [vendorAdditionalInfo, setVendorAdditionalInfo] = useState({
    industryType: {
      inputType: "text",
      value: "",
      hintText: "Industry Type",
      mandatory: false,
      colSpan: 8,
      error: false,
      errorMessage: "",
    },
    foundedDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Founded Date",
      mandatory: false,
      colSpan: 8,
      error: false,
      errorMessage: "",
    },
    otherBusinessDetails: {
      inputType: "multilineText",
      value: "",
      hintText: "Other Business Details",
      mandatory: false,
      colSpan: 13,
      rowSpan: 3,
      error: false,
      errorMessage: "",
    },
    vendorLogo: {
      inputType: "photo",
      value: "",
      hintText: "Vendor Logo",
      mandatory: false,
      colSpan: 3,
      rowSpan: 2,
      error: false,
      errorMessage: "",
    },
  });

  const [vendorContactDetailsList, setvendorContactDetailsList] = useState([]);

  const [vendorBankDetailsList, setVendorBankDetailsList] = useState([]);

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getState();
    // eslint-disable-next-line
  }, [vendorGeneralInfo.country]);

  useEffect(() => {
    getCity();
    // eslint-disable-next-line
  }, [vendorGeneralInfo.state]);

  async function getInformation() {
    await checkToken();
    var vendorGenInfoCopy = Object.assign(vendorGeneralInfo);
    vendorGenInfoCopy = await getDim(
      vendorGenInfoCopy,
      { field: "country" },
      "country"
    );
    setVendorGeneralInfo(vendorGenInfoCopy);
  }

  const history = useHistory();
  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getDim(vendorParamCopy, dataSent, dimension) {
    var data = await fetchData({
      requestingPage: "getDim",
      method: "post",
      url: "dim",
      headers: { token: isLogged.accessToken, module: "Vendor" },
      data: dataSent,
    });
    if (data.msg !== "failure") {
      var optionArray = [];

      switch (dimension) {
        case "country":
          data.forEach((dataItem) => {
            var a = {
              optionName: dataItem.country.toUpperCase(),
              optionId: dataItem.country_id,
            };
            optionArray.push(a);
          });
          break;

        case "state":
          data.forEach((dataItem) => {
            var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
            optionArray.push(a);
          });
          break;

        case "cityId":
          data.forEach((dataItem) => {
            var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
            optionArray.push(a);
          });
          break;

        default:
          break;
      }

      vendorParamCopy = update(vendorParamCopy, {
        [dimension]: {
          options: { $set: optionArray },
          value: { $set: "" },
        },
      });
      return vendorParamCopy;
    } else {
      console.log(data);
    }
  }

  async function getState() {
    if (vendorGeneralInfo.country.value !== "") {
      var vendorGenInfoCopy = await getDim(
        vendorGeneralInfo,
        {
          field: "state",
          filterBy: "country_id",
          filterValue: vendorGeneralInfo.country.value,
        },
        "state"
      );

      setVendorGeneralInfo(vendorGenInfoCopy);
    }
  }

  async function getCity() {
    if (vendorGeneralInfo.state.value !== "") {
      var vendorGenInfoCopy = await getDim(
        vendorGeneralInfo,

        {
          field: "city",
          filterBy: "state_id",
          filterValue: vendorGeneralInfo.state.value,
        },
        "cityId"
      );

      setVendorGeneralInfo(vendorGenInfoCopy);
    }
  }

  const updateVendorParameter = ({ paramName, section, key, value }) => {

    switch (section) {
      case "generalInfo":
        var vendorParams = Object.assign(vendorGeneralInfo);
        vendorParams = update(vendorParams, {
          [paramName]: { [key]: { $set: value } },
        });
        setVendorGeneralInfo(vendorParams);
        break;

      case "contactDetails":
        var vendorParams2 = Object.assign(vendorContactDetail);
        vendorParams2 = update(vendorParams2, {
          [paramName]: { [key]: { $set: value } },
        });
        setvendorContactDetail(vendorParams2);
        break;

      case "bankDetails":
        var vendorParams3 = Object.assign(vendorBankDetail);
        vendorParams3 = update(vendorParams3, {
          [paramName]: { [key]: { $set: value } },
        });
        setvendorBankDetail(vendorParams3);
        break;

      case "additionalInfo":
        var vendorParams4 = Object.assign(vendorAdditionalInfo);
        vendorParams4 = update(vendorParams4, {
          [paramName]: { [key]: { $set: value } },
        });
        setVendorAdditionalInfo(vendorParams4);
        break;

      default:
        break;
    }
  };

  const [vendorContactsError, setVendorContactsError] = useState([]);
  const checkVendorContactsError = () => {
    var errorList = [];
    var paramsCopy = Object.assign(vendorContactDetail);

    vendorParameterList[1].forEach((element) => {
      if (paramsCopy[element].mandatory) {
        paramsCopy = update(paramsCopy, {
          [element]: {
            error: { $set: !validateMandatory(paramsCopy[element].value) },
          },
        });
      }
      if (element === "mobile") {
        paramsCopy = update(paramsCopy, {
          [element]: {
            error: { $set: !validateMobileNumber(paramsCopy[element].value) },
          },
        });
      }
      if (element === "email") {
        if (paramsCopy[element].value.length > 0) {
          paramsCopy = update(paramsCopy, {
            [element]: {
              error: { $set: !validateEmail(paramsCopy[element].value) },
            },
          });
        } else {
          paramsCopy = update(paramsCopy, {
            [element]: { error: { $set: false } },
          });
        }
      }

      if (paramsCopy[element].error) {
        errorList.push(paramsCopy[element].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var newVendorContact = {};
      vendorParameterList[1].forEach((item) => {
        // if (paramsCopy.contactDetails[item].value.length > 0) {
        newVendorContact[item] = paramsCopy[item].value;
        paramsCopy[item].value = "";
        // }
      });

      setvendorContactDetailsList([
        ...vendorContactDetailsList,
        newVendorContact,
      ]);
      // paramsCopy.contactDetailsList.push(newVendorContact);

      setVendorContactsError(errorList);
      setvendorContactDetail(paramsCopy);
    } else {
      setVendorContactsError(errorList);
      setvendorContactDetail(paramsCopy);
    }
  };

  const [vendorBankError, setVendorBankError] = useState([]);
  const checkVendorBankError = () => {
    var errorList = [];
    var paramsCopy = Object.assign(vendorBankDetail);

    vendorParameterList[2].forEach((element) => {
      if (paramsCopy[element].mandatory) {
        paramsCopy = update(paramsCopy, {
          [element]: {
            error: { $set: !validateMandatory(paramsCopy[element].value) },
          },
        });
      }
      if (element === "confirmAccountNo") {
      
        paramsCopy = update(paramsCopy, {
          [element]: {
            error: {
              $set: !validateEqualValues(
                paramsCopy[element].value,
                paramsCopy.accountNumber.value
              ),
            },
          },
        });
      }

      if (paramsCopy[element].error) {
        errorList.push(paramsCopy[element].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var newVendorbank = {};
      vendorParameterList[2].forEach((item) => {
        if (item !== "confirmAccountNo") {
          newVendorbank[item] = paramsCopy[item].value;
        }
        paramsCopy[item].value = "";
      });
      setVendorBankDetailsList([...vendorBankDetailsList, newVendorbank]);
      setVendorBankError(errorList);
      setvendorBankDetail(paramsCopy);
    } else {
      setVendorBankError(errorList);
      setvendorBankDetail(paramsCopy);
    }
  };

  const [vendorErrors, setVendorErrors] = useState([]);

  const checkErrors = () => {
    // check generalInfo error

    var errorList = [];
    var paramsCopy = Object.assign(vendorGeneralInfo);

    // check errors in general info area
    vendorParameterList[0].forEach((element) => {
      if (paramsCopy[element].mandatory) {
        paramsCopy = update(paramsCopy, {
          [element]: {
            error: {
              $set: !validateMandatory(paramsCopy[element].value.toString()),
            },
          },
        });
      }

      if (["mobile", "phone"].includes(element)) {
        if (paramsCopy[element].value.toString().length > 0) {
          paramsCopy = update(paramsCopy, {
            [element]: {
              error: { $set: !validateMobileNumber(paramsCopy[element].value) },
            },
          });
        }
      }

      if (element === "email") {
        if (paramsCopy[element].value.length > 0) {
          paramsCopy = update(paramsCopy, {
            [element]: {
              error: { $set: !validateEmail(paramsCopy[element].value) },
            },
          });
        } else {
          paramsCopy[element].error = false;
        }
      }

      if (paramsCopy[element].error) {
        errorList.push(paramsCopy[element].errorMessage);
      }
    });
    setVendorErrors(errorList);
    setVendorGeneralInfo(paramsCopy);

    // Checking vendor contact and bank details errors before submitting

    var vendorContactAllClear = true;
    var vendorBankAllClear = true;

    if (errorList.length === 0) {
      // contactDetails area
      // check atleast 1 row present
      // check if input is not saved

      if (vendorContactDetailsList.length > 0) {
        vendorParameterList[1].forEach((element) => {
          if (vendorContactDetail[element].value !== "") {
            vendorContactAllClear = false;
            setVendorContactsError(["Unsaved vendor contact information"]);
          }
        });
      } else {
        vendorContactAllClear = false;
        setVendorContactsError([
          "Enter atleast one contact information for vendor",
        ]);
      }

      // bankDetails area
      // check atleast 1 row present
      // check if input is not saved

      // if (vendorBankDetailsList.length > 0) {
      vendorParameterList[2].forEach((element) => {
        if (vendorBankDetail[element].value !== "") {
          vendorBankAllClear = false;
          setVendorBankError(["Unsaved vendor bank information"]);
        }
      });
      // } else {
      // vendorBankAllClear = false;
      // setVendorBankError(["Enter atleast one bank information for vendor"]);
      // }

      if (vendorContactAllClear && vendorBankAllClear) {
        var data2 = new FormData();

        vendorParameterList[0].forEach((item, i) => {
          if (
            paramsCopy[item].value !== null &&
            paramsCopy[item].value.toString().length > 0
          ) {
            data2.append(item, paramsCopy[item].value);
          }
        });

        data2.append("contactDetail", JSON.stringify(vendorContactDetailsList));

        if (vendorBankDetailsList.length > 0) {
          data2.append("bankDetail", JSON.stringify(vendorBankDetailsList));
        }

        vendorParameterList[3].forEach((item, i) => {
          if (
            vendorAdditionalInfo[item].value !== null &&
            vendorAdditionalInfo[item].value.toString().length > 0
          ) {
            data2.append(item, vendorAdditionalInfo[item].value);
          }
        });

        setIsOpen(true);
        submitData(data2);
      }
    }
  };

  async function submitData(data2) {
    setDataSubmitted(false);

    // setIsOpen(true);
    var result = await fetchData({
      requestingPage: "createVendor",
      method: "post",
      url: "create/vendor",
      headers: { token: isLogged.accessToken, module: "Vendor" },
      data: data2,
    });

    if (result.msg === "success") {
      setModalText("Upload success!");
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setVendorErrors([result.desc]);
    }

    setDataSubmitted(true);
  }

  const submitOkClick = () => {
    setIsOpen(false);
    if (vendorErrors.length === 0) {
      setSection("vendorList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const editSubSectionItem = (tab, item, action) => {
    if (action === "edit") {
      switch (tab) {
        case "contactActions":
          var contactAllClear = true;
          vendorParameterList[1].forEach((item) => {
            if (vendorContactDetail[item].value !== "") {
              contactAllClear = false;
            }
          });

          if (!contactAllClear) {
            var yes = window.confirm(
              "Unsaved data found. Are you sure you want to overwrite it?"
            );
          }

          if (yes || contactAllClear) {
            var vendorparam = Object.assign(vendorContactDetail);
            var vendorContactListCopy = vendorContactDetailsList.slice();

            var editObject = vendorContactListCopy.splice(item, 1)[0];

            Object.keys(editObject).forEach((key) => {
              vendorparam[key].value = editObject[key];
            });
            setvendorContactDetail(vendorparam);
            setvendorContactDetailsList(vendorContactListCopy);
          }
          break;

        case "bankActions":
          var bankAllClear = true;
          vendorParameterList[2].forEach((item) => {
            if (vendorBankDetail[item].value !== "") {
              bankAllClear = false;
            }
          });

          if (!bankAllClear) {
            var yes2 = window.confirm(
              "Unsaved data found. Are you sure you want to overwrite it?"
            );
          }

          if (yes2 || bankAllClear) {
            var vendorparam2 = Object.assign(vendorBankDetail);
            var vendorBankListCopy = vendorBankDetailsList.slice();

            var editBankObj = vendorBankListCopy.splice(item, 1)[0];

            Object.keys(editBankObj).forEach((key) => {
              vendorparam2[key].value = editBankObj[key];
            });
            vendorparam2.confirmAccountNo.value = editBankObj.accountNumber;
            setvendorBankDetail(vendorparam2);
            setVendorBankDetailsList(vendorBankListCopy);
          }
          break;

        default:
          console.log("Not Handled Yet");
          break;
      }
    } else if (action === "delete") {
      switch (tab) {
        case "contactActions":
          var vendorContactListCopy2 = vendorContactDetailsList.slice();
          vendorContactListCopy2.splice(item, 1);
          setvendorContactDetailsList(vendorContactListCopy2);
          break;

        case "bankActions":
          var vendorBankListCopy3 = vendorBankDetailsList.slice();
          vendorBankListCopy3.splice(item, 1);
          setVendorBankDetailsList(vendorBankListCopy3);
          break;

        default:
          console.log("Not Handled Yet");
          break;
      }
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Uploading form. Please wait...");

  const renderErrorMessage = () => {
    if (vendorErrors.length > 0)
      return `General Info Section: ${vendorErrors[0]}`;
    else if (vendorContactsError.length > 0)
      return `Contact Details Tab: ${vendorContactsError[0]}`;
    else if (vendorBankError.length > 0)
      return `Bank Details Tab: ${vendorBankError[0]}`;
    else return null;
  };

  const renderFormElements = ({ elementList, section }) => {
    return elementList.map((item) => {
      switch (item) {
        case "vendorLogo":
          return (
            <div className="vendorPhoto" key={item}>
              <FormHint
                hintText={vendorAdditionalInfo[item].hintText}
                mandatory={vendorAdditionalInfo[item].mandatory}
              />

              {/* eslint-disable-next-line */}
              <img style={{opacity:"1"}}
                src={vendorAdditionalInfo[item].url}
                className="vendorLogo"
              />
              <label
                className={
                  vendorAdditionalInfo[item].error
                    ? "uploadEditProfilePhotoError"
                    : "uploadEditProfilePhoto"
                }
                htmlFor="uploadVendorlogo">
                {vendorAdditionalInfo[item].value !== null &&
                vendorAdditionalInfo[item].value.name !== null &&
                vendorAdditionalInfo[item].value.name !== undefined &&
                vendorAdditionalInfo[item].value.name.length > 0
                  ? "EDIT"
                  : "UPLOAD"}
              </label>
              <input
                className="uploadEditInput"
                id="uploadVendorlogo"
                type="file"
                accept="images/*"
                onChange={(event) => {
                  var vendoraddInfoCopy = Object.assign(vendorAdditionalInfo);
                  vendoraddInfoCopy = update(vendoraddInfoCopy, {
                    [item]: {
                      value: { $set: event.target.files[0] },
                      url: { $set: URL.createObjectURL(event.target.files[0]) },
                    },
                  });
                  setVendorAdditionalInfo(vendoraddInfoCopy);
                }}
              />
            </div>
          );

        case "otherBusinessDetails":
          return (
            <div className="multiline" key={item}>
              <FormHint
                hintText={vendorAdditionalInfo[item].hintText}
                mandatory={vendorAdditionalInfo[item].mandatory}
              />

              <textarea
                value={vendorAdditionalInfo[item].value}
                className="multilineInput"
                rows="8"
                cols="50"
                name="text"
                placeholder="Enter your notes"
                style={{
                  boxSizing: "border-box",
                  fontFamily: "sans-serif",
                  padding: "10px",
                  resize: "none",
                }}
                onChange={(e) => {
                  updateVendorParameter({
                    section,
                    paramName: item,
                    key: "value",
                    value: e.target.value,
                  });
                }}
              />
            </div>
          );

        default:
          switch (section) {
            case "generalInfo":
              return (
                <FormElement
                  key={item}
                  inputType={vendorGeneralInfo[item].inputType}
                  value={vendorGeneralInfo[item].value}
                  setInput={(value) => {
                    updateVendorParameter({
                      section,
                      paramName: item,
                      key: "value",
                      value: value,
                    });
                  }}
                  hintText={vendorGeneralInfo[item].hintText}
                  mandatory={vendorGeneralInfo[item].mandatory}
                  colSpan={vendorGeneralInfo[item].colSpan}
                  options={
                    vendorGeneralInfo[item].inputType === "options"
                      ? vendorGeneralInfo[item].options
                      : null
                  }
                  error={vendorGeneralInfo[item].error}
                  rowSpan={
                    item === "vendorLogo" || item === "otherBusinessDetails"
                      ? vendorGeneralInfo[item].rowSpan
                      : null
                  }
                />
              );

            case "contactDetails":
              return (
                <FormElement
                  key={item}
                  inputType={vendorContactDetail[item].inputType}
                  value={vendorContactDetail[item].value}
                  setInput={(value) => {
                    updateVendorParameter({
                      section,
                      paramName: item,
                      key: "value",
                      value: value,
                    });
                  }}
                  hintText={vendorContactDetail[item].hintText}
                  mandatory={vendorContactDetail[item].mandatory}
                  colSpan={vendorContactDetail[item].colSpan}
                  options={
                    vendorContactDetail[item].inputType === "options"
                      ? vendorContactDetail[item].options
                      : null
                  }
                  error={vendorContactDetail[item].error}
                  rowSpan={
                    item === "vendorLogo" || item === "otherBusinessDetails"
                      ? vendorContactDetail[item].rowSpan
                      : null
                  }
                />
              );
            case "bankDetails":
              return (
                <FormElement
                  key={item}
                  inputType={vendorBankDetail[item].inputType}
                  value={vendorBankDetail[item].value}
                  setInput={(value) => {
                    updateVendorParameter({
                      section,
                      paramName: item,
                      key: "value",
                      value: value,
                    });
                  }}
                  hintText={vendorBankDetail[item].hintText}
                  mandatory={vendorBankDetail[item].mandatory}
                  colSpan={vendorBankDetail[item].colSpan}
                  options={
                    vendorBankDetail[item].inputType === "options"
                      ? vendorBankDetail[item].options
                      : null
                  }
                  error={vendorBankDetail[item].error}
                  rowSpan={
                    item === "vendorLogo" || item === "otherBusinessDetails"
                      ? vendorBankDetail[item].rowSpan
                      : null
                  }
                />
              );
            case "additionalInfo":
              return (
                <FormElement
                  key={item}
                  inputType={vendorAdditionalInfo[item].inputType}
                  value={vendorAdditionalInfo[item].value}
                  setInput={(value) => {
                    updateVendorParameter({
                      section,
                      paramName: item,
                      key: "value",
                      value: value,
                    });
                  }}
                  hintText={vendorAdditionalInfo[item].hintText}
                  mandatory={vendorAdditionalInfo[item].mandatory}
                  colSpan={vendorAdditionalInfo[item].colSpan}
                  options={
                    vendorAdditionalInfo[item].inputType === "options"
                      ? vendorAdditionalInfo[item].options
                      : null
                  }
                  error={vendorAdditionalInfo[item].error}
                  rowSpan={
                    item === "vendorLogo" || item === "otherBusinessDetails"
                      ? vendorAdditionalInfo[item].rowSpan
                      : null
                  }
                />
              );

            default:
              return null;
          }
      }
    });
  };

  const tablist = [
    { id: "contactDetails", name: "Contact Details" },
    { id: "bankDetails", name: "Bank Details" },
    { id: "additionalInfo", name: "Additional Information" },
  ];
  const [selectedTab, setSelectedTab] = useState("contactDetails");
  const RenderTabList = ({ tablist }) => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            selectedTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={() => setSelectedTab(tab.id)}>
          {tab.name}
          {tab.id === "contactDetails" ? (
            selectedTab === "contactDetails" ? (
              " *"
            ) : (
              <span style={{ color: "red" }}> *</span>
            )
          ) : null}
        </div>
      );
    });
  };

  const RenderTable = ({ tab }) => {
    switch (tab) {
      case "contactDetails":
        return (
          <table className="createVendorContactsTable">
            <thead>
              <tr className="createVendorContactsTableHeader">
                <td>Contact Name</td>
                <td>Designation</td>
                <td>Department</td>
                <td>Mobile No</td>
                <td>Email Id</td>
                <td>Experience</td>
                <td>Education Details</td>
                <td>Actions</td>
              </tr>
            </thead>
            {vendorContactDetailsList.length > 0 ? (
              <tbody>
                <RenderTableRows
                  rows={vendorContactDetailsList}
                  tab="contactActions"
                />
              </tbody>
            ) : (
              <tbody>
                <tr className="createVendorContactsTableRows">
                  {vendorParameterList[1].map((item) => {
                    return <td key={item}>&nbsp;</td>;
                  })}
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            )}
          </table>
        );

      case "bankDetails":
        return (
          <table className="createVendorContactsTable">
            <thead>
              <tr className="createVendorContactsTableHeader">
                <td>Account Holder Name</td>
                <td>Account Number</td>
                <td>Bank Name</td>
                <td>IFSC Code</td>
                <td>Branch</td>
                <td>Actions</td>
              </tr>
            </thead>
            {vendorBankDetailsList.length > 0 ? (
              <tbody>
                <RenderTableRows
                  rows={vendorBankDetailsList}
                  tab="bankActions"
                />
              </tbody>
            ) : (
              <tbody>
                <tr className="createVendorContactsTableRows">
                  {vendorParameterList[2].map((item) => {
                    return <td>&nbsp;</td>;
                  })}
                </tr>
              </tbody>
            )}
          </table>
        );

      default:
        return <div>No Table rendered</div>;
    }
  };

  const renderSubSection = (selectedTab) => {
    switch (selectedTab) {
      case "contactDetails":
        return (
          <React.Fragment>
            <div className="createVendorIndividualTabDetails">
              {renderFormElements({
                elementList: vendorParameterList[1],
                section: "contactDetails",
              })}
              <FormElement
                inputType="addButton"
                value="+ Add"
                colSpan={2}
                setInput={() => {
                  checkVendorContactsError();
                }}
              />
            </div>
            {/* {vendorParameters.contactDetailsList.length > 0 ? ( */}
            <RenderTable tab={selectedTab} />
          </React.Fragment>
        );

      case "bankDetails":
        return (
          <React.Fragment>
            <div className="createVendorIndividualTabDetails">
              {renderFormElements({
                elementList: vendorParameterList[2],
                section: "bankDetails",
              })}
              <FormElement
                inputType="addButton"
                value="+ Add"
                colSpan={2}
                setInput={() => {
                  checkVendorBankError();
                }}
              />
            </div>
            <RenderTable tab={selectedTab} />
          </React.Fragment>
        );

      case "additionalInfo":
        return (
          <React.Fragment>
            <div className="createVendorIndividualTabAdditionalDetails">
              {renderFormElements({
                elementList: vendorParameterList[3],
                section: "additionalInfo",
              })}
              {/* <FormElement inputType="addButton" value="+ Add" colSpan={2} /> */}
            </div>
          </React.Fragment>
        );

      default:
        return <div>No Specified Subsection</div>;
    }
  };

  //     const [errorModalOpen, setErrorModalOpen] = useState(false);

  const RenderTableRows = ({ rows, tab }) => {

    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {Object.keys(row).map((key, i) => {
            return <td key={i}>{row[key]}</td>;
          })}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(tab, j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) editSubSectionItem(tab, j, "delete");
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />

      <div className="formArea">
        <div
          style={{
            width: "1100px",
            margin: "0 auto 4rem",
            padding: "3rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div className="createVendorGeneralGrid">
            {/* {renderFormElements({ elementList: vendorParameterList[0], section: "generalInfo" })} */}
            {renderFormElements({
              elementList: vendorParameterList[0],
              section: "generalInfo",
            })}
          </div>
          <div className="createVendorTabArea">
            <div className="createVendorTabs">
              {/* {renderTabList()} */}
              <RenderTabList tablist={tablist} />
            </div>
            {renderSubSection(selectedTab)}
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="cancelButton"
            onClick={() => {
              var reset = window.confirm("Do you want to reset all fields?");
              if (reset) {
                // ==============================================================================
                // reset generalInfo
                var emptyGeneralInfo = Object.assign(vendorGeneralInfo);
                vendorParameterList[0].forEach((item) => {
                  emptyGeneralInfo[item].value = "";
                  emptyGeneralInfo[item].error = false;
                });
                setVendorGeneralInfo(emptyGeneralInfo);
                setVendorErrors([]);

                // ==============================================================================
                // reset vendorContactDetail
                var emptyContactDetail = Object.assign(vendorContactDetail);
                vendorParameterList[1].forEach((item) => {
                  emptyContactDetail[item].value = "";
                  emptyContactDetail[item].error = false;
                });
                setvendorContactDetail(emptyContactDetail);
                setvendorContactDetailsList([]);
                setVendorContactsError([]);

                // ==============================================================================
                // reset vendorBankDetail

                var emptyBankDetail = Object.assign(vendorBankDetail);
                vendorParameterList[2].forEach((item) => {
                  emptyBankDetail[item].value = "";
                  emptyBankDetail[item].error = false;
                });
                setvendorBankDetail(emptyBankDetail);
                setVendorBankDetailsList([]);
                setVendorBankError([]);

                // ==============================================================================
                // reset additionalInfo

                var emptyAdditionalInfo = Object.assign(vendorAdditionalInfo);
                vendorParameterList[3].forEach((item) => {
                  emptyAdditionalInfo[item].value = "";
                  emptyAdditionalInfo[item].error = false;
                  if (item === "vendorLogo") {
                    emptyAdditionalInfo[item].url = "";
                  }
                });
                setVendorAdditionalInfo(emptyAdditionalInfo);
              }
            }}>
            Reset Fields
          </button>
          <button
            className="submitButton"
            onClick={() => {
              setVendorErrors([]);
              setVendorContactsError([]);
              setVendorBankError([]);
              checkErrors();
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateVendor);
