import React, { useEffect, useState } from "react";
import FormHint from "../SmallComponents/FormHint";
import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateEmail, validateEqualValues, validateMandatory, validateMobileNumber } from "../CommonFunctions/ValidateFields";

import { stateList, cityList } from "../CommonFunctions/CountryStateCityList";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const customerParameterList = [
    // general info
    [
        "companyName",
        "mailingName",
        "email",
        "website",
        "address",
        "country",
        "state",
        "cityId",
        "zipCode",
        "panCardNo",
        "gstRegisterType",
        "gstNo",
        "mobile",
        "phone",
        "priceTypeId",
        "creditAmountLimit",
        "creditDays",
        "customerType",
        "parentCustomerId",
        "employeeId"
    ],

    // contact details
    ["contactName", "designation", "department", "mobile", "email", "experience", "education"],

    // deliver address details
    [
        "consignorCompanyName",
        "gstNo",
        "gstRegisterType",
        "address",
        "country",
        "state",
        "cityId",
        "zipCode",
        "contactName",
        "designation",
        "department",
        "mobile",
        "email",
    ],

    // bank details
    ["accountHolderName", "accountNumber", "confirmAccountNo", "ifscCode", "bankName", "branch"],

    // additional information
    ["industryType", "registerDate", "customerLogo", "otherBusinessDetails"],
];

const EditCustomer = ({
    // props
    setSection,

    // state
    isLogged,
    customerInfo,

    // dispatch
    addTokenToState,
}) => {
    const [customerGeneralInfo, setCustomerGeneralInfo] = useState({
        companyName: {
            inputType: "text",
            value: "",
            hintText: "Company Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please enter Company Name",
        },
        mailingName: {
            inputType: "text",
            value: "",
            hintText: "Mailing Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please enter Mailing Name",
        },
        address: {
            inputType: "text",
            value: "",
            hintText: "Address",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please provide company address",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Email",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Please provide valid email address",
        },
        country: {
            inputType: "options",
            value: "",
            hintText: "Country",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select country",
        },
        state: {
            inputType: "options",
            value: "",
            hintText: "State",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select state",
        },
        cityId: {
            inputType: "options",
            value: "",
            hintText: "City",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select city",
        },
        zipCode: {
            inputType: "text",
            value: "",
            hintText: "Zip Code",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Enter Zip code",
        },
        website: {
            inputType: "text",
            value: "",
            hintText: "Website",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Provide company website",
        },
        panCardNo: {
            inputType: "text",
            value: "",
            hintText: "PanCard Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        gstRegisterType: {
            inputType: "options",
            value: "",
            hintText: "GST Register Type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Regular", optionName: "Regular" },
                { optionId: "Composition", optionName: "Composition" },
                { optionId: "Unregistered", optionName: "Unregistered" },
                { optionId: "Consumer", optionName: "Consumer" },
            ],
            error: false,
            errorMessage: "Select GST register type",
        },
        gstNo: {
            inputType: "text",
            value: "",
            hintText: "GST No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide GST Number",
        },
        mobile: {
            inputType: "text",
            value: "",
            hintText: "Mobile Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        phone: {
            inputType: "text",
            value: "",
            hintText: "Phone Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        priceTypeId: {
            inputType: "options",
            value: "",
            hintText: "Price Type",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select price level option",
        },
        creditAmountLimit: {
            inputType: "text",
            value: "",
            hintText: "Credit Amount Limit",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        creditDays: {
            inputType: "text",
            value: "",
            hintText: "Credit Days",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        customerType: {
            inputType: "options",
            value: "",
            hintText: "Customer Type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Individual", optionName: "Individual" },
                { optionId: "Organization", optionName: "Organization" },
            ],
            error: false,
            errorMessage: "",
        },
        parentCustomerId: {
            inputType: "options",
            value: "",
            hintText: "Parent Company",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select price level option",
        },
        employeeId: {
            inputType: "multiSelect",
            value: [],
            hintText: "Assigned To",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select atleast one Employee",
        },
    });

    const [customerContactDetail, setCustomerContactDetail] = useState({
        contactName: {
            inputType: "text",
            value: "",
            hintText: "Contact Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Contact Name",
        },
        designation: {
            inputType: "text",
            value: "",
            hintText: "Designation",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        department: {
            inputType: "text",
            value: "",
            hintText: "Department",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "testing spaces",
        },
        mobile: {
            inputType: "text",
            value: "",
            hintText: "Mobile No",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Email",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Please enter a valid email address",
        },
        experience: {
            inputType: "text",
            value: "",
            hintText: "Experience",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "",
        },
        education: {
            inputType: "text",
            value: "",
            hintText: "Education Details",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "",
        },
        contactDetailId: { value: null },
    });

    const [customerDeliverAddress, setCustomerDeliverAddress] = useState({
        consignorCompanyName: {
            inputType: "text",
            value: "",
            hintText: "Consignor Company name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Provide Consignor Company Name",
        },
        gstNo: {
            inputType: "text",
            value: "",
            hintText: "GST No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide GST Number",
        },
        gstRegisterType: {
            inputType: "options",
            value: "",
            hintText: "GST Register Type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Regular", optionName: "Regular" },
                { optionId: "Composition", optionName: "Composition" },
                { optionId: "Unregistered", optionName: "Unregistered" },
                { optionId: "Consumer", optionName: "Consumer" },
            ],
            error: false,
            errorMessage: "Select GST register type",
        },
        address: {
            inputType: "text",
            value: "",
            hintText: "Address",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Enter address",
        },
        country: {
            inputType: "options",
            value: "",
            hintText: "Country",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select country",
        },
        state: {
            inputType: "options",
            value: "",
            hintText: "State",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select State",
        },
        cityId: {
            inputType: "options",
            value: "",
            hintText: "City",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select City",
        },
        zipCode: {
            inputType: "text",
            value: "",
            hintText: "Zip Code",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Contact Name",
        },
        contactName: {
            inputType: "text",
            value: "",
            hintText: "Contact Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Contact Name",
        },
        designation: {
            inputType: "text",
            value: "",
            hintText: "Designation",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        department: {
            inputType: "text",
            value: "",
            hintText: "Department",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "testing spaces",
        },
        mobile: {
            inputType: "text",
            value: "",
            hintText: "Mobile No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Email",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter a valid email address",
        },
        deliveryAddressId: { value: null },
    });

    const [customerBankDetail, setCustomerBankDetail] = useState({
        accountHolderName: {
            inputType: "text",
            value: "",
            hintText: "Account Holder Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Provide account holder name",
        },
        accountNumber: {
            inputType: "text",
            value: "",
            hintText: "Account No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter account number",
        },
        confirmAccountNo: {
            inputType: "text",
            value: "",
            hintText: "Confirm Account No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Account numbers don't match",
        },
        ifscCode: {
            inputType: "text",
            value: "",
            hintText: "IFSC Code",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter IFSC Code",
        },
        bankName: {
            inputType: "text",
            value: "",
            hintText: "Bank Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Bank Name",
        },
        branch: {
            inputType: "text",
            value: "",
            hintText: "Branch",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Specify Bank Branch",
        },
        bankDetailId: { value: null },
    });

    const [customerAdditionalInfo, setCustomerAdditionalInfo] = useState({
        industryType: {
            inputType: "text",
            value: "",
            hintText: "Industry Type",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "",
        },
        registerDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Founded Date",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "",
        },
        otherBusinessDetails: {
            inputType: "multilineText",
            value: "",
            hintText: "Other Business Details",
            mandatory: false,
            colSpan: 13,
            rowSpan: 3,
            error: false,
            errorMessage: "",
        },
        customerLogo: {
            inputType: "photo",
            value: "",
            hintText: "customer Logo",
            mandatory: false,
            colSpan: 3,
            rowSpan: 2,
            error: false,
            errorMessage: "",
        },
    });

    const [customerContactDetailsList, setCustomerContactDetailsList] = useState([]);

    const [customerDeliverAddressList, setCustomerDeliverAddressList] = useState([]);

    const [customerBankDetailsList, setCustomerBankDetailsList] = useState([]);

    useEffect(() => {
        getInformation();
        setIsOpen(true);
        setModalText("Getting Customer Information");
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getState();
        // eslint-disable-next-line
    }, [customerGeneralInfo.country.value]);

    useEffect(() => {
        getCity();
        // eslint-disable-next-line
    }, [customerGeneralInfo.state.value]);

    useEffect(() => {
        getStateForDeliver();
        // eslint-disable-next-line
    }, [customerDeliverAddress.country]);

    useEffect(() => {
        getCityForDeliver();
        // eslint-disable-next-line
    }, [customerDeliverAddress.state]);

    useEffect(() => {
        if (customerAdditionalInfo.customerLogo !== null) getCustomerLogo();
        // eslint-disable-next-line
    }, [customerAdditionalInfo.customerLogo]);

    const [customerLogoServer, setCustomerLogoServer] = useState();
    async function getCustomerLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/customer-logo/" + customerInfo.editCustomerId,
            headers: { token: isLogged.accessToken,module:"Customer" },
        });
        data.msg !== "failure" ? setCustomerLogoServer(data) : console.log(data);
    }

    async function getEmployees() {
        var data = await fetchData({
            requestingPage: "employeeList",
            method: "POST",
            url: "fetch/employees",
            headers: { token: isLogged.accessToken,module:"Employee" },
        });
        if (data.msg === "success") {
          return data.asset;
        } else {
            return [];
        }
     }

    async function getInformation() {
        await checkToken();

        var data = await getCustomerDetail();
        var customerGeneralInfoFromServer = data.asset.customerDetail[0];
        var customerContactListFromServer = data.asset.contactDetail;
        var customerDeliverAddressFromServer = data.asset.deliveryDetail;
        var customerBankListFromServer = data.asset.bankDetail;
        var customerAddInfoFromServer = data.asset.additionalInfo[0];

        var parentsList = await getParents();

        var customerGeneralInfoCopy = await setDataFromServer(
            customerGeneralInfoFromServer,
            customerDeliverAddressFromServer,
            customerContactListFromServer,
            customerBankListFromServer,
            customerAddInfoFromServer
        );

        var dataDim = await getDim({ field: "country" });
        var parsedDataDim = await parseDim(dataDim, "country");

        var dataDimState = await getDim({ field: "state", filterBy: "country_id", filterValue: customerGeneralInfoCopy.country.value });
        var parsedDataDimState = await parseDim(dataDimState, "state");

        var dataDimCity = await getDim({ field: "city", filterBy: "state_id", filterValue: customerGeneralInfoCopy.state.value });
        var parsedDataDimCity = await parseDim(dataDimCity, "cityId");

        var priceTypeOptionsList = await getPriceTypeList();
        let employeeList = await getEmployees();

        let selectedEmployee = [];
            customerGeneralInfoFromServer.employeeIds.map((value)=>{
        let tempObj=employeeList.filter((obj)=>obj.optionId==value)[0]
            // if it has values push to employees
            if(tempObj)
            selectedEmployee.push({label:tempObj.optionName,value:tempObj.optionId})
    });

        customerGeneralInfoCopy = update(customerGeneralInfoCopy, {
            country: { options: { $set: parsedDataDim } },
            state: { options: { $set: parsedDataDimState } },
            cityId: { options: { $set: parsedDataDimCity } },
            parentCustomerId: { options: { $set: parentsList } },
            priceTypeId: { options: { $set: priceTypeOptionsList } },
            employeeId: {value: {$set: selectedEmployee} , options: { $set: employeeList} },
        });

        var customerDeliverAddressCopy = Object.assign(customerDeliverAddress);
        customerDeliverAddressCopy = update(customerDeliverAddressCopy, { country: { options: { $set: parsedDataDim }, value: { $set: "" } } });

        setCustomerGeneralInfo(customerGeneralInfoCopy);
        setCustomerDeliverAddress(customerDeliverAddressCopy);
        setIsOpen(false);
    }

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getParents() {
        var data = await fetchData({
            requestingPage: "getParents",
            method: "get",
            url: "fetch/customer-list",
            headers: { token: isLogged.accessToken ,module:"Customer"},
        });
        if (data.msg === "success") {
            var parentOptions = [];
            data.asset.forEach((parent) => {
                var newParent = { optionId: parent.customerId, optionName: parent.customer };
                parentOptions.push(newParent);
            });
            return parentOptions;
        }
    }

    async function getDim(dataSent) {
        var data = await fetchData({
            requestingPage: "getDim",
            method: "post",
            url: "dim",
            headers: { token: isLogged.accessToken ,module:"Customer"},
            data: dataSent,
        });
        if (data.msg !== "failure") {
            return data;
        } else {
            return [];
        }
    }

    async function parseDim(data, dimension) {
        var optionArray = [];

        switch (dimension) {
            case "country":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                    optionArray.push(a);
                });
                break;

            case "state":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                    optionArray.push(a);
                });
                break;

            case "cityId":
                data.forEach((dataItem) => {
                    var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                    optionArray.push(a);
                });
                break;

            default:
                break;
        }

        return optionArray;
    }

    async function getCustomerDetail() {
        var data = await fetchData({
            requestingPage: "customerDetail",
            method: "get",
            url: "fetch/customer/" + customerInfo.editCustomerId,
            headers: { token: isLogged.accessToken ,module:"Customer"},
        });
        if (data.msg === "success") {
            return data;
        } else {
            console.log(data);
            return [];
        }
    }

    async function setDataFromServer(customerGenInfo, customerDelivAdd, customerContactList, customerBankList, customerAddInfo) {
        // ================================================================
        // set vendorGeneralInfo
        // ================================================================
        var customerGeneralInfoCopy = Object.assign(customerGeneralInfo);
        customerParameterList[0].forEach((element) => {
            if (element === "cityId") customerGeneralInfoCopy.cityId.value = customerGenInfo.cityId;
            else if (element === "state") customerGeneralInfoCopy[element].value = customerGenInfo.stateId;
            else if (element === "country") customerGeneralInfoCopy[element].value = customerGenInfo.countryid;
            else {
                if (customerGenInfo[element] !== null) {
                    customerGeneralInfoCopy[element].value = customerGenInfo[element];
                } else {
                    customerGeneralInfoCopy[element].value = "";
                }
            }
        });
        setCustomerGeneralInfo(customerGeneralInfoCopy);

        // ================================================================
        // set customerContactList
        // ================================================================
        var newContactList = [];
        customerContactList.forEach((item) => {
            var newContact = {};
            ["contactName", "designation", "department", "mobile", "email", "experience", "education", "contactDetailId"].forEach((element) => {
                newContact[element] = item[element];
            });
            newContactList.push(newContact);
        });
        setCustomerContactDetailsList(newContactList);

        // ================================================================
        // set customerDelivAddList
        // ================================================================
        var newDeliverAddList = [];
        customerDelivAdd.forEach((item) => {
            var newAdd = {};
            [
                "consignorCompanyName",
                "gstNo",
                "gstRegisterType",
                "address",
                "country",
                "state",
                "cityId",
                "zipCode",
                "contactName",
                "designation",
                "department",
                "mobile",
                "email",
                "deliveryAddressId",
            ].forEach((element) => {
                if (["state", "cityId"].includes(element)) {
                    console.log(`Need to get ${element} options`);
                }

                if (element === "country") {
                    newAdd.country = item.countryid;
                } else if (element === "state") {
                    newAdd.state = item.stateId;
                } else {
                    newAdd[element] = item[element];
                }
            });
            newDeliverAddList.push(newAdd);
        
            setCustomerDeliverAddressList(newDeliverAddList);
        });

        // ================================================================
        // set customerBankList
        // ================================================================
        var newBankDetailList = [];
        customerBankList.forEach((item) => {
            var newBank = {};
            ["accountHolderName", "accountNumber", "confirmAccountNo", "ifscCode", "bankName", "branch", "bankDetailId"].forEach((element) => {
                if (element !== "confirmAccountNo") newBank[element] = item[element];
            });
            newBankDetailList.push(newBank);
            setCustomerBankDetailsList(newBankDetailList);
        });

        // ================================================================
        // set customerAdditionalInfo
        // ================================================================
        var customerAddInfoCopy = Object.assign(customerAdditionalInfo);
        customerParameterList[4].forEach((element) => {
            if (element !== "customerLogo") {
                if (element === "registerDate") {
                    if (customerAddInfo[element] !== null && customerAddInfo[element] !== undefined) {
                        var date = new Date(customerAddInfo[element]);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dateValue = date.getDate();

                        if (month.toString().length === 1) month = `0${month}`;
                        if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

                        var formattedDate = `${year}-${month}-${dateValue}`;
                        customerAddInfoCopy[element].value = formattedDate;
                    } else {
                        customerAddInfoCopy[element.value] = "";
                    }
                } else {
                    customerAddInfoCopy[element].value = customerAddInfo[element];
                }
            }
        });
        setCustomerAdditionalInfo(customerAddInfoCopy);

        return customerGeneralInfoCopy;
    }

    async function getState() {
        if (customerGeneralInfo.country.value !== "") {
            var dataDim = await getDim({ field: "state", filterBy: "country_id", filterValue: customerGeneralInfo.country.value });
            var parsedDataDim = await parseDim(dataDim, "state");
            var customerGenInfoCopy = Object.assign(customerGeneralInfo);
            customerGenInfoCopy = update(customerGenInfoCopy, {
                state: {
                    options: { $set: parsedDataDim },
                    value: { $set: "" },
                },
            });

            setCustomerGeneralInfo(customerGenInfoCopy);
        }
    }

    async function getStateForDeliver() {
        if (customerDeliverAddress.country.value !== "") {
            var dataDim = await getDim({ field: "state", filterBy: "country_id", filterValue: customerDeliverAddress.country.value });
            var parsedDataDim = await parseDim(dataDim, "state");
            var customerDeliverAddressCopy = Object.assign(customerDeliverAddress);

            customerDeliverAddressCopy = update(customerDeliverAddressCopy, {
                state: {
                    options: { $set: parsedDataDim },
                    value: { $set: "" },
                },
            });

            setCustomerDeliverAddress(customerDeliverAddressCopy);
        }
    }

    async function getCity() {
        if (customerGeneralInfo.state.value !== "") {
            var dataDim = await getDim({ field: "city", filterBy: "state_id", filterValue: customerGeneralInfo.state.value });
            var parsedDataDim = await parseDim(dataDim, "cityId");
            var customerGenInfoCopy = Object.assign(customerGeneralInfo);
            customerGenInfoCopy = update(customerGenInfoCopy, {
                cityId: {
                    options: { $set: parsedDataDim },
                    value: { $set: "" },
                },
            });

            setCustomerGeneralInfo(customerGenInfoCopy);
        }
    }

    async function getCityForDeliver() {
        if (customerDeliverAddress.state.value !== "") {
            var dataDim = await getDim({ field: "city", filterBy: "state_id", filterValue: customerDeliverAddress.state.value });
            var parsedDataDim = await parseDim(dataDim, "cityId");
            var customerDeliverAddressCopy = Object.assign(customerDeliverAddress);

            customerDeliverAddressCopy = update(customerDeliverAddressCopy, {
                cityId: {
                    options: { $set: parsedDataDim },
                    value: { $set: "" },
                },
            });

            setCustomerDeliverAddress(customerDeliverAddressCopy);
        }
    }

    async function getPriceTypeList() {
        var data = await fetchData({
            requestingPage: "itemPriceTypeList",
            method: "get",
            url: "fetch/price-types",
            headers: { token: isLogged.accessToken ,module:"Customer"},
        });

        if (data.msg === "success") {

            var priceTypeOptions = [];

            data.asset.forEach((element) => {
                var newOption = {
                    optionId: element.priceTypeId,
                    optionName: element.priceType,
                };
                priceTypeOptions.push(newOption);
            });
            return priceTypeOptions;
        }
    }

    const updateCustomerParameter = ({ paramName, section, key, value }) => {
        switch (section) {
            case "generalInfo":
                var customerParams = Object.assign(customerGeneralInfo);
                customerParams = update(customerParams, { [paramName]: { [key]: { $set: value } } });
                setCustomerGeneralInfo(customerParams);
                break;

            case "contactDetails":
                var customerParams2 = Object.assign(customerContactDetail);
                customerParams2 = update(customerParams2, { [paramName]: { [key]: { $set: value } } });
                setCustomerContactDetail(customerParams2);
                break;

            case "deliverAddress":
                var customerParams3 = Object.assign(customerDeliverAddress);
                customerParams3 = update(customerParams3, { [paramName]: { [key]: { $set: value } } });
                setCustomerDeliverAddress(customerParams3);
                break;

            case "bankDetails":
                var customerParams4 = Object.assign(customerBankDetail);
                customerParams4 = update(customerParams4, { [paramName]: { [key]: { $set: value } } });
                setCustomerBankDetail(customerParams4);
                break;

            case "additionalInfo":
                var customerParams5 = Object.assign(customerAdditionalInfo);
                customerParams5 = update(customerParams5, { [paramName]: { [key]: { $set: value } } });
                setCustomerAdditionalInfo(customerParams5);
                break;

            default:
                break;
        }
    };

    const [customerContactsError, setCustomerContactsError] = useState([]);
    const checkCustomerContactsError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(customerContactDetail);

        customerParameterList[1].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateMandatory(paramsCopy[element].value) } },
                });
            }
            if (element === "mobile") {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateMobileNumber(paramsCopy[element].value) } },
                });
            }
            if (element === "email") {
                if (paramsCopy[element].value.length > 0) {
                    paramsCopy = update(paramsCopy, {
                        [element]: { error: { $set: !validateEmail(paramsCopy[element].value) } },
                    });
                } else {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: false } } });
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newCustomerContact = {};
            ["contactName", "designation", "department", "mobile", "email", "experience", "education", "contactDetailId"].forEach((item) => {
                newCustomerContact[item] = paramsCopy[item].value;
                item === "contactDetailId" ? (paramsCopy.contactDetailId.value = null) : (paramsCopy[item].value = "");
            });

            setCustomerContactDetailsList([...customerContactDetailsList, newCustomerContact]);

            setCustomerContactsError(errorList);
            setCustomerContactDetail(paramsCopy);
        } else {
            setCustomerContactsError(errorList);
            setCustomerContactDetail(paramsCopy);
        }
    };

    const [customerDeliverError, setCustomerDeliverError] = useState([]);
    const checkCustomerDeliverAddressError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(customerDeliverAddress);

        customerParameterList[2].forEach((element) => {

            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }
            if (element === "mobile") {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateMobileNumber(paramsCopy[element].value) } },
                });
            }

            if (element === "email") {
                if (paramsCopy[element].value.length > 0) {
                    paramsCopy = update(paramsCopy, {
                        [element]: { error: { $set: !validateEmail(paramsCopy[element].value) } },
                    });
                } else {
                    paramsCopy[element].error = false;
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newCustomerDelivery = {};
            [
                "consignorCompanyName",
                "gstNo",
                "gstRegisterType",
                "address",
                "country",
                "state",
                "cityId",
                "zipCode",
                "contactName",
                "designation",
                "department",
                "mobile",
                "email",
                "deliveryAddressId",
            ].forEach((item) => {
                newCustomerDelivery[item] = paramsCopy[item].value;
                if (["state", "cityId"].includes(item)) {
                    var key = `${item}Options`;
                    newCustomerDelivery[key] = paramsCopy[item].options;
                    paramsCopy[item].options = [];
                }
                item === "deliveryAddressId" ? (paramsCopy.deliveryAddressId.value = null) : (paramsCopy[item].value = "");
            });
            setCustomerDeliverAddressList([...customerDeliverAddressList, newCustomerDelivery]);
            setCustomerDeliverError(errorList);
            setCustomerDeliverAddress(paramsCopy);
        } else {
            setCustomerDeliverError(errorList);
            setCustomerDeliverAddress(paramsCopy);
        }
    };

    const [customerBankError, setCustomerBankError] = useState([]);
    const checkCustomerBankError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(customerBankDetail);

        customerParameterList[3].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value) } } });
            }
            if (element === "confirmAccountNo") {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateEqualValues(paramsCopy[element].value, paramsCopy.accountNumber.value) } },
                });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newCustomerbank = {};
            ["accountHolderName", "accountNumber", "confirmAccountNo", "ifscCode", "bankName", "branch", "bankDetailId"].forEach((item) => {
                if (item !== "confirmAccountNo") {
                    newCustomerbank[item] = paramsCopy[item].value;
                }
                item === "bankDetailId" ? (paramsCopy.bankDetailId.value = null) : (paramsCopy[item].value = "");
            });
            setCustomerBankDetailsList([...customerBankDetailsList, newCustomerbank]);
            setCustomerBankError(errorList);
            setCustomerBankDetail(paramsCopy);
        } else {
            setCustomerBankError(errorList);
            setCustomerBankDetail(paramsCopy);
        }
    };

    const [customerErrors, setCustomerErrors] = useState([]);
    const checkErrors = () => {
        // check generalInfo error

        var errorList = [];
        var paramsCopy = Object.assign(customerGeneralInfo);

        // check errors in general info area
        customerParameterList[0].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, {
                    [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } },
                });
            }

            if (["mobile", "phone"].includes(element)) {
                if (paramsCopy[element].value.toString().length > 0) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMobileNumber(paramsCopy[element].value) } } });
                }
            }

            if (element === "email") {
                if (paramsCopy[element].value.length > 0) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateEmail(paramsCopy[element].value) } } });
                } else {
                    paramsCopy[element].error = false;
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });
        setCustomerErrors(errorList);
        setCustomerGeneralInfo(paramsCopy);

        // Checking customer contact and bank details errors before submitting

        var customerContactAllClear = true;
        var customerDeliverAllClear = true;
        var customerBankAllClear = true;

        if (errorList.length === 0) {

            // contactDetails area
            // check atleast 1 row present
            // check if input is not saved

            if (customerContactDetailsList.length > 0) {
                customerParameterList[1].forEach((element) => {
                    if (customerContactDetail[element].value !== "") {
                        customerContactAllClear = false;
                        setCustomerContactsError(["Unsaved Information found"]);
                    }
                });
            } else {
                customerContactAllClear = false;
                setCustomerContactsError(["Enter atleast one contact information for customer"]);
            }

            // deliverAddress area
            // check atleast 1 row present
            // check if input is not saved

            if (customerDeliverAddressList.length > 0) {
                customerParameterList[2].forEach((element) => {
                    if (customerDeliverAddress[element].value !== "") {
                        customerDeliverAllClear = false;
                        setCustomerDeliverError(["Unsaved Information found"]);
                    }
                });
            } else {
                customerDeliverAllClear = false;
                setCustomerDeliverError(["Enter alteast one Deliver Address for customer"]);
            }

            // bankDetails area
            // check atleast 1 row present
            // check if input is not saved

            // if (customerBankDetailsList.length > 0) {
            customerParameterList[3].forEach((element) => {
                if (customerBankDetail[element].value !== "") {
                    customerBankAllClear = false;
                    setCustomerBankError(["Unsaved Information found"]);
                }
            });
            // } else {
            //     customerBankAllClear = false;
            //     setCustomerBankError(["Enter atleast one bank information for customer"]);
            // }

            if (customerContactAllClear && customerBankAllClear && customerDeliverAllClear) {
                prepareDataToSubmit(paramsCopy);
            }
        }
    };

    const prepareDataToSubmit = (paramsCopy) => {
        var data2 = new FormData();

        // Add General Info
        customerParameterList[0].forEach((item) => {
            if (paramsCopy[item].value !== null && paramsCopy[item].value.toString().length > 0) {
                if(item === "employeeId"){
                    data2.append(item, paramsCopy[item].value.map((obj) => obj.value) )
                }
                else{
                data2.append(item, paramsCopy[item].value);
                }
            }
        });
        data2.append("customerId", customerInfo.editCustomerId);

        // Add Contact Details
        data2.append("contactDetail", JSON.stringify(customerContactDetailsList));

        // Add Deliver Address Detail
        var deliverListCopy = Object.assign(customerDeliverAddressList);
        deliverListCopy.forEach((item, i) => {
            delete deliverListCopy[i].stateOptions;
            delete deliverListCopy[i].cityIdOptions;
            delete deliverListCopy[i].country;
            delete deliverListCopy[i].state;
        });
        data2.append("deliveryAddress", JSON.stringify(deliverListCopy));

        // Add Bank Details
        data2.append("bankDetail", JSON.stringify(customerBankDetailsList));

        // Add Additional Info Details
        customerParameterList[4].forEach((item) => {
            if (customerAdditionalInfo[item].value !== null && customerAdditionalInfo[item].value.toString().length > 0) {
                data2.append(item, customerAdditionalInfo[item].value);
            }
        });

        setModalText("Uploading form. Please wait... ");
        setIsOpen(true);
        submitData(data2);
    };

    const [customerServerError, setcustomerServerError] = useState([]);
    async function submitData(data2) {
        // setIsOpen(true);
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "put",
            url: "edit/customer",
            headers: { token: isLogged.accessToken ,module:"Customer"},
            data: data2,
        });

        if (result.msg === "success") {
            setModalText("Upload success!");
            setDataSubmitted(true);
        } else {
            setModalText("Upload failed", result.desc);
            setTimeout(() => {
                setIsOpen(false);
                setModalText("Uploading form. Please wait... ");
                setcustomerServerError([result.desc]);
            }, 500);
        }
    }

    const submitOkClick = () => {
        setIsOpen(false);
        setSection("customerList");
    };

    const copyDataToDeliveryAddress = () => {
        // check if fields from customerGeneralInfo is filled
        var genInfoFilled = true;
        var genInfoMandatoryFields = ["companyName", "gstNo", "address", "country", "state", "cityId"];

        genInfoMandatoryFields.forEach((element) => {
            if ((customerGeneralInfo[element].value === "") | (customerGeneralInfo[element].value.length < 1)) {
                genInfoFilled = false;
            }
        });

        // check if there is atleast one element in customerContactDetailsList
        if (customerContactDetailsList.length > 0 && genInfoFilled) {
            setCopyDeliverAddress(true);

            // now set the values from two sections to deliverAddress list

            var customerDeliverAddressCopy = Object.assign(customerDeliverAddress);

            customerDeliverAddressCopy.consignorCompanyName.value = customerGeneralInfo.companyName.value;
            customerDeliverAddressCopy.gstNo.value = customerGeneralInfo.gstNo.value;
            customerDeliverAddressCopy.gstRegisterType.value = customerGeneralInfo.gstRegisterType.value;
            customerDeliverAddressCopy.address.value = customerGeneralInfo.address.value;
            customerDeliverAddressCopy.country.value = customerGeneralInfo.country.value;
            customerDeliverAddressCopy.state.options = customerGeneralInfo.state.options;
            customerDeliverAddressCopy.state.value = customerGeneralInfo.state.value;
            customerDeliverAddressCopy.cityId.options = customerGeneralInfo.cityId.options;
            customerDeliverAddressCopy.cityId.value = customerGeneralInfo.cityId.value;
            customerDeliverAddressCopy.zipCode.value = customerGeneralInfo.zipCode.value;

            customerDeliverAddressCopy.contactName.value = customerContactDetailsList[0].contactName;
            customerDeliverAddressCopy.designation.value = customerContactDetailsList[0].designation;
            customerDeliverAddressCopy.department.value = customerContactDetailsList[0].department;
            customerDeliverAddressCopy.mobile.value = customerContactDetailsList[0].mobile;
            customerDeliverAddressCopy.email.value = customerContactDetailsList[0].email;

            setCustomerDeliverAddress(customerDeliverAddressCopy);
        } else {
            window.alert("Make sure of the following \n\n1) Address detail above is complete,\n2) Atleast one contact information is filled");
        }
    };

    const [copyDeliverAddress, setCopyDeliverAddress] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const renderFormElements = ({ elementList, section }) => {
        return elementList.map((item) => {
            switch (item) {
                case "customerLogo":
                    return (
                        <div className="vendorPhoto" key={item}>
                            <FormHint hintText={customerAdditionalInfo[item].hintText} mandatory={customerAdditionalInfo[item].mandatory} />

                            {/* eslint-disable-next-line */}
                            <img style={{opacity:"1"}}
                                src={
                                    customerAdditionalInfo[item].url
                                        ? customerAdditionalInfo[item].url
                                        : `data:image/png;base64,${customerLogoServer}`
                                }
                                className="vendorLogo"
                            />
                            <label
                                className={customerAdditionalInfo[item].error ? "uploadEditProfilePhotoError" : "uploadEditProfilePhoto"}
                                htmlFor="uploadVendorlogo"
                            >
                                {customerLogoServer !== null ||
                                (customerAdditionalInfo[item].value !== null &&
                                    customerAdditionalInfo[item].value.name !== null &&
                                    customerAdditionalInfo[item].value.name !== undefined &&
                                    customerAdditionalInfo[item].value.name.length > 0)
                                    ? "EDIT"
                                    : "UPLOAD"}
                            </label>
                            <input
                                className="uploadEditInput"
                                id="uploadVendorlogo"
                                type="file"
                                accept="images/*"
                                onChange={(event) => {
                                    var customerAddInfoCopy = Object.assign(customerAdditionalInfo);
                                    customerAddInfoCopy = update(customerAddInfoCopy, {
                                        [item]: {
                                            value: { $set: event.target.files[0] },
                                            url: { $set: URL.createObjectURL(event.target.files[0]) },
                                        },
                                    });
                                    setCustomerAdditionalInfo(customerAddInfoCopy);
                                }}
                            />
                        </div>
                    );

                case "otherBusinessDetails":
                    return (
                        <div className="multiline" key={item}>
                            <FormHint hintText={customerAdditionalInfo[item].hintText} mandatory={customerAdditionalInfo[item].mandatory} />

                            <textarea
                                value={customerAdditionalInfo[item].value}
                                className="multilineInput"
                                rows="8"
                                cols="50"
                                name="text"
                                placeholder="Enter your notes"
                                style={{ boxSizing: "border-box", fontFamily: "sans-serif", padding: "10px", resize: "none" }}
                                onChange={(e) => {
                                    updateCustomerParameter({ section, paramName: item, key: "value", value: e.target.value });
                                }}
                            />
                        </div>
                    );

                default:
                    switch (section) {
                        case "generalInfo":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={customerGeneralInfo[item].inputType}
                                    value={customerGeneralInfo[item].value}
                                    setInput={(value) => {
                                        updateCustomerParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={customerGeneralInfo[item].hintText}
                                    mandatory={customerGeneralInfo[item].mandatory}
                                    colSpan={customerGeneralInfo[item].colSpan}
                                    options={ customerGeneralInfo[item].options || null}
                                    error={customerGeneralInfo[item].error}
                                    rowSpan={item === "customerLogo" || item === "otherBusinessDetails" ? customerGeneralInfo[item].rowSpan : null}
                                />
                            );

                        case "contactDetails":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={customerContactDetail[item].inputType}
                                    value={customerContactDetail[item].value}
                                    setInput={(value) => {
                                        updateCustomerParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={customerContactDetail[item].hintText}
                                    mandatory={customerContactDetail[item].mandatory}
                                    colSpan={customerContactDetail[item].colSpan}
                                    options={customerContactDetail[item].inputType === "options" ? customerContactDetail[item].options : null}
                                    error={customerContactDetail[item].error}
                                    rowSpan={item === "customerLogo" || item === "otherBusinessDetails" ? customerContactDetail[item].rowSpan : null}
                                />
                            );

                        case "deliverAddress":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={customerDeliverAddress[item].inputType}
                                    value={customerDeliverAddress[item].value}
                                    setInput={(value) => {
                                        updateCustomerParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={customerDeliverAddress[item].hintText}
                                    mandatory={customerDeliverAddress[item].mandatory}
                                    colSpan={customerDeliverAddress[item].colSpan}
                                    options={customerDeliverAddress[item].inputType === "options" ? customerDeliverAddress[item].options : null}
                                    error={customerDeliverAddress[item].error}
                                    rowSpan={item === "customerLogo" || item === "otherBusinessDetails" ? customerDeliverAddress[item].rowSpan : null}
                                />
                            );

                        case "bankDetails":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={customerBankDetail[item].inputType}
                                    value={customerBankDetail[item].value}
                                    setInput={(value) => {
                                        updateCustomerParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={customerBankDetail[item].hintText}
                                    mandatory={customerBankDetail[item].mandatory}
                                    colSpan={customerBankDetail[item].colSpan}
                                    options={customerBankDetail[item].inputType === "options" ? customerBankDetail[item].options : null}
                                    error={customerBankDetail[item].error}
                                    rowSpan={item === "customerLogo" || item === "otherBusinessDetails" ? customerBankDetail[item].rowSpan : null}
                                />
                            );

                        case "additionalInfo":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={customerAdditionalInfo[item].inputType}
                                    value={customerAdditionalInfo[item].value}
                                    setInput={(value) => {
                                        updateCustomerParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={customerAdditionalInfo[item].hintText}
                                    mandatory={customerAdditionalInfo[item].mandatory}
                                    colSpan={customerAdditionalInfo[item].colSpan}
                                    options={customerAdditionalInfo[item].inputType === "options" ? customerAdditionalInfo[item].options : null}
                                    error={customerAdditionalInfo[item].error}
                                    rowSpan={item === "customerLogo" || item === "otherBusinessDetails" ? customerAdditionalInfo[item].rowSpan : null}
                                />
                            );

                        default:
                            return null;
                    }
            }
        });
    };

    const tablist = [
        { id: "contactDetails", name: "Contact Details" },
        { id: "deliverAddress", name: "Deliver Address" },
        { id: "bankDetails", name: "Bank Details" },
        { id: "additionalInfo", name: "Additional Information" },
    ];
    const [selectedTab, setSelectedTab] = useState("contactDetails");
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                    {tab.id === "contactDetails" ? selectedTab === "contactDetails" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                    {tab.id === "deliverAddress" ? selectedTab === "deliverAddress" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const renderSubSection = (selectedTab) => {
        switch (selectedTab) {
            case "contactDetails":
                return (
                    <React.Fragment>
                        <div className="createVendorIndividualTabDetails">
                            {renderFormElements({ elementList: customerParameterList[1], section: "contactDetails" })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkCustomerContactsError();
                                }}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "deliverAddress":
                return (
                    <React.Fragment>
                        <div className="createCustomerDeliverCopyAddressWrapper">
                            <input
                                checked={copyDeliverAddress}
                                className="createCustomerDeliverCopyAddress"
                                type="checkbox"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        copyDataToDeliveryAddress();
                                    } else {
                                        setCopyDeliverAddress(false);
                                    }
                                }}
                            />
                            <span>Copy Billing Address</span>
                        </div>
                        <div className="createCustomerIndividualTabDetails">
                            {renderFormElements({ elementList: customerParameterList[2], section: "deliverAddress" })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkCustomerDeliverAddressError();
                                    setCopyDeliverAddress(false);
                                }}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "bankDetails":
                return (
                    <React.Fragment>
                        <div className="createVendorIndividualTabDetails">
                            {renderFormElements({ elementList: customerParameterList[3], section: "bankDetails" })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkCustomerBankError();
                                }}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "additionalInfo":
                return (
                    <React.Fragment>
                        <div className="createVendorIndividualTabAdditionalDetails">
                            {renderFormElements({ elementList: customerParameterList[4], section: "additionalInfo" })}
                        </div>
                    </React.Fragment>
                );

            default:
                return <div>No Specified Subsection</div>;
        }
    };

    const RenderTable = ({ tab }) => {
        switch (tab) {
            case "contactDetails":
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Contact Name</td>
                                <td>Designation</td>
                                <td>Department</td>
                                <td>Mobile No</td>
                                <td>Email Id</td>
                                <td>Experience</td>
                                <td>Education Details</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        {customerContactDetailsList.length > 0 ? (
                            <tbody>
                                <RenderTableRows
                                    rows={customerContactDetailsList}
                                    tab="contactActions"
                                    fields={["contactName", "designation", "department", "mobile", "email", "experience", "education"]}
                                />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {customerParameterList[1].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            case "bankDetails":
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Account Holder Name</td>
                                <td>Account Number</td>
                                <td>Bank Name</td>
                                <td>IFSC Code</td>
                                <td>Branch</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        {customerBankDetailsList.length > 0 ? (
                            <tbody>
                                <RenderTableRows
                                    rows={customerBankDetailsList}
                                    tab="bankActions"
                                    fields={["accountHolderName", "accountNumber", "ifscCode", "bankName", "branch"]}
                                />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {customerParameterList[3].map((item) => {
                                        return <td>&nbsp;</td>;
                                    })}
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            case "deliverAddress":
                var tableFields = ["consignorCompanyName", "gstNo", "contactName", "mobile", "address"];

                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Consignor Company Name</td>
                                <td>GST No</td>
                                <td>Contact Name</td>
                                <td>Mobile No</td>
                                <td>Address</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        {customerDeliverAddressList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={customerDeliverAddressList} tab="deliverActions" fields={tableFields} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {tableFields.map((item) => {
                                        return <td>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            default:
                return <div>No Table rendered</div>;
        }
    };

    const RenderTableRows = ({ rows, tab, fields }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {fields.map((key, i) => {
                        return <td key={i}> {row[key]}</td>;
                    })}
                    {listRecordActions(tab, j)}
                </tr>
            );
        });
    };
    var listRecordActions = (tab, j) => {
        return (
            <td>
                <img
                    alt="Edit record"
                    className="createVendorContactsAction"
                    src={editIcon}
                    onClick={() => {
                        editSubSectionItem(tab, j, "edit");
                    }}
                />
                <img
                    alt="Delete record"
                    className="createVendorContactsAction"
                    src={deleteIcon}
                    onClick={() => {
                        var reset = window.confirm("Delete record?");
                        if (reset) editSubSectionItem(tab, j, "delete");
                    }}
                />
            </td>
        );
    };

    const editSubSectionItem = (tab, item, action) => {
        if (action === "edit") {
            switch (tab) {
                case "contactActions":
                    var contactAllClear = true;
                    customerParameterList[1].forEach((item) => {
                        if (customerContactDetail[item].value !== "") {
                            contactAllClear = false;
                        }
                    });
                    if (!contactAllClear) {
                        var yes = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }
                    if (yes || contactAllClear) {
                        var customerparam = Object.assign(customerContactDetail);
                        var customerContactListCopy = customerContactDetailsList.slice();

                        var editObject = customerContactListCopy.splice(item, 1)[0];
                        Object.keys(editObject).forEach((key) => {
                            customerparam[key].value = editObject[key];
                        });

                        setCustomerContactDetail(customerparam);
                        setCustomerContactDetailsList(customerContactListCopy);
                    }
                    break;

                case "bankActions":
                    var bankAllClear = true;

                    customerParameterList[3].forEach((item) => {
                        if (customerBankDetail[item].value !== "") {
                            bankAllClear = false;
                        }
                    });

                    if (!bankAllClear) {
                        var yes2 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }

                    if (yes2 || bankAllClear) {
                        var customerparam2 = Object.assign(customerBankDetail);
                        var customerBankListCopy = customerBankDetailsList.slice();

                        var editBankObj = customerBankListCopy.splice(item, 1)[0];
                        Object.keys(editBankObj).forEach((key) => {
                            customerparam2[key].value = editBankObj[key];
                        });
                        customerparam2.confirmAccountNo.value = editBankObj.accountNumber;

                        setCustomerBankDetail(customerparam2);
                        setCustomerBankDetailsList(customerBankListCopy);
                    }
                    break;

                case "deliverActions":
                    var deliverAllClear = true;

                    customerParameterList[2].forEach((item) => {
                        if (customerDeliverAddress[item].value !== "") {
                            deliverAllClear = false;
                        }
                    });

                    if (!deliverAllClear) {
                        var yes3 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }

                    if (yes3 || deliverAllClear) {
                        var customerparam3 = Object.assign(customerDeliverAddress);
                        var customerAddressListCopy = customerDeliverAddressList.slice();

                        var editDeliverObj = customerAddressListCopy.splice(item, 1)[0];

                        Object.keys(editDeliverObj).forEach((key) => {
                            if (key === "state") {
                                customerparam3[key].options = stateList[editDeliverObj.country];
                            }
                            if (key === "cityId") {
                                customerparam3.cityId.options = cityList[editDeliverObj.state];
                            }
                            if (!["stateOptions", "cityIdOptions"].includes(key)) {
                                customerparam3[key].value = editDeliverObj[key];
                            }
                        });

                        setCustomerDeliverAddress(customerparam3);
                        setCustomerDeliverAddressList(customerAddressListCopy);
                    }
                    break;

                default:
                    console.log("Not Handled Yet");
                    break;
            }
        } else if (action === "delete") {
            switch (tab) {
                case "contactActions":
                    var customerContactListCopy2 = customerContactDetailsList.slice();
                    customerContactListCopy2.splice(item, 1);
                    setCustomerContactDetailsList(customerContactListCopy2);
                    break;

                case "bankActions":
                    var customerBankListCopy2 = customerBankDetailsList.slice();
                    customerBankListCopy2.splice(item, 1);
                    setCustomerBankDetailsList(customerBankListCopy2);
                    break;

                case "deliverActions":
                    var customerDeliverAddressListCopy2 = customerDeliverAddressList.slice();
                    customerDeliverAddressListCopy2.splice(item, 1);
                    setCustomerDeliverAddressList(customerDeliverAddressListCopy2);
                    break;

                default:
                    break;
            }
        }
    };

    const renderErrorMessage = () => {
        if (customerErrors.length > 0) return `General Info Section : ${customerErrors[0]}`;
        else if (customerServerError.length > 0) return `Error : ${customerServerError[0]}`;
        else if (customerContactsError.length > 0) return `Contact Details Tab : ${customerContactsError[0]}`;
        else if (customerDeliverError.length > 0) return `Deliver Address Tab : ${customerDeliverError[0]}`;
        else if (customerBankError.length > 0) return `Bank Details Tab : ${customerBankError[0]}`;
        else return null;
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "900px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        overflow: "hidden",
                    }}
                >
                    <div className="createCustomerGeneralGrid">
                        {renderFormElements({ elementList: customerParameterList[0], section: "generalInfo" })}
                    </div>
                    <div className="createVendorTabArea">
                        <div className="createVendorTabs">
                            <RenderTabList tablist={tablist} />
                        </div>
                        {renderSubSection(selectedTab)}
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setCustomerContactsError([]);
                            setCustomerBankError([]);
                            setCustomerDeliverError([]);
                            setcustomerServerError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        customerInfo: state.customerInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
