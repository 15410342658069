import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { ResponsiveBar } from "@nivo/bar";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";
import { addDaysToDate, dateFormatting, getIndianCurrency } from "components/CommonFunctions/utils";

const QuoteDashboard = ({isLogged}) => {

  const opportunityStage = ["Appointment Scheduled", "Qualified to Buy", "Presentation Scheduled",
                           "Decision Maker Bought-In", "Contract sent", "opportunity Won", "opportunity Lost"]


  const dealStageOptions = opportunityStage.map((item) => {
    return (
      {
        optionId: item,
        optionName: item
      }
    )
  })

  const [queryParams, setQueryParams] = useState({
    
    fromDate: {
      inputType: "dateFromEditPage",
      value: "04-01-" + new Date().getFullYear(),
      hintText: "From Date",
      mandatory: true,
      error: false,
      mindate: true,
      maxdate: true,
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      mandatory: true,
      error: false,
      maxdate: true,
    },
    stage: {
      inputType: "options",
      value: "opportunity Won",
      options: dealStageOptions,
      colSpan: 6,
      hintText: "Select A Stage",
      mandatory: false,
      error: false,
    },
    dealOwner: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Deal Owner",
      mandatory: false,
      error: false,
    },
    leadId: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Lead Name",
      mandatory: false,
      error: false,
    }
  })

  const [cardData1, setCardData1] = useState([]);
  const [barData, setBarData] = useState([
  ]);
  const [highestQuote,setHighestQuote] = useState([])
  const [dealOwnerList,setDealOwnerList] = useState([
  ])

  const renderFormElements = ({ elementList, params }) => {
    return (
      <div className="dashboardCard dashboardParams" >
        {elementList.map((item, ind) => {
          return (
            <div key={ind} >
              <FormElement
                key={item}
                inputType={params[item].inputType}
                value={params[item].value}
                setInput={(value) => {
                  updateSalesQuoteParameters({
                    param: params,
                    paramName: item,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={params[item].hintText}
                mandatory={params[item].mandatory}
                colSpan={params[item].colSpan}
                options={
                  params[item].inputType === "options" ||
                    params[item].inputType === "multiSelect"
                    ? params[item].options
                    : null
                }
                error={params[item].error}
                isClearable={params[item].isClearable}
                maxdate={params[item].maxdate}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "rgb(11,87,180)",
          strokeWidth: 2,
        },
      },
    },
  };

  const renderBarGraph = (data, indexBy, key, title = "Sales Amount",count) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          width: "32.8rem",
          gridColumn: `1/span 3`,
          alignSelf: 'start',
          gridRow: `3 / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
          <ResponsiveBar
            data={data}
            keys={key}
            indexBy={indexBy}
            margin={{ top: 10, right: 0, bottom: 50, left: 65 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            tooltip={(props) => {
              let  { value, indexValue } = props
              return (
                <div className="dashBoardToolTip">
                  {indexValue}
                  <br />
                  {props.data.count || value}
                  <br />
                </div>
              );
            }}
            theme={theme}
            colors={["rgb(11,87,180)"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 1,
              tickPadding: 5,
              tickRotation: -90,
              legend: "",
              legendPosition: "middle",
              legendOffset: 52,
            }}
            axisLeft={{
              tickSize: 1,
              tickPadding: 4,
              tickRotation: -1,
              legend: title,
              legendPosition: "middle",
              legendOffset: -60,
              format: (value) => `   ₹${Number(value) / 100000} L`,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            label={(d) => null}
            layers={["axes", "bars", "markers", "legends"]}
          />
        </div>
      </div>
    );
  };

  //get filtered quotes details
  const getDbResponse =async(qryParams)=>{
    let data = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-quote-dashboard",
      headers: { token: isLogged.accessToken, module: "Quote Dashboard" },
      data: qryParams,
    });
   if(data.msg ==="success"){
    setCardData1([
      {
        type: "numbers",
        title: "Sale Quote Count",
        value: data.asset.barData.length,
      },
      {
        type: "numbers",
        title: "Total Sale Quote Amount",
        value: getIndianCurrency(data.asset.totalSalesAmount).split('.')[0],
      },
    ])

    setHighestQuote([
      {
        type: "numbers",
        title: "Highest Quote Amount",
        label:data.asset?.highestQuoteAmount?.quoteName||"",
        value: getIndianCurrency(data.asset?.highestQuoteAmount?.totalAmount||0),
      }
    ])

    setBarData(data.asset.barData)
    let dealOwnerCount = data.asset.barData.reduce((acc,value)=>{

      if(acc.find(element=>element.employeeId ===value.employeeId)){
        acc.count+=1
        acc.totalAmount+=value.totalAmount
      }else{
          acc.push({
            count:1,
            employeeName:value.employeeName,
            totalAmount:value.totalAmount
          })
      }
          return acc

        },[])
        setDealOwnerList(dealOwnerCount)
    // return data.asset
   }
  }


  useEffect(()=>{

    let {fromDate,toDate,stage,dealOwner,leadId} =queryParams

    fromDate = dateFormatting(fromDate.value)
    toDate = dateFormatting(addDaysToDate(toDate.value,1))

     let qryParams = {
       dates :[fromDate,toDate],
       stage:stage.value,
       dealOwner:dealOwner.value.map(item=>item.value),
       leadId : leadId.value.map(item=>item.value)
     }

    getDbResponse(qryParams)

  },[queryParams])

  const updateSalesQuoteParameters = ({ param, paramName, key, value }) => {
    let paramsCopy = Object.assign(param);
    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });

    setQueryParams(paramsCopy);
  };


  //get all employee
  async function getEmployees() {

    var data = await fetchData({
      requestingPage: "employeeList",
      method: "POST",
      url: "fetch/employees",
      headers: { token: isLogged.accessToken, module: "Quote Dashboard" },
      data: { filter: "" }
    });
    if (data.msg === "success") {
      return data.asset
    } else {
      console.log(data);
    }
  }

  //get lead list
  const salesLeadList =async()=>{

    let data = await fetchData({
      requestingPage:"employeeDetail",
      method:"get",
      url:"so-fetch/sales-lead-list",
      headers:{token:isLogged.accessToken,module:"Quote Dashboard"}
    })

    if(data.msg === "success"){
      let leadOptions =[];
      data.asset.forEach((lead)=>{
        leadOptions.push({optionId: lead.leadId,optionName:lead.firstName +lead.lastName});
    });
    // setLeadList(data.asset)
    return leadOptions;
    
    }
  }

  const getInformation =async()=>{
    let employeeOptions = await getEmployees();
    let leadOptions = await salesLeadList();
    let copy = structuredClone(queryParams)
    //set deal and lead options in input field
    copy = update(copy,{
      dealOwner:{options:{$set:employeeOptions}},
      leadId:{options:{$set:leadOptions}}
    });
    
    setQueryParams(copy);
  }

 

  useEffect(() => {  
    getInformation()
  }, [])

  const renderSubSection = () => {
    return (
      <>
        <div className="dashboardGrid">
          {renderFormElements({
            elementList: Object.keys(queryParams),
            params: queryParams,
          })}
          <div className="cardsContainer">
            {renderCards(cardData1)}
              {renderCards(
                highestQuote
           )}

           {/* </div> */}
          </div>
           
          {renderBarGraph(
                barData,
                "createDate",
                ["totalAmount"],
                "Sales Quotes"
              )}

           <div style={{gridArea: "3 / 2/ span 3/span 2"}}>
              {renderBarGraph(
                dealOwnerList,
                "employeeName",
                ["totalAmount"],
                "Quote Amount",
                
              )}
              </div>
        </div>
      </>
    )
  }

  const renderCards = (data) => {
    return data?.map((item, ind) => {
      return (
        <div
          className="dashboardCard"
          key={item.title}
          style={{ height: "120px", width: "250px" }}>
          <div className="dashboardCardHeader">
            <span className="dashboardCardTitle">{item.title}</span>
          </div>
          <div className="dashboardCardBody">
            <div className="dashSingle">
              {/* for rupee symbol for amount */}
              <div>
                {/* {item.title ==="Sale Quote Count"?null:"₹"} */}
               {item.value}</div>
            </div>
          </div>
              <div style={{textAlign: "center"}}>{item?.label||""}</div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Dashboard" selectedSubMenu="Quotes Dashboard" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Quotes Dashboard" />
          <span
            className="hintText"
            style={{ color: "GrayText", fontSize: "13px", marginLeft: "40px", marginBottom: "0px" }}>
            *Dashboard data is mainly based on Sales Quote
          </span>
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

export default connect(mapStateToProps)(QuoteDashboard) 