import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const CreateOutward = ({ isLogged, addTokenToState, setSection }) => {
    var outwardParamList = ["orderType", "orderId", "orderDate", "opdId", "outwardDate"];

    const [outwardParams, setOutwardParams] = useState({
        orderType: {
            inputType: "options",
            value: "",
            hintText: "Order Type",
            mandatory: false,
            colSpan: 6,
            error: false,
            options: [
                { optionId: "Sales Order", optionName: "Sales Order" },
                { optionId: "Purchase Return", optionName: "Purchase Return" },
                { optionId: "Plant Transfer", optionName: "Plant Transfer" },
            ],
            errorMessage: "Please select order type",
            // disabled: true,
        },
        orderId: {
            inputType: "options",
            value: "",
            hintText: "Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Order",
        },
        orderDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Order Date",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        opdId: {
            inputType: "options",
            value: "",
            hintText: "OPD No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "",
            options: [],
            // disabled: true,
            errorMessage: "Select OPD Number",
        },
        outwardDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Outward Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Provide and outward date",
            maxdate: true,
        },
    });

    const [soOpdList, setSoOpdList] = useState({});

    const [opdDetail, setOpdDetail] = useState({});

    const [showCustomerDetail, setShowCustomerDetail] = useState(false);

    const [itemList, setItemList] = useState([]);

    const [outwardErrors, setOutwardErrors] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    useEffect(async () => {
        if (outwardParams.orderType.value !== "") {
            var [soList, opdForOrderList] = await getOpdOrderList();


            var paramsCopy = Object.assign(outwardParams);
            paramsCopy = update(paramsCopy, { orderId: { options: { $set: soList } } });
            setOutwardParams(paramsCopy);
            setSoOpdList(opdForOrderList);
        }
    }, [outwardParams.orderType.value]);

    const getInformation = async () => {
        await checkToken();
    };

    const history = useHistory();

    async function checkToken() {
    
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getOpdOrderList() {
        var address = "store-fetch/outward-pre-create-list/";
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: address + outwardParams.orderType.value,
            headers: { token: isLogged.accessToken, module: "Outward" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            var opdList = {};
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.orderNo, optionId: dataItem.orderId, orderDate: dataItem.orderDate };
                optionArray.push(a);
                opdList[dataItem.orderId] = [];
                dataItem.opdList.forEach((opd) => {
                    var opdObj = {};
                    opdObj.optionId = opd.opdId;
                    opdObj.optionName = opd.opdNo;
                    opdList[dataItem.orderId].push(opdObj);
                });
            });
            return [optionArray, opdList];
        }
    }

    useEffect(() => {
        var paramsCopy = Object.assign(outwardParams);
        if (outwardParams.orderId.value !== "") {
            var curItem = paramsCopy.orderId.options.filter((item) => item.optionId === paramsCopy.orderId.value)[0];
            paramsCopy = update(paramsCopy, {
                opdId: { options: { $set: soOpdList[outwardParams.orderId.value] }, value: { $set: "" } },
                orderDate: { value: { $set: curItem.orderDate } },
            });
            setOutwardParams(paramsCopy);
        } else {
            paramsCopy = update(paramsCopy, { orderDate: { value: { $set: "" } }, opdId: { options: { $set: [] }, value: { $set: "" } } });
            setOutwardParams(paramsCopy);
        }
    }, [outwardParams.orderId]);

    useEffect(async () => {
        if (outwardParams.opdId.value !== "") {
            var paramsCopy = Object.assign(outwardParams);

            var opdDetailCopy = await getOpdDetail();

            setOpdDetail(opdDetailCopy);
            setShowCustomerDetail(true);
            setItemList(opdDetailCopy.lineItems);
        } else {
            setShowCustomerDetail(false);
            setItemList([]);
        }
    }, [outwardParams.opdId]);

    async function getOpdDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "store-fetch/opd-detail/" + outwardParams.orderType.value + "/" + outwardParams.opdId.value,
            headers: { token: isLogged.accessToken, module: "Outward" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        } else {
            console.log(data);
        }
    }

    const updateOutwardParameter = ({ paramName, key, value }) => {
        var paramsCopy = Object.assign(outwardParams);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });

        if (paramName === "orderType") {
            paramsCopy = update(paramsCopy, { orderId: { value: { $set: "" } } });
            setShowCustomerDetail(false);
        }

        setOutwardParams(paramsCopy);
    };

    const RenderCustomerDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">{opdDetail.companyName}</div>
                <div className="detailText">
                    {`Customer ID: ${opdDetail.companyId}`},
                    <br /> {opdDetail.companyAddress},
                    <br /> {opdDetail.companyCity}
                    {opdDetail.companyPinCode !== null ? `, - ${opdDetail.companyPinCode}` : null}
                    {opdDetail.companyMobile !== null || opdDetail.companyPhone !== null ? <br /> : null}
                    {opdDetail.companyMobile !== null || opdDetail.companyPhone !== null ? `Phone: ` : null}
                    {opdDetail.companyMobile !== null ? opdDetail.companyMobile : null}
                    {opdDetail.companyPhone !== null ? `, ${opdDetail.companyPhone} ` : null}
                    {opdDetail.companyEmail !== null ? <br /> : null}
                    {opdDetail.companyEmail !== null ? `Email Id: ${opdDetail.companyEmail}` : null}
                    {opdDetail.companyGstNo !== null ? <br /> : null}
                    {opdDetail.companyGstNo !== null ? `GST No:  ${opdDetail.companyGstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Submitting Outward. Please wait..");

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value) => {
                        updateOutwardParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (outwardErrors.length === 0) {
            setSection("outwardList");
        }
        setModalText("Uploading form. Please wait...");
    };
const lineItemCols=isLogged.showHideWeight ? ["itemName", "from", "to", "netWeight", "grossWeight", "uom", "packedQuantity"] : ["itemName", "uom", "packedQuantity"] ;
    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable" style={{ margin: "0.5rem auto 1rem auto" }}>
                <thead>
                    <tr className="createVendorContactsTableHeader" key="tableHeader">
                        <td>Item</td>
                        {isLogged.showHideWeight ?
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td> </> : ''}
                        <td>UOM</td>
                        <td>Quantity</td>
                    </tr>
                </thead>
                {itemList.length > 0 ? (
                    <tbody>
                        <RenderTableRows rows={itemList} tab="contactActions" />
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows" key="emptyRow">
                            {lineItemCols.map((item, i) => {
                                return <td key={i}>&nbsp;</td>;
                            })}
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemCols.map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                </tr>
            );
        });
    };

    const checkOutwardError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(outwardParams);

        outwardParamList.map((item) => {
            if (outwardParams[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        if (errorList.length === 0) {

            var data2server = {
                opdId: paramsCopy.opdId.value,
                outwardDate: paramsCopy.outwardDate.value,
            };
            submitData(data2server);
        }

        setOutwardErrors(errorList);
        setOutwardParams(paramsCopy);
    };

    const submitData = async (data2server) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createPoItem",
            method: "post",
            url: "store-create/outward",
            headers: { token: isLogged.accessToken, module: "Outward" },
            data: data2server,
        });

        if (result.msg === "success") {
            setDataSubmitted(true);
            setModalText("Outward Submitted for Approval");
        } else {
         
            setOutwardErrors([result.desc]);
            setDataSubmitted(true);
            setModalText(result.desc);
        }
    };

    const RenderNoItems = () => {
        if (itemList.length > 0) {
            var uniqArr = Array.from(new Set(itemList.map((item) => item.itemId)));
            return <span>{uniqArr.length}</span>;
        } else {
            return <span>0</span>;
        }
    };

    const renderErrorMessage = () => {
        if (outwardErrors.length > 0) return outwardErrors[0];
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div className="createSalesOrderGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                            {renderFormElements({ elementList: outwardParamList, param: outwardParams })}
                        </div>
                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCustomerDetail ? <RenderCustomerDetail /> : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="purchaseOrderSubSectionArea" style={{width:"90%"}}>
                        {/* {renderSubSection()} */}
                        <span>&nbsp;</span>
                        <RenderTable />
                    </div>
                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                Number of unique items:&nbsp;
                                <RenderNoItems />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset selections?");
                            if (reset) {
                                var paramsCopy = Object.assign(outwardParams);

                                outwardParamList.forEach((item) => {
                                    if (item !== "orderType") paramsCopy[item].value = "";
                                    paramsCopy[item].error = false;
                                });

                                setOutwardParams(paramsCopy);
                                setItemList([]);
                                setOutwardErrors([]);
                                // setItemErrors([]);
                                // setOpdErrors([]);
                                setShowCustomerDetail(false);
                            }
                        }}
                    >
                        Reset Fields
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            // setOpdErrors([]);
                            // checkOpdError();
                            setOutwardErrors([]);
                            checkOutwardError();
                        }}
                    >
                        Submit for Approval
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOutward);
