import fetchData from "../../serverCall/fetchData";
export const countryList = [{ optionId: 1, optionName: "India" }];

export const stateList = {
    1: [
        {
            optionId: 1,
            optionName: "Tamil Nadu",
        },
        {
            optionId: 2,
            optionName: "Andhra Pradesh",
        },
        {
            optionId: 3,
            optionName: "Assam",
        },
        {
            optionId: 4,
            optionName: "Bihar",
        },
        {
            optionId: 5,
            optionName: "Chandigarh",
        },
        {
            optionId: 6,
            optionName: "Chhattisgarh",
        },
        {
            optionId: 7,
            optionName: "Delhi",
        },
        {
            optionId: 8,
            optionName: "Gujarat",
        },
        {
            optionId: 9,
            optionName: "Haryana",
        },
        {
            optionId: 10,
            optionName: "Jammu and Kashmir",
        },
        {
            optionId: 11,
            optionName: "Jharkhand",
        },
        {
            optionId: 12,
            optionName: "Karnataka",
        },
        {
            optionId: 13,
            optionName: "Kerala",
        },
        {
            optionId: 14,
            optionName: "Madhya Pradesh",
        },
        {
            optionId: 15,
            optionName: "Maharashtra",
        },
        {
            optionId: 16,
            optionName: "Odisha",
        },
        {
            optionId: 17,
            optionName: "Punjab",
        },
        {
            optionId: 18,
            optionName: "Rajasthan",
        },
    ],
};

export const cityList = {
    1: [
        {
            optionId: 1,
            optionName: "Coimbatore",
        },
        {
            optionId: 2,
            optionName: "Tiruppur",
        },
        {
            optionId: 3,
            optionName: "Chennai",
        },
        {
            optionId: 4,
            optionName: "Madurai",
        },
        {
            optionId: 5,
            optionName: "Salem",
        },
        {
            optionId: 6,
            optionName: "Tiruchirappalli",
        },
    ],
    2: [
        {
            optionId: 7,
            optionName: "Guntur",
        },
        {
            optionId: 8,
            optionName: "Hyderabad",
        },
        {
            optionId: 9,
            optionName: "Vijayawada",
        },
        {
            optionId: 10,
            optionName: "Visakhapatnam",
        },
    ],
    3: [
        {
            optionId: 11,
            optionName: "Guwahati",
        },
    ],
    4: [
        {
            optionId: 12,
            optionName: "Patna",
        },
    ],
    5: [
        {
            optionId: 13,
            optionName: "Chandigarh",
        },
    ],
    6: [
        {
            optionId: 14,
            optionName: "Bhilai",
        },
        {
            optionId: 15,
            optionName: "Raipur",
        },
    ],
    7: [
        {
            optionId: 16,
            optionName: "Delhi",
        },
    ],
    8: [
        {
            optionId: 17,
            optionName: "Ahmedabad",
        },
        {
            optionId: 18,
            optionName: "Jamnagar",
        },
        {
            optionId: 19,
            optionName: "Rajkot",
        },
        {
            optionId: 20,
            optionName: "Surat",
        },
        {
            optionId: 21,
            optionName: "Vadodara",
        },
    ],
    9: [
        {
            optionId: 22,
            optionName: "Faridabad",
        },
    ],
    10: [
        {
            optionId: 23,
            optionName: "Jammu",
        },
        {
            optionId: 24,
            optionName: "Srinagar",
        },
    ],
    11: [
        {
            optionId: 25,
            optionName: "Dhanbad",
        },
        {
            optionId: 26,
            optionName: "Jamshedpur",
        },
        {
            optionId: 27,
            optionName: "Ranchi",
        },
    ],
    12: [
        {
            optionId: 28,
            optionName: "Bangalore",
        },
        {
            optionId: 29,
            optionName: "Belgaum",
        },
        {
            optionId: 30,
            optionName: "Hubli",
        },
        {
            optionId: 31,
            optionName: "Mangalore",
        },
        {
            optionId: 32,
            optionName: "Mysore",
        },
    ],
    13: [
        {
            optionId: 33,
            optionName: "Kochi",
        },
        {
            optionId: 34,
            optionName: "Kozhikode",
        },
        {
            optionId: 35,
            optionName: "Thiruvananthapuram",
        },
    ],
    14: [
        {
            optionId: 36,
            optionName: "Bhopal",
        },
        {
            optionId: 37,
            optionName: "Gwalior",
        },
        {
            optionId: 38,
            optionName: "Indore",
        },
        {
            optionId: 39,
            optionName: "Jabalpur",
        },
    ],
    15: [
        {
            optionId: 40,
            optionName: "Amravati",
        },
        {
            optionId: 41,
            optionName: "Aurangabad",
        },
        {
            optionId: 42,
            optionName: "Bhiwandi",
        },
        {
            optionId: 43,
            optionName: "Kolhapur",
        },
        {
            optionId: 44,
            optionName: "Mumbai",
        },
        {
            optionId: 45,
            optionName: "Nagpur",
        },
        {
            optionId: 46,
            optionName: "Nashik",
        },
        {
            optionId: 47,
            optionName: "Pune",
        },
        {
            optionId: 48,
            optionName: "Solapur",
        },
    ],
    16: [
        {
            optionId: 49,
            optionName: "Bhubaneshwar",
        },
        {
            optionId: 50,
            optionName: "Cuttack",
        },
    ],
    17: [
        {
            optionId: 51,
            optionName: "Amritsar",
        },
        {
            optionId: 52,
            optionName: "Jalandhar",
        },
        {
            optionId: 53,
            optionName: "Ludhiana",
        },
    ],
    18: [
        {
            optionId: 54,
            optionName: "Bikaner",
        },
        {
            optionId: 55,
            optionName: "Jaipur",
        },
        {
            optionId: 56,
            optionName: "Jodhpur",
        },
        {
            optionId: 57,
            optionName: "Kota",
        },
    ],
};

export async function getLocation(pincode){
    var result=await fetchData({
        requestingPage: "vendorList",
        url: "location-fetch?postcode="+pincode,
        method: "get",
    });
// console.log("pinRes",result);
    if(result && result.length>0){
        return result;
    }else{
        return [];
    }
}