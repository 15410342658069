import React  from "react";
import "../styles/MenuList.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleMenuCollapse } from "../../redux/UserAccount/IsLoggedActions";

import dashboard from "../../assets/dashboard.svg";
import masterIcon from "../../assets/master.svg";
import purchaseIcon from "../../assets/purchase.svg";
import salesIcon from "../../assets/sales.svg";
import importIcon from "../../assets/import.svg";
import storeIcon2 from "../../assets/store.svg";

import roleIcon from "../../assets/role2.svg";
import roleControlIcon from "../../assets/role.svg";
import screenControlIcon from "../../assets/screen_access.svg";
import uomIcon from "../../assets/uom2.svg";
import storeIcon from "../../assets/store2.svg";
import itemMenuIcon from "../../assets/item_menu.svg";
import employeeIcon from "../../assets/employee.svg";
import vendorIcon from "../../assets/vendor.svg";
import customerIcon from "../../assets/customer.svg";
import transportIcon from "../../assets/transport.svg";
import clearingIcon from "../../assets/clearing.svg";
import rightArrowIcon from "../../assets/right-arrow.svg";
import leftArrowIcon from "../../assets/left-arrow.svg";
import inventoryIcon from "../../assets/inventory.svg";
import opdIcon from "../../assets/opd.svg";
import inwardIcon from "../../assets/inward.svg";
import outwardIcon from "../../assets/outward.svg";
import qualityIcon from "../../assets/quality.svg";
import stockTransferIcon from "../../assets/stockTransfer.svg";
import importPoIcon from "../../assets/importPo.svg";
import purchaseImportIcon from "../../assets/purchaseImport.svg";
import salesOrderIcon from "../../assets/salesOrder.svg";
import salesInvoiceIcon from "../../assets/invoice.svg";
import purchaseOrderIcon from "../../assets/purchaseOrder.svg";
import purchaseInvoiceIcon from "../../assets/purchaseInvoice.svg";
import soPendingIcon from "../../assets/soPending.svg";
import productionIcon from "../../assets/production.svg";
import reportIcon from "../../assets/sheets.svg";
import IDNIcon from "../../assets/idn.svg";
import MRSIcon from "../../assets/mrs.svg";
// import enterot2 from "../../assets/Enterot_logo_arciform_darkOrange.png";
import enterot from "../../assets/enterotNewLogoImage.svg";
import enterot2 from "../../assets/enterotNewLogoText.svg";
import DcListIcon from "../../assets/DcListIcon.svg";
import receiptRupee from "../../assets/receipt-rupee.svg";
import salesReportIcon from "../../assets/sales_report.svg";
import { useWindowSize } from "../CommonFunctions/utils";
import BOMIcon from '../../assets/bill-of-materials.svg';
import productionReport from "../../assets/production-report.svg";
import packingSlipIcon from "assets/packing-slip.svg";
import salesLeadIcon from "assets/sales-lead.svg";
import salesOpportunityIcon from "assets/sales-opportunity.svg";
import salesQuoteIcon from "assets/sales-quote.svg";


const MenuList = ({
    selectedMenu,
    selectedSubMenu,

    // state
    isLogged,

    // dispatch
    setCollapse,
}) => {
    const [windowWidth,windowHeight]=useWindowSize();

    const menuItemsList = [
        // { menu: "Dashboard", subMenu: [{ name: "Admin Dashboard", image: dashboard, route: "dashboard" }], enable: true, image: dashboard },
        {
            menu: "Dashboard",
            subMenu: [
                {name: "Sales Dashboard", image: dashboard, route: "salesdashboard"},
                {name : "Quotes Dashboard",image:dashboard,route: "quotesdashboard"},
                {name : "Stock Transfer Dashboard",image:dashboard,route: "stockdashboard"},
            ],
            enable: true,
            image: dashboard
        },
        {
            menu :"Reports",
            subMenu:[
                {name : "Sales Report",image:salesReportIcon,route:"salesreport"},
                {name: "Stock Report", image: reportIcon, route: "stockreport"},
                { name:"Purchase Report",image:purchaseOrderIcon,route:"purchasereport"},
                { name: "Import Report",image: salesReportIcon,route: "import-report"},
                {name: "Production Report",image: productionReport,route:"production-report"},
            ],
            enable:true,
            image:reportIcon
        },
        {
            menu: "Master Configure",
            subMenu: [
                { name: "Role", image: roleIcon, route: "role" },
                { name: "Role Control", image: roleControlIcon, route: "rolecontrol" },
                { name: "Screen Control", image: screenControlIcon, route: "screencontrol" },
                { name: "UOM", image: uomIcon, route: "uom" },
                { name: "Plant & Store", image: storeIcon, route: "plant" },
                { name: "Item Menu", image: itemMenuIcon, route: "itemmenu" },
                {name: "Location", image: dashboard, route: "location"},
                { name: "Employee", image: employeeIcon, route: "employee" },
                { name: "Vendor", image: vendorIcon, route: "vendor" },
                { name: "Customer", image: customerIcon, route: "customer" },
                { name: "Transporter", image: transportIcon, route: "transporter" },
                { name: "Clearing Agent", image: clearingIcon, route: "clearing" },
            ],
            enable: true,
            image: masterIcon,
        },
        {
            menu: "Sales",
            subMenu: [
                // { name: "Sales Opportunities", image: salesInvoiceIcon, route: "sales-opportunities" },
                // { name: "Sales Lead",image: salesLeadIcon, route: "sales-lead" },
                // { name: "Sales Opportunities",image: salesOpportunityIcon, route: "sales-opportunities" },
                // { name: "Sales Quote",image: salesQuoteIcon, route: "sales-quote" },
                { name: "Sales Order", image: salesOrderIcon, route: "salesorder" },
                { name: "Sales Invoice", image: salesInvoiceIcon, route: "salesinvoice" },
                { name: "Customer Receipt",image: receiptRupee, route: "customerreceipts" },
                // { name: "Sales Report",image: salesReportIcon, route: "salesreport" },
                { name: "Other Charges", image: salesInvoiceIcon, route: "othercharges" },
           ],
            enable: true,
            image: salesIcon,
        },
        {
            menu:"Sales Quotes(CRM)",
            subMenu: [
                // { name: "Sales Opportunities", image: salesInvoiceIcon, route: "sales-opportunities" },
                { name: "Sales Lead",image: salesLeadIcon, route: "sales-lead" },
                { name: "Sales Opportunities",image: salesOpportunityIcon, route: "sales-opportunities" },
                { name: "Sales Quote",image: salesQuoteIcon, route: "sales-quote" }
           ],
            enable: true,
            image: salesQuoteIcon,
        },
        {
            menu: "Store",
            subMenu: [
                { name: "Inventory", image: inventoryIcon, route: "store" },
                { name: "OPD", image: opdIcon, route: "opd" },
                { name: "Inward", image: inwardIcon, route: "inward" },
                { name: "Outward", image: outwardIcon, route: "outward" },
                { name: "Quality Checking", image: qualityIcon, route: "quality" },
                { name: "Stock Transfer", image: stockTransferIcon, route: "transfer" },
                { name: "Pending SO", image: soPendingIcon, route: "sopending" },
                { name: "Pending MRS", image: MRSIcon, route: "idnpending" },
                { name: "IDN", image: IDNIcon, route: "idn" },
                // { name: "Stock Report", image: reportIcon, route: "stockreport" },
                { name: "Packaging",image: packingSlipIcon,route:"packaging"},
                {name: "Store Dashboard", image: dashboard, route: "storedashboard"},
            ],
            enable: true,
            image: storeIcon2,
        },       
        {
            menu: "Purchase",
            subMenu: [
                { name: "Purchase Order", image: purchaseOrderIcon, route: "purchaseorder" },
                { name: "Purchase Invoice", image: purchaseInvoiceIcon, route: "purchaseinvoice" },
                { name: "Vendor Payments",image: receiptRupee, route: "vendorpayments" },
                {name: "Purchase Dashboard", image: dashboard, route: "purchasedashboard"},
                // { name:"Purchase Report",image:purchaseOrderIcon,route:"purchasereport"}
            ],
            enable: true,
            image: purchaseIcon,
        },
       
        {
            menu: "Import",
            subMenu: [
                { name: "Import PO", image: importPoIcon, route: "importpo" },
                { name: "Purchase Import", image: purchaseImportIcon, route: "purchaseimport" },
                {name: "Import Dashboard", image: dashboard, route: "importdashboard"},
                // { name: "Import Report",image: salesReportIcon,route: "import-report"}
            ],
            enable: true,
            image: importIcon,
        },
       
        {
            menu: "Production",
            subMenu: [
                    //   {name: "Production Report",image: productionReport,route:"production-report"},
                      { name: "Production Order", image: productionIcon, route: "production" },
                      { name: "Work Order",image: productionIcon,route: "work-order"},
                      {name: "Process Log",image: productionIcon,route: "process-log"},
                      { name: "Material Requisition", image: MRSIcon, route: "materialrequisition"},
                      { name: "DC List", image: DcListIcon, route: "dclist"},
                      { name: "Bill of Material",image: BOMIcon ,route:"bill-of-material"},
                      { name: "Sub Contracting", image: inventoryIcon, route: "subcontracting"},
                      {name: "Production Dashboard", image: dashboard, route: "productiondashboard"},
                     ],
            enable: true,
            image: productionIcon,
        },
    ];

    //console.log("menus..",selectedMenu, selectedSubMenu);

    const history = useHistory();

    const renderMenu = () => {
        return menuItemsList.map((menu, i) => {      
            var currentRoute = menu.subMenu.filter(sMenu=> isLogged.access[sMenu.name])[0];
            if(currentRoute){
            return (
                <div
                    className={menu.menu === selectedMenu ? "menuItemGroupSelected" : "menuItemGroup"}
                    key={menu.menu}
                    onClick={() => {
                        //console.log("current route",currentRoute.route);
                        if (currentRoute.route !== undefined) history.push(`/${currentRoute.route}`);
                        if(windowWidth<1300)
                        setCollapse(!isLogged.collapse);
                    }}
                >
                    <div className={menu.menu === selectedMenu ? "menuTitleSelected" : menu.enable ? "menuTitle" : "menuTitleDisabled"}>
                        <img className="menuImage" src={menu.image} alt={menu.menu} />
                        <span className={menu.menu === selectedMenu ? "menuTextSelected" : "menuText"}>{menu.menu}</span>
                    </div>

                    {menu.menu === selectedMenu
                        ? menu.subMenu.map((item) => {
                               //console.log("===\nMenuList Page:",isLogged.access, item.name, isLogged.access[item.name]);

                              if ( isLogged.access[item.name] !== null)
                                  return (
                                      <div
                                          key={item.name}
                                          className={item.name === selectedSubMenu ? "subMenuSelected" : "subMenu"}
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              var currentRoute = item.route;
                                              if(windowWidth<1300)
                                              setCollapse(!isLogged.menuCollapse)
                                              //console.log(currentRoute);
                                              if (currentRoute !== undefined) history.push(`/${currentRoute}`);
                                          }}
                                      >
                                          <img className="subMenuImage" src={item.image} />
                                          <span className="subMenuText">{item.name}</span>
                                      </div>
                                  );
                          })
                        : null}
                </div>
            ); } else { return null;}
        });
    };

    const renderMenuCollapsed = () => {
        return menuItemsList.map((menu) => {
            var currentRoute = menu.subMenu.filter(sMenu=> isLogged.access[sMenu.name]!==null)[0];
            if(currentRoute){
            return (
                <div
                    className={menu.menu === selectedMenu ? "indiMenuSelected" : "indiMenu"}
                    key={menu.menu}
                    onClick={() => {
                        //console.log(currentRoute.route);
                        if (currentRoute.route !== undefined) history.push(`/${currentRoute.route}`);
                        setCollapse(!isLogged.collapse);
                    }}
                >
                    <img
                        className={menu.menu === selectedMenu ? "indiMenuImageSelected" : "indiMenuImage"}
                        src={menu.image}
                        alt="Menu"
                        title={menu.menu}
                    />
                    {menu.menu === selectedMenu ? (
                        <React.Fragment>
                            {menu.subMenu.map((subMenu,ind) => {
                        if ( isLogged.access[subMenu.name] !== null)
                                return (
                                    <div
                                        className={subMenu.name === selectedSubMenu ? "indiMenu2Selected" : "indiMenu2"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            var currentRoute = subMenu.route;
                                            //console.log(currentRoute);
                                            if (currentRoute !== undefined) history.push(`/${currentRoute}`);
                                        }}
                                        key={ind}
                                    >
                                        <img
                                            className={subMenu.name === selectedSubMenu ? "indiMenuImage2selected" : "indiMenuImage2"}
                                            src={subMenu.image}
                                            alt="Sub Menu"
                                            title={subMenu.name}
                                        />
                                    </div>
                                ); 
                            })}
                        </React.Fragment>
                    ) : null}{" "}
                </div>
            ); } else { return null;}
        });
    };

    return (
        <React.Fragment>
            {!isLogged.menuCollapse ? (
                <div className="menuContainer">
                    <div className="menuExpandGroup">
                        <img alt="enterot"
                            className="companyLogo"
                            style={{ paddingRight: "5px" }}
                            src={enterot}
                            onClick={() => {
                                window.open("https://enterot.com/", "_blank");
                            }}
                        />
                        <img alt="enterot"
                            className="companyLogoText"
                            src={enterot2}
                            onClick={() => {
                                window.open("https://enterot.com/", "_blank");
                            }}
                        />
                        <div style={{ flex: "1" }}></div>
                        <img alt="menu"
                            className="menuExpandImage"
                            src={isLogged.menuCollapse ? rightArrowIcon : leftArrowIcon}
                            title={isLogged.menuCollapse ? "Expand Menu" : "Collapse Menu"}
                            onClick={() => setCollapse(!isLogged.menuCollapse)}
                        />
                    </div>
                    <div className="menuItems">{renderMenu()}</div>
                </div>
            ) : (
                <div className="menu2Container">
                    <div className="menuCollapseGroup">
                        {/* <img
                            className="companyLogo"
                            style={{ paddingRight: "5px" }}
                            src={enterot}
                            onClick={() => {
                                window.open("https://enterot.com/", "_blank");
                            }}
                        /> */}
                        <img alt="menu"
                            className="menuCollapseImage"
                            src={isLogged.menuCollapse ? rightArrowIcon : leftArrowIcon}
                            title={isLogged.menuCollapse ? "Expand Menu" : "Collapse Menu"}
                            onClick={() => setCollapse(!isLogged.menuCollapse)}
                        />
                    </div>
                    <div className="menuItems">{renderMenuCollapsed()}</div>
                </div>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCollapse: (collapse) => dispatch(toggleMenuCollapse(collapse)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
