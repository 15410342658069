import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import DcListIcon from "../../assets/DcListIcon.svg"
import {connect} from "react-redux";
import CreateDc from "./createDc";
import DcListPage from "./dcListPage";
import DcDetail from "./dcDetail";
import EditDc from "./editDc";
import CreateDcReturn from "./createDcReturn";
import EditDcReturn from "./editDcReturn";
import DcReturnDetail from "./dcReturnDetail";

const DcList =({isLogged,poInfo})=>{
    const [currentSection,setCurrentSection] = useState("dcList");
    const [approvalPage,setApprovalPage]=useState(false);
    const [editAndApprove,setEditAndApprove]=useState(false);
    const access = isLogged.access["DC List"];
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "dcList" };
    var createDc = {buttonName:"Create Dc",className:"employeeButton", setSection: setCurrentSection, sectionName: "createDc"};
    var createDcReturn = {buttonName:"Create Dc Return",className:"employeeButton", setSection: setCurrentSection, sectionName: "createDcReturn"};
    const renderSection=(section)=>{
        switch(section){
            case "createDc":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={DcListIcon} pageTitle="Create DC" buttonList={[goBackButton]} />
                        <CreateDc setSection={(value)=>setCurrentSection(value)} />
                    </React.Fragment>
                );

                case "createDcReturn":
                    return (
                        <React.Fragment>
                            <PageTitle imgSrc={DcListIcon} pageTitle="Create DC Return" buttonList={[goBackButton]} />
                            <CreateDcReturn setSection={(value)=>setCurrentSection(value)} />
                        </React.Fragment>
                    );

            case "dcList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={DcListIcon} pageTitle = "DC List" 
                        buttonList={(access === "Create" || access === "Edit" ||access ===  "App/Dec") ? 
                                        ( (poInfo.dcListTab==="dcReturnList" || poInfo.dcListTab==="dcReturnApprovedList") ? [createDcReturn] : [createDc] ) : null} />
                        <DcListPage setSection={(value)=>{setCurrentSection(value)}} setApproval={setApprovalPage}  setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                );

            case "dcDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={DcListIcon} pageTitle={approvalPage ? "Approve/Decline DC ": "DC Detail View"} buttonList={[goBackButton]} />
                        <DcDetail setSection={(value) => setCurrentSection(value)} approval={approvalPage} 
                                  setEditAndApprove={(value) => setEditAndApprove(value)}/>
                    </React.Fragment>
                );

            case "editDc":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={DcListIcon} pageTitle={ editAndApprove ? "Edit and Approve Dc" : "Edit Dc"} buttonList={[goBackButton]} />
                        <EditDc setSection={(value)=>setCurrentSection(value)} editApprove={editAndApprove}/>
                    </React.Fragment>
                    );

            case "editDcReturn":
                return(
                        <React.Fragment>
                            <PageTitle imgSrc={DcListIcon} pageTitle={editAndApprove ? "Edit and Approve Dc Return" : "Edit Dc Return"} buttonList={[goBackButton]} />
                            <EditDcReturn setSection={(value)=>setCurrentSection(value)} editApprove={editAndApprove}/>
                        </React.Fragment>
                );

            case "dcReturnDetail":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={DcListIcon} pageTitle={approvalPage ? "Approve DC Return": "DC Return Detail View"} buttonList={[goBackButton]} />
                        <DcReturnDetail setSection={(value)=>setCurrentSection(value)} approval={approvalPage}
                                        setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                )
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu= "Production" selectedSubMenu= "DC List"/>
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged,
             poInfo: state.poInfo };
};


export default connect(mapStateToProps, null)(DcList);
