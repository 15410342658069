import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import { editClearingAgentId, editTransporterId, editUomAction } from "../../redux/Item/ItemInfoActions";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const EditAgent = ({
    // props
    setSection,

    // state
    itemInfo,
    isLogged,

    // dispatch
    addTokenToState,
    editAgentId,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getTransporters();
    }

    const history = useHistory();

    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getTransporters() {
        var data = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/clearing-agent-list",
            headers: { token: isLogged.accessToken ,module:"Clearing Agent"},
        });
        if (data.msg === "success") {
            setAgentList(data.asset);
            setNewAgent(data.asset.filter((i) => i.clearingAgentId === itemInfo.editClearingAgentId)[0]);
        } else {
            // console.log(data);
        }
    }

    const [agentList, setAgentList] = useState([]);

    const [newAgent, setNewAgent] = useState({ clearingAgentName: "", description: "" });


    const renderTransporterList = (list) => {
        return list.map((row) => {
            return row.clearingAgentId !== itemInfo.editClearingAgentId ? (
                <tr className="uomListData" key={row.clearingAgentId}>
                    <td className="uomSymbolData">{row.clearingAgentName}</td>
                    <td className="uomSymbolData">{row.description}</td>
                    <td className="uomActionData">
                        <img
                            className="uomEditImage"
                            src={editIcon}
                            alt="Edit Record"
                            onClick={() => {
                                editAgentId(row.clearingAgentId);
                                setNewAgent(agentList.filter((i) => i.clearingAgentId === row.clearingAgentId)[0]);
                            }}
                        />
                    </td>
                </tr>
            ) : (
                renderCreateUomInput()
            );
        });
    };

    const renderCreateUomInput = () => {
        return (
            <tr className="uomListData" key="CreateUom">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newAgent?.clearingAgentName}
                        onChange={(e) => setNewAgent({ ...newAgent, clearingAgentName: e.target.value })}
                        autoFocus
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                </td>
                <td className="uomSymbolData3">
                    <input
                        className="createUom2"
                        value={newAgent.description}
                        onChange={(e) => setNewAgent({ ...newAgent, description: e.target.value })}
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                    <button
                        className="cancelRoleButton"
                        onClick={() => {
                            editAgentId(null);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if (newAgent.clearingAgentName.trim() !== "" && newAgent.description.trim() !== "") {
                                editTransporterData();
                                setIsOpen(true);
                            } else {
                                window.alert("Make sure Symbol and Description is provided");
                            }
                        }}
                    >
                        Save
                    </button>
                </td>
                <td className="uomActionData">&nbsp;</td>
            </tr>
        );
    };

    async function editTransporterData() {
        var newAgentCopy = Object.assign(newAgent);
        newAgentCopy.clearingAgentId = newAgentCopy.clearingAgentId;

        var dataSent = await fetchData({
            requestingPage: "editUom",
            method: "put",
            url: "edit/clearing-agent",
            headers: { token: isLogged.accessToken,module:"Clearing Agent" },
            data: newAgentCopy,
        });


        setDataSubmitted(true);
        if (dataSent.msg === "success") {
            editAgentId(null);
            setModalText("Saved Clearing Agent changes successfully!");
            setNewAgent({ clearingAgentName: "", description: "" });
            getInformation();
        } else {
            setModalText(dataSent.desc);
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Updating Clearing Agent. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Updating Clearing Agent. Please wait...");
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="uomListArea">
                <table className="uomListTable">
                    <tr className="uomListHeader">
                        <td className="uomSymbolHeader">Symbol</td>
                        <td className="uomSymbolHeader">Description</td>
                        <td className="uomActionHeader">Action</td>
                    </tr>

                    {renderTransporterList(agentList)}
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editAgentId: (agentId) => dispatch(editClearingAgentId(agentId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAgent);
