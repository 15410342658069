import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const NoAccess = ({ isLogged }) => {
    const history = useHistory();
    const condition = isLogged.homeRoute === '/salesdashboard' && isLogged.access["Sales Dashboard"] === null ? true : false;
    return (
        <div className="noAccessContainer">
            {(()=>{if(condition)
                    {return(<div>Screen Permission Not Provided For this User</div>);}
                else 
                    {return(<div>You can't access this Page</div>);}
                })()}
            <div>
                Go to{" "}
                <span
                    className="noAccessBackToHome"
                    onClick={() => {
                        if(condition)
                        {history.push('/');}
                        else
                        {history.push(isLogged.homeRoute);}
                    }}>
                    {(()=>{if(condition)
                    {return(<span>Login</span>);}
                else 
                    {return(<span>Home</span>);}
                })()}
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

export default connect(mapStateToProps, null)(NoAccess);
