import { connect, Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import allReducers from "./redux";
import reduxThunk from "redux-thunk";
import "./App.css";
import Home from "./Home";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "./components/CommonFunctions/utils";
import ErrorModal from "./components/SmallComponents/ErrorModal";

const middleware = [reduxThunk];
const store = createStore(allReducers, composeWithDevTools(applyMiddleware(...middleware)));

function App() {
    const [showAlert,setShowAlert] = useState(false);
    const [windowWidth, windowHeight] = useWindowSize();
    const errorList = [{title:'Please Rotate Your Device',messages:['This Web application is Better Viewable in Larger screen than this device.']}]
    useEffect(() => {
      if (windowWidth < windowHeight && showAlert !== 'init') setShowAlert(true);
      else {
        setShowAlert(false);
      }
    }, [windowWidth, windowHeight]);
    return (
      <React.Fragment>
        <ErrorModal errorModal={showAlert} errorList={errorList} okButtonPressed={()=>setShowAlert(false)}/>
        <Provider store={store}>
            <Home />
        </Provider>
        </React.Fragment>
    );
}

export default App;
