import { useState } from "react";

import * as XLSX from "xlsx";

const ReadFile = () => {
    const [excelData, setExcelData] = useState([]);
    const [excelData2, setExcelData2] = useState([]);

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const workBook = XLSX.read(bufferArray, { type: "buffer" });
                // console.log(workBook.SheetNames);

                const workSheetName = workBook.SheetNames[0];
                const workSheet = workBook.Sheets[workSheetName];
                const data = XLSX.utils.sheet_to_json(workSheet);

                const workSheetName2 = workBook.SheetNames[1];
                const workSheet2 = workBook.Sheets[workSheetName2];
                const data2 = XLSX.utils.sheet_to_json(workSheet2);

                // console.log(data2);

                resolve({ data, data2 });
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then(({ data, data2 }) => {
            // console.log(data);
            setExcelData(data);

            // console.log(data2);
            setExcelData2(data2);

            // var oaDate = data[1365].Date;
            // var date = new Date();

            // date.setTime((oaDate - 25569) * 24 * 3600 * 1000);
            // console.log(date);
            // console.log(date.toISOString());
        });
    };

    const [filesToServer, setFilesToServer] = useState([]);
    // console.log(filesToServer);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
                margin: "auto",
                textAlign: "center",
                padding: "2rem",
                justifyContent: "center",
            }}
        >
            <h1> Reading Excel File</h1>
            <input
                type="file"
                onChange={(e) => {
                    readExcel(e.target.files[0]);
                }}
            />

            <table style={{ width: "100%", border: "1px solid lightgray", fontSize: "12px" }}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Income</th>
                        <th>Into</th>
                        <th>Tag</th>
                        <th>Note</th>
                        <th>Expense</th>
                        <th>For</th>
                        <th>Tag_1</th>
                        <th>Type</th>
                        <th>From</th>
                        <th>From_1</th>
                        <th>To</th>
                        <th>Amount</th>
                        ​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​
                    </tr>
                </thead>
                <tbody>
                    {excelData.map((data) => {
                        var date = new Date();

                        date.setTime((data.Date - 25569) * 24 * 3600 * 1000);

                        // console.log(date.toISOString().slice(0, 10));
                        return (
                            <tr>
                                <td>{date.toISOString().slice(0, 10)}</td>
                                <td>{data.Income}</td>
                                <td>{data.Into}</td>
                                <td>{data.Tag}</td>
                                <td>{data.Note}</td>
                                <td>{data.Expense}</td>
                                <td>{data.For}</td>
                                <td>{data.Tag_1}</td>
                                <td>{data.Type}</td>
                                <td>{data.From}</td>
                                <td>{data.From_1}</td>
                                <td>{data.To}</td>
                                <td>{data.Amount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div>{excelData2.map((data2) => JSON.stringify(data2))}</div>

            <input
                type="file"
                multiple
                onChange={(e) => {
                    // console.log(e.target.files);
                    setFilesToServer([...filesToServer, e.target.files[0]]);
                }}
            />
        </div>
    );
};

export default ReadFile;
