import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import itemCurrency from "../../../assets/itemCurrency.svg";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import CreateItemCurrency from "./createItemCurrency";
import ItemCurrencyList from "./itemCurrencyList";
import EditItemCurrency from "./editItemCurrency";

const ItemCurrency = ({ isLogged }) => {
  var history = useHistory();

  const pushBack = (section) => {
    history.push(section);
  };

  const [currentSection, setCurrentSection] = useState("itemCurrencyList");

  var goBackButton = [
    {
      buttonName: "Back",
      className: "goBackButton",
      setSection: setCurrentSection,
      sectionName: "itemCurrencyList",
    },
  ];
  var goBackToItemButton = {
    buttonName: "Back",
    className: "goBackButton",
    setSection: pushBack,
    sectionName: "/itemMenu",
  };
  var createItemCurrencyButton = {
    buttonName: "Create Currency",
    className: "employeeButton",
    setSection: setCurrentSection,
    sectionName: "createItemCurrency",
  };

  const renderSection = (section) => {
    switch (section) {
      case "itemCurrencyList":
        return (
          <React.Fragment>
            <PageTitle imgSrc={itemCurrency} pageTitle="Item Currency"
              buttonList={ isLogged.access["Item Type"] === "Create" || isLogged.access["Item Type"] === "Edit"
              ? [createItemCurrencyButton, goBackToItemButton]
              : [goBackToItemButton]}
            />
            <ItemCurrencyList setSection={(value)=>setCurrentSection(value)}/>
          </React.Fragment>
        );
      case "createItemCurrency":
        return (
          <React.Fragment>
            <PageTitle pageTitle="Item Currency" imgSrc={itemCurrency} buttonList={goBackButton} />
            <CreateItemCurrency setSection={(value)=>setCurrentSection(value)}/>
          </React.Fragment>
        );
      case "editItemCurrency":
          return(
              <React.Fragment>
                  <PageTitle imgSrc={itemCurrency} pageTitle="Edit Item Currency" buttonList={goBackButton} />
                        <EditItemCurrency setSection={(value) => setCurrentSection(value)} />
              </React.Fragment>
          )
      default:
        return <h1>Page not yet created</h1>;
    }
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemCurrency);
