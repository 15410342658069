export const addToken = (accessToken, employeeId) => {
    return { type: "ADD_TOKEN", payload: { accessToken, employeeId } };
};

export const removeToken = () => {
    return { type: "REMOVE_TOKEN" };
};

export const addEmpInfo = (employeeId, employeeName, role, roleId,editableForApproved) => {
    return { type: "ADD_EMP_INFO", payload: { employeeId, employeeName, role, roleId,editableForApproved } };
};

export const addEmpImage = (empImage) => {
    return { type: "ADD_EMP_IMAGE", payload: { empImage } };
};

export const addUserAccess = (userAccess) => {
    return { type: "ADD_USER_ACCESS", payload: { userAccess } };
};

export const addHomeRoute = (homeRoute) => {
    return { type: "ADD_HOME_ROUTE", payload: { homeRoute } };
};

export const toggleMenuCollapse = (menuCollapse) => {
    return { type: "TOGGLE_MENU_COLLAPSE", payload: { menuCollapse } };
};

export const addPlantOptions = (options) =>{
    return {type: "ADD_PLANT_OPTIONS", payload:{ options } };
}

export const showHideWeight = (bool) =>{
    return { type: "SHOW_HIDE_WEIGHT" , payload: { bool } };
}

export const bomShortCut = (itemId) => {
    return {type: "BOM_SHORTCUT",payload: {itemId}}
}

export const viewSoDetailId = (soId)=>{
    return {type : "SO_DETAIL_ID",payload:{ soId }}
}