import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import viewIcon from "../../assets/view.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { editScreenControl, viewScreenControl } from "../../redux/Role/RoleInfoActions";

const ScreenControlList = ({ editScreenControlId, viewScreenControlId, addTokenToState, isLogged, setSection }) => {
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rolesConfigured, setRolesConfigured] = useState([]);

    useEffect(() => {
        // console.log("Starting");
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getRoles();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        }
    }

    async function getRoles() {
        var data = await fetchData({
            requestingPage: "roleList",
            method: "get",
            url: "fetch/screen-control-role-list-editable",
            headers: { token: isLogged.accessToken,module: "Screen Control" },
        });
        if (data.msg === "success") {
            setRolesConfigured(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        }
        setLoading(false);
    }

    const renderConfiguredROles = () => {
        return rolesConfigured.map((role) => {
            if(role.empNo != isLogged["employeeId"])
            return (
                <tr className="roleList" key={role.screen_control_id}>
                    <td className="roleName">{role.empNo}</td>
                    <td className="roleName">{role.roleName}</td>
                    <td className="roleName">{role.plantName}</td>
                    <td className="roleAction">
                        <img
                            className="employeeActionImage"
                            src={viewIcon}
                            alt="View Screen Control"
                            onClick={() => {
                                if (role.role_id !== 1) {
                                    setSection("viewScreenControl");
                                    viewScreenControlId({plantId: role.plantId,roleId:role.roleId,empNo:role.empNo});
                                }
                            }}
                            style={{ cursor: "pointer" }}
                            title="View Screen Control for this Employee"
                        />
                        <img
                            className="roleEditImage"
                            src={isLogged.access["Screen Control"] === "Edit" ? editIcon : role.role_id === 1 ? editIconDisabled : editIconDisabled}
                            alt="Edit Role"
                            onClick={() => {
                                if (role.role_id !== 1 && isLogged.access["Screen Control"] === "Edit") {
                                    setSection("editScreenControl");
                                    editScreenControlId({plantId: role.plantId,roleId:role.roleId,empNo:role.empNo});
                                }
                            }}
                            style={isLogged.access["Screen Control"]==="Edit" ? { cursor: "pointer" }: {cursor: "not-allowed"}}
                            title={isLogged.access["Screen Control"]==="Edit" ? "Edit Screen Control for this Employee" :  "Can't Edit" }
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="rolePagesContainer">
            <div className="roleListArea">
                <table className="roleListTable">
                    <thead>
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Employee ID</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Role Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Plant Name</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {rolesConfigured.length > 0 ? renderConfiguredROles() : null}
                    </tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editScreenControlId: (screenControlId) => dispatch(editScreenControl(screenControlId)),
        viewScreenControlId: (screenControlId) => dispatch(viewScreenControl(screenControlId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenControlList);
