import React from "react";
import Select from "react-select";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "white" : "#333",
        padding: "5px 20px",
        fontSize: "14px",
    }),
    control: (_, state) => ({
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        color: "#333",
        fontSize: "14px",
        border: state.selectProps.error ? "1px solid red" : "1px solid rgb(221,221,221)",
        boxSizing: "border-box",
        flex: 1,
        display: "flex",
        backgroundColor: state.selectProps.disabled ? "rgb(240,240,240)" : "white",
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        const color = "#333";
        const fontSize = "14px";

        return { ...provided, opacity, transition, color, fontSize };
    },
    indicatorSeparator: () => {
        return null;
    },
};

const SearchableDropDown = ({ options, selected, setInput, error, searchable, disabled,filterOptions, multiple ,isClearable}) => {
    var optionsFormatted = [];
    options.forEach((option) => {
        var formattedOption = { value: option.optionId , label: option.optionName ,...option };
        optionsFormatted.push(formattedOption);
    });
 
    const defIndex = optionsFormatted.findIndex((opt) =>  opt.value === selected);
 
    return (
        <Select
            options={optionsFormatted}
            styles={customStyles}
            onChange={(value) => {//console.log(value ? value.value : null,value);
                value !== null && value !== "" ? setInput(value.value,value) : setInput("");
            }}
            error={error}
            filterOption={filterOptions}
            value={defIndex < 0 ? null : optionsFormatted[defIndex]}
            isMulti={multiple}
            isSearchable={true}
            isClearable={isClearable==undefined ? true : isClearable}
            disabled={disabled}
            isDisabled={disabled}
        />
    );
};

export default React.memo(SearchableDropDown);
