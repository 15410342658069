import React,{Fragment,useState} from "react";
import { useHistory } from "react-router-dom";
import MenuList from "../../Menu/MenuList"
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner"
import purchaseOrderIcon from "../../../assets/purchaseOrder.svg"
import VenderInvoiceParams from "./vendorInvoiceParams"
import VendorInvoiceList from "./vendorInvoiceList";

const VenderInvoiceReport = () => {
    const [currentSection, setCurrentSection] = useState("vendorInvoice");
    const history = useHistory();

    var goBackButton = (props) =>
    ({
        buttonName: "Back",
        className: "goBackButton",
        ...props
    });

    const renderSection=(sectionName)=>{
      switch(sectionName){
          case "vendorInvoice": return(
              <Fragment>
             <PageTitle imgSrc={purchaseOrderIcon} pageTitle={"Vendor Invoice"}  
                buttonList={[goBackButton({setSection:(value)=>history.push(value),sectionName:"purchasereport"})]}/>
                <VenderInvoiceParams setCurrentSection={setCurrentSection}/>
              </Fragment>
          )

          case "vendorInvoiceList": return(
          <Fragment>
               <PageTitle imgSrc={purchaseOrderIcon} pageTitle={"Vendor Invoice"}  
                buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"vendorInvoice"})]}/>
                <VendorInvoiceList setCurrentSection={setCurrentSection}/>
          </Fragment>)
      }
    }

    return (
        <Fragment>
            <div className="completePage">
                <MenuList selectedMenu={"Reports"} selectedSubMenu={"Purchase Report"} />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </Fragment>
    )
}

export default VenderInvoiceReport;